import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import { helper } from "../../lib/helper";

export const addLevel = createAsyncThunk("level/addLevel", async ({formData, combinedValues}) => {
  console.log(combinedValues, "combinedValues")
  const form = new FormData();
  form.append("add_level", formData?.add_level);
  form.append("bus_id", formData?.bus_id);
  form.append("name",formData?.name );
  // form.append("accept_permission",formData?.accept_permission);
  form.append("accept_permission",combinedValues);
  try {
    const response = await axiosInstance.post("/levels.php", form);
    console.log(form,"form")
    // helper.sweetalert.toast("Add level successfully")
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const fetchAlllevels = createAsyncThunk("level/fetchAlllevels", async (_,{dispatch}) => {
  const formdata = new FormData();
  formdata.append("level_list", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  try {
    const response = await axiosInstance.post("/levels.php", formdata);
    console.log("Response level:", response.data.data.levels);
    dispatch(setFilterData(response.data.data.levels))
    return response.data.data.levels;
  } catch (error) {
    console.error("API Error:", error);
    return error.response;
  }
});

export const editLevels = createAsyncThunk("level/editLevels", async ({event , combinedValues,outid}) => {
  const formdata = new FormData(event.target);
  formdata.append("update_level",  localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"))
  formdata.append("level_id", outid);
  // formdata.append("name", formData?.name)
  formdata.append('accept_permission', combinedValues)
  try {
    const response = await axiosInstance.post(`/levels.php`, formdata);
    helper.sweetalert.toast("Edit level successfully")
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const assignLevelData = createAsyncThunk("level/assignLevelData", async ({levelId ,outid}) => {
  const formdata = new FormData();
  formdata.append("assign_emps", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"))
  formdata.append("level_id", levelId);
  formdata.append("emp_ids", outid)
  try {
    const response = await axiosInstance.post(`/levels.php`, formdata);
    console.log("level resoo assign",response)
    helper.sweetalert.toast("Assign level successfully")
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const unAssignLevel = createAsyncThunk("level/unAssignLevel", async (formData) => {
  const formdata = new FormData();
  formdata.append("unassign_emp", formData?.unassign_emp);
  formdata.append("bus_id", formData?.bus_id)
  formdata.append("emp_id", formData?.emp_id);
  try {
    const response = await axiosInstance.post(`/levels.php`, formdata);
    helper.sweetalert.toast("Unassign level successfully")
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const removelevel = createAsyncThunk(
  "staff/removelevel",
  async ({ id: id }, { dispatch }) => {
    const formdata = new FormData();
    formdata.append("delete_level", localStorage.getItem("token"));
    // formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("level_id", id);
    try {
      const response = await axiosInstance.post("levels.php", formdata, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: formdata,
      });
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);


export const fetchLevelById = createAsyncThunk("levels/fetchLevelById", async ({selectedLevelId}) => {
  try {
    const formdata = new FormData();
  formdata.append("level_list", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("level_id",selectedLevelId);
    const response = await axiosInstance.post("/levels.php", formdata);
    return response.data?.data
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});


const levelSlice = createSlice({
  name: "level",
  initialState: {
    levels: [],
    errorMessage: "",
    loading: true,
    filterdData: [],
    selectedData: null,
    selectedLevel:null,
    manageLevel:false,
    levelAssign:[],
    levelsId:[]

  },
  reducers: {
    // You can add other non-async actions here if needed.
    setFilterData: (state, action) => {
      state.filterdData = action.payload
    },
    clearSelectedData: (state) => {
      state.selectedData = null;
    },
    setSelectedData:(state, action)=>{
      state.selectedLevel = action.payload
    },
    setManageLevel:(state,action)=>{
      state.manageLevel=action.payload
    },
    setLoading:(state,action)=>{
      state.loading=action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlllevels.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlllevels.fulfilled, (state, action) => {
        state.loading = false;
        state.levels = action.payload;
        // state.levelSearchList = action.payload.data.levels;
      })
      .addCase(fetchAlllevels.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload;
      })
      .addCase(fetchLevelById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLevelById.fulfilled, (state, action) => {
        state.loading = false;
        state.levelsId = action.payload;
        // state.levelSearchList = action.payload.data.levels;
      })
      .addCase(fetchLevelById.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload;
        
      })
      .addCase(assignLevelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignLevelData.fulfilled, (state,  action ) => {
        state.loading = false;
        state.levelAssign=action.payload
        // Handle success or update state accordingly
      })
      .addCase(assignLevelData.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(unAssignLevel.pending, (state) => {
        state.loading = true;
      })
      .addCase(unAssignLevel.fulfilled, (state, { payload }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(unAssignLevel.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(removelevel.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removelevel.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload;
      })
      .addCase(removelevel.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(addLevel.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLevel.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(addLevel.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
      
  },
});

// Export actions
export const { setFilterData,clearSelectedData,setSelectedData,setManageLevel,setLoading  } = levelSlice.actions;

// Export the reducer
export default levelSlice.reducer;
