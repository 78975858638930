import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import { helper } from "../../lib/helper";

export const fetchData = createAsyncThunk(
  "payroll/fetchAllPayroll",
  async (_, { dispatch }) => {
    try {
      const formdata = new FormData();
      formdata.append("get_salary", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      const response = await axiosInstance.post("/salary.php", formdata);
      console.log("Response:", response.data);
      dispatch(setFilterData(response.data?.emp));
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const handleSubmit = createAsyncThunk(
  "payroll/handleSubmit",
  async ({ formattedDate }) => {
    const formdata = new FormData();
    formdata.append("get_month_salary", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("data_dt", formattedDate);
    try {
      const response = await axiosInstance.post(`/salary.php`, formdata);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const onMarkPaids = createAsyncThunk("payroll/onMarkPaids", async ({ sal_id ,event}) => {
    const formdata = new FormData(event.target);
    formdata.append("mark_salary_paid", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("sal_id", sal_id);

    try {
      const response = await axiosInstance.post(`/salary.php`, formdata);
      helper.sweetalert.toast("Salary Mark Sucsessfuly")
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getdetailssal = createAsyncThunk("payroll/getdetailssal",async ({ sal_id }) => {
    const formdata = new FormData();
    formdata.append("get_salary_detail", localStorage.getItem("token"));
    formdata.append("sal_id", sal_id);

    try {
      const response = await axiosInstance.post(`/salary.php`, formdata);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);


const payrollSlice = createSlice({
  name: "payroll",
  initialState: {
    payroll: [],
    errorMessage: "",
    loading: true,
    filterdData: [],
  },
  reducers: {
    // You can add other non-async actions here if needed.
    setFilterData: (state, action) => {
      state.filterdData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        console.log("action", action);
        state.businesses = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(handleSubmit.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleSubmit.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedData = action.payload;
      })
      .addCase(handleSubmit.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(onMarkPaids.pending, (state) => {
        state.loading = true;
      })
      .addCase(onMarkPaids.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedData = action.payload;
      })
      .addCase(onMarkPaids.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(getdetailssal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getdetailssal.fulfilled, (state, action) => {
        state.loading = false;
        state.paysummary = action.payload;
      })
      .addCase(getdetailssal.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      });
  },
});

// Export actions
export const { setFilterData } = payrollSlice.actions;

// Export the reducer
export default payrollSlice.reducer;
