import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import { PieChart, Pie, Legend, Tooltip } from 'recharts';
import { Container, Row, Col, Card } from 'react-bootstrap';
const data = [
    { name: 'Regular', value: 100, },
    { name: 'Early', value: 200 },
    // { name: 'Late', value: 200 },
    // { name: 'On leave', value: 100 },
];
const Attendacedetails = () => {


    return (
        <>
            <div>
                <Container fluid style={{ backgroundColor: 'rgba(171, 171, 173, 0.15)' }} >
                    <Row className='pt-3 pb-3'>
                        <Col>
                            <div className='' >
                                <span style={{ fontSize: '20px' }}>Attendance Details</span>
                                <button type="button " style={{ position: 'relative', float: 'right', marginLeft: "10px" }} className=' btn btn-success'>Setting</button>
                                <button type="button " style={{ position: 'relative', float: 'right' }} className='btn btn-primary'>Add attendance</button>

                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-3'>
                        <Col>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '100px', marginLeft: '25px', padding: '5px' }}>Department</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '90px', marginLeft: '25px', padding: '5px' }}>Work Shift</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '70px', marginLeft: '25px', padding: '5px' }}>Users</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '100px', marginLeft: '25px', padding: '5px' }}>Designation</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '150px', marginLeft: '25px', padding: '5px' }}>Employment Status</button>

                        </Col>
                    </Row>

                    <Card style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px" }}>
                        <Row className='mt-2 mb-3'>
                            <table className=' ' style={{ width: '96%', marginLeft: '40px', marginTop: '20px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '480px' }}>
                                            July, 2023
                                        </td>
                                        <td>Today</td>
                                        <td>This Week </td>
                                        <td> Last Week </td>
                                        <td>This Month </td>
                                        <td>Last Month</td>
                                        <td>This Year</td>
                                    </tr>
                                </tbody>
                            </table>

                        </Row>
                        <hr />

                        <Row>
                            <table className='table table-bordered ' style={{ marginLeft: '40px', width: 'auto' }}>
                                <thead style={{ height: '100px' }}>
                                    <tr style={{ verticalAlign: 'middle' }} className=''>
                                        <td style={{ paddingLeft: '20px', width: '50px' }}>Profile1 </td>
                                        <td colspan="2" style={{ width: '20px' }}>Total</td>
                                        <td>Jul 3</td>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                        <td rowSpan="5" style={{ paddingLeft: '20px', width: '50px' }}>
                                            <div className='top-card-div' style={{}}>
                                                <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                    </svg>
                                                </span>
                                                <p className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                    <br /> Development
                                                </p>

                                            </div>
                                        </td>
                                        <td style={{ width: '10px' }}>S</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                        <td style={{ width: '10px' }}>08:00</td>

                                    </tr>
                                    <tr>


                                        <td style={{ width: '10px' }}>w</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                    </tr>
                                    <tr>


                                        <td style={{ width: '10px' }}>BT</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                    </tr>
                                    <tr>


                                        <td style={{ width: '10px' }}>PL</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                    </tr>
                                    <tr>


                                        <td style={{ width: '10px' }}>B</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                        <td style={{ width: '10px' }}>08:00</td>
                                    </tr>
                                    {/* <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                            <td style={{ paddingLeft: '20px', width: '80px' }}>
                                                <div className='top-card-div' style={{}}>
                                                    <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                        </svg>
                                                    </span>
                                                    <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                        <br /> Development

                                                    </span>

                                                </div>
                                            </td>
                                            <td style={{  width: '10px' }}>S</td>
                                            <td style={{  width: '10px' }}>08:00</td>
                                            <td style={{  width: '10px' }}>08:00</td>
                                            
                                        </tr>
                                        <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                            <td style={{ paddingLeft: '20px', width: '80px' }}>
                                                <div className='top-card-div' style={{}}>
                                                    <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                        </svg>
                                                    </span>
                                                    <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                        <br /> Development

                                                    </span>

                                                </div>
                                            </td>
                                            <td style={{  width: '10px' }}>S</td>
                                            <td style={{  width: '10px' }}>08:00</td>
                                            <td> style={{  width: '10px' }}08:00</td>
                                            
                                        </tr> */}



                                </tbody>
                            </table>

                        </Row>

                    </Card>
                </Container>
            </div>
        </>
    )
}
export default Attendacedetails;