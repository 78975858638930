import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import UserInfoIcon from "@rsuite/icons/UserInfo";
import CalendarIcon from "@rsuite/icons/Calendar";
import ProjectIcon from "@rsuite/icons/Project";
import logo from "../../Aseset/image/logoNoText.png";
import WechatOutlineIcon from "@rsuite/icons/WechatOutline";
import HistoryIcon from "@rsuite/icons/History";
import ToolsIcon from "@rsuite/icons/Tools";
import TaskIcon from "@rsuite/icons/Task";
import EventDetailIcon from "@rsuite/icons/EventDetail";
import "./sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedPage,
  sidebarChildMenuClose,
  sidebarChildMenuOpen,
} from "../../store/silce/sidebarSlice";
import { fetchHome } from "../../store/silce/dashboardSlice";
import { Button } from "react-bootstrap";
import OffIcon from "@rsuite/icons/Off";
import { setManageLevel } from "../../store/silce/levelSlice";
import { BsNutFill } from "react-icons/bs";
import auth from "../../Model/profile.model";

const SideBar = ({ show1 }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isHovering = useSelector((state) => state.sidebar.isHovering);
  const showdata = "9074297336";
  let getPagename = localStorage.getItem("pageName");

  const handleTabClick = () => {
    // Use window.open to open the specified URL in a new tab
    window.open("http://15.168.83.45/RMS/login.php", "_blank");
  };
  const handleTabClickLms = () => {
    // Use window.open to open the specified URL in a new tab
    window.open("http://15.168.106.169/KKNT-LMS/", "_blank");
  };
  const HomeData = useSelector(
    (state) => state?.dashboard?.home?.data?.log_cont
  );

  useEffect(() => {
    dispatch(fetchHome());
  }, []);

 

  const menuData = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      path: "/home",
      Top: "0px",
      onClicksetPage: () => {
        window.localStorage.setItem("pageName", "Dashboard");
      },
    },
    {
      text: "Business",
      icon: <ProjectIcon />,
      Top: "10px",
      path: "/Businesslist",
      // onClicksetPage:()=>{
      //     window.localStorage.setItem("pageName", "Business")
      // },
      children: [
        {
          text: "Business",
          path: "/Businesslist",
          onClicksetPage: () => {
            window.localStorage.setItem("pageName", "Business");
          },
        },
        {
          text: "Department",
          path: "/Department",
          onClicksetPage: () => {
            window.localStorage.setItem("pageName", "Department");
          },
        },
        {
          text: "Sub-Department",
          path: "/Sub-Department",
          onClicksetPage: () => {
            window.localStorage.setItem("pageName", "Sub-Department");
          },
        },
      ],
    },
//     HomeData === "9074297336" && {
//     text: "Recruitment",
//     icon: <ProjectIcon />,
//     Top: "80px",
//   },
    {
      text: "Staff",
      icon: <UserInfoIcon />,
      Top: "100px",
      path: "/stafflist",
      children: [
        { text: "Staff List", path: "/stafflist" },
        // { text: "Staff Map", path: "/Staffmap" },
      ],
    },
    {
      text: "Attendance",
      icon: <CalendarIcon />,
      Top: "180px",
      path: "/Updatetype",
      children: [
        { text: "Today Logs", path: "/Updatetype" },
        { text: "Report Attendance", path: "/Attendance" },
        { text: "Selfie Attendance", path: "/SelfiAttendance" },
      ],
    },
    {
      text: "Holiday",
      icon: <HistoryIcon />,
      Top: "200px",
      path: "/Holidays",
      children: [{ text: "All Holidays", path: "/Holidays" }],
    },
    {
      text: "Payroll",
      icon: <WechatOutlineIcon />,
      Top: "260px",
      path: "/Salary",
      children: [
        { text: "Payroll", path: "/Salary" }
      ].concat(
        (HomeData === "9074297336" || HomeData === "8434506393" ) 
          ? [{ text: "Reimbursement", path: "/Reimbursement" }]
          : []
      )
    },
    {
      text: "Notice",
      icon: <WechatOutlineIcon />,
      Top: "320px",
      path: "/Noticehistory",
    },
    {
      text: "Leave",
      icon: <EventDetailIcon />,
      Top: "380px",
      path: "/LeaveHistory",
    },

    {
      text: "Role",
      icon: <TaskIcon />,
      Top: "450px",
      path: "/manegeRoleAndLevel",
      // children: [
      //     { text: 'Assigne Role', path: '/Role' },
      //     // { text: 'Level', path: '/level-management',changePage:()=>{dispatch(setManageLevel(false))} },
      // ],
    },
    {
      text: 'Library',
      icon: <ToolsIcon />,
      Top: '450px',
      path: '/Librarydoc/1',
      children: [
          { text: 'Document', path: '/Librarydoc/1' },
          { text: 'Video', path: '/Librarydoc/2' },
      ],
  },
    // {
    //   text: "Settings",
    //   icon: <ToolsIcon />,
    //   Top: "500px",
    //   path: "/Auto-Punch-Out",
    //   children: [
    //     { text: "Auto Punch Out", path: "/Auto-Punch-Out" },
    //     { text: "Salary-Setting", path: "/Salary-Setting" },
    //   ],
    // },
  ];
  if (HomeData === "9074297336" || HomeData === "8434506393"  || HomeData === "9820652104") {
    menuData.splice(2, 0, {
      text: "Recruitment",
      icon: <ProjectIcon />,
      Top: "80px",
    });
  }
  if (HomeData === "9584744525" || HomeData === "8434506393" || HomeData === "9074297336") {
    menuData.splice(3, 0, {
      text: "LMS",
      icon: <ProjectIcon />,
      Top: "80px",
    });
  }
  // if (HomeData === "9074297336" || HomeData === "8434506393"  || HomeData === "9820652104") {
  //   menuData.splice(2, 0, {
  //     text: "Recruitment",
  //     icon: <ProjectIcon />,
  //     Top: "80px",
  //   });
  // }
  
  const [openMenus, setOpenMenus] = React.useState({});
  const [hoveredItem, setHoveredItem] = React.useState(null);
  const toggleMenu = (text) => {
    const updatedMenus = {};
    Object.keys(openMenus).forEach((key) => {
      updatedMenus[key] = false;
    });
    setOpenMenus({
      ...updatedMenus,
      [text]: !openMenus[text],
    });
  };
  const isParentActive = (parent) => {
    if (!parent.children) return false;
    return parent.children.some((child) => location.pathname === child.path);
  };

  const handleMouseOn = (item) => {
    
    dispatch(sidebarChildMenuOpen({ data: item, value: true }));
    setHoveredItem(item);
  };


  useEffect(() => {
    dispatch(setSelectedPage(getPagename));
  }, []);

  const renderSideMenus = () => {
    return (
      <>
        {!show1 && (
          <List
            sx={{
              backgroundColor: "#2b303b",
              border: "none",
              borderRadius: "0px 10px 10px 0px",
              position: "absolute",
              top: hoveredItem?.Top,
              zIndex: 2,
              left: "58px",
            }}
          >
            <ListItemButton>
              <ListItemText
                onClick={() => {
                  navigate(hoveredItem?.path);
                }}
                sx={{ color: "white" }}
                primary={hoveredItem?.text}
              />
            </ListItemButton>
            {/* {data?.children && ( */}
            <List component="div" disablePadding>
              {hoveredItem?.children?.map((child) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={child.text}
                  onClick={() => {
                    navigate(child.path);
                    dispatch(sidebarChildMenuClose(false));
                    child?.onClicksetPage();
                  }}
                >
                  <ListItemText
                    primary={
                      <ul className="nested-list">
                        <li
                          style={{
                            color:
                              location.pathname === child.path
                                ? "#a5ceea"
                                : "#6e7684",
                          }}
                        >
                          {child.text}
                        </li>
                      </ul>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
            {/* )} */}
            {/* <Button><OffIcon/></Button> */}
          </List>
        )}
      </>
    );
  };
  const logoutApp = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <>
      <aside className={`sidebar simplebar ${show1 ? "show1" : null}`}>
        <nav className="navt">
          <div>
            <Link to="/home" className="nav-logo">
              {/* <i className={`fas fa-home-alt nav-logo-icon`}></i> */}
              <Avatar src={logo} />
              <span className="nav-logo-name">QR Staff</span>
            </Link>
            {/* <ListCollapse title="My List" items={['Item 1', 'Item 2', 'Item 3']} /> */}
            <List>
              {menuData?.map((item, i) => {
                return (
                  <ListItem
                    key={i}
                    onClick={() => {
                      renderSideMenus(item);
                      item.onClicksetPage();
                    }}
                    onMouseOver={() => {
                      handleMouseOn(item);
                    }}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: show1 ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        navigate(item.path);
                        handleMouseOn(item);
                        renderSideMenus(item);
                        toggleMenu(item.text);
                      }}

                      // onMouseOver={() => {
                      //   handleMouseOver(item)
                      //   renderSideMenus(item)
                      // }}
                      // onMouseOut={handleMouseOut}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: show1 ? 3 : "auto",
                          justifyContent: "center",
                          padding: "10px",
                          backgroundColor:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "#019aff"
                              : "#1e2229",
                          color:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "white"
                              : "#6e7684",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span className="nav-link-name">{item.text}</span>
                        }
                        sx={{
                          opacity: show1 ? 1 : 0,
                          color:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "white"
                              : "#6e7684",
                        }}
                        onClick={() =>
                          item.text === "Recruitment" ? handleTabClick() : item.text === "LMS" ? handleTabClickLms() : null
                        }
                      />
                      {item.children && show1 && (
                        <div>
                          {openMenus[item.text] ? (
                            <ExpandLess
                              sx={{ color: "gray" }}
                              onClick={() => toggleMenu(item.text)}
                            />
                          ) : (
                            <ExpandMore
                              sx={{ color: "gray" }}
                              onClick={() => toggleMenu(item.text)}
                            />
                          )}
                        </div>
                      )}
                    </ListItemButton>
                    {show1 && (
                      <>
                        {item.children && (
                          <Collapse
                            in={openMenus[item.text]}
                            timeout="auto"
                            unmountOnExit
                            style={{
                              backgroundColor:
                                location.pathname === item.path ||
                                isParentActive(item)
                                  ? "#1e2229"
                                  : "#1e2229",
                            }}
                          >
                            <List component="div" disablePadding>
                              {item.children.map((child) => (
                                <ListItemButton
                                  sx={{ pl: 4 }}
                                  key={child.text}
                                  onClick={() => {
                                    navigate(child.path);
                                    child?.onClicksetPage();
                                  }}
                                >
                                  <ListItemText
                                    onClick={child?.changePage}
                                    primary={
                                      <ul className="nested-list">
                                        <li
                                          style={{
                                            color:
                                              location.pathname === child.path
                                                ? "#a5ceea"
                                                : "#6e7684",
                                          }}
                                        >
                                          {child.text}
                                        </li>
                                      </ul>
                                    }
                                  />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </>
                    )}
                  </ListItem>
                );
              })}
            </List>
            {show1 ? (
              <div className="p-2">
                <Button
                  style={{ backgroundColor: "#18285a", border: "none" }}
                  onClick={() => logoutApp()}
                  className="w-100"
                >
                  Logout
                </Button>
              </div>
            ) : (
              <div className="p-2">
                <Button
                  style={{
                    backgroundColor: "#1e2229",
                    border: "none",
                    color: "gray",
                  }}
                  onClick={() => logoutApp()}
                >
                  <OffIcon />
                </Button>
              </div>
            )}
          </div>
        </nav>
      </aside>
      <div style={{ color: "black", marginTop: "6rem", marginLeft: "5rem" }}>
        {isHovering && renderSideMenus()}
      </div>
    </>
  );
};

export default SideBar;
