import axiosInstance from "../lib/axiosInstance";
const Notice = class {
  
  
    async Noticehistory(noticehistory ) {
       
        return await axiosInstance.post("/notice.php", noticehistory);
    }
    async Boardcast(boradcast){

        return await axiosInstance.post("/notice.php", boradcast);
    }
    async Noticeemp(noticeemp){

        return await axiosInstance.post("/notice.php", noticeemp);
    }
    
    
}

export default new Notice();