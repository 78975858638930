

import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from '../components/Navbar/Navbar';
import Sidenav from "./sidenav";
// import { AppContext } from '../lib/AppContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './admin.css'
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../components/Sidebar/SideBar';
// import Navbar from '../components/Navbar/Navbar';
import { sidebarChildMenuClose } from '../store/silce/sidebarSlice';

const drawerWidth = 240;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Admin({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  // const [open, setOpen] = React.useState(false);+
  // const { handleMouseOut, selectedHoverData, isHovering, handleMouseOver } = React.useContext()
  const location = useLocation();
  const isHovering = useSelector((state) => state.sidebar.isHovering);
  const selectedHoverData = useSelector((state) => state.sidebar.selectedHoverData);

  const [show1, setshow1] = React.useState(true);

  const [openMenus, setOpenMenus] = React.useState({});

  React.useEffect(() => {
    const sessionData = sessionStorage.getItem("userinfo");
    const isClient = sessionData && JSON.parse(sessionData).usr_type === "Client";
    const isOnHomePage = window.location.pathname === "/";

    if (!isClient || isOnHomePage) {
      window.location.assign(isClient ? "/home" : "/");
    }
  }, []);




  return (
  <main onClick={() => {
      dispatch(sidebarChildMenuClose(false));
    }} className={show1 ? 'space-toggle' : null}>
      <Navbar show1={show1} setshow1={setshow1} />
      <SideBar show1={show1} />
      <div onClick={() => {
        dispatch(sidebarChildMenuClose(false));
      }}>
        {children}
      </div>
    </main>
  );
}