import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import { helper } from "../../lib/helper";

// Create an async thunk for getting all businesses
// export const selectOptionSlice = createSlice({
//   name: 'selectOption',
//   initialState: {
//     value: localStorage.getItem('selectOption') || 'default', // Initial value from localStorage
//   },
//   reducers: {
//     updateSelectOption: (state, action) => {
//       state.value = action.payload;
//       localStorage.setItem('selectOption', action.payload);
//     },
//   },
// });
export const addBusiness = createAsyncThunk("business/addBusiness", async (event) => {
  const form = new FormData(event.target);
  form.append("add_site", localStorage.getItem("token"));
  form.append("bus_id", localStorage.getItem("selecteoption"));
  form.append("email_count", 1);

  try {
    const response = await axiosInstance.post("/add_bus.php", form);
    helper.sweetalert.toast(response?.data?.message)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const Adddepartments = createAsyncThunk("business/Adddepartments", async (event) => {
  const form = new FormData(event.target);
  form.append("add", localStorage.getItem("token"));
  form.append("bus_id", localStorage.getItem("selecteoption"));

  try {
    const response = await axiosInstance.post("/departments.php", form);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const AddSubDepartments = createAsyncThunk("business/AddSubDepartments", async (event) => {
  const form = new FormData(event.target);
  form.append("add", localStorage.getItem("token"));
  form.append("bus_id", localStorage.getItem("selecteoption"));

  try {
    const response = await axiosInstance.post("/departments.php", form);
    helper.sweetalert.toast(response?.data?.message)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

// Create an async thunk for deleting a business
export const businessDelete = createAsyncThunk("business/businessDelete", async ({ id }) => {
  const formdata = new FormData();
  formdata.append("delete_bus", localStorage.getItem("token"));
  formdata.append("bus_id", id);
  try {
    const response = await axiosInstance.post(`/add_bus.php`, formdata);
    return response;
  } catch (error) {
    return error.response.data;
  }
});
export const editBusiness = createAsyncThunk("business/editBusiness", async ({ id }) => {
  const formdata = new FormData();
  formdata.append("bus_profile", localStorage.getItem("token"));
  formdata.append("bus_id", id)
  try {
    const response = await axiosInstance.post(`/get_bus.php`, formdata);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const editDepartmet = createAsyncThunk("business/editDepartmet", async ({id }) => {
  console.log(id, "sssoutid")
  const form = new FormData();
  form.append("detail", localStorage.getItem("token"));
  form.append("department_id", id)
  try {
    const response = await axiosInstance.post(`/departments.php`, form);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const assingeempdep = createAsyncThunk("business/assingeempdep", async ({outid,idget }) => {
  console.log(outid, "sssoutid")
  const form = new FormData();
  form.append("assign", localStorage.getItem("token"));
  form.append("department_id", outid)
  form.append("bus_id", localStorage.getItem("selecteoption"));
  form.append("employeeIds", idget);
  try {
    const response = await axiosInstance.post(`/departments.php`, form);
    helper.sweetalert.toast(response?.data?.message)
    return response.data;
  } catch (error) {
    return error.response.data;
  }});
  export const unassingeempdep = createAsyncThunk("business/unassingeempdep", async ({outid,idget }) => {
    console.log(outid, "sssoutid")
    const form = new FormData();
    form.append("unassign", localStorage.getItem("token"));
    form.append("department_id", outid)
    form.append("bus_id", localStorage.getItem("selecteoption"));
    form.append("employeeIds", idget);
    try {
      const response = await axiosInstance.post(`/departments.php`, form);
      helper.sweetalert.toast(response?.data?.message)
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  });
export const removeDerpartment = createAsyncThunk("staff/removeDerpartment", async ({ id: id }, { dispatch }) => {
  const formdata = new FormData();
  formdata.append("delete", localStorage.getItem("token"));
  formdata.append("department_id", id);
  try {
      const response = await axiosInstance.post("/departments.php", formdata, {
          method: 'post',
          headers: {
              'Content-Type': 'application/json'
          },
          body: (formdata)
      });
      return response;
  } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
  }
});
export const  updateSubmit = createAsyncThunk("business/updateSubmit", async ({ event, outid }) => {
  const form = new FormData(event.target);
  form.append("update_bus", localStorage.getItem("token"));
  form.append("bus_id", outid);
  form.append("email_count", 1);
  try {
    const response = await axiosInstance.post(`/add_bus.php`, form);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const  updatedepartment = createAsyncThunk("business/updatedepartment", async ({ event, outid }) => {
  const form = new FormData(event.target);
  form.append("update", localStorage.getItem("token"));
  form.append("department_id", outid);

  try {
    const response = await axiosInstance.post(`/departments.php`, form);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const  updateBusLogo = createAsyncThunk("business/updateBusLogo", async ({ event}) => {
  const form = new FormData(event.target);
  form.append("update_bus_logo", localStorage.getItem("token"));
  // form.append("bus_id", outid);
  try {
    const response = await axiosInstance.post(`/add_bus.php`, form);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});


export const fetchAllBusinesses = createAsyncThunk("business/fetchAllBusinesses", async (_, { dispatch }) => {
  try {
    const formdata = new FormData();
    formdata.append("get_all_bus", localStorage.getItem("token"));
    const response = await axiosInstance.post("/get_bus.php", formdata);
    console.log("Response:", response.data.bussiness);
    dispatch(setFilterData(response.data?.bussiness))
    return response;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

export const fetchAlldepartments = createAsyncThunk("business/fetchAlldepartments", async () => {
  try {
    const formdata = new FormData();
    formdata.append("list", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    const response = await axiosInstance.post("/departments.php", formdata);
    console.log("Response:", response.data.bussiness);
    // dispatch(setFilterData(response.data?.bussiness))
    return response;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

export const fetchAllSubdepartments = createAsyncThunk("business/fetchAllSubdepartments", async ({Option}) => {
  try {
    console.log(Option, 'OptionOption')
    const formdata = new FormData();
    formdata.append("list", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("department_id", Option );
    const response = await axiosInstance.post("/departments.php", formdata);
    console.log("Responsshiabve", response.data);
    // dispatch(setFilterData(response.data?.bussiness))
    return response;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

export const fetchSubDepartment = createAsyncThunk("business/fetchSubDepartment", async ({selectedOption}) => {
  try {
    console.log(selectedOption, 'selectedOptionselectedOption')
    const formdata = new FormData();
    formdata.append("list", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("department_id",selectedOption);
    const response = await axiosInstance.post("/departments.php", formdata);
    console.log("sub:", response.data.departments)
    // dispatch(setFilterData(response.data?.bussiness))
    return response
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

export const departmentBusinesses = createAsyncThunk("business/departmentBusinesses", async (_, { dispatch },selectedValue) => {
  try {
    const formdata = new FormData();
    formdata.append("list", localStorage.getItem("token"));
    formdata.append("bus_id", selectedValue);
    const response = await axiosInstance.post("/departments.php", formdata);
    console.log("Response:", response.data.bussiness);
    dispatch(setFilterData(response.data?.bussiness))
    return response;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

export const businesssectorFetch = createAsyncThunk("business/businesssectorFetch", async (_, { dispatch }) => {
  try {
    const formdata = new FormData();
    formdata.append("get_bus", localStorage.getItem("token"));
    const response = await axiosInstance.post("/get_bus.php", formdata);
    dispatch(setSector(response.data?.bussiness))
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

export const changeBusiness = createAsyncThunk("business/changeBusiness", async ({selectedValue}) => {
  const formdata = new FormData();
  formdata.append("change_site", localStorage.getItem("token"));
  formdata.append("def_bus", selectedValue);
  try {
    const response = await axiosInstance.post("/change_bussiness.php", formdata);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

const businessSlice = createSlice({
  name: "business",
  initialState: {
    value: localStorage.getItem('selectOption') || 'default',
    businesses: null,
    errorMessage: "",
    loading: true,
    businessesList: [],
    selectedData: null,
    updatedData: null,
    getBusData: null,
    sector: [],
    filterdData: [],
    Alldepartments:[],
    AllSubdepartments:[],
    departmentBusinesses:[],
    fetchSubDepartment:[],
    Allnewsubdepartments:[]
  },
  reducers: {
    // You can add other non-async actions here if needed.
    clearSelectedData: (state) => {
      state.selectedData = null;
    },

    setSector: (state, action) => {
      state.sector = action.payload
    },
    setFilterData: (state, action) => {
      state.filterdData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBusinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllBusinesses.fulfilled, (state, action) => {
        console.log("action", action)
        state.businessesList = action.payload.data.bussiness;
        state.businesses = action.payload
        state.loading = false;
      })
      .addCase(fetchAllBusinesses.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchAlldepartments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlldepartments.fulfilled, (state, action) => {
        console.log("action", action)
        state.Alldepartments = action.payload.data.departments;
        state.businesses = action.payload
        state.loading = false;
      })
      .addCase(fetchAlldepartments.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchAllSubdepartments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllSubdepartments.fulfilled, (state, action) => {
        console.log("action", action)
        state.Allnewsubdepartments = action.payload.data.departments;
        state.businesses = action.payload
        state.loading = false;
      })
      .addCase(fetchAllSubdepartments.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(departmentBusinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubDepartment.fulfilled, (state, action) => {
        console.log("action", action.payload.data.departments)
        state.AllSubdepartments =  action.payload.data.departments;
        state.businesses = action.payload
        state.loading = false;
      })
      .addCase(fetchSubDepartment.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(departmentBusinesses.fulfilled, (state, action) => {
        console.log("action", action)
        state.businessesList = action.payload.data.bussiness;
        state.businesses = action.payload
        state.loading = false;
      })
      .addCase(departmentBusinesses.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(addBusiness.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBusiness.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(addBusiness.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
      .addCase(Adddepartments.pending, (state) => {
        state.loading = true;
      })
      .addCase(Adddepartments.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(Adddepartments.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
      .addCase(businessDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(businessDelete.fulfilled, (state, { payload }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(businessDelete.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(editBusiness.pending, (state) => {
        state.loading = true;
      })
      .addCase(editBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedData = action.payload
        // Handle success or update state accordingly
      })
      .addCase(editBusiness.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(editDepartmet.pending, (state) => {
        state.loading = true;
      })
      .addCase(editDepartmet.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedData = action.payload
        // Handle success or update state accordingly
      })
      .addCase(editDepartmet.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(updateSubmit.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSubmit.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedData = action.payload
      })
      .addCase(updateSubmit.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(updatedepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatedepartment.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedData = action.payload
      })
      .addCase(updatedepartment.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(updateBusLogo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBusLogo.fulfilled, (state, action) => {
        state.loading = false;
        // state.updatedData = action.payload
      })
      .addCase(updateBusLogo.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(businesssectorFetch.pending, (state) => {
        state.loading = true;
      })
      .addCase(businesssectorFetch.fulfilled, (state, action) => {
        state.getBusData = action.payload
        state.loading = false;
      })
      .addCase(businesssectorFetch.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      });
  },
});

// Export actions
export const { /* Other non-async actions */ clearSelectedData, setSector, setFilterData } = businessSlice.actions;

// Export the reducer
export default businessSlice.reducer;
