import React  from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";

const Calender = () => {

    return (
        <>
            <div className="d-flex" id="wrapper">
                <Sidenav style={{ parseFloat }} />
                <div id="page-content-wrapper">
                    <Topnav />
                    <div className="container-fluid pb-5 "style={{ backgroundColor: 'rgba(171, 171, 173, 0.15)' }}>
                        <div className='pt-5' >
                            <span style={{fontSize:'20px'}} className='mt-5'>Designation</span>
                            <button type="button " style={{ position: 'relative', float: 'right' }} className='btn btn-primary'>Add Employee Status</button>
                        </div>
                        

                        <div className="  pt-4">

                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '100px',  padding: '5px' }}>Department</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '110px', marginLeft: '25px', padding: '5px' }}>Work Shift</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '120px', marginLeft: '25px', padding: '5px' }}>Leave duration</button>

                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '70px', marginLeft: '25px', padding: '5px' }}>User</button>
                            <div className='search-box' style={{ position: 'relative', display: 'inline-block', float: 'right' }} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-search p-1" viewBox="0 0 16 16" style={{ position: 'absolute', top: '5px', left: '0px', transform: 'translateY(-50)' }}>
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                                <input type="text" style={{ paddingLeft: "30px", height: '40px', borderRadius: '20px', borderStyle: 'solid', backgroundColor: "#f5f2f2", borderColor: 'lightblue' }} placeholder='Search' />
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt-4 "
                        style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px", width: "95%", height: "100%" }}>
                        <div className=" container-fluid justify-content-between pt-5" >
                        <table className=' ' style={{ width: '96%', marginLeft: '40px', marginTop: '20px' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '350px' }}>
                                                July, 2023
                                            </td>
                                            <td>Today</td>
                                            <td>This Week </td>
                                            <td> Last Week </td>
                                            <td>This Month </td>
                                            <td>Last Month</td>
                                            <td>This Year</td>
                                            <td><input type="checkbox" name="" id="" /><span> See leave 
                                                <br />
                                                request</span> </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                        <hr />
                        <br className="dropdown-divider" />
                        <div className=" text-center">
                            <div className="row">
                                <div className="col-3  mx-4 shadow-sm" style={{ width: "20%" }}>
                                    <h4 className='mt-4'>0</h4>
                                    <h6 className='mb-4'>Employees on leave</h6>
                                </div>
                                <div className="col-3  mx-4 shadow-sm" style={{ width: "20%" }}>
                                    <h4 className='mt-4'>00:00:00</h4>
                                    <h6 className='mb-4'>Total leave hours</h6>
                                </div>
                                <div className="col-3  mx-4 shadow-sm" style={{ width: "20%" }}>
                                    <h4 className='mt-4'>0</h4>
                                    <h6 className='mb-4'>On leave( Single day )</h6>
                                </div>
                                <div className="col-3  mx-4 shadow-sm" style={{ width: "20%" }}>
                                    <h4 className='mt-4'>0</h4>
                                    <h6 className='mb-4'>On leave (Multi day)</h6>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
export default Calender;