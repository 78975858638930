import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import {
  fetchAttendance,
} from "../../store/silce/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "react-google-charts";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardNewCharts = ({attendance}) => {
  const totalAbsent = parseInt(attendance?.absent || 0);
  const totalPresent = parseInt(attendance?.present || 0);
  const hasData = totalAbsent !== 0 || totalPresent !== 0;

  const data = hasData
    ? [
        ["Label", "Value", { role: "style" }],
        ["Absent", totalAbsent, "#4286fc"],
        ["Present", totalPresent, "#109618"],
      ]
    : [
        ["Label", "Value", { role: "style" }],
        ["No Data", 100, "#CCCCCC"],
      ];

  const options = {
    is3D: true,
    slices: {
      0: { color: "#2a5091" }, // Red color for "Absent" slice
      1: { color: "#7aa9fa" }, // Green color for "Present" slice
    },
  };
  return (
    <div >
      {/* <Pie data={pieData} options={chartOptions} s /> */}
      <Chart
        width={"100%"}
        height={"200px"}
        chartType="PieChart"
        data={data}
        options={options}
        loader={<div>Loading Chart</div>}
      />
    </div>
  );
};

export default DashboardNewCharts;
