import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Avatar,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DashboardIcon from "@rsuite/icons/legacy/Dashboard";
import UserInfoIcon from "@rsuite/icons/UserInfo";
import CalendarIcon from "@rsuite/icons/Calendar";
import ProjectIcon from "@rsuite/icons/Project";
import logo from "../../Aseset/image/logoNoText.png";
import WechatOutlineIcon from "@rsuite/icons/WechatOutline";
import HistoryIcon from "@rsuite/icons/History";
import ToolsIcon from "@rsuite/icons/Tools";
import TaskIcon from "@rsuite/icons/Task";
import EventDetailIcon from "@rsuite/icons/EventDetail";
import "./sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  sidebarChildMenuClose,
  sidebarChildMenuOpen,
} from "../../store/silce/sidebarSlice";
import { Button } from "react-bootstrap";
import OffIcon from "@rsuite/icons/Off";
import { setManageLevel } from "../../store/silce/levelSlice";
import { EmpHomeData } from "../../store/silce/Employee";

const SideBarEmp = ({ show1 ,toggleButton, setToggleButton }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isHovering = useSelector((state) => state.sidebar.isHovering);
  const employeeDetailss = useSelector(
    (state) => state?.employee?.Employee?.data?.permissions
  );
const busdata = useSelector((state) => state?.dashboard?.home?.data?.bussiness?.[0]?.id );
localStorage.setItem("selecteoption", busdata); 

  const employeeData = employeeDetailss && employeeDetailss?.map((item)=>item.name)

  useEffect(() => {
    dispatch(EmpHomeData());

  }, []);

    const menuData = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      path: "/admin/dashboard",
      Top: "0px",
    },
    {
      text: " My Profile",
      icon: <UserInfoIcon />,
      Top: "100px",
      path: "/My-Profile",
      // children: [
      //     { text: 'Staff List', path: '/stafflist' },
      //     { text: 'Staff Map', path: '/stafflist' },
      // ],
    },
    {
      text: "Attendance",
      icon: <CalendarIcon />,
      Top: "180px",
      path: "/Emp/Attandance",
      // children: [
      //     { text: 'Today Logs', path: '/Updatetype' },
      //     // { text: 'Report Attendance', path: '/Attendance' },
      //     // { text: 'Selfie Attendance', path: '/SelfiAttendance' },
      // ],
    },
    {
      text: "Holiday",
      icon: <HistoryIcon />,
      Top: "200px",
      path: "/Emp/Hoilday",
      // children: [
      //     { text: 'All Holidays', path: '/Holidays' },
      // ],
    },
    {
      text: "Reimbursement",
      icon: <WechatOutlineIcon />,
      Top: "260px",
      path: "/Emp/Reimbursement",
      // children: [
      //     { text: 'Payroll', path: '/Salary' },
      //     { text: 'Reimbursement', path: '/Reimbursement' },
      // ],
    },
    {
      text: "Notice",
      icon: <WechatOutlineIcon />,
      Top: "320px",
      path: "/Emp/Notice",
    },
    {
      text: "Leave",
      icon: <EventDetailIcon />,
      Top: "380px",
      path: "/Emp/Leave",
    },
    {
      text: "Salary",
      icon: <TaskIcon />,
      Top: "450px",
      path: '/Emp/Salary',
      // children: [
      //     { text: 'Assigne Role', path: '/Role' },
      //     // { text: 'Level', path: '/level-management',changePage:()=>{dispatch(setManageLevel(false))} },
      // ],
    },
    {
      text: "Bank Details",
      icon: <TaskIcon />,
      Top: "450px",
      path: '/bank',
      // children: [
      //     { text: 'Assigne Role', path: '/Role' },
      //     // { text: 'Level', path: '/level-management',changePage:()=>{dispatch(setManageLevel(false))} },
      // ],
    },
    {
      text: "Social Link",
      icon: <TaskIcon />,
      Top: "450px",
      path: '/socal',
      // children: [
      //     { text: 'Assigne Role', path: '/Role' },
      //     // { text: 'Level', path: '/level-management',changePage:()=>{dispatch(setManageLevel(false))} },
      // ],
    },
    {
        text: 'Library',
        icon: <ToolsIcon />,
        Top: '450px',
        path: '/Emp/Librarydoc/1',
        children: [
            { text: 'Document', path: '/Emp/Librarydoc/1' },
            { text: 'Video', path: '/Emp/Librarydoc/2' },
        ],
    },
    // {
    //     text: 'Settings',
    //     icon: <ToolsIcon />,
    //     Top: '500px',
    //     path: '/Auto-Punch-Out',
    //     children: [
    //         { text: 'Auto Punch Out', path: '/Auto-Punch-Out' },
    //         { text: 'Salary-Setting', path: '/Salary-Setting' },
    //     ],
    // },
  ];

  

  const menuData1 = [
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      path: "/admin/dashboard",
      Top: "0px",
    },
    employeeData?.includes('admin_attendance') && {
      text: "Attendance",
      icon: <DashboardIcon />,
      path: "/Updatetypes",
      Top: "0px",
    },
    employeeData?.includes('employees') && {
      text: "Employees",
      icon: <DashboardIcon />,
      path: "/stafflists",
      Top: "0px",
    },
     employeeData?.includes('admin_selfie_attendance') && {
      text: "Selfies",
      icon: <DashboardIcon />,
      path: "/SelfiAttendances",
      Top: "0px",
      
    },
     employeeData?.includes('send_notice') && {
      text: 'Notice',
      icon: <WechatOutlineIcon />,
      Top: '320px',
      path: '/Noticehistorys',
    },
    employeeData?.includes('admin_leave_request') && {
      text: "Leaves",
      icon: <DashboardIcon />,
      path: "/LeaveHistorys",
      Top: "0px",
    },
    employeeData?.includes('admin_salary_page')  && {
      text: "Salary",
      icon: <DashboardIcon />,
      path: "/Salary-Settings",
      Top: "0px",
    },
     employeeData?.includes('business') && {
      text: 'Business',
      icon: <ProjectIcon />,
      Top: '10px',
      path: '/Businesslists',
    },
    // employeeData?.includes('salary_settings') && {
    //   text: "Salary Settings",
    //   icon: <DashboardIcon />,
    //   path: "/Salary-Settings",
    //   Top: "0px",
    // },
    employeeData?.includes('employee_locations')  && {
      text: "Locations",
      icon: <DashboardIcon />,
      // path: "/admin",
      Top: "0px",
    },
    employeeData?.includes('reporting_manager') && {
      text: 'Staff',
      icon: <UserInfoIcon />,
      Top: '100px',
      // path: '/stafflist',
    },
    


   
  ];

  const filteredMenuData = menuData1.filter(item => item);


  const [openMenus, setOpenMenus] = React.useState({});
  const [hoveredItem, setHoveredItem] = React.useState(null);
  const toggleMenu = (text) => {
    // Close all other open parent menus
    const updatedMenus = {};
    Object.keys(openMenus).forEach((key) => {
      updatedMenus[key] = false;
    });
    setOpenMenus({
      ...updatedMenus,
      [text]: !openMenus[text],
    });
  };
  const isParentActive = (parent) => {
    if (!parent.children) return false;
    return parent.children.some((child) => location.pathname === child.path);
  };

  const handleMouseOn = (item) => {
  
    dispatch(sidebarChildMenuOpen({ data: item, value: true }));
    setHoveredItem(item);
  };
 

  const renderSideMenus = () => {
    return (
      <>
        {!show1 && (
          <List
            sx={{
              backgroundColor: "#2b303b",
              border: "none",
              borderRadius: "0px 10px 10px 0px",
              position: "absolute",
              top: hoveredItem?.Top,
              zIndex: 2,
              left: "58px",
            }}
          >
            <ListItemButton>
              <ListItemText
                onClick={() => {
                  navigate(hoveredItem?.path);
                }}
                sx={{ color: "white" }}
                primary={hoveredItem?.text}
              />
            </ListItemButton>
            {/* {data?.children && ( */}
            <List component="div" disablePadding>
              {hoveredItem?.children?.map((child) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={child.text}
                  onClick={() => {
                    navigate(child.path);
                    dispatch(sidebarChildMenuClose(false));
                  }}
                >
                  <ListItemText
                    primary={
                      <ul className="nested-list">
                        <li
                          style={{
                            color:
                              location.pathname === child.path
                                ? "#a5ceea"
                                : "#6e7684",
                          }}
                        >
                          {child.text}
                        </li>
                      </ul>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
            {/* )} */}
            {/* <Button><OffIcon/></Button> */}
          </List>
        )}
      </>
    );
  };
  const logoutApp = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  return (
    <>
      <aside className={`sidebar simplebar ${show1 ? "show1" : null}`}>
        <nav className="navt">
          <div>
            <Link to="/home" className="nav-logo">
              {/* <i className={`fas fa-home-alt nav-logo-icon`}></i> */}
              <Avatar src={logo} />
              <span className="nav-logo-name">QR Staff</span>
            </Link>
            {/* <ListCollapse title="My List" items={['Item 1', 'Item 2', 'Item 3']} /> */}
            <List>
              { toggleButton !== true ? menuData?.map((item, i) => {
                return (
                  <ListItem
                    key={i}
                    onClick={() => {
                      renderSideMenus(item);
                    }}
                    onMouseOver={() => {
                      handleMouseOn(item);
                    }}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: show1 ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        navigate(item.path);
                        handleMouseOn(item);
                        renderSideMenus(item);
                        toggleMenu(item.text);
                      }}

                      // onMouseOver={() => {
                      //   handleMouseOver(item)
                      //   renderSideMenus(item)
                      // }}
                      // onMouseOut={handleMouseOut}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: show1 ? 3 : "auto",
                          justifyContent: "center",
                          padding: "10px",
                          backgroundColor:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "#019aff"
                              : "#1e2229",
                          color:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "white"
                              : "#6e7684",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span className="nav-link-name">{item.text}</span>
                        }
                        sx={{
                          opacity: show1 ? 1 : 0,
                          color:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "white"
                              : "#6e7684",
                        }}
                      />
                      {item.children && show1 && (
                        <div>
                          {openMenus[item.text] ? (
                            <ExpandLess
                              sx={{ color: "gray" }}
                              onClick={() => toggleMenu(item.text)}
                            />
                          ) : (
                            <ExpandMore
                              sx={{ color: "gray" }}
                              onClick={() => toggleMenu(item.text)}
                            />
                          )}
                        </div>
                      )}
                    </ListItemButton>
                    {show1 && (
                      <>
                        {item.children && (
                          <Collapse
                            in={openMenus[item.text]}
                            timeout="auto"
                            unmountOnExit
                            style={{
                              backgroundColor:
                                location.pathname === item.path ||
                                isParentActive(item)
                                  ? "#1e2229"
                                  : "#1e2229",
                            }}
                          >
                            <List component="div" disablePadding>
                              {item.children.map((child) => (
                                <ListItemButton
                                  sx={{ pl: 4 }}
                                  key={child.text}
                                  onClick={() => {
                                    navigate(child.path);
                                  }}
                                >
                                  <ListItemText
                                    onClick={child?.changePage}
                                    primary={
                                      <ul className="nested-list">
                                        <li
                                          style={{
                                            color:
                                              location.pathname === child.path
                                                ? "#a5ceea"
                                                : "#6e7684",
                                          }}
                                        >
                                          {child.text}
                                        </li>
                                      </ul>
                                    }
                                  />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </>
                    )}
                  </ListItem>
                );
              }) : filteredMenuData?.map((item, i) => {
                return (
                  <ListItem
                    key={i}
                    onClick={() => {
                      renderSideMenus(item);
                    }}
                    onMouseOver={() => {
                      handleMouseOn(item);
                    }}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: show1 ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        navigate(item.path);
                        handleMouseOn(item);
                        renderSideMenus(item);
                        toggleMenu(item.text);
                      }}

                      // onMouseOver={() => {
                      //   handleMouseOver(item)
                      //   renderSideMenus(item)
                      // }}
                      // onMouseOut={handleMouseOut}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: show1 ? 3 : "auto",
                          justifyContent: "center",
                          padding: "10px",
                          backgroundColor:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "#019aff"
                              : "#1e2229",
                          color:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "white"
                              : "#6e7684",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <span className="nav-link-name">{item.text}</span>
                        }
                        sx={{
                          opacity: show1 ? 1 : 0,
                          color:
                            location.pathname === item.path ||
                            isParentActive(item)
                              ? "white"
                              : "#6e7684",
                        }}
                      />
                      {item.children && show1 && (
                        <div>
                          {openMenus[item.text] ? (
                            <ExpandLess
                              sx={{ color: "gray" }}
                              onClick={() => toggleMenu(item.text)}
                            />
                          ) : (
                            <ExpandMore
                              sx={{ color: "gray" }}
                              onClick={() => toggleMenu(item.text)}
                            />
                          )}
                        </div>
                      )}
                    </ListItemButton>
                    {show1 && (
                      <>
                        {item.children && (
                          <Collapse
                            in={openMenus[item.text]}
                            timeout="auto"
                            unmountOnExit
                            style={{
                              backgroundColor:
                                location.pathname === item.path ||
                                isParentActive(item)
                                  ? "#1e2229"
                                  : "#1e2229",
                            }}
                          >
                            <List component="div" disablePadding>
                              {item.children.map((child) => (
                                <ListItemButton
                                  sx={{ pl: 4 }}
                                  key={child.text}
                                  onClick={() => {
                                    navigate(child.path);
                                  }}
                                >
                                  <ListItemText
                                    onClick={child?.changePage}
                                    primary={
                                      <ul className="nested-list">
                                        <li
                                          style={{
                                            color:
                                              location.pathname === child.path
                                                ? "#a5ceea"
                                                : "#6e7684",
                                          }}
                                        >
                                          {child.text}
                                        </li>
                                      </ul>
                                    }
                                  />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </>
                    )}
                  </ListItem>
                );
              })}
            </List>
            {show1 ? (
              <div className="p-2">
                <Button
                  style={{ backgroundColor: "#18285a", border: "none" }}
                  onClick={() => logoutApp()}
                  className="w-100"
                >
                  Logout
                </Button>
              </div>
            ) : (
              <div className="p-2">
                <Button
                  style={{
                    backgroundColor: "#1e2229",
                    border: "none",
                    color: "gray",
                  }}
                  onClick={() => logoutApp()}
                >
                  <OffIcon />
                </Button>
              </div>
            )}
          </div>
        </nav>
      </aside>
      <div style={{ color: "black", marginTop: "6rem", marginLeft: "5rem" }}>
        {isHovering && renderSideMenus()}
      </div>
    </>
  );
};

export default SideBarEmp;
