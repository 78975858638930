import { configureStore } from "@reduxjs/toolkit";
import bussiness from "./silce/bussiness";
import attendanceSlce from "./silce/attendance";
import holidaySlice from "./silce/holidaySlice";
import modalSlice from "./silce/modalSlice";
import sidebarSlice from "./silce/sidebarSlice";
import dashboardSlice from "./silce/dashboardSlice";
import staffSlice from "./silce/staffSlice";
import levelSlice from "./silce/levelSlice";
import roll from "./silce/roll";
import payroll from './silce/payroll'
import leave from "./silce/leave";
import autopunchout from "./silce/autopunchout";
import home from "./silce/home";
import Employeedata from './silce/Employee';
import selectOptionReducer from './silce/selectOptionSlice';
export const store = configureStore({
    reducer: {
        Business: bussiness,
        holiday:holidaySlice,
        modal: modalSlice,
        sidebar:sidebarSlice,
        dashboard:dashboardSlice,
        staff:staffSlice,
        attendance:attendanceSlce,
        level:levelSlice,
        roll:roll,
        payroll:payroll,
        leave:leave,
        autopunchout:autopunchout,
        home:home,
        employee:Employeedata,
        selectOption: selectOptionReducer,
    }      

})