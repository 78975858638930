import axiosInstance from "../lib/axiosInstance";
const Role = class {
  
  
    async AssignRole(attendance ) {
       
        return await axiosInstance.post("/roles.php", attendance);
    }
    async Rolelist(attendance ) {
       
        return await axiosInstance.post("/roles.php", attendance);
    }

    async Addnew(attendance ) {

        return await axiosInstance.post("/roles.php", attendance);
    }
   async DeleteRole(attendance ) {
        
        return await axiosInstance.post("/roles.php", attendance);
    }
   
}

export default new Role();