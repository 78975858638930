import React,{useState} from 'react';
import { Row, Col } from 'react-bootstrap';

function MediaPreview({ added_by, added_on, doc_url, docs_name, document_type_name }) {
    
        const url = doc_url;
        // console.log("url", url)
        const parts = url.split('.');
        const fileExtension = parts[parts.length - 1].toLowerCase();
        let mediaType 
        if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'gif') {
            mediaType = `<img src=${doc_url} alt=${docs_name}  style="height: 100px; width: 100px;"/>`;
           // return <img src={doc_url} alt={docs_name}  style={{ height: '10px', width: '10px' }}/>;
        } else if (fileExtension === 'mp4' || fileExtension === 'avi' || fileExtension === 'mov') {
            mediaType = `  <video controls width="100" height="100">
            <source src=${doc_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>`;
          
        } else if (fileExtension === 'pdf') {
            mediaType = `
            <iframe
            src=${doc_url}
            width="100"
            height="100"
            title="name"
            target="_blank"
            rel="noopener noreferrer"
         
          ></iframe>

      `
     
        } else {
            mediaType = 'unknown';
        }
        // console.log(`Media type: ${mediaType}`);
        

    
    return (
        <div>
            <Row className='mt-5 mb-5'>

                <Col sm={2}><b>{docs_name}</b></Col>
                <Col sm={3}>
                
                <div dangerouslySetInnerHTML={{__html: mediaType}}  />
               
                </Col>
                <Col sm={2}><b>{document_type_name}</b>
                </Col>
                <Col sm={2}><b>
                    {added_by}
                </b></Col>
                <Col sm={2}>
                    <div>
                        <button><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg></button>
                        <button><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16" style={{ marginLeft: '20px' }}>
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg></button>
                    </div>
                </Col>
            </Row>
            {/* <h3>{`${userName} (${userId})`}</h3>
            {renderMediaPreview()} */}
        </div>
    );
}

export default MediaPreview;
