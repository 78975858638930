import React, { useEffect, useState } from "react";
import DataTable from "../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeStatics,
  HomeStatus,
  fetchAllNotice,
  fetchAttendance,
  fetchHome,
  sendStatusToAPI,
  setFiltertoday,
  setStatusData,
  undoAttendance,
} from "../store/silce/dashboardSlice";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import "./internalPage.css";
import { Row, Col, Card, Button } from "react-bootstrap";
import BarChartComponent from "./Graphs/DashboardChart";
import moment from "moment";
import { BsPersonFillCheck } from "react-icons/bs";
import { BsPersonFillDash } from "react-icons/bs";
import { BsPersonFillSlash } from "react-icons/bs";
import { BsPersonFillAdd } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import Loader from "../components/Common/Loading";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const selecteoption = localStorage.getItem("selecteoption");
  const dispatch = useDispatch();
  const busid = useSelector((state) => state?.selectOption?.value);
  const attendance = useSelector((state) => state?.dashboard?.attendance);
 
  const homestatus = useSelector((state) => state.dashboard.emp);
  const loading = useSelector((state) => state.dashboard.loading);
  const [search, setSearch] = useState("");

  const filtertoday = useSelector((state) => state.dashboard.filtertoday);
  const notice = useSelector((state) => state.dashboard.notice);
  const navLoading = useSelector((state) => state.dashboard.navLoading);
  const loadHome = () => {
    dispatch(fetchAttendance());
  };
  // window.location.reload()
  useEffect(() => {
    dispatch(HomeStatics());
  }, [busid]);

  const statusMapping = {
    Absent: {
      backgroundColor: "rgb(202 90 90)",
      label: "Absent",
    },
    in: {
      backgroundColor: "rgb(81 151 81)",
      label: "Punch In",
    },
    out: {
      backgroundColor: "rgb(204, 204, 204)",
      label: "Punch Out",
    },
    Leave: {
      backgroundColor: "rgb(95 95 202)",
      label: "On Leave",
    },
    overtime: {
      backgroundColor: "rgb(255, 165, 0)",
      label: "Overtime",
    },
    "Week off": {
      backgroundColor: "rgb(128, 128, 128)",
      label: "Week off",
    },
  };

  const getLabel = (row) => {
    const status = statusMapping[row.marked_status] || {};
    return status.label || "";
  };
  const handleAbsentClick = () => {
    sendDataToAPI1("absent");
  };
  const handlePresentClick = () => {
    sendDataToAPI1("present");
  };
  const handleIntClick = () => {
    sendDataToAPI1("leave");
  };
  const navigate = useNavigate();
  const linkdata = () => {
    dispatch(setStatusData(true));
    navigate("/Updatetype");
  };
  const senddata = useSelector((state) => state.dashboard);
  const sendDataToAPI1 = (status) => {
    dispatch(HomeStatus({ status: status, linkdata: linkdata() }));
  };
  const attendance_status = [
    {
      id: 1,
      total: attendance?.absent ? attendance?.absent : "0",
      title: "Absent",
      icon: <BsPersonFillCheck />,
      onClick: () => {
        handleAbsentClick();
      },
    },
    {
      id: 2,
      total: attendance?.present ? attendance?.present : "0",
      title: "Present",
      icon: <BsPersonFillDash />,
      onClick: () => {
        handlePresentClick();
      },
    },
    {
      id: 3,
      total: attendance?.paid_holiday ? attendance?.paid_holiday : "0",
      title: "On Leave",
      icon: <BsPersonFillSlash />,
      onClick: () => {
        handleIntClick("leave");
      },
    },
    {
      id: 4,
      total: attendance?.late_entry ? attendance?.late_entry : "0",
      title: "Late Entry",
      icon: <BsPersonFillAdd />,
    },
    {
      id: 5,
      total: attendance?.total_count ? attendance?.total_count : "0",
      title: "Total Staff",
      icon: <IoIosPeople />,
    },
  ];

  const columns = [
    {
      name: "S.No",

      cell: (row, index) => index + 1,
      width: "100px",
      nextOrder: "true",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      width: "270px",
    },
    {
      name: "Message",
      selector: (row) => row.msg,
      width: "270px",
    },
    {
      name: "Notice Time",
      selector: (row) => row.notice_time,
      cell: (row) => {
        return <div>{moment(row?.notice_time).format("DD-MM-YYYY")}</div>;
      },
      width: "270px",
    },
    {
      name: "Action",
      selector: (row) => row.pro_img,
      cell: (row) => {
        return <TableDropdown isView={true} />;
      },
      width: "100px",
    },
  ];

  useEffect(() => {
    loadHome();
  }, [busid]);
  useEffect(() => {
    dispatch(fetchHome());
  }, [busid]);
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("get_notice_history", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    dispatch(fetchAllNotice());
  }, [busid]);
  

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);
  return (
    <div>
      <Row xs={1} md={4} sm={3} xl={5} className="g-4">
        {attendance_status.map((ele, idx) => (
          <Col key={idx}>
            <Card
              style={{
                border: "none",
                boxShadow: "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                cursor: "pointer",
              }}
              className="text-center"
              onClick={ele.onClick}
            >
              <Card.Body>
                <div
                  className={`d-flex align-items-center flex-wrap ${
                    screenWidth > 1425 ? "gap-5" : "gap-3"
                  } ${screenWidth < 1225 && "flex-column"}`}
                >
                  <div>
                    <Button
                      style={{ backgroundColor: "#18285a", border: "none" }}
                    >
                      {ele.icon}
                    </Button>
                  </div>
                  <div className="text-justify">
                    <Card.Title>
                      {navLoading ? <Loader /> : ele.total}
                    </Card.Title>
                    <Card.Text>{ele.title}</Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div className=" mt-4 mb-4">
        <BarChartComponent />
      </div>
      {/* <DataTable
                title={"Notice"}
                columns={columns}
                data={!search ? notice?.data : filtertoday}
                page={"dashboard"}
               
                filterdData={filtertoday}
                isSubHeader={true}
                loading={loading}
                searchData={notice?.data}
                setData={setFiltertoday}
                keys={['title', 'msg']}
            /> */}
    </div>
  );
};

export default Dashboard;
