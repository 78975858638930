import { createSlice } from '@reduxjs/toolkit';

export const selectOptionSlice = createSlice({
  name: 'selectOption',
  initialState: {
    value: localStorage.getItem('selecteoption') || 'default', 
  },
  reducers: {
    updateSelectOption: (state, action) => {
      state.value = action.payload;
      localStorage.setItem('selecteoption', action.payload);
    },
  },
});
export const { updateSelectOption } = selectOptionSlice.actions;
export const selectOptionSelector = (state) => state.selectOption.value;
export default selectOptionSlice.reducer;
