import React, { useEffect } from "react";
import "../Aseset/css/sidenav.css";
import { Container, Row, Card, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "../store/silce/dashboardSlice";
import moment from "moment";
import DataTable from "../components/Tables/Tables";
import { Tooltip } from '@mui/material';
const Notification = () => {
  const dispatch = useDispatch();
  const notificationlist = useSelector(
    (state) => state?.dashboard?.notification?.notification
  );
  useEffect(() => {
    dispatch(notification());
  }, []);

  
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "4%",
      center: "center",
    },
    {
      name: "title",
      selector: (row) => row.
      title,
      // center:'center',
    },
    {
      name: "Message",
      selector: (row) => `${row.msg}`,
      cell : (row) =>{
        return(<>
            <div  style={{
                height: "17px",
                overflow: "hidden",
                // "text-overflow": "ellipsis",
                color:"black"
              }}>  
           <Tooltip title ={row.msg} placement="top">
        <span >{`${row.msg}..`}</span>
        </Tooltip>
        </div>
        </>)
    },
      center: "center",


    },
    {
      name: "Date",
      selector: (row) => row.
      date
      ,
      center: "center",
 
    },
   
  ];

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="mt-1 mb-3">
              <DataTable
                // loading={loading}
                columns={columns}
                data={notificationlist}
                title={"Notification List"}
                page={"notificationList"}
                searchData={notificationlist}
                // setData={setFilterData}
                keys={["name", "contact", "business_sector_name"]}
                // filterdData={filterdData}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Notification;
