import axiosInstance from "../lib/axiosInstance";
const Salarydetails = class {
  
  
    async salarymonth(salmonth ) {
       
        return await axiosInstance.post("/salary.php", salmonth);
    }
    async Advancepayemp(advemppay ) {
       
        return await axiosInstance.post("/salary_tran.php", advemppay);
    }
    async Removepay(payremove) {
       
        return await axiosInstance.post("/salary_tran.php", payremove);
    }

    async RemoveAll(payremove) {
       
        return await axiosInstance.post("/employee.php", payremove);
    }


    async Trancation(Trancationlist ) {
       
        return await axiosInstance.post("/employee.php", Trancationlist);
    }
    
    async Removetrans(trasn){
        return await axiosInstance.post("/employee.php", trasn);
    }
    async Viewpaid(paid){
        return await axiosInstance.post("/salary.php", paid);   
    }
    async Markpaid(paid){
        return await axiosInstance.post("/salary.php", paid);   
    }
    async detailsal(detailsal){
        return await axiosInstance.post("/salary.php", detailsal);   
    }
    async Reimbursement(res){
        return await axiosInstance.post("/reimbursement.php", res);
    }
    async Updatereimbursement(res){
        return await axiosInstance.post("/reimbursement.php", res);
    }
    async updateattend(update){
        return await axiosInstance.post("/attendance.php", update);
    }
    async Addadvance(update){
        return await axiosInstance.post("/salary_tran.php", update);
    }
    async jobhistory(update){
        return await axiosInstance.post("/employment_history.php", update);
    } 
    async veiwjobhiss(update){
        return await axiosInstance.post("/employment_history.php", update);
    }

    async veiwjobhistorybyid(update){
        return await axiosInstance.post("/employment_history.php", update);
    }
    async veiwjobhistorybyidDelete(update){
        return await axiosInstance.post("/employment_history.php", update);
    }
    
    async veiwsalary (data){
        return await axiosInstance.post("/salary.php", data);
    }


    
}

export default new Salarydetails();