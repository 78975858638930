import React, { useState, useEffect } from "react";
import EmphomeModal from "../Modal/Emphome.Modal";
import DataTable from "../../components/Tables/Tables";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CommonModal from "../../components/Common/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditModal,
  closeModal,
  openEditModal,
  openModal,
} from "../../store/silce/modalSlice";
import {
  ApplyLeave,
  ApplyMutiLeave,
  GetALLLeave,
} from "../../store/silce/Employee";
import CommonEditModal from "../../components/Common/CommonEditModal";
import moment from "moment";
import { helper } from "../../lib/helper";
const Empleavehistory = () => {
  const dispatch = useDispatch();

  const [Empnotice, setEmpnotice] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showModal = useSelector((state) => state.modal.showModal);
  const [currentTitle, setCurrentTitle] = useState("Apply-Leave");
  const currentPage = useSelector((state) => state.modal.currentPage);
  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const Empleavaehistory = useSelector((state) => state?.employee?.GetALLLeave);
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
    },
    {
      name: "Applied on Date",
      selector: (row) => row.applied_on_date,
      cell: (row) => {
        return (
          <center> {moment(row.applied_on_date).format("DD/MM/YYYY")}</center>
        );
      },
    },
    {
      name: "No of Leave Days",
      selector: (row) => row.leave_days,
    },
    {
      name: "Leave From",
      selector: (row) => row.leave_from,
      cell: (row) => {
        return <center>{moment(row.leave_from).format("DD/MM/YYYY")}</center>;
      },
    },
    {
      name: "Leave Reason",
      selector: (row) => row.leave_reason,
    },
    {
      name: "Leave Type",
      selector: (row) => row.leave_type,
    },
    {
      name: "Leave To",
      selector: (row) => row.leave_to,
      cell: (row) => {
        return <center> {moment(row.leave_to).format("DD/MM/YYYY")}</center>;
      },
    },
    {
      name: "Comment",
      selector: (row) => row.comment,
    },
    {
      name: "Status",
      width: "10%",
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status === "Approved") {
          return (
            <>
              <center>
                {" "}
                <p
                  className="text-capitalize mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    fontSize: "12px",
                    color: "rgb(81 151 81)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
            </>
          );
        } else if (row.status === "Denied") {
          return (
            <>
              <center>
                {" "}
                <p
                  className="text-capitalize  mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    fontSize: "12px  ",
                    color: "rgb(202 90 90)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        } else if (row.status === "Pending") {
          return (
            <>
              {/* <div
                      className="w-100"
                      style={{
                        backgroundColor: " rgb(255, 165, 0)",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                        height: "3vh",
                      }}
                    > */}
              <center>
                {" "}
                <p
                  className="text-capitalize  mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    color: "rgb(255, 165, 0)",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        }
      },
    },
  ];
  useEffect(() => {
    dispatch(GetALLLeave());
  }, []);
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const from_date = formData.get("from_date");
    const to_date = formData.get("to_date");
    const reason = formData.get("reason");
    const leave_type = formData.get("leave_type");

    if (leave_type === "casual_leave") {
      // Get the current date
      const currentDate = new Date();

      // Create a new Date object for the "from_date"
      const fromDateObj = new Date(from_date);

      // Calculate the date 7 days from the current date
      const sevenDaysAfter = new Date(currentDate);
      sevenDaysAfter.setDate(currentDate.getDate() + 7);

      // Check if the "from_date" is at least 7 days in the future compared to the current date
      if (fromDateObj <= sevenDaysAfter) {
        // Show an error or alert if the condition is not met
        helper.sweetalert.toast1(
          "Casual Leave must be applied at least 7 days in advance."
        );
        return;
      }
    }

    // If the condition is met, continue with dispatching actions
    dispatch(ApplyLeave({ event }));
    dispatch(GetALLLeave());
    dispatch(closeModal());
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const from_date = formData.get("from_date");
    const to_date = formData.get("to_date");
    const reason = formData.get("reason");
    const leave_type = formData.get("leave_type");

    // If casual_leave is selected, validate the date range
    if (leave_type === "casual_leave") {
      // Create new Date objects for the "from_date" and "to_date"
      const fromDateObj = new Date(from_date);
      const toDateObj = new Date(to_date);

      // Calculate the difference in days between from_date and to_date
      const differenceInTime = toDateObj.getTime() - fromDateObj.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      // Check if the difference is less than 7 days
      if (differenceInDays < 7) {
        // Show an error or alert if the duration of leave is less than 7 days
        helper.sweetalert.toast1(
          "Casual leave duration must be at least 7 days."
        );
        return; // Prevent form submission
      }
    }

    // Continue with dispatching actions if the conditions are met
    dispatch(ApplyLeave({ event }));
    dispatch(closeEditModal());
    dispatch(GetALLLeave());
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            <div className="mt-0 mb-5">
              <span style={{ marginLeft: "20px", fontSize: "22px" }}>
                <b>Leave History</b>
              </span>
              <button
                className="btn"
                onClick={() => {
                  dispatch(openModal("Apply-Leave"));
                  setCurrentTitle("Apply 1 Day Leave");
                }}
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                  marginRight: "15px",
                }}
              >
                Apply 1 Day Leave
              </button>
              <button
                className="btn"
                onClick={() => {
                  dispatch(openEditModal("Apply-Leavse"));
                  setCurrentTitle("Apply Multiple Days Leave");
                }}
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                  marginRight: "15px",
                }}
              >
                Apply Multiple Days Leave
              </button>
            </div>
            <DataTable columns={columns} data={Empleavaehistory} pagination />
          </Col>
        </Row>
      </Container>

      <CommonModal
        onSubmit={handleSubmit}
        show={showModal}
        onHide={() => {
          dispatch(closeModal());
        }}
        title={currentTitle}
        name={currentPage}
      />

      <CommonEditModal
        onSubmit={handleSubmit1}
        show={showEditModal}
        onHide={() => {
          dispatch(closeEditModal());
        }}
        title={currentTitle}
        name={currentPage}
      />
    </div>
  );
};

export default Empleavehistory;
