import React from 'react'
import {Container ,Row ,Col ,Card} from 'react-bootstrap';
import TextField from '@mui/material/TextField';

const General = () => {
  return (
    <div>
      
      <Card>
        <Container>
            <Row>
                <Col className='m-5'>
                    <span style={{fontSize:'20px'}}>General</span>
                    <hr />
                    <p style={{fontSize:'15px',color:'blue'}}> Company info</p>
                </Col>
            </Row>
            <Row>
              <Col sm={3}>Company name</Col>
              <Col sm={7}><TextField id="outlined-basic" label="PayDay" variant="outlined" fullWidth size="small" /></Col>

            </Row>
            <Row>
              <Col sm={3}>Company Logo</Col>
              <Col sm={7}><TextField id="outlined-basic" label="PayDay" variant="outlined" fullWidth size="small" /></Col>

            </Row>
        </Container>
      </Card>
    </div>
  )
}

export default General
