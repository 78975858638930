import React, { useState, useEffect, } from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';
import EmphomeModal from '../Modal/Emphome.Modal';
import Loader from "../../components/Common/Loading";
const Emphome = () => {

    const [EmpDetails, setEmpDetails] = useState();
    const [loading,setloading] = useState(true)
    useEffect(() => {
        const formdata = new FormData();
        formdata.append("get_home_emp", localStorage.getItem("token"));
        EmphomeModal.Empdetails(formdata).then((res) => {
          
            setEmpDetails(res.data)
            setloading(false)

        }).catch((error) => {
            
        })
    }, []);

    const cardData = [
        { key: 'absent', title: 'Absent', colorClass: 'text-danger' },
        { key: 'present_count', title: 'Present', colorClass: 'text-primary' },
        { key: 'leave_count', title: 'On Leave' },
    ];

    return (
        <>
            <div>
                <Container fluid >
                    <Row className='mt-5 mb-2'>
                        {cardData.map((data, index) => (
                            <Col key={index}>
                                <Card>
                                    <p className={`text-center mt-1 ${data.colorClass}`} style={{ fontSize: "35px", fontWeight: "900" }}>{EmpDetails?.[data.key]}</p>
                                    <h5 className='text-center mt-1 mb-2' style={{ fontWeight: "900" }}>{loading ? <Loader/> :data.title}</h5>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Emphome;