import React from 'react'
import { DropdownButton, Form, InputGroup  } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import { MDBCol, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardHeader,  } from 'mdb-react-ui-kit';
const Persoanltab = ({data}) => {
    const navigate = useNavigate()
    const params = useParams()
    console.log(params , 'paramsshiavm')
    return (
<>
      <MDBCard className="mt-3">
<MDBCardHeader>
<span style={{ marginTop: 'px', marginLeft: '10px', fontSize: '19px' }}>
                                <b style={{ paddingLeft: '' }}> Persoanl Details</b></span>
</MDBCardHeader>
<MDBCardBody>
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Frist Name</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                                placeholder={`${data?.prefix} ${ data?.name}`}       

                // placeholder={data?.name}
                    contentEditable='false'
                    readOnly
                    name='emp_name'
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Middle Name</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                placeholder={data?.middle_name}      
                    contentEditable='false'
                    readOnly
                    name='emp_name'
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                    required
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Last Name</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                placeholder={data?.last_name}      
                    contentEditable='false'
                    readOnly
                    name='emp_name'
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                    required
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Father Name</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                placeholder={data?.fathers_name}      
                    contentEditable='false'
                    readOnly
                    name='emp_name'
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                    required
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Mothers Name</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                placeholder={data?.mothers_name}      
                    contentEditable='false'
                    readOnly
                    name='emp_name'
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                    required
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Gender</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                placeholder={data?.gender}      
                    contentEditable='false'
                    readOnly
                    name='gender'
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                    required
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Blood Group</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                placeholder={data?.blood_group}      
                    contentEditable='false'
                    readOnly
                    
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                    required
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Marital Status</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' 
                placeholder={`${data?.marital_status} ${ data?.marriage_dt}`? `${data?.marital_status} ${ data?.marriage_dt}` : 'NA'}       
                    contentEditable='false'
                    readOnly
                    
                    // onChange={handleInputChange}
                    // value={empprivew?.name}
                    required
                    disabled
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Email ID</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.email_id}
                    name='email_id'
                    disabled
                    required
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Date of Birth</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type="text" id="" placeholder={data?.dob ? data?.dob: ''}
                    disabled
                    className="text-muted"
                    style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
                />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Mobile Number</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <InputGroup className="mb-3">
                    <DropdownButton
                        variant="outline-secondary"
                        title="+91"
                        id="input-group-dropdown-1"
                    >
                    </DropdownButton>
                    <Form.Control aria-label="Text input with dropdown button" placeholder={data?.cont}
                        // defaultValue={empprivew?.cont}
                        name='cont'
                        readOnly
                        disabled
                        // onChange={handleInputChange}
                         />
                </InputGroup>
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Nearest Police Station</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <InputGroup className="mb-3">
                  
                    
                    <Form.Control aria-label="Text input with dropdown button"
                     placeholder={data?.nearest_police_station}
                        // defaultValue={empprivew?.cont}
                        name='cont'
                        readOnly
                        disabled
                        // onChange={handleInputChange}
                         />
                </InputGroup>
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Idenificattion Mark</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <InputGroup className="mb-3">
                  
                    <Form.Control aria-label="Text input with dropdown button"
                     placeholder={data?.identification_mark}
                        // defaultValue={empprivew?.cont}
                        name='cont'
                        readOnly
                        disabled
                        // onChange={handleInputChange}
                         />
                </InputGroup>
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    
       <button className='mt-2'  onClick={() => navigate(`/Update/${params.id}`)} style={{float:"right" , backgroundColor:"rgb(24, 40, 90)" , color:'white ', padding:'6px', borderRadius:"4px" , borderStyle:'none' }}>  Edit Profile</button>

   </MDBCardBody>



</MDBCard>

      
        </>
    )
}

export default Persoanltab