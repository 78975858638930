import React from 'react';
import { Container, Row, Col, Card, } from 'react-bootstrap';

const Payrun   = () => {

    return (
        <div>
            <Card style={{height:'110vh'}}>
                <Container>
                    <Row>
                        <Col>
                            <h4 className='m-4'>Payrun And Badge</h4>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <div className='top-card-div' style={{}}>
                            <span className='card-icon' style={{marginLeft:'18px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                                    <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                                </svg>
                            </span>
                            <span className='card-text'>Why Payrun & Badge?</span>
                        </div>
                        <Col>
                            <Card style={{ backgroundColor: 'rgb(255 250 234)',marginLeft:'18px'}} className='mt-2'>
                                <ol type="1" style={{paddingTop:'25px' ,marginLeft:'15px' ,marginBottom:'25px'}}>
                                    <li><p>By default all payrun and beneficiary badges is set from default Setting</p></li>
                                    <li><p>You can individually update or change these values from the edit option.</p></li>
                                </ol>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={4}>
                            <div className="image-contact" style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                </svg>
                                    <p style={{ fontSize: '15px', marginLeft: '10px' }}>Payrun period</p>
                            </div>
                        </Col>
                        <Col sm={3}>
                           <div style={{lineHeight:'10px'}}>
                                <p><b>Monthly</b></p>
                                <p>consider type - None</p>
                           </div>
                        </Col>
                        <Col sm={2}></Col>
                        <Col sm={3}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16" style={{marginLeft:'40px'}}>
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={4}>
                            <div className="image-contact" style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                </svg>
                                <p style={{ fontSize: '15px', marginLeft: '10px' }}>Allowance</p>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <p>Bonus</p>
                           
                        </Col>
                        <Col sm={2}><p>10 %</p></Col>
                        <Col sm={3}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16" style={{marginLeft:'40px'}}>
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </Col>
                    </Row>
                    <Row className='mt-5 mb-5'>
                        <Col sm={4}>
                            <div className="image-contact" style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-dash-lg" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
                                </svg>
                                <p style={{ fontSize: '15px', marginLeft: '10px' }}>Deduction</p>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <p>Tax</p>
                           
                        </Col>
                        <Col sm={2}><p>2 %</p></Col>
                        <Col sm={3}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="blue" class="bi bi-pencil-square" viewBox="0 0 16 16" style={{marginLeft:'40px'}}>
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                        </Col>
                    </Row>
                </Container>
            </Card>

        </div>
    )
}

export default Payrun  
