import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import { useParams } from 'react-router-dom';
// import { CSVLink } from 'react-csv'
import { Container, Row, Col, Card } from 'react-bootstrap';

import AttModal from '../Model/Att.modal';

const Monthattendance = () => {
    const [mntatt, setMntatt] = useState([]);
    const params = useParams();
    const [formData, setFormData] = useState({
        at_year: '',
        at_month: '',
    });
    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = (event) => {

        event.preventDefault();
        const form = new FormData(event.target);

        form.append("emp_id", params?.id);
        form.append("get_emp_detail_att", localStorage.getItem("token"));
        form.append("bus_id", localStorage.getItem("selecteoption"));


        AttModal.monthatthisorty(form).then((res) => {
            setMntatt(res.data)
          
        }
        ).catch((error) => {
           
        })
    }

    return (
        <>
           
                    <Container fluid >
                        <Row>
                            <Col>
                                <h3 className="fs-4 mb-3">Attendance  By Month</h3>
                                <Card style={{ height: '100px' }}>
                                    <form onSubmit={handleSubmit}>

                                        <label for="at_month" style={{ paddingLeft: '40px', paddingTop: '40px' }}>Months</label>

                                        <select name="at_month" id="at_month" style={{ width: "150px", marginLeft: '15px', paddingLeft: "50px" }}>
                                            <option value="01 ">January </option>
                                            <option value="02">February</option>
                                            <option value="03 ">March </option>
                                            <option value="04 ">April </option>
                                            <option value="05">May</option>
                                            <option value="06 ">June </option>
                                            <option value="07 ">July </option>
                                            <option value="08">August</option>
                                            <option value="09 ">September </option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>

                                        </select>

                                        <label for="at_year" style={{ paddingLeft: '40px', paddingTop: '40px' }}>year</label>

                                        <select name="at_yeary" id="at_year " style={{ width: "150px", marginLeft: '15px', paddingLeft: "50px" }}>
                                            <option value="2022 ">2022 </option>
                                            <option value="2023">2023</option>

                                        </select>
                                        <button type="submit" style={{
                                            width: "116px",
                                            borderRadius: " 25px",
                                            padding: "4px",
                                            backgroundColor: "#888894",
                                            color: "white",
                                            position: "relative",
                                            float: "right",
                                            marginRight: '40px', marginTop: '40px'

                                        }} > submit</button>
                                    </form>
                                </Card>


                            </Col>


                        </Row>
                        <Row>     {
                            mntatt?.att && mntatt?.att.map((todaylist,i) =>
                                <Col key={i} sm={3} className='mt-3'>

                                    <Card>
                                        <center>
                                            <p> <b>Day:</b>{todaylist.day}</p>
                                            <p><b>Attedance</b>:{todaylist.attendance_type}</p>
                                            <p><b>punch in</b>{todaylist.punch_in}</p>
                                            <p><b>punch out</b>{todaylist.punch_out}</p>
                                            <p><b>shift time</b>{todaylist.shift_hour}</p>
                                            <p><b>shift dif</b>{todaylist.shift_difference}</p>
                                        </center>
                                    </Card>

                                </Col>
                            )
                        }
                        </Row>
                    </Container>
             
        </>
    )
}
export default Monthattendance;