import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';

import DataTable from '../components/Tables/Tables';
// import { CSVLink } from 'react-csv'
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import profileModel from '../Model/profile.model';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAttendance, setFilterData } from '../store/silce/attendance';
import AttModal from '../Model/Att.modal';
import { helper } from '../lib/helper';
import moment from 'moment';
import Loader from '../components/Common/Loading';
import { BsFiletypeExe } from 'react-icons/bs';
import { IoIosCloudDownload } from 'react-icons/io';
import download from '../Aseset/image/download.gif'

const Attendance = () => {

    const attendanceList = useSelector((state) => state.attendance.attendanceList)
    const loading = useSelector((state) => state.attendance.loading)
    const filterdData = useSelector((state) => state.attendance.filterdData)
    const dispatch = useDispatch()
    const busid = useSelector(state => state?.selectOption?.value)
    const columns = [
        {
            name: 'S.No',
            cell: (row, index) => index + 1,
            width: '80px',
            center: 'center'

        },
        {
            name: 'Staff ID',
            selector: (row) => row.emp_code ? row.emp_code : 'NA',
            center: 'center'
        },
        {
            name: ' Staff Name',
            cell: row => {
                return (
                    <div style={{
                        "height": "20px",
                        "overflow": 'hidden',
                        "text-overflow": "ellipsis"
                    }}
                    >
                        {row.name ? <Link to={`/attendancehistory/${row.emp_id}`}>{row.name } {row.last_name}</Link> : 'NA'}
                    </div>


                )
            },
            // center: 'center'

        },
        {
            name: 'Designation',
            selector: (row) => row.designation ? row.designation : 'NA',
            // center: 'center'

        },
        {
            name: 'In Time',
            selector: (row) => row.in_tim ? row.in_tim : 'NA',
            center: 'center'
        },
        {
            name: 'Out Time',
            selector: (row) => row.out_tim ? row.out_tim : 'NA',
            center: 'center'
        },
        {
            name: 'Comment',
            selector: (row) => row.out_comment ? row.out_comment : 'NA',
            center: 'center'
        },



    ]
    useEffect(() => {
        dispatch(fetchAllAttendance())
    }, [busid]);
    const customStyles = {
        headCells: {
            style: {
                fontWeight: '900'
            },
        },
    };
    const [salslips, setSaslip] = useState([])
    const openLinkInNewTabs = () => {
        if (salslips) {
            window.open(salslips, '_blank');
        }
    };
    const [salslipss, setSaslips] = useState([])
    const openLinkInNewTab = () => {
        if (salslipss) {
            window.open(salslipss, '_blank');
        }
    };
    const [salslipssMOnth, setsalslipssMOnth] = useState([])
    const openLinkInNewMOnth = () => {
        if (salslipssMOnth) {
            window.open(salslipssMOnth, '_blank');
        }
    };
    
    const [showLoader, setShowLoader] = useState(true);

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const handleMonthChange = (event) => {
        const value = event.target.value;
        setSelectedMonth(value.substring(5, 7));
        setSelectedYear(value.substring(0, 4));
    };
    const handleSubmited = () => {
        const form = new FormData();
        form.append("get_monthly_attendance_report", localStorage.getItem("token"));
        form.append("bus_id", localStorage.getItem("selecteoption"));
        form.append("report_format", "xlsx")
        form.append("report_month", selectedMonth)
        form.append("report_year", selectedYear)
        AttModal.genmonth(form).then((res) => {
            if (res.data.is_error == false) {
                setShowDownloadMonth(true)
                setShowDownloadMonthloader(false)
                setsalslipssMOnth(res?.data?.data?.pdfUrl)
            }


        }
        ).catch((error) => {
            
        })
    }
    const [selectedDate, setSelectedDate] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const handleDateChange = (event) => {
        const dateValue = event.target.value;
        setSelectedDate(dateValue);
        setShowDropdown(true);
    };
    const [ShowDownload, setShowDownload] = useState(false);
    const [ShowDownloadMonth, setShowDownloadMonth] = useState(false);
    const [ShowDownloadMonthloader, setShowDownloadMonthloader] = useState(true);
    const [selectedDatee, setSelectedDatee] = useState('');
    const handleDateChangee = (event) => {
        const dateValue = event.target.value;
        setSelectedDatee(dateValue);
    };

    const handleSubmite = () => {
        const form = new FormData();
        const fromDate = moment(selectedDatee).format("DD-MM-YYYY");
        const toDate = moment(selectedDate).format("DD-MM-YYYY");

        form.append("get_monthly_attendance_report_bydate", localStorage.getItem("token"));
        form.append("bus_id", localStorage.getItem("selecteoption"));
        form.append("report_format", "xlsx")
        form.append("fromDate", fromDate)
        form.append("toDate", toDate)
        AttModal.gencustom(form).then((res) => {
            // helper.sweetalert.toast( 'Plaese wait until your attedance is download ', 'success')
            if (res.data.is_error == false) {
                setShowDownload(true)
                setSaslips(res?.data?.data?.pdfUrl)
            }


        }
        ).catch((error) => {
           
        })
    }
    return (
        <>
            <div>
                <Container fluid >
                    <Row>
                        <Col>
                            <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className=' mt-2 mb-2'>
                         
                                <div className="row mb-2">
                                    <div className="col-sm-5 col-xs-12 mt-2 ">
                                        <div >
                                        <span style={{ textAlign: 'center', fontWeight: "700", marginLeft: '15px' }} > Report</span>
                                  
                                        
                                  <input type="month"
                                      onChange={handleMonthChange} style={{ width: '170px', borderRadius: '8px', height: "40px" , marginLeft:'20px' }} />
                                  {selectedMonth && (
                                      <>
                                          <button type='submit' onClick={handleSubmited} style={{ backgroundColor: '#18285A', borderRadius: '8px', padding: '8px', color: '#fff', marginLeft: '10px' }}><BsFiletypeExe /></button>
                                         {ShowDownloadMonthloader && ( <span>Please Wait</span>)}
                                          {ShowDownloadMonth && (
                                              <button onClick={openLinkInNewMOnth} className='btn' style={{ backgroundColor: '#18285a', color: 'white', marginLeft: '10px', }}><IoIosCloudDownload /></button>
                                          )}
                                      </>
                                    )
                                   }
                                        </div> 
                                    </div>
                                    <div className="col-sm-7 col-xs-12 d-flex mt-2">
                                        <div > 
                                            <span style={{ marginRight: '10px' }}>From </span>
                                            <input name="" id="" style={{ width: '30%', borderRadius: '8px', marginRight: '10px', height: "40px" }}
                                                type="date"
                                                value={selectedDatee}
                                                onChange={handleDateChangee}
                                            />
                                            <span style={{ marginRight: '10px' }}>To</span><input type="date" name="" id=""
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                style={{ width: '30%', borderRadius: '8px', height: "40px", marginRight: '10px' }} />
                                            {showDropdown && (
                                                <> <button style={{ backgroundColor: '#18285A', borderRadius: '8px', padding: '8px', color: '#fff' }} onClick={handleSubmite}><BsFiletypeExe /></button>
                                                {ShowDownloadMonthloader && <img src={download}  style={{width:"50px",height:'50px'}} />}
                                                </>      
                                            )}
                                            {ShowDownload && (<button onClick={openLinkInNewTab} className='btn   ' style={{ backgroundColor: '#18285a', color: 'white', marginLeft: '10px' }}> <IoIosCloudDownload /></button>
                                            )}
                                        </div>
                                    </div>

                                </div>


                                <DataTable
                                    title='Today Attendance List'
                                    columns={columns}
                                    loading={loading}
                                    data={attendanceList}
                                    keys={['cont', 'designation', 'name']}
                                    filterdData={filterdData}
                                    setData={setFilterData}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight='470px'
                                    selectableRowsHighlight
                                    highlightOnHover
                                    customStyles={customStyles}
                                />
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Attendance;