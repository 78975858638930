// import React, { useEffect, useState } from "react";
// import { Badge, Container, InputGroup } from "react-bootstrap";
// import { ListGroup } from "react-bootstrap";
// import { Modal, Button, Form, Row, Col } from "react-bootstrap";
// import businessModel from "../../Model/business.model";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   clearSelectedData,
//   fetchAllBusinesses,
// } from "../../store/silce/bussiness";
// import { BiCalendar } from "react-icons/bi";
// import { BsCalendar2Date } from "react-icons/bs";
// import {
//   fetchData,
//   setFilterData,
//   clearroleSelectedData,
//   fetchDataAllPermissionsRollList,
// } from "../../store/silce/roll";
// import { TextField } from "@mui/material";

// const CommonModal = ({
//   show,
//   onHide,
//   name,
//   title,
//   onChange,
//   onSubmit,
//   pageData,
//   displaybus,
//   size,
//   showComponents,
//   setShowComponents,
//   handleItemClick,
//   selectedItems,
// }) => {
// 
//   const dispatch = useDispatch();
//   const selectedData = useSelector((state) => {
//     return state.Business.selectedData;
//   });
//   const sectorData = useSelector((state) => {
//     return state.Business.sector;
//   });
//   const DepartmentData = useSelector((state) => {
//     return state.Business.Alldepartments;
//   });

//   const onCloseModal = () => {
//     dispatch(clearSelectedData());
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSubmit();
//   };
//   console.log("sectordata", sectorData);

//   // Add role Function

//   const permissions = useSelector((state) => {
//     return state?.roll?.fetchDataAllPermissionsRollList?.data?.permissions;
//   });

//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");

//   const handleFromDateChange = (e) => {
//     const newFromDate = e.target.value;
//     setFromDate(newFromDate);
//     setToDate(newFromDate); // Set the value of "toDate" to match "fromDate"
//   };

//   const handleToDateChange = (e) => {
//     setToDate(e.target.value);
//   };

//   // const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(fetchDataAllPermissionsRollList());
//   }, []);

//   const handleToggle = (index) => {
//     setShowComponents((prevShowComponents) => {
//       const newShowComponents = [...prevShowComponents];
//       newShowComponents[index] = !newShowComponents[index];
//       console.log(`Checkbox ${index} is now:`, newShowComponents[index]);
//       return newShowComponents;
//     });
//   };

//   const generateCheckboxes = (index, permission) => {
//     return showComponents[index] ? (
//       <div key={index}>
//         <Row>
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-between",
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//               }}
//             >
//               <span style={{ paddingLeft: "10px" }}>View</span>
//               <span style={{ paddingLeft: "10px" }}>Add</span>
//               <span style={{ paddingLeft: "10px" }}>Edit</span>
//               <span style={{ paddingLeft: "10px" }}>Delete</span>
//             </div>
//             <div style={{ marginRight: "20px" }}>
//               <Form.Check
//                 style={{ fontSize: "25px" }}
//                 type="checkbox"
//                 name={`${permission.perName}[view]`}
//                 id=""
//                 value="true"
//                 // value="true"
//                 // style={{ paddingLeft: "10px" }}
//               />{" "}
//               <Form.Check
//                 style={{ fontSize: "25px" }}
//                 type="checkbox"
//                 name={`${permission.perName}[add]`}
//                 id=""
//                 value="true"

//                 // style={{ marginLeft: "10px" }}
//               />{" "}
//               <Form.Check
//                 style={{ fontSize: "25px" }}
//                 type="checkbox"
//                 name={`${permission.perName}[edit]`}
//                 id=""
//                 value="true"
//                 // style={{ marginLeft: "10px" }}
//               />{" "}
//               <Form.Check
//                 style={{ fontSize: "25px" }}
//                 type="checkbox"
//                 name={`${permission.perName}[delete]`}
//                 id=""
//                 value="true"
//                 // style={{ marginLeft: "10px" }}
//               />{" "}
//             </div>
//           </div>
//         </Row>
//       </div>
//     ) : null;
//   };

//   const ViewEmployeeSuperviderList = useSelector(
//     (state) => state?.staff?.ViewEmployeeSuperviderStaff?.emp
//   );
//   console.log(pageData, "pratibha");
//   const renderFields = () => {
//     switch (name) {
//       case "add-business":
//         return (
//           <Form onSubmit={onSubmit}>
//             <div className="row">
//               <div className="col-md-6">
//                 <input
//                   type="hidden"
//                   name="bus_id"
//                   id=""
//                   value={pageData?.outid}
//                 />
//                 <Form.Group controlId="nameOfBusiness">
//                   <Form.Label className="mt-2">Name of Business*</Form.Label>
//                   <Form.Control
//                     onChange={onChange}
//                     name="cl_name"
//                     type="text"
//                     placeholder="Enter Name of Business"
//                     required
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="businessSector">
//                   <Form.Label className="mt-3">Business Sector*</Form.Label>
//                   <select
//                     name="bus_sector"
//                     required
//                     // value={pageData?.formData?.bus_sector}
//                     onChange={onChange}
//                     id=""
//                     style={{ height: "35px", width: "100%" }}
//                   >
//                     {sectorData &&
//                       sectorData.map((sectorlist) => (
//                         <option
//                           value={sectorlist.id}
//                           name="bus_sector"
//                           style={{ height: "120px" }}
//                         >
//                           {sectorlist.name}
//                         </option>
//                       ))}
//                   </select>
//                 </Form.Group>
//                 <Form.Group controlId="mobileNumber">
//                   <Form.Label className="mt-3">Mobile Number*</Form.Label>
//                   <InputGroup>
//                     <InputGroup.Text
//                       id="basic-addon1"
//                       style={{ height: "35px", fontSize: "16px" }}
//                     >
//                       +91
//                     </InputGroup.Text>
//                     <Form.Control
//                       required
//                       type="tel" // Use type "tel" for phone numbers
//                       style={{ height: "35px", appearance: "textfield" }}
//                       name="cl_cont"
//                       placeholder="Enter Your Mobile Number"
//                       pattern="[0-9]*" // Use a regex pattern to allow only numeric input
//                       // value={num}
//                       maxLength={10}
//                       defaultValue={pageData?.displaybus?.whatsapp}
//                       onChange={onChange}
//                     />
//                   </InputGroup>
//                 </Form.Group>
//                 <Form.Group controlId="email">
//                   <Form.Label className="mt-3"> Email ID</Form.Label>
//                   <Form.Control
//                     name="cl_email1"
//                     placeholder="Enter Your Email ID"
//                     type="email"
//                     onChange={onChange}
//                     defaultValue={pageData?.displaybus?.email?.[0]?.id}
//                     required
//                     // style={{ fontSize: "20px" }}
//                   />
//                 </Form.Group>
//               </div>
//               <div className="col-md-6">
//                 <div className="mb-3">
//                   How do you want to calculate your staff's Salary?
//                 </div>
//                 <ListGroup>
//                   <ListGroup.Item
//                     as="li"
//                     className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
//                     onClick={() => handleItemClick("monthlyRadio")}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <div className="ms-2 me-auto">
//                       <div className="fw-bold">
//                         {" "}
//                         <BiCalendar /> Monthly basis
//                       </div>
//                       Salary will be calculated for a month
//                     </div>
//                     <Form.Check
//                       id="monthlyRadio"
//                       onChange={onChange}
//                       type="radio"
//                       name="salary_basis"
//                       value="monthly"
//                       aria-label="option 1"
//                       style={{ fontSize: "20px" }}
//                     />
//                   </ListGroup.Item>
//                   <ListGroup.Item
//                     as="li"
//                     className="list-group-item list-group-item-action d-flex justify-content-between align-items-start mt-2"
//                     onClick={() => handleItemClick("30daysRadio")}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <div className="ms-2 me-auto">
//                       <div className="fw-bold">
//                         <BsCalendar2Date /> 30 Days basis
//                       </div>
//                       Salary will be calculated for 30 days (Doesn't matter how
//                       many days in the current month)
//                     </div>
//                     <Form.Check
//                       id="30daysRadio"
//                       onChange={onChange}
//                       name="salary_basis"
//                       value="30 days"
//                       type="radio"
//                       aria-label="option 2"
//                     />
//                   </ListGroup.Item>
//                 </ListGroup>
//               </div>
//             </div>
//             <div className="mt-4">
//               <Button
//                 style={{ float: "left" }}
//                 className=""
//                 variant="secondary"
//                 onClick={() => {
//                   onHide();
//                   onCloseModal();
//                 }}
//               >
//                 Close
//               </Button>
//               <Button
//                 style={{ float: "right", borderStyle: "none" }}
//                 className="btn submitButtons "
//                 type="submit"
//                 variant="primary"
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//         );
//       case "add-department":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Form.Group controlId="mobileNumber">
//               <Form.Label>Department*</Form.Label>
//               <Form.Control
//                 onChange={onChange}
//                 name="name"
//                 className="form-group required"
//                 type="text"
//                 placeholder="Enter Department Name"
//                 required
//               />
//             </Form.Group>

//             <div className="mt-4">
//               <Button
//                 className=""
//                 variant="secondary"
//                 onClick={onHide}
//                 style={{ float: "left" }}
//               >
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons"
//                 type="submit"
//                 variant="primary"
//                 style={{ float: "right" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//         );

//       case "approved-leave":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Form.Group controlId="mobileNumber">
//               <Form.Label>comment *</Form.Label>
//               <Form.Control
//                 onChange={onChange}
//                 name="leave_comment"
//                 className="form-group required"
//                 type="text"
//                 placeholder="Comment"
//                 required
//               />
//             </Form.Group>

//             <div className="mt-4">
//               <Button
//                 className=""
//                 variant="secondary"
//                 onClick={onHide}
//                 style={{ float: "left" }}
//               >
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons"
//                 type="submit"
//                 variant="primary"
//                 style={{ float: "right" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//         );
//       case "add-new-holiday":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Form.Group controlId="nameOfHoliday">
//               <Form.Label>Name of Holiday*</Form.Label>
//               <Form.Control
//                 value={pageData?.formDataNew?.nameholiday}
//                 onChange={onChange}
//                 name="name"
//                 type="text"
//                 placeholder="Enter Name of Holiday"
//               />
//             </Form.Group>
//             <Form.Group controlId="date">
//               <Form.Label className="mt-3">Date</Form.Label>
//               <Form.Control
//                 type="date"
//                 formate
//                 name=""
//                 value={pageData?.input}
//                 onInput={(e) => pageData?.setInput(e.target.value)}
//               />
//             </Form.Group>
//             {/* Add more fields for the holiday page */}
//             <Modal.Footer>
//               <Button className="w-25" variant="secondary" onClick={onHide}>
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons w-25"
//                 type="submit"
//                 variant="primary"
//               >
//                 Submit
//               </Button>
//             </Modal.Footer>
//           </Form>
//         );
//       case "add-sub-department":
//         return (
//           <>
//           <Form onSubmit={onSubmit}>

//             <Form.Group controlId="department">
//               <Form.Label className="mt-2">Department *</Form.Label>
//               <Form.Group controlId="businessSector">
//                 <Form.Select
//                   name="department_id"
//                   id=""required
//                   style={{ height: "35px", width: "100%" }}
//                 >
//                   <option disabled selected>Select Department</option>
//                   {DepartmentData &&
//                     DepartmentData.map((sectorlist) => (
//                       <option
//                         value={sectorlist.department_id}
//                         name="department_id"
//                         style={{ height: "120px" }}
//                       >
//                         {sectorlist.department_name}
//                       </option>
//                     ))}
//                   {/* {pageData?.dropdrownElement && pageData?.dropdrownElement.map((DepartmentData) =>
//                                         <option value={DepartmentData.id} name='bus_sector' defaul
//                                             onChange={onChange} style={{ height: "120px", }}>{DepartmentData.name}</option>
//                                     )} */}
//                 </Form.Select>
//               </Form.Group>
//             </Form.Group>
//             <Form.Group controlId="cl_name" className="mt-2">
//               <Form.Label>Name Of Sub Department *</Form.Label>
//               <Form.Control
//                 onChange={onChange}
//                 name="name"
//                 className="form-group required"
//                 type="text"
//                 placeholder="Name Of  Sub Department"
//                 required
//               />
//             </Form.Group>
//             <div className="mt-4">
//               <Button
//                 style={{ float: "left" }}
//                 className=""
//                 variant="secondary"
//                 onClick={() => {
//                   onHide();
//                   onCloseModal();
//                 }}
//               >
//                 Close
//               </Button>
//               <Button
//                 style={{ float: "right" ,color:'white'}}
//                 className="submitButtons "
//                 type="submit"
//                 variant=""
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//           </>
//         );
//       case "add-designation":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Form.Group controlId="cl_name">
//               <Form.Label>Name Of Designation *</Form.Label>
//               <Form.Control
//                 onChange={onChange}
//                 name="cl_name"
//                 className="form-group required"
//                 type="text"
//                 placeholder="Name Of Designation"
//                 required
//               />
//             </Form.Group>
//             <Form.Group controlId="department">
//               <Form.Label className="mt-2">Name of Department *</Form.Label>
//               <Form.Group controlId="businessSector">
//                 <select
//                   name="bus_sector"
//                   id=""
//                   style={{ height: "35px", width: "100%" }}
//                 >
//                   {pageData?.dropdrownElement &&
//                     pageData?.dropdrownElement.map((sectorlist) => (
//                       <option
//                         value={sectorlist.id}
//                         name="bus_sector"
//                         defaul
//                         onChange={onChange}
//                         style={{ height: "120px" }}
//                       >
//                         {sectorlist.name}
//                       </option>
//                     ))}
//                 </select>
//               </Form.Group>
//             </Form.Group>
//             <Form.Group controlId="department">
//               <Form.Label className="mt-2">Name of Sub Department *</Form.Label>
//               <Form.Group controlId="businessSector">
//                 <select
//                   name="bus_sector"
//                   id=""
//                   style={{ height: "35px", width: "100%" }}
//                 >
//                   {pageData?.dropdrownElement &&
//                     pageData?.dropdrownElement.map((sectorlist) => (
//                       <option
//                         value={sectorlist.id}
//                         name="bus_sector"
//                         defaul
//                         onChange={onChange}
//                         style={{ height: "120px" }}
//                       >
//                         {sectorlist.name}
//                       </option>
//                     ))}
//                 </select>
//               </Form.Group>
//             </Form.Group>
//             <div className="mt-4">
//               <Button
//                 className="w-25"
//                 variant="secondary"
//                 onClick={onHide}
//                 style={{ float: "left" }}
//               >
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons w-25"
//                 type="submit"
//                 style={{ float: "right" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//         );
//       case "punch-in":
//         return (
//           <>
//             <input type="hidden" name="" id="" />
//             <input
//               type="time"
//               name=""
//               formate
//               id={`userTimes${pageData?.row?.emp_id}`}
//             />
//             <div className="mt-4">
//               <Button
//                 className="w-25"
//                 variant="secondary"
//                 onClick={onHide}
//                 style={{ float: "left" }}
//               >
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons w-25"
//                 type="submit"
//                 style={{ float: "right" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </>
//         );
//       case "punch-out":
//         return (
//           <>
//             <Form onSubmit={onSubmit}>
//               <input type="hidden" name="" id="" />
//               <input
//                 type="time"
//                 name=""
//                 formate
//                 id={`userTime${pageData?.row?.emp_id}`}
//               />
//               <div className="mt-4">
//                 <Button
//                   className="w-25"
//                   variant="secondary"
//                   onClick={onHide}
//                   style={{ float: "left" }}
//                 >
//                   Close
//                 </Button>
//                 <Button
//                   className="submitButtons w-25"
//                   type="submit"
//                   style={{ float: "right" }}
//                 >
//                   Submit
//                 </Button>
//               </div>
//             </Form>
//           </>
//         );
//       case "add-level-management":
//         return (
//           <>
//             <Form onSubmit={onSubmit}>
//               <div>Level Name</div>
//               <div class="input-group mb-3 mt-2">
//                 <input
//                   name="name"
//                   onChange={onChange}
//                   type="text"
//                   class="form-control"
//                   placeholder="Level Name"
//                   aria-label="Username"
//                   aria-describedby="basic-addon1"
//                 />
//               </div>
//               <div className="col-md-12">
//                 <div
//                   className="mb-3"
//                   style={{ fontSize: "16px", fontWeight: "bold" }}
//                 >
//                   Permission for this level
//                 </div>
//                 {/* <ListGroup> */}
//                 <div
//                   style={{
//                     alignItems: "center",
//                     display: "flex",
//                     justifyContent: "space-between",
//                     flexDirection: "row",
//                   }}
//                 >
//                   <div className="ms-2 me-auto">
//                     <p className="mt-1">
//                       {" "}
//                       Can accept or deny leave requests of staff
//                     </p>
//                   </div>

//                   <div>
//                     <Form.Check
//                       style={{ fontSize: "25px" }}
//                       onChange={onChange}
//                       type="checkbox"
//                       name="accept_permission"
//                       value="accept"
//                       id='accept'
//                       aria-label="option 1"
//                     />
//                   </div>
//                 </div>

//                 <div className="d-flex gap-2 mt-3 mb-3">
//                   <div className="ms-2 me-auto">
//                     <p className="mt-1">Can forward leave requests to admin</p>
//                   </div>
//                   <Form.Check
//                     style={{ fontSize: "25px" }}
//                     onChange={onChange}
//                     name="accept_permission"
//                     value="forward"
//                     type="checkbox"
//                     id='forward'
//                     aria-label="option 2"
//                   />
//                 </div>
//                 {/* </ListGroup> */}
//               </div>
//               <div className="mt-4">
//                 <Button
//                   className="w-25"
//                   variant="secondary"
//                   onClick={onHide}
//                   style={{ float: "left" }}
//                 >
//                   Close
//                 </Button>
//                 <Button
//                   className="submitButtons w-25"
//                   type="submit"
//                   style={{ float: "right" }}
//                 >
//                   Submit
//                 </Button>
//               </div>
//             </Form>
//           </>
//         );
//       case "add-role":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Form.Control
//               type="text"
//               placeholder="Enter Role Name"
//               name="role_name"
//               onChange={onChange}
//             />
//             <hr />
//             <p style={{ fontSize: "20px" }}>Permissions</p>
//             {permissions &&
//               permissions?.map((permission, index) => (
//                 <div key={index} className="mt-4" style={{ cursor: "pointer" }}>
//                   <span
//                     style={{
//                       fontSize: "15px",
//                       position: "relative",
//                       top: "-5px",
//                     }}
//                   >
//                     <b>{permission.show_name}</b>
//                   </span>
//                   <div className="toggle">
//                     <input
//                       type="checkbox"
//                       className="checkbox"
//                       checked={showComponents[index]}
//                       onChange={() => handleToggle(index)}
//                       id={`toggle-${index}`}
//                     />
//                     <label htmlFor={`toggle-${index}`}></label>
//                   </div>

//                   {generateCheckboxes(index, permission)}
//                 </div>
//               ))}
//             <div className="mt-4">
//               <Button
//                 className="w-25"
//                 variant="secondary"
//                 onClick={onHide}
//                 style={{ float: "left" }}
//               >
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons w-25"
//                 type="submit"
//                 style={{ float: "right" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//         );
//       case "add-addressnew":
//         return (
//           <>
//             <Form onSubmit={onSubmit}>
//               <div>
//                 {/* <input
//                   type="hidden"
//                   name="bus_id"
//                   id=""
//                   value={pageData?.outid}
//                 /> */}
//                 <Form.Group>
//                   {/* <Form.Control onChange={onChange} name='address_line1' required type="text" placeholder="Enter Name of Address Line 1" /> */}
//                 </Form.Group>
//                 <Row className="">
//                   <Col sm={4}>
//                     <Form.Label className="mt-2"> Address Line 1</Form.Label>
//                   </Col>
//                   <Col sm={8}>
//                     {/* <input type="text"
//                                             style={{ height: '40px' }}
//                                             name="address_line1" id=""
//                                             required
//                                             placeholder=' Address Line 1'
//                                         // onChange={handleChange}
//                                         /> */}
//                     <Form.Control
//                       // onChange={onChange}
//                       name="address_line1"
//                       required
//                       type="text"
//                       placeholder="Enter Name of"
//                     />
//                   </Col>
//                 </Row>
//                 <Row className="mt-2">
//                   <Col sm={4}>
//                     <Form.Label className="mt-2"> Address Line 2</Form.Label>
//                   </Col>
//                   <Col sm={8}>
//                     <Form.Control
//                       type="text"
//                       style={{ height: "40px" }}
//                       placeholder=" Address Line 2"
//                       required
//                       name="address_line2"
//                       id=""
//                     />
//                   </Col>
//                 </Row>
//                 <Row className="mt-2">
//                   <Col sm={4}>
//                     <Form.Label className="mt-2"> City</Form.Label>
//                   </Col>
//                   <Col sm={8}>
//                     <Form.Control
//                       type="text"
//                       style={{ height: "40px" }}
//                       placeholder="Enter Your city"
//                       required
//                       // onChange={handleChange}
//                       name="city"
//                       id=""
//                     />
//                   </Col>
//                 </Row>
//                 <Row className="mt-2">
//                   <Col sm={4}>
//                     <Form.Label className="mt-2"> State</Form.Label>
//                   </Col>
//                   <Col sm={8}>
//                     <Form.Control
//                       type="text"
//                       style={{ height: "40px" }}
//                       name="state"
//                       id=""
//                       required
//                       // onChange={handleChange}
//                       placeholder="Enter Your State"
//                     />
//                   </Col>
//                 </Row>
//                 <Row className="mt-2">
//                   <Col sm={4}>
//                     <Form.Label className="mt-2"> Pincode</Form.Label>
//                   </Col>
//                   <Col sm={8}>
//                     <Form.Control
//                       type="number"
//                       style={{ height: "40px" }}
//                       name="pincode"
//                       id=""
//                       required
//                       // onChange={handleChange}
//                       placeholder="Enter Your Pincode"
//                     />
//                   </Col>
//                 </Row>

//         case "add-addressnew":
//           return (
//             <>
//               <Form onSubmit={onSubmit}>
//                 <div>
//                   {/* <input
//                     type="hidden"
//                     name="bus_id"
//                     id=""
//                     value={pageData?.outid}
//                   /> */}
//                   <Form.Group controlId="nameOfBusiness">
//                     {/* <Form.Control onChange={onChange} name='address_line1' required type="text" placeholder="Enter Name of Address Line 1" /> */}
//                   </Form.Group>
//                   <Row className="">
//                     <Col sm={4}>
//                       <Form.Label className="mt-2"> Address Line 1</Form.Label>
//                     </Col>
//                     <Col sm={8}>
//                       <Form.Control
//                         // onChange={onChange}
//                         name="address_line1"
//                         required
//                         type="text"
//                         placeholder="Enter Name of Address Line 1"
//                       />
//                     </Col>
//                   </Row>
//                   <Row className="mt-2">
//                     <Col sm={4}>
//                       <Form.Label className="mt-2"> Address Line 2</Form.Label>
//                     </Col>
//                     <Col sm={8}>
//                       <Form.Control
//                         type="text"
//                         style={{ height: "40px" }}
//                         placeholder=" Address Line 2"
//                         required
//                         name="address_line2"
//                         id=""
//                       />
//                     </Col>
//                   </Row>
//                   <Row className="mt-2">
//                     <Col sm={4}>
//                       <Form.Label className="mt-2"> City</Form.Label>
//                     </Col>
//                     <Col sm={8}>
//                       <Form.Control
//                         type="text"
//                         style={{ height: "40px" }}
//                         placeholder="Enter Your city"
//                         required
//                         // onChange={handleChange}
//                         name="city"
//                         id=""
//                       />
//                     </Col>
//                   </Row>
//                   <Row className="mt-2">
//                     <Col sm={4}>
//                       <Form.Label className="mt-2"> State</Form.Label>
//                     </Col>
//                     <Col sm={8}>
//                       <Form.Control
//                         type="text"
//                         style={{ height: "40px" }}
//                         name="state"
//                         id=""
//                         required
//                         // onChange={handleChange}
//                         placeholder="Enter Your State"
//                       />
//                     </Col>
//                   </Row>
//                   <Row className="mt-2">
//                     <Col sm={4}>
//                       <Form.Label className="mt-2"> Pincode</Form.Label>
//                     </Col>
//                     <Col sm={8}>
//                       <Form.Control
//                         type="number"
//                         style={{ height: "40px" }}
//                         name="pincode"
//                         id=""
//                         required
//                         // onChange={handleChange}
//                         placeholder="Enter Your Pincode"
//                       />
//                     </Col>
//                   </Row>

//                   <div className="mt-4">
//                     <Button
//                       className=""
//                       style={{
//                         backgroundColor: "gray",
//                         color: "white",
//                         borderStyle: "none",
//                       }}
//                       // onClick={() => handleClose()}
//                     >
//                       {" "}
//                       Close
//                     </Button>
//                     <Button
//                       type="submit"
//                       style={{
//                         backgroundColor: "#18285a  ",
//                         borderStyle: "none",
//                         float: "right",
//                       }}
//                       className=""
//                     >
//                       {" "}
//                       Submit
//                     </Button>
//                   </div>

//                   {/* <button className='btn btn-primary mb-4 mt-4' style={{ float: "right" }} type='submit'>Submit</button> */}
//                 </div>
//               </Form>
//             </>
//           );
//       case "add-leave":
//         return (
//           <Form onSubmit={onSubmit}>
//             <div className="row">
//               <div className="col-md-6">
//                 <input
//                   type="hidden"
//                   name="emp_id"
//                   id=""
//                   value={pageData?.outid}
//                 />
//                 <Form.Group controlId="nameOfBusiness">
//                   <Form.Label className="mt-2">Name of Leave Name*</Form.Label>
//                   <Form.Control
//                     onChange={onChange}
//                     name="cl_name"
//                     type="text"
//                     placeholder="Enter Name of Leave"
//                   />
//                 </Form.Group>
//                 {/* <Form.Group controlId="businessSector">
//                                     <Form.Label className="mt-3">Business Sector*</Form.Label>
//                                     <select name="bus_sector"
//                                         // value={pageData?.formData?.bus_sector}
//                                         onChange={onChange}
//                                         id="" style={{ height: '35px', width: "100%" }}>
//                                         {sectorData && sectorData.map((sectorlist) =>
//                                             <option value={sectorlist.id} name='bus_sector'
//                                                  style={{ height: "120px", }}>{sectorlist.name}</option>
//                                         )}
//                                     </select>
//                                 </Form.Group> */}
//                 <Form.Group controlId="mobileNumber">
//                   <Form.Label className="mt-3">Leave Count*</Form.Label>
//                   <InputGroup>
//                     <Form.Control
//                       type="tel" // Use type "tel" for phone numbers
//                       style={{ height: "35px", appearance: "textfield" }}
//                       name="cl_cont"
//                       // placeholder="Mobile Number"
//                       pattern="[0-9]*" // Use a regex pattern to allow only numeric input
//                       // value={num}
//                       defaultValue={pageData?.displaybus?.whatsapp}
//                       onChange={onChange}
//                     />
//                   </InputGroup>
//                 </Form.Group>
//                 {/* <Form.Group controlId="email">
//                                     <Form.Label className="mt-3">Enter Email</Form.Label>
//                                     <Form.Control name='cl_email1'
//                                         placeholder='Email'
//                                         onChange={onChange}
//                                         defaultValue={pageData?.displaybus?.email?.[0]?.id} type="text" />
//                                 </Form.Group> */}
//               </div>
//               <div className="col-md-6">
//                 <div className="mb-3">Leave attendance_type</div>
//                 <ListGroup>
//                   <ListGroup.Item
//                     as="li"
//                     className="d-flex justify-content-between align-items-start bg-light"
//                   >
//                     <div className="ms-2 me-auto">
//                       <div className="fw-bold">
//                         {" "}
//                         <BiCalendar /> Yearly basis
//                       </div>
//                       {/* Salary will be calculated for a month */}
//                     </div>
//                     <Form.Check
//                       onChange={onChange}
//                       type="radio"
//                       name="salary_basis"
//                       value="monthly"
//                       aria-label="option 1"
//                     />
//                   </ListGroup.Item>
//                   <ListGroup.Item
//                     as="li"
//                     className="d-flex justify-content-between align-items-start bg-light mt-2"
//                   >
//                     <div className="ms-2 me-auto">
//                       <div className="fw-bold">
//                         <BsCalendar2Date /> Monthly basis
//                       </div>
//                       {/* Salary will be calculated for 30 days (Doesn't matter how many days in the current month) */}
//                     </div>
//                     <Form.Check
//                       onChange={onChange}
//                       name="salary_basis"
//                       value="30 days"
//                       type="radio"
//                       aria-label="option 2"
//                     />
//                   </ListGroup.Item>
//                 </ListGroup>

//                 <div className="mb-3">Leave type</div>
//                 <ListGroup>
//                   <ListGroup.Item
//                     as="li"
//                     className="d-flex justify-content-between align-items-start bg-light"
//                   >
//                     <div className="ms-2 me-auto">
//                       <div className="fw-bold">
//                         {" "}
//                         <BiCalendar /> Yearly basis
//                       </div>
//                       {/* Salary will be calculated for a month */}
//                     </div>
//                     <Form.Check
//                       onChange={onChange}
//                       type="radio"
//                       name="salary_basis"
//                       value="monthly"
//                       aria-label="option 1"
//                     />
//                   </ListGroup.Item>
//                   <ListGroup.Item
//                     as="li"
//                     className="d-flex justify-content-between align-items-start bg-light mt-2"
//                   >
//                     <div className="ms-2 me-auto">
//                       <div className="fw-bold">
//                         <BsCalendar2Date /> Monthly basis
//                       </div>
//                       {/* Salary will be calculated for 30 days (Doesn't matter how many days in the current month) */}
//                     </div>
//                     <Form.Check
//                       onChange={onChange}
//                       name="salary_basis"
//                       value="30 days"
//                       type="radio"
//                       aria-label="option 2"
//                     />
//                   </ListGroup.Item>
//                 </ListGroup>
//               </div>
//             </div>
//             <div className="mt-4">
//               <Button
//                 className="w-25"
//                 variant="secondary"
//                 onClick={onHide}
//                 style={{ float: "left" }}
//               >
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons w-25"
//                 type="submit"
//                 style={{ float: "right" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//         );

//       case "Supervised-Staff-Assign-Emp":
//         return (
//           <Form onSubmit={onSubmit}>
//             <div className="row">
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <div>
//                   <p style={{ fontSize: "15px", fontWeight: "bold" }}>S.No</p>
//                 </div>
//                 <div>
//                   <p style={{ fontSize: "15px", fontWeight: "bold" }}>
//                     Staff Name
//                   </p>
//                 </div>
//                 <div>
//                   <p style={{ fontSize: "15px", fontWeight: "bold" }}>
//                     CheckBox
//                   </p>
//                 </div>
//               </div>
//               {pageData && pageData?.map((item, index) => (
//                     <div key={index}>
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <div>
//                           <p style={{ fontSize: "14px", fontWeight: "low" }}>
//                             {index + 1}
//                           </p>
//                         </div>
//                         <div style={{ float: "right", width: "18%" }}>
//                           <p style={{ fontSize: "14px", fontWeight: "low" }}>
//                             {item.name}
//                           </p>
//                         </div>
//                         <div>
//                           <Form.Check
//                             style={{ fontSize: "20px" }}
//                             type="checkbox"
//                             onChange={onChange}
//                             value={item.id}
//                             checked={selectedItems.includes(item.id)}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//             </div>
//             <div className="mt-4">
//               <Button
//                 className="w-25"
//                 variant="secondary"
//                 onClick={onHide}
//                 style={{ float: "left" }}
//               >
//                 Close
//               </Button>
//               <Button
//                 className="submitButtons w-25"
//                 type="submit"
//                 style={{ float: "right" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </Form>
//         );
//         case "Supervised-Staff-Assign-Emp-view":
//           return (
//             <Form onSubmit={onSubmit}>
//               <div className="row">
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     justifyContent: "space-between",
//                   }}
//                 >
//                   <div>
//                     <p style={{ fontSize: "15px", fontWeight: "bold" }}>S.No</p>
//                   </div>
//                   <div>
//                     <p style={{ fontSize: "15px", fontWeight: "bold" }}>
//                     Designation
//                     </p>
//                   </div>
//                   <div>
//                     <p style={{ fontSize: "15px", fontWeight: "bold" }}>
//                       Staff Name
//                     </p>
//                   </div>

//                 </div>
//                 {ViewEmployeeSuperviderList &&
//                    ViewEmployeeSuperviderList?.map((item, index) => (
//                       <div key={index}>
//                         <div
//                           style={{
//                             display: "flex",
//                             flexDirection: "row",
//                             justifyContent: "space-between",
//                           }}
//                         >
//                           <div>
//                             <p style={{ fontSize: "14px", fontWeight: "low" }}>
//                               {index + 1}
//                             </p>
//                           </div>
//                           <div style={{ width: "18%",marginLeft:"12px "}}>
//                             <p style={{ fontSize: "14px", fontWeight: "low" }}>
//                               {item.designation
// }
//                             </p>
//                           </div>
//                           <div style={{ float: "right", width: "18%" }}>
//                             <p style={{ fontSize: "14px", fontWeight: "low" }}>
//                               {item.name}
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     ))
//                   }
//               </div>

//             </Form>
//           );

//       case "add-bank":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Container>
//               <Row>
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Bank name</span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter bank name"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="bank_name"
//                   />
//                 </Col>
//               </Row>
//               <Row className="mt-3">
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>IFCS Code</span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter IFCS Code"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="ifsc"
//                   />
//                 </Col>
//               </Row>
//               <Row className="mt-3">
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Account Holder Name</span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter Account Holder Name"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="ac_name"
//                   />
//                 </Col>
//               </Row>

//               <Row className="mt-3">
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Account number</span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter Account number"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="ac_no"
//                   />
//                 </Col>
//               </Row>

//               <Row className="mt-4">
//                 <Col sm={6}>
//                   <Button
//                     className=""
//                     style={{
//                       backgroundColor: "gray",
//                       color: "white",
//                       borderStyle: "none",
//                     }}
//                   >
//                     {" "}
//                     Close
//                   </Button>
//                 </Col>
//                 <Col sm={6}>
//                   <Button
//                     type="submit"
//                     style={{
//                       backgroundColor: "#18285a",
//                       borderStyle: "none",
//                       float: "right",
//                     }}
//                     className=""
//                   >
//                     {" "}
//                     Submit
//                   </Button>
//                 </Col>
//               </Row>
//             </Container>
//           </Form>
//         );
//       case "add-reimbursemnet":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Container>
//               <Row>
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Amount</span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter Amoiunt"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="amount"
//                   />
//                 </Col>
//               </Row>
//               <Row className="mt-3">
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Reason</span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter Reason"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="reason"
//                   />
//                 </Col>
//               </Row>
//               <Row className="mt-3">
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Add file</span>
//                 </Col>
//                 <Col sm={8}>
//                   <input
//                     type="file"
//                     id="outlined-basic"
//                     placeholder="Enter Account Holder Name"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="image"
//                   />
//                 </Col>
//               </Row>

//               <Row className="mt-4">
//                 <Col sm={6}>
//                   <Button
//                     className=""
//                     style={{
//                       backgroundColor: "gray",
//                       color: "white",
//                       borderStyle: "none",
//                     }}
//                   >
//                     {" "}
//                     Close
//                   </Button>
//                 </Col>
//                 <Col sm={6}>
//                   <Button
//                     type="submit"
//                     style={{
//                       backgroundColor: "#18285a",
//                       borderStyle: "none",
//                       float: "right",
//                     }}
//                     className=""
//                   >
//                     {" "}
//                     Submit
//                   </Button>
//                 </Col>
//               </Row>
//             </Container>
//           </Form>
//         );
//       case "Apply-Leave":
//         return (
//           <Form onSubmit={onSubmit}>
//             <Container>
//               <Row>
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Select Date </span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter Amoiunt"
//                     variant="outlined"
//                     fullWidth
//                     type="date"
//                     size="small"
//                     name="from_date"
//                     value={fromDate}
//                     onChange={handleFromDateChange}
//                   />
//                 </Col>
//               </Row>
//               <Row style={{ display: "none" }}>
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Select Date </span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter Amoiunt"
//                     variant="outlined"
//                     fullWidth
//                     type="date"
//                     size="small"
//                     name="to_date"
//                     value={toDate}
//                     onChange={handleToDateChange}
//                     min={fromDate}
//                   />
//                 </Col>
//               </Row>
//               <Row className="mt-3">
//                 <Col sm={4}>
//                   {" "}
//                   <span style={{ fontSize: "16px" }}>Reason</span>
//                 </Col>
//                 <Col sm={8}>
//                   <TextField
//                     id="outlined-basic"
//                     placeholder="Enter Reason"
//                     variant="outlined"
//                     fullWidth
//                     size="small"
//                     name="reason"
//                   />
//                 </Col>
//               </Row>
//               <Row className="mt-4">
//                 <Col sm={6}>
//                   <Button
//                     className=""
//                     style={{
//                       backgroundColor: "gray",
//                       color: "white",
//                       borderStyle: "none",
//                     }}
//                   >
//                     {" "}
//                     Close
//                   </Button>
//                 </Col>
//                 <Col sm={6}>
//                   <Button
//                     type="submit"
//                     style={{
//                       backgroundColor: "#18285a",
//                       borderStyle: "none",
//                       float: "right",
//                     }}
//                     className=""
//                   >
//                     {" "}
//                     Submit
//                   </Button>
//                 </Col>
//               </Row>
//             </Container>
//           </Form>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Modal show={show} onHide={onHide} size={size}>
//       <Modal.Header closeButton>
//         <Modal.Title>{title ? title : null}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>{renderFields()}</Modal.Body>
//     </Modal>
//   );
// };

// export default CommonModal;

import React, { useEffect, useState } from "react";
import { Badge, Container, InputGroup } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import businessModel from "../../Model/business.model";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedData,
  fetchAllBusinesses,
} from "../../store/silce/bussiness";
import { BiCalendar } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import {
  fetchData,
  setFilterData,
  clearroleSelectedData,
  fetchDataAllPermissionsRollList,
} from "../../store/silce/roll";
import { TextField } from "@mui/material";
import "../../App.css";

const CommonModal = ({
  show,
  onHide,
  name,
  title,
  onChange,
  onSubmit,
  pageData,
  displaybus,
  size,
  showComponents,
  setShowComponents,
  handleItemClick,
  selectedItems,
}) => {
  // console.log("name", name);
  const dispatch = useDispatch();
  const selectedData = useSelector((state) => {
    return state.Business.selectedData;
  });
  const sectorData = useSelector((state) => {
    return state.Business.sector;
  });
  const DepartmentData = useSelector((state) => {
    return state.Business.Alldepartments;
  });

  const onCloseModal = () => {
    dispatch(clearSelectedData());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  // console.log("sectordata", sectorData);

  // Add role Function

  const permissions = useSelector((state) => {
    return state?.roll?.fetchDataAllPermissionsRollList?.data?.permissions;
  });

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleFromDateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);
    setToDate(newFromDate); // Set the value of "toDate" to match "fromDate"
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  // const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDataAllPermissionsRollList());
  }, []);

  const handleToggle = (index) => {
    setShowComponents((prevShowComponents) => {
      const newShowComponents = [...prevShowComponents];
      newShowComponents[index] = !newShowComponents[index];
      // console.log(`Checkbox ${index} is now:`, newShowComponents[index]);
      return newShowComponents;
    });
  };

  const generateCheckboxes = (index, permission) => {
    return showComponents[index] ? (
      <div key={index}>
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <span style={{ paddingLeft: "10px" }}>View</span>
              <span style={{ paddingLeft: "10px" }}>Add</span>
              <span style={{ paddingLeft: "10px" }}>Edit</span>
              <span style={{ paddingLeft: "10px" }}>Delete</span>
            </div>
            <div style={{ marginRight: "20px" }}>
              <Form.Check
                style={{ fontSize: "25px" }}
                type="checkbox"
                name={`${permission.perName}[view]`}
                id=""
                value="true"
                // value="true"
                // style={{ paddingLeft: "10px" }}
              />{" "}
              <Form.Check
                style={{ fontSize: "25px" }}
                type="checkbox"
                name={`${permission.perName}[add]`}
                id=""
                value="true"

                // style={{ marginLeft: "10px" }}
              />{" "}
              <Form.Check
                style={{ fontSize: "25px" }}
                type="checkbox"
                name={`${permission.perName}[edit]`}
                id=""
                value="true"
                // style={{ marginLeft: "10px" }}
              />{" "}
              <Form.Check
                style={{ fontSize: "25px" }}
                type="checkbox"
                name={`${permission.perName}[delete]`}
                id=""
                value="true"
                // style={{ marginLeft: "10px" }}
              />{" "}
            </div>
          </div>
        </Row>
      </div>
    ) : null;
  };

  const ViewEmployeeSuperviderList = useSelector(
    (state) => state?.staff?.ViewEmployeeSuperviderStaff?.emp
  );
  // console.log(pageData, "pratibha");
  const renderFields = () => {
    switch (name) {
      case "add-business":
        return (
          <Form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="hidden"
                  name="bus_id"
                  id=""
                  value={pageData?.outid}
                />
                <Form.Group controlId="nameOfBusiness">
                  <Form.Label className="mt-2">Name of Business*</Form.Label>
                  <Form.Control
                    onChange={onChange}
                    name="cl_name"
                    type="text"
                    placeholder="Enter Name of Business"
                    required
                    id="form1"
                  />
                </Form.Group>
                <Form.Group controlId="businessSector">
                  <Form.Label className="mt-3">Business Sector*</Form.Label>
                  <Form.Select
                    name="bus_sector"
                    onChange={onChange}
                    id="form1"
                    required
                    // style={{ height: "35px", width: "100%" }}
                  >
                    <option
                      value=""
                      name="bus_sector"
                      style={{ height: "120px" }}
                      disabled
                    >
                      Select option
                    </option>
                    {sectorData &&
                      sectorData.map((sectorlist) => (
                        <option
                          value={sectorlist.id}
                          name="bus_sector"
                          style={{ height: "120px" }}
                        >
                          {sectorlist.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="mobileNumber">
                  <Form.Label className="mt-3">Mobile Number*</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ height: "35px", fontSize: "16px" }}
                    >
                      +91
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="tel" // Use type "tel" for phone numbers
                      style={{ height: "35px", appearance: "textfield" }}
                      name="cl_cont"
                      placeholder="Enter Your Mobile Number"
                      pattern="[0-9]*" // Use a regex pattern to allow only numeric input
                      // value={num}
                      id="form1"
                      maxLength={10}
                      defaultValue={pageData?.displaybus?.whatsapp}
                      onChange={onChange}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label className="mt-3"> Email ID</Form.Label>
                  <Form.Control
                    name="cl_email1"
                    placeholder="Enter Your Email ID"
                    type="email"
                    onChange={onChange}
                    defaultValue={pageData?.displaybus?.email?.[0]?.id}
                    required
                    id="form1"
                    // style={{ fontSize: "20px" }}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  How do you want to calculate your staff's Salary?
                </div>
                <ListGroup>
                  <ListGroup.Item
                    as="li"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    onClick={() => handleItemClick("monthlyRadio")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        {" "}
                        <BiCalendar /> Monthly basis
                      </div>
                      Salary will be calculated for a month
                    </div>
                    <Form.Check
                      id="monthlyRadio"
                      onChange={onChange}
                      type="radio"
                      name="salary_basis"
                      value="monthly"
                      aria-label="option 1"
                      style={{ fontSize: "20px" }}
                    />
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-start mt-2"
                    onClick={() => handleItemClick("30daysRadio")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <BsCalendar2Date /> 30 Days basis
                      </div>
                      Salary will be calculated for 30 days (Doesn't matter how
                      many days in the current month)
                    </div>
                    <Form.Check
                      id="30daysRadio"
                      onChange={onChange}
                      name="salary_basis"
                      value="30 days"
                      type="radio"
                      aria-label="option 2"
                    />
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft: "5%" }}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  float: "right",
                  borderStyle: "none",
                  marginRight: "5%",
                }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "add-department":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="mobileNumber">
              <Form.Label>Department*</Form.Label>
              <Form.Control
                onChange={onChange}
                name="name"
                className="form-group required"
                type="text"
                placeholder="Enter Department Name"
                required
                id="form1"
              />
            </Form.Group>

            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft: "5%" }}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  float: "right",
                  borderStyle: "none",
                  marginRight: "5%",
                }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );

      case "approved-leave":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="mobileNumber">
              <Form.Label>comment *</Form.Label>
              <Form.Control
                onChange={onChange}
                name="leave_comment"
                className="form-group required"
                type="text"
                placeholder="Comment"
                required
                id="form1"
              />
            </Form.Group>

            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft: "5%" }}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  float: "right",
                  borderStyle: "none",
                  marginRight: "5%",
                }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "add-new-holiday":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="nameOfHoliday">
              <Form.Label>Name of Holiday*</Form.Label>
              <Form.Control
                value={pageData?.formDataNew?.nameholiday}
                onChange={onChange}
                name="name"
                type="text"
                required
                id="form1"
                placeholder="Enter Name of Holiday"
              />
            </Form.Group>
            <Form.Group controlId="date">
              <Form.Label className="mt-3">Date</Form.Label>
              <Form.Control
                type="date"
                formate
                name=""
                required
                id="form1"
                value={pageData?.input}
                onInput={(e) => pageData?.setInput(e.target.value)}
              />
            </Form.Group>
            {/* Add more fields for the holiday page */}
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft: "5%" }}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  float: "right",
                  borderStyle: "none",
                  marginRight: "5%",
                }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "add-sub-department":
        return (
          <>
            <Form onSubmit={onSubmit}>
              <Form.Group controlId="department">
                <Form.Label className="mt-2">Department *</Form.Label>
                <Form.Group controlId="businessSector">
                  <Form.Select
                    required // Add the required attribute here
                    name="department_id"
                    style={{ height: "35px", width: "100%" }}
                    id="form1"
                    defaultValue="" // Set a default value to pre-select the default option
                  >
                    <option value="" disabled defaultValue>
                      Select Department
                    </option>
                    {DepartmentData &&
                      DepartmentData.map((sectorlist) => (
                        <option
                          key={sectorlist.department_id}
                          value={sectorlist.department_id}
                          name="department_id"
                          style={{ height: "120px" }}
                          id="Form1"
                        >
                          {sectorlist.department_name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Form.Group>
              <Form.Group controlId="cl_name" className="mt-2">
                <Form.Label>Name Of Sub Departments *</Form.Label>
                <Form.Control
                  onChange={onChange}
                  name="name"
                  className="form-group required"
                  type="text"
                  placeholder="Name Of  Sub Department"
                  required
                  id="form1"
                />
              </Form.Group>
              <div className="mt-4">
                <Button
                  style={{ float: "left", marginLeft: "5%" }}
                  className="w-25"
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    onCloseModal();
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{
                    float: "right",
                    borderStyle: "none",
                    marginRight: "5%",
                  }}
                  className="btn submitButtons w-25"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        );
      case "add-designation":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="cl_name">
              <Form.Label>Name Of Designation *</Form.Label>
              <Form.Control
                onChange={onChange}
                name="cl_name"
                className="form-group required"
                type="text"
                placeholder="Name Of Designation"
                required
              />
            </Form.Group>
            <Form.Group controlId="department">
              <Form.Label className="mt-2">Name of Department *</Form.Label>
              <Form.Group controlId="businessSector">
                <select
                  name="bus_sector"
                  id=""
                  style={{ height: "35px", width: "100%" }}
                >
                  {pageData?.dropdrownElement &&
                    pageData?.dropdrownElement.map((sectorlist) => (
                      <option
                        value={sectorlist.id}
                        name="bus_sector"
                        defaul
                        onChange={onChange}
                        style={{ height: "120px" }}
                      >
                        {sectorlist.name}
                      </option>
                    ))}
                </select>
              </Form.Group>
            </Form.Group>
            <Form.Group controlId="department">
              <Form.Label className="mt-2">Name of Sub Department *</Form.Label>
              <Form.Group controlId="businessSector">
                <select
                  name="bus_sector"
                  id=""
                  style={{ height: "35px", width: "100%" }}
                >
                  {pageData?.dropdrownElement &&
                    pageData?.dropdrownElement.map((sectorlist) => (
                      <option
                        value={sectorlist.id}
                        name="bus_sector"
                        defaul
                        onChange={onChange}
                        style={{ height: "120px" }}
                      >
                        {sectorlist.name}
                      </option>
                    ))}
                </select>
              </Form.Group>
            </Form.Group>
            <div className="mt-4">
              <Button
                className="w-25"
                variant="secondary"
                onClick={onHide}
                style={{ float: "left" }}
              >
                Close
              </Button>
              <Button
                className="submitButtons w-25"
                type="submit"
                style={{ float: "right" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "punch-in":
        return (
          <>
            <input type="hidden" name="" id="" />
            <input
              type="time"
              name=""
              formate
              id={`userTimes${pageData?.row?.emp_id}`}
            />
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft: "5%" }}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  float: "right",
                  borderStyle: "none",
                  marginRight: "5%",
                }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </>
        );
      case "punch-out":
        return (
          <>
            <Form onSubmit={onSubmit}>
              <input type="hidden" name="" id="" />
              <input
                type="time"
                name=""
                formate
                id={`userTime${pageData?.row?.emp_id}`}
              />
              <div className="mt-4">
                <Button
                  className="w-25"
                  variant="secondary"
                  onClick={onHide}
                  style={{ float: "left" }}
                >
                  Close
                </Button>
                <Button
                  className="submitButtons w-25"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        );
      case "add-level-management":
        return (
          <>
            <Form onSubmit={onSubmit}>
              <div>Level Name</div>
              <div class="input-group mb-3 mt-2">
                <input
                  name="name"
                  onChange={onChange}
                  type="text"
                  class="form-control"
                  placeholder="Level Name"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                  id="form1"
                />
              </div>
              <div className="col-md-12">
                <div
                  className="mb-3"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  Permission for this level
                </div>
                {/* <ListGroup> */}
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <div className="ms-2 me-auto">
                    <p className="mt-1">
                      {" "}
                      Can accept or deny leave requests of staff
                    </p>
                  </div>

                  <div>
                    <Form.Check
                      style={{ fontSize: "25px" }}
                      onChange={onChange}
                      type="checkbox"
                      name="accept_permission"
                      value="accept"
                      id="accept"
                      aria-label="option 1"
                      // required
                    />
                  </div>
                </div>

                <div className="d-flex gap-2 mt-3 mb-3">
                  <div className="ms-2 me-auto">
                    <p className="mt-1">Can forward leave requests to admin</p>
                  </div>
                  <Form.Check
                    style={{ fontSize: "25px" }}
                    onChange={onChange}
                    name="accept_permission"
                    value="forward"
                    type="checkbox"
                    id="forward"
                    aria-label="option 2"
                  />
                </div>
                {/* </ListGroup> */}
              </div>
              <div className="mt-4">
                <Button
                  style={{ float: "left", marginLeft: "5%" }}
                  className="w-25"
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    onCloseModal();
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{
                    float: "right",
                    borderStyle: "none",
                    marginRight: "5%",
                  }}
                  className="btn submitButtons w-25"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        );
      case "add-role":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Control
              type="text"
              placeholder="Enter Role Name"
              name="role_name"
              onChange={onChange}
              required
              id="form1"
            />
            <hr />
            <p style={{ fontSize: "20px" }}>Permissions</p>
            {permissions &&
              permissions?.map((permission, index) => (
                <div key={index} className="mt-4" style={{ cursor: "pointer" }}>
                  <span
                    style={{
                      fontSize: "15px",
                      position: "relative",
                      top: "-5px",
                    }}
                  >
                    <b>{permission.show_name}</b>
                  </span>
                  <div className="toggle">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={showComponents[index]}
                      onChange={() => handleToggle(index)}
                      id={`toggle-${index}`}
                    />
                    <label htmlFor={`toggle-${index}`}></label>
                  </div>

                  {generateCheckboxes(index, permission)}
                </div>
              ))}
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft: "5%" }}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  float: "right",
                  borderStyle: "none",
                  marginRight: "5%",
                }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "add-addressnew":
        return (
          <>
            <Form onSubmit={onSubmit}>
              <div>
                {/* <input
                  type="hidden"
                  name="bus_id"
                  id=""
                  value={pageData?.outid}
                /> */}
                <Form.Group>
                  {/* <Form.Control onChange={onChange} name='address_line1' required type="text" placeholder="Enter Name of Address Line 1" /> */}
                </Form.Group>
                <Row className="">
                  <Col sm={4}>
                    <Form.Label className="mt-2"> Address Line 1</Form.Label>
                  </Col>
                  <Col sm={8}>
                    {/* <input type="text"
                                            style={{ height: '40px' }}
                                            name="address_line1" id=""
                                            required
                                            placeholder=' Address Line 1'
                                        // onChange={handleChange}
                                        /> */}
                    <Form.Control
                      // onChange={onChange}
                      name="address_line1"
                      required
                      type="text"
                      placeholder="Enter Name of"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    <Form.Label className="mt-2"> Address Line 2</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      style={{ height: "40px" }}
                      placeholder=" Address Line 2"
                      required
                      name="address_line2"
                      id=""
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    <Form.Label className="mt-2"> City</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      style={{ height: "40px" }}
                      placeholder="Enter Your city"
                      required
                      // onChange={handleChange}
                      name="city"
                      id=""
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    <Form.Label className="mt-2"> State</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      style={{ height: "40px" }}
                      name="state"
                      id=""
                      required
                      // onChange={handleChange}
                      placeholder="Enter Your State"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    <Form.Label className="mt-2"> Pincode</Form.Label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="number"
                      style={{ height: "40px" }}
                      name="pincode"
                      id=""
                      required
                      // onChange={handleChange}
                      placeholder="Enter Your Pincode"
                    />
                  </Col>
                </Row>

                <div className="mt-4">
                  <Button
                    className=""
                    style={{
                      backgroundColor: "gray",
                      color: "white",
                      borderStyle: "none",
                    }}
                    // onClick={() => handleClose()}
                  >
                    {" "}
                    Close
                  </Button>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#18285a  ",
                      borderStyle: "none",
                      float: "right",
                    }}
                    className=""
                  >
                    {" "}
                    Submit
                  </Button>
                </div>

                {/* <button className='btn btn-primary mb-4 mt-4' style={{ float: "right" }} type='submit'>Submit</button> */}
              </div>
            </Form>
          </>
        );
      case "add-leave":
        return (
          <Form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="hidden"
                  name="emp_id"
                  id=""
                  value={pageData?.outid}
                />
                <Form.Group controlId="nameOfBusiness">
                  <Form.Label className="mt-2">Name of Leave Name*</Form.Label>
                  <Form.Control
                    onChange={onChange}
                    name="cl_name"
                    type="text"
                    placeholder="Enter Name of Leave"
                  />
                </Form.Group>
                {/* <Form.Group controlId="businessSector">
                                    <Form.Label className="mt-3">Business Sector*</Form.Label>
                                    <select name="bus_sector"
                                        // value={pageData?.formData?.bus_sector} 
                                        onChange={onChange}
                                        id="" style={{ height: '35px', width: "100%" }}>
                                        {sectorData && sectorData.map((sectorlist) =>
                                            <option value={sectorlist.id} name='bus_sector'
                                                 style={{ height: "120px", }}>{sectorlist.name}</option>
                                        )}
                                    </select>
                                </Form.Group> */}
                <Form.Group controlId="mobileNumber">
                  <Form.Label className="mt-3">Leave Count*</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="tel" // Use type "tel" for phone numbers
                      style={{ height: "35px", appearance: "textfield" }}
                      name="cl_cont"
                      // placeholder="Mobile Number"
                      pattern="[0-9]*" // Use a regex pattern to allow only numeric input
                      // value={num}
                      defaultValue={pageData?.displaybus?.whatsapp}
                      onChange={onChange}
                    />
                  </InputGroup>
                </Form.Group>
                {/* <Form.Group controlId="email">
                                    <Form.Label className="mt-3">Enter Email</Form.Label>
                                    <Form.Control name='cl_email1'
                                        placeholder='Email'
                                        onChange={onChange}
                                        defaultValue={pageData?.displaybus?.email?.[0]?.id} type="text" />
                                </Form.Group> */}
              </div>
              <div className="col-md-6">
                <div className="mb-3">Leave attendance_type</div>
                <ListGroup>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start bg-light"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        {" "}
                        <BiCalendar /> Yearly basis
                      </div>
                      {/* Salary will be calculated for a month */}
                    </div>
                    <Form.Check
                      onChange={onChange}
                      type="radio"
                      name="salary_basis"
                      value="monthly"
                      aria-label="option 1"
                    />
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start bg-light mt-2"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <BsCalendar2Date /> Monthly basis
                      </div>
                      {/* Salary will be calculated for 30 days (Doesn't matter how many days in the current month) */}
                    </div>
                    <Form.Check
                      onChange={onChange}
                      name="salary_basis"
                      value="30 days"
                      type="radio"
                      aria-label="option 2"
                    />
                  </ListGroup.Item>
                </ListGroup>

                <div className="mb-3">Leave type</div>
                <ListGroup>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start bg-light"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        {" "}
                        <BiCalendar /> Yearly basis
                      </div>
                      {/* Salary will be calculated for a month */}
                    </div>
                    <Form.Check
                      onChange={onChange}
                      type="radio"
                      name="salary_basis"
                      value="monthly"
                      aria-label="option 1"
                    />
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start bg-light mt-2"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <BsCalendar2Date /> Monthly basis
                      </div>
                      {/* Salary will be calculated for 30 days (Doesn't matter how many days in the current month) */}
                    </div>
                    <Form.Check
                      onChange={onChange}
                      name="salary_basis"
                      value="30 days"
                      type="radio"
                      aria-label="option 2"
                    />
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
            <div className="mt-4">
              <Button
                className="w-25"
                variant="secondary"
                onClick={onHide}
                style={{ float: "left" }}
              >
                Close
              </Button>
              <Button
                className="submitButtons w-25"
                type="submit"
                style={{ float: "right" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        );

      case "Supervised-Staff-Assign-Emp":
        return (
          <Form onSubmit={onSubmit}>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>S.No</p>
                </div>
                <div>
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Staff Name
                  </p>
                </div>
                <div>
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                    CheckBox
                  </p>
                </div>
              </div>
              {pageData &&
                pageData?.map((item, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p style={{ fontSize: "14px", fontWeight: "low" }}>
                          {index + 1}
                        </p>
                      </div>
                      <div style={{ float: "right", width: "18%" }}>
                        <p style={{ fontSize: "14px", fontWeight: "low" }}>
                          {item.name}
                        </p>
                      </div>
                      <div>
                        <Form.Check
                          style={{ fontSize: "20px" }}
                          type="checkbox"
                          onChange={onChange}
                          value={item.id}
                          checked={selectedItems.includes(item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft: "5%" }}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{
                  float: "right",
                  borderStyle: "none",
                  marginRight: "5%",
                }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "Supervised-Staff-Assign-Emp-view":
        return (
          <Form onSubmit={onSubmit}>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>S.No</p>
                </div>
                <div>
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Designation
                  </p>
                </div>
                <div>
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Staff Name
                  </p>
                </div>
              </div>
              {ViewEmployeeSuperviderList &&
                ViewEmployeeSuperviderList?.map((item, index) => (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p style={{ fontSize: "14px", fontWeight: "low" }}>
                          {index + 1}
                        </p>
                      </div>
                      <div style={{ width: "18%", marginLeft: "12px " }}>
                        <p style={{ fontSize: "14px", fontWeight: "low" }}>
                          {item.designation}
                        </p>
                      </div>
                      <div style={{ float: "right", width: "18%" }}>
                        <p style={{ fontSize: "14px", fontWeight: "low" }}>
                          {item.name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Form>
        );

      case "add-bank":
        return (
          <Form onSubmit={onSubmit}>
            <Container>
              <Row>
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Bank name</span>
                </Col>
                <Col sm={8}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter bank name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="bank_name"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>IFCS Code</span>
                </Col>
                <Col sm={8}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter IFCS Code"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="ifsc"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Account Holder Name</span>
                </Col>
                <Col sm={8}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Account Holder Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="ac_name"
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Account number</span>
                </Col>
                <Col sm={8}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Account number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="ac_no"
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col sm={6}>
                  <Button
                    className=""
                    style={{
                      backgroundColor: "gray",
                      color: "white",
                      borderStyle: "none",
                    }}
                  >
                    {" "}
                    Close
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#18285a",
                      borderStyle: "none",
                      float: "right",
                    }}
                    className=""
                  >
                    {" "}
                    Submit
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        );
      case "add-reimbursemnet":
        return (
          <Form onSubmit={onSubmit}>
            <Container>
              <Row>
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Amount</span>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    id="form1"
                    placeholder="Enter Amoiunt"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="amount"
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Reason</span>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    id="form1"
                    placeholder="Enter Reason"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="reason"
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Add file</span>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="file"
                    id="form1"
                    placeholder="Enter Account Holder Name"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="image"
                    required
                  />
                </Col>
              </Row>

              {/* <Row className="mt-4">
                <Col sm={6}>
                  <Button
                    className=""
                    style={{
                      backgroundColor: "gray",
                      color: "white",
                      borderStyle: "none",
                    }}
                  >
                    {" "}
                    Close
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#18285a",
                      borderStyle: "none",
                      float: "right",
                    }}
                    className=""
                  >
                    {" "}
                    Submit
                  </Button>
                </Col>
              </Row> */}

              <div className="mt-4">
                <Button
                  style={{ float: "left", marginLeft: "5%" }}
                  className="w-25"
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    onCloseModal();
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{
                    float: "right",
                    borderStyle: "none",
                    marginRight: "5%",
                  }}
                  className="btn submitButtons w-25"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </Container>
          </Form>
        );
      case "Apply-Leave":
        return (
          <Form onSubmit={onSubmit}>
            <Container>
            <Row > 
              <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Leave Type</span>
                </Col>
              <Col sm={8}>
          
                  <Form.Select
                    name="leave_type"
                    id=""
                    required
                    style={{ height: "35px", width: "100%" }}
                  >
                    <option value=''>Select</option>
                    <option value='casual_leave'>Casual Leave </option>
                    <option value='sick_leave'>Sick Leave </option>
                  </Form.Select>{" "}
                </Col>
                </Row>
              <Row className="mt-3">
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Select Date </span>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    id="form1"
                    placeholder="Enter Amoiunt"
                    variant="outlined"
                    fullWidth
                    type="date"
                    size="small"
                    name="from_date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    required
                  />
                </Col>
              </Row>
              <Row style={{ display: "none" }}>
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Select Date </span>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    id="form1"
                    placeholder="Enter Amoiunt"
                    variant="outlined"
                    fullWidth
                    type="date"
                    size="small"
                    name="to_date"
                    value={toDate}
                    onChange={handleToDateChange}
                    min={fromDate}
                    required
                  />
                </Col>

              
              </Row>
              <Row className="mt-3">
                <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Reason</span>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    id="form1"
                    required
                    placeholder="Enter Reason"
                    variant="outlined"
                    fullWidth
                    size="small"
                    name="reason"
                  />
                </Col>
              </Row>
       
              {/* <Row className="mt-4">
                <Col sm={6}>
                  <Button
                    className=""
                    style={{
                      backgroundColor: "gray",
                      color: "white",
                      borderStyle: "none",
                    }}
                  >
                    {" "}
                    Close
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#18285a",
                      borderStyle: "none",
                      float: "right",
                    }}
                    className=""
                  >
                    {" "}
                    Submit
                  </Button>
                </Col>
              </Row> */}

              <div className="mt-4">
                <Button
                  style={{ float: "left", marginLeft: "5%" }}
                  className="w-25"
                  variant="secondary"
                  onClick={() => {
                    onHide();
                    onCloseModal();
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{
                    float: "right",
                    borderStyle: "none",
                    marginRight: "5%",
                  }}
                  className="btn submitButtons w-25"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </Container>
          </Form>
        );
      default:
        return null;
    }
  };

  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title ? title : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderFields()}</Modal.Body>
    </Modal>
  );
};

export default CommonModal;
