import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Nav,
  Tab,
  Overlay,
  Tooltip,
  Alert,
  ListGroup,
  Table,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBProgressBar,
  MDBProgress,
  MDBIcon,
  MDBListGroupItem,
  MDBListGroup,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCardHeader,
  MDBTypography,
} from "mdb-react-ui-kit";
import { BsBookHalf } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { editEmployee } from "../../store/silce/staffSlice";
import employeeModel from "../../Model/employee.Model";

import { useParams } from "react-router-dom";
import { helper } from "../../lib/helper";
const LeaveAllowance = () => {
  const params = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const employees = useSelector(
    (state) => state?.staff?.employeeDetails?.emp?.emp_leaves
      );

  console.log(employees,"employeeslevave")

  const [newLeave, setNewLeave] = useState({
    type: "",
    period: "",
    leave_detail: "",
    leave_count: "",
  });
  console.log(employees, "addNewValue");
  const handleInputChange = (event) => {
    setNewLeave({
      ...newLeave,
      [event.target.name]: event.target.value,
    });
    let newArray = employees?.concat(newLeave);
    console.log(newArray, "console.log(newArray)");
  };

  const handleSubmit = (event) => {
    
    event.preventDefault();
    let newArray = employees?.concat(newLeave);
    const form = new FormData();
    form.append("emp_code", 123);
    form.append("emp_id", params?.id);  
    form.append("app_ver", 2);
    form.append("update_employee", localStorage.getItem("token"));
    console.log("yesdataupdate",form);
    newArray?.forEach((item, index) => {
      // Iterate through the object properties and append each one individually
      Object.keys(item).forEach((key) => {
        form.append(`emp_leaves[${index}][${key}]`, item[key]);
      });
    });
    
    employeeModel
      .updateemp(form)
      .then((response) => {
        setNewLeave({
          type: "",
          period: "",
          leave_detail: "",
          leave_count: "",
        });
        helper.sweetalert.toast("Leave added.", "success");
        handleClose();
        dispatch(editEmployee({ id: params?.id }));
      })
      .catch((error) => {
        console.log(error,"errorleave");
      });
  };
  useEffect(() => {
    dispatch(editEmployee({ id: params?.id }));
  }, []);
  return (
    <MDBCard className="mt-3">
      <MDBCardHeader>
        <span style={{ marginTop: "px", marginLeft: "10px", fontSize: "19px" }}>
          <b style={{ paddingLeft: "" }}> Leave Allowance Policy </b>
        </span>
        <button
          style={{
            borderRadius: "5px",
            backgroundColor: "#18285A",
            padding: "5px",
            color: "white",
            float: "right",
          }}
          onClick={handleShow}
        >
          Add New{" "}
        </button>
      </MDBCardHeader>
      <MDBCardBody>
        <div className=" d-flex align-items-center gap-2">
          <div>
            {" "}
            <BsBookHalf />
          </div>
          <div>Allowance Policy</div>
        </div>
        <div className="mt-3">
          <Alert variant={"warning"}>
            <ListGroup className="gap-2 border-none" as="ol" numbered>
              <ListGroup.Item as="li">
                Leave will start from the month of January
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Any type of change will be effective on the next day.
              </ListGroup.Item>
            </ListGroup>
          </Alert>
        </div>
        <div className="mt-3">
          <Row xs={1} md={3} className="g-4">
            {employees &&
              employees?.map((ele, idx) => (
                <Col key={idx}>
                  <Card>
                    <Card.Body>
                      <Card.Header className="text-capitalize">
                        {ele.leave_detail}
                      </Card.Header>
                      <div className="mt-3">
                        <Table>
                          <tbody>
                            <tr>
                              <td> Leave Type </td>
                              <td className="text-capitalize"> {ele.type}</td>
                            </tr>
                            {/* <tr>
                                                        <td>LeaveDetail </td>
                                                        <td>{ele.leave_detail}</td>
                                                    </tr> */}
                            <tr>
                              <td> Leave Period </td>
                              <td className="text-capitalize">{ele.period}</td>
                            </tr>
                            <tr>
                              <td>Leave Count </td>
                              <td>{ele.leave_count}</td>
                            </tr>
                            {/* <tr>
                                                        <td>Availability </td>
                                                        <td>{ele.availability}</td>
                                                    </tr> */}
                          </tbody>
                        </Table>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      </MDBCardBody>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Leave</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Container>
              <Row>
                <Col sm={4}> Leave Name </Col>
                <Col sm={8}>
                  {" "}
                  <Form.Control
                    type="text"
                    placeholder="Enter Add Leave"
                    name="leave_detail"
                    value={newLeave.leave_detail}
                    onChange={handleInputChange}
                    style={{ height: "35px", borderRadius: "8px" }}
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}> Leave Count</Col>
                <Col sm={8}>
                  {" "}
                  <Form.Control
                    type="number"
                    placeholder=" Enter Leave Count"
                    name="leave_count"
                    value={newLeave.leave_count}
                    onChange={handleInputChange}
                    style={{ height: "35px", borderRadius: "8px" }}
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}> Leave Period </Col>
                <Col sm={4}>
                  {" "}
                  <span>Paid Leave</span>
                  <input
                    type="radio"
                    name="period"
                    value="paid"
                    onChange={handleInputChange}
                    style={{ width: "20px", marginLeft: "10px" }}
                  
                  />
                </Col>
                <Col sm={4}>
                  {" "}
                  <span>Unpaid Leave</span>
                  <input
                    type="radio"
                    onChange={handleInputChange}
                    name="period"
                    placeholder=""
                    value="unpaid"
                    style={{ width: "20px", marginLeft: "10px" }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={4}> Leave Type </Col>
                <Col sm={4}>
                  <span>Monthly</span>{" "}
                  <input
                    type="radio"
                    name="type"
                    onChange={handleInputChange}
                    placeholder=""
                    value="monthly"
                    style={{ width: "20px", marginLeft: "21px" }}
                  />
                </Col>
                <Col sm={4}>
                  <span>Yearly</span>{" "}
                  <input
                    type="radio"
                    name="type"
                    onChange={handleInputChange}
                    placeholder=""
                    value="yearly"
                    style={{ width: "20px", marginLeft: "59px" }}
                  />
                </Col>
              </Row>
              {/* <Row className="mt-3"> 
              <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Leave Type</span>
                </Col>
              <Col sm={8}>
          
                  <Form.Select
                    name="leave_type"
                    id=""
                    required
                    style={{ height: "35px", width: "100%" }}
                  >
                    <option value=''>Selected Option</option>
                    <option value='casual_leave'>Casual Leave </option>
                    <option value='sick_leave'>Sick Leave </option>
                  </Form.Select>{" "}
                </Col>
                </Row> */}
              <div className="mt-4 mb-4">
                <Button
                  style={{ float: "left", marginLeft: "5%" }}
                  className="w-25"
                  variant="secondary"
                  onClick={() => handleClose()}
                >
                  Close
                </Button>
                <Button
                  style={{
                    float: "right",
                    borderStyle: "none",
                    marginRight: "5%",
                  }}
                  className="btn submitButtons w-25"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
              <div className="mt-5">
                <span style={{ opacity: "-1", zIndex: "0" }}>ggggt</span>
              </div>
            </Container>
          </Modal.Body>
        </Form>
      </Modal>
    </MDBCard>
  );
};

export default LeaveAllowance;
