import ReactDataTable from 'react-data-table-component';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import { handleSearch } from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';

const DataTable = ({ columns,size,width, data, title, page, selectableRowsSelected,loading, isSubHeader,isSubHeaderaction ,onClick, searchData, rowReorder,filterdData, setData ,keys,noDataComponent,selectableRows,onSelectedRowsChange,titlebuttom}) => {
    const customStyles = {
        head: {
            style: {
                background: 'gray',
                fontWeight: '300',
                fontSize: '14px',
                color: 'white !important'
            },
        },
    };
    const dispatch = useDispatch()
    const [search, setSearch] = useState('');
    const handleSearchData = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearch(searchTerm);
      
        handleSearch({ data: data, keys: keys, searchTerm: searchTerm, dispatch: dispatch, setData:setData })
    };
    return (
        <div className='tableContainer'>
            {
                loading ?
                    <center>
                        <Spinner  variant="secondary" animation="grow" />
                        <p className='text-black'>Please Wait Data is Loading...</p>
                    </center>
                    :
                    <ReactDataTable
                    actions={isSubHeaderaction ? <div>
                        <button className='btn '  style={{backgroundColor:"#18285a",color:'white',}} onClick={onClick}> {titlebuttom}</button>
                       </div>:null}
                        title={
                            <div className='d-flex align-items-center justify-content-lg-between mb-2'>
                                <div>{title}</div>
                                <div>
                                    <MDBInput id="outlined-basic"
                                        variant="outlined"
                                        margin="normal"
                                        size={size}
                                        type="text"
                                        placeholder='Search here...'
                                        className='MdbSearchInput'
                                        value={search}
                                        width={width}
                                        onChange={handleSearchData}
                                    />
                                </div>
                         
                            </div>
                        }
                        selectableRowsHighlight={false}
                        striped
                        data={!search ? data : filterdData}
                        columns={columns}
                        pagination
                        rowSelectCritera 
                        selectableRowsSelected={selectableRowsSelected}
                        // selectedRows={selectedRows}
                        // selectableRowSelected={selectableRowSelected}
                        onSelectedRowsChange={onSelectedRowsChange}
                        selectableRows={selectableRows}
                        rowReorder={rowReorder}
                        noDataComponent={noDataComponent}
                        customStyles={customStyles}
                        subHeaderComponent={isSubHeader &&
                            <MDBInput id="outlined-basic"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                type="text"
                                width='100%'     
                                placeholder='Search here...'
                                className='MdbSearchInput'
                                value={search}
                                onChange={handleSearchData}
                            />
                            }
                    />
            }
        </div>
    );
};

export default DataTable;