import React, { useState, useEffect } from "react";
import "../../Aseset/css/sidenav.css";
import {
  Container,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
  Button,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  closeEditModal,
  closeModal,
  openEditModal,
  openModal,
} from "../../store/silce/modalSlice";
import CommonModal from "../../components/Common/CommonModal";
import DataTable from "../../components/Tables/Tables";
import {
  AddSubDepartments,
  fetchSubDepartment,
  fetchAlldepartments,
  fetchAllSubdepartments,
  removeDerpartment,
  editDepartmet,
  updatedepartment,
  clearSelectedData,
  assingeempdep,
  unassingeempdep,
} from "../../store/silce/bussiness";
import TableDropdown from "../../components/TableDropDown/TableDropdown";
import moment from "moment";
import { helper } from "../../lib/helper";
import CommonEditModal from "../../components/Common/CommonEditModal";
import {
  fetchSubAllEmployees,
  nofetchAllEmployees,
  setFilterData,
} from "../../store/silce/staffSlice";
import DataTables from "react-data-table-component";

const SubDepartmentist = () => {
  const busid = useSelector((state) => state?.selectOption?.value);
  useEffect(() => {
    dispatch(nofetchAllEmployees());
  }, [busid]);
  const dispatch = useDispatch();
  const updatedData = useSelector((state) => {
    return state?.Business?.selectedData?.department;
  });
  const DepartmentData = useSelector((state) => {
    return state?.Business?.Alldepartments;
  });
  const SubDepartmentData = useSelector((state) => {
    return state?.Business?.businesses?.data.departments;
  });

  const businessesList = useSelector((state) => {
    return state?.Business?.Allnewsubdepartments;
  });

 


  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const showModal = useSelector((state) => state.modal.showModal);
  const [outid, setOutid] = useState(0);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const [currentTitle, setCurrentTitle] = useState("add-sub-department");
  const displaydata = async (department_id) => {
    dispatch(editDepartmet({ id: department_id }));
    setOutid(department_id);
  };
  
  const [assingeemp, setAssingeemp] = useState(null);
  const [showassingeemp, setShowassingeemp] = useState(false);
  const handleCloseassingeemp = () => setShowassingeemp(false);
  const handleShowassingeemp = () => setShowassingeemp(true);
  const [showunassingeemp, setShowunassingeemp] = useState(false);
  const handleCloseunassingeemp = () => setShowunassingeemp(false);
  const handleShowunassingeemp = () => setShowunassingeemp(true);
  // const businessesListsingh = useSelector((state) => {
  //   return state?.Business?.selectedData?.department;
  // });
  useEffect(() => {
    // dispatch(fetchAlldepartments());
    dispatch(fetchAllSubdepartments({ Option: selecteddeparmentId }));
  }, [busid]);

  const [subId, setSubid] = useState();

  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "Sub-Department Name",
      selector: (row) => row.department_name,
    },
    {
      name: "Department Name",
      selector: (row) => row.parent_department,
    },
    {
      name: "Total Staff",
      selector: (row) => row.staff_count,
    },
    {
      name: "Created On",
      selector: (row) => row.added_on,
    },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            isRemove={true}
            id={row.department_id}
            isEdit={true}
            openEditModal={() => {
              dispatch(openEditModal("edit-sub-department"));
              setSubid(row.department_id);
              displaydata(row.department_id);
            }}
            isdeptassing={true}
            isdeptunassing={true}
            onDeleteClick={() => onDELETE(row.department_id)}
            onClick={() => onDELETE(row.department_id)}
            handleShowassingeemp={() => {
              setAssingeemp(row.department_id);
              handleShowassingeemp();
              dispatch(nofetchAllEmployees());
            }}
            handleShowunassingeemp={() => {
              setAssingeemp(row.department_id);
              handleShowunassingeemp();
              dispatch(fetchSubAllEmployees({ Option: assingeemp }));
            }}
          />
        );
      },
    },
  ];
  const columns2 = [
    {
      name: "Staff ID",
      selector: (row) => row.id,
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
    },
  ];
  const columns1 = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "Sub-Departments",
      selector: (row) => row.department_name,
    },
    {
      name: "Department Name",
      selector: (row) => row.parent_department,
    },
    {
      name: "Total Staff",
      selector: (row) => row.staff_count,
      center: "center",
    },
    {
      name: "Created Date",
      selector: (row) => row.added_on,
      cell: (row) => {
        return (
          <div>
            <span className="">
              {moment(row.added_on).format("DD-MM-YYYY")}
            </span>
          </div>
        );
      },
      center: "center",
    },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            isRemove={true}
            id={row.department_id}
            isEdit={true}
            openEditModal={() => {
              dispatch(openEditModal("edit-sub-department"));
              setOutid(row.department_id);
              displaydata(row.department_id);
            }}
            displaydata={row.department_id}
            isdeptassing={true}
            isdeptunassing={true}
            onDeleteClick={() => onDELETE(row.department_id)}
            onClick={() => onDELETE(row.department_id)}
            handleShowassingeemp={() => {
              setAssingeemp(row.department_id);
              handleShowassingeemp();
            }}
            handleShowunassingeemp={() => {
              setAssingeemp(row.department_id);
              handleShowunassingeemp();
              dispatch(fetchSubAllEmployees({ Option: row.department_id }));
            }}
          />
        );
      },
    },
  ];

  const columns3 = [
    {
      name: "Staff ID",
      selector: (row) => row.id,
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
    },
  ];

  const onDELETE = (department_id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(removeDerpartment({ id: department_id })).then(
            () => (
              helper.sweetalert.toast(
                "Deleted",
                "Your file has been deleted.",
                "success"
              ),
              dispatch(fetchAllSubdepartments({ Option: selecteddeparmentId }))
            )
          );
        }
      });
  };
  useEffect(() => {
    dispatch(fetchAlldepartments());
  }, [busid]);

  useEffect(() => {
    dispatch(fetchSubDepartment());
  }, [busid]);
  const [selecteddeparmentId, setSlecteddeparmentId] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(AddSubDepartments(event));
    dispatch(fetchAllSubdepartments({ Option: selecteddeparmentId }));
    dispatch(closeModal());
  };

  const [selectedLevel, setSelectedLevel] = useState("Select Department");

  const handleLevelSelect = (department_id, department_name) => {
    setSelectedLevel(department_name);
    setSlecteddeparmentId(department_id);
    // dispatch(fetchAllEmployees({ selectedOptions: department_id, name: "department" }))
    dispatch(fetchAllSubdepartments({ Option: department_id }));
  };
  const handleSubmit1 = (event) => {
    event.preventDefault();
    dispatch(updatedepartment({ outid: outid, event: event }));
    displaydata(updatedData?.id);
    // dispatch(fetchSubDepartment());
    dispatch(fetchAlldepartments());
    dispatch(fetchAllSubdepartments({ Option: selecteddeparmentId }))
    dispatch(closeEditModal());
  };
  const pageData = {
    outid: outid,
  };


  const employees = useSelector((state) => state.staff.employees);
  const loading = useSelector((state) => {
    return state.staff.loading;
  });



  const businessesloading = useSelector((state) => {
    return state.Business.loading;
  });

  const filterdData = useSelector((state) => {
    return state.staff.filterdData;
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectedRowsChange = (state) => {
    // Update the selectedRows state whenever the selection changes
    setSelectedRows(state.selectedRows);
  };
  const idget = selectedRows && selectedRows?.map((item, index) => item.id);
 
  const AssignEmpdeps = () => {
    dispatch(assingeempdep({ outid: assingeemp, idget: idget }));
    dispatch(fetchAllSubdepartments({ Option: selecteddeparmentId }));
    handleCloseassingeemp();
  };
  const UnassignEmpdeps = () => {
    dispatch(unassingeempdep({ outid: assingeemp, idget: idget }));
    dispatch(fetchAllSubdepartments({ Option: selecteddeparmentId }));
    handleCloseunassingeemp();
  };

 
  const employeedid = useSelector(
    (state) => state?.staff?.employees?.data?.emp
  );



  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
              <div className="mt-0 mb-4"  >
                <DropdownButton
                  id="dropdown-basic-button"
                  className="custom-dropdown subdepartment-drop"
                  variant="secondary"
                  title={selectedLevel}
                  size="sm"
                  style={{
                    fontSize: "14px",
                    marginLeft: "10px",
                    width: "10px",
                  }}
                >
                  {DepartmentData &&
                    DepartmentData?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          handleLevelSelect(
                            item.department_id,
                            item.department_name
                          )
                        }
                        style={{ fontSize: "14px" }}
                      >
                        {item.department_name}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
                <Button
                  className="submitButtons btn"
                  variant="primary"
                  style={{ float: "right", padding: "5px" }}
                  onClick={() => {
                    setCurrentTitle("Add Sub Department");
                    dispatch(openModal("add-sub-department"));
                  }}
                >
                  {" "}
                  Add Sub-Department
                </Button>
              </div>
              {/* {SubDepartmentData?.[0]?.parent_department === null ||
              SubDepartmentData?.[0]?.parent_department === "" ? ( */}
                <>
                  <DataTable
                    title={"Sub-Department List"}
                    page={"subDepartment"}
                    loading={businessesloading}
                    columns={columns}
                    data={businessesList}
                    noDataComponent="No Sub Department Found"
                  />
                </>
              {/* ) : (
                <>
                  <div
                    style={{
                      "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                    }}
                    className=" mt-2 mb-4"
                  >
                    <DataTable
                      title={"Sub-Department List"}
                      page={"subDepartment"}
                      loading={businessesloading}
                      columns={columns1}
                      data={SubDepartmentData}
                    />
                  </div>
                </>
              )} */}
            </Col>
          </Row>
          <CommonModal
            onSubmit={handleSubmit}
            show={showModal}
            onHide={() => {
              dispatch(closeModal());
            }}
            name={currentPage}
            title={currentTitle}
          />
          <CommonEditModal
            pageData={pageData}
            show={showEditModal}
            name={"edit-sub-department"}
            title={"Edit Sub Department"}
            onHide={() => {
              dispatch(closeEditModal());
              dispatch(clearSelectedData());
            }}
            onSubmit={handleSubmit1}
          />
          <Modal show={showassingeemp} onHide={handleCloseassingeemp} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Assign Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "500px", overflowY: "scroll" }}>
              <DataTables
                columns={columns2}
                loading={loading}
                data={employees?.data?.emp}
                setData={setFilterData}
                // keys={['email_id', 'emp_code', 'name', 'designation','cont']}
                filterdData={filterdData}
                page={"Department"}
                keys={["name"]}
                selectableRows
                highlightOnHover
                pagination
                onSelectedRowsChange={handleSelectedRowsChange}
                // paginationRowsPerPageOptions={5}
                // noDataComponent="No Department Found"
                isSubHeaderaction={true}
                onClick={AssignEmpdeps}
                titlebuttom={"Assign"}
              />
              <Button
                style={{
                  backgroundColor: "#232c59",
                  float: "right",
                  marginTop: "5px",
                  marginBottom: "5px",
                  marginRight: "15px",
                }}
                onClick={AssignEmpdeps}
              >
                Assign
              </Button>
            </Modal.Body>
          </Modal>
          <Modal
            show={showunassingeemp}
            onHide={handleCloseunassingeemp}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Unassign Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "500px", overflowY: "scroll" }}>
              {/* <button
                        type="submit"
                        className="btn btn-block"
                        style={{ backgroundColor: '#232c59', float:'right', marginTop: '5px',marginBottom
                        :'5px',marginRight:'15px' }} onClick={AssignEmpdeps}>
                        <b className='text-light'>Assign</b>
                        </button> */}
              <DataTables
                columns={columns3}
                loading={loading}
                data={employeedid}
                setData={setFilterData}
                // keys={['email_id', 'emp_code', 'name', 'designation','cont']}
                filterdData={filterdData}
                page={"Department"}
                keys={["name"]}
                selectableRows
                highlightOnHover
                pagination
                titlebuttom={"Unassign"}
                onSelectedRowsChange={handleSelectedRowsChange}
                // paginationRowsPerPageOptions={5}
                // noDataComponent="No Department Found"

                isSubHeaderaction={true}
                onClick={UnassignEmpdeps}
              />
              
              <Button
                style={{
                  backgroundColor: "#232c59",
                  float: "right",
                  marginTop: "5px",
                  marginBottom: "5px",
                  marginRight: "15px",
                }}
                onClick={UnassignEmpdeps}
              >
                submit
              </Button>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  );
};
export default SubDepartmentist;
