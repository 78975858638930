import React, { useState, useEffect } from 'react'
// import EmphomeModal from '../Modal/Emphome.Modal';
// import DataTable from "../../components/Tables/Tables";
import { Modal, Container, Row, Col } from 'react-bootstrap';
import CommonModal from '../components/Common/CommonModal';
import { useDispatch, useSelector } from 'react-redux';
import { closeEditModal, closeModal, openEditModal, openModal } from '../store/silce/modalSlice';
import { ApplyLeave, ApplyMutiLeave, GetALLLeave, GetBankdata, GetUpdateBank } from '../store/silce/Employee';
import CommonEditModal from '../components/Common/CommonEditModal';
import moment from 'moment';
import { TextField } from '@mui/material';

const EmployeeBank = () => {
    const dispatch = useDispatch()

    const [currentTitle, setCurrentTitle] = useState('add-business')
    const showModal = useSelector((state) => state.modal.showModal);
    const currentPage = useSelector((state) => state.modal.currentPage);
   

    const Empleavaehistory = useSelector((state) => state?.employee?.Employee?.bank)



    
    const handleSubmit = (event) => {

        event.preventDefault();
       
        dispatch(GetUpdateBank({ event }))
        dispatch(GetBankdata())
        dispatch(closeModal())
    }

    useEffect(() => {
        dispatch(GetBankdata())
    }, [])
    return (
        <div>
            <Container fluid >
                <Row>
                    <Col>
                        <div className='mt-0 mb-5'>
                            <span style={{ marginLeft: '20px', fontSize: '22px' }}><b>Add Bank</b></span>
                            <button className='btn'
                                onClick={() => {
                                    dispatch(openModal("add-bank"))
                                    setCurrentTitle("Add New Business")
                                }}
                                style={{ float: "right", backgroundColor: '#18285a', color: 'white', marginRight: '15px' }}>Apply  Bank</button>

                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                </Row>
            </Container>
            <Container >


<Row>
    <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Bank name</p></Col>
    <Col sm={6}>
        <TextField id="outlined-basic"  variant="outlined" fullWidth size="small"
            value={Empleavaehistory?.bank_name}
            disabled
        /></Col>
</Row>
<Row className='mt-3'>
    <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>IFCS Code</p></Col>
    <Col sm={6}>
        <TextField id="outlined-basic" placeholder='Enter IFCS Code' variant="outlined" fullWidth size="small"
            value={Empleavaehistory?.ifsc_code}
            disabled
            name='ifsc'
            /></Col>
</Row>
<Row className='mt-3'>
    <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Account Holder Name</p></Col>
    <Col sm={6}>
        <TextField id="outlined-basic" placeholder='Enter Account Holder Name' variant="outlined" fullWidth size="small"
            value={Empleavaehistory?.account_name}
            disabled
         
        /></Col>
</Row>


<Row className='mt-3'>
    <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Account number</p></Col>
    <Col sm={6}>
        <TextField id="outlined-basic" placeholder='Enter Account number'
            variant="outlined" fullWidth size="small"
disabled
            value={Empleavaehistory?.account_no}
            // onChange={handleInputChange}
        /></Col>
</Row>


</Container>
<CommonModal onSubmit={handleSubmit} show={showModal} onHide={() => {
                    dispatch(closeModal()) 
                }}  title={currentTitle} name={currentPage} />
        </div>

    )
}

export default EmployeeBank;
