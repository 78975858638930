import React, { useState, useEffect } from "react";
import "../../Aseset/css/sidenav.css";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  InputGroup,
  Spinner,
  Button,
} from "react-bootstrap";
import businessModel from "../../Model/business.model";
import { helper } from "../../lib/helper";
import calander from "../../../src/Aseset/image/calendar.png";
import day from "../../../src/Aseset/image/30day.png";
import DataTable from "../../components/Tables/Tables";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
// import {  setFilterData } from '../store/silce/staffSlice';

import {
  closeEditModal,
  closeModal,
  openEditModal,
  openModal,
} from "../../store/silce/modalSlice";
import CommonModal from "../../components/Common/CommonModal";
import TableDropdown from "../../components/TableDropDown/TableDropdown";
import {
  Adddepartments,
  editDepartmet,
  fetchAllBusinesses,
  fetchAlldepartments,
  removeDerpartment,
  updatedepartment,
  clearSelectedData,
  assingeempdep,
  unassingeempdep,
} from "../../store/silce/bussiness";
import DataTables from "react-data-table-component";
import CommonEditModal from "../../components/Common/CommonEditModal";
import moment from "moment";
import {
  fetchAllEmployees,
  nofetchAllEmployees,
  setFilterData,
} from "../../store/silce/staffSlice";
const Departmentist = () => {
  const showdepartment = useSelector((state) => state);
  const [outid, setOutid] = useState(0);

  const businessesList = useSelector((state) => {
    return state.Business.Alldepartments;
  });
  const [selectedRowss, setSelectedRowss] = useState([]);
  const [showassingeemp, setShowassingeemp] = useState(false);
  const handleCloseassingeemp = () => setShowassingeemp(false);
  const handleShowassingeemp = () => setShowassingeemp(true);
  const [showunassingeemp, setShowunassingeemp] = useState(false);
  const handleCloseunassingeemp = () => setShowunassingeemp(false);
  const handleShowunassingeemp = () => setShowunassingeemp(true);
  const [assingeemp, setAssingeemp] = useState(null);
  
  const updatedData = useSelector((state) => {
    return state?.Business;
  });
  const loading = useSelector((state) => {
    return state.staff.loading;
  });
  const departmentloading = useSelector((state) => {
    return state.Business.loading;
  });

  
  const filterdData = useSelector((state) => {
    return state.staff.filterdData;
  });

  const busid = useSelector((state) => state?.selectOption?.value);

  const showModal = useSelector((state) => state.modal.showModal);
  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const [currentTitle, setCurrentTitle] = useState("add-sub-department");
  const dispatch = useDispatch();
  const displaydata = async (department_id) => {
    dispatch(editDepartmet({ id: department_id }));
    setOutid(department_id);
  };
  
  const columns1 = [
    {
      name: "Staff ID",
      selector: (row) => row.id,
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
    },
  ];
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      maxWidth: "12px",
    },
    {
      name: "Department Name",
      selector: (row) => row.department_name,
      // width: "300px",
    },
    {
      name: "Total Staff",
      selector: (row) => row.staff_count,
      cell: (row) => {
        return (
          <div>
            <span className="">{row.staff_count}</span>
          </div>
        );
      },
      // width: "300px",
      center: "center",
    },
    {
      name: "Created On",
      selector: (row) => row.added_on,
      // cell: row => {
      //     return (
      //         <div>
      //             <span className=''>{moment(row.added_on).format("DD-MM-YYYY")}</span></div>
      //     )

      // },
      // width: "300px",
    },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            isRemove={true}
            id={row.department_id}
            isEditbuss={true}
            openEditModal={() => dispatch(openEditModal("edit-department"))}
            displaydata={displaydata}
            onDeleteClick={() => onDELETE(row.department_id)}
            onClick={() => onDELETE(row.department_id)}
            isdeptassing={true}
            isdeptunassing={true}
            handleShowassingeemp={() => {
              setAssingeemp(row.department_id);
              handleShowassingeemp();
            }}
            handleShowunassingeemp={() => {
              setAssingeemp(row.department_id);
              handleShowunassingeemp();
              dispatch(
                fetchAllEmployees({
                  name: "department",
                  selectedOptions: row.department_id,
                })
              );
            }}
            // onClick={() => {
            //   setLeaveData(row);
            // }}
          />
        );
      },

      center: "center",
    },
  ];

  const onDELETE = (department_id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(removeDerpartment({ id: department_id })).then(
            () => (
              helper.sweetalert.toast(
                "Deleted",
                "Your file has been deleted.",
                "success"
              ),
              dispatch(fetchAlldepartments())
            )
          );
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(Adddepartments(event));
    dispatch(fetchAlldepartments());
    dispatch(closeModal());
  };
  useEffect(() => {
    dispatch(fetchAlldepartments());
  }, [busid]);
  const employees = useSelector((state) => state.staff.employees);
  const employeedid = useSelector(
    (state) => state?.staff?.employeesdept?.data?.emp
  );

  const handleSubmit1 = (event) => {
    event.preventDefault();
    dispatch(updatedepartment({ outid: outid, event: event }));
    displaydata(updatedData?.id);
    dispatch(fetchAlldepartments());
    dispatch(closeEditModal());
  };
  const pageData = {
    outid: outid,
  };

  useEffect(() => {
    dispatch(nofetchAllEmployees());
  }, [busid]);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectedRowsChange = (state) => {
    // e.preventDefault()
    // Update the selectedRows state whenever the selection changes
    setSelectedRows(state.selectedRows);
  };

  const idget = selectedRows && selectedRows?.map((item, index) => item.id);
  

  const AssignEmpdeps = () => {
    dispatch(assingeempdep({ outid: assingeemp, idget: idget }));
    dispatch(fetchAlldepartments());
    handleCloseassingeemp();
  };
  const UnassignEmpdeps = () => {
    dispatch(unassingeempdep({ outid: assingeemp, idget: idget }));
    dispatch(fetchAlldepartments());
    handleCloseunassingeemp();
  };
  const shouldSelectRow = (row) => row.parent_department_id === assingeemp;
  

  const rowSelectCritera = (row) => row.parent_department_id === assingeemp;

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
              <div className="mt-0 mb-5">
                <Button
                  className="btn submitButtons"
                  style={{
                    float: "right",
                  }}
                  onClick={() => {
                    setCurrentTitle("Add New Department");
                    dispatch(openModal("add-department"));
                  }}
                >
                  {" "}
                  Add Department
                </Button>
              </div>
              <div
                style={{
                  "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                }}
              >
                <div>
                  <DataTable
                    columns={columns}
                    data={businessesList}
                    loading={departmentloading}
                    title={"Department List"}
                    page={"Department"}
                    setData={setFilterData}
                    keys={["department_name"]}
                    filterdData={filterdData}
                    noDataComponent="No Department Found"
                    options={{
                      selectableRowsHeader: false,
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <CommonModal
            show={showModal}
            name={currentPage}
            title={currentTitle}
            onHide={() => dispatch(closeModal())}
            onSubmit={handleSubmit}
          />
          <CommonEditModal
            pageData={pageData}
            show={showEditModal}
            name={"edit-department"}
            title={"Edit Department"}
            onHide={() => {
              dispatch(closeEditModal());
              dispatch(clearSelectedData());
            }}
            onSubmit={handleSubmit1}
          />
          <Modal show={showassingeemp} onHide={handleCloseassingeemp} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Assign Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "500px", overflowY: "scroll" }}>
              <div>
                <div>
                  <DataTable
                    columns={columns1}
                    loading={loading}
                    data={employees?.data?.emp}
                    setData={setFilterData}
                    filterdData={filterdData}
                    page={"Department"}
                    keys={["name"]}
                    // selectableRowSelected={rowSelectCritera}
                    selectableRowsSelected={rowSelectCritera}
                    selectableRowsVisibleOnly={false}
                    selectableRows
                    selectedRows={selectedRows}
                    selectableRowsHighlight={false}
                    highlightOnHover
                    pagination
                    onSelectedRowsChange={handleSelectedRowsChange}
                    titlebuttom={"Assign"}
                    isSubHeaderaction={true}
                    onClick={AssignEmpdeps}
                    selectableRowPersistence
                    options={{
                      selectableRowsHeader: false,
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  className="btn btn-block"
                  style={{
                    backgroundColor: "#232c59",
                    float: "right",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginRight: "15px",
                  }}
                  onClick={AssignEmpdeps}
                >
                  <b className="text-light">Assign</b>
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showunassingeemp}
            onHide={handleCloseunassingeemp}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Unassign Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "500px", overflowY: "scroll" }}>
              {/* <button
                        type="submit"
                        className="btn btn-block"
                        style={{ backgroundColor: '#232c59', float:'right', marginTop: '5px',marginBottom
                        :'5px',marginRight:'15px' }} onClick={AssignEmpdeps}>
                        <b className='text-light'>Assign</b>
                        </button> */}
              <DataTable
                columns={columns1}
                loading={loading}
                data={employeedid}
                setData={setFilterData}
                // keys={['email_id', 'emp_code', 'name', 'designation','cont']}
                filterdData={filterdData}
                page={"Department"}
                keys={["name"]}
                selectableRows
                highlightOnHover
                pagination
                selectableRowsSelected={shouldSelectRow}
                onSelectedRowsChange={handleSelectedRowsChange}
                // paginationRowsPerPageOptions={5}
                // noDataComponent="No Department Found"
                isSubHeaderaction={true}
                titlebuttom={"Unassign"}
                onClick={UnassignEmpdeps}
              />
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  );
};
export default Departmentist;
