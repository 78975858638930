import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Card,
  Button,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import employeeModel from "../Model/employee.Model";
import moment from "moment";
import WeekOffDay from "./Components/WeekOffDay";
import Swal from "sweetalert2";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { Radio } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { fetchAlldepartments } from "../store/silce/bussiness";
import Department from "./Components/Department";
import SubDepartment from "./Components/SubDepartment";
function Staffdetails() {
  const [empprivew, setEmpPrivew] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const params = useParams();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const busid = useSelector((state) => state?.selectOption?.value);
  const DepartmentData = useSelector((state) => {
    return state.Business.Alldepartments;
  });
  const SubDepartmentData = useSelector((state) => {
    return state?.Business?.AllSubdepartments;
  });
  useEffect(() => {
    dispatch(fetchAlldepartments());
  }, []);
  useEffect(() => {
    if (params?.id) {
      const formdata = new FormData();
      formdata.append("edit_emp", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      formdata.append("emp_id", params?.id);
      employeeModel
        .Editemplyee(formdata)
        .then((respone) => {
          setEmpPrivew(respone?.data?.emp);
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    }
  }, [busid]);

  const navigate = useNavigate();
  const Admindetails = useSelector(
    (state) => state?.dashboard?.home?.data?.roleName
  );
  const datahai = () => {
    if (Admindetails === "client") {
      navigate("/stafflist");
    } else {
      navigate("/stafflists");
    }
  };

  const employeeDetails = useSelector(
    (state) => state?.employee?.Employee?.data?.permissions
  );
  const filteredData =
    employeeDetails &&
    employeeDetails?.filter((item) => item?.name === "employees");
  const [formattedDate, setFormattedDate] = useState(null);
  console.log(empprivew, "empprivew11");
  const [formattedStartTime, setFormattedStartTime] = useState(null);
  const [formattedEndTime, setFormattedEndTime] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);

  const [attendance_type, setattendance_type] = useState("");
  const [attendance_type_ar, setattendance_type_ar] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [weekArray, setWeekArray] = useState([]);
  const displayValue = selectedSubDepartment || selectedDepartment;
  const tempWeekAr = [
    { value: "sunday", name: "Sunday", checked: false, selectedWeek: "" },
    { value: "monday", name: "Monday", checked: false, selectedWeek: "" },
    { value: "tuesday", name: "Tuesday", checked: false, selectedWeek: "" },
    { value: "wednesday", name: "Wednesday", checked: false, selectedWeek: "" },
    { value: "thursday", name: "Thursday", checked: false, selectedWeek: "" },
    { value: "friday", name: "Friday", checked: false, selectedWeek: "" },
    { value: "saturday", name: "Saturday", checked: false, selectedWeek: "" },
  ];
  const [yearsPassed, setYearsPassed] = useState(null);
  const calculateAndShowResult = (date) => {
    const selectedDateObj = new Date(date);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceMs = currentDate.getTime() - selectedDateObj.getTime();

    // Convert milliseconds to years
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365);

    console.log("Difference in years:", differenceYears);

    // Round down to get the whole number of years
    const yearsPassed = Math.floor(differenceYears);

    // Set the state to display the number of years passed
    setYearsPassed(yearsPassed);
  };
  const handleDateChangee = (event) => {
    const selectedDate = event.target.value;
    setFormattedDate(selectedDate);
    calculateAndShowResult(selectedDate);
  };

  useEffect(() => {
    if (empprivew) {
      if (empprivew?.dob !== "") {
        setFormattedDate(
          moment(empprivew?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        );
        calculateAndShowResult(
          moment(empprivew?.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        );
      }

      if (empprivew?.start_time !== "") {
        setFormattedStartTime(
          moment(empprivew?.start_time, "hh:mm A").format("HH:mm")
        );
      }
      if (empprivew?.end_time !== "") {
        setFormattedEndTime(
          moment(empprivew?.end_time, "hh:mm A").format("HH:mm")
        );
      }
      if (empprivew?.emp_week_off) {
        Object.keys(empprivew?.emp_week_off).forEach((curDay) => {
          const weekCount = empprivew?.emp_week_off[curDay].week_count;
          if (weekCount !== "") {
            const weekIndex = tempWeekAr.findIndex(
              (day) => day.value === curDay
            );
            if (weekIndex !== undefined) {
              if (!selectedWeeks.includes(curDay)) {
                setSelectedWeeks((prevSelectedWeeks) => [
                  ...prevSelectedWeeks,
                  curDay,
                ]);
              }
              tempWeekAr[weekIndex].checked = true;
              tempWeekAr[weekIndex].selectedWeek = weekCount;
            }
          }
        });
      }
      setWeekArray(tempWeekAr);
      setIsLoading(false);
    }
  }, [empprivew, busid]);
  const newDataUpadfwe = empprivew?.attendance_type;
  const [showComponent, setShowComponent] = useState(false);
  const [formData, setFormData] = useState({
    emp_name: "",
    emp_cont: "",
    emp_desg: "",
    emp_email: "",
    start_time: "",
    start_end: "",
    mname: "",
    mothers_name: "",
    fathers_name: "",
    husband_name: "",
    paid_leave: "",
    att_basis: "",
    dob: "",
    salary: "",
    profile_img: "",
    epf: "",
    eepf: "",
    emp_code: "",
    emp_week_off: [
      {
        day: "",
        week_count: "",
      },
    ],
    identification_mark:"",
    nearest_police_station:""
  });
  const [num, setNum] = useState("");
  const [Newvalue, setNewvalue] = useState(newDataUpadfwe);
  const [NewDepartment, setNewDepartment] = useState("");
  const handleDepartmentSelect = (value) => {
    setSelectedDepartment(value);
    setSelectedSubDepartment();
  };

  const handleSubDepartmentSelect = (value) => {
    setSelectedSubDepartment(value);
  };
  console.log(NewDepartment, "NewDepartment");
  const limit = 10;
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (event.target.type === "file") {
      const imageFile = event.target.files[0];
      if (imageFile) {
        setSelectedImage(URL.createObjectURL(imageFile));
      }
    }
    let value = event.target.value;
    if (event.target.type === "number") {
      value = value.length > limit ? value.substring(0, limit) : value;
    }
    console.log(value, event.key, event, num);

    event.target.value = value;
    var checkbox1 = document.getElementById("Selfie");
    var checkbox2 = document.getElementById("Scan");
    if (checkbox1.checked && checkbox2.checked) {
      var combinedValue = checkbox1.value + "," + checkbox2.value;
      console.log(combinedValue, "combinedValue");
      setNewvalue(combinedValue);
    } else if (checkbox1.checked) {
      setNewvalue(checkbox1.value);
    } else if (checkbox2.checked) {
      setNewvalue(checkbox2.value);
    }
  };
  const getCheckedCheckboxes = (inputName) => {
    const checkboxes = document.querySelectorAll(
      'input[name="' + inputName + 'WeekCount"]:checked'
    );
    const checkedValues = [];

    checkboxes.forEach((checkbox) => {
      checkedValues.push(checkbox.value);
    });

    return checkedValues.join(",");
  };
  const [input, setInput] = useState("");
  // if(displayValue === ''){
  //   setNewDepartment(selectedDepartment)
  // }else{
  //   setNewDepartment(selectedSubDepartment)
  // }
  const fromDate = moment(formattedDate).format("DD-MM-YYYY");

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("emp_id", params?.id);
    let attenType = "";
    if (Newvalue === undefined) {
      attenType = empprivew?.attendance_type;
    } else {
      attenType = Newvalue;
    }
    let deptType = "";
    if (displayValue === null) {
      deptType = empprivew?.department_id;
    } else {
      deptType = displayValue;
    }
    form.append("emp_att_type", attenType);
    form.append("department_id", deptType);
    form.append("update_employee", localStorage.getItem("token"));
    form.append("dob", fromDate);
    // let attenType = ", manual, ";
    // if (attendance_type !== "") {
    //   attenType = attendance_type + ", manual, ";
    // }
    // form.append("emp_att_type", attenType);
    if (selectedWeeks.length > 0) {
      selectedWeeks.map((curWeek, index) => {
        form.append("emp_week_off[" + index + "][day]", curWeek);
        form.append(
          "emp_week_off[" + index + "][week_count]",
          getCheckedCheckboxes(curWeek)
        );
      });
    }

    employeeModel
      .updateemp(form)
      .then((response) => {
        console.log(response.data, "yes data update");
        Swal.fire({
          title: "Are you sure to update data?",
          text: " This action cannot be undone and you will be unable to recover any data.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, update it!",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire("Update!", "Your data is update.", "success");
            datahai();
          }
          if (
            filteredData &&
            filteredData.length > 0 &&
            filteredData[0]?.add == "true"
          ) {
            window.location("./stafflists");
          } else {
            window.location("./stafflist");
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleimageClick = () => {
    inputRef.current.click();
  };
  const handleWeekChange = (event) => {
    const value = event.target.value;
    let att = [];
    if (event.target.checked) {
      att = [...attendance_type_ar, value];
      setattendance_type_ar([...attendance_type_ar, value]);
    } else {
      setattendance_type_ar(
        attendance_type_ar.filter((item) => item !== value)
      );
      att = attendance_type_ar.filter((item) => item !== value);
    }
    setattendance_type(att.join(", "));
  };
  useEffect(() => {
    console.log("selectedWeeks", selectedWeeks);
  }, [selectedWeeks, busid]);
  const [isDivVisible, setDivVisibility] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const handleRadioChange = (event) => {
    const value = event.target.value;
    setRadioValue(value);
    setDivVisibility(value === "married");
  };

  const [selectedOption, setSelectedOption] = useState(
    empprivew?.parent_department_id
  );
  console.log(empprivew?.parent_department_id, "fffffffffffffff");
  console.log(selectedOption, "selectedOptionqqq");
  useEffect(() => {
    setSelectedOption(empprivew?.parent_department_id);
  }, [empprivew?.parent_department_id, busid]);

  // console(selectedSubDepartment,  'selectedDepartment')
  return (
    <>
      <div>
        <Container fluid className="mb-0">
          <Row>
            <Col>
              {" "}
              <h4 className="text-center mt-0">Update Employee Details</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card className="box-shadow mt-3">
                <div className="m-5">
                  {!isLoading ? (
                    <form onSubmit={handleSubmit}>
                      <MDBRow style={{ marginLeft: "1px" }}>
                        <center>
                          <div
                            onClick={handleimageClick}
                            style={{ width: "25%" }}
                          >
                            {selectedImage ? (
                              <img
                                src={selectedImage}
                                alt=""
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "50%",
                                  marginBottom: "20px",
                                }}
                              />
                            ) : (
                              <img
                                src={`http://qrstaff.in/api/pro_pic/${empprivew?.profile_img}`}
                                alt=""
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  borderRadius: "50%",
                                  marginBottom: "20px",
                                }}
                              />
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleInputChange}
                              value={formData.profile_img}
                              name="profile_img"
                              style={{ display: "none" }}
                              ref={inputRef}
                            />
                          </div>
                          <p>Change Image</p>
                        </center>
                        <MDBRow>
                          <label
                            style={{
                              fontSize: "20px",
                              marginBottom: "20px",
                              backgroundColor: "lightgrey",
                              fontWeight: 500,
                              borderRadius: "6px",
                            }}
                          >
                            Basic Information
                          </label>
                          <MDBCol md="3">
                            <label>Select Title</label>
                            <Form.Select
                              style={{ width: "100%", height: "37px" }}
                              className="mt-2"
                              name="prefix"
                            >
                              <option value="" selected>
                                Select Title
                              </option>
                              <option
                                value="miss"
                                selected={"miss" === empprivew.prefix}
                              >
                                Miss
                              </option>
                              <option
                                value="Mr"
                                selected={"Mr" === empprivew.prefix}
                              >
                                Mr.
                              </option>
                              <option
                                value="Mr"
                                selected={"Mr" === empprivew.prefix}
                              >
                                Mrs.
                              </option>
                            </Form.Select>
                          </MDBCol>

                          <MDBCol md="3">
                            <label>First Name</label>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="form1"
                              type="text"
                              placeholder="Frist Name*"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              defaultValue={empprivew?.name}
                              name="emp_name"
                              required
                              onChange={handleInputChange}
                            />
                          </MDBCol>
                          <MDBCol md="3">
                            <span>Midlle Name</span>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="form1"
                              type="text"
                              placeholder="Midlle Name"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              defaultValue={empprivew.middle_name}
                              name="mname"
                              onChange={handleInputChange}
                            />
                          </MDBCol>

                          <MDBCol md="3">
                            <label>Last Name</label>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="form1"
                              type="text"
                              placeholder="Last Name *"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              defaultValue={empprivew?.last_name}
                              name="lname"
                              required
                              onChange={handleInputChange}
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="2">
                            <label className="mb-2">Date Of Birth</label>
                            <MDBInput
                              wrapperClass="mb-4"
                              type="date"
                              value={formattedDate}
                              // label="Date of Brith"
                              placeholder="dob"
                              onChange={handleDateChangee}
                              // id="form1"
                              required
                            />
                          </MDBCol>

                          <MDBCol md="1">
                            {yearsPassed && (
                              <p style={{ marginTop: "35px" }}>
                                {yearsPassed} Y/O
                              </p>
                            )}
                          </MDBCol>

                          <MDBCol md="3">
                            <span>Mother Name</span>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="form1"
                              type="text"
                              placeholder="Mother Name"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              defaultValue={empprivew.mothers_name}
                              name="mothers_name"
                              onChange={handleInputChange}
                            />
                          </MDBCol>

                          <MDBCol md="3">
                            <span>Father Name</span>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="form1"
                              type="text"
                              placeholder="Father Name"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              defaultValue={empprivew.fathers_name}
                              name="fathers_name"
                              onChange={handleInputChange}
                            />
                          </MDBCol>

                          <MDBCol md="3">
                            <label> Blood Group</label>

                            <Form.Select
                              name="blood_group"
                              id=""
                              style={{ width: "100%", height: "38px" }}
                              className="mt-2"
                            >
                              <option value="" selected>
                                Select Blood Group
                              </option>
                              <option
                                value="A+"
                                selected={"A+" == empprivew.blood_group}
                              >
                                A+
                              </option>
                              <option
                                value="A-"
                                selected={"A-" == empprivew.blood_group}
                              >
                                A-
                              </option>
                              <option
                                value="B+"
                                selected={"B+" == empprivew.blood_group}
                              >
                                B+
                              </option>
                              <option
                                value="B-"
                                selected={"B-" == empprivew.blood_group}
                              >
                                B-
                              </option>
                              <option
                                value="O+"
                                selected={"O+" == empprivew.blood_group}
                              >
                                O+
                              </option>
                              <option
                                value="O-"
                                selected={"O-" == empprivew.blood_group}
                              >
                                O-
                              </option>
                              <option
                                value="AB+"
                                selected={"AB+" == empprivew.blood_group}
                              >
                                AB+
                              </option>
                              <option
                                value="AB-"
                                selected={"AB-" == empprivew.blood_group}
                              >
                                AB-
                              </option>
                            </Form.Select>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="3">
                            <label>Gender</label>
                            <Form.Select
                              name="gender"
                              id=""
                              style={{
                                width: "100%",
                                height: "38px",
                                // marginLeft: "-10px",
                              }}
                              className="mt-2"
                            >
                              <option value="" selected>
                                Select Gender
                              </option>
                              <option
                                value="Male"
                                selected={"Male" == empprivew.gender}
                              >
                                Male
                              </option>
                              <option
                                value="Female"
                                selected={"Female" == empprivew.gender}
                              >
                                Female
                              </option>
                              <option
                                value="Other"
                                selected={"Other" == empprivew.gender}
                              >
                                Other
                              </option>
                            </Form.Select>
                          </MDBCol>
                          <MDBCol md="3">
                            <span> Staff ID</span>
                            <MDBInput
                              wrapperClass="mb-4"
                              id=""
                              type="text"
                              placeholder="Staff ID*"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              defaultValue={empprivew?.emp_code}
                              name="emp_code"
                              onChange={handleInputChange}
                            />
                          </MDBCol>
                         

                          <MDBCol md="3">
                            <div className="mt-2 ">
                              <p style={{ marginBottom: "-2px" }}>
                                {" "}
                                Select Marital Status
                              </p>
                              <label>
                                <input
                                  type="radio"
                                  name="marital_status"
                                  value="single"
                                  defaultChecked={
                                    empprivew?.marital_status === "Single"
                                  }
                                  onChange={handleRadioChange}
                                />
                                Unmarried
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="marital_status"
                                  value="married"
                                  defaultChecked={
                                    empprivew?.marital_status === "Married"
                                  }
                                  onChange={handleRadioChange}
                                />
                                Married
                              </label>

                              {isDivVisible && (
                                <div>
                                  <MDBInput
                                    type="date"
                                    name="marriage_dt"
                                    id=""
                                    defaultValue={empprivew?.marriage_dt}
                                  ></MDBInput>
                                </div>
                              )}
                            </div>
                          </MDBCol>
                          {isDivVisible && (
                            <MDBCol md="3">
                              <span> Spouse Name</span>
                              <MDBInput
                                wrapperClass="mb-4"
                                id=""
                                type="text"
                                placeholder="Spouse Name"
                                variant="outlined"
                                fullWidth
                                size="md"
                                className="mt-2"
                                defaultValue={empprivew.husband_name}
                                name="husband_name"
                                onChange={handleInputChange}
                              />
                            </MDBCol>
                          )}
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="6">
                            <span>Nearest Police Station </span>
                            <InputGroup>
                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="form1"
                                placeholder="Enter Nearest Police Station"
                                fullWidth
                                variant="outlined"
                                size="md"
                                name='nearest_police_station'
                                // value={num}
                                onChange={handleInputChange}
                                // value={formData.nearest_police_station}
                                defaultValue={empprivew?.nearest_police_station}
                     
                                type="text"
                                style={{ appearance: "textfield" }}
                                // onChange={handleChange}
                                // inputProps={{  }}
                              />
                            </InputGroup>
                          </MDBCol>
                          <MDBCol md="6">
                            <span> Idenificattion Mark</span>
                            <InputGroup>
                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="form1"
                                placeholder="Enter Idenificattion Mark"
                                fullWidth
                                variant="outlined"
                                size="md"
                                name='identification_mark'
                                // value={num}
                                // name="emp_cont"
                                onChange={handleInputChange}
                                // value={formData.identification_mark}
                                defaultValue={empprivew?.identification_mark}
                             
                                type="text"
                                style={{ appearance: "textfield" }}
                                // onChange={handleChange}
                                // inputProps={{  }}
                              />
                            </InputGroup>
                          </MDBCol>
                        </MDBRow>

                        {/* <MDBRow>
                          <label
                            style={{
                              fontSize: "20px",
                              marginBottom: "20px",
                              marginTop: "20px",
                              backgroundColor: "lightgrey",
                              fontWeight: 500,
                              borderRadius: "6px",
                            }}
                          >
                            Nominee Details
                          </label>

                          <MDBCol md="3">
                            <span>Nominee Name</span>
                            <InputGroup>
                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="form1"
                                placeholder="Enter Nominee Name"
                                fullWidth
                                variant="outlined"
                                size="md"
                                // value={num}
                                name="emp_cont"
                                required
                                type="text"
                                style={{ appearance: "textfield" }}
                                // onChange={handleChange}
                                // inputProps={{  }}
                              />
                            </InputGroup>
                          </MDBCol>
                          <MDBCol md="3">
                            <span>Nominee Email</span>
                            <InputGroup>
                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="form1"
                                placeholder="Enter Nominee Email"
                                fullWidth
                                variant="outlined"
                                size="md"
                                // value={num}
                                name="emp_cont"
                                required
                                type="email"
                                style={{ appearance: "textfield" }}
                                // onChange={handleChange}
                                // inputProps={{  }}
                              />
                            </InputGroup>
                          </MDBCol>
                          <MDBCol md="3">
                            <span> Phone Number</span>
                            <InputGroup>
                              <InputGroup.Text
                                id="basic-addon1"
                                className="mt-2"
                              >
                                +91
                              </InputGroup.Text>
                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="outlined-basic"
                                placeholder="Enter Your Number"
                                fullWidth
                                variant="outlined"
                                size="md"
                                // maxLength={10}
                                name="official_cont"
                                type="number"
                                pattern="[0-9]"
                                style={{ appearance: "textfield" }}
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  const truncatedMobileNumber =
                                    numericValue.slice(0, 10);
                                  e.target.value = truncatedMobileNumber;
                                }}
                                // inputProps={{  }}
                              />
                            </InputGroup>
                          </MDBCol>
                          <MDBCol md="3">
                            <label className="mb-2">
                              Nominee Date Of Birth
                            </label>
                            <MDBInput
                              wrapperClass="mb-4"
                              type="date"
                              // value={selectedDatee}
                              // label="Date of Brith"
                              placeholder="dob"
                              // onChange={handleDateChangee}
                              // id="form1"
                              required
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="3">
                            <span>Relation</span>
                            <InputGroup>
                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="form1"
                                placeholder="Enter Relation Type"
                                fullWidth
                                variant="outlined"
                                size="md"
                                // value={num}
                                name="emp_cont"
                                required
                                type="text"
                                style={{ appearance: "textfield" }}
                                // onChange={handleChange}
                                // inputProps={{  }}
                              />
                            </InputGroup>
                          </MDBCol>
                        </MDBRow> */}

                        <MDBRow>
                          <label
                            style={{
                              fontSize: "20px",
                              marginBottom: "20px",
                              marginTop: "20px",
                              backgroundColor: "lightgrey",
                              fontWeight: 500,
                              borderRadius: "6px",
                            }}
                          >
                            Contact Details
                          </label>

                          <MDBCol md="6">
                            <span>Official Phone Number</span>
                            <InputGroup>
                              <InputGroup.Text
                                id="basic-addon1"
                                className="mt-2"
                              >
                                +91
                              </InputGroup.Text>

                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="outlined-basic"
                                placeholder="Enter Official  Number"
                                fullWidth
                                variant="outlined"
                                size="md"
                                defaultValue={empprivew?.cont}
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  const truncatedMobileNumber =
                                    numericValue.slice(0, 10);
                                  e.target.value = truncatedMobileNumber;
                                }}
                                name="emp_cont"
                                required
                                type="number"
                                pattern="[0-9]"
                                style={{ appearance: "textfield" }}
                              />
                            </InputGroup>
                          </MDBCol>

                          <MDBCol md="6">
                            <span> Phone Number</span>

                            <InputGroup>
                              <InputGroup.Text
                                id="basic-addon1"
                                className="mt-2"
                              >
                                +91
                              </InputGroup.Text>
                              <Form.Control
                                wrapperClass="mb-4"
                                className="mt-2"
                                id="outlined-basic"
                                placeholder="Enter Your Number"
                                fullWidth
                                variant="outlined"
                                size="md"
                                defaultValue={empprivew?.official_cont}
                                name="official_cont"
                                type="number"
                                onChange={(e) => {
                                  const numericValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  const truncatedMobileNumber =
                                    numericValue.slice(0, 10);
                                  e.target.value = truncatedMobileNumber;
                                }}
                                pattern="[0-9]"
                                style={{ appearance: "textfield" }}
                              />
                            </InputGroup>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow className="mt-4">
                          <MDBCol md={6}>
                            <span> Official Email ID</span>

                            <MDBInput
                              wrapperClass="mb-4"
                              id="outlined-basic"
                              fullWidth
                              placeholder="Enter Official Email ID"
                              variant="outlined"
                              size="small"
                              defaultValue={empprivew?.email_id}
                              type="email"
                              name="emp_email"
                              required
                              className="mt-2"
                              onChange={handleInputChange}
                            />
                          </MDBCol>

                          <MDBCol md={6}>
                            <span> Email ID </span>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="outlined-basic"
                              fullWidth
                              placeholder=" Enter Email ID"
                              variant="outlined"
                              size="small"
                              defaultValue={empprivew.official_email}
                              type="email"
                              name="official_email"
                              className="mt-2"
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <label
                            style={{
                              fontSize: "20px",
                              marginBottom: "20px",
                              marginTop: "20px",
                              backgroundColor: "lightgrey",
                              fontWeight: 500,
                              borderRadius: "6px",
                            }}
                          >
                            Employee Professional Details
                          </label>

                          <MDBCol md="4">
                            <span>Desgination</span>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="outlined-basic"
                              placeholder="Enter Desgination "
                              fullWidth
                              variant="outlined"
                              size="small"
                              defaultValue={empprivew.designation}
                              name="emp_desg"
                              required
                              className="mt-2"
                              onChange={handleInputChange}
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <span>Employee Status</span>

                            <Form.Select
                              name="emp_status"
                              id=""
                              style={{ width: "100%", height: "38px" }}
                              className="mt-2"
                            >
                              <option value="" disabled selected>
                                Emplyee Status
                              </option>

                              {/* <option
                                value="Active"
                                selected={"Active" == empprivew.emp_status}
                              >
                                Active
                              </option> */}
                              <option
                                value="Probation"
                                selected={"Probation" == empprivew.emp_status}
                              >
                                Probation
                              </option>
                              {/* <option
                                value="Inactive"
                                selected={"Inactive" == empprivew.emp_status}
                              >
                                Inactive
                              </option> */}
                              <option
                                value="Full-Time"
                                selected={"Full-Time" == empprivew.emp_status}
                              >
                                Full-Time
                              </option>
                              <option
                                value="Part-Time"
                                selected={"Part-Time" == empprivew.emp_status}
                              >
                                Part-Time
                              </option>

                              <option
                                value="Termination"
                                selected={"Termination" == empprivew.emp_status}
                              >
                                Termination
                              </option>
                              <option
                                value="Resigned"
                                selected={"Resigned" == empprivew.emp_status}
                              >
                                Resigned
                              </option>
                            </Form.Select>
                          </MDBCol>

                          <MDBCol md={4}>
                            <label>Monthly Salary Amount</label>
                            {/* <MDBInput
                            wrapperClass="mb-4"
                            placeholder="Monthly salary amount"
                            type="number"
                            required
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.salary}
                            name="salary"
                            className="mt-2"
                            pattern="[0-9]"
                            style={{ appearance: "textfield" }}
                            onChange={handleInputChange}
                          /> */}

                            <MDBInput
                              wrapperClass="mb-4"
                              placeholder="Enter Monthly Salary Amount"
                              type="number"
                              required
                              variant="outlined"
                              fullWidth
                              size="small"
                              defaultValue={empprivew.salary}
                              name="salary"
                              className="mt-2"
                              pattern="[0-9]"
                              style={{ appearance: "textfield" }}
                              onChange={handleInputChange}
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md={4}>
                            <label className="mb-2">UAN Number</label>

                            <MDBInput
                              wrapperClass="mb-4"
                              type="text"
                              name="uan_number"
                              label=""
                              placeholder="Enter UAN Number"
                              defaultValue={empprivew.uan_number}
                              id=""
                              onChange={handleInputChange}
                            />
                          </MDBCol>

                          <MDBCol md={4}>
                            <label>ESI Number</label>

                            <MDBInput
                              wrapperClass="mb-4"
                              id=""
                              type="text"
                              placeholder="Enter ESI Number *"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              defaultValue={empprivew.esi_no}
                              name="esi_no"
                              onChange={handleInputChange}
                            />
                          </MDBCol>

                          <MDBCol md={4}>
                            <label>PF Number</label>
                            <MDBInput
                              wrapperClass="mb-4"
                              id="form1"
                              type="text"
                              placeholder="Enter PF Number"
                              variant="outlined"
                              fullWidth
                              size="md"
                              className="mt-2"
                              value={formData.pf_no}
                              name="pf_no"
                              defaultValue={empprivew.pf_no}
                              onChange={handleInputChange}
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md="6">
                            <label className="mt-2">Department</label>
                            <Department
                              onSelect={setSelectedOption}
                              onSelectDepartment={handleDepartmentSelect}
                              data={empprivew}
                              id="select1"
                            />
                          </MDBCol>

                          <MDBCol md="6">
                            <label className="mt-2">Sub-Department</label>
                            <SubDepartment
                              selectedOption={selectedOption}
                              onSelectSubDepartment={handleSubDepartmentSelect}
                              datas={empprivew}
                              id="select2"
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol md={6}>
                            <Form.Check // prettier-ignore
                              type="switch"
                              label="Do you calculate PF ?"
                              className="mt-5 ml-1"
                              checked={showComponent}
                              onChange={() => setShowComponent(!showComponent)}
                            />
                            {showComponent ? (
                              <>
                                <MDBRow>
                                  <MDBCol md="6">
                                    <MDBInput
                                      wrapperClass="mb-4"
                                      type="text"
                                      placeholder="Employee Contribution(Amount)"
                                      value={formData.epf}
                                      name="epf"
                                      onChange={handleInputChange}
                                      style={{
                                        width: "100%",
                                        height: "40px",
                                        marginTop: "4px",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <MDBInput
                                      wrapperClass="mb-4"
                                      type="text"
                                      placeholder="Employer Contribution(Amount)"
                                      value={formData.eepf}
                                      name="eepf"
                                      onChange={handleInputChange}
                                      style={{
                                        width: "100%",
                                        height: "40px",
                                        marginTop: "4px",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </>
                            ) : (
                              ""
                            )}
                          </MDBCol>

                          <MDBCol md="3">
                            <Form.Check
                              className="mt-5 ms-5"
                              value="selfie + gps"
                              defaultChecked={
                                empprivew.attendance_type === "selfie + gps"
                                  ? true
                                  : empprivew.attendance_type ===
                                    "selfie + gps, manual, "
                                  ? true
                                  : empprivew.attendance_type ===
                                    "selfie + gps,qr scan + gps, manual, "
                                  ? true
                                  : empprivew.attendance_type ===
                                    "qr scan + gps, selfie + gps, manual, "
                                  ? true
                                  : empprivew.attendance_type ===
                                    "selfie + gps,qr scan + gps"
                              }
                              // name="emp_att_type"
                              onChange={handleInputChange}
                              control={<Radio />}
                              id="Selfie"
                              label="Selfie + GPS"
                              style={{
                                position: "relative",
                                float: "left",
                                marginTop: "10px",
                                paddingLeft: "50px",
                              }}
                            />
                          </MDBCol>

                          <MDBCol md="3">
                            <Form.Check
                              value="qr scan + gps"
                              // name="emp_att_type"
                              control={<Radio />}
                              defaultChecked={
                                empprivew.attendance_type === "qr scan + gps"
                                  ? true
                                  : empprivew.attendance_type ===
                                    "qr scan + gps"
                                  ? true
                                  : empprivew.attendance_type ===
                                    "qr scan + gps, manual, "
                                  ? true
                                  : empprivew.attendance_type ===
                                    "selfie + gps,qr scan + gps, manual, "
                                  ? true
                                  : empprivew.attendance_type ===
                                    "qr scan + gps, selfie + gps, manual, "
                                  ? true
                                  : empprivew.attendance_type ===
                                    "selfie + gps,qr scan + gps"
                              }
                              className="mt-5"
                              label="QR Code Scan + GPS"
                              id="Scan"
                              onChange={handleInputChange}
                              style={{
                                position: "relative",
                                float: "left",
                                marginTop: "10px",
                              }}
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <label
                            style={{
                              fontSize: "20px",
                              marginBottom: "20px",
                              marginTop: "20px",
                              backgroundColor: "lightgrey",
                              fontWeight: 500,
                              borderRadius: "6px",
                            }}
                          >
                            Working Schedule
                          </label>

                          <MDBCol md={6}>
                            <MDBRow>
                              <MDBCol md={3}>
                                <span>
                                  <b>Start Time</b>
                                </span>
                              </MDBCol>
                              <MDBCol md={7}>
                                <MDBInput
                                  wrapperClass="mb-4"
                                  type="time"
                                  defaultValue={formattedStartTime}
                                  name="start_time"
                                  required
                                  className="mb-8"
                                  onChange={handleInputChange}
                                  id=""
                                  // label="Start Time"
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    paddingLeft: "10px",
                                  }}
                                />
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol md={6}>
                            <MDBRow>
                              <MDBCol md={3}>
                                <label>
                                  <b>End Time</b>
                                </label>
                              </MDBCol>
                              <MDBCol md={7}>
                                <MDBInput
                                  wrapperClass="mb-4"
                                  type="time"
                                  defaultValue={formattedEndTime}
                                  name="start_end"
                                  required
                                  className="mb-8"
                                  onChange={handleInputChange}
                                  id=""
                                  // label="End Time"
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    paddingLeft: "10px",
                                    position: "relative",
                                  }}
                                />
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>

                          <MDBCol md="12">
                            <label className="mb-2">
                              <b>Week Off Days</b>
                            </label>
                            {/* <FormLabel
                            id="demo-form-control-label-placement"
                            className="mt-3"
                          >
                            Week Off Days
                          </FormLabel> */}
                            {weekArray?.map((weekdata, index) => {
                              return (
                                <WeekOffDay
                                  key={index}
                                  curWeekData={weekdata}
                                  selectedWeeks={selectedWeeks}
                                  setSelectedWeeks={setSelectedWeeks}
                                />
                              );
                            })}
                          </MDBCol>
                        </MDBRow>
                        <div className="d-flex justify-content-end w-100">
                          <Button
                            style={{
                              float: "right",
                              borderStyle: "none",
                              marginRight: "5%",
                            }}
                            className="btn submitButtons w-25"
                            type="submit"
                            variant="primary"
                          >
                            Submit
                          </Button>
                        </div>
                      </MDBRow>
                    </form>
                  ) : (
                    <div>Please Wait</div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Staffdetails;
