import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAlllevels, unAssignLevel } from '../../store/silce/levelSlice';

const UnassignedModal = ({ open, close }) => {
    const selectedLevel = useSelector((state) => state.level.selectedLevel);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        unassign_emp: localStorage.getItem("token"),
        bus_id: localStorage.getItem('selecteoption'),
        emp_id: '',
    });
    console.log('modal called');

    const handleclose = () => {
        close(false);
    };

    const handleRadioChange = (empId) => {
        setFormData({
            ...formData,
            emp_id: empId,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(unAssignLevel(formData));
        dispatch(fetchAlllevels())
        handleclose()
    };

    console.log({ formData: formData })

    useEffect(() => { }, [formData, selectedLevel])

    return (
        <div>

            <Modal show={open} onHide={handleclose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Unassign Level</Modal.Title>
                </Modal.Header>
                <form onSubmit={onSubmit}>
                    <Modal.Body>
                        <>
                        {!selectedLevel?.assigned_emp && <h5 className="mb-3 p-3">Assigned levels data not available</h5>}
                        </>
                        < >
                       
                            {selectedLevel?.assigned_emp?.map((data) => (
                                <div key={`inline-${data?.id}`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label={`${data?.name} (${data?.id})`}
                                        type="radio"
                                        onChange={() => handleRadioChange(data?.id)}
                                        checked={formData.emp_id === data?.id}
                                    />
                                </div>
                            ))}
                        </>
                        <Modal.Footer>
                            <Button className="w-25" variant="secondary" onClick={handleclose}>
                                Cancel
                            </Button>
                            <Button className="bg-danger w-25" type="submit" variant="danger" >
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal.Body>

                </form>

            </Modal>
        </div>
    );
};

export default UnassignedModal;
