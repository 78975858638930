import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import WeekOffDayCheckbox from './WeekOffDayCheckbox';
const WeekOffDay = ({ curWeekData, selectedWeeks, setSelectedWeeks }) => {
    const [showWeek, setShowWeek] = useState(curWeekData?.checked);
    const weekCount = 5;
    const handleWeekChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            setSelectedWeeks([...selectedWeeks, value]);
        } else {
            setSelectedWeeks(selectedWeeks.filter(item => item !== value));
        }
    };

  return (
    <div>

      <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        label={curWeekData?.name}
        checked={showWeek}
        value={curWeekData?.value}
        onChange={(e) => {
          setShowWeek(!showWeek);
          handleWeekChange(e)}}
      />
      {showWeek ? (
        <>
          <div>
            <Row>
              <Col>
                <div>
                  {Array.from({length: weekCount}).map((_, index) => {
                    const classval = index === 0 ? "ps-2" : "ms-4";
                    const dayValue = index + 1;
                    const isChecked =curWeekData?.selectedWeek?.includes(dayValue);

                    return (
                      <WeekOffDayCheckbox
                        isChecked={isChecked}
                        index={index}
                        classval={classval}
                        weekVal={curWeekData?.value}
                      />
                    );
                  })}
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default WeekOffDay;
