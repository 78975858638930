import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import moment from 'moment'

export const fetchHome = createAsyncThunk("dashboard/fetchHome", async () => {
  const formdata = new FormData();
  formdata.append("get_home", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  try {
    const response = await axiosInstance.post("/home.php", formdata);
    return response;
  } catch (error) {
    return error.response.data;
  }
});
export const setbuiHome = createAsyncThunk("dashboard/setbuiHome", async () => {
  const formdata = new FormData();
  formdata.append("get_home", localStorage.getItem("token"));
  // formdata.append("bus_id", localStorage.getItem("selecteoption"));
  try {
    const response = await axiosInstance.post("/home.php", formdata);
    return response;
  } catch (error) {
    return error.response.data;
  }
});
export const HomeStatics = createAsyncThunk("dashboard/HomeStatics", async () => {
  const formdata = new FormData();
  formdata.append("home_admin_statics", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  try {
    const response = await axiosInstance.post("/home.php", formdata)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const HomeStatus = createAsyncThunk("dashboard/HomeStatus", async ({status}) => {
  const formdata = new FormData();
  formdata.append("attendance_kpi_empList", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("att_type", status);
  try {
    const response = await axiosInstance.post("/home.php", formdata);
    console.log(response.data, 'shiavmhoanme')
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const EmpTimeLog = createAsyncThunk("dashboard/EmpTimeLog", async ({id,date}) => {
  const formdata = new FormData();
  formdata.append("emp_time_log", localStorage.getItem("token"));
  // formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("emp_id", id);
  formdata.append("log_date", date);
  try {
    const response = await axiosInstance.post("/home.php", formdata);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const fetchAttendance = createAsyncThunk("dashboard/fetchAttendance", async (_, { dispatch }) => {
  const formdata = new FormData();
  formdata.append("emp_att_list", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  try {
    const response = await axiosInstance.post("/attendance.php", formdata);
    console.log("res================", response.data.emp)
    // dispatch(setUndoattand(response.data.emp));
    dispatch(setFiltertoday(response.data?.emp))
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const notification = createAsyncThunk("dashboard/notification", async () => {
  const formdata = new FormData();
  formdata.append("get_admin_notification", localStorage.getItem("token"));
  try {
    const response = await axiosInstance.post("/notification.php", formdata);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const undoAttendance = createAsyncThunk("dashboard/undoAttendance", async ({ att_id: att_id }, { dispatch }) => {
  const formdata = new FormData();
  formdata.append("undo_attendance", localStorage.getItem("token"));
  formdata.append("attId", att_id);
  try {
    const response = await axiosInstance.post("/attendance.php", formdata);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const sendStatusToAPI = createAsyncThunk("dashboard/sendStatusToAPI", async ({ userTime: userTime, emp_id: emp_id, status: status }, { dispatch }) => {
  const momentTime = moment(userTime, 'HH:mm A'); const formattedTime = momentTime.format('hh:mm A');
  const formdata = new FormData();
  moment().format('LT');
  formdata.append("mark_attendance", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("mark_type", status)
  formdata.append("emp_id", emp_id)
  formdata.append("mark_time", formattedTime)
  try {
    const response = await axiosInstance.post("/attendance.php", formdata);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

export const fetchAllNotice = createAsyncThunk("dashboard/fetchAllNotice", async (_, { dispatch }) => {
  const formdata = new FormData();
  formdata.append("get_notice_history", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  try {
    const response = await axiosInstance.post("/notice.php", formdata);
    dispatch(setFiltertoday(response.data?.data))
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const noticedetailsID = createAsyncThunk("dashboard/noticedetailsID", async ({noticeId}) => {
  console.log(noticeId,"this is the id")
  const formdata = new FormData();
  formdata.append("notice_detail", localStorage.getItem("token"));
  formdata.append("notice_id",noticeId);
  try {
    const response = await axiosInstance.post("/notice.php", formdata);
  
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    home: null,
    errorMessage: "",
    loading: false,
    attendance: null,
    homeLoading: true,
    undoattand: null,
    filtertoday: [],
    notice:null,
    navLoading:true,
    Homedata:[],
    noticedetailsID:[],
    EmpTimelogo:[],
    statusData:false,
    // empTimeLogLoading:true,
  },
  reducers: {
    // You can add other non-async actions here if needed.
    setUndoattand: (state, action) => {
      state.undoattand = action.payload
    },
    setFiltertoday: (state, action) => {
      state.filtertoday = action.payload
    },
    setStatusData: (state, action) => {
      state.statusData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHome.pending, (state) => {
        state.homeLoading = true;
      })
      .addCase(fetchHome.fulfilled, (state, action) => {
        state.homeLoading = false;
        console.log("action", action)
        state.home = action.payload;
      })
      .addCase(fetchHome.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(setbuiHome.pending, (state) => {
        state.homeLoading = true;
      })
      .addCase(setbuiHome.fulfilled, (state, action) => {
        state.homeLoading = false;
        console.log("action", action)
        state.homeset = action.payload;
      })
      .addCase(setbuiHome.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(EmpTimeLog.pending, (state) => {
        state.homeLoading = true;
      })
      .addCase(EmpTimeLog.fulfilled, (state, action) => {
        state.homeLoading = false;
        console.log("action", action)
        state.home.EmpTimeLog = action.payload;
      })
      .addCase(EmpTimeLog.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      }) 
      .addCase(HomeStatics.pending, (state) => {
        state.Homedata = true;
      })
      .addCase(HomeStatics.fulfilled, (state, action) => {
        console.log("actionddddddd", action)
        state.Homedata = action.payload.data;
        state.homeLoading = false;
      })
      .addCase(HomeStatics.rejected, (state, { payload }) => {

        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(HomeStatus.pending, (state) => {
        state.Homedata = true;
        state.homeLoading = true;
      })
      .addCase(HomeStatus.fulfilled, (state, action) => {
        console.log("actionddddddd", action)
        state.HomeStatuss = action.payload.data;
        state.homeLoading = false;
      })
      .addCase(HomeStatus.rejected, (state, { payload }) => {

        state.loading = false;
        state.homeLoading = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchAttendance.pending, (state) => {
        state.loading = true;
        state.navLoading=true
      })
      .addCase(fetchAttendance.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action", action)
        state.attendance = action.payload;
        state.navLoading =false
      })
      .addCase(fetchAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
        state.navLoading =false
      })
      .addCase(notification.pending, (state) => {
        state.loading = true;
        state.navLoading=true
      })
      .addCase(notification.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action", action)
        state.notification = action.payload;
      
      })
      .addCase(notification.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
        state.navLoading =false
      })
      .addCase(sendStatusToAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendStatusToAPI.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action", action)
        state.attendance = action.payload;
      })
      .addCase(sendStatusToAPI.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(undoAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(undoAttendance.fulfilled, (state, action) => {
        state.loading = false;
        // state.attendance = action.payload;
      })
      .addCase(undoAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchAllNotice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.notice = action.payload;
      })
      .addCase(fetchAllNotice.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(noticedetailsID.pending, (state) => {
        state.loading = true;
      })
      .addCase(noticedetailsID.fulfilled, (state, action) => {
        state.loading = false;
        state.noticeID = action.payload;
      })
      .addCase(noticedetailsID.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
  },
});

// Export actions
export const { setUndoattand, setFiltertoday ,setStatusData } = dashboardSlice.actions;

// Export the reducer
export default dashboardSlice.reducer;
