import React, { useState, useEffect } from "react";
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import logo from "../Aseset/image/test.JPG";
import "../Aseset/css/staffview.css";
import Persoanl from "./staffview/persoanl";
import {
  Container,
  Row,
  Col,
  Card,
  Nav,
  Tab,
  Dropdown,
  Modal,
  Form,
  InputGroup,
  DropdownButton,
  Button,
  Spinner,
} from "react-bootstrap";
import Activity from "./staffview/activitylog";
import LeaveAllowance from "./staffview/LeaveAllowance";
import { Link } from "react-router-dom";
import Contact from "./staffview/Contact";
import Address from "./staffview/Address";
import Bank from "./staffview/Bank";
import Payrun from "./staffview/Payrun";
import Social from "./staffview/Social";
import Document from "./staffview/Document";
import Attendance from "./staffview/Attendance";
import Leave from "./staffview/Leave";
import Leavehistory from "./staffview/Jobhistory";
import SalaryOver from "./staffview/SalaryOver";
import Payslip from "./staffview/Payslip";
import { useParams } from "react-router-dom";
import employeeModel from "../Model/employee.Model";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBProgressBar,
  MDBProgress,
  MDBIcon,
  MDBListGroupItem,
  MDBListGroup,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCardHeader,
  MDBTypography,
} from "mdb-react-ui-kit";
import "./staff.css";
import "../App.css";
import { BsPersonCircle } from "react-icons/bs";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { GoReport } from "react-icons/go";
import { AiFillCalendar } from "react-icons/ai";
import { BsFiletypeDocx } from "react-icons/bs";
import { FaHistory } from "react-icons/fa";
import { BsPaypal } from "react-icons/bs";
import { BsBank2 } from "react-icons/bs";
import { LiaAddressCard } from "react-icons/lia";
import { AiTwotonePhone } from "react-icons/ai";
import { AiOutlineLink } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { editEmployee } from "../store/silce/staffSlice";
import Loader from "../components/Common/Loading";
import moment from "moment";
import { FaArrowRight } from "react-icons/fa";
const Staffview = () => {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [Setempstats, setSetempstats] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  // const [employeeDetails, setEmpPrivew] = useState();
  const [Emphist, setEmphist] = useState();
  const [show, setShow] = useState(false);
  const handleClose3 = () => setSetempstats(false);
  const handleClose = () => setShowModal(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShowModal1(false);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShowModal2(false);
  const [show3, setShow3] = useState(false);
  const handleClose4 = () => setShow3(false);
  const loading = useSelector((state) => state.staff.loading);
  const employeeDetails = useSelector(
    (state) => state?.staff?.employeeDetails?.emp
  );
  const dispatch = useDispatch();

  const employeeDetailsTopLevel = useSelector(
    (state) => state?.staff?.employeeDetails?.top_level
  );

  const busid = useSelector((state) => state?.selectOption?.value);
  const fetchData = async () => {
    console.log(params, "data");
    const formdata = new FormData();
    formdata.append("edit_emp", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("emp_id", params?.id);
    dispatch(editEmployee({ id: params?.id }));
  };

  const emphist = async () => {
    console.log(params, "data");
    const formdata = new FormData();
    formdata.append("get_employment_history", localStorage.getItem("token"));

    formdata.append("emp_id", params?.id);
    employeeModel
      .emphistory(formdata)
      .then((respone) => {
        console.log(respone, "employeeDetailsTophestiory");
        setEmphist(respone.data?.data[0]);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const [formData, setFormData] = useState({
    joining_date: "",
    joining_comment: "",
  });
  // console.log("employeeDetailsTopLevel", Emphist);
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("emp_code", employeeDetails?.emp_code);
    form.append("emp_id", params?.id);
    form.append("update_employee", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));

    employeeModel
      .updateemp(form)
      .then((response) => {
        console.log(response.data, "yes data update");

        // Display success message to the user
        // window.location.assign("/stafflist");
        fetchData();
        toggleModal();
        handleClose2();
      })
      .catch((error) => {
        console.log(error);
        // Display error message to the user
      });
  };
  const [formData1, setFormData1] = useState({
    joining_date: "",
    joining_comment: "",
  });
  const handleChange = (event) => {
    setFormData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("emp_id", params?.id);
    form.append("update_employee", localStorage.getItem("token"));
    employeeModel
      .updateemp(form)
      .then((response) => {
        console.log(response.data, "yes data update");

        fetchData();
        toggleModal1();
        // Display success message to the user
        // window.location.assign("/stafflist");
      })
      .catch((error) => {
        console.log(error);
        // Display error message to the user
      });
  };

  useEffect(() => {
    emphist();
    fetchData();
  }, [busid]);
  const toggleModal = () => {
    console.log("current = ", showModal);
    setShowModal(!showModal);
    setShow(!show);
  };
  const toggleModal1 = () => {
    console.log("current = ", showModal1);
    setShowModal1(!showModal1);
    setShow1(!show1);
  };
  const updatestatus = () => {
    console.log("current = ", showModal1);
    setSetempstats(!Setempstats);
    // setShow1(!show1);
  };

  const toggleModal2 = () => {
    setShowModal2(!showModal2);
    setShow2(!show2);
  };
  const toggleModal3 = () => {
    setShow3(!show3);
    setShow2(!show2);
  };

  //////////////////////////////////////////////////////////////////////////////////////////

  const [formSubData, setFormSUbData] = useState({
    emp_status: "",
  });
  const handlesubInputChange = (event) => {
    setFormSUbData({
      ...formSubData,
      [event.target.name]: event.target.value,
    });
  };
  const Statusbutton = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("emp_code", employeeDetails?.emp_code);
    form.append("emp_id", params?.id);
    form.append("update_employee", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));

    employeeModel
      .updateemp(form)
      .then((response) => {
        console.log(response.data, "yes data update");

        // Display success message to the user
        // window.location.assign("/stafflist");
        fetchData();
        handleClose3();
      })
      .catch((error) => {
        console.log(error);
        // Display error message to the user
      });
  };
  const [activeTab, setActiveTab] = useState(() => {
    // Retrieve active tab from localStorage on initial render
    const storedActiveTab = localStorage.getItem("activeTab");
    const validTabs = ["one", "two", "three"]; // Define your valid tab keys here

    // Check if storedActiveTab is valid or null
    const isValidTab = storedActiveTab && validTabs.includes(storedActiveTab);

    return isValidTab ? storedActiveTab : "one"; // Default to 'one' if no valid tab is stored
  });

  // Function to handle tab selection
  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
    localStorage.setItem("activeTab", eventKey); // Store active tab in localStorage
  };
  const handlePrint = () => {
    window.print();
  };

  console.log(employeeDetails, "employeeDetailssdkjch");

  return (
    <div>
      <section style={{ backgroundColor: "#eee" }}>
        <MDBContainer>
          <MDBRow className="p-2">
            <MDBCol className="bg-light rounded-3 p-2 ">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <span
                    style={{
                      marginTop: "px",
                      marginLeft: "10px",
                      fontSize: "19px",
                    }}
                  >
                    <b style={{ paddingLeft: "" }}> Employee Detail</b>
                  </span>
                  {/* <button onClick={handlePrint}>Print</button> */}
                </div>
                <div>
                  <Dropdown style={{ position: "relative", float: "right" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ backgroundColor: "#18285a", border: "none" }}
                    >
                      Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="mt-2">
                      {/* <Dropdown.Item>Add attendance</Dropdown.Item> */}
                      <Dropdown.Item onClick={toggleModal3}>
                        Resign
                      </Dropdown.Item>

                      <Dropdown.Item onClick={toggleModal2}>
                        Terminate
                      </Dropdown.Item>
                      <Dropdown.Item onClick={toggleModal}>
                        Edit salary
                      </Dropdown.Item>
                      <Dropdown.Item onClick={toggleModal1}>
                        Add joining date
                      </Dropdown.Item>
                      <Dropdown.Item onClick={updatestatus}>
                        {" "}
                        Employee Status
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </MDBCol>
          </MDBRow>

          <section className="w-100" style={{ backgroundColor: "#f4f5f7" }}>
            <MDBContainer className=" h-100">
              <MDBRow className="justify-content-center align-items-center h-75">
                <MDBCol lg="12" className="mb-4 mb-lg-0">
                  {loading ? (
                    <center>
                      <Spinner animation="grow" />
                      <p>Please Wait Data is Loading...</p>
                    </center>
                  ) : (
                    <MDBCard className="mb-3" style={{ borderRadius: ".5rem" }}>
                      <MDBRow className="g-0">
                        <MDBCol
                          md="4"
                          className="gradient-custom text-center text-white"
                          style={{
                            borderTopLeftRadius: ".5rem",
                            borderBottomLeftRadius: ".5rem",
                          }}
                        >
                          <MDBCardImage
                            className=" "
                            style={{
                              width: "150px",
                              borderRadius: "10px",
                              marginTop: "80px",
                              height: "150px",
                            }}
                            src={`https://qrstaff.in/api/pro_pic/${employeeDetails?.profile_img}`}
                            alt="avatar"
                            fluid
                          />
                          <h4 className="mt-3">
                            {" "}
                            {employeeDetails?.prefix} {employeeDetails?.name}
                            <br />
                          </h4>
                          {employeeDetails?.emp_status === "Active" ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "green",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  width: "40%",
                                  padding: "5px",
                                  borderStyle: "none",
                                }}
                              >
                                <b className="text-light">
                                  {employeeDetails?.emp_status}
                                </b>
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>
                          )}
                          {employeeDetails?.emp_status === "Inactive" ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "red",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  width: "40%",
                                  padding: "5px",
                                  borderStyle: "none",
                                }}
                              >
                                <b className="text-light">
                                  {employeeDetails?.emp_status}
                                </b>
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>
                          )}
                          {employeeDetails?.emp_status === "Probation" ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "orange",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  width: "40%",
                                  padding: "5px",
                                  borderStyle: "none",
                                }}
                              >
                                <b className="text-light">
                                  {employeeDetails?.emp_status}
                                </b>
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>
                          )}
                          {employeeDetails?.emp_status === "Full-Time" ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "#be3910",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  width: "40%",
                                  padding: "5px",
                                  borderStyle: "none",
                                }}
                              >
                                <b className="text-light">
                                  {employeeDetails?.emp_status}
                                </b>
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>
                          )}
                          {employeeDetails?.emp_status === "Part-Time" ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "blue",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  width: "40%",
                                  padding: "5px",
                                  borderStyle: "none",
                                }}
                              >
                                <b className="text-light">
                                  {employeeDetails?.emp_status}
                                </b>
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>
                          )}
                          {employeeDetails?.emp_status === "Termination" ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "blue",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  width: "40%",
                                  padding: "5px",
                                  borderStyle: "none",
                                }}
                              >
                                <b className="text-light">
                                  {employeeDetails?.emp_status}
                                </b>
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>
                          )}
                          {employeeDetails?.emp_status === "Resigned" ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "blue",
                                  borderRadius: "8px",
                                  marginTop: "10px",
                                  width: "40%",
                                  padding: "5px",
                                  borderStyle: "none",
                                }}
                              >
                                <b className="text-light">
                                  {employeeDetails?.emp_status}
                                </b>
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>
                          )}
                        </MDBCol>
                        <MDBCol md="8">
                          <MDBCardBody className="p-4">
                            {/* <div>
                                                                <div style={{ lineHeight: '13px', paddingTop: '15px' }}>
                                                                    <p>{employeeDetails?.designation}</p>
                                                                    <p>{employeeDetails?.emp_code}</p>

                                                                </div>
                                                            </div> */}
                            <MDBRow className="pt-1">
                              <MDBCol size="6" className="mb-3">
                                <div className="top-card-div">
                                  <div
                                    style={{
                                      lineHeight: "13px",
                                      paddingTop: "15px",
                                    }}
                                  >
                                    <p>
                                      {" "}
                                      <span
                                        style={{
                                          color: "lightgray",
                                          paddingBottom: "1px",
                                        }}
                                      >
                                        Designation{" "}
                                      </span>
                                      - {employeeDetails?.designation}
                                    </p>
                                    <p>
                                      <span style={{ color: "lightgray" }}>
                                        {" "}
                                        Emp code{" "}
                                      </span>{" "}
                                      - {employeeDetails?.emp_code}
                                    </p>
                                  </div>
                                </div>
                              </MDBCol>
                              <MDBCol size="6" className="mb-3">
                                <div className="top-card-div">
                                  <div
                                    style={{
                                      lineHeight: "13px",
                                      paddingTop: "15px",
                                    }}
                                  >
                                    <p>
                                      <span style={{ color: "lightgray" }}>
                                        Role Name{" "}
                                      </span>{" "}
                                      - {employeeDetails?.roleName}
                                    </p>
                                    {/* <p>
                                      {" "}
                                      <span style={{ color: "lightgray" }}>
                                        Level Name{" "}
                                      </span>{" "}
                                      - {employeeDetails?.levelName}
                                    </p> */}
                                  </div>
                                  {/* <p className='card-text'><span style={{ color: 'lightgray' }}>Role Name 

                                                                        </span> <br />
                                                                        
                                                                        {employeeDetails?.roleName}</p> */}
                                </div>
                              </MDBCol>
                              <MDBCol size="12" className="mb-3">
                                <div
                                  style={
                                    {
                                      // lineHeight: "13px",
                                      // paddingTop: "15px",
                                    }
                                  }
                                >
                                  <p>
                                    <span style={{ color: "lightgray" }}>
                                      Manager 
                                    </span>

                                   - {employeeDetailsTopLevel &&
                                      employeeDetailsTopLevel?.map(
                                        (item, index) => (
                                          <React.Fragment key={index}>
                                            <span>
                                              {item &&
                                                item.emps &&
                                                item?.emps?.map(
                                                  (data, i, arr) => (
                                                    <React.Fragment key={i}>
                                                      <span>
                                                        {data?.name}(
                                                        {data?.designation})
                                                        {index <
                                                          employeeDetailsTopLevel?.length -
                                                            1 && (
                                                          <FaArrowRight />
                                                        )}
                                                      </span>
                                                    </React.Fragment>
                                                  )
                                                )}
                                            </span>
                                          </React.Fragment>
                                        )
                                      )}
                                  </p>
                                  {/* <p>
                                      <span style={{ color: "lightgray" }}>
                                        {" "}
                                        Emp code{" "}
                                      </span>{" "}
                                      - {employeeDetails?.emp_code}
                                    </p> */}
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <hr className="mt-0 mb-4" />
                            <MDBRow className="pt-1">
                              <MDBCol size="6" className="mb-3">
                                <div className="top-card-div">
                                  <span className="card-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      class="bi bi-bag"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                    </svg>
                                  </span>
                                  <p className="card-text">
                                    <span style={{ color: "lightgray" }}>
                                      Department
                                    </span>{" "}
                                    <br />{" "}
                                    {employeeDetails?.parent_department_name}{" "}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol size="6" className="mb-3">
                                <div className="top-card-div">
                                  <span className="card-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      class="bi bi-clock"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                    </svg>
                                  </span>
                                  <p className="card-text">
                                    <span style={{ color: "lightgray" }}>
                                      Shift Time
                                    </span>{" "}
                                    <br />
                                    {employeeDetails?.start_time}-
                                    {employeeDetails?.end_time}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>
                            <hr className="mt-0 mb-4" />
                            <MDBRow className="pt-1">
                              <MDBCol size="6" className="mb-3">
                                <div className="top-card-div">
                                  <span className="card-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      class="bi bi-currency-rupee"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z" />
                                    </svg>
                                  </span>
                                  <p className="card-text">
                                    <span style={{ color: "lightgray" }}>
                                      Salary
                                    </span>{" "}
                                    <br /> ₹ {employeeDetails?.salary}{" "}
                                    <span className="text-primary">
                                      (current)
                                    </span>{" "}
                                  </p>
                                </div>
                              </MDBCol>
                              <MDBCol size="6" className="mb-3">
                                <div className="top-card-div">
                                  <span className="card-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      class="bi bi-calendar-check"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                    </svg>
                                  </span>
                                  <p className="card-text">
                                    <span style={{ color: "lightgray" }}>
                                      Joining date
                                    </span>{" "}
                                    <br /> {employeeDetails?.joining_date}
                                  </p>
                                </div>
                              </MDBCol>
                            </MDBRow>

                            <div className="d-flex justify-content-start">
                              <a href="#!">
                                <MDBIcon fab icon="facebook me-3" size="lg" />
                              </a>
                              <a href="#!">
                                <MDBIcon fab icon="twitter me-3" size="lg" />
                              </a>
                              <a href="#!">
                                <MDBIcon fab icon="instagram me-3" size="lg" />
                              </a>
                            </div>
                          </MDBCardBody>
                        </MDBCol>
                      </MDBRow>
                    </MDBCard>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={activeTab}
            onSelect={handleSelect}
          >
            <MDBRow>
              <MDBCol lg="3">
                {/* <Tab.Container id="left-tabs-example" defaultActiveKey="one" > */}
                {/* <Nav> */}
                <MDBCard className="mb-4 mb-lg-0 mt-3">
                  <MDBCardBody className="p-0">
                    <MDBListGroup flush className="rounded-3">
                      <MDBListGroupItem className="d-flex align-items-center justify-content-start  gap-2 p-3">
                        <BsPersonCircle className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="one"
                              className="pt-2 staffview-active"
                            >
                              Employee Details
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <BsFillCalendarCheckFill className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="three"
                              className="pt-2 staffview-active"
                            >
                              Leave Allowance
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <GoReport className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="four"
                              className="pt-2 staffview-active"
                            >
                              Attendance Report
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <AiFillCalendar className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="five"
                              className="pt-2 staffview-active"
                            >
                              Leave History
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <BsFiletypeDocx className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="six"
                              className="pt-2 staffview-active"
                            >
                              Document
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <FaHistory className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="eight"
                              className="pt-2 staffview-active"
                            >
                              Job History
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <BsPaypal className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="eleven"
                              className="pt-2 staffview-active"
                            >
                              Salary Payrun And Badge
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <BsBank2 className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="twevel"
                              className="pt-2 staffview-active"
                            >
                              Bank Details
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                      {/* <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                                                <LiaAddressCard className='fs-4' />
                                                <MDBCardText>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="thirteen" className='pt-2 staffview-active'>Address Details</Nav.Link>
                                                    </Nav.Item>
                                                </MDBCardText>
                                            </MDBListGroupItem> */}
                      {/* <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                                                <AiTwotonePhone className='fs-4' />
                                                <MDBCardText>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="fourteen" className='pt-2 staffview-active'>Emergency Contacts</Nav.Link>
                                                    </Nav.Item>
                                                </MDBCardText>
                                            </MDBListGroupItem> */}
                      <MDBListGroupItem className="d-flex justify-content-start gap-2 align-items-center p-3">
                        <AiOutlineLink className="fs-4" />
                        <MDBCardText>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fifteen"
                              className="pt-2 staffview-active"
                            >
                              Social Links
                            </Nav.Link>
                          </Nav.Item>
                        </MDBCardText>
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
                {/* </Nav> */}
                {/* </Tab.Container> */}
              </MDBCol>
              <MDBCol lg="9">
                {loading ? (
                  <center className="mt-5">
                    <Loader />
                    <p>Please Wait Data is Loading...</p>
                  </center>
                ) : (
                  <Tab.Content>
                    <Tab.Pane eventKey="one">
                      <Persoanl />
                    </Tab.Pane>
                    <Tab.Pane eventKey="tow">
                      <Activity />
                    </Tab.Pane>
                    <Tab.Pane eventKey="three">
                      <LeaveAllowance />
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                      <Attendance />
                    </Tab.Pane>
                    <Tab.Pane eventKey="five">
                      <Leave />
                    </Tab.Pane>
                    <Tab.Pane eventKey="six">
                      <Document />
                    </Tab.Pane>
                    <Tab.Pane eventKey="seven">
                      <Activity />
                    </Tab.Pane>
                    <Tab.Pane eventKey="eight">
                      <Leavehistory />
                    </Tab.Pane>
                    <Tab.Pane eventKey="nine">
                      <SalaryOver />
                    </Tab.Pane>
                    <Tab.Pane eventKey="ten">
                      <Payrun />
                    </Tab.Pane>
                    <Tab.Pane eventKey="eleven">
                      <Payslip />
                    </Tab.Pane>
                    <Tab.Pane eventKey="twevel">
                      <Bank />
                    </Tab.Pane>
                    <Tab.Pane eventKey="thirteen">
                      <Address />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourteen">
                      <Contact />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifteen">
                      <Social />
                    </Tab.Pane>
                  </Tab.Content>
                )}
              </MDBCol>
            </MDBRow>
          </Tab.Container>
        </MDBContainer>
      </section>

      {Setempstats && (
        <Modal show={Setempstats} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Update Employee Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <form onSubmit={Statusbutton}>
                <Container>
                  <Row>
                    <Col sm={4}>
                      {" "}
                      <label
                        style={{
                          fontSize: "16px",
                          margin: "2px",
                          fontWeight: "600",
                        }}
                      >
                        Employee Status
                      </label>
                    </Col>
                    <Col sm={8}>
                      <Form.Select
                        name="emp_status"
                        id="form1"
                        required
                        style={{ width: "100%", height: "38px" }}
                        className=""
                      >
                        <option value="" disabled selected>
                          Select Employee Status
                        </option>
                        {/* <option value="A+">Emplyee Status</option> */}
                        {/* <option value="Active">Active</option> */}
                        <option value="Probation">Probation</option>
                        {/* <option value="Inactive">Inactive</option> */}
                        <option value="Full-Time">Full-Time</option>
                        <option value="Part-Time">Part-Time</option>
                        {/* <option value="A+">O-</option>
                              <option value="A+">AB+</option>
                              <option value="A+">AB-</option> */}
                      </Form.Select>
                    </Col>
                  </Row>
                  {/* <Row className='mt-2'>
                                        <Col sm={4}> <label style={{ fontSize: '16px' }}>Note(optional)</label></Col>
                                        <Col sm={8}>
                                            <input type="text" name="joining_comment" onChange={handleChange} placeholder='Any Note (optional)...' id="" style={{ height: "35px", width: '100%' }} />
                                        </Col>
                                    </Row> */}
                  <div className="mt-3">
                    {/* <Button
                      className=""
                      variant="secondary"
                      style={{ backgroundColor: "gray", color: "white" }}
                      onClick={() => handleClose3()}
                    >
                      {" "}
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{ backgroundColor: "#18285a", float: "right" }}
                      className=""
                    >
                      {" "}
                      Submit
                    </Button> */}

                    <Button
                      style={{ float: "left", marginLeft: "5%" }}
                      className="w-25"
                      variant="secondary"
                      onClick={() => handleClose3()}
                    >
                      Close
                    </Button>
                    <Button
                      style={{
                        float: "right",
                        borderStyle: "none",
                        marginRight: "5%",
                      }}
                      className="btn submitButtons w-25"
                      type="submit"
                      variant="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Container>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showModal1 && (
        <Modal show={showModal1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Add Joining Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <form onSubmit={handleSubmit1}>
                <Container>
                  <Row>
                    <Col sm={4}>
                      {" "}
                      <label style={{ fontSize: "16px" }}>
                        Add Joining Date
                      </label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        type="date"
                        name="joining_date"
                        onChange={handleChange}
                        placeholder="Deduction Amount"
                        id="form1"
                        style={{ height: "35px", width: "100%" }}
                        required
                      />
                    </Col>
                  </Row>
                  {/* <Row className="mt-2">
                    <Col sm={4}>
                      {" "}
                      <label style={{ fontSize: "16px" }}>Note(optional)</label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        // required
                        type="text"
                        name="joining_comment"
                        onChange={handleChange}
                        placeholder="Any Note (optional)..."
                        // id="form1"
                        style={{ height: "35px", width: "100%" }}
                      />
                    </Col>
                  </Row> */}
                </Container>
                <div className="mt-4">
                  <Button
                    style={{ float: "left", marginLeft: "5%" }}
                    className="w-25"
                    variant="secondary"
                    onClick={() => {
                      handleClose1();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    style={{
                      float: "right",
                      borderStyle: "none",
                      marginRight: "5%",
                    }}
                    className="btn submitButtons w-25"
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showModal && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update New Salary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2">
              <form onSubmit={handleSubmit}>
                <Container>
                  <Row>
                    <Col sm={4}>
                      {" "}
                      <label style={{ fontSize: "16px" }}>New Salary*</label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        required
                        type="number"
                        name="salary"
                        onChange={handleInputChange}
                        placeholder="Enter Amount"
                        id="form1"
                        style={{
                          height: "35px",
                          width: "100%",
                          borderRadius: "8px",
                          border: "solid lightgray 1px",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={4}>
                      {" "}
                      <label style={{ fontSize: "16px" }}>Note(optional)</label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        type="text"
                        name="joining_comment"
                        onChange={handleInputChange}
                        placeholder="Enter Note (optional)..."
                        style={{
                          height: "35px",
                          width: "100%",
                          borderRadius: "8px",
                          border: "solid lightgray 1px",
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
                <div className="mt-4">
                  {/* <Button className='w-25' style={{ backgroundColor: 'gray', color: 'white',borderStyle:"none" }} onClick={() => handleClose()} > Close</Button>
                                    <Button type="submit"
                                        style={{ backgroundColor: '#18285a' ,borderStyle:"none" }}
                                        className='w-25'> Submit</Button> */}
                  {/* <Button
                    style={{ float: "left", marginLeft: "5%" }}
                    className="w-25"
                    variant="secondary"
                    // onClick={() => {
                    //   onHide();
                    //   onCloseModal();
                    // }}
                  >
                    Close
                  </Button>
                  <Button
                    style={{
                      float: "right",
                      borderStyle: "none",
                      marginRight: "5%",
                    }}
                    className="btn submitButtons w-25"
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </Button> */}

                  <Button
                    style={{ float: "left", marginLeft: "5%" }}
                    className="w-25"
                    variant="secondary"
                    onClick={() => handleClose1()}
                  >
                    Close
                  </Button>
                  <Button
                    style={{
                      float: "right",
                      borderStyle: "none",
                      marginRight: "5%",
                    }}
                    className="btn submitButtons w-25"
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </Button>
                </div>
              </form>
              {/* <button className='btn btn-secondary mt-4 mb-2'> Close</button> */}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* {showModal2 && ( */}
      <Modal show={showModal2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Termination Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2">
            <form onSubmit={handleSubmit1}>
              <Container>
                <Row>
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Termination Date</label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      name="leaving_date"
                      onChange={handleInputChange}
                      placeholder="Termination Date"
                      id="form1"
                      style={{ height: "35px", width: "100%" }}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Note(optional)</label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name="leaving_comment"
                      onChange={handleInputChange}
                      placeholder="Enter Note (optional)..."
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
              </Container>
              <div className="mt-4">
                {/* <Button
                  type="submit"
                  style={{ backgroundColor: "#18285a" }}
                  className="w-25"
                  // style={{
                  //     width: "116px",
                  //     borderRadius: " 25px",
                  //     padding: "10px",
                  //     position: 'relative', float: "right",
                  //     backgroundColor: '#18285a', color: 'white',
                  //     marginTop: '20px', marginBottom: '20px'

                  // }}
                >
                  {" "}
                  submit
                </Button>
                <Button
                  className="w-25"
                  style={{ backgroundColor: "gray", color: "white" }}
                  onClick={() => handleClose2()}
                >
                  {" "}
                  Close
                </Button> */}

                <Button
                  style={{ float: "left", marginLeft: "5%" }}
                  className="w-25"
                  variant="secondary"
                  onClick={() => handleClose2()}
                >
                  Close
                </Button>
                <Button
                  style={{
                    float: "right",
                    borderStyle: "none",
                    marginRight: "5%",
                  }}
                  className="btn submitButtons w-25"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </form>
            {/* <button className='btn btn-secondary mt-4 mb-2'> Close</button> */}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show3} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title>Resign Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2">
            <form onSubmit={handleSubmit1}>
              <Container>
                <Row>
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Resign Date</label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      name="salary"
                      onChange={handleInputChange}
                      placeholder="Resign Date"
                      id="form1"
                      style={{ height: "35px", width: "100%" }}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Note(optional)</label>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name="joining_comment"
                      onChange={handleInputChange}
                      placeholder="Enter Note (optional)..."
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
              </Container>
              <div className="mt-4">
                {/* <Button
                  type="submit"
                  style={{ backgroundColor: "#18285a" }}
                  className="w-25"
                  // style={{
                  //     width: "116px",
                  //     borderRadius: " 25px",
                  //     padding: "10px",
                  //     position: 'relative', float: "right",
                  //     backgroundColor: '#18285a', color: 'white',
                  //     marginTop: '20px', marginBottom: '20px'

                  // }}
                >
                  {" "}
                  submit
                </Button>
                <Button
                  className="w-25"
                  style={{ backgroundColor: "gray", color: "white" }}
                  onClick={() => handleClose2()}
                >
                  {" "}
                  Close
                </Button> */}

                <Button
                  style={{ float: "left", marginLeft: "5%" }}
                  className="w-25"
                  variant="secondary"
                  onClick={() => handleClose2()}
                >
                  Close
                </Button>
                <Button
                  style={{
                    float: "right",
                    borderStyle: "none",
                    marginRight: "5%",
                  }}
                  className="btn submitButtons w-25"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </form>
            {/* <button className='btn btn-secondary mt-4 mb-2'> Close</button> */}
          </div>
        </Modal.Body>
      </Modal>

      {/* )} */}
    </div>
  );
};
export default Staffview;
