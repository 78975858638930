import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import employeeModel from "../Model/employee.Model";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, Table, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import salary from "../Model/salary";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import { helper } from "../lib/helper";

const Deduction = () => {
  const [salslip, setSaslip] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();
  const [NewformData, setNewFormData] = useState({
    deduction_amount: "",
    deduction_notice: "",
    deduction_type: "",
  });
  const [input, setInput] = useState("");
  const formattedDate = moment(input).format("DD-MM-YYYY");
  const handleInputChange = (event) => {
    setNewFormData({
      ...NewformData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("deduction_date", formattedDate);
    form.append("emp_id", params?.id);
    form.append("add_deduction", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    employeeModel
      .Deduction(form)
      .then((res) => {
        setSaslip(res.data);
     
        handleClose();
        sendDataToAPI();
        // alert("Deduction Money Add")
      })
      .catch((error) => {
        
      });
  };
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      //RDT provides index by default,
      maxWidth: "1px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      maxWidth: "50px",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      maxWidth: "10px",
    },
    {
      name: "Notice",
      selector: (row) => row.notice,
      maxWidth: "200px",
    },
    {
      name: "Transaction Category",
      selector: (row) => row.transaction_category,
    },
    {
      name: "Transaction Date",
      selector: (row) => row.transaction_date,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },

    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            onClick={() => onDELETE(row.id)}
            onDeleteClick={() => onDELETE(row.id)}
            // openEditModal={() => dispatch(openEditModal("edit-business"))}
            isRemove={true}
            page="businessList"
          />
        );
      },
      width: "4%",
      center: "center",
    },
  ];
  const [TranFilter, setTranFilter] = useState();
  const sendDataToAPI = (data) => {
    const formdata = new FormData();

    formdata.append("emp_transaction", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("filter_type", "deduction");
    formdata.append("emp_id", params?.id);
    salary
      .Trancation(formdata)
      .then((res) => {
        setTranFilter(res.data.tran);
       
      })
      .catch((error) => {
        
      });
  };
  useEffect(() => {
    sendDataToAPI();
  }, []);


  const onDELETE = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("del_transaction", localStorage.getItem("token"));
          formdata.append("transaction_id", id);
          salary
            .RemoveAll(formdata)
            .then((res) => {
              helper.sweetalert.toast(
                "Deleted",
                "Your Deduction has been deleted.",
                "success"
              );
              return sendDataToAPI();
            })
            .then(() => sendDataToAPI()); // Fetch sector data
        }
      });
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <span style={{ fontSize: "18px" }}>
          <b>Deduction List</b>
        </span>
        <Button

        className="btn submitButtons w-25"
                type="submit"
                variant="primary"
          onClick={handleShow}
          style={{ float: "right"}}
        >
          Add{" "}
        </Button>
      </div>
      <div>
        <DataTable
          columns={columns}
          data={TranFilter}
          pagination
          fixedHeader
          //   fixedHeaderScrollHeight='400px'
          highlightOnHover
          //   title={title}
        />
      </div>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "20px" }}>Add Deduction Payment</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2">
            <form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Add Deduction</label>
                  </Col>
                  <Col sm={8}>
                    <input
                      type="number"
                      name="deduction_amount"
                      onChange={handleInputChange}
                      placeholder="Deduction Amount"
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Notice(option)</label>
                  </Col>
                  <Col sm={8}>
                    <input
                      type="text"
                      name="deduction_notice"
                      onChange={handleInputChange}
                      placeholder="Any Notice (option)..."
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Allowance Date</label>
                  </Col>
                  <Col sm={8}>
                    <input
                      type="date"
                      name=""
                      onInput={(e) => setInput(e.target.value)}
                      onChange={handleInputChange}
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={3}>
                    {" "}
                    <label style={{ fontSize: "16px" }}>Allowance Type</label>
                  </Col>
                  <Col sm={3}>
                    {" "}
                    <label
                      for="age1"
                      style={{ marginRight: "10px", marginLeft: "35px" }}
                    >
                      ESI
                    </label>
                    <input
                      type="radio"
                      id="deduction_type"
                      name="deduction_type"
                      value="PF"
                    />
                  </Col>
                  <Col sm={3}>
                    <label
                      for="age2"
                      style={{ marginRight: "10px", marginLeft: "30px" }}
                    >
                      PF
                    </label>
                    <input
                      type="radio"
                      id="deduction_type"
                      name="deduction_type"
                      value="Other"
                    />{" "}
                  </Col>
                  <Col sm={3}>
                    <label
                      for="age2"
                      style={{ marginRight: "10px", marginLeft: "20px" }}
                    >
                      Other
                    </label>
                    <input
                      type="radio"
                      id="deduction_type"
                      name="deduction_type"
                      value="Other"
                    />{" "}
                  </Col>
                </Row>
              </Container>
              <button
                type="submit"
                style={{
                  borderRadius: " 8px",
                  padding: "8px",
                  position: "relative",
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {" "}
                Submit
              </button>
            </form>
            <button className="btn btn-secondary mt-4 mb-2"> Close</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Deduction;
