import React from 'react'
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import logo from '../Aseset/image/test.JPG'
import '../Aseset/css/staffview.css'

import { Container, Row, Col, Card, Nav, Tab, Dropdown } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import General from './Appsetting/General';

const AppSetting = () => {
    return (
        <div>
            <div className="d-flex" id="wrapper">
                <Sidenav />
                <div id="page-content-wrapper">
                    <Topnav />
                    <Container >
                        {/* <Row>
                            <Col>

                                <div className='mt-4 mb-5'>
                                    <span className='large text-dark' style={{ fontSize: '20px', fontWeight: '900' }}>Employee Details </span> <span>|</span>
                                    <Link to='/stafflist'> <span style={{ fontSize: '15px', marginTop: '18px', fontWeight: '500' }} className=''> Back to all employee</span></Link>
                                    <Dropdown style={{ position: 'relative', float: 'right' }}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{}}>
                                           Action
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='mt-2'>
                                            <Dropdown.Item href="#/action-1">Add  attendance</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Assign leave</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Trminate</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Edit salary</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Add joining date</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Card style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",width:'97%', marginLeft:"20px" }} >
                                <Row>
                                    <Col sm={5} className='mb-5 '>
                                        <div className='border-end'>
                                        <div style={{ position: "relative", float: 'left', marginTop: '25px', marginLeft: '30px' }}>
                                            <center>
                                                <img src={logo} alt="" style={{ height: '150px', width: '150px', borderRadius: "50%" }} />
                                            </center>
                                        </div>
                                        <div style={{ position: "relative", marginTop: '50px', marginLeft: '220px' }}>
                                            <h4> Shivam
                                                <br /> Bhargava
                                            </h4>
                                            <button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px' ,width:'60%',padding:'5px'}}><b className='text-light'>Probation</b></button>
                                            <div style={{lineHeight:'13px',paddingTop:'15px'}}>
                                            <p>Director</p>
                                            <p>HCOE7zahxu</p>
                                            <p>Employee</p>
                                            </div>
                                            <div style={{ paddingTop:'15px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ padding: '5px' }} width="25" height="25" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ padding: '5px' }} width="25" height="25" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ padding: '5px' }} width="25" height="25" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ padding: '5px' }} width="25" height="25" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                                </svg>
                                            </div>
                                        </div>
                                        </div>
                                    </Col>
                                    <Col sm={7} >
                                        <Row>
                                            <Col sm={6} >
                                                <div className='border-end'>
                                                <div className='top-card-div' style={{ marginTop: '50px' }}>
                                                    <span className='card-icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
                                                            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                                        </svg>
                                                    </span>
                                                    <p className='card-text'><span style={{ color: 'lightgray' }}>Department</span>  <br /> Accounts1 </p>
                                                </div>
                                                <br />
                                                <div className='top-card-div'>
                                                    <span className='card-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                                    </svg>
                                                    </span>
                                                    <p className='card-text'><span style={{ color: 'lightgray' }}>Work Shift

                                                    </span> <br /> Regular work shift </p>
                                                </div>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className='top-card-div' style={{ marginTop: '50px' }}>
                                                    <span className='card-icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z" />
                                                        </svg>
                                                    </span>
                                                    <p className='card-text'><span style={{ color: 'lightgray' }}>Salary</span>  <br /> ₹ 10,100 <span className='text-primary'>(current)</span> </p>
                                                </div>
                                                <br />
                                                <div className='top-card-div'>
                                                    <span className='card-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16">
                                                        <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                                    </svg>
                                                    </span>
                                                    <p className='card-text'><span style={{ color: 'lightgray' }}>Joining date

                                                    </span>  <br /> Not added yet </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </Card>
                        </Row> */}
                        <h4 className='mt-5'>App Settings</h4>
                        <Row className='mt-5'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="one" >
                                <Row>
                                    <Col sm={3}>
                                        <Card style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px" }}>
                                            <Nav className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="one">Gernerl</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="tow">Modules</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="three">Cron Job</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="four">Notification</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="five">Update</Nav.Link>
                                                </Nav.Item>
                                                


                                            </Nav>
                                        </Card>
                                    </Col>
                                    <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="one"><General/></Tab.Pane>
                                            <Tab.Pane eventKey="tow"></Tab.Pane>
                                            <Tab.Pane eventKey="three"></Tab.Pane>
                                            <Tab.Pane eventKey="four"></Tab.Pane>
                                            <Tab.Pane eventKey="five"></Tab.Pane>
                                            <Tab.Pane eventKey="six"></Tab.Pane>
                                            
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Row>

                    </Container>
                </div>
            </div>
        </div>
    )
}

export default AppSetting
