import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import { helper } from "../../lib/helper";

export const EmpHomeData = createAsyncThunk(  "Employeedata/EmpHomeData",  async () => {
    const formdata = new FormData();
    formdata.append("get_home_emp", localStorage.getItem("token"));
    try {
      const response = await axiosInstance.post(`/home.php`, formdata);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const EmployeeAttendance = createAsyncThunk("Employeedata/EmployeeAttendance",  async ({selectedMonthss,selectedYearss}) => {
 console.log(selectedMonthss ,'selectedMonthselectedMonth')
  const formdata = new FormData();
    formdata.append("get_att_emp", localStorage.getItem("token"));
    formdata.append("att_month", selectedMonthss);
    formdata.append("att_year", selectedYearss);

    try {
      const response = await axiosInstance.post(`/attendance.php`, formdata);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const EmployeeReimbursement = createAsyncThunk(  "Employeedata/EmployeeReimbursement",  async ({event}) => {
    const formdata = new FormData(event.target);
    formdata.append("apply_reimbursement", localStorage.getItem("token"));
  

    try {
      const response = await axiosInstance.post(`/attendance.php`, formdata);
      return response;
    } catch (error) {
      return error.response.data;
    }
  });
  export const staffMap = createAsyncThunk(  "Employeedata/staffMap",  async () => {
    const formdata = new FormData();
    formdata.append("emp_last_location", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    try {
      const response = await axiosInstance.post(`/employee.php`, formdata);
      return response;
    } catch (error) {
      return error.response.data;
    }
  });
  export const getEmpHolidayLists = createAsyncThunk(  "Employeedata/getEmpHolidayLists",  async () => {
    const formdata = new FormData();
    formdata.append("getEmpHolidayLists", localStorage.getItem("token"));
    formdata.append("sel_year", "2023");
    try {
      const response = await axiosInstance.post(`/holidays.php`, formdata);
      console.log("Responsehhh", response?.data);

      return response;
    } catch (error) {
      return error.response.data;
    }
  }
  );

  export const getEmpNoticeList = createAsyncThunk(
    "Employeedata/getEmpNoticeList",
    async (_, { dispatch }) => {
      try {
        const formdata = new FormData();
        formdata.append("get_notice", localStorage.getItem("token"));
        const response = await axiosInstance.post("/notice.php", formdata);
       
        dispatch(setFilterData(response.data?.notice));
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        return error.response.data;
      }
    }
  );

  export const GetReimbursemnt = createAsyncThunk("Employeedata/GetReimbursemnt", async ({event}) => {
      try {
        const formdata = new FormData(event.target)
        formdata.append("apply_reimbursement", localStorage.getItem("token"));
        const response = await axiosInstance.post("/reimbursement.php", formdata);
   
        console.log("Responsehhh", response.data);
       
        return response.data;
      } catch (error) {
        console.error("API Error:", error);
        return error.response.data;
      }
    }
  );
  export const GetALLLeave = createAsyncThunk("Employeedata/GetALLLeave", async () => {
    try {
      const formdata = new FormData()
      formdata.append("leave_history", localStorage.getItem("token"));
      const response = await axiosInstance.post("/leave.php", formdata);
      return response?.data?.leave;

    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
  export const ApplyLeave = createAsyncThunk("Employeedata/ApplyLeave", async ({event}) => {
    try {
      console.log(event.target.id, 'eventevent')
      const formdata = new FormData(event.target)
      formdata.append("leave_req", localStorage.getItem("token"));
      
      const response = await axiosInstance.post("/leave.php", formdata);
      helper.sweetalert.toast(response.data.message)
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
  
);
export const ApplyMutiLeave = createAsyncThunk("Employeedata/ApplyMutiLeave", async ({event}) => {
  try {
    console.log(event.target.id, 'eventevent')
    const formdata = new FormData(event.target)
    formdata.append("leave_req", localStorage.getItem("token"));
    
    const response = await axiosInstance.post("/leave.php", formdata);
    helper.sweetalert.toast(response.data.message)
    // console.log("Responsehhh", response.data);
   
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
}

);
export const GetUpdateBank = createAsyncThunk("Employeedata/GetUpdateBank", async ({event}) => {
  try {

    const formdata = new FormData(event.target)
    formdata.append("update_emp_bank", localStorage.getItem("token"));
    const response = await axiosInstance.post("/empbank.php", formdata);

    console.log("Responsehhh", response.data);
   
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
}
);
export const GetBankdata = createAsyncThunk("Employeedata/GetBankdata", async () => {
  try {

    const formdata = new FormData()
    formdata.append("bank_detail_edit", localStorage.getItem("token"));
    const response = await axiosInstance.post("/empbank.php", formdata);

    console.log("Responsehhh", response.data);
   
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
}
);

const Employeedata = createSlice({
  name: "Employeedata",
    initialState: {
    EmpHomeData: [],
    errorMessage: "",
    loading: true,
    AttendanceDetails: [],
    getEmpHolidayLists:[],
    getEmpNoticeList:[],
    GetALLLeave:[],
    GetBankdata:[]
  },
  reducers: {
  
    setFilterData: (state, action) => {
      state.filterdData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(EmpHomeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(EmpHomeData.fulfilled, (state, action) => {
        console.log("action", action);
        state.Employee = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(EmpHomeData.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(EmployeeAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(EmployeeAttendance.fulfilled, (state, action) => {
        state.Employee = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(EmployeeAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(GetALLLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetALLLeave.fulfilled, (state, action) => {
        console.log("actionfree", action);
        state.GetALLLeave = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(GetALLLeave.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(GetBankdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetBankdata.fulfilled, (state, action) => {
        console.log("actionfree", action);
        state.Employee = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(GetBankdata.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(getEmpHolidayLists.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmpHolidayLists.fulfilled, (state, action) => {
        console.log("actionfree", action);
        state.getEmpHolidayLists = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(getEmpHolidayLists.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(getEmpNoticeList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmpNoticeList.fulfilled, (state, action) => {
        console.log("actionfree", action);
        state.getEmpNoticeList = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(getEmpNoticeList.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(EmployeeReimbursement.pending, (state) => {
        state.loading = true;
      })
      .addCase(EmployeeReimbursement.fulfilled, (state, action) => {
        console.log("actionfree", action);
        state.Employee = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(EmployeeReimbursement.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(staffMap.pending, (state) => {
        state.loading = true;
      })
      .addCase(staffMap.fulfilled, (state, action) => {
        console.log("actionfree", action);
        state.Employee = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(staffMap.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
     
      
  },
});

// Export actions
export const { setFilterData } = Employeedata.actions;

// Export the reducer
export default Employeedata.reducer;
