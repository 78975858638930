import React from 'react'
import { Form } from 'react-router-dom'
import { Row } from 'rsuite'

const RolePermission = ({permission,showComponents,index}) => {

  return(
    <div>
    {
        showComponents[index] && (
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ paddingLeft: "10px" }}>View</span>
                <span style={{ paddingLeft: "10px" }}>Add</span>
                <span style={{ paddingLeft: "10px" }}>Edit</span>
                <span style={{ paddingLeft: "10px" }}>Delete</span>
              </div>
              <div style={{ marginRight: "20px" }}>
                <Form.Check
                  style={{ fontSize: "25px" }}
                  type="checkbox"
                  name={`${permission.perName}[view]`}
                  id=""
                  value={'true'}
                  //   defaultChecked={matchingPermission ? matchingPermission.view_permission === 'true' : false}
                />
                <Form.Check
                  style={{ fontSize: "25px" }}
                  type="checkbox"
                  name={`${permission.perName}[add]`}
                  id=""
                  value={'true'}
                  //   defaultChecked={matchingPermission ? matchingPermission.add_permission === 'true' : false}
                />
                <Form.Check
                  style={{ fontSize: "25px" }}
                  type="checkbox"
                  name={`${permission.perName}[edit]`}
                  id=""
                  value={'true'}
                  defaultChecked={true}
                />
                <Form.Check
                  style={{ fontSize: "25px" }}
                  type="checkbox"
                  name={`${permission.perName}[delete]`}
                  id=""
                  //   value={matchingPermission ? matchingPermission.delete_permission === 'true' : false}
                  //   defaultChecked={matchingPermission ? matchingPermission.delete_permission === 'true' : false}
                />
              </div>
            </div>
          </Row>
        )
      }
      </div>
      
    
  )
}

export default RolePermission