import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";

import { Container, Row, Col, Card } from 'react-bootstrap';


const StaffStatus = () => {

    return (
        <>
            <div>
                <Container fluid  >
                    <Row className='mt-3 pb-3'>
                        <Col>
                            <div className='' >
                                <span style={{ fontSize: '20px' }}>Employment Status</span>
                                <button type="button " style={{ position: 'relative', float: 'right' }} className='btn btn-primary'>Add Employment Status</button>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-3'>
                        <Col>
                            <button style={{ backgroundColor: 'white' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-columns" viewBox="0 0 16 16">
                                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2zm8.5 0v8H15V2H8.5zm0 9v3H15v-3H8.5zm-1-9H1v3h6.5V2zM1 14h6.5V6H1v8z" />
                            </svg></button>
                            <div className='search-box' style={{ position: 'relative', display: 'inline-block', float: 'right' }} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-search p-1" viewBox="0 0 16 16" style={{ position: 'absolute', top: '5px', left: '0px', transform: 'translateY(-50)' }}>
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                                <input type="text" style={{ paddingLeft: "30px", height: '40px', borderRadius: '20px', borderStyle: 'solid', backgroundColor: "#f5f2f2", borderColor: 'lightblue' }} placeholder='Search' />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-3'>
                        <Col>Showing 1 to 4 items of 4</Col>
                    </Row>
                    <Card style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px" }}>
                        <Row className='mt-2 mb-3'>
                            <table className='table  ' style={{ width: '96%', marginLeft: '20px' }}>
                                <thead style={{ height: '100px' }}>
                                    <tr style={{ verticalAlign: 'middle' }} className=''>
                                        <td style={{ paddingLeft: '50px' }}>Name </td>
                                        <td>Preview </td>
                                        <td>Description</td>
                                        <td>Actions</td>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                        <td style={{ paddingLeft: '50px' }}>Terminated</td>
                                        <td><button style={{ color: 'white', backgroundColor: 'red', borderRadius: '10px', width: '100px', padding: '3px' }}>Terminated		</button></td>
                                        <td>--</td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg></td>
                                    </tr>
                                    <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                        <td style={{ paddingLeft: '50px' }}>Permanent</td>
                                        <td><button style={{ color: 'white', backgroundColor: 'blue', borderRadius: '10px', width: '100px', padding: '3px' }}>Permanent	</button></td>
                                        <td>--</td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>

                                        </td>
                                    </tr>
                                    <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                        <td style={{ paddingLeft: '50px' }}>Probation	</td>
                                        <td><button style={{ color: 'white', backgroundColor: 'orange', borderRadius: '10px', width: '100px', padding: '3px' }}>Probation	</button></td>
                                        <td>--</td>
                                        <td> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>

                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </Row>

                    </Card>
                </Container>
            </div>
        </>
    )
}
export default StaffStatus;