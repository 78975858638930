import React from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import employeeModel from "../Model/employee.Model";
import { useEffect } from "react";
import '../App.css'

const Video = () => {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShow(true);
  const toggleModal = () => {

    setShowModal(!showModal);
  };
  const [doclist, setDoclist] = useState([]);
  useEffect(() => {
    const form = new FormData();
    form.append("get_doc_types", localStorage.getItem("token"));
    employeeModel
      .doclist(form)
      .then((res) => {
        
       
      })
      .catch((error) => {
        
      });
  }, []);
  return (
    <Container fluid>
      <Row>
        <Col>
          <div style={{ position: "relative" }}>
            <span
              style={{
                marginTop: "px",
                marginLeft: "20px",
                fontSize: "22px",
                color: "black",
              }}
            >
              <>Video</>
            </span>
            <Button
              style={{
                float: "right",
                borderStyle: "none",
                marginBottom: "20px",
              }}
              className="btn submitButtons w-20"
              type="submit"
              variant="primary"
              onClick={toggleModal}
            >
              Add New
            </Button>
          </div>
          {showModal && (
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Document</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <form>
                    <Container>
                      <Row>
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            Name
                          </span>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            type="text"
                            name="docs_name"
                            // onChange={handleInputChange}
                            placeholder="Add Document Name"
                            required
                            id="form1"
                            style={{
                              height: "35px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            Document No
                          </span>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            type="text"
                            name="doc_number"
                            // onChange={handleInputChange}
                            placeholder="Add Document Number"
                            required
                            id="form1"
                            style={{
                              height: "35px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            {" "}
                            Select Document
                          </span>
                        </Col>
                        <Col sm={8}>
                          {" "}
                          <Form.Control
                            type="file"
                            name="document"
                            id="form1"
                            // onChange={handleInputChange}
                            required
                            style={{ height: "40px", width: "100%" }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={4}>
                          {" "}
                          <span style={{ fontSize: "17px" }}>
                            {" "}
                            Documnet Type
                          </span>{" "}
                        </Col>
                        <Col sm={8}>
                          <Form.Select
                            name="doc_type_id"
                            id=""
                            required
                            style={{ height: "35px", width: "100%" }}
                          >
                            {doclist &&
                              doclist.map((list) => (
                                <option value={list.id}> {list.type}</option>
                              ))}
                          </Form.Select>{" "}
                        </Col>
                      </Row>
                    </Container>
                    <div className="mt-2">
                    <Button
                      style={{ float: "left", marginLeft: "5%" }}
                      className="w-25"
                      variant="secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </Button>
                    <Button
                      style={{
                        float: "right",
                        borderStyle: "none",
                        marginRight: "5%",
                      }}
                      className="btn submitButtons w-25"
                      type="submit"
                      variant="primary"
                    >
                      Submit
                    </Button>
                    </div>
                    {/* <button type='submit' className='btn mt-4 mb-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }}> Submit</button> */}
                  </form>
                  {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{ float: 'left' }}>Close</button> */}
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Video;
