// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Card, Modal, Form, Button } from "react-bootstrap";
// // import employeeModel from "../../Model/employee.Model";
// import { useParams } from "react-router-dom";
// import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
// import { helper } from "../../lib/helper";
import CommonModal from "../../components/Common/CommonModal";
// // import CommonEditModal from "../../components/Common/CommonEditModal";
// import {
//   closeEditModal,
//   closeModal,
//   openEditModal,
//   openModal,
// } from "../../store/silce/modalSlice";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addAddress,
// } from "../../store/silce/staffSlice";

// const Address = () => {
//     const dispatch = useDispatch();
//     const [outid, setOutid] = useState(0);
//     const [currentTitle, setCurrentTitle] = useState('Add Address')
//     const showModal = useSelector((state) => state.modal.showModal);
//     // const currentPage = useSelector((state) => state.modal.currentPage);
//     const showEditModal = useSelector((state) => state.modal.showEditModal);
//     const [listAddresss, setListAddress] = useState([]);
//     const params = useParams();
//     console.log('params?.id', params?.id)
//     const pageData = {
//         outid: outid,
//     }
//     const handleSubmit  = (event) => {
//         event.preventDefault();
//         dispatch(addAddress({ event: event, empid: params.id}));
//         dispatch(closeModal())
//         helper.sweetalert.toast('Your Address add.', 'success')

//     };
//     return (
//         <MDBCard className="mt-3">
//             <MDBCardHeader>
//                 <Row>
//                     <Col>
//                         <div style={{ position: 'relative' }}>
//                             <span style={{ marginTop: 'px', marginLeft: '10px', fontSize: '19px' }}>
//                                 <b style={{}}> Address Details</b></span>
//                             <Button className='btn'
//                                 onClick = {() =>  {
//                                     dispatch(openModal("add-addressnew"))

//                                 }}
//                                style={{ float: "right", backgroundColor: '#18285a', color: 'white' }}>Add New</Button>
//                         </div>
//                     </Col>
//                 </Row>
//             </MDBCardHeader>
//             <MDBCardBody>
//                 <div>
//                     <Card>
//                         <Container className='mt-3'>
//                             {listAddresss && listAddresss.map((list) =>
//                                 <Container fluid className='mb-4'>
//                                     <hr style={{ border: '1px solid' }} />
//                                     <Row>
//                                         <Col sm={6}>
//                                             <p>Address Line 1  - <span>{list.address_line1}</span></p>
//                                             <p>Address Line 2 - <span>{list.address_line2}</span></p>
//                                             <p>City  - <span>{list.city}</span></p>
//                                             <p>State  - <span>{list.state}</span></p>
//                                             <p>Pincode - <span>{list.pincode}</span> </p>
//                                             {/* <p>Contact No  - <span>{list.contact_no}</span></p> */}
//                                         </Col>
//                                         {/* <Col>
//                                             <button onClick={() => (onDELETE(list.id))} className='btn btn-danger' style={{ float: 'right', marginLeft: '10px' }} >Delete</button>
//                                             <button onClick={() => (getid(list.id))} className='btn' style={{ float: 'right', backgroundColor: '#18285A', color: "white" }} >Edit</button>

//                                             {/* <button
//                                                 className='btn btn-secondary'
//                                                 onClick={() => {
//                                                     dispatch(openModal("edit-address"))
//                                                     setCurrentTitle("Update  Address")
//                                                 }}
//                                                 style={{ float: 'right', marginRight: '20px' }}
//                                             >Edit</button> */}
//                                         {/* </Col> */}
//                                     </Row>

//                                 </Container>
//                             )}
//                         </Container>
//                     </Card>
//                 </div>
//             </MDBCardBody>

//             <CommonModal
//                     // pageData={pageData}
//                     onSubmit={handleSubmit}
//                      show={showModal} onHide={() => {
//                     dispatch(closeModal())
//                 }}
//                 name={'add-addressnew'}
//                 // name={currentPage}
//                  title={currentTitle}
//                  />

//         </MDBCard>
//     )
// }

// export default Address

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import employeeModel from "../../Model/employee.Model";
import { helper } from "../../lib/helper";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardImage,
  MDBCardText,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { AiFillPhone } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { FaPeopleArrows } from "react-icons/fa";
import '../../App.css'
const Address = () => {
  const params = useParams();
  const [EmgCont, setEmgCont] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [outid, setOutid] = useState(0);
  const [showModal1, setShowModal1] = useState(false);
  const handleClose1 = () => setShowModal1(false);
  const handleShow1 = () => setShowModal1(true);
  const [EmgSingle, setEmgSingle] = useState();
  const [formData, setFormData] = useState({
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    pincode: "",
    contact_no: "",
    address_type: "",
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("add_address", localStorage.getItem("token"));
    form.append("emp_id", params?.id);
    employeeModel
      .Addressadd(form)
      .then((res) => {
        console.log(res.data);
        helper.sweetalert.toast("Your Address add.", "success");
        fetchData();
        handleClose();
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const fetchData = async () => {
    const formdata = new FormData();
    formdata.append("emp_id", params?.id);
    formdata.append("emp_address_list", localStorage.getItem("token"));
    employeeModel
      .AddressList(formdata)
      .then((res) => {
        console.log(res?.data?.address, "saurabh");
        setEmgCont(res?.data?.address);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  console.log(EmgCont, "EmgCont");
  useEffect(() => {
    fetchData();
  }, []);
  const onDelete = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("delete_address", localStorage.getItem("token"));
          formdata.append("addres_id", id);
          employeeModel.DeleteAddress(formdata).then((res) => {
            console.log(res.data);
            helper.sweetalert.toast(
              "Deleted",
              "Your Address has been deleted.",
              "success"
            );
            fetchData();
            handleClose();
          });
        }
      });
  };

  const [id, setId] = useState();
  const toggleModal1 = (id) => {
    // displaydata(id);
    setShowModal1(!showModal1);
    setId(id);
  };
  const displaydata = async (id) => {
    const formdata = new FormData();
    formdata.append("emp_address_detail", localStorage.getItem("token"));
    formdata.append("addres_id", id);
    employeeModel
      .AddressList(formdata)
      .then((res) => {
        console.log(res.data, "singh");
        setEmgSingle(res.data.address);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const [formData1, setFormData1] = useState({
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    pincode: "",
    contact_no: "",
    address_type: "",
  });
  const handleChange = (event) => {
    setFormData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
  };
  const handleInSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("update_address", localStorage.getItem("token"));
    form.append("addres_id", id);
    employeeModel
      .UpdateAddress(form)
      .then((res) => {
        console.log(res.data);
        handleClose1(true);
        helper.sweetalert.toast("Your Address add.", "success");
        fetchData();
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  return (
    <MDBCard className="mt-3">
      <MDBCardHeader>
        <Row>
          <Col>
            <div style={{ position: "relative" }}>
              <span
                style={{
                  marginTop: "px",
                  marginLeft: "10px",
                  fontSize: "19px",
                }}
              >
                <b>Address List</b>
              </span>
              <button
                className="btn"
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                }}
                onClick={handleShow}
              >
                Add New
              </button>
            </div>
          </Col>
        </Row>
      </MDBCardHeader>
      <MDBCardBody>
        <div>
          <>
            <Container className="mt-3">
              {EmgCont &&
                EmgCont.map((list) => (
                  <Container fluid className="mb-4">
                    <hr style={{ border: "1px solid" }} />

                    {/* <div> */}
                    <Row>
                      <Col sm={8}>
                        <p>
                          Address Line 1 - <span>{list.address_line1}</span>
                        </p>
                        <p>
                          Address Line 2 - <span>{list.address_line2}</span>
                        </p>
                        <p>
                          City - <span>{list.city}</span>
                        </p>
                        <p>
                          State - <span>{list.state}</span>
                        </p>
                        <p>
                          Pincode - <span>{list.pincode}</span>{" "}
                        </p>
                        {/* <p>Contact No  - <span>{list.contact_no}</span></p> */}
                      </Col>
                      <Col>
                        <div className="d-flex align-items-end justify-content-start gap-3  flex-row  ms-1">
                          <Button
                            onClick={() => {
                              toggleModal1(list.id);
                              displaydata(list.id);
                            }}
                            className="w-0"
                            style={{ backgroundColor: "#18285a" }}
                            outline
                            color="dark"
                          >
                            <BiEdit />
                          </Button>
                          <Button
                            onClick={() => {
                              onDelete(list.id);
                            }}
                            className="w-0"
                            style={{ backgroundColor: "#18285a" }}
                            outline
                            color="dark"
                          >
                            <MdDeleteForever />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {/* </div> */}
                  </Container>
                ))}
              {/* </Container>
                    </Card> */}
            </Container>
          </>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Form onSubmit={handleSubmit}>
                  <div>
                    {/* <input
//                     type="hidden"
//                     name="bus_id"
//                     id=""
//                     value={pageData?.outid}
//                   /> */}
                    <Form.Group controlId="nameOfBusiness">
                      {/* <Form.Control onChange={onChange} name='address_line1' required type="text" placeholder="Enter Name of Address Line 1" /> */}
                    </Form.Group>
                    <Row className="">
                      {" "}
                      <Col sm={4}>
                        <Form.Label className="mt-2">
                          {" "}
                          Building Name And House no.
                        </Form.Label>{" "}
                      </Col>
                      <Col sm={8}>
                        {" "}
                        <Form.Control
                          // onChange={onChange}
                          name="address_line1"
                          
                          type="text"
                          placeholder="Building Name And House no."
                          required
                           id="form1"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2">
                          {" "}
                          Landmark Area
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          style={{ height: "40px" }}
                          placeholder="Landmark Area Name"
                          // required
                          // id="form1"
                          name="address_line2"
                     
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2"> City</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          style={{ height: "40px" }}
                          placeholder="Enter Your city"
                          required
                          id="form1"
                          // onChange={handleChange}
                          name="city"
                      
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2"> State</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          style={{ height: "40px" }}
                          name="state"
                        
                          required
                          id="form1"
                          // onChange={handleChange}
                          placeholder="Enter Your State"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2"> Pincode</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="number"
                          style={{ height: "40px" }}
                          name="pincode"
                          maxLength={6} // Restrict input to 6 characters
                          pattern="[0-9]*" // Allow only numeric characters
                          required
                          id="form1"
                          // onChange={handleChange}
                          placeholder="Enter Your Pincode"
                        />
                      </Col>
                    </Row>
                    <div className="mt-4">
                      {/* <Button
                        className=""
                        style={{
                          backgroundColor: "gray",
                          color: "white",
                          borderStyle: "none",
                        }}
                        onClick={() => handleClose()}
                      >
                        {" "}
                        Close
                      </Button>
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#18285a  ",
                          borderStyle: "none",
                          float: "right",
                        }}
                        className=""
                      >
                        {" "}
                        Submit
                      </Button> */}
                      <Button
                          style={{ float: "left", marginLeft: "5%" }}
                          className="w-25"
                          variant="secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </Button>
                        <Button
                          style={{
                            float: "right",
                            borderStyle: "none",
                            marginRight: "5%",
                          }}
                          className="btn submitButtons w-25"
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                    </div>
                    {/* <button className='btn btn-primary mb-4 mt-4' style={{ float: "right" }} type='submit'>Submit</button> */}
                  </div>
                </Form>
                {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{
                                    float: 'left', marginLeft
                                        : "10px"
                                }}>Close</button> */}
              </div>
            </Modal.Body>
          </Modal>
          {showModal1 && (
            <Modal show={showModal1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Address </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Form onSubmit={handleInSubmit}>
                    <div>
                      {/* <input
//                     type="hidden"
//                     name="bus_id"
//                     id=""
//                     value={pageData?.outid}
//                   /> */}
                      <Form.Group controlId="nameOfBusiness">
                        {/* <Form.Control onChange={onChange} name='address_line1' required type="text" placeholder="Enter Name of Address Line 1" /> */}
                      </Form.Group>
                      <Row className="">
                        {" "}
                        <Col sm={4}>
                          <Form.Label className="mt-2">
                            {" "}
                            Building Name And House no.
                          </Form.Label>{" "}
                        </Col>
                        <Col sm={8}>
                          {" "}
                          <Form.Control
                            // onChange={onChange}
                            defaultValue={EmgSingle?.address_line1}
                            name="address_line1"
                            required
                            type="text"
                            placeholder=" Building Name And House no."
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={4}>
                          <Form.Label className="mt-2">
                            {" "}
                            Landmark Area
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            defaultValue={EmgSingle?.address_line2}
                            type="text"
                            style={{ height: "40px" }}
                            placeholder="  Landmark Area"
                            required
                            name="address_line2"
                            id=""
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={4}>
                          <Form.Label className="mt-2"> City</Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            defaultValue={EmgSingle?.city}
                            type="text"
                            style={{ height: "40px" }}
                            placeholder="Enter Your city"
                            required
                            // onChange={handleChange}
                            name="city"
                            id=""
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={4}>
                          <Form.Label className="mt-2"> State</Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            defaultValue={EmgSingle?.state}
                            type="text"
                            style={{ height: "40px" }}
                            name="state"
                            id=""
                            required
                            // onChange={handleChange}
                            placeholder="Enter Your State"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={4}>
                          <Form.Label className="mt-2"> Pincode</Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            defaultValue={EmgSingle?.pincode}
                            type="number"
                            style={{ height: "40px" }}
                            name="pincode"
                            id=""
                            required
                            // onChange={handleChange}
                            placeholder="Enter Your Pincode"
                          />
                        </Col>
                      </Row>
                      <div className="mt-4">
                        {/* <Button
                          className=""
                          style={{
                            backgroundColor: "gray",
                            color: "white",
                            borderStyle: "none",
                          }}
                          // onClick={() => handleClose()}
                        >
                          {" "}
                          Close
                        </Button>
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "#18285a  ",
                            borderStyle: "none",
                            float: "right",
                          }}
                          className=""
                        >
                          {" "}
                          Submit
                        </Button> */}
                        <Button
                          style={{ float: "left", marginLeft: "5%" }}
                          className="w-25"
                          variant="secondary"
                           onClick={() => handleClose1()}
                        >
                          Close
                        </Button>
                        <Button
                          style={{
                            float: "right",
                            borderStyle: "none",
                            marginRight: "5%",
                          }}
                          className="btn submitButtons w-25"
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                      </div>
                      {/* <button className='btn btn-primary mb-4 mt-4' style={{ float: "right" }} type='submit'>Submit</button> */}
                    </div>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Address;
