import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import { helper } from "../../lib/helper";

export const nofetchAllEmployees = createAsyncThunk(
  "staff/nofetchAllEmployees",
  async (_, { dispatch }) => {
    try {
      const formdata = new FormData();
      formdata.append("emp_list", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      const response = await axiosInstance.post("/employee.php", formdata);
      console.log("Responseholidaysss:", response);
      dispatch(setFilterData(response.data?.emp));
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
export const fetchAllEmployees = createAsyncThunk(
  "staff/fetchAllEmployees",
  async ({ selectedOptions, name }) => {
    try {
      console.log(selectedOptions, "shiavammm1");
      const formdata = new FormData();
      formdata.append("emp_list", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      formdata.append("filter", name);
      formdata.append("filter_key", selectedOptions);
      const response = await axiosInstance.post("/employee.php", formdata);
      console.log("Response holiday:", response);
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
export const fetchSubAllEmployees = createAsyncThunk(
  "staff/fetchSubAllEmployees",
  async ({ Option }) => {
    try {
      console.log(Option, "subdepartmentid");
      const formdata = new FormData();
      formdata.append("emp_list", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      formdata.append("filter", "sub_department");
      formdata.append("filter_key", Option);
      const response = await axiosInstance.post("/employee.php", formdata);
      console.log("Response holiday:", response);
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
export const fetchAllStatusEmployees = createAsyncThunk(
  "staff/fetchAllStatusEmployees",
  async ({ selectedOption, name }) => {
    try {
      const formdata = new FormData();
      console.log(selectedOption, "");
      formdata.append("emp_list", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      formdata.append("filter", name);
      formdata.append("filter_key", selectedOption);
      const response = await axiosInstance.post("/employee.php", formdata);
      console.log("Response holiday:", response);
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const addnewemployee = createAsyncThunk(
  "staff/addnewemployee",
  async (
    {
      event,
      selectedWeeks,
      getCheckedCheckboxes,
      setOpen,
      navigate,
      selectedDatee,
      Newvalue,
      departmentId,
      empCode,
    },
    { dispatch }
  ) => {
    const form = new FormData(event.target);
    console.log(selectedDatee, "ggggg");
    form.append("add_emp", localStorage.getItem("token"));
    form.append("dob", selectedDatee);
    form.append("emp_att_type", Newvalue);
    form.append("department_id", departmentId);

    if (empCode && empCode.trim() !== "") {
        form.append("emp_id", empCode.trim());
      }

    form.append("bus_id", localStorage.getItem("selecteoption"));
    if (selectedWeeks.length > 0) {
      selectedWeeks.map((curWeek, index) => {
        form.append("emp_week_off[" + index + "][day]", curWeek);
        form.append(
          "emp_week_off[" + index + "][week_count]",
          getCheckedCheckboxes(curWeek)
        );
      });
    }
    try {
      const response = await axiosInstance.post("/employee.php", form);
      console.log("Response holiday:", response);
      if (response.data.is_error === false) {
        setOpen(false);
        helper.sweetalert.toast("Staff Added Succesfully");
        // navigate('/stafflist')
      } else {
        helper.sweetalert.toast1("Number is Already Register");
      }
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const removeEmployee = createAsyncThunk(
  "staff/removeEmployee",
  async ({ id: id }, { dispatch }) => {
    const formdata = new FormData();
    formdata.append("delete_emp", localStorage.getItem("token"));
    formdata.append("emp_id", id);
    try {
      const response = await axiosInstance.post("/employee.php", formdata, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: formdata,
      });
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const editEmployee = createAsyncThunk(
  "staff/editEmployee",
  async ({ id: id }, { dispatch }) => {
    const formdata = new FormData();
    formdata.append("edit_emp", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("emp_id", id);
    try {
      const response = await axiosInstance.post("/employee.php", formdata);
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
export const addAddress = createAsyncThunk(
  "staff/addAddress",
  async ({ event, empid }) => {
    const form = new FormData(event.target);
    form.append("add_address", localStorage.getItem("token"));
    form.append("emp_id", empid);
    try {
      const response = await axiosInstance.post("/employee.php", form);
      return response.data;
    } catch (error) {
      console.error("API Erroradress:", error);
      return error.response.data;
    }
  }
);

export const supervisedStaffAdd = createAsyncThunk(
  "staff/supervisedStaffAdd",
  async ({ event, outid, empid }) => {
    const form = new FormData();
    form.append("asign_emp", localStorage.getItem("token"));
    form.append("reporting_manager_emp_id", outid);
    form.append("emp_ids", empid);
    try {
      const response = await axiosInstance.post("/employee.php", form);
      helper.sweetalert.toast(response?.data?.message);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

//   export const addBank = createAsyncThunk("staff/addBank", async ({event ,empid}) => {
//     const form = new FormData(event.target);
//     form.append("add_address", localStorage.getItem("token"));
//     form.append("emp_id" ,empid)
//     form.append("bus_id", localStorage.getItem("selecteoption"))
//     try {
//       const response = await axiosInstance.post("/empbank.php", form);
//       return response.data;
//     } catch (error) {
//       return error.response.data;
//     }
//   });

export const editAddresss = createAsyncThunk(
  "staff/editAddresss",
  async ({ event, empid }) => {
    const form = new FormData(event.target);
    form.append("emp_address_detail", localStorage.getItem("token"));
    form.append("addres_id", empid);

    try {
      const response = await axiosInstance.post("/employee.php", form);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const ViewEmployeeSuperviderStaff = createAsyncThunk(
  "roll/ViewEmployeeSuperviderStaff",
  async ({ empid }) => {
    try {
      const formdata = new FormData();
      formdata.append("assigned_emp_list", localStorage.getItem("token"));
      formdata.append("reporting_manager_emp_id", empid);
      const response = await axiosInstance.post("/employee.php", formdata);
      console.log("httt", response.data);
      return response?.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

const staffSlice = createSlice({
  name: "staff",
  initialState: {
    employees: [],
    errorMessage: "",
    loading: false,
    filterdData: [],
    employeeDetails: null,
    employeesdept: [],
    employeesSub: [],
  },
  reducers: {
    // You can add other non-async actions here if needed.
    setFilterData: (state, action) => {
      state.filterdData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployees.pending, (state, action) => {
        console.log("actions-pending", action);
        state.loading = true;
      })
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action-fullfilled", action);
        state.employees = action.payload;
      })
      .addCase(fetchAllEmployees.rejected, (state, action) => {
        state.loading = false;
        console.log("action-reject", action);
        state.errorMessage = action.payload;
      })
      .addCase(fetchSubAllEmployees.pending, (state, action) => {
        console.log("actions-pending", action);
        state.loading = true;
      })
      .addCase(fetchSubAllEmployees.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action-fullfilled", action);
        state.employees = action.payload;
      })
      .addCase(fetchSubAllEmployees.rejected, (state, action) => {
        state.loading = false;
        console.log("action-reject", action);
        state.errorMessage = action.payload;
      })
      .addCase(fetchAllStatusEmployees.pending, (state, action) => {
        console.log("actions-pending", action);
        state.loading = true;
      })
      .addCase(fetchAllStatusEmployees.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action-fullfilled", action);
        state.employees = action.payload;
      })
      .addCase(fetchAllStatusEmployees.rejected, (state, action) => {
        state.loading = false;
        console.log("action-reject", action);
        state.errorMessage = action.payload;
      })
      .addCase(nofetchAllEmployees.pending, (state, action) => {
        console.log("actions-pending", action);
        state.loading = true;
      })
      .addCase(nofetchAllEmployees.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action-fullfilled", action);
        state.employees = action.payload;
      })
      .addCase(nofetchAllEmployees.rejected, (state, action) => {
        state.loading = false;
        console.log("action-reject", action);
        state.errorMessage = action.payload;
      })
      .addCase(addnewemployee.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addnewemployee.fulfilled, (state, action) => {
        state.loading = false;
        // state.employees = action.payload;
      })
      .addCase(addnewemployee.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(supervisedStaffAdd.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(supervisedStaffAdd.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload;
      })
      .addCase(supervisedStaffAdd.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(ViewEmployeeSuperviderStaff.pending, (state) => {
        state.loading = true;
      })
      .addCase(ViewEmployeeSuperviderStaff.fulfilled, (state, action) => {
        state.loading = false;
        state.ViewEmployeeSuperviderStaff = action.payload;
      })
      .addCase(ViewEmployeeSuperviderStaff.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(removeEmployee.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload;
      })
      .addCase(removeEmployee.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(editEmployee.pending, (state, action) => {
        state.loading = false;
      })
      .addCase(editEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.employeeDetails = action.payload?.data;
      })
      .addCase(editEmployee.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      // .addCase(addAddress.pending, (state) => {
      //     state.loading = true;
      //   })
      //   .addCase(addAddress.fulfilled, (state ,action ) => {
      //     state.loading = false;
      //     state.employees = action.payload;
      //     // Handle success or update state accordingly
      //   })
      //   .addCase(addAddress.rejected, (state, { load }) => {
      //     state.loading = false;
      //     state.errorMessage = load.message;
      //   })
      .addCase(addAddress.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAddress.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(addAddress.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      });
    //   .addCase(addBank.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(addBank.fulfilled, (state, { load }) => {
    //     state.loading = false;
    //     // Handle success or update state accordingly
    //   })
    //   .addCase(addBank.rejected, (state, { load }) => {
    //     state.loading = false;
    //     state.errorMessage = load.message;
    //   })
  },
});

// Export actions
export const { /* Other non-async actions */ setFilterData } =
  staffSlice.actions;

// Export the reducer
export default staffSlice.reducer;
