import React, { useState, } from 'react';
import '../Aseset/css/sidenav.css';
import { Container, Row, Col } from 'react-bootstrap';
import businessModel from '../Model/business.model';
import calander from '../../src/Aseset/image/calendar.png'
import day from '../../src/Aseset/image/30day.png'
const SalarySetting = () => {
    const [formDataNew, setFormDataNew] = useState({
        salary_basis: '',
    });
    const handleChange = (event) => {
        setFormDataNew({
            ...formDataNew,
            [event.target.name]: event.target.value,
        });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formdata = new FormData();
        formdata.append("update_salary_basis", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("salary_basis", formDataNew.salary_basis);
        businessModel.salarybasis(formdata).then((res) => {
            console.log(res.data.message)
            alert(res.data.message)
        }).catch((error) => {
            console.log("error => ", error)
        })
    }
    return (
        <>
            <Container fluid >
                <Row>
                    <h4>Salary Setting</h4>
                </Row>
                <Row className='mt-5 mb-2'>
                    <Col>
                        <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className=' mt-3 mb-4'>
                            <form onSubmit={handleSubmit}>
                                <div className='m-3'>
                                    <label className='label-radio mt-2'>
                                        <input type="radio" name="salary_basis" value='monthly' className="card-input-element d-none" id="demo1" />
                                        <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            <p style={{ fontSize: '16px',marginTop:'10px' }} ><img src={calander} alt="" style={{ height: "20px" }} /> Monthly basis<br /> 
                                        <span style={{fontSize:'12px'}}>Salary will be calculated for a month</span></p>
                                        </div>
                                    </label>
                                    <label className='label-radio mt-2'>
                                        <input type="radio" name="salary_basis" value='30 days' className="card-input-element d-none pt-2" id="demo1" />
                                        <div class="card card-body  bg-light  d-flex flex-row justify-content-between align-items-center" >
                                            <p style={{ fontSize: '16px', marginTop: '10px' }} ><img src={day} style={{ height: "20px" }} alt="" /> 30 Days basis <br/>
                                            <span style={{fontSize:'12px'}}>Salary will be calculated for 30 days(Doesn't matter how many days in current month)</span></p>
                                        </div>
                                    </label> 
                                </div>
                                <br />
                                <button className='btn mt-2 mb-2' style={{ float: 'right',marginRight:'18px',backgroundColor:'#18285A',color:'white' }}>Update Salary Basis</button>
                            </form>
                            <form>
                                <div className='m-3'>
                                    <label className='label-radio mt-2'>
                                            <input type="checkbox" name="" value='30 days' class="card-input-element d-none" id="demo1" />
                                            <div class="card card-body  bg-light  d-flex flex-row justify-content-between align-items-center" >
                                                <p style={{ fontSize: '16px', marginTop: '10px' }} ><img src={day} style={{ height: "20px" }} alt="" />Collapse paid leave <br />
                                                <span style={{ fontSize: '12px'}} >When it is on, All the paid leave of any employee will be collapsed with month end.</span></p>
                                            </div>
                                    </label> 
                                    <button className='btn mt-4 mb-5' style={{ float: 'right',backgroundColor:'#18285A',color:'white' }}>Collapse Paid Leave</button>
                                </div>                            
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default SalarySetting;