import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { updateatt } from "../../store/silce/attendance";
import { GrView } from "react-icons/gr";
import businessModel from "../../Model/business.model";
import { unAssignRollEmp } from "../../store/silce/roll";
import { useNavigate, useParams } from "react-router-dom";
import { TiUserAddOutline } from "react-icons/ti";
import { FaExchangeAlt } from "react-icons/fa";
import { CgCloseR } from "react-icons/cg";
import { IoIosArrowRoundForward } from "react-icons/io";
import { BiSolidUserDetail } from "react-icons/bi";

import { editBusiness } from "../../store/silce/bussiness";

import CommonModal from "../Common/CommonModal";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { MdOutlineAssignmentTurnedIn } from "react-icons/md";
import { IoMdDoneAll } from "react-icons/io";
import { TiUserDelete } from "react-icons/ti";
import { IoMdPersonAdd } from "react-icons/io";

import { helper } from "../../lib/helper";

import { Button } from "react-bootstrap";
import { FcApproval, FcApprove, FcDisapprove } from "react-icons/fc";

function TableDropdown({
  id,
  page,
  setCurrentTitle,
  displaydata,
  isRemove,
  isEdit,
  isAttendance,
  isApproved,
  isRejected,
  isAdress,
  isView,
  isdeptassing,
  isdeptunassing,
  handleClickD,
  punchInClick,
  absentClick,
  puchOutClick,
  Approvedres,
  Rejectres,
  overTimeClick,
  leaveClick,
  weekOffClick,
  undoClick,
  openEditModal,
  openViewClick,
  handleShowassingeemp,
  handleShowunassingeemp,
  removeAssignId,
  isEditlogo,
  isEditbuss,
  openEditModal1,
  onDeleteClick,
  onClick,
  isAssign,
  isPermissionsRole,
  markPaid,
  isViewEmpPermissionsRole,
  allowEmpAssign,
  isAssignLevel,
  handleAssign,
  isUnassign,
  handleShow,
  isLeave,
  openModal,
  handleShowleaveDetails,
  selectedLevel,
  openModalStaffView,
  isQRlogo,
  UnAssignRollEMp,
  isStaffDow
}) {
  const formdata = new FormData();
  formdata.append("delete_bus", localStorage.getItem("token"));
  formdata.append("bus_id", id);
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.modal.showModal);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    // dispatch(deleteBusiness({ id: id, payload: formdata }))
    handleClose();
  };
  const handleLeaveClick = (Leave) => {
    sendDataToAPI(Leave);
  };

  const sendDataToAPI = (status) => {
    const formdataa = new FormData();
    formdataa.append("mark_attendance", localStorage.getItem("token"));
    formdataa.append("bus_id", localStorage.getItem("selecteoption"));
    formdataa.append("mark_type", status);
    formdataa.append("emp_id", id);
    dispatch(updateatt({ id: id, payload: formdataa }));
    handleClose();
  };

  const menuItems = [
    {
      label: "Absent",
      color: "rgb(202 90 90)",
      onClick: () => {
        absentClick();
      },
    },
    {
      label: "Punch In",
      color: "rgb(81 151 81)",
      onClick: () => {
        handleClose();
        punchInClick();
      },
    },
    {
      label: "Punch Out",
      color: "rgb(204, 204, 204)",
      onClick: () => {
        handleClose();
        puchOutClick();
      },
    },

    {
      label: "Overtime",
      color: "rgb(255, 165, 0)",
      onClick: () => {
        handleClose();
        overTimeClick();
      },
    },
    {
      label: "Leave",
      color: "rgb(95 95 202)",
      onClick: () => {
        handleClose();
        leaveClick();
      },
    },
    {
      label: "Week Off",
      color: "rgb(211 101 211)",
      onClick: () => {
        handleClose();
        weekOffClick();
      },
    },
    {
      label: "Undo",
      color: "rgb(128, 128, 128)",
      onClick: undoClick,
    },
  ];

  const navigate = useNavigate();
  const prmas = useParams();
  const handleSubmit = () => {
    dispatch(unAssignRollEmp({ empid: prmas.id || id.id || id }));
    handleClose();
  };

  return (
    <div>
      <MoreVertIcon onClick={handleClick} style={{ cursor: "pointer" }} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ cursor: "pointer" }}
      >
        {
          <>
            {isEdit && (
              <MenuItem
                onClick={() => {
                  // displaydata(id);
                  handleClose();
                  openEditModal();
                }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <BiEdit />
                  </div>
                  <div>Edit</div>
                </div>
              </MenuItem>
            )}
            {isEditbuss && (
              <MenuItem
                onClick={() => {
                  displaydata(id);
                  handleClose();
                  openEditModal();
                }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <BiEdit />
                  </div>
                  <div>Edit</div>
                </div>
              </MenuItem>
            )}
            {isEditlogo && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleShow();
                  displaydata(id);
                }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <BiEdit />
                  </div>
                  <div>Edit logo</div>
                </div>
              </MenuItem>
            )}
            {/* {isQRlogo && (
              <MenuItem
                // onClick={() => {
                //   handleClose();
                //   handleShow();
                //   displaydata(id);
                // }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <BiEdit />
                  </div>
                  <div>QR Logo</div>
                </div>
              </MenuItem>
            )} */}

            {isRemove && (
              <MenuItem
                onClick={() => {
                  onClick();
                  handleClose();
                }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <MdDeleteForever />
                  </div>
                  <div>Delete</div>
                </div>
              </MenuItem>
            )}
            {isView && (
              <MenuItem
              onClick={() => {
                openViewClick();
                handleClose();
              }}
                
                // onClick={() => { page === 'buisnessList' && handleDelete() }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <GrView />
                  </div>
                  <div>View</div>
                </div>
              </MenuItem>
            )}
            {isdeptassing && (
              <MenuItem
               
                onClick={() => {
                  handleShowassingeemp();
                  handleClose();
                }}
                // onClick={() => { page === 'buisnessList' && handleDelete() }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <TiUserAddOutline />
                  </div>
                  <div>Assign Emp</div>
                </div>
              </MenuItem>
            )}
            {isdeptunassing && (
              <MenuItem
              onClick={() => {
                handleShowunassingeemp();
                handleClose();
              }}
             
                // onClick={() => { page === 'buisnessList' && handleDelete() }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <TiUserAddOutline />
                  </div>
                  <div>Unassign Emp</div>
                </div>
              </MenuItem>
            )}
            {isApproved && (
              <MenuItem
                onClick={Approvedres}
                // onClick={() => { page === 'buisnessList' && handleDelete() }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <FcApprove />
                  </div>
                  <div>Approved</div>
                </div>
              </MenuItem>
            )}
            {isRejected && (
              <MenuItem
                onClick={Rejectres}
                // onClick={() => { page === 'buisnessList' && handleDelete() }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <FcDisapprove />
                  </div>
                  <div>Rejected</div>
                </div>
              </MenuItem>
            )}
            {isAdress && (
              <MenuItem
                onClick={() => openModal()}
                // onClick={() => { page === 'buisnessList' && handleDelete() }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <FcDisapprove />
                  </div>
                  <div>Rejected</div>
                </div>
              </MenuItem>
            )}

{isStaffDow && (
         
          <div>
            <MenuItem onClick={() => navigate(`/StaffinfoDow/${id}`)}>
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <MdOutlineAssignmentTurnedIn />
                </div>
                <div>StaffInfoDow</div>
              </div>
            </MenuItem>
          
          </div>
          // {/* </MenuItem> */}
        )}

            {/* {isAssignLevel && <MenuItem onClick={() => {
           handleClose()
            handleAssign()
           
          }} >
            <div className='d-flex align-items-center justify-content-lg-between gap-2'>
              <div><BiEdit /></div>
              <div>Assign Level</div>
            </div>
          </MenuItem>} */}
            {/* {isUnassign && <MenuItem onClick={() => {
            handleClose()
          }} >
            <div className='d-flex align-items-center justify-content-lg-between gap-2'>
              <div><BiEdit /></div>
              <div onClick={removeAssignId }>Remove Level</div>
            </div>
          </MenuItem>} */}
            {isPermissionsRole && (
              <MenuItem
                onClick={() => navigate(`/AssignEmpRoleView/${id}`)}
                // onClick={() => { page === 'buisnessList' && handleDelete() }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <GrView />
                  </div>
                  <div>ViewPermissions</div>
                </div>
              </MenuItem>
            )}
          </>
        }
        {isAttendance && (
          <>
            {menuItems.map((item, index) => (
              <MenuItem key={index}>
                <button
                  onClick={item.onClick}
                  className="text-center"
                  style={{
                    backgroundColor: item.color,
                    color: "#FFFFFF",
                    borderRadius: "8px",
                    width: "15vh",
                  }}
                >
                  {item.label}
                </button>
              </MenuItem>
            ))}
          </>
        )}
        {markPaid && (
          <MenuItem
            onClick={() => {
              onClick();
              handleClose();
            }}
          >
            <div className="d-flex align-items-center justify-content-lg-between gap-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-clipboard-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                  />
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                </svg>
              </div>
              <div>Mark Paid</div>
            </div>
          </MenuItem>
        )}
        {isAssign && (
          // <MenuItem
          //   onClick={() => {
          //     onDeleteClick();
          //     handleClose();
          //   }}
          //   style={{
          //     display: "flex",
          //     flexDirection: "column",
          //     justifyContent: "space-between",
          //   }}
          // >
          <div>
            <MenuItem onClick={() => navigate(`/AssignRole/${id}`)}>
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <MdOutlineAssignmentTurnedIn />
                </div>
                <div>Assign Role</div>
              </div>
            </MenuItem>
            {/* <Button
           
              className="submitButtons"
              style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
            >
              Assign Role
            </Button> */}
            {/* <Button
              onClick={handleSubmit}
              className="submitButtons"
              style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
            >
              unassign Role
            </Button> */}
            <MenuItem onClick={handleSubmit}>
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <CgCloseR />
                </div>
                <div> unAssign Role</div>
              </div>
            </MenuItem>
          </div>
          // {/* </MenuItem> */}
        )}

        {isLeave && (
          // <MenuItem
          //   onClick={() => {
          //     onDeleteClick();
          //     handleClose();
          //   }}
          //   style={{
          //     display: "flex",
          //     flexDirection: "column",
          //     justifyContent: "space-between",
          //   }}
          // >
          <div>

     
            {selectedLevel !== "approved" && (
              // <Button
              //   onClick={() => {
              //     handleClose();
              //     openModal();
              //     displaydata(id);
              //   }}
              //   className="submitButtons"
              //   style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              // >
              //   Approved Leave
              // </Button>

              <MenuItem
                onClick={() => {
                  handleClose();
                  openModal();
                  displaydata(id);
                }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <MdOutlineAssignmentTurnedIn />
                  </div>
                  <div> Approved Leave</div>
                </div>
              </MenuItem>
            )}

            {selectedLevel !== "denied" && (
              // <Button
              //   onClick={() => {
              //     handleClose();
              //     openEditModal();
              //     displaydata(id);
              //   }}
              //   className="submitButtons"
              //   style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              // >
              //   Denied Leave
              // </Button>
              <MenuItem
                onClick={() => {
                  handleClose();
                  openEditModal();
                  displaydata(id);
                }}
              >
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <CgCloseR />
                  </div>
                  <div> Denied Leave</div>
                </div>
              </MenuItem>
            )}
            {selectedLevel !== "forwaded" && (
              // <Button
              //   className="submitButtons"
              //   style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              //   onClick={handleShow}
              // >
              //   Forwaded
              // </Button>
              <MenuItem onClick={handleShow}>
                <div className="d-flex align-items-center justify-content-lg-between gap-2">
                  <div>
                    <IoIosArrowRoundForward />
                  </div>
                  <div>Forwaded</div>
                </div>
              </MenuItem>
            )}

            {/* <Button
              className="submitButtons"
              style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              onClick={handleShowleaveDetails}
            >
              view Emp info
            </Button> */}
            <MenuItem onClick={handleShowleaveDetails}>
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <BiSolidUserDetail />
                </div>
                <div> view Emp info</div>
              </div>
            </MenuItem>
            </div>
          // {/* </MenuItem> */}
        )}

        {isViewEmpPermissionsRole && (
          <div>
            {/* // <MenuItem
          //   onClick={() => {
          //     onDeleteClick();
          //     handleClose();
          //   }}
          //   style={{
          //     display: "flex",
          //     flexDirection: "column",
          //     justifyContent: "space-between",
          //   }}
          // > */}
            <MenuItem
              onClick={() => {
                handleClose();
                openEditModal();
                displaydata(id);
              }}
            >
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <FaExchangeAlt />
                </div>
                <div>Change Seniority</div>
              </div>
            </MenuItem>
            {/* <div
              style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              // className="submitButtons"
             
            >
              
            
            </div> */}
            <MenuItem onClick={() => navigate(`/AssignRole/${id?.id}`)}>
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <MdOutlineAssignmentTurnedIn />
                </div>
                <div>Assign Role</div>
              </div>
            </MenuItem>

            <MenuItem
              onClick={() => navigate(`/AssignEmpRoleView/${id?.roleId}`)}
            >
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <IoMdDoneAll />
                </div>
                <div> View all Permissions</div>
              </div>
            </MenuItem>
            <MenuItem onClick={UnAssignRollEMp}>
              <div className="d-flex align-items-center justify-content-lg-between gap-2">
                <div>
                  <CgCloseR />
                </div>
                <div> Unassign Role</div>
              </div>
            </MenuItem>

            {/* <Button
            
              style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              className="submitButtons"
            >
            
            </Button> */}
            {/* <Button
              onClick={() => navigate(`/AssignEmpRoleView/${id?.roleId}`)}
              style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              className="submitButtons"
            >
              View All PermissionsRole
            </Button> */}

            {/* <Button
              onClick={handleSubmit}
              style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
              className="submitButtons"
            >
              unAssign Role
            </Button> */}

            {allowEmpAssign === "1" && (
              <>
                {/* <Button
                onClick={() => {
                  handleClose();
                  openModal();
                  displaydata(id);
                }}
                style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
                className="submitButtons"
              >
                Supervised Staff Add
              </Button> */}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    openModal();
                    displaydata(id);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-lg-between gap-2">
                    <div>
                      <IoMdPersonAdd />
                    </div>
                    <div>Add Staff</div>
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    openModalStaffView();
                    displaydata(id);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-lg-between gap-2">
                    <div>
                      <GrView />
                    </div>
                    <div> View Staff </div>
                  </div>
                </MenuItem>
                {/* <Button
               onClick={() => {
                 handleClose();
                 openModalStaffView();
                 displaydata(id);
               }}
               style={{ marginTop: "3px", width: "100%", fontSize: "12px" }}
               className="submitButtons"
             >
               Supervised  Staff View
             </Button> */}
              </>
            )}
            {/* // </MenuItem> */}
          </div>
        )}
      </Menu>
    </div>
  );
}

export default TableDropdown;
