import React, { useState, useEffect, useRef } from "react";
import loginImg from "../Aseset/image/qrstaff.png";
import logo from "../Aseset/image/bluelogo.png";
import "./login.css";
import { helper } from "../lib/helper";
import auth from "../Model/auth.model";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { setbuiHome } from "../store/silce/dashboardSlice";
import appstore from "../Aseset/image/appstore.webp";
import playstore from "../Aseset/image/googleplay.webp";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";

const Login = () => {
  const [formErrors, setFormErrors] = useState([]);
  const [is_error, setIsError] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isOTPSent, setisOTPSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerify, setIsVerify] = useState(false);

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const mobileNumberLimit = 10;
  const otpLimit = 6;
  const [num, setNum] = useState("");
  const [num1, setNum1] = useState("");
  const [isotp, setIsOtp] = useState("");
  const otpAuth = async (data) => {
    const formData = new FormData();
    formData.append("cont_verify_otp", num);
    formData.append("otp_log", isotp);
    await auth
      .otp(formData)
      .then((res) => {
        
        //localStorage.setItem("token", res.data.token);
        localStorage.setItem("token", res.data.token);
        sessionStorage.setItem("access_token", res.data.token);
        sessionStorage.setItem("userinfo", JSON.stringify(res.data));
        

        if (res.data.is_error === false) {
          if (res.data.usr_type === "Client") {
            window.location.assign("./home");
            helper.sweetalert.toast("Welcome Back");
          } else {
            window.location.assign("/admin/dashboard");
            helper.sweetalert.toast("Welcome Back");
          }
          setIsVerified(true);
        } else {
          setError("Invalid OTP");
          helper.sweetalert.toast1(" please vaild OTP");
        }
      })
      .catch((error) => {
        // console.error(error.response?.data)
        setFormErrors(error.response?.data?.errors);
      });
  };

  const enterKeyPressed = useRef(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      enterKeyPressed.current = true;
    }
  };
 

  const onSubmit = handleSubmit(async (data, otp, event) => {
    setIsSubmitting(true);

    const formData = new FormData();

    if (!isOTPSent) {
      formData.append("cont_log", num);
      // console.log("data", data);
      await auth
        .login(formData)
        .then((res) => {
          if (res.data.is_error === false) {
            setIsError(true);
            setIsSubmitting(false);
            setisOTPSent(true);
            setIsVerify(true);
          }
        })
        .catch((error) => {
          console.error(error.response?.data);
          setFormErrors(error.response?.data?.errors);
        });
    } else {
      otpAuth(data);
      //     formData.append("cont_verify_otp", data.number);
      //     formData.append("otp_log", data.otp);
      //     await auth
      //       .otp(formData)
      //       .then((res) => {
      //         console.log(res.data);
      //         //localStorage.setItem("token", res.data.token);
      //         localStorage.setItem("token", res.data.token);

      //         console.log(res.data.usr_type === "Client");

      //         if (res.data.is_error === false) {
      //           if (res.data.usr_type === "Client") {
      //             window.location.assign("./home");
      //             helper.sweetalert.toast("Welcome Back");
      //           } else {
      //             window.location.assign("/admin/dashboard");
      //             helper.sweetalert.toast("Welcome Back");
      //           }
      //           setIsVerified(true);
      //         } else {
      //           setError("Invalid OTP");
      //           helper.sweetalert.toast1("Enter Worng OTP");
      //         }
      //       })
      //       .catch((error) => {
      //         // console.error(error.response?.data)
      //         setFormErrors(error.response?.data?.errors);
      //       });
    }
  });

  const limit = 10;

  // const handleInputChange = (event) => {
  //   let value = event.target.value;
  //   if (event.target.type === "number") {
  //     value = value.length > limit ? value.substring(0, limit) : value;
  //   }

  //   console.log(value, event.key, event, num);
  //   event.target.value = value;
  // };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to allow only up to 10 digits
    const sanitizedValue = inputValue.replace(/\D/g, "").slice(0, 10);
    setNum(sanitizedValue);
  };

  const limit1 = 6;
  const handleInputChange1 = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to allow only up to 10 digits
    const sanitizedValue = inputValue.replace(/\D/g, "").slice(0, 6);
    setNum1(sanitizedValue);
  };

  // const handleInputChange1 = (event) => {
  //   let value = event.target.value;
  //   console.log("value", value);
  //   if (event.target.type === "number") {
  //     value = value.length > limit1 ? value.substring(0, limit1) : value;
  //   }
  //   console.log(value, event.key, event, num1);
  //   event.target.value = value;
  // };
  useEffect(() => {
    // if (localStorage.getItem("token")) {
    //   window.location.replace("./home");
    //   // window.location.assign("./home");
    // }
  }, []);

 
  const handleChange = (otp) => {
    setIsOtp(otp); // Update the OTP value in the state
  };


  return (
    <form onSubmit={onSubmit}>
      <MDBContainer fluid className="">
        <Row className="justify-content-md-center">
          <Col xs lg={10}>
            <MDBRow>
              <MDBCol col="10" md="6" className="mt-1">
                <img
                  src={loginImg}
                  className="img-fluid"
                  alt="dispaly-img"
                  style={{ height: "97vh", borderRadius: "10px" }}
                />
              </MDBCol>
              <MDBCol
                col="4"
                md="6"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "1rem",
                  overflowY: "hidden",
                }}
              >
                <Container>
                  <Row className="justify-content-md-center">
                    <Col xs lg="10">
                      <img
                        style={{ height: "8vh" }}
                        src={logo}
                        class="img-fluid"
                        alt="Phone image"
                      />
                      <h2 className="mt-2" style={{ color: "#18285a" }}>
                        Log in to your account
                      </h2>
                      <div className="mt-2 text-secondary">
                        <p>
                          Enter your mobile number then click on send OTP
                          button.
                        </p>
                      </div>
                      <InputGroup className="mb-3" size="lg">
                        <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                        <Form.Control
                          wrapperClass="mb-4"
                          aria-label="Large"
                          id="outlined-basic"
                          placeholder="Enter Mobile Number"
                          fullWidth
                          variant="outlined"
                          size="md"
                          value={num}
                          name="cont_log"
                          required
                          type="number"
                          pattern="[0-9]"
                          style={{
                            appearance: "textfield",
                            outline: "none", // Remove default focus outline
                            boxShadow: "none", // Remove default focus box shadow
                            border: "1px solid #ced4da", // Set border color to match other states
                            transition: "border-color 0.15s ease-in-out", // Add transition for smooth effect
                          }}
                          onChange={handleInputChange}
                        />
                      </InputGroup>

                      {/* <MDBInput */}
                      {/* // style={{ fontFamily: "FontAwesome" }}
                      //   wrapperClass="mb-4"
                      //   placeholder="Enter Mobile Number"
                      //   size="lg"
                      //   onKeyUp={handleInputChange}
                      //   type="number"
                      //   maxLength={10}
                      //   id="outlined-basic fullWidth"
                      //   margin="normal"
                      //   {...register("number", { required: "Fill number" })}
                      //   isinvalid={formErrors?.number}
                      // /> */}
                      {is_error === true ? (
                        <div style={{marginBottom:"20px"}}>
                          {/* <MDBInput
                            size="lg"
                            wrapperClass="mb-4"
                            onKeyUp={handleInputChange1}
                            type="number"
                            maxLength={6}
                            id="outlined-basic fullWidth"
                            margin="normal"
                            {...register("otp", { required: "Fill number" })}
                            isinvalid={formErrors?.number}
                            {...register("otp", { required: "Fill number" })}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                onSubmit();
                              }
                            }}
                            isi
                            placeholder="Enter 6 Digit OTP"
                          /> */}
                          {/* <InputGroup className="mb-3" size="lg">
                            <Form.Control
                              wrapperClass="mb-4"
                              aria-label="Large"
                              id="outlined-basic"
                              placeholder="Enter 6 Digit OTP"
                              fullWidth
                              variant="outlined"
                              size="md"
                              value={num1}
                              name="cont_log"
                              required
                              type="number"
                              pattern="[0-9]"
                              style={{
                                appearance: "textfield",
                                outline: "none", // Remove default focus outline
                                boxShadow: "none", // Remove default focus box shadow
                                border: "1px solid #ced4da", // Set border color to match other states
                                transition: "border-color 0.15s ease-in-out", // Add transition for smooth effect
                              }}
                              onChange={handleInputChange1}
                            />
                          </InputGroup> */}
<Col>

                          <OtpInput
                            value={isotp}
                            onChange={handleChange}
                            numInputs={6} // Assuming OTP length is 6
                            // renderSeparator={<span>-</span>} // Uncomment if you want to add a separator
                            renderInput={(props, index) => (
                              <Form.Control
                                {...props}
                                key={index}
                                style={{
                                  // width: "100%",
                                  // height: "40px",
                                  marginRight: "35px",
                                  border:"1px solid lightgray"
                                }} // Example styling, adjust as needed
                              />
                              
                            )}
                          />
                          </Col>
                        </div>
                      ) : null}
                      {/* <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg' type='password' size="lg" /> */}
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#18285a",
                          borderStyle: "none",
                        }}
                        className="mb-4 w-100"
                        size="lg"
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        {isSubmitting
                          ? "Please Wait"
                          : isVerify
                          ? "Submit"
                          : "Send OTP"}
                      </Button>
                      <center>
                        <div className="d-flex align-items-center ml-5">
                          <div>Facing any issue ? </div>
                          <div
                            style={{ marginLeft: "2px", color: "#18285a" }}
                            className="fw-bold"
                          >
                            {" "}
                            Contact Support
                          </div>
                        </div>
                      </center>
                      <div className="mt-2">
                        <a href="https://apps.apple.com/in/app/qr-staff/id6444002653">
                          <img
                            src={appstore}
                            alt="apllie"
                            style={{ height: "40px" }}
                          />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=in.qrstaff">
                          <img
                            src={playstore}
                            alt=""
                            style={{ height: "40px", marginLeft: "20px" }}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </MDBCol>
            </MDBRow>
          </Col>
        </Row>
      </MDBContainer>
    </form>
  );
};

export default Login;
