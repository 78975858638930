import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import employeeModel from "../../Model/employee.Model";
import TextField from "@mui/material/TextField";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { FaSnapchat } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import "../../App.css"
const Social = () => {
  const [Addsocial, setAddSocial] = useState();
  const params = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    linkdin: "",
    snapchat: "",
    youtube: "",
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    if (params?.id !== undefined && params.id !== "") {
      form.append("emp_id", params.id);
      form.append("update_social_link", localStorage.getItem("token"));
    } else {
      form.append("update_social_link", localStorage.getItem("token"));
    }

    employeeModel
      .addsocial(form)
      .then((response) => {
        // console.log(response.data, "Bank Data Add");

        fetchData();
        handleClose();
        // Display success message to the user
        // window.location.assign("/stafflist");
      })
      .catch((error) => {
        console.log(error);
        // Display error message to the user
      });
  };
  const fetchData = async () => {
    const formdata = new FormData();
    if (params?.id !== undefined && params.id !== "") {
      formdata.append("emp_id", params?.id);
      formdata.append("get_social_link", localStorage.getItem("token"));
    } else {
      formdata.append("get_social_link", localStorage.getItem("token"));
    }
    employeeModel
      .viewsocial(formdata)
      .then((res) => {
        console.log(res.data.data);
        setAddSocial(res.data.data);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <MDBCard className="mt-3">
      <MDBCardHeader>
        <Row>
          <Col>
            <div style={{ position: "relative" }}>
              <span
                style={{
                  marginTop: "px",
                  marginLeft: "10px",
                  fontSize: "19px",
                }}
              >
                <>Social</>
              </span>
              <button
                className="btn"
                onClick={handleShow}
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                }}
              >
                Add New
              </button>
            </div>
          </Col>
        </Row>
      </MDBCardHeader>
      <MDBCardBody>
        <div>
          <Card>
            <Container className="mt-3">
              <Card bg="light">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-start gap-2">
                    <div>
                      <BsFacebook />
                    </div>
                    <div className="pt-1">Facebook</div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-sm-between">
                    <div>{Addsocial?.facebook}</div>
                    <div className="d-flex align-items-center justify-content-sm-between gap-2">
                      {/* <div><Button><BiEdit /></Button></div>
                                            <div><Button><MdDeleteForever /></Button></div> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card bg="light" className="mt-3">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-start gap-2">
                    <div>
                      <AiFillInstagram />
                    </div>
                    <div className="pt-1">Instagram</div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-sm-between">
                    <div>{Addsocial?.instagram}</div>
                    <div className="d-flex align-items-center justify-content-sm-between gap-2">
                      {/* <div><Button><BiEdit /></Button></div>
                                            <div><Button><MdDeleteForever /></Button></div> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card bg="light" className="mt-3">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-start gap-2">
                    <div>
                      <AiFillTwitterCircle />
                    </div>
                    <div className="pt-1">Twitter</div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-sm-between">
                    <div>{Addsocial?.twitter}</div>
                    <div className="d-flex align-items-center justify-content-sm-between gap-2">
                      {/* <div><Button><BiEdit /></Button></div>
                                            <div><Button><MdDeleteForever /></Button></div> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card bg="light" className="mt-3">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-start gap-2">
                    <div>
                      <AiFillLinkedin />
                    </div>
                    <div className="pt-1">Linkedin</div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-sm-between">
                    <div>{Addsocial?.linkdin}</div>
                    <div className="d-flex align-items-center justify-content-sm-between gap-2">
                      {/* <div><Button><BiEdit /></Button></div>
                                            <div><Button><MdDeleteForever /></Button></div> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card bg="light" className="mt-3">
                <Card.Header>
                  <div className="d-flex align-items-center justify-content-start gap-2">
                    <div>
                      <FaSnapchat />
                    </div>
                    <div className="pt-1">Snapchat</div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-sm-between">
                    <div>{Addsocial?.snapchat}</div>
                    <div className="d-flex align-items-center justify-content-sm-between gap-2">
                      {/* <div><Button><BiEdit /></Button></div>
                                            <div><Button><MdDeleteForever /></Button></div> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              {/* <Card bg='light' className='mt-3'>
                                <Card.Header>
                                    <div className='d-flex align-items-center justify-content-start gap-2'>
                                        <div><AiFillYoutube /></div>
                                        <div className='pt-1'>Youtube</div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-sm-between'>
                                        <div>http://www.w3.org/2000/svg</div>
                                        <div className='d-flex align-items-center justify-content-sm-between gap-2'>
                                            <div><Button><BiEdit /></Button></div>
                                            <div><Button><MdDeleteForever /></Button></div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card> */}
            </Container>
          </Card>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Social Media</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit}>
                <Container>
                  <Row>
                    <Col sm={4}>
                      {" "}
                      <span style={{ fontSize: "16px" }}>Facebook</span>
                    </Col>
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Facebook link"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="facebook"
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={4}>
                      {" "}
                      <span style={{ fontSize: "16px" }}>Instagram</span>
                    </Col>
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Instagram link"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="instagram"
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={4}>
                      {" "}
                      <span style={{ fontSize: "16px" }}>Twitter</span>
                    </Col>
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Twitter link"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="twitter"
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col sm={4}>
                      {" "}
                      <span style={{ fontSize: "16px" }}>Linkdin</span>
                    </Col>
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Linkdin link"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="linkdin"
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={4}>
                      {" "}
                      <span style={{ fontSize: "16px" }}>Snapchat</span>
                    </Col>
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Snapchat link"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="snapchat"
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                  <div className=" mt-3">
                    {/* <Button
                      className=""
                      style={{
                        backgroundColor: "gray",
                        color: "white",
                        borderStyle: "none",
                      }}
                      onClick={() => handleClose()}
                    >
                      {" "}
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#18285a",
                        borderStyle: "none",
                        float: "right",
                      }}
                    >
                      {" "}
                      Submit
                    </Button> */}
                    <Button
                          style={{ float: "left", marginLeft: "5%" }}
                          className="w-25"
                          variant="secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </Button>
                        <Button
                          style={{
                            float: "right",
                            borderStyle: "none",
                            marginRight: "5%",
                          }}
                          className="btn submitButtons w-25"
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                  </div>
                </Container>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Social;
