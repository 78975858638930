import React, { useState, useEffect, useContext } from 'react'; import './Navbar.css';
import auth from '../Model/auth.model';
import Card from '@mui/material/Card';
import { Navbar, Container, Dropdown } from 'react-bootstrap/';
import NavDropdown from 'react-bootstrap/NavDropdown';
import businessModel from '../Model/business.model';
import { useDispatch, useSelector } from 'react-redux';
import { changeBusiness } from '../store/silce/bussiness';

const Topnav = () => {
    const dispatch = useDispatch()

    const { businessId, updateBusinessId } = useState();
    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;

        try {
            const formdata = new FormData();
            formdata.append("change_site", localStorage.getItem("token"));
            formdata.append("def_bus", selectedValue);
            businessModel.ChangeDefaultBusiness(formdata).then((res) => {
                updateBusinessId(selectedValue);
                localStorage.setItem('selecteoption', selectedValue);
            })
        } catch (error) {
           
        }

    };


    const [displayprofile, setdisplayprofile] = useState([]);
    useEffect(() => {
        try {
            const formdata = new FormData();
            formdata.append("get_home", localStorage.getItem("token"));
            formdata.append("bus_id", localStorage.getItem("selecteoption"));

            auth.home(formdata).then((res) => {
                //console.log("ressss => ", res)
                if(res?.data?.is_error === false) {
                    setdisplayprofile(res?.data)
                } else {
                    if(res?.data?.message === "Please Re-Login") {
                        localStorage.clear();
                        window.location.assign("/");
                    }
                }
            })
        } catch (error) {
           
        }

    }, [])



    return (
        <>

            <Card variant="outlined" style={{ marginTop: "1px", borderRadius: "0px", backgroundColor: "#f7f7fa", height: "60px",position:'relative'}}>
                {/* <img src={`https://qrstaff.in/api/logo/${displayprofile?.bussiness?.[0]?.bus_logo}`} alt="" style={{
                    backgroundColor: "bisque",
                    marginTop: "3px",
                    marginLeft: '5px',
                    "height": " 50px",
                    "width": "50px",
                    marginRight:'5px',
                    position: "relative"
                }} className='' /> */}
                 <span style={{top:'5px',position:'relative'}} >Hi, <b >{displayprofile.log_name} !</b></span>
                <div style={{
                    "position": "relative",
                    "float": "right",
                    "right": "8px"
                }} className='mt-3'>
                    <label variant="success" id="dropdown-basic" style={{ paddingRight: "10px" }}>
                        Change Bussiness
                    </label>

                    <select value={businessId} onChange={handleDropdownChange} style={{height:'30px',width:'200px',fontWeight:'800'}}>
                        {
                            displayprofile?.bussiness && displayprofile?.bussiness.map((logo) => {
                                if(businessId === "" && logo?.default === "true") {
                                    updateBusinessId(logo?.id)
                                    localStorage.setItem('selecteoption', logo?.id);
                                }
                                return (
                                    <option value={logo?.id} style={{fontWeight:'800',padding:'15px'}}  >
                                      
                                       <b  style={{padding:'15px'}}  > {logo.name}</b>
                                    </option>
                                )
                            })}
                    </select>
                </div>
            </Card>

        </>
    )
}

export default Topnav

