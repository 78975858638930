import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";

// Create an async thunk for getting all businesses

export const updateatt = createAsyncThunk("attendance/updateatt", async (id, payload) => {
  console.log("ghjkl--->", payload)
  try {
    const response = await axiosInstance.post(`/attendance.php`, payload);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

// Create an async thunk for deleting a business
export const deleteBusiness = createAsyncThunk("business/deleteBusiness", async ({ id, payload }) => {
  try {
    const response = await axiosInstance.post(`/add_bus.php`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const editBusiness = createAsyncThunk("business/editBusiness", async ({ id, payload }) => {
  try {
    const response = await axiosInstance.post(`/add_bus.php`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const fetchAllAttendance = createAsyncThunk("attendance/fetchAllAttendance", async (_, { dispatch }) => {
  const formdata = new FormData();
  formdata.append("emp_att_list", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  // formdata.append("bus_id", localStorage.getItem("bus_idEmp"))
  try {
    const response = await axiosInstance.post("/attendance.php", formdata);
    console.log("fetchAllAttendance:", response.data);
    dispatch(setFilterData(response.data?.emp))
    return response.data.emp;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

export const fetchAllPrevAttendance = createAsyncThunk("attendance/fetchAllPrevAttendance", async ({formattedDate:formattedDate}, { dispatch }) => {
  const formdata = new FormData();
  formdata.append("get_previous", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("data_dt", formattedDate);
  try {
    const response = await axiosInstance.post("/attendance.php", formdata);
    console.log("fetchAllPrevAttendance:", response.data);
    dispatch(setPrevFilterData(response.data?.emp))
    return response.data.emp;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});
export const AttendanceStatusprev = createAsyncThunk("attendance/AttendanceStatusprev", async ({status,date}) => {
  const formdata = new FormData();
  formdata.append("attendance_kpi_empList", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("att_type", status);
  formdata.append("selected_date", date);
  try {
    const response = await axiosInstance.post("/home.php", formdata);
    return response.data.data.emp;
  } catch (error) {
    return error.response.data;
  }
});

export const fetchSelfieHistory = createAsyncThunk("attendance/fetchSelfieHistory", async ({formattedDate}) => {
  const formdata = new FormData();
  formdata.append("selfie_history", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("sel_dt", formattedDate);
  try {
    const response = await axiosInstance.post("/attendance.php", formdata);
    console.log("fetchSelfieHistory:", response.data);
    // dispatch(setPrevFilterData(response.data?.emp))
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

const businessSlice = createSlice({
  name: "attendance",
  initialState: {
    oldData:false,
    attendance: [],
    errorMessage: "",
    loading: false,
    attendanceList: [],
    prevAttendanceList: [],
    prevAttendanceListStatusbaise:[],
    filterdData: [],
    prevFilterdData:[],
    selfieHistoryList:[],
    selfieFilterdData:[],
   prevStatusloading:false
  },
  reducers: {
    // You can add other non-async actions here if needed.
    setFilterData: (state, action) => {
      state.filterdData = action.payload
    },
    setPrevFilterData: (state, action) => {
      state.prevFilterdData = action.payload
    },
    setSelfieFilterData: (state, action) => {
      state.selfieFilterdData = action.payload
    },
    setOldData: (state, action) => {
      state.oldData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllAttendance.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action", action)
        state.attendanceList = action.payload;
      })
      .addCase(fetchAllAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(updateatt.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateatt.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(updateatt.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
      .addCase(deleteBusiness.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBusiness.fulfilled, (state, { payload }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(deleteBusiness.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchAllPrevAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPrevAttendance.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action", action)
        state.prevAttendanceList = action.payload;
        // state.attendanceList = action.payload;
      })
      .addCase(fetchAllPrevAttendance.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(AttendanceStatusprev.pending, (state) => {
        state.prevStatusloading = true;
      })
      .addCase(AttendanceStatusprev.fulfilled, (state, action) => {
        state.prevStatusloading = false;
        state.prevAttendanceListStatusbaise = action.payload;
        // state.attendanceList = action.payload;
      })
      .addCase(AttendanceStatusprev.rejected, (state, { payload }) => {
        state.prevStatusloading = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchSelfieHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSelfieHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.selfieHistoryList = action.payload;
      })
      .addCase(fetchSelfieHistory.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
  },
});

// Export actions
export const { /* Other non-async actions */ setFilterData, setPrevFilterData, setSelfieFilterData  ,setOldData} = businessSlice.actions;

// Export the reducer
export default businessSlice.reducer;
