import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import { Container, Row, Col } from "react-bootstrap";
import AttModal from "../Model/Att.modal";
import moment from "moment";
import DataTable from "../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelfieHistory,
  setSelfieFilterData,
} from "../store/silce/attendance";
const SelfiAttendance = () => {
  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState(currentDate);
  const [Selfiatt, setSelfiatt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const selfieHistoryList = useSelector(
    (state) => state?.attendance?.selfieHistoryList?.selfie
  );
 
  const loading = useSelector((state) => state.attendance.loading);
  const busid = useSelector((state) => state?.selectOption?.value);
  const selfieFilterdData = useSelector(
    (state) => state.attendance.selfieFilterdData
  );
  const columns = [
    {
      name: '#',
      cell: (row, index) => index + 1,
      width: '4%',
      center: 'center',
  },  
    {
      name: "Name",
      selector: (row) => row.name,
      maxWidth: "150px",
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      maxWidth: "150px",
    },
    {
      name: "Image",
      cell: (row) => {
        return (
          <div>
            <img
              src={`${row.selfie_url}`}
              alt=""
              style={{ width: "30px", height: "30px", borderRadius: "5px" }}
            />
          </div>
        );
      },
      width: "8%",
      center: "center",
    },
    {
      name: "In Time",
      selector: (row) => row.time,
      cell: (row) => {
        return (
          <div>
            <p>
              {row.type} - {row.time}
            </p>
          </div>
        );
      },
      maxWidth: "120px",
    },
    {
      name: "Loaction",
      selector: (row) => row.loc_name,
    },
  ];
  const [formDataNew, setFormDataNew] = useState({
    sel_dt: "",
  });
  const handleChange = (event, userTime) => {
    setFormDataNew({
      ...formDataNew,
      [event.target.name]: event.target.value,
    });
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const [input, setInput] = useState("");
  const formattedDate = moment(selectedMonth).format("DD-MM-YYYY");
  useEffect(() => {
    // Make your API call here with the selectedMonth
    dispatch(fetchSelfieHistory({ formattedDate: formattedDate }));
  }, [formattedDate, busid]);
 
  //   const handleSubmit  = async (event ) => {
  //     setIsLoading(true)
  //     event.preventDefault();
  //     dispatch(fetchSelfieHistory({formattedDate:formattedDate}))
  // }
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "900",
      },
    },
  };
  const formdata = moment(selectedMonth).format("YYYY-MM-DD");
  return (
    <>
      <div>
        <Container fluid>
          <Row>
        
            <form>
              <span className="text-black" style={{ fontSize: "16px" }}>
                Select Date
              </span>{" "}
              <input
                className="text-black"
                type="date"
                name="data_dt"
                id=""
                value={formdata}
                onChange={handleMonthChange}
                style={{ height: "35px", width: "20%" }}
              />
            </form>
            <Col>
              <div
                style={{
                  "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                }}
                className=" mt-3 mb-4"
              >
                <DataTable
                  title="Selfi Attendance List"
                  columns={columns}
                  customStyles={customStyles}
                  data={selfieHistoryList}
                  filterdData={selfieFilterdData}
                  setData={setSelfieFilterData}
                  keys={["name"]}
                  pagination
                  fixedHeader
                  fixedHeaderScrollHeight="470px"
                  selectableRowsHighlight
                  highlightOnHover
                  page={"Attendance"}
                  loading={loading}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default SelfiAttendance;
