import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv'
import { Container, Row, Col, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import AttModal from '../Model/Att.modal';

const Dayattendance = () => {
    const [toadayatt, setToadayatt] = useState([]);

    const [formData, setFormData] = useState({
        data_dt: '',


    });
    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = (event) => {

        event.preventDefault();
        const form = new FormData(event.target);

        form.append("get_previous", localStorage.getItem("token"));
        form.append("bus_id", localStorage.getItem("selecteoption"));

        AttModal.PreviousAtt(form).then((res) => {
            setToadayatt(res.data)
      
        }
        ).catch((error) => {
           
        })
    }

    return (
        <>
            <div>
                <Container fluid >
                    <Row>
                        <Col>
                            <h3 className="fs-4 mb-3">Attendance  By Day</h3>
                            <form onSubmit={handleSubmit}>
                                <input type="date" name="data_dt" id=""
                                    value={formData.dob}

                                    onChange={handleInputChange}
                                />
                                <button type="submit"> submit</button>
                            </form>
                        </Col>
                    </Row>
                    <Row>

                        <p>{toadayatt.message}</p>
                        {
                            toadayatt?.emp && toadayatt?.emp.map((todaylist,i) =>
                                <Col key={i} sm={4}>
                                    <div>


                                        <Card>
                                            <p><b>Name:</b> {todaylist.name}</p>
                                            <p><b>Designation</b>:{todaylist.designation}</p>
                                            <p><b>Time in</b> :{todaylist.in_tim}</p>
                                            <p><b>Comment</b>{todaylist.out_comment}</p>
                                            <p><b>Time in</b>{todaylist.out_tim}</p>
                                            <img src={`http://3.111.95.141/api/pro_pic/${todaylist.pro_img}`} alt="Avatar" className="m-1" style={{ width: "100px", height: '100px', borderRadius: "10px" }} />
                                        </Card>

                                    </div>
                                </Col>
                            )
                        }




                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Dayattendance;