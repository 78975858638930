import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Tab, Tabs } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import EmphomeModal from './Modal/Emphome.Modal';
import DataTable from 'react-data-table-component';
import CommonModal from '../components/Common/CommonModal';
import { closeModal, openModal } from '../store/silce/modalSlice';
import { clearSelectedData } from '../store/silce/roll';
import { useDispatch, useSelector } from 'react-redux';
import { GetReimbursemnt } from '../store/silce/Employee';
import "../App.css"
const EmpReimbursement = () => {
  const dispatch = useDispatch()
  const [EmpReimHistory, setEmpReimHistory] = useState();
  const [currentTitle, setCurrentTitle] = useState('add-reimbursemnet')
  const showModal = useSelector((state) => state.modal.showModal);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const [formDataNew, setFormDataNew] = useState({
    amount: '',
    reason: '',
    image: '',
  });
  const handleInputChange = (event) => {
    setFormDataNew({
      ...formDataNew,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
   dispatch(GetReimbursemnt({event}))
  }
  useEffect(() => {
    const form = new FormData();
    form.append("reimbursement_history", localStorage.getItem("token"));
    EmphomeModal.EmpResHistory(form).then((res) => {
     
      setEmpReimHistory(res.data.data.reimbursement)
    }
    ).catch((error) => {
      
    })
  }, [])
  const columns = [
    {
      name: 'SN.',
      cell: (row, index) => index + 1,
      //RDT provides index by default,
      width: '70px'
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      width: '100px'
    },
    {
      name: 'Image',
      selector: (row) => row.image,
      cell: (row) => {
        return (
          <img src={`http://qrstaff.in/api/image/${row.image}`}
            alt="Avatar"
            className="m-1" style={{ width: "30px", height: '30px', borderRadius: "10px" }} />
        );
      },
      width: '100px'
    },
    {
      name: 'Applied On',
      selector: (row) => row.applied_on,
    },
    {
      name: 'reason',
      selector: (row) => row.reason,
    },
    {
      name: "Status",
      width: "10%",
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status === "Approved") {
          return (
            <>
              <center>
                {" "}
                <p
                  className="text-capitalize mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    fontSize: "12px",
                    color: "rgb(81 151 81)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
            </>
          );
        } else if (row.status === "Rejected") {
          return (
            <>
        
              <center>
                {" "}
                <p
                  className="text-capitalize  mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    fontSize: "12px  ",
                    color: "rgb(202 90 90)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        } else if (row.status === "Pending") {
          return (
            <>
              {/* <div
                className="w-100"
                style={{
                  backgroundColor: " rgb(255, 165, 0)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  height: "3vh",
                }}
              > */}
              <center>
                {" "}
                <p
                  className="text-capitalize  mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    color: "rgb(255, 165, 0)",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        }
      },
    },
  ]
  return (
    <>
      <button className='btn mb-2' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }}
       onClick={() => {
        dispatch(openModal("add-reimbursemnet"))
        setCurrentTitle("Add Reimbursemnet")
    }}
      >Add New </button>
      <DataTable
        title=''
        columns={columns}
        data={EmpReimHistory}
        pagination
        selectableRowsHighlight
        highlightOnHover
      />
         <CommonModal onSubmit={handleSubmit} show={showModal} onHide={() => {
                    dispatch(closeModal()) 
                }}  title={currentTitle} name={currentPage} />
    </>
  )
}

export default EmpReimbursement
