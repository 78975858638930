import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import noticeModel from "../Model/notice.model";
import moment from "moment";
import DataTable from "../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import { setFiltertoday, fetchAllNotice, noticedetailsID } from "../store/silce/dashboardSlice";
import TableDropdown from '../components/TableDropDown/TableDropdown';
import { Link } from "react-router-dom";
import { openEditModal } from "../store/silce/modalSlice";
const Noticehistory = () => {
  const loading = useSelector((state) => state.dashboard.loading);
  const [search, setSearch] = useState("");
  const filtertoday = useSelector((state) => state.dashboard.filtertoday);
  const noticeHistoryAllData = useSelector(
    (state) =>  state?.dashboard?.notice?.data
  );  
  const noticeIDDetails = useSelector(
    (state) => state?.dashboard?.noticeID?.data?.notice
  );
  const busid = useSelector(state =>state?.selectOption?.value)
  const employeeDetails = useSelector(state => state?.employee?.Employee?.data?.permissions)
  const filteredData = employeeDetails && employeeDetails?.filter((item) => item?.name === "send_notice");

  const notice = useSelector((state) => state.dashboard.notice);
  const dispatch = useDispatch();
  const Admindetails = useSelector(state =>state?.dashboard?.home?.data?.roleName)
  
  const [assingeemp, setAssingeemp] = useState(null);
  
  const [noticeDetails, setNoticeDetailsp] = useState(false);
  const handleClosenoticeDetails = () => setNoticeDetailsp(false);
  const handleShownoticeDetails = (id) => {
    dispatch(noticedetailsID({noticeId: id }));
    setNoticeDetailsp(true);
  }
  useEffect(() => {
    dispatch(fetchAllNotice());
  }, [busid]);
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "3vw",
    },
    {
      name: "Title",
      selector: (row) => row.title,
     width:"10vw"
    },
    {
      name: "Image",
      selector: (row) => row.notice_file_url,
      cell: (row) => {
        return (
            <img src={row.notice_file_url}
                alt="Avatar"
                style={{ width: "30px", height: '30px', borderRadius: "10px" }} />
        );
    },
    width:"5vw",
    center:'center',
    },
    {
      name: "Message",
      selector: (row) => row.msg,
      width:"37vw",
    },
    {
      name: "Notice Date",
      selector: (row) => row.notice_time,
      cell: (row) => {
        return <div>{moment(row?.notice_time).format("DD-MM-YYYY")}</div>;
      },
      // width: "10%",
    },
    {
      name: "Action",
      selector: (row) => row.pro_img,
      cell: (row) => {
        return (
          <TableDropdown
            isView={true}
            
            openViewClick={() => {
              setAssingeemp(row.notice_id)
              handleShownoticeDetails(row.notice_id)
          
              // dispatch(noticedetailsID( {noticeId: row.notice_id }));
              // dispatch(openEditModal("edit-sub-department"))
              
            }} 
          />

        );
      },
      // width: "100px",
    },
  ];


  

  return (
    <>
      <Container fluid >
     
        <Row>

          { (filteredData && filteredData.length > 0 && (filteredData[0]?.add === 'true')) || Admindetails === 'client' ?
          (<>{ (
            filteredData && filteredData.length > 0 && filteredData[0]?.add === 'true') ? 
            <Link to='/Boradcasts'> 
             <button style={{ backgroundColor: '#18285A', color: '#fff', padding: "8px", float: 'right', borderRadius: '5px' }} >Add Notice</button>
             </Link>
                : (<>
            <Link to='/Boradcast'>
                <button style={{ backgroundColor: '#18285A', color: '#fff', padding: "8px", float: 'right', borderRadius: '5px' }} >Add Notice</button></Link>
                </>)
          }</>):(<></>) 
          }
      {/* { (
        filteredData && filteredData.length > 0 && filteredData[0]?.add === 'true') ? 
        <Link to='/Boradcasts'> 
         <button style={{ backgroundColor: '#18285A', color: '#fff', padding: "8px", float: 'right', borderRadius: '5px' }} >Add Notice</button>
         </Link>
            : (<>
        <Link to='/Boradcast'>
            <button style={{ backgroundColor: '#18285A', color: '#fff', padding: "8px", float: 'right', borderRadius: '5px' }} >Add Notice</button></Link>
            </>)
      } */}
        
          <Col>
            <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className=' mt-2 mb-4'>
              <DataTable
                title={"Notice"}
                columns={columns}
                data={noticeHistoryAllData}
                page={"dashboard"}
                filterdData={filtertoday}
                isSubHeader={true}
                loading={loading}
                searchData={notice?.data}
                setData={setFiltertoday}
                keys={["title", "msg"]}
              />
            </div>
          </Col>
        </Row>
        <Modal show={noticeDetails} onHide={handleClosenoticeDetails} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Notice Details</Modal.Title>
            </Modal.Header>
              <Modal.Body style={{maxHeight:'400px',minHeight:"auto", overflowY:'scroll'}}>
              
                  <Container>
                    <Row>
                      <Col md={7}>
                     <span><strong>Send By:</strong></span>   <span>{noticeIDDetails?.added_by_name}</span>
                        <p className='mt-2'><strong>Title: </strong>    {noticeIDDetails?.title}</p>
                        
                       <p className='mt-2'><strong>Message: </strong></p> <span className=" text-align-justify">  {noticeIDDetails?.msg}</span>
                      <br />
                      <span><b>Send To User :-</b></span> <br />
                      {/* {noticeIDDetails?.sent_user?.count === null ? (<></>)(<></>)} */}
                      {noticeIDDetails?.sent_user?.users.map((user) => 
                   <>  <span> {user?.name} </span> <br/> </>
                      )}
                
                      </Col>
                      <Col md={5}>
                        <img src={noticeIDDetails?.notice_file_url}  alt="" width="100%" height="250px "/>
                        
                      </Col>
                    </Row>
                  </Container>
                          
            </Modal.Body>
          </Modal>
      </Container>
   

    </>
  );
};
export default Noticehistory;
