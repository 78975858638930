import axiosInstance from "../lib/axiosInstance";
const Attendance = class {
  
  
    async dayatthisorty(attendance ) {
       
        return await axiosInstance.post("/attendance.php", attendance);
    }
    async monthatthisorty(attendance ) {
       
        return await axiosInstance.post("/employee.php", attendance);
    }
    async allatt(data){
        return await axiosInstance.post('/attendance.php' , data)
    }
    async PreviousAtt(data){
        return await axiosInstance.post('/attendance.php' ,data)
    }
    async Attupdate(data){
        return await axiosInstance.post('/attendance.php' , data)
    }
    async SelfieHistory(data){
        return await axiosInstance.post('/attendance.php' , data)
    }
    async undoatt(data){
        return await axiosInstance.post('/attendance.php'  , data)
    }
    async Autopunch(data){
        return await axiosInstance.post('/attendance.php'  , data)
    }
    async genmonth(data){
        return await axiosInstance.post('/attendance.php'  , data)
    }
    async gencustom(data){
        return await axiosInstance.post('/attendance.php'  , data)
    }
    async doclist(data){
        return await axiosInstance.post('/documents.php'  , data)
    }
   
}

export default new Attendance();