// import React from "react";
// import Sidenav from "./sidenav";
// import Topnav from "./topnav";
// import Navbar from "../components/Navbar/Navbar";

// function Admin({ children }) {
//   return (
//     <>
//       <div className="d-flex" id="wrapper">
//         <Sidenav />
//         <div id="page-content-wrapper" style={{marginLeft:'210px'}}>
//           {/* <Topnav /> */}
//           <Navbar/>
//           {children}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Admin;









import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// import Navbar from '../components/Navbar/Navbar';
import Sidenav from "./sidenav";
// import { AppContext } from '../lib/AppContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './admin.css'
import { useDispatch, useSelector } from 'react-redux';
import SideBarEmp from '../components/Sidebar/SideBarEmp'
import Navbar from '../components/Navbar/Navbar';
import { sidebarChildMenuClose } from '../store/silce/sidebarSlice';
import NavbarEmp from '../components/Navbar/NavbarEmp';

const drawerWidth = 240;
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Emp({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  // const [open, setOpen] = React.useState(false);+
  // const { handleMouseOut, selectedHoverData, isHovering, handleMouseOver } = React.useContext()
  const location = useLocation();
  const isHovering = useSelector((state) => state.sidebar.isHovering);
  const selectedHoverData = useSelector((state) => state.sidebar.selectedHoverData);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const [show1, setshow1] = React.useState(true);

  const [openMenus, setOpenMenus] = React.useState({});
  const [toggleButton, setToggleButton] =React.useState(false);

  React.useEffect(() => {
    const sessionData = sessionStorage.getItem("userinfo");
    const isClient = sessionData && JSON.parse(sessionData).usr_type === "Employee";
    const isOnHomePage = window.location.pathname === "/";

    if (!isClient || isOnHomePage) {
      window.location.assign(isClient ? "/admin/dashboard" : "/");
    }
  }, []);

  return (
    <main onClick={() => {
      dispatch(sidebarChildMenuClose(false));
    }} className={show1 ? 'space-toggle' : null}>
      <NavbarEmp show1={show1} setshow1={setshow1} setToggleButton={setToggleButton} toggleButton={toggleButton} />
      <SideBarEmp show1={show1} setToggleButton={setToggleButton} toggleButton={toggleButton} />
      <div onClick={() => {
        dispatch(sidebarChildMenuClose(false));
      }}>
        {children}
      </div>
    </main>
  );
}