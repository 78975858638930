import React from "react";
function Blank({ children }) {
  return (
    <>
      <div>
        {children}
      </div>
    </>
  );
}

export default Blank;
