import React, { useState, useEffect } from 'react';
import '../../Aseset/css/sidenav.css';
import { Container, Row, Col, Modal, Form, InputGroup } from 'react-bootstrap';
import businessModel from '../../Model/business.model';
import { helper } from '../../lib/helper';
import calander from '../../../src/Aseset/image/calendar.png'
import day from '../../../src/Aseset/image/30day.png'
import DataTable from '../../components/Tables/Tables';
import TableDropdown from '../../components/TableDropDown/TableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { closeEditModal, closeModal, openEditModal, openModal } from '../../store/silce/modalSlice';
import CommonModal from '../../components/Common/CommonModal';
import CommonEditModal from '../../components/Common/CommonEditModal';
const Desigationlist = () => {
    const dispatch = useDispatch();
    // const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [staff, setStaff] = useState([]);
    const [displaybus, setDisplaybus] = useState([]);
    const [sector, setSector] = useState([]);
    // const handleClose = () => setShowModal(false);
    // const handleShow = () => setShowModal(true);
    const handleClose1 = () => setShowModal1(false);
    const handleShow1 = () => setShowModal1(true);
    const [outid, setOutid] = useState(0)
    const [currentTitle, setCurrentTitle]= useState('add-designation') 
    const showModal = useSelector((state) => state.modal.showModal);
    const showEditModal = useSelector((state) => state.modal.showEditModal);
    const currentPage = useSelector((state) => state.modal.currentPage);
    const columns = [
        {
            name: 'SN.',
            cell: (row, index) => index + 1,
            width: '60px'
        },
       

        {
            name: 'Department Name',
            selector: (row) => row.name,
            width: '200px'
        },
        {
            name: 'Sub-Department Name',
            selector: (row) => row.subname,
            width: '200px'
        },
        {
            name: 'Degination Name',
            selector: (row) => row.deg,
            width: '200px'
        },
       
       
        {
            name: 'Total Staff',
            selector: (row) => row.count,
            width: '200px'
        },
    
        {
            name: 'Action',
            cell: (row) =>
                // <Row>
                //     <Col sm={6}>

                //         <button style={{ backgroundColor: "#fff" }} onClick={() => (displaydata(row.id))} >
                //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                //                 <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                //             </svg>
                //         </button>

                //     </Col>
                //     <Col sm={6}>
                //         <button style={{ backgroundColor: "#fff" }} onClick={() => { onDelete(row.id) }}  >
                //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                //                 <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                //                 <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                //             </svg>
                //         </button>
                //     </Col>

                // </Row>,
                <TableDropdown openEditModal={()=>{dispatch(openEditModal('edit-designation'))}} displaydata={displaydata} isRemove={true} isEdit={true} setCurrentTitle={setCurrentTitle} page="Designation" id={row.id} />,
            width: '80px'

        },
    ] 
    const playerSummary = [
        {
          name: "TI",
          subname:'Developer',
          deg:"Front-End Devolper",
          Date: "20-109-2023",
          count:'2'
        },
    ]
    useEffect(() => {
        fetchData();
    }, []);
    const onDelete = (id) => {
        helper.sweetalert.confirm('Are you sure?', "You won't be able to revert this!", "warning", true).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData();
                formdata.append("delete_bus", localStorage.getItem("token"));
                formdata.append("bus_id", id);
                businessModel.deletebusiness(formdata).then((res) => {
                
                    helper.sweetalert.toast("Deleted", 'Your Business has been deleted.', 'success')
                    fetchData();
                })

            }
        })
    }
    const fetchData = async () => {
        const formdata = new FormData();
        formdata.append("get_all_bus", localStorage.getItem("token"));
        businessModel.Businesslist(formdata).then((res) => {
  
            setStaff(res.data.bussiness)
        }).catch((error) => {
         
        })
    }
    const [formData, setFormData] = useState({
        cl_name: '',
        cl_cont: '',
        bus_sector: '',
        salary_basis: '',
        cl_email1: '',
        email_count:'1',
    });
    const [num, setNum] = useState('');
    const handleInputChange = (event) => {

        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });

        if (event.target.type === "number") {
            setNum(event.target.value.slice(0, limit));
        }

    };
    // const toggleModal = () => {
    //    
    //     setShowModal(!showModal);
    //     handleShow(true);
    //     // setShow(!show);
    // };
    const handleSubmit = (event) => {

        event.preventDefault();
        const form = new FormData(event.target);
        form.append("add_site", localStorage.getItem("token"));
        form.append("bus_id", localStorage.getItem("selecteoption"));
        form.append("email_count", 1 );
       
        businessModel.Addnewbusiness(form)
            .then((response) => {
               
                fetchData();
                // toggleModal();  
                dispatch(closeModal())
                helper.sweetalert.toast("Business Added Successfully.")
            })
            .catch((error) => {
              
            });
    };

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("get_bus", localStorage.getItem("token"));
        businessModel.businesssector(formdata).then((res) => {
       
            setSector(res.data.bussiness)
        }).catch((error) => {
 
        })
    }, []);
  
 
    const displaydata = async (id) => {
       
        const formdata = new FormData();
        formdata.append("bus_profile", localStorage.getItem("token"));
        formdata.append("bus_id", id)
        businessModel.displaybus(formdata).then((res) => {
         
            setDisplaybus(res.data.bus)
            setSelectedOption(res.data.bus.salary_basis);
            // fetchData();
            setOutid(id);
            setShowModal1(true);
        }).catch((error) => {
            
        })
    }
    const limit = 10;
    const [formData1, setFormData1] = useState({
        bus_name: '',
        staff_count: '',
        salary_basis: '',
        whatsapp: '',
        bus_sector: '',
        bus_id: '',
        cl_email1:'',
        email_count:'1'
    });
    const handleInputChange1 = (event) => {
        setFormData1({
            ...formData1,
            [event.target.name]: event.target.value,
        });
        let value = event.target.value;
        if (event.target.type === "number") {
            value = value.length > limit ? value.substring(0, limit) : value;
        }
      
        event.target.value = value;
    };
    const handleSubmit1 = (event) => {

        event.preventDefault();
        const form = new FormData(event.target);
        form.append("update_bus", localStorage.getItem("token"));
        form.append("bus_id", outid);
        form.append("email_count", 1 );
        businessModel.editbusiness(form)
            .then((response) => {
          
                displaydata()
                fetchData()
                setShowModal1(true);

            })
            .catch((error) => {
                
            });
    };

    const enablePagination = staff.length > 10;
    const paginationOptions = {
        paginationPerPage: 10, // Number of rows per page
        paginationRowsPerPageOptions: [10, 20, 30], // Options for rows per page dropdown
        pagination: enablePagination, // Enable pagination based on the condition
      };
      const pageData={
        dropdrownElement:sector
      }
    return (
        <>
            <div>
                <Container fluid >
                    <Row>
                        <Col>
                            <div className='mt-0 mb-5'>
                                <button className='btn ' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }}onClick={()=>{
                                    dispatch(openModal('add-designation'))
                                    setCurrentTitle('Add Designation')
                                }}> Add Designation</button>

                            </div>
                            <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className=' mt-2 mb-4'>
                            <DataTable
                                // customStyles={customStyles}
                                columns={columns}
                                data={playerSummary}
                                title={"Designation List"}
                                page={"Designation"}

                                // pagination={enablePagination ? true : false} 
                                paginationComponentOptions={paginationOptions}
                                fixedHeader
                              
                                selectableRowsHighlight
                        
                                noDataComponent="Pleasae wait" //or your component
                            />
                            </div>
                        </Col>
                    </Row>
                    <CommonModal pageData={pageData} onChange={handleInputChange} show={showModal} name={currentPage} title={currentTitle} onHide={() => dispatch(closeModal())} /> 
                    <CommonEditModal pageData={pageData} onChange={handleInputChange} show={showEditModal} name={'edit-designation'} title={'Edit Designation'} onHide={() => dispatch(closeEditModal())} />                                        
                </Container>
            </div>
        </>
    )
}
export default Desigationlist;