import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import {
  Container,
  Row,
  Col,
  Modal,
  DropdownButton,
  Dropdown,
  Button,
  Form,
} from "react-bootstrap";
import Leaves from "../Model/leave.model";
import { TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import DataTable from "../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  setFilterData,
  approvedLeave,
  deniedLeave,
  forwardLeave,
  forwardLeaveEmpget,
  leavelog,
} from "../store/silce/leave";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import {
  closeDeleteModal,
  closeEditModal,
  closeModal,
  openDeleteModal,
  openEditModal,
  openModal,
} from "../store/silce/modalSlice";
import CommonEditModal from "../components/Common/CommonEditModal";
import CommonModal from "../components/Common/CommonModal";
import "../App.css"
const Leavehistory = () => {
  const [leave, setLeave] = useState("");
  // const [Acpetleave, setAcpetleave] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [outid, setOutid] = useState(null);
  const openCheckoutModal = (id) => {
    setOutid(id);

    // handleShow(true);
  };
    const busid = useSelector(state =>state?.selectOption?.value)
  const [showForward, setShowForward] = useState(false);

  const handleCloseForward = () => setShowForward(false);
  const handleShowForward = (id) => {
    setShowForward(true);
    dispatch(forwardLeaveEmpget({ levelId: id }));
    dispatch(leavelog({ levelId: id }));
  };

  const forwardLeaveEmpgetLIst = useSelector((state) => {
    return state?.leave?.forwardLeaveEmpget?.upper_level_emp;
  });
  const logLeavelog = useSelector((state) => {
    return state?.leave?.leavelog?.data?.log;
  });

  

  const [showleaveDetails, setShowleaveDetails] = useState(false);
  const handleCloseleaveDetails = () => setShowleaveDetails(false);
  const handleShowleaveDetails = (id) => {
    setShowleaveDetails(true);
    dispatch(leavelog({ levelId: id }));
  }


  const [leaveData, setLeaveData] = useState(null);
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      // width: "7%",
    },
    {
      name: "Staff ID",
      selector: (row) => row.emp_code,
    },

    {
      name: " Staff Name",
      selector: (row) => row.emp_name,
    },
    // {
    //   name: "Designation",
    //   selector: (row) => row.emp_designation,
    // },

    {
      name: "Applied Date",
      selector: (row) => row.applied_on_date,
      cell: (row) => {
        return <div>{moment(row?.applied_on_date).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      name: "From",
      selector: (row) => row.leave_from,
    },
    {
      name: "To",
      selector: (row) => row.leave_to,
    },
    {
      name: "Leave Type",
      selector: (row) => row.leave_type,
      // width: "15%",
    },
    {
      name: "Reason for leave",
      selector: (row) => row.leave_reason,
      width: "12%",
      cell: (row) => {
        return (
          <div>
            <Tooltip title={row.leave_reason} arrow>
              <p className="mt-2"
                style={{
                  marginTop:'10px ',
                  height: "18px",
                  overflow: "hidden",
                  "text-overflow": "ellipsis",
                }}
              >
                {row.leave_reason}
              </p>
            </Tooltip>
          </div>
        );
      },
    },

    {
      name: "Status",
      width: "10%",
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status === "approved") {
          return (
            <>
              {/* <div
                className="w-100"
                style={{
                  backgroundColor: "rgb(81 151 81)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  height: "3vh",
                }}
              > */}
              <center>
                {" "}
                <p
                  className="text-capitalize mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    fontSize: "12px",
                    color: "rgb(81 151 81)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        } else if (row.status === "denied") {
          return (
            <>
              {/* <div
                className="w-100"
                style={{
                  backgroundColor: "rgb(202 90 90)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  height: "3vh",
                }}
              > */}
              <center>
                {" "}
                <p
                  className="text-capitalize  mt-1 "
                  style={{
                    position: "relative",
                    bottom: "2px",
                    fontSize: "12px  ",
                    color: "rgb(202 90 90)",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        } else if (row.status === "pending") {
          return (
            <>
              {/* <div
                className="w-100"
                style={{
                  backgroundColor: " rgb(255, 165, 0)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  height: "3vh",
                }}
              > */}
              <center>
                {" "}
                <p
                  className="text-capitalize  mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    color: "rgb(255, 165, 0)",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        } else if (row.status === "forwaded") {
          return (
            <>
              {/* <div
                className="w-100"
                style={{
                  backgroundColor: " rgb(255, 165, 0)",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  height: "3vh",
                }}
              > */}
              <center>
                {" "}
                <p
                  className="text-capitalize  mt-1"
                  style={{
                    position: "relative",
                    bottom: "2px",
                    color: "rgb(255, 165, 0)",
                  }}
                >
                  {" "}
                  {row.status}
                </p>
              </center>
              {/* </div> */}
            </>
          );
        }
      },
    },
    {
      name: "Comment",
      width: "10%",
      selector: (row) => row.comment,
      cell: (row) => {
        return (
          <div>
            <Tooltip title={row.comment} arrow>
              <p
                style={{
                  height: "20px",
                  overflow: "hidden",
                  "text-overflow": "ellipsis",
                }}
              >
                {row.comment}
              </p>
            </Tooltip>
          </div>
        );
      },
    },

    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            isLeave={true}
            openEditModal={() => {
              setOutid(row?.leave_id);
              dispatch(openEditModal("denied-leave"));
            }}
            openModal={() => {
              setOutid(row?.leave_id);
              dispatch(openModal("approved-leave"));
            }}
            page="leavelist"
            id={row.id}
            handleShow={() => {
              setOutid(row?.leave_id);
              handleShowForward(row?.leave_id);
            }}
            handleShowleaveDetails={() => {
              setLeaveData(row);
              handleShowleaveDetails(row?.leave_id);
         
            }}
            selectedLevel={selectedLevel}
            onClick={() => {
              setLeaveData(row);
            }}
          />
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const [selectedLevel, setSelectedLevel] = useState("Select Status");
  const [selectedLeveltitle, setSelectedLevelTitle] = useState("Select Status");

  const handleLevelSelect = (level, id) => {
    setSelectedLevel(level);
    setSelectedLevelTitle(id);
    dispatch(fetchData({ status: level == "" ? "pending" : level }));
  };

  useEffect(() => {
    dispatch(
      fetchData({ status: selectedLevel == "" ? "pending" : selectedLevel })
    );
  }, [selectedLevel ,busid]);

  const leaveList = useSelector((state) => {
    return state.leave?.businesses?.data?.leave;
  });
  const leaveMessage = useSelector((state) => {
    return state.leave?.businesses?.data?.message;
  });
  const filterdData = useSelector((state) => {
    return state.leave?.filterdData;
  });

  const loading = useSelector((state) => {
    return state.leave?.loading;
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(approvedLeave({ levelId: outid, event: event }));
    dispatch(closeModal());
    dispatch(
      fetchData({ status: selectedLevel == "" ? "pending" : selectedLevel })
    );
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    dispatch(deniedLeave({ levelId: outid, event: event }));
    dispatch(closeEditModal());
    dispatch(
      fetchData({ status: selectedLevel == "" ? "pending" : selectedLevel })
    );
  };

  const handleSubmitforwardLeave = (event) => {
    event.preventDefault();
    dispatch(forwardLeave({ levelId: outid, event: event }));
    handleCloseForward();
    dispatch(
      fetchData({ status: selectedLevel == "" ? "pending" : selectedLevel })
    );
  };


  const [formData, setFormData] = useState({
    leave_id: "",
    leave_comment: "",
  });

  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const [showComponents, setShowComponents] = useState([]);
  const showModal = useSelector((state) => state.modal.showModal);
  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
              <DropdownButton
                id="dropdown-basic-button"
                className="custom-dropdown"
                title={selectedLeveltitle}
                variant="secondary"
                size="xl"
                style={{ fontSize: "14px" }}
              >
                {/* {levelList &&
                levelList?.map((item, index) => ( */}
                {/* <Dropdown.Item
                  // key={index}
                  onClick={() => handleLevelSelect("")}
                  style={{ fontSize: "14px" }}
                >
                  Select Status
                </Dropdown.Item> */}
                <Dropdown.Item
                  // key={index}
                  onClick={() => handleLevelSelect("pending", "Pending")}
                  style={{ fontSize: "14px" }}
                >
                  Pending
                </Dropdown.Item>
                <Dropdown.Item
                  // key={index}
                  onClick={() => handleLevelSelect("approved", "Approved")}
                  style={{ fontSize: "14px" }}
                >
                  Approved
                </Dropdown.Item>
                <Dropdown.Item
                  // key={index}
                  onClick={() => handleLevelSelect("denied", "Denied")}
                  style={{ fontSize: "14px" }}
                >
                  Denied
                </Dropdown.Item>

                <Dropdown.Item
                  // key={index}
                  onClick={() => handleLevelSelect("forwaded", "Forwaded")}
                  style={{ fontSize: "14px" }}
                >
                  Forwaded
                </Dropdown.Item>
                {/* ))} */}
              </DropdownButton>

              <Button
                className="btn "
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  borderStyle: "none",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  setSelectedLevelTitle("Select Status");
                  setSelectedLevel("Select Status");
                }}
              >
                Clear
              </Button>
              <div className=" mt-3 mb-4">
                <DataTable
                  loading={loading}
                  columns={columns}
                  data={leaveList}
                  title={"Leave History"}
                  setData={setFilterData}
                  keys={["emp_name", "emp_designation", "emp_code"]}
                  filterdData={filterdData}
                />
              </div>
            </Col>
            <CommonEditModal
              size="md"
              // pageData={pageData}
              // onChange={handleInputChange1}
              onSubmit={handleSubmit1}
              show={showEditModal}
              onHide={() => {
                dispatch(closeEditModal());
                // dispatch(clearSelectedData());
              }}
              name={"denied-leave"}
              title={"Denied Leave"}
            />
            <CommonModal
              size="md"
              // pageData={pageData}
              // onChange={handleInputChange}
              onSubmit={handleSubmit}
              show={showModal}
              onHide={() => {
                dispatch(closeModal());
                // dispatch(clearSelectedData());
              }}
              name={"approved-leave"}
              title={"Approved Leave"}
              showComponents={showComponents}
              setShowComponents={setShowComponents}
            />
          </Row>
          <Modal show={showForward} onHide={handleCloseForward}>
            <Modal.Header closeButton>
              <Modal.Title>Forward Leave</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmitforwardLeave}>
                <Form.Group controlId="mobileNumber">
                  <Form.Label>comment *</Form.Label>
                  <Form.Control
                    name="leave_comment"
                    className="form-group required"
                    type="text"
                    placeholder="Comment"
                    required
                    id="form1"
                  />
                </Form.Group>
            
                <div className="mt-2">
                {/* <span className="mt-3">Forward To</span> */}
                < hr />
                  {forwardLeaveEmpgetLIst &&
                    forwardLeaveEmpgetLIst?.map((item, index) => {
                      return (
                        <div
                        style={{
                          marginTop: "",
                          
                          justifyContent: "space-between",
                          alignItems: "center",  // Added to vertically align content
                          flexDirection:"column",
                          width:"100%",
                        }}
                      >
                        
                          <label style={{fontSize:"18px"}}
                            className="form-check-label"
                            htmlFor={`flexRadioDefault2-${item.employee_id}`} // Used htmlFor and added a unique identifier for better accessibility
                          >
                            {item.employee_name}
                          </label>
                       
                       
                          <input
                            className="form-check-input"
                            type="radio"
                            name="forward_to"
                            id={`flexRadioDefault2-${item.employee_id}`} // Used a unique identifier for better accessibility
                            value={item.employee_id}
                            style={{float:'right',fontSize:"20px",right:'10px'}}
                          />
                     
                      </div>
                      
                      );
                    })}
                </div>

                <div className="mt-4">
                  <Button
                    className=""
                    variant="secondary"
                    style={{ float: "left" }}
                  >
                    Close
                  </Button>
                  <Button
                    className="submitButtons"
                    type="submit"
                    variant="primary"
                    style={{ float: "right" }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal show={showleaveDetails} onHide={handleCloseleaveDetails}>
            <Modal.Header closeButton>
              <Modal.Title>Request Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container fluid>
                <Row>
                  <Col>
                    <Form>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                color: "gray",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              Requested on -  {"  "}
                              <span style={{fontSize:"15px" ,fontWeight:'normal' ,color:"black" }}>{leaveData?.applied_on_date}</span>
                            </p>
                          </div>
                          <div>
                            <p
                              style={{
                                color: "rgb(81 151 81)",
                                fontWeight: "bold",
                              }}
                            >
                              {leaveData?.status}
                            </p>
                          </div>
                        </div>
                      
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <img
                            src={leaveData?.profile_imgUrl}
                            style={{ width: "40px", height: "40px",borderRadius:"8px" }}
                            alt="img"
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                marginLeft: "20px",
                              }}
                            >
                              {leaveData?.emp_name}
                            </span>
                            <span
                              style={{
                                marginLeft: "20px",
                              }}
                            >
                              {leaveData?.emp_designation}
                            </span>
                          </div>
                        </div>
                        <div>
                          <p style={{ color: "gray", fontSize: "12px" }}>
                            {leaveData?.emp_code}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          marginTop:'10px'
                        }}
                      >
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Requested For
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <p style={{ color: "red", fontWeight: "bold" }}>
                              {leaveData?.approved_days} Day
                            </p>
                          </div>
                          <div>
                            <p
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              <span style={{ color: "gray", fontSize: "12px" }}>
                                From
                              </span>{" "}
                              {leaveData?.leave_from}{" "}
                              <span style={{ color: "gray", fontSize: "12px" }}>
                                To{" "}
                              </span>
                              {leaveData?.leave_to}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Reason
                        </span>
                        <span style={{ color: "black" }}>
                          {leaveData?.leave_reason}
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop:'10px',
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                         Leave Approval Status
                        </span>
                        {/* <span style={{ color: "black" }}>
                          {leaveData?.comment}
                        </span>  */}
                      
                      </div>
                        {logLeavelog && logLeavelog.map((leavedetails) => 
                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                          flexDirection: "row",
                        }}
                      >
                        <span style={{ color: "gray", fontSize: "14px" }}>
                          Last Update on  - {leavedetails?.updated_on}  Status -   {leavedetails?.log_status} <br />
                          Last Update by - {leavedetails?.updated_by_name}
                        </span>
                      </div>
                      )}
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  );
};
export default Leavehistory;
