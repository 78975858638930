import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import salary from '../../Model/salary';
import DataTable from 'react-data-table-component';
import { MDBInput } from 'mdb-react-ui-kit';

const Tranaction = () => {
    const [salslip, setSaslip] = useState([])
    const params = useParams();
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const handleMonthChange = (event) => {
        const value = event.target.value;
        setSelectedMonth(value.substring(5, 7));
        setSelectedYear(value.substring(0, 4));
        console.log('cvhgjfjhfjh',(value.substring(5, 7)) )
      };
    const [NewformData, setNewFormData] = useState({
  
        filter_type: '',
    });
    const [input, setInput] = useState('');
    const handleInputChange = (event) => {
        setNewFormData({
            ...NewformData,
            [event.target.name]: event.target.value,
        });
    };
    useEffect(() => {

    }, [])
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = new FormData(event.target);
        form.append("emp_id", params?.id);
        form.append("sel_month", selectedMonth);
        form.append("sel_year",  selectedYear);
        form.append("emp_transaction", localStorage.getItem("token"));
        form.append("bus_id", localStorage.getItem("selecteoption"));
        salary.Trancation(form).then((res) => {
            setSaslip(res.data?.tran)
            console.log(res.data?.data?.tran)
            // alert("Allowance Add")
        }
        ).catch((error) => {
            console.log("error => ", error)
        })
    }
    const [remome, setRemove] = useState([])
    const onDELETE = (id) => {
        const formdata = new FormData();

        formdata.append("del_transaction", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("transaction_id", id);
        salary.Removetrans(formdata).then((res) => {
            console.log(res.data)

            setRemove(res.data)

        }).catch((error) => {
            console.log("error => ", error)
        })

    }
    const columns = [
        {
            name: 'S.No',
            cell: (row, index) => index + 1,
            //RDT provides index by default,
            maxWidth: '1px'
        },
        {
            name: 'Amount',
            selector: (row) => row.amount,
            maxWidth: '50px'
        },
        {
            name: 'Category',
            selector: (row) => row.category,
            maxWidth: '10px',
        },
        {
            name: 'Notice',
            selector: (row) => row.notice,
            maxWidth: '200px',
        },
        {
            name: 'Transaction Category',
            selector: (row) => row.transaction_category,
        },
        {
            name: 'Transaction Date',
            selector: (row) => row.transaction_date,
        },
        {
            name: 'Type',
            selector: (row) => row.type,
        },
        {
            name: 'Action',
            cell: (row) =>
                <div>

                    <button style={{ marginLeft: '10px', backgroundColor: 'white' }} onClick={() => (onDELETE(row.id))}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                        <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                        <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg></button>
                </div>
        },

    ]
    return (
        <Card>
            <div className=''>
                <div className='p-3 mt-3' style={{ fontSize: '18px' }}><b>Transaction</b></div>
                <div className=''>
           
                        <Container>

                        <Card className='mb-2'>
                {/* <span style={{ fontSize: '18px' }}><b>Monthly PaySlip</b></span> */}
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className='mt-4 mb-4 d-flex justify-content-end'>
                                    <span className='mt-2 mb-2' style={{margin:'8px'}}>Select Month</span>
                                    <MDBInput
                                    className='mt-2 mb-2'
                                        wrapperClass=""
                                        type="month"
                                        onChange={handleMonthChange} 
                                        style={{width:'200px' , marginLeft:'20px'}}
                                    />
                                    <select name="filter_type" id="filter_type "  className='mt-2 mb-2' style={{marginRight:'10px', marginLeft:'10px',height:"38px" }}>
                                        <option value="all">All Tranaction</option>
                                        <option value="allowance">Allowance</option>
                                        <option value="deduction">Deduction</option>
                                        <option value="overtime">Overtime </option>
                                    </select>
                                    <button type='submit'  className='mt-2 mb-2'  style={{backgroundColor:'#18285a', borderRadius:'5px',marginLeft:'10px', padding:'8px', color:'#fff',marginRight:'10px'}}> Submit</button>

                                </div>
                    </form>
                </div>
            </Card>
                            <Row>
                               
                            </Row>
                        </Container>

                       

                </div>
            </div>
            <div style={{ marginTop: '50px' }}>
                <DataTable
                    columns={columns}
                    data={salslip}
                    noDataComponent="No data Found"
                />
            </div>

        </Card>
    )
}

export default Tranaction;
