import React from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";

const Calender = () => {

    return (
        <>
            <div className="d-flex" id="wrapper">
                <Sidenav style={{ parseFloat }} />
                <div id="page-content-wrapper">
                    <Topnav />
                    <div class="container-fluid  bg-body-tertiary rounded-3 justify-content-between" style={{backgroundolor:"rgba(17, 12, 46, 0.15)"}}>
                        <h3 class="d-inline m-3 mt-3 ">Leave Status</h3>
                        <div class=" gap-2 d-md-flex justify-content-end pb-5 ">
                            <button class="btn  shadow-sm d-inline  text-light " type="button" style={{backgroundColor: "#46C35F"}}>Settings</button>
                            <button class="btn btn-primary shadow-sm d-inline  " type="button" style={{backgroundColor: "#019AFF"}}>Assign Leave</button>
                        </div>

                        <div class="container-fluid bg-body-tertiary pb-4">
                            <button type="button"
                                class="btn btn-outline-light rounded-pill text-body-tertiary bg-white shadow-sm">Department</button>
                            <button type="button" class="btn btn-outline-light rounded-pill text-body-tertiary bg-white shadow-sm">Work
                                Shift</button>
                            <button type="button" class="btn btn-outline-light rounded-pill text-body-tertiary bg-white shadow-sm">Leave
                                Duration</button>
                            <button type="button" class="btn btn-outline-light rounded-pill text-body-tertiary bg-white shadow-sm">users</button>
                            {/* <div class="search-box btn btn-outline-light bg-white rounded-pill mt-2 mb-4 shadow-sm"
                                style={{position:"relative", display:"inline-block", float:"right", backgroundColor: "#f5f2f2" , borderColor:"white"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="gery"
                                    className="bi bi-search p-1 " viewBox="0 0 16 16"
                                    style={{position:'absolute',top:'5px',left:'0px',transform:'translateY(-50)'}}>
                                    <path
                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                                <input type="text" class=" bg-white " style={{paddingLeft:"30px" ,height:"30px", borderRadius:"30px; border-style:solid;  border-color:white;"
                                    placeholder='Search' />
                            </div> */}
                        </div>
                    </div>
                    <div class="container-fluid mt-4 " style={{"box-shadow":"rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",width: "95%"}}>
                        <div class=" container-fluid justify-content-between pt-5" >
                            <div class="calender-dropdown justify-content-between" style={{display:"flex"}}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                                    class="bi bi-chevron-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg>
                                    <p herf="#" class="mb-2 mx-5"  ><h4 style={{color: "#019AFF"}}  ><b>june 2023</b></h4></p>
                                    <input type="month" name="month-picker " id=" calender-dropdown" style="" />
                                </span>

                                <ul class="nav justify-content-end align-items-center mb-4" style={{width:"70%",float: "right"}}>
                                    <li class="">
                                        <a class="nav-link text-dark" aria-current="page" href="#">Today</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-dark" href="#">This week</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-dark" href="#">Last week</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-primary ">This month</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-dark" href="#">Last month</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-dark ">This year</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <hr />
                            <br class="dropdown-divider" />
                                <div class=" container-fluid text-center">
                                    <div class="row">
                                        <div class="col-3 bg-body-tertiary mx-4 shadow-sm" style={{width: "20%"}}>
                                            <h4>5</h4>
                                            <h6>Employees on leave</h6>
                                        </div>
                                        <div class="col-3 bg-body-tertiary mx-4 shadow-sm" style={{width: "20%"}}>
                                            <h4>43</h4>
                                            <h6>Total leave hours</h6>
                                        </div>
                                        <div class="col-3 bg-body-tertiary mx-4 shadow-sm" style={{width: "20%"}}>
                                            <h4>1</h4>
                                            <h6>On leave( Single day )</h6>
                                        </div>
                                        <div class="col-3 bg-body-tertiary mx-4 shadow-sm" style={{width: "20%"}}>
                                            <h4>1</h4>
                                            <h6>On leave (Multi day)</h6>
                                        </div>
                                    </div>
                                </div>
                                <br class="dropdown-divider" />
                                    <div class="container-fluid mt-5">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Profile</th>
                                                    <th scope="col">Date & time</th>
                                                    <th scope="col">Leave duration</th>
                                                    <th scope="col">Leave Type</th>
                                                    <th scope="col">Attachments</th>
                                                    <th scope="col">Activity</th>
                                                    <th scope="col">Actions</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" style={{color: "#019AFF"}}> <img src="download.png" alt="" style={{width: "50px", height: "50px"}} /> Rickie Schinner <p style={{color: "#AFB1B6"}} class=" pt-0 mx-lg-5">Accounts</p></th>

                                                    <td>16 Jun, 23</td>
                                                    <td>1 Day</td>
                                                    <td>Paid Sick</td>
                                                    <td><h2>-</h2></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                                        <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-activity" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                                        </svg></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{color: "#019AFF"}}> <img src="download.png" alt="" style= {{width: "50px" ,height: "50px"}}/> Rickie Schinner <p style={{color: "#AFB1B6"}} class=" pt-0 mx-lg-5">Accounts</p></th>

                                                    <td>17 Jun, 23</td>
                                                    <td>First half</td> 
                                                    <td>Unpaid Casual</td>
                                                    <td><h2>-</h2></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                                        <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-activity" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                                        </svg></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{color: "#019AFF"}}> <img src="download.png" alt="" style={{ width: "50px", height: "50px"}} /> Rickie Schinner <p style={{color: "#AFB1B6"}} class=" pt-0 mx-lg-5">UI & UX</p></th>

                                                    <td>from: 17 Jun, 23
                                                        to: 19 Jun, 23</td>
                                                    <td>3 Days
                                                        Includes 1 off day</td>
                                                    <td>Unpaid Sick</td>
                                                    <td><h2>-</h2></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                                        <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-activity" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                                        </svg></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style="color: #019AFF;"> <img src="download.png" alt="" style=" width: 50px; height: 50px;" /> Rickie Schinner <p style="color: #AFB1B6;" class=" pt-0 mx-lg-5">Development</p></th>

                                                    <td>from: 5:35 PM
                                                        to: 1:35 AM, 20 Jun, 23</td>
                                                    <td>08:00 Hours</td>
                                                    <td>Unpaid Casual</td>
                                                    <td><h2>-</h2></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                                        <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-activity" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                                        </svg></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style="color: #019AFF;"> <img src="download.png" alt="" style=" width: 50px; height: 50px;" /> Rickie Schinner <p style="color: #AFB1B6;" class=" pt-0 mx-lg-5">Software</p></th>

                                                    <td>
                                                        21 Jun, 23</td>
                                                    <td>Last half</td>
                                                    <td>Paid Sick</td>
                                                    <td><h2>-</h2></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                                        <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-activity" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                                        </svg></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style="color: #019AFF;"> <img src="download.png" alt="" style=" width: 50px; height: 50px;" /> Rickie Schinner <p style="color: #AFB1B6;" class=" pt-0 mx-lg-5">Software</p></th>

                                                    <td>from: 5:35 PM
                                                        to: 12:35 AM, 24 Jun, 23</td>
                                                    <td>07:00 Hours</td>
                                                    <td>Unpaid Sick</td>
                                                    <td><h2>-</h2></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                                        <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                    </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF" class="bi bi-activity" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                                        </svg></td>
                                                    <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                    </svg></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                    </div>
                </>
                )
}
                export default Calender;