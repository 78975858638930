import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const AssignModal = ({ open, close, onChange, onSubmit }) => {
    const selectedLevel = useSelector((state) => state.level.selectedLevel);
    const levelList = useSelector((state) => state.level.levels);
    

    const handleclose = () => {
        close();
    };

    useEffect(() => { }, [selectedLevel])

    return (
        <div>

            <Modal show={open} onHide={handleclose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Assign Level to Employee</Modal.Title>
                </Modal.Header>
                <form onSubmit={onSubmit}>
                    <Modal.Body>
                        <Form onSubmit={onSubmit}>
                            <select
                                style={{ width: "100%", margin: "0rem 0rem 1rem", padding: "0.5rem" }}
                                onChange={(event) => onChange(event, event.target.value)}
                            >
                                <option value="" disabled selected>Select Emp</option>
                                {levelList?.map((ele) => {
                                    return ele?.assigned_emp?.map((list) => (
                                        <option key={list?.id} value={list?.id}>
                                            {list?.name}
                                        </option>
                                    ));
                                })}
                            </select>
                        </Form>
                        <Modal.Footer>
                            <Button className="w-25" variant="secondary" onClick={handleclose}>
                                Close
                            </Button>
                            <Button className="submitButtons w-25" type="submit" variant="primary">
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </form>
            </Modal>
        </div>
    );
};

export default AssignModal;
