import React from "react";
import Login from "./Layouts/login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mainpage from "./InternalPage/Dashboard";
import Stafflist from "./staff/stafflist";
import Attendance from "./attendance/attendance"
import Adminprofile from "./Layouts/adminprofile"
import AllHolidays from "./InternalPage/holiday";
import StaffRole from "./InternalPage/SatffRole";
import Staffdetails from './staff/Staffdeatils';
import Employeeview from "./staff/emplyeeview";
import Dayattendance from "./attendance/Dayattendance";
import Monthattendance from "./attendance/monthAtt";
import Allattendance from "./attendance/listatt";
import Perviousatt from './attendance/Perviousatt';
import Typeupdate from "./attendance/attupdate";
import Noticehistory from "./Notice/noticehistory";
import Boradcast from "./Notice/Boardcast";
import Leavehistory from "./Leave/Leavehistory";
import BusinessLIst from "./Business/businesslist";
import Staffview from "./staff/satffview";
import JobProfiel from "./staff/JobProfile";
import StaffStatus from "./staff/staffStatus";
import Dailylog from "./attendance/Dailylog";
import AttendanceRequest from "./attendance/AttendanceRequest";
import AppSetting from "./Setting/Appsetting";
import Summary from "./attendance/Summary";
import Attendacedetails from "./attendance/Attendancedetails";
import Calender from "./newLeave/Calender";
import Request from "./newLeave/Request";
import Status from './newLeave/Status';
import AdminLayout from "./Layouts/AdminLayout";
import AuthLayout from "./Layouts/BlankLayout";
import Payroll from "./Payroll/Payroll";
import Roleemplist from "./Role/Rollemplist";
import AssignEmproll from "./Role/Componenet/AssignEmproll";
import ViewAllPermissions from "./Role/Componenet/ViewAllPermissions";
import EditRollLIst from "./Role/Componenet/AssignRollList";
import SelfiAttendance from "./attendance/SelfiAttendance";
import Reimbursement from "./Payroll/Reimbursement";
import SalarySetting from "./Setting/SalarySetting";
import Autopunch from "./Setting/Autopunch";
import EmpLayout from "./Layouts/EmpLayout";
import Emphome from "./Empdata/Myprofile/Home";
import EmpHoliday from "./Empdata/Holidays/EmpHoliday";
import EmpReimbursement from "./Empdata/EmpReimbursement";
import EmpAttendance from "./Empdata/Attendance/EmpAttendance";
import Empnotice from "./Empdata/Notice/Empnotice";
import Empleavehistory from "./Empdata/Leave/Empleavehistory";
import EmpSalary from "./Empdata/Salary/Salary";
import Addstaff from "./staff/AddStaff";
import Departmentist from "./Business/Component/Department";
import SubDepartmentist from "./Business/Component/Subdepartment";
import Desigationlist from "./Business/Component/Designation";
import Levellist from "./Business/Component/Level";
import Librarydoc from "./Library/Docement";
import LevelList from "./pages/Level/Level";
import RoleLevelManege from "./Role/RoleLevelManege";
import AssignEmpRoleView from "./Role/Componenet/AssignEmpRoleView";
import MyProfile from "./Empdata/Myprofile/MyProfile";
import EmployeeBank from "./Empdata/EmployeeBank";
import Notification from "./InternalPage/Notification";
import Usermap from "./InternalPage/Usermap";
import Bank from "./staff/staffview/Bank";
import Social from "./staff/staffview/Social";
import StaffinfoDow from "./staff/staffview/StaffinfoDow";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<AuthLayout><Login /></AuthLayout>} />
          <Route path="/stafflist" element={<AdminLayout><Stafflist /> </AdminLayout>} />
          <Route path="/home" element={<AdminLayout><Mainpage/></AdminLayout>} />
          <Route path="/profile" element={<AdminLayout><Adminprofile /></AdminLayout>}/>
          <Route path="/Notification" element={<AdminLayout><Notification /></AdminLayout>}/>
          <Route path="/Staffmap" element={<AdminLayout><Usermap /></AdminLayout>}/>
          <Route path="/attendance" element={<AdminLayout><Attendance /></AdminLayout>} />
          <Route path="/Holidays" element={<AdminLayout><AllHolidays/></AdminLayout>}/>
          <Route path="/Satffrole" element={<AdminLayout><StaffRole /></AdminLayout>}/>
          <Route path='/staffdetails/:id' element={<AdminLayout><Staffdetails/></AdminLayout>}/>
          <Route path='/Update/:id' element={<AdminLayout><Employeeview/></AdminLayout>}/>
          <Route path="/attendancehistory/:id" element={<AdminLayout><Dayattendance/></AdminLayout>}/>
          <Route path="/MonthAtt/:id" element={<AdminLayout><Monthattendance/></AdminLayout>}/>
          <Route path="/Att_details" element={<AdminLayout><Allattendance/></AdminLayout>}/>
          <Route path="/PerviousAtt" element={<AdminLayout><Perviousatt/></AdminLayout>}/>
          <Route path='/Updatetype'element={<AdminLayout><Typeupdate/></AdminLayout>}/>
          <Route path="/Adminprofile" element={<AdminLayout><Adminprofile/></AdminLayout>}/>
          <Route path="/Noticehistory" element={<AdminLayout><Noticehistory/></AdminLayout>}/>
          <Route path="/Boradcast" element={<AdminLayout><Boradcast/></AdminLayout>}/>
          <Route path="/LeaveHistory" element={<AdminLayout><Leavehistory/></AdminLayout>}/>
          <Route path="/Businesslist" element={<AdminLayout><BusinessLIst/></AdminLayout>}/>
          <Route path="/Department" element={<AdminLayout><Departmentist/></AdminLayout>}/>
          <Route path="/Sub-Department" element={<AdminLayout><SubDepartmentist/></AdminLayout>}/>
          <Route path="/Desigation" element={<AdminLayout><Desigationlist/></AdminLayout>}/>
          <Route path="/Level" element={<AdminLayout><Levellist/></AdminLayout>}/>
          <Route path="/Staffview/:id" element={<AdminLayout><Staffview/></AdminLayout>}/>
          <Route path="/AddStaff" element={<AdminLayout><Addstaff/></AdminLayout>}/>
          <Route path="/StaffinfoDow/:id" element={<AdminLayout><StaffinfoDow/></AdminLayout>}/>
          <Route path="/Jopprofile" element={<AdminLayout><JobProfiel/></AdminLayout>}/>
          <Route path="/StaffStaus" element={<AdminLayout><StaffStatus/></AdminLayout>}/>
          <Route path="/Dailylog" element={<AdminLayout><Dailylog/></AdminLayout>}/>
          <Route path="/Attendance-request" element={<AdminLayout><AttendanceRequest/></AdminLayout>}/>
          <Route path="/Appseting" element={<AdminLayout><AppSetting/></AdminLayout>}/>
          <Route path="/Summary" element={<AdminLayout><Summary/></AdminLayout>}/>
          <Route path="/Attendacedetails" element={<AdminLayout><Attendacedetails/></AdminLayout>}/>
          <Route path="/Calender" element={<AdminLayout><Calender/></AdminLayout>}/>
          <Route path="/Request" element={<AdminLayout><Request/></AdminLayout>}/>
          <Route path="/Leave-Status" element={<AdminLayout><Status/></AdminLayout>}/>
          <Route path="/Salary" element={<AdminLayout><Payroll/></AdminLayout>}/>
          {/* <Route path="/Role" element={<AdminLayout><Roleemplist/></AdminLayout>}/> */}
          <Route path="/manegeRoleAndLevel" element={<AdminLayout><RoleLevelManege/></AdminLayout>}/>
          <Route path="/Salary" element={<AdminLayout><Payroll /></AdminLayout>}/>
          <Route  path="/Role" element={<AdminLayout><Roleemplist /></AdminLayout>} />
          <Route path="/AssignEmproll" element={<AdminLayout><AssignEmproll /></AdminLayout>}/>
           <Route path="/AssignRole/:id"  element={ <AdminLayout><EditRollLIst /></AdminLayout> }  />
            <Route  path="/AssignEmpRoleView/:id" element={  <AdminLayout> <AssignEmpRoleView /> </AdminLayout>}/>
          <Route  path="/ViewAllPermissions"  element={ <AdminLayout><ViewAllPermissions /> </AdminLayout>}/>
          <Route path="/level-management" element={<AdminLayout><LevelList/></AdminLayout>}/>
          <Route path="/Librarydoc/:id" element={<AdminLayout><Librarydoc/></AdminLayout>}/>
          <Route path="/Editroll" element={<AdminLayout><EditRollLIst/></AdminLayout>}/>
          <Route path="/SelfiAttendance" element={<AdminLayout><SelfiAttendance/></AdminLayout>}/>
          <Route path="/Reimbursement" element={<AdminLayout><Reimbursement/></AdminLayout>}/>
          <Route path="/Salary-Setting" element={<AdminLayout><SalarySetting/></AdminLayout>}/>
          <Route path="/Auto-Punch-Out" element={<AdminLayout><Autopunch/></AdminLayout>}/>
          <Route path="/admin/dashboard" element={<EmpLayout><Emphome/></EmpLayout>}/>
          <Route path="/Emp/Hoilday" element={<EmpLayout><EmpHoliday/></EmpLayout>}/>
          <Route path="/Emp/Reimbursement" element={<EmpLayout><EmpReimbursement/></EmpLayout>}/>
          <Route path="/Emp/Attandance" element={<EmpLayout><EmpAttendance/></EmpLayout>}/>
          <Route path="/Emp/Notice" element={<EmpLayout><Empnotice/></EmpLayout>}/>
          <Route path="/Emp/Leave" element={<EmpLayout><Empleavehistory/></EmpLayout>}/>
          <Route path="/Emp/Salary" element={<EmpLayout><EmpSalary/></EmpLayout>}/>
          <Route path="/Updatetypes" element={<EmpLayout><Typeupdate/></EmpLayout>}/>
          <Route path="/My-Profile" element={<EmpLayout><MyProfile/></EmpLayout>}/>
          <Route path="/Add-Bank" element={<EmpLayout><EmployeeBank/></EmpLayout>}/>
          <Route path="/LeaveHistorys" element={<EmpLayout><Leavehistory/></EmpLayout>}/>
          <Route path="/Noticehistorys" element={<EmpLayout><Noticehistory/></EmpLayout>}/>
          <Route path="/stafflists" element={<EmpLayout><Stafflist /></EmpLayout>} />
          <Route path='/Updates/:id' element={<EmpLayout><Employeeview/></EmpLayout>}/>
          <Route path="/SelfiAttendances" element={<EmpLayout><SelfiAttendance/></EmpLayout>}/>
          <Route path="/Staffviews/:id" element={<EmpLayout><Staffview/></EmpLayout>}/>
          <Route path="/Salary-Settings" element={<EmpLayout><SalarySetting/></EmpLayout>}/>
          <Route path="/Businesslists" element={<EmpLayout><BusinessLIst/></EmpLayout>}/>
          <Route path="/Boradcasts" element={<EmpLayout><Boradcast/></EmpLayout>}/>
          <Route path="/bank" element={<EmpLayout><Bank/></EmpLayout>}/>
          <Route path="/socal" element={<EmpLayout><Social/></EmpLayout>}/>
          <Route path="/Emp/Librarydoc/:id" element={<EmpLayout><Librarydoc/></EmpLayout>}/>
          <Route path="/AddStaffs" element={<AdminLayout><Addstaff/></AdminLayout>}/>
        </Routes>
      </Router>
    </>
  );
}
export default App;