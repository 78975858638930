import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AttModal from "../../Model/Att.modal";

const Overtime = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();
  const [formData, setFormData] = useState({
    mark_date: "",
    hourly_wage: "",
    overtime: "",
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("emp_id", params?.id);
    form.append("mark_attendance", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    form.append("mark_type", "overtime");
    AttModal.Attupdate(form)
      .then((response) => {
        handleClose()
        // console.log(response.data, "yes data update");
        // alert("New Role Add");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <div className="mt-4 mb-4">
        <span style={{ fontSize: "18px" }}>
          <b>OverTime List</b>
        </span>
        <Button
          onClick={handleShow}
          style={{ float: "right" }}
          className="btn submitButtons w-25"
          type="submit"
          variant="primary"
        >
          Add{" "}
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "20px" }}>Add Advance Payment</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-1" style={{}}>
            <form onSubmit={handleSubmit}>
              <Container className="mt-4">
                <Row>
                  <Col sm={4} style={{ fontSize: "16px" }}>
                    {" "}
                    <span>Add Date</span>
                  </Col>
                  <Col sm={8}>
                    {" "}
                    <input
                      type="date"
                      name="mark_date"
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    <span style={{ fontSize: "16px" }}>Over-Time Pay(₹)</span>
                  </Col>
                  <Col sm={8}>
                    <input
                      type="number"
                      name="hourly_wage"
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    <span style={{ fontSize: "16px" }}>Over-Time </span>
                  </Col>
                  <Col sm={8}>
                    {/* <Row>
                    <input
                      type="text"
                      // name="overtime"
                      id=""
                      style={{ height: "35px",}}
                    /> */}

                    <input
                      type="text"
                      name="overtime"
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                      {/* </Row> */}
                  
                  </Col>
                </Row>
              </Container>
              <button
                className="btn  mt-4 mb-4"
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                }}
              >
                Add Over Time
              </button>
            </form>
          </div>
          <button className="btn btn-secondary mt-4 mb-2"> Close</button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Overtime;
