import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";

export const fetchData = createAsyncThunk(
  "payroll/fetchAllAutoPunchout",
  async (_, { dispatch }) => {
    try {
      const form = new FormData();
      form.append("emp_list", localStorage.getItem("token"));
      form.append("bus_id", localStorage.getItem("selecteoption"));
      const response = await axiosInstance.post("/employee.php", form);
      console.log("Response:", response);
      dispatch(setFilterData(response.data?.emp));
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

// export const handleSubmit = createAsyncThunk(
//   "payroll/handleSubmit",
//   async ({ formattedDate }) => {
//     const formdata = new FormData();
//     formdata.append("get_month_salary", localStorage.getItem("token"));
//     formdata.append("bus_id", localStorage.getItem("selecteoption"));
//     formdata.append("data_dt", formattedDate);
//     try {
//       const response = await axiosInstance.post(`/salary.php`, formdata);
//       return response;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );
// export const onMarkPaid = createAsyncThunk(
//   "payroll/onMarkPaid",
//   async ({ sal_id }) => {
//     const formdata = new FormData();
//     formdata.append("removeHoliday", localStorage.getItem("token"));
//     formdata.append("bus_id", localStorage.getItem("selecteoption"));
//     formdata.append("sal_id", sal_id);
//     try {
//       const response = await axiosInstance.post(`/salary.php`, formdata);
//       return response;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );

const autopunchoutSlice = createSlice({
  name: "autopunchout",
  initialState: {
    autopunchout: [],
    errorMessage: "",
    loading: true,
    filterdData: [],
  },
  reducers: {
    // You can add other non-async actions here if needed.
    setFilterData: (state, action) => {
      state.filterdData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        console.log("action", action);
        state.businesses = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      });
    //   .addCase(handleSubmit.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(handleSubmit.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.updatedData = action.payload;
    //   })
    //   .addCase(handleSubmit.rejected, (state, action) => {
    //     state.loading = false;
    //     state.errorMessage = action.payload.message;
    //   })
    //   .addCase(onMarkPaid.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(onMarkPaid.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.updatedData = action.payload;
    //   })
    //   .addCase(onMarkPaid.rejected, (state, action) => {
    //     state.loading = false;
    //     state.errorMessage = action.payload.message;
    //   });
  },
});

// Export actions
export const { setFilterData } = autopunchoutSlice.actions;

// Export the reducer
export default autopunchoutSlice.reducer;
