import React, { useState, useEffect, useRef } from "react";
import "../Aseset/css/sidenav.css";
import auth from "../Model/profile.model";
import Delete from "../Model/employee.Model";
import DataTable from "react-data-table-component";
import { Spinner, InputGroup, Button } from "react-bootstrap";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import WeekOffDay from "./Components/WeekOffDay";
import nouser from "../Aseset/image/user.png";
import { useDispatch, useSelector } from "react-redux";
import { addnewemployee } from "../store/silce/staffSlice";
// import { Form } from 'react-bootstrap';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBInput,
  MDBCheckbox,
  MDBCardHeader,
} from "mdb-react-ui-kit";
import moment from "moment";
import { fetchAlldepartments } from "../store/silce/bussiness";
import Department from "./Components/Department";
import SubDepartment from "./Components/SubDepartment";
const Addstaff = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const params = useParams();
  console.log(params, "params");
  const [search, setSearch] = useState("");
  const [staff, setStaff] = useState([]);
  const [filterstaff, setFilterstaff] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DepartmentData = useSelector((state) => {
    return state.Business.Alldepartments;
  });
  const loading = useSelector((state) => state.staff.loading);
  const [showDiv, setShowDiv] = useState(false);
  const getEmployees = () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("selecteoption")
    ) {
      const formdata = new FormData();
      formdata.append("emp_list", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      auth
        .employee(formdata)
        .then((res) => {
          //console.log(res.data.emp)
          setStaff(res?.data?.emp);
          setFilterstaff(res?.data?.emp);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    }
  };
  const [validated, setValidated] = useState(false);
  const busid = useSelector((state) => state?.selectOption?.value);
  useEffect(() => {
    dispatch(fetchAlldepartments());
  }, [busid]);
  useEffect(() => {
    setIsLoading(true);
    getEmployees();
  }, [busid]);
  useEffect(() => {
    const result = staff.filter((staffname) => {
      return staffname.name.toLowerCase().match(search.toLowerCase());
    });
    setFilterstaff(result);
  }, [search, busid]);
  const [selectedOption, setSelectedOption] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [formData, setFormData] = useState({
    emp_name: "",
    emp_cont: "",
    emp_desg: "",
    emp_email: "",
    mname: "",
    mothers_name: "",
    fathers_name: "",
    husband_name: "",
    // emp_att_type: "",
    start_time: "",
    start_end: "",
    paid_leave: "",
    att_basis: "",
    salary: "",
    profile_img: "",
    epf: "",
    eepf: "",
    emp_week_off: [
      {
        day: "",
        week_count: "",
      },
    ],
    identification_mark:"",
    nearest_police_station:""
  });

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const displayValue = selectedSubDepartment || selectedDepartment;
  const [Newvalue, setNewvalue] = useState();
  const [empCode, setEmpCode] = useState("");
  const handleInputChange = (event) => {
    if (event.target.name === "emp_code") {
      // Update empCode state
      setEmpCode(event.target.value);
    } else {
      // Update formData state
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
    if (event.target.type === "file") {
      const imageFile = event.target.files[0];
      if (imageFile) {
        setSelectedImage(URL.createObjectURL(imageFile));
      }
    }
    if (event.target.type === "tel") {
      setNum(event.target.value.slice(0, limit));
    }
    let value = event.target.value;
    if (event.target.type === "number") {
      value = value.length > limit ? value.substring(0, limit) : value;
    }
    event.target.value = value;
    var checkbox1 = document.getElementById("Selfie");
    var checkbox2 = document.getElementById("Scan");

    if (checkbox1.checked && checkbox2.checked) {
      var combinedValue = checkbox1.value + "," + checkbox2.value;
      console.log(combinedValue, "combinedValue");
      setNewvalue(combinedValue);
      // You can replace this with your desired action (e.g., sending the value to a server).
    } else if (checkbox1.checked) {
      // Only checkbox1 is checked, send its individual value

      setNewvalue(checkbox1.value);
      // You can then send checkbox1.value to the server or perform any other action
    } else if (checkbox2.checked) {
      // Only checkbox2 is checked, send its individual value
      setNewvalue(checkbox2.value);
      // You can then send checkbox2.value to the server or perform any other action
    }
  };

  const getCheckedCheckboxes = (inputName) => {
    const checkboxes = document.querySelectorAll(
      'input[name="' + inputName + 'WeekCount"]:checked'
    );
    const checkedValues = [];

    checkboxes.forEach((checkbox) => {
      checkedValues.push(checkbox.value);
    });

    return checkedValues.join(",");
  };
  const handleimageClick = () => {
    inputRef.current.click();
  };
  const [selectedDatee, setSelectedDatee] = useState("");
  const [yearsPassed, setYearsPassed] = useState(null);

  const handleDateChangee = (event) => {
    const selectedDate = event.target.value;
    setSelectedDatee(selectedDate);
    calculateAndShowResult(selectedDate);
  };

  const calculateAndShowResult = (date) => {
    const selectedDateObj = new Date(date);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceMs = currentDate.getTime() - selectedDateObj.getTime();

    // Convert milliseconds to years
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365);

    console.log("Difference in years:", differenceYears);

    // Round down to get the whole number of years
    const yearsPassed = Math.floor(differenceYears);

    // Set the state to display the number of years passed
    setYearsPassed(yearsPassed);
  };

  const fromDate = moment(selectedDatee).format("DD-MM-YYYY");
  const Admindetails = useSelector(
    (state) => state?.dashboard?.home?.data?.roleName
  );
  console.log(Admindetails, "Admindetails");
  const datahai = () => {
    if (Admindetails === "client") {
      navigate("/stafflist");
    } else {
      navigate("/stafflists");
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    dispatch(
      addnewemployee({
        event: event,
        selectedWeeks: selectedWeeks,
        selectedDatee: fromDate,
        getCheckedCheckboxes: getCheckedCheckboxes,
        setOpen: setOpen,
        departmentId: displayValue,
        datahai: datahai(),
        Newvalue: Newvalue,
        empCode: empCode,
      })
    );
  };
  console.log(selectedWeeks, "selectedWeeks");
  const [showComponent, setShowComponent] = useState(false);
  const weekArray = [
    { value: "sunday", name: "Sunday", checked: false, selectedWeek: "" },
    { value: "monday", name: "Monday", checked: false, selectedWeek: "" },
    { value: "tuesday", name: "Tuesday", checked: false, selectedWeek: "" },
    { value: "wednesday", name: "Wednesday", checked: false, selectedWeek: "" },
    { value: "thursday", name: "Thursday", checked: false, selectedWeek: "" },
    { value: "friday", name: "Friday", checked: false, selectedWeek: "" },
    { value: "saturday", name: "Saturday", checked: false, selectedWeek: "" },
  ];
  const [num, setNum] = useState("");
  // const handleChange = (e) => {
  //     setNum(e.target.value)
  // }
  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Use a regular expression to allow only up to 10 digits
    const sanitizedValue = inputValue.replace(/\D/g, "").slice(0, 10);
    setNum(sanitizedValue);
  };
  const limit = 6;
  const [isDivVisible, setDivVisibility] = useState(false);
  const [radioValue, setRadioValue] = useState("");

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setRadioValue(value);

    // Show the div only if "Married" is selected
    setDivVisibility(value === "married");
  };
  const handleDepartmentSelect = (value) => {
    setSelectedDepartment(value);
    setSelectedSubDepartment();
    setSelectedOption(value);
  };

  const handleSubDepartmentSelect = (value) => {
    setSelectedSubDepartment(value);
  };

  return (
    <>
      <MDBContainer fluid className="h-custom">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            {loading ? (
              <center>
                <Spinner animation="grow" />
                <p>Please wait for submitting data...</p>
              </center>
            ) : (
              <form onSubmit={handleSubmit}>
                <MDBCard
                  className="card-registration card-registration-2 mb-3 "
                  style={{
                    borderRadius: "15px",
                    // border: "2px solid #18285A",
                  }}
                >
                  <MDBCardHeader
                    style={{
                      // backgroundColor: "#18285A",
                      // color: "white",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                    }}
                  >
                    Add New Staff
                  </MDBCardHeader>
                  <MDBCardBody className="p-0 m-4">
                    <MDBRow style={{ marginLeft: "1px" }}>
                      <center>
                        <div
                          onClick={handleimageClick}
                          style={{ width: "25%" }}
                        >
                          {selectedImage ? (
                            <img
                              src={selectedImage}
                              alt=""
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                marginBottom: "20px",
                                marginTop: "20px",
                              }}
                            />
                          ) : (
                            <img
                              src={nouser}
                              // src={`http://qrstaff.in/api/pro_pic/${empprivew?.profile_img}`}
                              alt=""
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "50%",
                                marginBottom: "20px",
                                // marginTop: "20px",
                              }}
                            />
                          )}
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleInputChange}
                            value={formData.profile_img}
                            name="profile_img"
                            style={{ display: "none" }}
                            ref={inputRef}
                          />
                        </div>
                        <p>Change Image</p>
                      </center>
                    </MDBRow>
                    <MDBRow>
                      <label
                        style={{
                          fontSize: "20px",
                          marginBottom: "20px",
                          backgroundColor: "lightgrey",
                          fontWeight: 500,
                          borderRadius: "6px",
                          paddingLeft: "20px",
                        }}
                      >
                        Basic Information
                      </label>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="3">
                        <span> Title</span>

                        <Form.Select
                          style={{
                            width: "100%",
                            height: "37px",
                            // backgroundColor: "pink",
                          }}
                          className="mt-2"
                          name="prefix"
                        >
                          <option value="" selected>
                            Select Title
                          </option>
                          <option value="Miss">Miss</option>
                          <option value="Mr">Mr.</option>
                          <option value="Mrs">Mrs.</option>
                        </Form.Select>
                      </MDBCol>

                      <MDBCol md="3">
                        <span>Frist Name</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="form1"
                          type="text"
                          placeholder="Frist Name"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={formData.emp_name}
                          name="emp_name"
                          required
                          onChange={handleInputChange}
                        />
                      </MDBCol>

                      <MDBCol md="3">
                        <span>Midlle Name</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="form1"
                          type="text"
                          placeholder="Midlle Name"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={formData.mname}
                          name="mname"
                          onChange={handleInputChange}
                        />
                      </MDBCol>

                      <MDBCol md="3">
                        <span>Last Name</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="form1"
                          type="text"
                          placeholder="Last Name"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={formData.lname}
                          name="lname"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md="2">
                        <label className="mb-2">Date Of Birth</label>
                        <MDBInput
                          wrapperClass="mb-4"
                          type="date"
                          value={selectedDatee}
                          
                          placeholder="dob"
                          onChange={handleDateChangee}
                         
                        />
                      </MDBCol>
                      <MDBCol md="1">
                        {yearsPassed && (
                          <p style={{ marginTop: "35px" }}>
                            {yearsPassed} Y/O{" "}
                          </p>
                        )}
                      </MDBCol>
                      <MDBCol md="3">
                        <span>Mother Name</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="form1"
                          type="text"
                          placeholder="Mother Name"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={formData.mothers_name}
                          name="mothers_name"
                          onChange={handleInputChange}
                        />
                      </MDBCol>

                      <MDBCol md="3">
                        <span>Father/Husband Name</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="form1"
                          type="text"
                          placeholder="Father Name"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={formData.fathers_name}
                          name="fathers_name"
                          onChange={handleInputChange}
                        />
                      </MDBCol>

                      <MDBCol md="3">
                        <label className="mb-2"> Blood Group</label>
                        <Form.Select
                          name="blood_group"
                          id=""
                          style={{ width: "100%" }}
                          // className="mt-2"
                        >
                          <option value="" selected>
                            Select Blood Group
                          </option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="B-">B-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                        </Form.Select>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      {/* <MDBCol md="4">
                          <div
                            className="mt-2 ms-5"
                            style={{ display: "flex" }}
                          >
                            <label>
                              <input
                                type="radio"
                                name="marital_status"
                                value="single"
                                checked={radioValue === "single"}
                                onChange={handleRadioChange}
                              />
                              Unmarried
                            </label>
                            <label style={{}}>
                              <input
                                type="radio"
                                name="marital_status"
                                value="married"
                                checked={radioValue === "married"}
                                onChange={handleRadioChange}
                              />
                              Married
                            </label>

                            {isDivVisible && (
                              <div>
                                <MDBInput
                                  type="date"
                                  name="marriage_dt"
                                  id=""
                                ></MDBInput>
                              </div>
                            )}
                          </div>
                        </MDBCol> */}
                      {/* saurabh */}
                      <MDBCol md="3">
                        <label>Gender</label>
                        <Form.Select
                          name="gender"
                          // id="form1"
                          style={{ width: "100%", height: "38px" }}
                          className="mt-2 mb-2"
                          // required
                        >
                          <option value="" selected>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </MDBCol>
                      <MDBCol md="3">
                        <span> Staff ID</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id=""
                          type="text"
                          placeholder="Staff ID"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={empCode}
                          name="emp_code"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                      
                     

                      <MDBCol md="3">
                        <div className="mt-2 ">
                          <p style={{ marginBottom: "-2px" }}>
                            {" "}
                            Select Marital Status
                          </p>
                          <label>
                            <input
                              type="radio"
                              name="marital_status"
                              value="single"
                              checked={radioValue === "single"}
                              onChange={handleRadioChange}
                            />
                            Unmarried
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="marital_status"
                              value="married"
                              checked={radioValue === "married"}
                              onChange={handleRadioChange}
                            />
                            Married
                          </label>

                          {isDivVisible && (
                            <div>
                              <MDBInput
                                type="date"
                                name="marriage_dt"
                                id=""
                              ></MDBInput>
                            </div>
                          )}
                        </div>
                      </MDBCol>
                      {isDivVisible && (
                      <MDBCol md="3">
                        <span> Spouse Name</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id=""
                          type="text"
                          placeholder="Spouse Name"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          // value={formData.husband_name}
                          // name="husband_name"
                          // onChange={handleInputChange}
                        />
                      </MDBCol>)}
                    </MDBRow>
                    <MDBRow>
                    

                      <MDBCol md="6">
                        <span>Nearest Police Station </span>
                        <InputGroup>
                         
                          <Form.Control
                            wrapperClass="mb-4"
                            className="mt-2"
                            id="form1"
                            placeholder="Enter Nearest Police Station"
                            fullWidth
                            variant="outlined"
                            size="md"
                            // value={num}
                            name="nearest_police_station"
                            onChange={handleInputChange}
                            value={formData.nearest_police_station}
                            type="text"
                            style={{ appearance: "textfield" }}
                            // onChange={handleChange}
                            // inputProps={{  }}
                          />
                        </InputGroup>
                      </MDBCol>
                      <MDBCol md="6">

                        <span>  Idenificattion Mark</span>
                        <InputGroup>
                         
                          <Form.Control
                            wrapperClass="mb-4"
                            className="mt-2"
                            id="form1"
                            placeholder="Enter Idenificattion Mark"
                            fullWidth
                            variant="outlined"
                            size="md"
                            onChange={handleInputChange}
                            value={formData.identification_mark}
                            name="identification_mark"
                        
                            type="text"
                            style={{ appearance: "textfield" }}
                          
                          />
                        </InputGroup>
                      </MDBCol>
                 
                    </MDBRow>
                    <MDBRow>
                    

                   
                     
                    </MDBRow>

                    <MDBRow>
                      <label
                        style={{
                          fontSize: "20px",
                          marginBottom: "20px",
                          marginTop: "20px",
                          backgroundColor: "lightgrey",
                          fontWeight: 500,
                          borderRadius: "6px",
                        }}
                      >
                        Contact Details
                      </label>

                      <MDBCol md="6">
                        <span> Phone Number</span>

                        <InputGroup>
                          <InputGroup.Text id="basic-addon1" className="mt-2">
                            +91
                          </InputGroup.Text>
                          <Form.Control
                            wrapperClass="mb-4"
                            className="mt-2"
                            id="form1"
                            placeholder="Enter Official Number"
                            fullWidth
                            variant="outlined"
                            size="md"
                            value={num}
                            name="emp_cont"
                            required
                            type="number"
                            pattern="[0-9]"
                            style={{ appearance: "textfield" }}
                            onChange={handleChange}
                            // inputProps={{  }}
                          />
                        </InputGroup>
                      </MDBCol>

                      <MDBCol md="6">
                        <span>Official Phone Number</span>
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1" className="mt-2">
                            +91
                          </InputGroup.Text>
                          <Form.Control
                            wrapperClass="mb-4"
                            className="mt-2"
                            id="outlined-basic"
                            placeholder="Enter Your Number"
                            fullWidth
                            variant="outlined"
                            size="md"
                            // maxLength={10}
                            name="official_cont"
                            type="number"
                            pattern="[0-9]"
                            style={{ appearance: "textfield" }}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              const truncatedMobileNumber = numericValue.slice(
                                0,
                                10
                              );
                              e.target.value = truncatedMobileNumber;
                            }}
                            // inputProps={{  }}
                          />
                        </InputGroup>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className="mt-4">
                      <MDBCol md={6}>
                        <span> Email ID</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="form1"
                          fullWidth
                          placeholder="Enter Official Email ID"
                          variant="outlined"
                          size="small"
                          value={formData.emp_email}
                          type="email"
                          name="emp_email"
                          required
                          className="mt-2"
                          onChange={handleInputChange}
                        />
                      </MDBCol>

                      <MDBCol md={6}>
                        <span>Official Email ID </span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="outlined-basic"
                          fullWidth
                          placeholder=" Enter  Email ID "
                          variant="outlined"
                          size="small"
                          value={formData.official_email}
                          type="email"
                          name="official_email"
                          className="mt-2"
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <label
                        style={{
                          fontSize: "20px",
                          marginBottom: "20px",
                          marginTop: "20px",
                          backgroundColor: "lightgrey",
                          fontWeight: 500,
                          borderRadius: "6px",
                        }}
                      >
                        Employee Professional Details
                      </label>

                  
                      <MDBCol md="4">
                        <span>Employee Status</span>
                        <Form.Select
                          name="emp_status"
                          id=""
                          style={{ width: "100%", height: "38px" }}
                          className="mt-2"
                        >
                          <option value="" disabled selected>
                            Select Emplyee Status
                          </option>
                          {/* <option value="Active">Active</option> */}
                          <option value="Probation">Probation</option>
                          {/* <option value="Inactive">Inactive</option> */}
                          <option value="Full-Time">Full-Time</option>
                          <option value="Part-Time">Part-Time</option>
                          <option value="Termination">Termination</option>
                          <option value="Resigned">Resigned</option>
                        </Form.Select>
                      </MDBCol>

                      <MDBCol md={4}>
                        <label>Monthly Salary </label>
                        <MDBInput
                          wrapperClass="mb-4"
                          placeholder=" Enter Monthly Salary "
                          type="number"
                          required
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={formData.salary}
                          name="salary"
                          className="mt-2"
                          pattern="[0-9]"
                          style={{ appearance: "textfield" }}
                          onChange={handleInputChange}
                          id="form1"
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md={4}>
                        <label className="mb-2">UAN Number</label>
                        <MDBInput
                          // wrapperClass="mb-4"
                          type="text"
                          name="uan_number"
                          label=""
                          placeholder="Enter UAN Number"
                          onChange={handleInputChange}
                          // style={{ marginLeft: "-10px" }}
                          id=""
                        />
                      </MDBCol>

                      <MDBCol md={4}>
                        <label>ESI Number</label>
                        <MDBInput
                          // wrapperClass="mb-4"
                          id="form1"
                          type="text"
                          placeholder=" Enter ESI Number *"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={formData.esi_no}
                          name="esi_no"
                          // style={{ marginLeft: "-10px" }}
                          onChange={handleInputChange}
                        />
                      </MDBCol>

                      <MDBCol md={4}>
                        <label>PF Number</label>
                        <MDBInput
                          // wrapperClass="mb-2"
                          id="form1"
                          type="text"
                          placeholder=" Enter PF Number"
                          variant="outlined"
                          fullWidth
                          size="md"
                          className="mt-2"
                          value={formData.pf_no}
                          name="pf_no"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className="mt-4">
                      <MDBCol md="4">
                        <label className="mt-2">Department</label>
                        <Department
                          onSelect={setSelectedOption}
                          onSelectDepartment={handleDepartmentSelect}
                          id="select1"
                        />
                      </MDBCol>

                      <MDBCol md="4">
                        <label className="mt-2">Sub-Department</label>
                        <SubDepartment
                          selectedOption={selectedOption}
                          onSelectSubDepartment={handleSubDepartmentSelect}
                          id="select2"
                        />
                      </MDBCol>
                      <MDBCol md="4">
                        <span>Desgination</span>
                        <MDBInput
                          wrapperClass="mb-4"
                          id="form1"
                          placeholder="Enter Desgination "
                          fullWidth
                          variant="outlined"
                          size="small"
                          value={formData.emp_desg}
                          name="emp_desg"
                          required
                          className="mt-2"
                          onChange={handleInputChange}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md={6}>
                        <Form.Check // prettier-ignore
                          type="switch"
                          label="Do you calculate PF ?"
                          className="mt-5 ml-1"
                          checked={showComponent}
                          onChange={() => setShowComponent(!showComponent)}
                        />
                        {showComponent ? (
                          <>
                            <MDBRow>
                              <MDBCol md="6">
                                <MDBInput
                                  wrapperClass="mb-4"
                                  type="text"
                                  placeholder="Employee Contribution(Amount)"
                                  value={formData.epf}
                                  name="epf"
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    marginTop: "4px",
                                  }}
                                />
                              </MDBCol>
                              <MDBCol md="6">
                                <MDBInput
                                  wrapperClass="mb-4"
                                  type="text"
                                  placeholder="Employer Contribution(Amount)"
                                  value={formData.eepf}
                                  name="eepf"
                                  onChange={handleInputChange}
                                  style={{
                                    width: "100%",
                                    height: "40px",
                                    marginTop: "4px",
                                  }}
                                />
                              </MDBCol>
                            </MDBRow>
                          </>
                        ) : (
                          ""
                        )}
                      </MDBCol>

                      <MDBCol md="3">
                        <Form.Check
                          className="mt-5 ms-5"
                          value="selfie + gps"
                          // name="emp_att_type"
                          id="Selfie"
                          onChange={handleInputChange}
                          // control={<Radio />}
                          label="Selfie + GPS"
                          style={{
                            position: "relative",
                            float: "left",
                            marginTop: "10px",
                            paddingLeft: "50px",
                            fontSize: "16px",
                          }}
                        />
                      </MDBCol>

                      <MDBCol md="3">
                        <Form.Check
                          value="qr scan + gps"
                          // name="emp_att_type"
                          // control={<Radio />}
                          required
                          className="mt-5"
                          id="Scan"
                          label="QR Code Scan + GPS"
                          onChange={handleInputChange}
                          style={{
                            position: "relative",
                            float: "left",
                            marginTop: "10px",
                            fontSize: "16px",
                          }}
                        />
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <label
                        style={{
                          fontSize: "20px",
                          marginBottom: "20px",
                          marginTop: "20px",
                          backgroundColor: "lightgrey",
                          fontWeight: 500,
                          borderRadius: "6px",
                        }}
                      >
                        Working Schedule
                      </label>
                      <MDBCol md={6}>
                        <MDBRow>
                          <MDBCol md={3}>
                            <span>
                              <b>Start Time</b>
                            </span>
                          </MDBCol>
                          <MDBCol md={7}>
                            <MDBInput
                              wrapperClass="mb-4"
                              type="time"
                              value={formData.start_time}
                              name="start_time"
                              required
                              className="mb-8"
                              onChange={handleInputChange}
                              id="form1"
                              style={{
                                width: "100%",
                                height: "40px",
                                paddingLeft: "10px",
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                      <MDBCol md={6}>
                        <MDBRow>
                          <MDBCol md={3}>
                            <label>
                              <b>End Time</b>
                            </label>
                          </MDBCol>
                          <MDBCol md={7}>
                            <MDBInput
                              wrapperClass="mb-7"
                              type="time"
                              value={formData.start_end}
                              name="start_end"
                              required
                              className="mb-8"
                              onChange={handleInputChange}
                              id="form1"
                              // label="End Time"
                              style={{
                                width: "100%",
                                height: "40px",
                                paddingLeft: "10px",
                                position: "relative",
                              }}
                            />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>

                      <MDBCol md="6">
                        <label className="mb-2">Week Off Days</label>
                        {/* <FormLabel
                            id="demo-form-control-label-placement"
                            className="mt-3"
                          >
                            Week Off Days
                          </FormLabel> */}
                        {weekArray?.map((weekdata, index) => {
                          return (
                            <WeekOffDay
                              key={index}
                              curWeekData={weekdata}
                              selectedWeeks={selectedWeeks}
                              setSelectedWeeks={setSelectedWeeks}
                            />
                          );
                        })}
                      </MDBCol>

                      {/* <MDBCol md={3}>



                        </MDBCol> */}

                      <div className="d-flex justify-content-end w-100">
                        <Button
                          style={{
                            float: "right",
                            borderStyle: "none",
                            marginRight: "5%",
                          }}
                          className="btn submitButtons w-25"
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                      </div>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </form>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default Addstaff;
