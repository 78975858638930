import axiosInstance from "../../lib/axiosInstance";
const EmpHome = class {
  
    async Empdetails(emphome ) {
        return await axiosInstance.post("/home.php", emphome);
    }
    async Empholiday(empholiday ) {
        return await axiosInstance.post("/holidays.php", empholiday);
    }
    async Empreimbursement(empreimbursement ) {
        return await axiosInstance.post("/reimbursement.php", empreimbursement);
    }
    async EmpResHistory(empreimbursement ) {
        return await axiosInstance.post("/reimbursement.php", empreimbursement);
    }
    async Empatthistyory(empatthistyory ) {
        return await axiosInstance.post("/attendance.php", empatthistyory);
    }
    async EmpNotice(empnotice ) {
        return await axiosInstance.post("/notice.php", empnotice);
    }
    async Empleavehistory(Empleavehistory ) {
        return await axiosInstance.post("/leave.php", Empleavehistory);
    }
    async EmpSalaryhistory(Empleavehistory ) {
        return await axiosInstance.post("/salary.php", Empleavehistory);
    }


   
}

export default new EmpHome();