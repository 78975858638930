import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";

import { Container, Row, Col, Card } from 'react-bootstrap';


const AttendanceRequest = () => {

    return (
        <>
            <div>
                <Container fluid style={{ backgroundColor: 'rgba(171, 171, 173, 0.15)' }} >
                    <Row className='pt-3 pb-3'>
                        <Col>
                            <div className='' >
                                <span style={{ fontSize: '20px' }}>AttendanceRequest</span>
                                <button type="button " style={{ position: 'relative', float: 'right', width: '80px', marginLeft: '25px', padding: '5px' }} className='btn btn-success'>Setting</button>
                                <button type="button " style={{ position: 'relative', float: 'right', width: '160px', marginLeft: '25px', padding: '5px' }} className='btn btn-primary'>Add Attendance</button>

                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-3'>
                        <Col>
                            <button style={{ backgroundColor: 'white' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-columns" viewBox="0 0 16 16">
                                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V2zm8.5 0v8H15V2H8.5zm0 9v3H15v-3H8.5zm-1-9H1v3h6.5V2zM1 14h6.5V6H1v8z" />
                            </svg></button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '90px', marginLeft: '25px', padding: '5px' }}>Entry Date</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '100px', marginLeft: '25px', padding: '5px' }}>Request date</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '110px', marginLeft: '25px', padding: '5px' }}>Department</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '80px', marginLeft: '25px', padding: '5px' }}>Work Shift</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '90px', marginLeft: '25px', padding: '5px' }}>See rejected</button>
                            <br />
                            <br />
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '120px', marginLeft: '25px', padding: '5px' }}>Request type</button>
                            <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '70px', marginLeft: '25px', padding: '5px' }}>User</button>

                            <div className='search-box' style={{ position: 'relative', display: 'inline-block', float: 'right' }} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-search p-1" viewBox="0 0 16 16" style={{ position: 'absolute', top: '5px', left: '0px', transform: 'translateY(-50)' }}>
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                                <input type="text" style={{ paddingLeft: "30px", height: '40px', borderRadius: '20px', borderStyle: 'solid', backgroundColor: "#f5f2f2", borderColor: 'lightblue' }} placeholder='Search' />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-3'>
                        <Col>Showing 1 to 4 items of 4</Col>
                    </Row>
                    <Card style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px" }}>
                        <Row className='mt-2 mb-3'>
                            <table className='table  table-responsive-sm' style={{ width: '96%', marginLeft: '20px' }}>
                                <thead style={{ height: '100px' }}>
                                    <tr style={{ verticalAlign: 'middle' }} className=''>
                                        <td style={{ paddingLeft: '20px', width: '180px' }}>Profile </td>
                                        <td style={{ width: '180px' }}>Punched in</td>
                                        <td style={{ width: '180px' }}>Punched Out</td>
                                        <td style={{ width: '180px' }} >Request type</td>
                                        <td style={{ width: '180px' }} >Total hours</td>
                                        <td style={{ width: '180px' }} >Status</td>
                                        <td style={{ width: '180px' }}>Actions</td>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                        <td style={{ paddingLeft: '20px', width: '280px' }}>
                                            <div className='top-card-div' style={{}}>
                                                <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                    </svg>
                                                </span>
                                                <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                    <br /> Development

                                                </span>

                                            </div>
                                        </td>
                                        <td >11:34 AM, 29 Mar, 23</td>
                                        <td>8:34 PM, 29 Mar, 23</td>
                                        <td>New <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                        </svg> </td>
                                        <td>09:00</td>
                                        <td> <button style={{ color: 'white', backgroundColor: 'orange', borderRadius: '10px' }}> Pending</button></td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>
                                    <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                        <td style={{ paddingLeft: '20px', width: '280px' }}>
                                            <div className='top-card-div' style={{}}>
                                                <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                    </svg>
                                                </span>
                                                <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                    <br /> Development

                                                </span>

                                            </div>
                                        </td>
                                        <td >11:34 AM, 29 Mar, 23</td>
                                        <td>8:34 PM, 29 Mar, 23</td>
                                        <td>New <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                        </svg> </td>
                                        <td>09:00</td>
                                        <td> <button style={{ color: 'white', backgroundColor: 'orange', borderRadius: '10px' }}> Pending</button></td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>

                                </tbody>
                            </table>
                        </Row>

                    </Card>
                </Container>
            </div>
        </>
    )
}
export default AttendanceRequest;