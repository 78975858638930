import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchAllSubdepartments, fetchAlldepartments } from '../../store/silce/bussiness';
import { Form } from 'react-bootstrap';
const Department = ({ onSelect, data ,onSelectDepartment   }) => {
  console.log(data, 'empprivewqq')
  const dispatch = useDispatch();
  const DepartmentData = useSelector((state) => { return state.Business.Alldepartments })
  const SubDepartmentData = useSelector((state) => { return state?.Business?.AllSubdepartments })
  useEffect(() => {
    dispatch(fetchAlldepartments())
  }, []);
  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    onSelect(selectedValue);
    onSelectDepartment (selectedValue);
    dispatch(fetchAllSubdepartments({ Option: selectedValue }))
    console.log(selectedValue,"selectedValue")
  };
  console.log(SubDepartmentData, 'AllSubdepartments')
  return (
    <div>
      <Form.Select style={{ width: '100%', }} className="mt-2"id="select1"  onChange={handleDropdownChange} >
        <option value="">
          Select  Department</option>
        {DepartmentData && DepartmentData.map((sectorlist) =>
          <option value={sectorlist.department_id} selected={sectorlist.department_id === data?.parent_department_id}
            style={{ height: "120px", }}>{sectorlist.department_name}</option>
        )}
      </Form.Select>
    </div>
  )
}
export default Department
