// src/store/appSlice.js

import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    businessId: '',
    isHovering: false,
    selectedHoverData: null,
    selectedPage:""
  },
  reducers: {
    updateBusinessId: (state, action) => {
      state.businessId = action.payload;
    },
    sidebarChildMenuClose: (state, action) => {
      state.isHovering = action.payload;
    },
    sidebarChildMenuOpen: (state, action) => {
      state.isHovering = action.payload.value;
      state.selectedHoverData = action.payload.data;
    },
    setSelectedPage:(state, action)=>{
      state.selectedPage = action.payload
    }
  },
});

export const {
  updateBusinessId,
  sidebarChildMenuClose,
  sidebarChildMenuOpen,
  setSelectedPage
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
