import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HomeStatics, notification } from "../../store/silce/dashboardSlice";

import { Button, Card, Form, Row, Container } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Rectangle,
} from "recharts";
import "../internalPage.css";
import { MdOutlineWatchLater } from "react-icons/md";
import { nofetchAllEmployees } from "../../store/silce/staffSlice";
import { EmpTimeLog, fetchAttendance } from "../../store/silce/dashboardSlice";
import DashboardNewCharts from "./DashboardNewCharts";
// import Notification from "../../InternalPage/Notification";
import DashboardChartNews from "./DashboardChartNews";
import DashboardEmpStatusChart from "./DashboardEmpStatusChart";
import Loader from "../../components/Common/Loading";
import moment from "moment";

const dropdownOptions = [{ value: "1", label: "Select Employee" }];

function NotificationGroup({ datat, timeDisplay }) {
  return (
    <Card className="shadow p-1 mb-2 bg-white rounded">
      <div className="m-2">
        <span
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "inherit",
          }}
        >
          {datat?.title}
        </span>
        <br />
        <span className="small text-disable" style={{ fontSize: "12px" }}>
          {timeDisplay}
        </span>{" "}
        <br />
        <span className="mt-1 small" style={{ fontSize: "14px" }}>
          {datat?.msg}{" "}
        </span>
      </div>
    </Card>
  );
}

const BarChartComponent = () => {
  const busid = useSelector((state) => state?.selectOption?.value);

  const selecteoption = localStorage.getItem("selecteoption");

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.dashboard.homeLoading);

  useEffect(() => {
    
    dispatch(nofetchAllEmployees());
  }, [busid]);

  const employees = useSelector((state) => state.staff.employees);
  

  const employeesDashboard = useSelector(
    (state) => state?.dashboard?.home?.EmpTimeLog?.data
  );

  const homestatus = useSelector((state) => state?.dashboard?.Homedata);
 

  const [selectedDatee, setSelectedDatee] = useState(
    moment().format("YYYY-MM-DD")
  );
  const fromDate = moment(selectedDatee).format("DD-MM-YYYY");

  

  const [selectedOptionId, setSelectedOptionId] = useState("Select Emp");
  const [isLoading, setIsLoading] = useState(true);
  const handlenewdataa = (event) => {
    const selectedId = event.target.value;

    setSelectedOptionId(selectedId);
    // Set the selected option ID in the state

    dispatch(EmpTimeLog({ id: selectedId, date: fromDate }));
  };

  const notificationlist = useSelector(
    (state) => state?.dashboard?.notification?.notification
  );
  useEffect(() => {
    dispatch(HomeStatics());
    dispatch(nofetchAllEmployees());
  }, [busid]);
  const remainingHours =
    employeesDashboard?.current_date?.scheduled_hour -
    employeesDashboard?.current_date?.worked_hour;

  const [timer, setTimer] = useState(remainingHours * 60 * 60);
  
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimer((prevTimer) => {
        // Update UI with remaining time
       

        // Check if timer has reached zero
        if (prevTimer <= 0) {
          clearInterval(countdownInterval);
         
        }

        return prevTimer - 1;
      });
    }, 10000);
    return () => clearInterval(countdownInterval);
  }, []);
  useEffect(() => {
    dispatch(notification());
  }, []);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    const options = { month: "short", day: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const attendance = useSelector((state) => state.dashboard.attendance);
  // const busid = useSelector((state) => state.selectOption.value);
  const Chartloading = useSelector((state) => state.dashboard.navLoading);
 

  useEffect(() => {
    dispatch(fetchAttendance());
  }, [busid]);

  const groupedNotifications =
    notificationlist &&
    notificationlist?.reduce(
      (acc, datat) => {
        const messageDate = moment(datat?.date);
        let timeDisplay;

        if (messageDate.isSame(moment(), "day")) {
          timeDisplay = "Today " + messageDate.format("hh:mm A");
        } else if (messageDate.isSame(moment().subtract(1, "days"), "day")) {
          timeDisplay = "Yesterday " + messageDate.format("hh:mm A");
        } else {
          timeDisplay = messageDate.format("MMMM Do YYYY, hh:mm A");
        }

        if (messageDate.isSame(moment(), "day")) {
          acc.today.push(
            <NotificationGroup
              key={datat.id}
              datat={datat}
              timeDisplay={timeDisplay}
            />
          );
        } else if (messageDate.isSame(moment().subtract(1, "days"), "day")) {
          acc.yesterday.push(
            <NotificationGroup
              key={datat.id}
              datat={datat}
              timeDisplay={timeDisplay}
            />
          );
        } else {
          acc.other.push(
            <NotificationGroup
              key={datat.id}
              datat={datat}
              timeDisplay={timeDisplay}
            />
          );
        }

        return acc;
      },
      { today: [], yesterday: [], other: [] }
    );

  const handleDateChangee = (event) => {
    const dateValue = event.target.value;
    setSelectedDatee(dateValue);
    dispatch(EmpTimeLog({ id: selectedOptionId, date: fromDate }));
  };
  useEffect(() => {}, [selectedDatee]);


 

  return (
    <div className="container">
      {Chartloading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader />
        </div>
      ) : (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="col-md-4 mb-4">
          <DashboardNewCharts attendance={attendance} />
        </div>
        <div className="col-md-4 mb-4">
          <DashboardChartNews attendance={attendance} />
        </div>
        <div className="col-md-4 mb-4">
          {/* <DashboardChartNews/> */}
          <DashboardEmpStatusChart />
        </div>
      </div>
      )}
      <div>
        {/* <div className="col-md-12 mb-4">
          <Card
            className="mt-0 h-100 w-100"
            style={{ boxShadow: "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }}
          >
            <Card.Header className="d-flex align-items-center justify-content-sm-between ">
              <Form.Label className="fw-bold">Time Log </Form.Label>
              <select
                name=""
                id=""
                className="cutum-data"
                value={selectedOptionId}
                onChange={handlenewdataa}
                style={{
                  fontSize: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <option value="" selected style={{ fontSize: "15px" }}>
                  Select Employee
                </option>
                {employees &&
                  employees?.map((data) => (
                    <option
                      value={data?.id}
                      style={{
                        fontSize: "15px",
                        height: "20px",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      }}
                    >
                      {data.name}
                    </option>
                  ))}
              </select>
            </Card.Header>
            <Card.Body>
              <div>
                <h6 style={{ height: "7px" }}>Today</h6>
                <hr />
                <div className="d-flex align-items-center justify-content-sm-between">
                  <div>
                    <p className="mb-0" style={{ height: "12px" }}>
                      {employeesDashboard?.current_date?.scheduled_hour}
                    </p>
                    <p className="text-secondary mb-0 timelog-p">Scheduled</p>
                  </div>
                  <div>
                    <p className="mb-0" style={{ height: "12px" }}>
                      {employeesDashboard?.current_date?.worked_hour}
                    </p>
                    <p className="text-secondary mb-0 timelog-p">Worked</p>
                  </div>
                  <div>
                    <p className="mb-0" style={{ height: "12px" }}>
                      {employeesDashboard?.current_date?.balance_hour}
                    </p>
                    <p className="text-secondary mb-0 timelog-p">Balance</p>
                  </div>
                </div>
                <div className="mt-3">
                  <h6 style={{ height: "7px" }}>This Month</h6>
                  <hr />
                  <div
                    className="d-flex align-items-center gap-2"
                    style={{ height: "60px" }}
                  >
                    <Button className="mb-2">
                      <MdOutlineWatchLater className="mb-1" />
                    </Button>
                    <div className="mb-2">
                      <p className="fw-bold mt-5" style={{ height: "9px" }}>
                        {employeesDashboard?.current_month?.scheduled_hour}
                      </p>
                      <p className="text-secondary mb-5 timelog-p">
                        total schedule time
                      </p>
                    </div>
                  </div>
                  <div>
                    <p style={{ height: "0px", fontSize: "12px" }}>
                      Worked time -{" "}
                      {employeesDashboard?.current_month?.worked_hour} (hh:mm)
                    </p>
                    <p style={{ height: "0px", fontSize: "12px" }}>
                      Balance time -{" "}
                      {employeesDashboard?.current_month?.balance_hour}
                    </p>
                    <div>
                      <input
                        style={{ height: "5px" }}
                        type="range"
                        id="volume"
                        name="volume"
                        min="0"
                        max="11"
                      />
                      <label style={{ height: "1px", fontSize: "12px" }}>
                        Shortage time - 00:00
                      </label>
                    </div>
                    <p style={{ height: "0px", fontSize: "12px" }}>
                      Over time -{" "}
                      {employeesDashboard?.current_month?.overtime_hour}
                    </p>
                    <div>
                      <input
                        style={{ height: "5px" }}
                        type="range"
                        id="volume"
                        name="volume"
                        min="0"
                        max="11"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginBottom: "20px",
        }}
        className="mt-4"
      >
        {/* <div className="col-md-6">
          <ResponsiveContainer
            width="100%"
            height={400}
            style={{ boxShadow: "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }}
          >
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Probation"
                fill={color1}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
              <Bar
                dataKey="Full Time"
                fill={color2}
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />
              <Bar
                dataKey="Contractual"
                fill={color3}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
              <Bar
                dataKey="Part Time"
                fill={color4}
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div> */}
        <div className="col-md-6 md-3">
          <Card
            className="col-md-12"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Card.Header className="fw-bold">
              <span>Time Log</span>
              {/* <span style={{ marginRight: "10px" }}>From </span> */}
              <input
                name=""
                id=""
                style={{
                  width: "29%",
                  borderRadius: "8px",
                  height: "30px",
                  marginLeft: "20px",
                }}
                type="date"
                value={selectedDatee}
                onChange={handleDateChangee}
              />
              <select
                className="float-end cutum-data"
                name=""
                id=""
                // className="cutum-data"
                value={selectedOptionId}
                onChange={handlenewdataa}
                style={{
                  fontSize: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <option value="" selected style={{ fontSize: "15px" }}>
                  Select Employee
                </option>
                {employees &&
                  employees?.data?.emp?.map((data) => (
                    <option
                      value={data?.id}
                      style={{
                        fontSize: "15px",
                        height: "20px",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      }}
                    >
                      {data.name}
                    </option>
                  ))}
              </select>
            </Card.Header>
            <Card.Body >
              <span style={{ fontWeight: "bold" }}>Today</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Card className="col-md-4">
                  <Card.Header className="fw-bold" style={{ fontSize: "14px" }}>
                    Scheduled Time
                  </Card.Header>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      {/* {formatDate(currentDate)} */}
                      {selectedDatee ? moment(selectedDatee).format("MMM DD, YYYY") : formatDate(currentDate)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {loading ? (
                        <Loader />
                      ) : (
                        employeesDashboard?.current_date?.scheduled_hour
                      )}
                    </Card.Text>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Scheduled Time{" "}
                    </Card.Title>
                  </Card.Body>
                </Card>

                <Card className="col-md-4" style={{ marginLeft: "5px" }}>
                  <Card.Header className="fw-bold" style={{ fontSize: "14px" }}>
                    worked Time
                  </Card.Header>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                  {selectedDatee ? moment(selectedDatee).format("MMM DD, YYYY") : formatDate(currentDate)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {loading ? (
                        <Loader />
                      ) : (
                        employeesDashboard?.current_date?.worked_hour
                      )}
                    </Card.Text>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      worked Time
                    </Card.Title>
                  </Card.Body>
                </Card>

                <Card className="col-md-4" style={{ marginLeft: "5px" }}>
                  <Card.Header className="fw-bold" style={{ fontSize: "14px" }}>
                    Balance Time
                  </Card.Header>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                       {selectedDatee ? moment(selectedDatee).format("MMM DD, YYYY") : formatDate(currentDate)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {loading ? (
                        <Loader />
                      ) : (
                        employeesDashboard?.current_date?.balance_hour
                      )}
                    </Card.Text>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Balance Time
                    </Card.Title>
                  </Card.Body>
                </Card>

                {/* <Card className="col-md-3" style={{marginLeft:"5px"}}>
                <Card.Header className="fw-bold">Total Time</Card.Header>
                  <Card.Body>
                    <Card.Title style={{fontSize:"14px",fontWeight:"bold",color:"gray"}}>Apr 20,2024</Card.Title>
                    <Card.Text style={{fontSize:"40px",fontWeight:"bold"}}>
                    {employeesDashboard?.current_month?.overtime_hour}
                    </Card.Text>
                    <Card.Title style={{fontSize:"14px",fontWeight:"bold",color:"gray"}}>puch in 6:43 am</Card.Title>
                  </Card.Body>
                </Card> */}
              </div>

              <span style={{ fontWeight: "bold" }}>This Month</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Card className="col-md-4">
                  <Card.Header className="fw-bold" style={{ fontSize: "14px" }}>
                    Scheduled Time
                  </Card.Header>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                    {selectedDatee ? moment(selectedDatee).format("MMM DD, YYYY") : formatDate(currentDate)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {loading ? (
                        <Loader />
                      ) : (
                        employeesDashboard?.current_month?.scheduled_hour
                      )}
                    </Card.Text>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Scheduled Time{" "}
                    </Card.Title>
                  </Card.Body>
                </Card>

                <Card className="col-md-4" style={{ marginLeft: "5px" }}>
                  <Card.Header className="fw-bold" style={{ fontSize: "14px" }}>
                    worked Time
                  </Card.Header>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      {selectedDatee ? moment(selectedDatee).format("MMM DD, YYYY") : formatDate(currentDate)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {loading ? (
                        <Loader />
                      ) : (
                        employeesDashboard?.current_month?.worked_hour
                      )}
                    </Card.Text>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      worked Time
                    </Card.Title>
                  </Card.Body>
                </Card>

                <Card className="col-md-4" style={{ marginLeft: "5px" }}>
                  <Card.Header className="fw-bold" style={{ fontSize: "14px" }}>
                    Balance Time
                  </Card.Header>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                     {selectedDatee ? moment(selectedDatee).format("MMM DD, YYYY") : formatDate(currentDate)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {loading ? (
                        <Loader />
                      ) : (
                        employeesDashboard?.current_month?.balance_hour
                      )}
                    </Card.Text>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Balance Time
                    </Card.Title>
                  </Card.Body>
                </Card>

                {/* <Card className="col-md-3" style={{marginLeft:"5px"}}>
                <Card.Header className="fw-bold">Total Time</Card.Header>
                  <Card.Body>
                    <Card.Title style={{fontSize:"14px",fontWeight:"bold",color:"gray"}}>Apr 20,2024</Card.Title>
                    <Card.Text style={{fontSize:"40px",fontWeight:"bold"}}>
                    {employeesDashboard?.current_month?.overtime_hour}
                    </Card.Text>
                    <Card.Title style={{fontSize:"14px",fontWeight:"bold",color:"gray"}}>puch in 6:43 am</Card.Title>
                  </Card.Body>
                </Card> */}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Card className="col-md-12" style={{ marginTop: "10px" }}>
                  <Card.Header className="fw-bold" style={{ fontSize: "14px" }}>
                    Over Time
                  </Card.Header>
                  <Card.Body
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      {selectedDatee ? moment(selectedDatee).format("MMM DD, YYYY") : formatDate(currentDate)}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {loading ? (
                        <Loader />
                      ) : (
                        employeesDashboard?.current_month?.overtime_hour
                      )}
                    </Card.Text>
                    <Card.Title
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "gray",
                      }}
                    >
                      Balance Time
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>

              {/* <div className="mt-3">
                <h6 style={{ height: "7px" }}>This Month</h6>
                <hr />
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ height: "60px" }}
                >
                  <Button className="mb-2">
                    <MdOutlineWatchLater className="mb-1" />
                  </Button>
                  <div className="mb-2">
                    <p className="fw-bold mt-5" style={{ height: "9px" }}>
                      {employeesDashboard?.current_month?.scheduled_hour}
                    </p>
                    <p className="text-secondary mb-5 timelog-p">
                      total schedule time
                    </p>
                  </div>
                </div>
                <div>
                  <p style={{ height: "0px", fontSize: "12px" }}>
                    Worked time -{" "}
                    {employeesDashboard?.current_month?.worked_hour} (hh:mm)
                  </p>
                  <p style={{ height: "0px", fontSize: "12px" }}>
                    Balance time -{" "}
                    {employeesDashboard?.current_month?.balance_hour}
                  </p>
                  <div>
                    <input
                      style={{ height: "5px" }}
                      type="range"
                      id="volume"
                      name="volume"
                      min="0"
                      max="11"
                    />
                    <label style={{ height: "1px", fontSize: "12px" }}>
                      Shortage time - 00:00
                    </label>
                  </div>
                  <p style={{ height: "0px", fontSize: "12px" }}>
                    Over time -{" "}
                    {employeesDashboard?.current_month?.overtime_hour}
                  </p>
                  <div>
                    <input
                      style={{ height: "5px" }}
                      type="range"
                      id="volume"
                      name="volume"
                      min="0"
                      max="11"
                    />
                  </div>
                </div>
              </div> */}
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-6 md-3" style={{ marginLeft: "20px" }}>
          <Card className="col-md-12">
            <Card.Header className="fw-bold" style={{ height: "53px" }}>
              <span> Notification List</span>
              {/* <select
                className="float-end cutum-data"
                name=""
                id=""
                // className="cutum-data"
                value={selectedOptionId}
                onChange={handlenewdataa}
                style={{
                  fontSize: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <option value="" selected style={{ fontSize: "15px" }}>
                  Select Employee
                </option>
                {employees &&
                  employees?.map((data) => (
                    <option
                      value={data?.id}
                      style={{
                        fontSize: "15px",
                        height: "20px",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                      }}
                    >
                      {data.name}
                    </option>
                  ))}
              </select> */}
            </Card.Header>
            <Card.Body>
              <div style={{ height: "99vh", overflowY: "scroll" }}>
                <Container fluid>
                  <Row>
                    {notificationlist && notificationlist.length > 0 ? (
                      <div>
                        <p className="fw-bold">Today</p>

                        <Card>
                          <Card.Body>
                            {groupedNotifications?.today?.length > 0 &&
                              groupedNotifications?.today}
                          </Card.Body>
                        </Card>

                        <p className="fw-bold">Yesterday</p>

                        <Card>
                          <Card.Body>
                            {groupedNotifications?.yesterday?.length > 0 &&
                              groupedNotifications?.yesterday}
                          </Card.Body>
                        </Card>

                        {/* <p className="fw-bold">Others Date</p> */}
                        {groupedNotifications?.other?.length > 0 && (
                          <Card style={{marginTop:"10px"}}>
                            <Card.Body>
                              {groupedNotifications?.other}{" "}
                            </Card.Body>
                          </Card>
                        )}
                      </div>
                    ) : (
                      <span>There are no records to display </span>
                    )}
                  </Row>
                </Container>
              </div>
            </Card.Body>
          </Card>
        </div>

        {/* <div style={{ height: "550px", overflowY: "auto" }} className="col-md-6 md-3">
          <Notification />
        </div> */}
      </div>
    </div>
  );
};

export default BarChartComponent;
