// import React, { useState } from 'react';
// import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
// import TextField from '@mui/material/TextField';
// import employeeModel from '../../Model/employee.Model';
// import { useParams } from 'react-router-dom';
// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-react-ui-kit';
// import { helper } from '../../lib/helper';
// import CommonModal from '../../components/Common/CommonModal';

// import { closeDeleteModal, closeEditModal, closeModal, openDeleteModal, openEditModal, openModal } from '../../store/silce/modalSlice';
// import { addBank } from '../../store/silce/staffSlice';

// const Bank = () => {
//     const dispatch = useDispatch()
//     const [currentTitle, setCurrentTitle] = useState('add-bank')
//     const showModal = useSelector((state) => state.modal.showModal);
//     const currentPage = useSelector((state) => state.modal.currentPage);
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     const [viewbank, setViewbank] = useState([]);
//     const params = useParams();
//     const [formData, setFormData] = useState({
//         ac_name: '',
//         ac_no: '',
//         bank_name: '',
//         ifsc: '',
//     });
//     const handleInputChange = (event) => {
//         setFormData({
//             ...formData,
//             [event.target.name]: event.target.value,
//         });

//     };
//     // const handleSubmit = (event) => {
//     //     event.preventDefault();
//     //     dispatch(addBank(event));
//     //     dispatch(closeModal())

//     // };
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         const form = new FormData(event.target);
//         form.append("emp_id", params?.id);
//         form.append("update_emp_bank_admin", localStorage.getItem("token"));
//         form.append("bus_id", localStorage.getItem("selecteoption"));
//         employeeModel.updatebank(form)
//             .then((response) => {
//                 console.log(response.data, "Bank Data Add");
//                 helper.sweetalert.toast( 'Your bank add.', 'success')
//                 handleClose();
//                 fetch()
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//         };
//    const fetch =  () => {
//         const formdata = new FormData();
//         formdata.append("bank_detail_edit_admin", localStorage.getItem("token"));
//         formdata.append("bus_id", sessionStorage.getItem("selecteoption"));
//         formdata.append("emp_id", params?.id);
//         employeeModel.bankdetail(formdata).then((res) => {
//             setViewbank(res.data.bank)
//             console.log(res.data)
//         }
//         )
//     }
// useEffect(() => {
//     fetch()
// }, [])

//     return (

//         <MDBCard className="mt-3">
//             <MDBCardHeader>
//                 <Row className=''>
//                     <Col>
//                         <div>
//                         <span style={{ marginTop: 'px', marginLeft: '10px', fontSize: '19px' }}>
//                                 <b style={{ paddingLeft: '' }}>Bank Details</b></span>
//                                 <button className='btn ' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }} onClick={() => {
//                                     setCurrentTitle('Add New Bank')
//                                     dispatch(openModal('add-bank'))
//                                 }}> Add Bank</button>
//                             {/* <button className='btn'
//                             onClick={() => {
//                                     dispatch(openModal("add-bank"))
//                                     setCurrentTitle("Add New bank")
//                                 }}
//                             // onClick={handleShow}
//                                 style={{ float: "right", backgroundColor: '#18285a', color: 'white' }}>Add Bank</button> */}
//                         </div>
//                     </Col>
//                 </Row>
//             </MDBCardHeader>
//             <MDBCardBody>
//                 <div>
//                     <Card className='mb-5'>
//                         <Container className='mb-3 mt-3'>
//                             <Row>

//                                 <Col sm={9}>
//                                     <Container fluid>

//                                         <Row>
//                                             <Col sm={4}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Bank name</p></Col>
//                                             <Col sm={8}>
//                                                 <TextField id="outlined-basic" placeholder={viewbank?.bank_name} variant="outlined" fullWidth size="small"
//                                                     value={viewbank?.bank_name}
//                                                     disabled
//                                                 /></Col>
//                                         </Row>
//                                         <Row className='mt-3'>
//                                             <Col sm={4}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>IFCS Code</p></Col>
//                                             <Col sm={8}>
//                                                 <TextField id="outlined-basic" placeholder='Enter IFCS Code' variant="outlined" fullWidth size="small"
//                                                     value={viewbank?.ifsc_code}
//                                                     disabled
//                                                     name='ifsc'
//                                                    /></Col>
//                                         </Row>
//                                         <Row className='mt-3'>
//                                             <Col sm={4}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Account Holder Name</p></Col>
//                                             <Col sm={8}>
//                                                 <TextField id="outlined-basic" placeholder='Enter Account Holder Name' variant="outlined" fullWidth size="small"
//                                                     value={viewbank?.account_name}
//                                                     disabled

//                                                 /></Col>
//                                         </Row>
//                                         {/* <Row className='mt-3'>
//                                         <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Account title</p></Col>
//                                         <Col sm={7}>
//                                             <TextField id="outlined-basic" placeholder='Enter account title'
//                                                 variant="outlined" fullWidth size="small"
//                                             /></Col>
//                                     </Row> */}

//                                         <Row className='mt-3'>
//                                             <Col sm={4}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Account number</p></Col>
//                                             <Col sm={8}>
//                                                 <TextField id="outlined-basic" placeholder='Enter Account number'
//                                                     variant="outlined" fullWidth size="small"
// disabled
//                                                     value={viewbank?.account_no}

//                                                 /></Col>
//                                         </Row>
//                                         {/* <Row className='mt-3'>
//                                         <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Confirm  Account number</p></Col>
//                                         <Col sm={7}>
//                                             <TextField id="outlined-basic" placeholder='Enter Confirm Account number'
//                                                 variant="outlined" fullWidth size="small"
//                                             /></Col>
//                                     </Row> */}
//                                         {/* <Row className='mt-3'>
//                                         <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Tax payer id</p></Col>
//                                         <Col sm={7}>
//                                             <TextField id="outlined-basic" placeholder='Enter Tax payer id'
//                                                 variant="outlined" fullWidth size="small"
//                                             /></Col>
//                                     </Row> */}

//                                     </Container>
//                                 </Col>
//                                 <Col sm={3} >

//                                 </Col>
//                             </Row>
//                             <Modal show={show} onHide={handleClose} size="md">
//                                 <Modal.Header closeButton>
//                                     <Modal.Title><span style={{ fontSize: '18px' }}>Add Bank Details</span></Modal.Title>
//                                 </Modal.Header>
//                                 <Modal.Body>
//                                     <form onSubmit={handleSubmit}>
//                                         <Container >

//                                             <Row>
//                                                 <Col sm={4}> <span style={{ fontSize: '16px' }}>Bank name</span></Col>
//                                                 <Col sm={8}>
//                                                     <TextField id="outlined-basic" placeholder='Enter bank name' variant="outlined" fullWidth size="small"

//                                                         name='bank_name'
//                                                        /></Col>
//                                             </Row>
//                                             <Row className='mt-3'>
//                                                 <Col sm={4}> <span style={{ fontSize: '16px' }}>IFCS Code</span></Col>
//                                                 <Col sm={8}>
//                                                     <TextField id="outlined-basic" placeholder='Enter IFCS Code' variant="outlined" fullWidth size="small"

//                                                         name='ifsc'
//                                                        /></Col>
//                                             </Row>
//                                             <Row className='mt-3'>
//                                                 <Col sm={4}> <span style={{ fontSize: '16px' }}>Account Holder Name</span></Col>
//                                                 <Col sm={8}>
//                                                     <TextField id="outlined-basic" placeholder='Enter Account Holder Name' variant="outlined" fullWidth size="small"
//                                                         name='ac_name'

//                                                     /></Col>
//                                             </Row>

//                                             <Row className='mt-3'>
//                                                 <Col sm={4}> <span style={{ fontSize: '16px' }}>Account number</span></Col>
//                                                 <Col sm={8}>
//                                                     <TextField id="outlined-basic" placeholder='Enter Account number'
//                                                         variant="outlined" fullWidth size="small"
//                                                         name='ac_no'

//                                                     /></Col>
//                                             </Row>

// <Row className='mt-4'>
//                                                 <Col sm={6}><Button className='' style={{ backgroundColor: 'gray', color: 'white' ,borderStyle:"none"}} onClick={() => handleClose()} > Close</Button></Col>
//                                                 <Col sm={6}><Button type="submit"
//                                                 style={{ backgroundColor: '#18285a',borderStyle:"none" ,float:'right' }}
//                                                 className=''

//                                             > Submit</Button></Col>
//                                             </Row>
//                                         </Container>
//                                     </form>
//                                 </Modal.Body>

//                             </Modal>
//                         </Container>
//                     </Card>

//                 </div>
//             </MDBCardBody>

//             <CommonModal  show={showModal} name={currentPage} title={currentTitle} onHide={() => dispatch(closeModal())} onSubmit={handleSubmit} />

//         </MDBCard>
//     )
// }

// export default Bank
//////////////////////////////////////
import React, { useState } from "react";
import { Container, Row, Col, Card, Modal, Button, Form } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import employeeModel from "../../Model/employee.Model";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
import { helper } from "../../lib/helper";
import "../../App.css";
const Bank = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [viewbank, setViewbank] = useState("");
  const params = useParams();
  const [formData, setFormData] = useState({
    ac_name: "",
    ac_no: "",
    bank_name: "",
    ifsc: "",
  });
  console.log(viewbank, "viewbank");
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  console.log(params?.id, "params?.id");
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    if (params?.id !== undefined && params.id !== "") {
      form.append("emp_id", params?.id);
      form.append("bus_id", localStorage.getItem("selecteoption"));
      form.append("update_emp_bank_admin", localStorage.getItem("token"));
    } else {
      form.append("update_emp_bank", localStorage.getItem("token"));
    }

    employeeModel
      .updatebank(form)
      .then((response) => {
        console.log(response.data, "Bank Data Add");
        helper.sweetalert.toast("Your bank add.", "success");
        handleClose();
        getdata();
        // Display success message to the user
        // window.location.assign("/stafflist");
      })
      .catch((error) => {
        console.log(error);
        // Display error message to the user
      });
  };
  const getdata = () => {
    const form = new FormData();
    if (params?.id !== undefined && params.id !== "") {
      form.append("bank_detail_edit_admin", localStorage.getItem("token"));
      form.append("bus_id", sessionStorage.getItem("selecteoption"));
      form.append("emp_id", params.id);
    } else {
      form.append("bank_detail_edit", localStorage.getItem("token"));
    }
    // Calling employeeModel.bankdetail with the formed FormData
    employeeModel.bankdetail(form).then((res) => {
      // Setting the state with the retrieved data

      setViewbank(res.data.bank);
      // console.log(res?.data,"lllllllff");
    });
  };
  const [formDatanew, setFormDatanew] = useState({
    ac_name: "",
    ac_no: "",
    bank_name: "",
    ifsc: "",
  });

  useEffect(() => {
    getdata();
  }, []);
  return (
    <MDBCard className="mt-3 ">
      <MDBCardHeader>
        <Row className="">
          <Col>
            <div>
              <span style={{ fontSize: "22px" }}>
                <>Bank Details</>
              </span>
              <button
                className="btn"
                onClick={handleShow}
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                }}
              >
                Add Bank
              </button>
            </div>
          </Col>
        </Row>
      </MDBCardHeader>
      <MDBCardBody>
        <div>
          <Card className="mb-5">
            <Container className="mb-3 mt-3">
              <Row>
                <Col sm={9}>
                  <Container fluid>
                    <Row>
                      <Col sm={4}>
                        <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                          Bank name
                        </p>
                      </Col>
                      <Col sm={8}>
                        <TextField
                          id="outlined-basic"
                          placeholder={viewbank?.bank_name}
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={viewbank?.bank_name}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4}>
                        <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                          IFCS Code
                        </p>
                      </Col>
                      <Col sm={8}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter IFCS Code"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={viewbank?.ifsc_code}
                          disabled
                          name="ifsc"
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm={4}>
                        <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                          Account Holder Name
                        </p>
                      </Col>
                      <Col sm={8}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter Account Holder Name"
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={viewbank?.account_name}
                          disabled
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    {/* <Row className='mt-3'>
                                        <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Account title</p></Col>
                                        <Col sm={7}>
                                            <TextField id="outlined-basic" placeholder='Enter account title'
                                                variant="outlined" fullWidth size="small"
                                            /></Col>
                                    </Row> */}

                    <Row className="mt-3">
                      <Col sm={4}>
                        <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
                          Account number
                        </p>
                      </Col>
                      <Col sm={8}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Enter Account number"
                          variant="outlined"
                          fullWidth
                          size="small"
                          disabled
                          value={viewbank?.account_no}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    {/* <Row className='mt-3'>
                                        <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Confirm  Account number</p></Col>
                                        <Col sm={7}>
                                            <TextField id="outlined-basic" placeholder='Enter Confirm Account number'
                                                variant="outlined" fullWidth size="small"
                                            /></Col>
                                    </Row> */}
                    {/* <Row className='mt-3'>
                                        <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Tax payer id</p></Col>
                                        <Col sm={7}>
                                            <TextField id="outlined-basic" placeholder='Enter Tax payer id'
                                                variant="outlined" fullWidth size="small"
                                            /></Col>
                                    </Row> */}
                  </Container>
                </Col>
                <Col sm={3}></Col>
              </Row>
              <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                  <Modal.Title>
                    <span style={{ fontSize: "18px" }}>Add Bank Details</span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleSubmit}>
                    <Container>
                      <Row>
                        <Col sm={4}>
                          {" "}
                          <span style={{ fontSize: "16px" }}>Bank name</span>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            placeholder="Enter bank name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            required
                            id="form1"
                            name="bank_name"
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col sm={4}>
                          {" "}
                          <span style={{ fontSize: "16px" }}>IFCS Code</span>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                           
                            placeholder="Enter IFCS Code"
                            variant="outlined"
                            fullWidth
                            size="small"
                            required
                            id="form1"
                            name="ifsc"
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col sm={4}>
                          {" "}
                          <span style={{ fontSize: "16px" }}>
                            Account Holder Name
                          </span>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                      
                            placeholder="Enter Account Holder Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="ac_name"
                            required
                            id="form1"
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                      {/* <Row className='mt-3'>
                                        <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Account title</p></Col>
                                        <Col sm={7}>
                                            <TextField id="outlined-basic" placeholder='Enter account title'
                                                variant="outlined" fullWidth size="small"
                                            /></Col>
                                    </Row> */}

                      <Row className="mt-3">
                        <Col sm={4}>
                          {" "}
                          <span style={{ fontSize: "16px" }}>
                            Account number
                          </span>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                     type="number"
                            placeholder="Enter Account number"
                            variant="outlined"
                            fullWidth
                            required
                            id="form1"
                            size="small"
                            name="ac_no"
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                      {/* <Row className='mt-3'>
                                                <Col sm={4}> <span style={{ fontSize: '16px' }}>Confirm  Account number</span></Col>
                                                <Col sm={7}>
                                                    <TextField id="outlined-basic" placeholder='Enter Confirm Account number'
                                                        variant="outlined" fullWidth size="small"
                                                    /></Col>
                                            </Row> */}
                      {/* <Row className='mt-3'>
                                        <Col sm=11}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Tax payer id</p></Col>
                                       
                                    </Row> */}

                      <div className="mt-4">
                      <Button
                        style={{ float: "left", marginLeft: "5%" }}
                        className="w-25"
                        variant="secondary"
                        onClick={() => handleClose()}
                      >
                        Close
                      </Button>
                      <Button
                        style={{
                          float: "right",
                          borderStyle: "none",
                          marginRight: "5%",
                        }}
                        className="btn submitButtons w-25"
                        type="submit"
                        variant="primary"
                      >
                        Submit
                      </Button>
                      </div>
                    </Container>
                    {/* <div className='d-flex align-items-center justify-content-end gap-2 mt-2'>
                                            <Button type="submit"
                                                style={{ backgroundColor: '#18285a' }}
                                                className='w-25'
                                            // style={{
                                            //     width: "116px",
                                            //     borderRadius: " 25px",
                                            //     padding: "10px",
                                            //     position: 'relative', float: "right",
                                            //     backgroundColor: '#18285a', color: 'white',
                                            //     marginTop: '20px', marginBottom: '20px'

                                            // }}  
                                            > submit</Button>
                                            <Button className='w-25' style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => handleClose()} > Close</Button>
                                        </div> */}
                    {/* <button variant="primary" className='btn  mt-3 mb-3' type='submit' style={{ float: 'right', backgroundColor: '#18285a', color: 'white', marginRight: '50px' }}>
                                            Save
                                        </button> */}
                  </form>
                  {/* <button type='submit' className='btn btn-secondary mt-3 mb-3' onClick={handleClose} style={{ float: 'left' }}>Close</button> */}
                </Modal.Body>
              </Modal>
            </Container>
          </Card>
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Bank;
