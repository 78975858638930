import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import moment from "moment";
import DataTable from "../components/Tables/Tables";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import {
  fetchData,
  setFilterData,
  handleSubmit,
  onMarkPaid,
  getdetailssal,
  onMarkPaids,
} from "../store/silce/payroll";
import Loader from "../components/Common/Loading";
import { closeEditModal, openEditModal } from "../store/silce/modalSlice";
import CommonEditModal from "../components/Common/CommonEditModal";
import { clearSelectedData } from "../store/silce/roll";
ChartJS.register(ArcElement, Tooltip, Legend);
const Payroll = () => {

  const [assingeemp, setAssingeemp] = useState(null);
  const [data, setData] = useState(null);
  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState(currentDate);

  console.log("assingeempassingeemp", assingeemp)
  const navLoading = useSelector((state) => state.dashboard.navLoading)
  const [showunassingeemp, setShowunassingeemp] = useState(false);
  const handleCloseunassingeemp = () => setShowunassingeemp(false);
  const handleShowunassingeemp = () => setShowunassingeemp(true);
  const payrollList = useSelector((state) => {
    console.log(state?.payroll?.updatedData?.data, "payrollList");
    return state?.payroll?.updatedData?.data
  });
  const Summary = useSelector((state) => {
    console.log(state?.payroll?.paysummary?.data?.data, "1111paly");
    return state?.payroll?.paysummary?.data?.data
  });
  const navigate = useNavigate();
  // const markaPayid = (sal_id) => {
  //   dispatch(onMarkPaid({ sal_id: sal_id }));
  // };
  const busid = useSelector(state => state?.selectOption?.value)
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      // width: '6px'
    },
    {
      name: "Staff ID",
      selector: (row) => row.emp_code,
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
    },
    {
      name: "Paid",
      selector: (row) => row.paid,
    },
    {
      name: "Due",
      selector: (row) => row.due,
    },
    {
      name: "Advance",
      selector: (row) => row.advance,
    },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            // onClick={() => markaPayid(row.sal_id)}
            onClick={() => {
              dispatch(openEditModal('Mark-Paid'))
              setAssingeemp(row.sal_id)
            }}

            // onDeleteClick={() => onDELETE(row.holidayId)}
            isView={true}
            markPaid={true}
            openViewClick={() => {
              setAssingeemp(row.sal_id);
              handleShowunassingeemp();
              dispatch(getdetailssal({ sal_id: row.sal_id }))
            }}

            page={"holidays"}
            id={row.id}
          />
        );
      },
    },

  ];
  
  const columns1 = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      // width: '6px'
    },
    {
      name: "Salary Remark",
      selector: (row) => row.salary_remark,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Comment",
      selector: (row) => row.comment,
    },
  ];

  const payroll_type = [
    {
      id: 1,
      title: "Advance",
      total: payrollList?.paid?.advance ? payrollList?.paid?.advance : '0',

    },
    {
      id: 2,
      title: "Paid",
      total: payrollList?.paid?.paid ? payrollList?.paid?.paid : '0',
    },
    {
      id: 3,

      title: "Due Employee",
      total: payrollList?.dues?.remaining ? payrollList?.dues?.remaining : '0',
    },
    {
      id: 4,
      total: payrollList?.dues?.employee ? payrollList?.dues?.employee : '0',
      title: "Paid Employee",
    },
  ];

  const [formDataNew, setFormDataNew] = useState({
    data_dt: "",
  });

  const handleChange = (event, userTime) => {
    setFormDataNew({
      ...formDataNew,
      [event.target.name]: event.target.value,
    });
  };



  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData());
  }, [busid]);


  const showEditModal = useSelector((state) => state.modal.showEditModal);

  const filterdData = useSelector((state) => {
    return state.payroll?.filterdData;
  });

  const loading = useSelector((state) => {
    return state.payroll.loading;
  });
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const formattedDate = moment(selectedMonth).format("DD-MM-YYYY");
  useEffect(() => {
    // Make your API call here with the selectedMonth
    dispatch(handleSubmit({ formattedDate: formattedDate }));


  }, [formattedDate]);

  function getCurrentMonth() {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Adjusting month to be 2 digits
    const year = now.getFullYear();
    return `${year}-${month}`;
  }

  const handleSubmit2 = (event) => {
    event.preventDefault();
    dispatch(onMarkPaids({ event: event, sal_id: assingeemp }))
    dispatch(handleSubmit({ formattedDate: formattedDate }));
    dispatch(closeEditModal())
  }
 
  // console.log("Paid Leave Salary Objects:", paidLeaveSalaryObjects?.[0]?.amount);
  const formdata = moment(selectedMonth).format("YYYY-MM")
  console.log(formdata, 'formdataformdata')

  function findObjectsBySalaryRemark(array, targetValue) {
    return array.filter(obj => obj["salary_remark"] === targetValue);
  }
  // let paidLeaveSalaryObjects, netPaySalaryObjects;
  if (Summary !== undefined) {
    var paidLeaveSalaryObjects = findObjectsBySalaryRemark(Summary, 'Monthly Salary');
    var netPaySalaryObjects = findObjectsBySalaryRemark(Summary, 'Net Pay Salary');
    var netPaydeeObjects = findObjectsBySalaryRemark(Summary, 'Due Salary To Pay');
 
  } else {
    console.log("Summary is undefined. Handle this case accordingly.");
  }
  console.log("Paid123456", paidLeaveSalaryObjects?.[0]?.amount);
  console.log("Net Pay Salary Objects:", netPaySalaryObjects?.[0]?.amount);
  const pieData = {
  
    datasets: [
      {
        label: "Pay Role",
        data: [
          netPaySalaryObjects?.[0]?.amount ?  netPaySalaryObjects?.[0]?.amount : "0",
          paidLeaveSalaryObjects?.[0]?.amount ? paidLeaveSalaryObjects?.[0]?.amount : "0",
          // attendance?.paid_holiday ? attendance?.paid_holiday : "0",
          // attendance?.late_entry ? attendance?.late_entry : "0",
          netPaydeeObjects?.[0]?.amount ? netPaydeeObjects?.[0]?.amount : "0",
        ],
        backgroundColor: [
          "#F7464A",
          "#46BFBD",
          "#4D5360",
        ],
        hoverBackgroundColor: [
          "#FF5A5E",
          "#5AD3D1",
          "#616774",
        ],
        borderWidth: 1,
      },
    ],
    labels: ["Salary ", "Net Pay","Due Salary"],
  };
  const chartOptions = {
    maintainAspectRatio: false, // Set to false to adjust width and height independently
    responsive: true,
  };
  return (
    <>
      <Container fluid>
        <Row>
          <div>
            <form >
              <h4 style={{ display: "inline" }}>Salary</h4>
              <input
                type="month"
                palc
                name="data_dt"
                value={formdata}
                onChange={handleMonthChange}
                id=""
                style={{
                  height: "38px",
                  width: "150px",
                  position: "relative",
                  marginLeft: "12px",
                  borderRadius: '7px',
                  // padding:'8px',
                }}
              />
              {/* <button
                onClick={handleSubmit1}
                type=" submit"
                className="btn btn-primary"
                style={{
                  position: "relative",
                  float: "right",
                  backgroundColor: "#18285a",
                }}
              >
                Show Salary
              </button> */}
            </form>
          </div>
        </Row>

        <Row xs={1} md={4} className="g-4 mt-1">
          {payroll_type.map((ele, idx) => (
            <Col key={idx}>
              <Card
                style={{
                  border: "none",
                  "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                }}
                className="text-center"
              >
                <Card.Body>
                  <Card.Title>{navLoading ? <Loader /> : ele.total}</Card.Title>
                  <Card.Text>{ele.title}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <div
              className="card mb-4 mt-3"
              style={{
                "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
              }}
            >
              <DataTable
                loading={loading}
                columns={columns}
                data={payrollList?.emp}
                title={"Payroll List"}
                page={"payroll"}
                searchData={payrollList}
                setData={setFilterData}
                keys={["name", "designation", "emp_code"]}
                filterdData={filterdData}
              />
            </div>
          </Col>
        </Row>
        <CommonEditModal
          onSubmit={handleSubmit2}
          show={showEditModal} name={'Mark-Paid'} title={'Mark-Paid'} onHide={() => {
            dispatch(closeEditModal())
            dispatch(clearSelectedData())
          }} />
        {/* { Summary.lenght === 0 || Summary === null ? (<>
                    <p>No Summary  Available</p></>) :(<> */}
        <Modal show={showunassingeemp} onHide={handleCloseunassingeemp} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Salary Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '400px', minHeight: "auto", overflowY: 'scroll' }}>
            <Container>
              <Row>
              <Col  sm={7}>
                  <Card style={{  }} >
                    {Summary && Summary.map((details) =>
                   
                      <>
                        <span className="m-2">

                          <span style={{ fontSize: "15px" }}>{details?.salary_remark}</span>  <span style={{ float: 'right' }}> ₹ {details?.amount}
                            {details?.type === "Due" ? (<span className="samll " style={{ fontSize: "10px" }}>{details?.type}</span>) : (<></>)}
                            {details?.type === "Credit" ? (<span className="samll text-success" style={{ fontSize: "10px" }}>{details?.type}</span>) : (<></>)}
                            {details?.type === "Debit" ? (<span className="samll text-danger" style={{ fontSize: "10px" }}>{details?.type}</span>) : (<></>)}
                            {details?.type === "Total" ? (<span className="samll " style={{ fontSize: "10px" }}>{details?.type}</span>) : (<></>)}
                          </span></span>
                      </>
                    )}
                  </Card>
                </Col>
                <Col sm={5} >
                  <Card.Subtitle className="mb-2 text-muted"> </Card.Subtitle>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Pie data={pieData} options={chartOptions}  />
                   
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: "20px", alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', whiteSpace: 'nowrap' }}>
                      <h6>Paid Leave Salary <span> ₹  {paidLeaveSalaryObjects?.[0]?.amount}</span></h6>
                      <h6>Net Pay Salary <span> ₹ { netPaySalaryObjects?.[0]?.amount}</span></h6>
                      <h6>Due Salary To Pay <span> ₹ {netPaydeeObjects?.[0]?.amount}</span></h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>



          </Modal.Body>
        </Modal>
        {/* </>)} */}

      </Container>


    </>
  );
};
export default Payroll;
