import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import { Container, Row, Col, Card } from 'react-bootstrap';
import AttModal from '../Model/Att.modal';
import DataTable from 'react-data-table-component';
const Allattendance = () => {
    const [toadayatt, setToadayatt] = useState([]);

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("emp_att_list", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        AttModal.allatt(formdata).then((res) => {
          
            setToadayatt(res.data)

        }
        ).catch((error) => {
            
        })
    }, [])
    return (
        <>
        
                    <Container fluid >
                        <Row>
                            <Col>
                                <Card>
                                    <p><b>Absent</b>:{toadayatt.absent}</p>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <p><b>Present</b>:{toadayatt.present}</p>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <p><b>Paid_Holiday</b>:{toadayatt.paid_holiday}</p>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <p><b>Late_Entry</b>:{toadayatt.late_entry}</p>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            {
                                toadayatt.emp && toadayatt.emp.map((todaylist,i) =>
                                    <Col key={i} sm={4}>


                                        <div>
                                            <Card>
                                                <p><b>Name:</b> {todaylist.name}</p>
                                                <p><b>Designation</b>:{todaylist.designation}</p>
                                                <p><b>Time in</b> :{todaylist.in_tim}</p>
                                                <p><b>Comment</b>{todaylist.out_comment}</p>
                                                <p><b>Time Out</b>{todaylist.out_tim}</p>
                                                <img src={`https://qrstaff.in/api/pro_pic/${todaylist.pro_img}`} alt="Avatar" className="m-1" style={{ width: "100px", height: '100px', borderRadius: "10px" }} />
                                            </Card>

                                        </div>

                                    </Col>
                                )
                            }
                        </Row>
                    </Container>

        </>
    )
}
export default Allattendance;