import React, { useEffect, useState, useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import employeeModel from "../../../Model/employee.Model";
import { Link } from "react-router-dom";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBProgressBar,
  MDBProgress,
  MDBIcon,
  MDBListGroupItem,
  MDBListGroup,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCardHeader,
  MDBTypography,
} from "mdb-react-ui-kit";
// import Persoanltab from '.././personaltab';
// import Persoanlbustab from './personalbustab';
import Address from "../Address";
import Contact from "../Contact";
import { editEmployee } from "../../../store/silce/staffSlice";
import { EmpHomeData } from "../../../store/silce/Employee";
import EmpPersoanlTab from "./EmpPersoanlTabBasic";
import EmpPersoanlDetails from "./EmpPersoanlDetailsTab";
import Document from "../../staffview/Document";
import EmpDocument from "./EmpDocument";
import EmpAddress from "./EmpAddress";
import EmpContact from "./EmpContact";
import EmpNomineeDetails from "./EmpNomineeDetails";

const EmpPersoanl = () => {
  const params = useParams();
  const [empprivew, setEmpPrivew] = useState();
  const dispatch = useDispatch();
  const employeeDetails = useSelector((state) => state?.staff?.employeeDetails);

  // console.log(employeeDetails,'sdjjhfbdfd')
  const employeeDetailss = useSelector(
    (state) => state?.employee?.Employee?.data
  );
  // console.log(employeeDetailss ,'Empdata')

  useEffect(() => {
    dispatch(EmpHomeData());
  }, []);

  const fetchData = () => {
    console.log(params, "data");
    const formdata = new FormData();
    formdata.append("edit_emp", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("emp_id", params?.id);
    dispatch(editEmployee({ id: params?.id }));
    // console.log()
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Tabs
        defaultActiveKey="basic"
        id="fill-tab-example"
        className="mb-3 mt-3"
        variant="pills"
        fill
      >
        <Tab eventKey="basic" title="Basic Details">
          {/* <Persoanltab  data={employeeDetailss}/> */}
          <EmpPersoanlTab data={employeeDetailss} />
        </Tab>
        <Tab eventKey="address" title="Address Details">
          <EmpAddress data={employeeDetails} />
        </Tab>
        <Tab eventKey="contact" title="Emergency contact">
          <EmpContact data={employeeDetails} />
        </Tab>
        <Tab eventKey="nomineedetails" title="Nominee Details">
          <EmpNomineeDetails data={employeeDetails} />
        </Tab>
        <Tab eventKey="comapny" title="Professional Details">
          <EmpPersoanlDetails data={employeeDetailss} />
        </Tab>
        <Tab eventKey="document" title="Document">
          {/* <Document  data={employeeDetails}/> */}
          <EmpDocument />
        </Tab>
      </Tabs>
    </>
  );
};

export default EmpPersoanl;
