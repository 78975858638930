import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import { Container, Row, Col } from "react-bootstrap";
import DataTable from "../components/Tables/Tables";
import Modal from "react-bootstrap/Modal";
import employeeModel from "../Model/employee.Model";
import AttModal from "../Model/Att.modal";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, setFilterData } from "../store/silce/autopunchout";
import {  removeEmployee } from '../store/silce/staffSlice';
import { helper } from '../lib/helper';

const Autopunch = () => {
  const busid = useSelector(state =>state?.selectOption?.value)
  const [emplist, setepmlist] = useState();
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [outid, setOutid] = useState(0);
  const navigate = useNavigate();
  const openCheckoutModal = (id) => {
    setOutid(id);
    handleShow2(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  }, [busid]);

  const autopunchoutList = useSelector((state) => {
    return state?.autopunchout.businesses?.data?.emp;
  });
  const filterdData = useSelector((state) => {
    return state?.autopunchout?.filterdData;
  });

  const loading = useSelector((state) => {
    return state.autopunchout.loading;
  });

  const getEmployees = () => {
    if (localStorage.getItem("token") && localStorage.getItem("selecteoption")) {

        dispatch(fetchData())
      
    }
}
useEffect(() => {
  dispatch(fetchData());
}, [busid]);
// useEffect(() => {
//     dispatch(fetchData())
//     // setFilterstaff(employees?.data?.emp)
// }, [busid]);
  const onDELETE = (id) => {
    helper.sweetalert.confirm('Are you sure?', "You won't be able to revert this!", "warning", true).then((result) => {
        if (result.isConfirmed) {
            dispatch(removeEmployee({id:id})).then(()=>(
                helper.sweetalert.toast("Deleted", 'Your file has been deleted.', 'success'),
                getEmployees()
            ))
        }
    })
}

  const columns = [
    {
      name: "Profile Image",
      selector: (row) => row.pro_img,
      cell: (row) => {
        return (
          <img
            src={`http://qrstaff.in/api/pro_pic/${row.pro_img}`}
            alt="Avatar"
            className="m-1"
            style={{ width: "50px", height: "50px", borderRadius: "10px" }}
          />
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
    },
    {
      name: "Auto Punch Out",
      selector: (row) => row.auto_punchout,
    },
    {
      name: "Maximum late time",
      cell: (row) => (
        <div>
          <span>{row.auto_punchout_min} min</span>
        </div>
      ),
    },

    {
      name: "Action",
      cell: (row) => {
        return (
          <TableDropdown
            openEditModal={() => navigate(`/Update/${row.id}`)}
            isRemove={true}
            isEdit={true}
            page={"AutoPunchOut"}
            id={row.id}
            onDeleteClick={() => (onDELETE(row.id))}
            onClick={() => (onDELETE(row.id))}
          />
        );
      },
    },
  ];
  const [formDataNew, setFormDataNew] = useState({
    emp_ids: "",
  });
  const handleChange = (event, userTime) => {
    setFormDataNew({
      ...formDataNew,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formdata = new FormData(event.target);
    console.log(event?.target?.roleId?.value);
    formdata.append("update_auto_punchout", localStorage.getItem("token"));
    // formdata.append("emp_ids", localStorage.getItem("selecteoption"));

    AttModal.Autopunch(formdata)
      .then((res) => {
        console.log(res.data);

        // setepmlist(res.data)
        alert("Assing Roll");
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  // useEffect(() => {
  //   fetchData();
  // }, []);
  return (
    <>
      {/* <Container fluid>
        <Row>
          <Col>
            <div
              style={{
                "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
              }}
              className=" mt-3 mb-4"
            >
              <DataTable
                title={"Auto Punch Out List"}
                columns={columns}
                data={emplist}
                pagination
                fixedHeader
                selectableRowsHighlight
                highlightOnHover
              />
            </div>
          </Col>
        </Row>
        <Modal show={show2} onHide={() => handleClose2(emplist.emp_id)}>
          <Modal.Header closeButton>
            <Modal.Title>Punch out update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form onSubmit={handleSubmit}>
                <input
                  type="hidden"
                  name="emp_ids"
                  id=""
                  value={outid}
                  onChange={handleChange}
                />
                <span style={{ fontSize: "20px" }}>Enable Auto Punch Out</span>
                <input
                  type="checkbox"
                  name="allow_auto_punchout"
                  id=""
                  value="true"
                />
                <br />
                <span>Select Auto Punch Out Time</span>
                <select name="auto_punchout_min" id="">
                  <option value="5">5 Minutes</option>
                  <option value="10">10 Minutes</option>
                  <option value="15">15 Minutes</option>
                  <option value="20">20 Minutes</option>
                  <option value="25">25 Minutes</option>
                  <option value="30">30 Minutes</option>
                  <option value="35">35 Minutes</option>
                  <option value="40">40 Minutes</option>
                  <option value="45">45 Minutes</option>
                </select>

                <button
                  className="btn btn-primary mt-4 mb-4"
                  style={{ float: "right" }}
                >
                  submit
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </Container> */}
      <Container fluid>
        <Row>
          <Col>
            <div
              style={{
                "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
              }}
              className=" mt-2 mb-4"
            >
              <DataTable
                loading={loading}
                columns={columns}
                data={autopunchoutList}
                title={"Auto-Punch-Out List"}
                setData={setFilterData}
                keys={["name", "designation", "emp_code"]}
                filterdData={filterdData}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Autopunch;
