import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { IoIosNotifications } from "react-icons/io";
import { HiMoon } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { changeBusiness } from "../../store/silce/bussiness";
import { fetchAttendance, fetchHome } from "../../store/silce/dashboardSlice";
import "./navbar.css";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import auth from "../../Model/profile.model";
import businessModel from "../../Model/business.model";
import { EmpHomeData } from "../../store/silce/Employee";

const NavbarEmp = ({ show1, setshow1, toggleButton, setToggleButton }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayprofile, setdisplayprofile] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profileadmin, setProfileAdmin] = useState([]);
  const [businessId, setBusinessId] = useState("");
  const updateBusinessId = (newId) => {
    setBusinessId(newId);
  };

  const employeeDetails = useSelector(
    (state) => state?.employee?.Employee?.data?.showAdminToggle
  );
  const employeeDetailss = useSelector(
    (state) => state?.employee?.Employee?.data
  );

  

  useEffect(() => {
    dispatch(EmpHomeData());
  }, []);
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("get_admin_profile", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    auth
      .profile(formdata)
      .then((res) => {
        
        setProfileAdmin(res.data);
      })
      .catch((error) => {
   
      });
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  

  const logoutApp = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const home = useSelector((state) => {
    return state.dashboard.home;
  });

  const loadHome = () => {
    const formdata = new FormData();
    formdata.append("emp_att_list", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    dispatch(fetchAttendance(formdata)).then(() => setIsLoading(false));
  };

  const fetchDataBasedOnBusiness = (selectedValue) => {
    const formdata = new FormData();
    formdata.append("change_site", localStorage.getItem("token"));
    formdata.append("def_bus", selectedValue);
    dispatch(changeBusiness(formdata));
    // setupdateBusinessId(selectedValue);
    window.localStorage.setItem("selecteoption", selectedValue);
    loadHome();
  };

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("get_home", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    dispatch(fetchHome(formdata));
    setdisplayprofile(home?.data);
    loadHome();
  }, []);

 

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 900 && isDropdownOpen) {
      setIsDropdownOpen(false); // Close the dropdown when screen width is greater than 900
    }
  }, [screenWidth]);

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("get_home", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));

    // Trigger the fetchDataBasedOnBusiness function with the default selection.
    fetchDataBasedOnBusiness(localStorage.getItem("selecteoption"));

    dispatch(fetchHome(formdata));
    setdisplayprofile(home?.data);
    loadHome();
  }, []);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;

    try {
      const formdata = new FormData();
      formdata.append("change_site", localStorage.getItem("token"));
      formdata.append("def_bus", selectedValue);
      businessModel.ChangeDefaultBusiness(formdata).then((res) => {
        updateBusinessId(selectedValue);
        localStorage.setItem("selecteoption", selectedValue);
      });
    } catch (error) {
     
    }
  };

  const handleSwitchChange = () => {
    setToggleButton(!toggleButton); // Toggle the state when the switch is changed
  };

 
  return (
    <>
      <header className={`header ${show1 ? "space-toggle" : null}`}>
        <div className="header-toggle" onClick={() => setshow1(!show1)}>
          <i
            className={`fas fa-bars ${show1 ? "fa-solid fa-xmark" : null}`}
          ></i>
        </div>
        <>
          <Nav className="justify-content-end flex-grow-1 pe-4">
            {/* <div className='line mt-4'>
                            <h2 className='pop-outin mt-4'>text pop-out-in</h2>
                        </div>                         */}
            {/* <div className={`pe-2 ${screenWidth < 900 ? 'mt-2 w-25 pe-1' : 'mt-3'}`} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '135px', paddingLeft: screenWidth < 900 ? '20px' : '' }}>Change Buisness</div> */}
            {/* <Form.Select value={businessId} onChange={handleDropdownChange}  className={`${screenWidth < 900 ? 'w-75' : 'w-25'} pe-5 ${screenWidth < 900 ? 'mt-1' : 'mt-2'} drop-nav`}
                            aria-label="select example" >
                                {
                                    home?.data?.bussiness && home?.data?.bussiness.map((logo) => {
                                        if (businessId === "" && logo?.default === "true") {
                                            updateBusinessId(logo?.id)
                                            localStorage.setItem('selecteoption', logo?.id);
                                        }
                                        return (
                                            <option value={logo?.id} >

                                                <b style={{ padding: '15px' }}  > {logo.name}</b>
                                            </option>
                                        )
                                    })}
                            </Form.Select> */}
            {/* <Form> */}

            {/* </Form> */}
            {screenWidth < 900 ? (
              <div
                style={{ position: "absolute", bottom: "18px", right: "10px" }}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <BsThreeDotsVertical />
              </div>
            ) : (
              <>
                {/* <div className='pe-3 px-2 mt-2 drop-nav'>
                                        <IoIosNotifications style={{ fontSize: '32px' }} />
                                    </div>
                                    <div className='pe-3 mt-2 drop-nav'>
                                        <HiMoon style={{ fontSize: '32px' }} />
                                    </div> */}

                {employeeDetails && (
                  <div className="pe-1 mt-3">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip  id="tooltip-bottom" > { toggleButton === true ?"Swtich to Staff" :"Swtich To Admin"}</Tooltip>
                      }
                    >
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        style={{ fontSize: "20px" }}
                        checked={toggleButton}
                        onChange={handleSwitchChange}
                        //   label="Check this switch"
                      />
                    </OverlayTrigger>
                  </div>
                )}
                <div className="pe-1 mt-3">
                  <p className="fw-bold">{employeeDetailss?.name}</p>
                </div>
                <div
                  className="pe-3 mt-2"
                  //   onClick={() => {
                  //     navigate("/profile");
                  //   }}
                >
                  <img
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "35px",
                    }}
                    src={employeeDetailss?.profile_imgUrl}
                  />
                </div>
                <div className="mt-2">
                  <Button
                    className="logout_btn"
                    onClick={() => {
                      logoutApp();
                    }}
                  >
                    Logout
                  </Button>
                </div>
              </>
            )}
          </Nav>
          {isDropdownOpen && (
            <ul
              className={`list-group listStyle ${screenWidth < 321 && "w-50"}`}
            >
              <li
                className="list-group-item"
                onClick={() => setIsDropdownOpen(false)}
              >
                <IoIosNotifications style={{ fontSize: "32px" }} />
              </li>
              <li
                className="list-group-item"
                onClick={() => setIsDropdownOpen(false)}
              >
                <HiMoon style={{ fontSize: "32px" }} />
              </li>
              <li className="list-group-item">
                <div className="d-flex">
                  <div className="pe-1 mt-3">
                    <p>{profileadmin?.profile?.name}</p>
                  </div>
                  <div className="pe-3 mt-3">
                    <img
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "35px",
                      }}
                      src={profileadmin?.profile?.profile_img_url}
                    />
                  </div>
                </div>
              </li>
              <li className="list-group-item">
                <Button
                  style={{ backgroundColor: "#18285a" }}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    logoutApp();
                  }}
                  className="w-100"
                >
                  Logout
                </Button>
              </li>
            </ul>
          )}
        </>
      </header>
    </>
  );
};

export default NavbarEmp;
