// import React, { useEffect, useState } from 'react';
// import { Badge, Button, Dropdown, Form, Nav } from 'react-bootstrap';
// import { IoIosNotifications } from 'react-icons/io';
// import { HiMoon } from 'react-icons/hi';
// import { useDispatch, useSelector } from 'react-redux';
// import { changeBusiness } from '../../store/silce/bussiness';
// import { fetchAttendance, fetchHome } from '../../store/silce/dashboardSlice';
// import './navbar.css'
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import { BsThreeDotsVertical } from 'react-icons/bs'
// import { useNavigate } from 'react-router-dom';
// import auth from '../../Model/profile.model';
// import businessModel from '../../Model/business.model';
// import { MdOutlineLogout } from "react-icons/md";
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
// import { updateSelectOption, selectOptionSelector } from '../../store/silce/selectOptionSlice';

// const Navbar = ({ show1, setshow1 }) => {
//     const dispatch = useDispatch()
//     const navigate = useNavigate()
//     const [displayprofile, setdisplayprofile] = useState([]);
//     const [isNavOpen, setIsNavOpen] = useState(false);
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [isLoading, setIsLoading] = useState(true)
//     const [profileadmin, setProfileAdmin] = useState([]);
//     const [businessId, setBusinessId] = useState('')
//     const selectOptionValue = useSelector(selectOptionSelector);

//     const updateBusinessId = (newId) => {
//         setBusinessId(newId);
//     };
//     useEffect(() => {
//         const formdata = new FormData();
//         formdata.append("get_admin_profile", localStorage.getItem("token"));
//         formdata.append("bus_id", localStorage.getItem("selecteoption"));
//         auth.profile(formdata).then((res) => {
//             console.log(res.data)
//             setProfileAdmin(res.data)
//         }).catch((error) => {
//             console.log("error => ", error)
//         })
//     }, []);

//     const toggleNav = () => {
//         setIsNavOpen(!isNavOpen);
//     };

//     console.log("profileadmin", profileadmin)

//     const logoutApp = () => {
//         localStorage.removeItem("token")
//         navigate('/')
//     }

//     const home = useSelector((state) => {
//         return state?.dashboard?.home?.data
//     })
//     console.log(home, "employeesDashboardss");
//     const loadHome = () => {
//         const formdata = new FormData();
//         formdata.append("emp_att_list", localStorage.getItem("token"));
//         formdata.append("bus_id", localStorage.getItem("selecteoption"));
//         dispatch(fetchAttendance(formdata)).then(() => setIsLoading(false))
//     }
//     const fetchDataBasedOnBusiness = (selectedValue) => {
//         const formdata = new FormData();
//         formdata.append("change_site", localStorage.getItem("token"));
//         formdata.append("def_bus", selectedValue);
//         dispatch(changeBusiness(formdata));
//         window.localStorage.setItem('selecteoption', selectedValue);
//         loadHome();
//     };
//     useEffect(() => {
//         const formdata = new FormData();
//         formdata.append("get_home", localStorage.getItem("token"));
//         formdata.append("bus_id", localStorage.getItem("selecteoption"));
//         dispatch(fetchHome(formdata))
//         setdisplayprofile(home?.data)
//         loadHome()
//     }, [])

//     console.log({ home: home, businessId: businessId, displayprofile: displayprofile })

//     const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//     const updateScreenWidth = () => {
//         setScreenWidth(window.innerWidth);
//     };
//     useEffect(() => {
//         window.addEventListener('resize', updateScreenWidth);
//         return () => {
//             window.removeEventListener('resize', updateScreenWidth);
//         };
//     }, []);

//     useEffect(() => {
//         if (screenWidth > 900 && isDropdownOpen) {
//             setIsDropdownOpen(false); // Close the dropdown when screen width is greater than 900
//         }
//     }, [screenWidth]);

//     useEffect(() => {
//         const formdata = new FormData();
//         formdata.append("get_home", localStorage.getItem("token"));
//         formdata.append("bus_id", localStorage.getItem("selecteoption"));

//         // Trigger the fetchDataBasedOnBusiness function with the default selection.
//         fetchDataBasedOnBusiness(localStorage.getItem("selecteoption"));

//         dispatch(fetchHome(formdata));
//         setdisplayprofile(home?.data);
//         loadHome();
//     }, []);



//     const handleDropdownChange = (event) => {
//         const selectedValue = event.target.value;
//         dispatch(updateSelectOption(selectedValue));
//         try {
//             const formdata = new FormData();
//             formdata.append("change_site", localStorage.getItem("token"));
//             formdata.append("def_bus", selectedValue);
//             businessModel.ChangeDefaultBusiness(formdata).then((res) => {
//                 updateBusinessId(selectedValue);
//                 localStorage.setItem('selecteoption', selectedValue);
//             });
//         } catch (error) {
//             console.log("setting default business error", error?.message);
//         }
//     };

//     return (
//         <>
//             <header className={`header ${show1 ? 'space-toggle' : null}`}>
//                 <div className='header-toggle' onClick={() => setshow1(!show1)}>
//                     <i className={`fas fa-bars ${show1 ? 'fa-solid fa-xmark' : null}`}></i>
//                 </div>
//                 <>
//                     <Nav className="justify-content-end flex-grow-1 pe-4">
//                         {/* <div className='line mt-4'>
//                             <h2 className='pop-outin mt-4'>text pop-out-in</h2>
//                         </div>                         */}
//                         <div className={`pe-2 ${screenWidth < 900 ? 'mt-2 w-25 pe-1' : 'mt-3'}`} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '135px', paddingLeft: screenWidth < 900 ? '20px' : '' }}>Change Buisness</div>
//                             <Form.Select value={businessId} onChange={handleDropdownChange}  className={`${screenWidth < 900 ? 'w-75' : 'w-25'} pe-5 ${screenWidth < 900 ? 'mt-1' : 'mt-2'} drop-nav`}
//                             aria-label="select example" >
//                                 {
//                                     home?.bussiness && home?.bussiness.map((logo) => {
//                                         if (businessId === ''  && businessId === null && logo?.default === "true"   ) {
//                                             updateBusinessId(logo?.id)
//                                             console.log("newdataget")
//                                             localStorage.setItem('selecteoption', logo?.id);
//                                         }
//                                         return (
//                                             <option value={logo?.id}  selected={logo?.id === localStorage.getItem('selecteoption') }>
//                                                 <b style={{ padding: '15px' }}  > {logo.name}</b>
//                                             </option>
//                                         )
//                                     })}
//                             </Form.Select>
//                         {
//                             screenWidth < 900 ?
//                                 <div style={{ position: 'absolute', bottom: '18px', right: '10px' }} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
//                                     <BsThreeDotsVertical />
//                                 </div>
//                                 :
//                                 <>

//                                     <div className='pe-3 px-2 mt-2 drop-nav'>
//                                         {/* <IoIosNotifications style={{ fontSize: '32px' }} /> */}
//                                     </div>
//                                     <div className='pe-3 mt-2 drop-nav'>
//                                         {/* <HiMoon style={{ fontSize: '32px' }} /> */}
//                                     </div>
//                                     <div className='pe-3 mt-2' onClick={() => {
//                                         navigate('/profile')
//                                     }}>
//                                         <img style={{ width: '35px', height: '35px', borderRadius: '35px' }} src={profileadmin?.profile?.profile_img_url} />
//                                     </div>
//                                     <div className='pe-1 mt-3' style={{marginRight:"15px"}}>
//                                         <p className='fw-bold'>{profileadmin?.profile?.name}</p>
//                                     </div>
                                    
//                                     <div className='mt-2'>
//                                     <OverlayTrigger
//                                         placement="bottom"
//                                         overlay={<Tooltip id="button-tooltip-2">Logout</Tooltip>}
//                                       >
//                                         <Button className='logout_btn' onClick={() => {
//                                             logoutApp()
//                                         }}>
                                            
//                                             <MdOutlineLogout />
//                                             </Button></OverlayTrigger>
//                                     </div>
//                                 </>
//                         }
//                     </Nav>
//                     {isDropdownOpen &&
//                         <ul className={`list-group listStyle ${screenWidth < 321 && 'w-50'}`}>
//                             <li className="list-group-item" onClick={() => setIsDropdownOpen(false)}>
//                                 <IoIosNotifications style={{ fontSize: '32px' }} />
//                             </li>
//                             <li className="list-group-item" onClick={() => setIsDropdownOpen(false)}>
//                                 <HiMoon style={{ fontSize: '32px' }} />
//                             </li>
//                             <li className="list-group-item">
//                                 <div className='d-flex'>
//                                     <div className='pe-1 mt-3'>
//                                         <p>{profileadmin?.profile?.name}</p>
//                                     </div>
//                                     <div className='pe-3 mt-3'>
//                                         <img style={{ width: '35px', height: '35px', borderRadius: '35px' }} src={profileadmin?.profile?.profile_img_url} />
//                                     </div>
//                                 </div>
//                             </li>
//                             <li className="list-group-item"  >
//                                 <Button style={{ backgroundColor: '#18285a' }} onClick={() => {
//                                     setIsDropdownOpen(false)
//                                     logoutApp()
//                                 }} className='w-100'>Logout</Button>
//                             </li>
//                         </ul>
//                     }
//                 </>
//             </header>
//         </>
//     );
// };

// export default Navbar;


import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Dropdown, Form, Modal, Nav } from 'react-bootstrap';
import { IoIosNotifications } from 'react-icons/io';
import { HiMoon } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { changeBusiness, fetchAllBusinesses } from '../../store/silce/bussiness';
import { fetchAllNotice, fetchAttendance, fetchHome, notification } from '../../store/silce/dashboardSlice';
import './navbar.css'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BsQrCode, BsThreeDotsVertical } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import auth from '../../Model/profile.model';
import businessModel from '../../Model/business.model';
import { MdOutlineLogout } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { updateSelectOption, selectOptionSelector } from '../../store/silce/selectOptionSlice';
import { nofetchAllEmployees } from '../../store/silce/staffSlice';
import { FaDownload } from 'react-icons/fa';
const Navbar = ({ show1, setshow1 }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [displayprofile, setdisplayprofile] = useState([]);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [profileadmin, setProfileAdmin] = useState([]);
    const [businessId, setBusinessId] = useState("");
    let getPagename = localStorage.getItem('pageName')
    const notificationlist = useSelector((state) => state?.dashboard?.notification?.notification)
    const downloadQrcode = useSelector((state) => state?.dashboard?.home?.data?.code)
    // console.log(downloadQrcode ,'downloadQrcode')
    const [shownotification, setShownotification] = useState(false);
    const handleClosenotification = () => setShownotification(false);
    const handleShownotification = () => setShownotification(true);
    const updateBusinessId = (newId) => {
        setBusinessId(newId);
    };
    const selectedPage = useSelector((state)=>state.sidebar.selectedPage)
    useEffect(() => {
        const formdata = new FormData();
        formdata.append("get_admin_profile", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        auth.profile(formdata).then((res) => {
            // console.log(res.data)
            setProfileAdmin(res.data)
        }).catch((error) => {
            // console.log("error => ", error)
        })
    }, []);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    // console.log("profileadmin", profileadmin)

    const logoutApp = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("pageName")
        localStorage.removeItem("selecteoption")
        localStorage.removeItem("selecteoptionName")
        navigate('/')
    }

    const home = useSelector((state) => {
        return state.dashboard.home
    })

    const loadHome = () => {
       
        dispatch(fetchAttendance()).then(() => setIsLoading(false))
    }

    const fetchDataBasedOnBusiness = (selectedValue) => {
        const formdata = new FormData();
        formdata.append("change_site", localStorage.getItem("token"));
        formdata.append("def_bus", selectedValue);
        dispatch(changeBusiness(formdata));
        // setupdateBusinessId(selectedValue);
        window.localStorage.setItem('selecteoption', selectedValue);
        setTimeout(()=>{
            // dispatch(fetchAttendance()).then(() => setIsLoading(false))
            // dispatch(nofetchAllEmployees().then(() => setIsLoading(false)))
        },3000)
    };


    useEffect(() => {
        const formdata = new FormData();
        formdata.append("get_home", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        dispatch(fetchHome(formdata))
        setdisplayprofile(home?.data)
        setTimeout(()=>{
            dispatch(fetchAttendance()).then(() => setIsLoading(false))
        
        },3000)
    }, [])

    // console.log({ home: home, businessId: businessId, displayprofile: displayprofile })

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', updateScreenWidth);
        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    useEffect(() => {
        if (screenWidth > 900 && isDropdownOpen) {
            setIsDropdownOpen(false); // Close the dropdown when screen width is greater than 900
        }
    }, [screenWidth]);



    const [BussinessName,setBusinessName] = useState('')
       const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedName = selectedOption.getAttribute('name');
        setBusinessName(selectedName)

        dispatch(updateSelectOption(selectedValue));
        try {
            const formdata = new FormData();
            formdata.append("change_site", localStorage.getItem("token"));
            formdata.append("def_bus", selectedValue);
            businessModel.ChangeDefaultBusiness(formdata).then((res) => {
                updateBusinessId(selectedValue);
                localStorage.setItem('selecteoption', selectedValue);
            });
        } catch (error) {
            console.log("setting default business error", error?.message);
        }
    };

const getNotification = () => {
    dispatch(notification());
        navigate('/Notification')
}
const viewQR =() => {
    window.open(downloadQrcode?.qrcode_image_link, '_blank');
}
const downlaodQR =() => {
    window.open(downloadQrcode?.qrcode_pdf, '_blank');
}

const [name ,setName] = useState('')
    return (
        <>
            <header className={`header ${show1 ? 'space-toggle' : null}`}>
                <div className='header-toggle' onClick={() => setshow1(!show1)}>
                    <i className={`fas fa-bars ${show1 ? 'fa-solid fa-xmark' : null}`}></i>
                </div>
                <>
                    <Nav className="justify-content-end flex-grow-1 pe-4">
                        {/* <div className='line mt-4'>
                            <h2 className='pop-outin mt-4'>text pop-out-in</h2>
                        </div>                         */}
                        <div className={`pe-2 ${screenWidth < 900 ? 'mt-2 w-25 pe-1' : 'mt-3'}`} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100px', paddingLeft: screenWidth < 900 ? '20px' : '' }}>Businesses</div>
                            <Form.Select value={businessId} onChange={handleDropdownChange}  className={`${screenWidth < 900 ? 'w-75' : 'w-25'} pe-5 ${screenWidth < 900 ? 'mt-1' : 'mt-2'} drop-nav`}
                            aria-label="select example" >
                                {
                                    home?.data?.bussiness && home?.data?.bussiness?.map((logo) => {
                                        if (businessId === "" && logo?.default === "true") {
                                            updateBusinessId(logo?.id)
                                            localStorage.setItem('selecteoption', logo?.id);
                                            localStorage.setItem('selecteoptionName', logo?.name);
                                            setName(logo?.name)
                                        }
                                        return (
                                            <option value={logo?.id} name={logo?.name}>
                                                <b style={{ padding: '15px' }}  > {logo.name}</b>
                                            </option>
                                        )
                                    })}
                            </Form.Select>
                        {
                            screenWidth < 900 ?
                                <div style={{ position: 'absolute', bottom: '18px', right: '10px' }} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                    <BsThreeDotsVertical />
                                </div>
                                :
                                <>
                                    <div className='pe-2 px-3 pt-1 mt-2 drop-nav'  style={{cursor:"pointer"}}>
                                        <BsQrCode style={{ fontSize: '25px' }}    onClick={handleShownotification} />
                                    </div>
                                    <div className='pe-2 mt-2 drop-nav' style={{cursor:"pointer"}}>
                                        <IoIosNotifications style={{ fontSize: '32px' }}  onClick={getNotification} />
                                    </div>
                                   
                                    <div className='pe-3 mt-2' onClick={() => {
                                        navigate('/profile')
                                    }} style={{cursor:"pointer"}}>
                                        <img style={{ width: '35px', height: '35px', borderRadius: '35px' }} src={profileadmin?.profile?.profile_img_url} />
                                    </div>
                                    <div className='pe-1 mt-3' style={{marginRight:"15px"}}>
                                        <p className='fw-bold'>{profileadmin?.profile?.name}</p>
                                    </div>
                                    
                                    <div className='mt-2'>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">Logout</Tooltip>}
                                      >
                                        <Button className='logout_btn' onClick={() => {
                                            logoutApp()
                                        }}>
                                            
                                            <MdOutlineLogout />
                                            </Button></OverlayTrigger>
                                    </div>
                                </>
                        }
                    </Nav>
                    {isDropdownOpen &&
                        <ul className={`list-group listStyle ${screenWidth < 321 && 'w-50'}`}>
                            <li className="list-group-item" onClick={() => setIsDropdownOpen(false)}>
                                <IoIosNotifications style={{ fontSize: '32px' }} />
                            </li>
                            <li className="list-group-item" onClick={() => setIsDropdownOpen(false)}>
                                <HiMoon style={{ fontSize: '32px' }} />
                            </li>
                            <li className="list-group-item">
                                <div className='d-flex'>
                                    <div className='pe-1 mt-3'>
                                        <p>{profileadmin?.profile?.name}</p>
                                    </div>
                                    <div className='pe-3 mt-3'>
                                        <img style={{ width: '35px', height: '35px', borderRadius: '35px' }} src={profileadmin?.profile?.profile_img_url} />
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item"  >
                                <Button style={{ backgroundColor: '#18285a' }} onClick={() => {
                                    setIsDropdownOpen(false)
                                    logoutApp()
                                }} className='w-100'>Logout</Button>
                            </li>
                        </ul>
                    }
                </>
                <Modal show={shownotification} onHide={handleClosenotification} size="sm">
            <Modal.Header closeButton>
              <Modal.Title style={{fontSize:"15px"}}>{BussinessName ?BussinessName :name} Download QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <img src={downloadQrcode?.qrcode_image_link} alt="" width={'100%'} />
                </div>  
                <hr /> 
              <div>
              <HiMiniViewfinderCircle style={{ fontSize: '32px' }}  onClick={viewQR}/>     
                <FaDownload style={{ fontSize: '32px',float:'right' }}    onClick={downlaodQR}/>
              </div>
     
            </Modal.Body>
          </Modal>
            </header>
        </>
    );
};

export default Navbar;
