import axiosInstance from "../lib/axiosInstance";
const Auth = class {
  
    async login(data = []) {
        // console.log(process.env.PUBLIC_API_URL);
        return await axiosInstance.post("/reg.php", data);
    }
    async otp(otp = []) {
        // console.log(process.env.PUBLIC_API_URL);
        return await axiosInstance.post("/reg.php", otp);
    }
    async home(home = []) {
        home["get_home"] = localStorage.getItem("token");
        home["bus_id"] = localStorage.getItem("selecteoption");

        // console.log(process.env.PUBLIC_API_URL);
         //axiosInstance.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("get_home");
        return await axiosInstance.post("/home.php", home);
    }
    
}
export default new Auth();