import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  Form,
} from "react-bootstrap";

import "../../../src/Aseset/css/salary.css";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBInput,
} from "mdb-react-ui-kit";
import salary from "../../Model/salary";
import { helper } from "../../lib/helper";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import "../../App.css";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
const Leavehistory = () => {
  const params = useParams();
  const [vewjob, setViewJob] = useState();
  const [show, setShow] = useState(false);
  const [EmgSingle, setEmgSingle] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedjon, setSelectedjoin] = useState("");
  const [selectedleave, setSelectedleave] = useState("");
  const [selectedOnboarding, setSelectedOnboarding] = useState("");

  const [formDataAdd, setFormDataNewAdd] = useState({
    cmp_name: "",
    designation: "",
    joining_comment: "",
    leaving_comment: "",
  });

  const [showModal1, setShowModal1] = useState(false);
  const handleClose1 = () => {
    setShowModal1(false);
    setEmgSingle("");
  };
  const handleShow1 = () => setShowModal1(true);
  const [id, setId] = useState();
  const toggleModal1 = (id) => {
    // displaydata(id);
    setShowModal1(!showModal1);
    setId(id);
  };

  const handleChanges = (event) => {
    setFormDataNewAdd({
      ...formDataAdd,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (event) => {
    const dateValue = event.target.value;
    setSelectedjoin(dateValue);
  };
  const handleLeaveChange = (event) => {
    const dateValue = event.target.value;
    setSelectedleave(dateValue);
  };

  const handleOnboardingChange = (event) => {
    const dateValue = event.target.value;
    setSelectedOnboarding(dateValue);
  };

  const Addjobhis = (event) => {
    const selectedJoinDate = moment(selectedjon).format("DD-MM-YYYY");
    const selectedLeaveDate = moment(selectedleave).format("DD-MM-YYYY");
    const selectedOnboardingDate =
      moment(selectedOnboarding).format("DD-MM-YYYY");
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("add_employment_history", localStorage.getItem("token"));
    form.append("emp_id", params?.id);
    form.append("joining_date", selectedJoinDate);
    form.append("leaving_date", selectedLeaveDate);
    form.append("onboarding_date", selectedOnboardingDate);
    salary.jobhistory(form).then((res) => {
      helper.sweetalert.toast("Job History is added .", "update.");
      handleClose();
      showJobhis();
    });
  };

  const [selectedjonupdate, setSelectedjoinupdate] = useState(
    moment(EmgSingle?.joining_date).format("MM-DD-YYYY")
  );
  const [selectedleavingupdate, setSelectedleavingupdate] = useState(
    moment(EmgSingle?.leaving_date).format("YYYY-MM-DD")
  );
  const [selectedOnboardingupdate, setSelectedOnboardingupdate] = useState(
    moment(EmgSingle?.onboarding_date).format("MM-DD-YYYY")
  );

  const handleDateChangeupdate = (event) => {
    const dateValue = event.target.value;
    setSelectedjoinupdate(dateValue);
  };
  const handleLeaveChangeupdate = (event) => {
    const dateValue = event.target.value;
    setSelectedleavingupdate(dateValue);
  };

  const handleOnboardingChangeupdate = (event) => {
    const dateValue = event.target.value;
    setSelectedOnboardingupdate(dateValue);
  };

  const updateJobHistory = (event) => {
    const selectedJoinDate = moment(selectedjonupdate).format("DD-MM-YYYY");
    const selectedLeaveDate = moment(selectedleavingupdate).format(
      "DD-MM-YYYY"
    );
    const selectedOnboardingDate = moment(selectedOnboardingupdate).format(
      "DD-MM-YYYY"
    );
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("update_employment_history", localStorage.getItem("token"));
    form.append("detail_id", id);
    form.append("joining_date", selectedJoinDate);
    form.append("leaving_date", selectedLeaveDate);
    form.append("onboarding_date", selectedOnboardingDate);
    salary.jobhistory(form).then((res) => {
      helper.sweetalert.toast("Job History is added .", "update.");
      handleClose1();
      setEmgSingle("");
      showJobhis();
    });
  };

  const showJobhis = () => {
    const form = new FormData();
    form.append("get_employment_history", localStorage.getItem("token"));
    form.append("emp_id", params?.id);
    salary.veiwjobhiss(form).then((res) => {
      setViewJob(res?.data?.data);
      console.log(res.data?.data);
    });
  };
  // const evenArray = vewjob.filter((_, index) => index % 2 !== 0);

  console.log(vewjob, "vewjob");
  useEffect(() => {
    showJobhis();
  }, []);

  const displaydata = async (id) => {
    const formdata = new FormData();
    formdata.append(
      "get_employment_history_detail",
      localStorage.getItem("token")
    );
    formdata.append("detail_id", id);
    salary
      .veiwjobhistorybyid(formdata)
      .then((res) => {
        console.log(res.data, "singh");
        setEmgSingle(res?.data?.data);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };

  const onDelete = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("delete_employment", localStorage.getItem("token"));
          formdata.append("history_id", id);
          salary.veiwjobhistorybyidDelete(formdata).then((res) => {
            console.log(res.data);
            helper.sweetalert.toast(
              "Deleted",
              "Your Job History has been deleted.",
              "success"
            );
            showJobhis();
            // handleClose1();
          });
        }
      });
  };

  console.log(selectedleavingupdate, "selectedleavingupdate");

  return (
    <MDBCard className="mt-3">
      <MDBCardHeader>
        <Row>
          <Col>
            <div style={{ position: "relative" }}>
              <span style={{ marginLeft: "10px", fontSize: "19px" }}>
                <>Job History</>
              </span>
              <button
                className="btn"
                onClick={handleShow}
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                }}
              >
                Add New
              </button>
            </div>
          </Col>
        </Row>
      </MDBCardHeader>
      <MDBCardBody style={{ height: "90vh", overflowY: "scroll" }}>
        <Container className="mt-3">
          {vewjob &&
            vewjob.map((list, index) => (
              <Container fluid className="mb-4">
                <hr style={{ border: "1px solid" }} />

                {/* <div> */}
                <Row>
                  <Row>
                    {/* Left Column */}
                    <Col sm={5}>
                      <p>
                        <strong>Company Name:</strong>
                      </p>
                      <p>
                        <strong>Designation:</strong>
                      </p>
                      <p>
                        <strong>Salary:</strong>
                      </p>
                      <p>
                        <strong>Joining Comment:</strong>
                      </p>
                      <p>
                        <strong>Joining Date:</strong>
                      </p>
                    </Col>

                    {/* Right Column */}
                    <Col sm={5}>
                      <p>{list.company_name}</p>
                      <p>{list.designation}</p>
                      <p>{list.salary}</p>
                      <p>{list.joining_comment}</p>
                      <p>
                        {list.joining_date
                          ? moment(list.joining_date).format("MMMM Do YYYY")
                          : "N/A"}
                      </p>
                    </Col>
                    <Col sm={2}>
                    <div className="d-flex align-items-end justify-content-start gap-3  flex-row  ms-1">
                      <Button
                        onClick={() => {
                          toggleModal1(list.history_id);
                          displaydata(list.history_id);
                        }}
                        className="w-0"
                        style={{ backgroundColor: "#18285a" }}
                        outline
                        color="dark"
                      >
                        <BiEdit />
                      </Button>
                      <Button
                        onClick={() => {
                          onDelete(list.history_id);
                        }}
                        className="w-0"
                        style={{ backgroundColor: "#18285a" }}
                        outline
                        color="dark"
                      >
                        <MdDeleteForever />
                      </Button>
                    </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* Left Column */}
                    <Col sm={5}>
                      <p>
                        <strong>Leaving Comment:</strong>
                      </p>
                      <p>
                        <strong>Leaving Date:</strong>
                      </p>
                      <p>
                        <strong>Onboarding Comment:</strong>
                      </p>
                      <p>
                        <strong>Onboarding Date:</strong>
                      </p>
                    </Col>

                    {/* Right Column */}
                    <Col sm={5}>
                      <p>{list.leaving_comment}</p>
                      <p>
                        {list.leaving_date}
                          
                      </p>
                      <p>{list.onboarding_comment}</p>
                      <p>
                        {list.onboarding_date
                          ? moment(list.onboarding_date).format("MMMM Do YYYY")
                          : "N/A"}
                      </p>
                    </Col>
                   
                  </Row>
                  <Col>
                   
                  </Col>
                </Row>
                {/* </div> */}
              </Container>
            ))}
          {/* </Container>
                    </Card> */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Employment History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <form onSubmit={Addjobhis}>
                  <Container>
                    <Row>
                      <Col sm={4}>
                        <Form.Label className="mt-2"> Name Company</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          placeholder="Enter Complany name"
                          type="text"
                          name="cmp_name"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2">Designation</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="designation"
                          placeholder="Enter Designation"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2">Salary</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="salary"
                          placeholder=" Enter salary"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">Joining Date</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="date"
                          name="joining_date"
                          onChange={handleDateChange}
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Joining Comment
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="joining_comment"
                          placeholder="Add Note "
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">Leaving Date</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="date"
                          name="leaving_date"
                          onChange={handleLeaveChange}
                          placeholder="Add Note "
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Leaving Comment
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="leaving_comment"
                          placeholder="Add Note "
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Onboarding Date
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="date"
                          name="onboarding_date"
                          onChange={handleOnboardingChange}
                          placeholder="Onboarding Comment"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Onboarding Comment
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="onboarding_comment"
                          placeholder="Onboarding Comment "
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>

                    <div className=" mt-2">
                      <Button
                        style={{ float: "left", marginLeft: "5%" }}
                        className="w-25"
                        variant="secondary"
                        onClick={() => handleClose()}
                      >
                        Close
                      </Button>
                      <Button
                        style={{
                          float: "right",
                          borderStyle: "none",
                          marginRight: "5%",
                        }}
                        className="btn submitButtons w-25"
                        type="submit"
                        variant="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </Container>

                  {/* <button type='submit' className='btn mt-4 mb-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }}> Submit</button> */}
                </form>
                {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{ float: 'left' }}>Close</button> */}
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={showModal1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Employment History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <form onSubmit={updateJobHistory}>
                  <Container>
                    <Row>
                      <Col sm={4}>
                        <Form.Label className="mt-2"> Name Company</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          placeholder="Enter Complany name"
                          type="text"
                          defaultValue={EmgSingle.company_name}
                          name="cmp_name"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2">Designation</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="designation"
                          defaultValue={EmgSingle.designation}
                          placeholder="Enter Designation"
                          // required
                          // id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col sm={4}>
                        <Form.Label className="mt-2">Salary</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="salary"
                          defaultValue={EmgSingle.salary}
                          placeholder=" Enter salary"
                          // required
                          // id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">Joining Date</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <MDBInput
                          // type="date"
                          // name="joining_date"
                          // // onChange={handleDateChange}
                          // value={moment(EmgSingle.joining_date).format("MM-DD-YYYY")}
                          // style={{
                          //   height: "35px",
                          //   width: "100%",
                          //   fontSize: "16px",
                          // }}

                          wrapperClass="mb-4"
                          type="date"
                          defaultValue={selectedjonupdate}
                          name="joining_date"
                          id=""
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Joining Comment
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="joining_comment"
                          defaultValue={EmgSingle.joining_comment}
                          placeholder="Add Note "
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">Leaving Date</Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="date"
                          name="leaving_date"
                          onChange={handleLeaveChangeupdate}
                          placeholder="Add Note"
                          defaultValue={selectedleavingupdate}
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}

                          // wrapperClass="mb-4"
                          // type="date"
                          // defaultValue={selectedleavingupdate}
                          // // label="Date of Brith"
                          // placeholder="leaving_date"
                          // name="leaving_date"
                          // id=""
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Leaving Comment
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="leaving_comment"
                          defaultValue={EmgSingle.leaving_comment}
                          placeholder="Add Note "
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Onboarding Date
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <MDBInput
                          //   type="date"
                          //   name="onboarding_date"
                          //   onChange={handleOnboardingChange}
                          //   // defaultValue={EmgSingle.joining_comment}
                          //   placeholder="Onboarding Comment"
                          //   style={{
                          //     height: "35px",
                          //     width: "100%",
                          //     fontSize: "16px",
                          //   }}

                          wrapperClass="mb-4"
                          type="date"
                          defaultValue={selectedOnboardingupdate}
                          // label="Date of Brith"
                          placeholder="leaving_date"
                          name="leaving_date"
                          id=""
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        {" "}
                        <Form.Label className="mt-2">
                          Onboarding Comment
                        </Form.Label>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="onboarding_comment"
                          placeholder="Onboarding Comment "
                          defaultValue={EmgSingle.onboarding_comment}
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>

                    <div className=" mt-2">
                      <Button
                        style={{ float: "left", marginLeft: "5%" }}
                        className="w-25"
                        variant="secondary"
                        onClick={() => handleClose()}
                      >
                        Close
                      </Button>
                      <Button
                        style={{
                          float: "right",
                          borderStyle: "none",
                          marginRight: "5%",
                        }}
                        className="btn submitButtons w-25"
                        type="submit"
                        variant="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </Container>

                  {/* <button type='submit' className='btn mt-4 mb-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }}> Submit</button> */}
                </form>
                {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{ float: 'left' }}>Close</button> */}
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Leavehistory;
