import React, { useEffect, useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
const WeekOffDayCheckbox = ({ index, isChecked, classval, weekVal }) => {
    const [checkDay, setCheckDay] = useState(isChecked)
    const handleCheckboxChange = () => {
        setCheckDay(prevCheckDay => !prevCheckDay);
    };
    return (
        <label key={index}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", paddingLeft: "15px" }}>
                <input
                    type="checkbox"
                    name={`${weekVal}WeekCount`}
                    value={index + 1}
                    className={classval}
                    checked={checkDay}
                    onChange={handleCheckboxChange}
                    style={{ width: "40px", padding: "0px" }}

                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", paddingLeft: "15px" }}>
               {index + 1}
            </div>
        </label>
    );
}

export default WeekOffDayCheckbox
