import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import auth from "../Model/profile.model";
import { Container, Row, Col, Card } from "react-bootstrap";
import './admin.css'

const Adminprofile = () => {
  const [profileadmin, setProfileAdmin] = useState([]);
  
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("get_admin_profile", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    auth
      .profile(formdata)
      .then((res) => {
      
        setProfileAdmin(res.data);
      })
      .catch((error) => {
        
      });
  }, []);
  return (
    // <>
    //     <div classNameName="d-flex" id="wrapper">
    //         {/* <Sidenav /> */}
    //         <div id="page-content-wrapper">
    //             {/* <Topnav /> */}
    //             <Container fluid style={{marginLeft:"231px",marginRight: "-11px",width: "-webkit-fill-available"}}>
    //                 <Row classNameName='mt-5'>
    //                     <Col sm={3}></Col>
    //                     <Col sm={6}>
    //                         <h3 classNameName="fs-4 mb-3">Admin Profile</h3>
    //                          <Card classNameName=''>
    //                            <center> <img src={`http://3.111.95.141/api/pro_pic/${profileadmin.profile?.profile_img}`} alt="" style={{height:"20%" ,width:'20%'}} classNameName='mt-4' /></center>
    //                             <table classNameName='m-5'>
    //                                 <tbody>
    //                                     <tr>
    //                                         <td classNameName='text-dark'>
    //                                             Name
    //                                         </td>
    //                                         <td classNameName='text-dark'>
    //                                             {profileadmin?.profile?.name}
    //                                         </td>
    //                                     </tr>
    //                                     <tr>
    //                                         <td classNameName='text-dark'>
    //                                             Phone Number
    //                                         </td>
    //                                         <td classNameName='text-dark'>
    //                                             {profileadmin?.profile?.cont}
    //                                         </td>
    //                                     </tr>
    //                                     <tr>
    //                                         <td classNameName='text-dark'>
    //                                             Email
    //                                         </td>
    //                                         <td classNameName='text-dark'>
    //                                             {profileadmin?.profile?.email}
    //                                         </td>
    //                                     </tr>
    //                                 </tbody>
    //                             </table>
    //                         </Card>
    //                     </Col>
    //                     <Col sm={3}></Col>
    //                 </Row>
    //             </Container>
    //         </div>
    //     </div>
    // </>
    <section className="vh-100" 
    style={{backgroundColor: '#f4f5f7', 
    // position:'relative', bottom:'100px'
}}
    >
      <div className="container h-75">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-lg-6 mb-4 mb-lg-0">
            <div className="card mb-3" 
            style={{borderRadius: '.5rem'}}
            >
              <div className="row g-0">
                <div
                  className="col-md-4 gradient-custom text-center text-white"
                  style={{
                    borderTopLeftRadius: ".5rem",
                    borderBottomleftRadius: ".5rem",
                  }}
                >
                  <img
                    src={profileadmin?.profile?.profile_img_url}
                    alt="Avatar"
                    className="img-fluid my-5"
                    style={{width: '80px'}}
                  />
                  <h5>{profileadmin?.profile?.name}</h5>
                  <i className="far fa-edit mb-5"></i>
                </div>
                <div className="col-md-8">
                  <div className="card-body p-4">
                    <h6>Admin Profile</h6>
                    <hr className="mt-0 mb-4" />
                    <div className="row pt-1">
                      <div className="col-6 mb-3">
                        <h6>Name</h6>
                        <p className="text-muted">{profileadmin?.profile?.name}</p>
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="col-6 mb-3">
                        <h6>Email</h6>
                        <p className="text-muted"> {profileadmin?.profile?.email}</p>
                      </div>
                      <div className="col-6 mb-3">
                        <h6>Phone</h6>
                        <p className="text-muted"> {profileadmin?.profile?.cont}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Adminprofile;
