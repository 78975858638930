import React, { useEffect, useState } from 'react';
import employeeModel from '../../Model/employee.Model';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import saalary from '../../Model/salary';
import { Container,Row,Col } from 'react-bootstrap';
import DataTable from '../../components/Tables/Tables';
const Salaryhistory = ({title}) => {
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);

    const [saldetails, setSaldetails] = useState();
    const [TranFilter , setTranFilter] = useState();
    const handleClose = () => setShowModal(false);
    const handleClose1 = () => setShowModal1(false);

    // const handleShow = () => setShow(true);
    const [outid, setOutid] = useState(0)
    const [salary, setSalary] = useState([]);
    const params = useParams();
    useEffect(() => {
        console.log(params, "data")
        const formdata = new FormData();
        formdata.append("get_emp_detail", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("bus_id"));
        formdata.append("emp_id", params?.id);
        employeeModel.employeedetails(formdata).then((respone) => {
            console.log(respone.data.sal)
            setSalary(respone.data.sal)
        }
        )
    }, [])
    const columns = [
        {
            name: 'S.No',
            cell: (row, index) => index + 1,
            //RDT provides index by default,
            maxWidth: '1px'
        },
        {
            name: 'Advance',
            selector: (row) => row.sal_advance,
            maxWidth: '50px'
        },
        {
            name: 'Due',
            selector: (row) => row.sal_due,
            maxWidth: '10px',
        },
        {
            name: 'Month',
            selector: (row) => row.sal_monthname,
            maxWidth: '200px',
        },
        {
            name: 'Salary Paid',
            selector: (row) => row.sal_paid,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
        },
        {
            name: 'Action',
            cell: (row) =>
                <div>
                    <button style={{ backgroundColor: "#fff" }} onClick={() => (toggleModal(row.sal_id))}  >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                        </svg>
                    </button>
                    <button style={{ marginLeft: '10px', backgroundColor: 'white' }} onClick={() => (toggleModal1(row.sal_id))}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                        <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                        <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                    </svg></button>
                </div>
        },

    ]
    const toggleModal = (sal_id) => {
        console.log("current = ", showModal)
        setShowModal(!showModal);
        setOutid(sal_id);
        onView(sal_id)
    };
    const onView = (sal_id) => {
        const formdata = new FormData();
        formdata.append("get_salary_detail", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("sal_id", sal_id);
        saalary.Viewpaid(formdata).then((res) => {
            console.log(res.data)
            setSaldetails(res.data?.data)
        }).catch((error) => {
            console.log("error => ", error)
        })

    }
    const toggleModal1 = (sal_id) => {
        console.log("current = ", showModal)
        setShowModal1(!showModal1);
        setOutid(sal_id);
    };
    const [formDataNew, setFormDataNew] = useState({
        amount:'',
        remark: '',
    });
    const handleChange = (event) => {

        setFormDataNew({
            ...formDataNew,
            [event.target.name]: event.target.value,

        });
    
      }
    const onMarkpaid = (sal_id , event) => {
        event.preventDefault();
        const formdata = new FormData(event.target);
        formdata.append("mark_salary_paid", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("sal_id", sal_id);
        saalary.Markpaid(formdata).then((res) => {
            console.log(res.data)
            setSaldetails(res.data?.data)
        }).catch((error) => {
            console.log("error => ", error)
        })

    }
    const sendDataToAPI = (number,) => {

        const formdata = new FormData();
    
        formdata.append("emp_transaction", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("emp_id", params?.id);
        saalary.Trancation(formdata).then((res) => {
            setTranFilter(res.data);
    
          console.log(res.data.documents);
       
        }).catch((error) => {
          console.log("error => ", error)
        })
      }
    

    return (
        <div>
            <DataTable
                pagination
                highlightOnHover
                columns={columns}
                fixedHeaderScrollHeight='312px'
                striped
                // fixedHeader
                data={salary}
                title={title}
                subHeader
            />
            {showModal && (
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Salary  Summary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" />
                        {saldetails && saldetails.map((detsal) =>
                            <div>
                                <div  >
                                    <span>{detsal.salary_remark}</span>  <span style={{ float: 'right' }}> ₹{detsal.amount}/-</span>
                                </div>
                                <div className='pt-1 pb-1' >
                                    <span style={{ float: 'right' }}>{detsal.type}</span>
                                </div>
                                <br />
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            )}
            {showModal1 && (
                <Modal show={showModal1} onHide={handleClose1}>
                    <Modal.Header closeButton>
                        <Modal.Title>Marking salary as paid</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="hidden" />
                        <form onSubmit={onMarkpaid}>
                            <Container>
                                <Row><input type="hidden" name="" value={outid} id="" />
                                    <Col sm={4}><span style={{fontSize:'16px'}}>Amount </span></Col>
                                    <Col sm={8}>
                                        <input type="number"  required name='amount' onChange={handleChange} placeholder='Enter Amount' style={{paddingLeft:"10px" ,fontSize:'16px',height:'35px',width:"100%"}} /></Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col sm={4}><span style={{fontSize:'16px'}}>Note</span></Col>
                                    <Col sm={8}><input type="text" name='remark' onChange={handleChange} placeholder='Enter Note' style={{paddingLeft:"10px" ,fontSize:'16px',height:'35px',width:"100%"}} /></Col>
                                </Row>
                            </Container>
                            <button className='btn mt-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white',marginRight:'14px' }}>Pay Salary</button>
                        </form>
                    </Modal.Body>
                </Modal>
            )}
        </div>

    )
}

export default Salaryhistory;
