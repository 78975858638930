import axiosInstance from "../lib/axiosInstance";
const Employee = class {
  
    async employeeDelete(employee) {
       
        return await axiosInstance.post("/employee.php", employee,{
            
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: (employee)
        });
        
    }
  
    async attendance(attendance ) {
       
        return await axiosInstance.post("/attendance.php", attendance);
    }
    async profile(profile ) {
       
        return await axiosInstance.post("/profile.php", profile);
    }
    async employeedetails(data){
        return await axiosInstance.post("/employee.php", data)
    }
    async addnewemployee(data){
        return await axiosInstance.post("/employee.php", data)
    }
    async Editemplyee(data){
        return await axiosInstance.post('/employee.php', data)

    }
    async updateemp(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async updatebank(data){
        return await axiosInstance.post('/empbank.php' , data)
    }
    async bankdetail(data){
        return await axiosInstance.post('/empbank.php' , data)
    }
    async documents(data){
        return await axiosInstance.post('/documents.php' , data)
    }
    async Alldetailsemp(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async Addressadd(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async AddressList(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async DeleteAddress(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async UpdateAddress(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async Downloadslip(data){
        return await axiosInstance.post('/salary.php' , data)
    }
    async Deduction(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async Allowanceadd(data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async Updatesocial (data){
        return await axiosInstance.post('/employee.php' , data)
    }
    async doclist(data){
        return await axiosInstance.post('/documents.php'  , data)
    }
    async addempemg(data){
        return await axiosInstance.post('/emergency_cont.php'  , data)
    }
    async empemgviewaddmin(data){
        return await axiosInstance.post('/emergency_cont.php'  , data)
    }
    async delemgcont(data){
        return await axiosInstance.post('/emergency_cont.php'  , data)
    }
    async emgsinglecont(data){
        return await axiosInstance.post('/emergency_cont.php'  , data)
    }
    async emgUpdate(data){
        return await axiosInstance.post('/emergency_cont.php'  , data)
    }
    async addsocial(data){
        return await axiosInstance.post('/employee.php'  , data)
    }
    async viewsocial(data){
        return await axiosInstance.post('/employee.php'  , data)
    }
    async Addjoinig(data){
        return await axiosInstance.post('/employment_history.php', data)
    }
    async AddTerminationDate(data){
        return await axiosInstance.post('/employment_history.php', data)
    }
    async emphistory(data){
        return await axiosInstance.post('/employment_history.php', data)
    }
    async edithistory(data){
        return await axiosInstance.post('/employee.php', data)
    }
    async DeleteDocment(data){
        return await axiosInstance.post('/documents.php', data)
    }
    async editDisplayDocment(data){
        return await axiosInstance.post('/documents.php', data)
    }

}


export default new Employee();