import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import employeeModel from "../../Model/employee.Model";
import { Link } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import salary from "../../Model/salary";
import Loader from "../../components/Common/Loading";
import { helper } from "../../lib/helper";

const Emppayslip = () => {
  const [salslip, setSaslip] = useState([]);
  const params = useParams();

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const handleMonthChange = (event) => {
    const value = event.target.value;
    setSelectedMonth(value.substring(5, 7));
    setSelectedYear(value.substring(0, 4));
    console.log("cvhgjfjhfjh", value.substring(5, 7));
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    const form = new FormData();
    form.append("emp_id", params?.id);
    form.append("slip_month", selectedMonth);
    form.append("slip_year", selectedYear);
    form.append("get_salary_slip", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    salary
      .veiwsalary(form)
      .then((res) => {
        // console.log(res.data, "shivamtech");
        setSaslip(res?.data?.data?.url);
        if (res.data.is_error === false) {
          setLoading(true);
        } else {
          helper.sweetalert.toast1(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("shivamtech", error);
      });
  };
  const openLinkInNewTab = () => {
    if (salslip) {
      window.open(salslip, "_blank");
    }
  };
  return (
    <div>
      <Card>
        <div className="mt-4 mb-4 d-flex justify-content-end">
          <span style={{ marginTop: "10px" }}>Select Month</span>
          <MDBInput
            className="mt-2 mb-2"
            wrapperClass=""
            type="month"
            onChange={handleMonthChange}
            style={{ width: "200px", marginLeft: "20px" }}
          />
          <button
            type="submit"
            onClick={() => {
              handleSubmit();
              setLoading(false);
            }}
            style={{
              backgroundColor: "#18285a",
              borderRadius: "5px",
              marginLeft: "10px",
              color: "#fff",
              marginRight: "10px",
            }}
            className="btn mb-2 mt-2"
          >
            {" "}
            Submit
          </button>

          {/* <button
            onClick={openLinkInNewTab}
            className="btn mb-2 mt-2"
            style={{ backgroundColor: "#18285a", color: "white" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-cloud-download"
              viewBox="0 0 16 16"
            >
              <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
              <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
            </svg>{" "}
            Download Now
          </button> */}

          {loading && (
            <Button
            type="submit"
           
            style={{
              backgroundColor: "#18285a",
              borderRadius: "5px",
              marginLeft: "10px",
              color: "#fff",
              marginRight: "10px",
            }}
            className="btn mb-2 mt-2"
          
              onClick={openLinkInNewTab}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-cloud-download"
                viewBox="0 0 16 16"
              >
                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
              </svg>{" "}
              Download Now
            </Button>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Emppayslip;
