import React, { useState, useEffect } from "react";
import Sidenav from "../../Layouts/sidenav";
import Topnav from "../../Layouts/topnav";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Table,
  Collapse,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import RoleModal from "../../Model/Role.modal";
import Addnew from "./AssignEmproll";
import TableDropdown from "../../components/TableDropDown/TableDropdown";
import { useDispatch, useSelector } from "react-redux";
import { helper } from "../../lib/helper";
import { formControlClasses } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import {
  fetchData,
  setFilterData,
  fetchDataAssignEmpRollList,
  assignRollEmp,
  clearSelectedData,
  removeRoll,
} from "../../store/silce/roll";

import { useParams } from "react-router-dom";
import CommonModal from "../../components/Common/CommonModal";

const EditRollLIst = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const AllrollLists = useSelector((state) => {
    return state?.roll?.fetchDataAssignEmpRollList?.data?.role?.permissions
  });


  const filterdData = useSelector((state) => {
    return state?.roll?.filterdData;
  });

  const loading = useSelector((state) => {
    return state.roll.loading;
  });
  const prmas = useParams()
  useEffect(() => {
    dispatch(fetchDataAssignEmpRollList({id:prmas.id}));
  }, []);


  const dispatch = useDispatch();
  const onDELETE = (id) => {
    console.log(id, "idnew");
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(removeRoll({ id: id })).then(
            () =>
              helper.sweetalert.toast(
                "Deleted",
                "Your file has been deleted.",
                "success"
              ),
            // dispatch(fetchDataAllRollList())
          );
        }
      });
  };
  const rows =
    AllrollLists &&
    AllrollLists?.map((item, index) => ({
      name: item.roleName,
      id: item.roleId, // Use a unique identifier from your data
      add: (
        <Form.Check
          inline
          label="1"
          name={`group1-${index}`}
          type="radio"
          id={`inline-checkbox-add-${index}`}
        />
      ),
      update: (
        <Form.Check
          inline
          label="1"
          name={`group1-${index}`}
          type="checkbox"
          id={`inline-checkbox-update-${index}`}
        />
      ),
      delete: (
        <Form.Check
          inline
          label="1"
          name={`group1-${index}`}
          type="checkbox"
          id={`inline-checkbox-delete-${index}`}
        />
      ),
      view: (
        <Form.Check
          inline
          label="1"
          name={`group1-${index}`}
          type="checkbox"
          id={`inline-checkbox-view-${index}`}
        />
      ),
    }));

  // console.log(rows, "rows");

  const [checkedStates, setCheckedStates] = useState(
    AllrollLists &&  AllrollLists?.map(() => ({
      view: false,
      add: false,
      update: false,
      delete: false,
    })) || []
  );

  // const handleCheckboxChange = (index, action) => {
  //   setCheckedStates((prevStates) => {
  //     const newCheckedStates = [...prevStates];
  //     newCheckedStates[index] = { ...newCheckedStates[index], [action]: !newCheckedStates[index][action] };
  //     return newCheckedStates;
  //   });
  // };
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectAll = (type) => {
    const newStates = checkedStates.map((state) => ({
      ...state,
      [type]: !state[type],
    }));
    setCheckedStates(newStates);

    if (selectedItems.includes(type)) {
      setSelectedItems((prev) => prev.filter((item) => item !== type));
    } else {
      setSelectedItems((prev) => [...prev, type]);
    }
  };

  const handleCheckboxChange = (name, type) => {
    const newStates = [...checkedStates];
    const index = newStates.findIndex((state) => state.name === name);
    newStates[index][type] = !newStates[index][type];
    setCheckedStates(newStates);

    if (selectedItems.includes(name)) {
      setSelectedItems((prev) => prev.filter((item) => item !== name));
    } else {
      setSelectedItems((prev) => [...prev, name]);
    }
  };

  const tablehedingStyle = {
    cursor: "pointer",
    fontWeight: "bold",
  };

  // ToDo Task DropDown and open and show all task in to    -- bysaurabh
  const [isExpanded, setIsExpanded] = useState(
    Array(AllrollLists && AllrollLists?.length).fill(false)
  );

  const toggleCollapse = (index) => {
    const newIsExpanded = [...isExpanded];
    newIsExpanded[index] = !newIsExpanded[index];
    setIsExpanded(newIsExpanded);
  };

  const handleSubmit = (roleId) => {
    dispatch(assignRollEmp({ roleId: roleId, empid: prmas.id }));
  };


  return (
    <>
      <div>
        <Container fluid>
          <Row>
                <Col sm={12}>
                  <Card className="shadow-sm p-3 mt-4 bg-white rounded border-0">
                  
                  

                            <div style={{ width: "100%" }}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th style={tablehedingStyle}>
                                      Permission Name
                                    </th>
                                    <th
                                      onClick={() => handleSelectAll("view")}
                                      style={tablehedingStyle}
                                    >
                                      View
                                    </th>
                                    <th
                                      onClick={() => handleSelectAll("add")}
                                      style={tablehedingStyle}
                                    >
                                      Add
                                    </th>
                                    <th
                                      onClick={() => handleSelectAll("update")}
                                      style={tablehedingStyle}
                                    >
                                      Update
                                    </th>
                                    <th
                                      onClick={() => handleSelectAll("delete")}
                                      style={tablehedingStyle}
                                    >
                                      Delete
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {AllrollLists &&
              AllrollLists?.map((insidelist, index) => (

                                        <tr
                                          key={index}
                                          style={{
                                            height: "50px",
                                            alignItems: "center",
                                          }}
                                        >
                                          <td style={{width:"40%"}}>{insidelist.show_name}</td>
                                          <td>
                                            <Form.Check
                                               style={{fontSize:"25px"}}
                                              type="checkbox"
                                              defaultChecked={
                                                insidelist.view_permission ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                              // onChange={() =>
                                              //   handleCheckboxChange(
                                              //     row.name,
                                              //     "view"
                                              //   )
                                              // }
                                            />
                                          </td>
                                          <td>
                                            <Form.Check
                                               style={{fontSize:"25px"}}
                                              type="checkbox"
                                              defaultChecked={
                                                insidelist.add_permission ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Form.Check
                                               style={{fontSize:"25px"}}
                                              type="checkbox"
                                              defaultChecked={
                                                insidelist.edit_permission ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                              // onChange={() =>
                                              //   handleCheckboxChange(
                                              //     row.name,
                                              //     "update"
                                              //   )
                                              // }
                                            />
                                          </td>
                                          <td>
                                            <Form.Check
                                               style={{fontSize:"25px"}}
                                              type="checkbox"
                                              defaultChecked={
                                                insidelist.delete_permission ===
                                                "true"
                                                  ? true
                                                  : false
                                              }
                                              // onChange={() =>
                                              //   handleCheckboxChange(
                                              //     row.name,
                                              //     "delete"
                                              //   )
                                              // }
                                            />
                                          </td>
                                        </tr>
                                     ))}
                                </tbody>
                              </Table>
                            </div>

                            
                  
                  </Card>
                </Col>
          </Row>
        </Container>
      </div>

          
    </>
  );
};
export default EditRollLIst;
