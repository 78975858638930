import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import employeeModel from "../../Model/employee.Model";
import { Link } from "react-router-dom";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import moment from "moment";
import salary from "../../Model/salary";
import DataTable from "react-data-table-component";
import { helper } from "../../lib/helper";
import TableDropdown from "../../components/TableDropDown/TableDropdown";
const Allowance = () => {
  const [Advance, setAdvance] = useState([]);
  const [salslip, setSaslip] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [TranFilter, setTranFilter] = useState();
  const params = useParams();
  const [NewformData, setNewFormData] = useState({
    allowance_amount: "",
    allowance_notice: "",
  });
  const [input, setInput] = useState("");
  const formattedDate = moment(input).format("DD-MM-YYYY");
  const handleInputChange = (event) => {
    setNewFormData({
      ...NewformData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("allowance_date", formattedDate);
    form.append("emp_id", params?.id);
    form.append("add_allowance", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));

    employeeModel
      .Allowanceadd(form)
      .then((res) => {
        setSaslip(res.data);
        console.log(res.data);
        handleClose();
        sendDataToAPI();
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };

  //   const onDELETE = (id) => {
  //     const formdata = new FormData();

  //     formdata.append("del_transaction", localStorage.getItem("token"));
  //     formdata.append("transaction_id", id);
  //     salary.RemoveAll(formdata).then((res) => {
  //         handleSubmit();
  //       console.log(res.data)
  //     //   setRemoveAdavnce(res.data)

  //       // alert("Advance Pay Remove")
  //     });
  //     // window.location.reload();
  //   }

  const onDELETE = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("del_transaction", localStorage.getItem("token"));
          formdata.append("transaction_id", id);
          salary
            .RemoveAll(formdata)
            .then((res) => {
              helper.sweetalert.toast(
                "Deleted",
                "Your Allowance has been deleted.",
                "success"
              );
              return sendDataToAPI();
            })
            .then(() => sendDataToAPI()); // Fetch sector data
        }
      });
  };

  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      //RDT provides index by default,
      maxWidth: "1px",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      maxWidth: "50px",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      maxWidth: "10px",
    },
    {
      name: "Notice",
      selector: (row) => row.notice,
      maxWidth: "200px",
    },
    {
      name: "Transaction Category",
      selector: (row) => row.transaction_category,
    },
    {
      name: "Transaction Date",
      selector: (row) => row.transaction_date,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },

    // {
    //     name: 'Action',
    //     cell: (row) =>

    //       <button style={{ backgroundColor: "#fff" }}
    //         onClick={() => (onDELETE(row.id))}
    //       >
    //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
    //           <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
    //         </svg>
    //       </button>
    //   },

    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            onClick={() => onDELETE(row.id)}
            onDeleteClick={() => onDELETE(row.id)}
            // openEditModal={() => dispatch(openEditModal("edit-business"))}
            isRemove={true}
            page="businessList"
          />
        );
      },
      width: "4%",
      center: "center",
    },
  ];
  const sendDataToAPI = (data) => {
    const formdata = new FormData();

    formdata.append("emp_transaction", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("filter_type", "allowance");
    formdata.append("emp_id", params?.id);
    salary
      .Trancation(formdata)
      .then((res) => {
        setTranFilter(res.data.tran);
        console.log(res.data.tran);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  useEffect(() => {
    sendDataToAPI();
  }, []);
  return (
    <div>
      <div className="mt-4 mb-4">
        <span style={{ fontSize: "18px" }}>
          <b>Allowance List</b>
        </span>
        <Button
          onClick={handleShow}
          className="btn submitButtons w-25"
          type="submit"
          variant="primary"
          style={{ float: "right" }}
        >
          Add{" "}
        </Button>
      </div>
      <div>
        <DataTable
          columns={columns}
          data={TranFilter}
          pagination
          fixedHeader
          //   fixedHeaderScrollHeight='400px'
          highlightOnHover
          //   title={title}
        />
      </div>
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ fontSize: "20px" }}>Add Allowance Payment</span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-1" style={{}}>
            <form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col sm={4}>
                    <label style={{ fontSize: "16px" }}>Add Allowance</label>
                  </Col>
                  <Col sm={8}>
                    <input
                      type="number"
                      name="allowance_amount"
                      onChange={handleInputChange}
                      placeholder="Allowance Amount"
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    {" "}
                    <label style={{ fontSize: "16px", marginRight: "10px" }}>
                      Comment(optional)
                    </label>
                  </Col>
                  <Col sm={8}>
                    <input
                      type="text"
                      name="allowance_notice"
                      onChange={handleInputChange}
                      placeholder="Add Comment "
                      id=""
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4}>
                    <label style={{ fontSize: "16px", marginRight: "10px" }}>
                      {" "}
                      Allowance Date
                    </label>
                  </Col>
                  <Col sm={8}>
                    <input
                      type="date"
                      id=""
                      onInput={(e) => setInput(e.target.value)}
                      style={{ height: "35px", width: "100%" }}
                    />
                  </Col>
                </Row>
              </Container>

              <div className="mt-2">
                {" "}
                <button className="btn btn-secondary "> Close</button>
                <Button
                  type="submit"
                  style={{
                    float: "right",
                    padding: "8px",
                    borderStyle: "none",
                    backgroundColor: "#202C4A",
                    color: "white",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                  Submit
                </Button>{" "}
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Allowance;
