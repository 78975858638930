import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Modal, Tabs, Tab } from "react-bootstrap";
import DataTable from "../../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";

import { EmployeeAttendance } from "../../store/silce/Employee";
import { setLoading } from "../../store/silce/levelSlice";
import moment from "moment";

const EmpAttendance = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.employee?.loading);
  const employeeDetails = useSelector(
    (state) => state?.employee?.Employee?.data?.attendance
  );
  const reversedAttendanceData = employeeDetails?.slice().reverse();
  const columns = [
    {
      name: "SN.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Day",
      selector: (row) => row.day,
    },
    {
      name: "Punch In",
      selector: (row) => row.punch_in,
    },
    {
      name: "Punch Out",
      selector: (row) => row.punch_out,
    },
    {
      name: "Total Hours",
      selector: (row) => row.overall,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "900",
      },
    },
  };
  const currentDate = moment();
   // Get the current year as "YYYY" (e.g., "2024")
   const currentYear = currentDate.format("YYYY");

   // Get the current month as "MM" (e.g., "02")
   const currentMonth = currentDate.format("MM");
   const [selectedMonthss, setSelectedMonthss] = useState(currentMonth);
   const [selectedYearss, setSelectedYearss] = useState(currentYear);

  // console.log(selectedMonthss,selectedYearss,"selectedYearsshjwdfdcds")

//   const date = moment(currentDate).format("YYYY-MM");


  const handleMonthChangess = (event) => {
    
    const value = event.target.value;
    setSelectedMonthss(value.substring(5, 7));
    setSelectedYearss(value.substring(0, 4));
    dispatch(
      EmployeeAttendance({
        selectedMonthss: selectedMonthss,
        selectedYearss: selectedYearss,
      })
    );
  };

  useEffect(() => {
    dispatch(
      EmployeeAttendance({
        selectedMonthss: selectedMonthss ,
        selectedYearss: selectedYearss,
      })
    );
  }, []);

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
              <div
                style={{
                  "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                }}
                className=" mt-2 mb-4"
              >
                <div></div>
                <div className="row mb-3">
                  <div className="col-sm-2 mt-3">
                    <span style={{ marginLeft: "10px", marginTop: "40px" }}>
                      Attendance Report
                    </span>
                  </div>
                  <div className="col-sm-4">
                    <div className="mt-2" style={{ marginLeft: "50px" }}>
                      <input
                        type="month"
                        value={`${selectedYearss}-${selectedMonthss}`}
                        onChange={handleMonthChangess}
                        style={{ width: "170px", borderRadius: "8px" }}
                      />
                    </div>
                  </div>
                </div>

                <DataTable
                  title="Attendance List"
                  columns={columns}
                  loading={loading}
                  data={reversedAttendanceData}
                  // keys={['cont', 'designation', 'name']}

                  pagination
                  // fixedHeader
                  // fixedHeaderScrollHeight='470px'
                  selectableRowsHighlight
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EmpAttendance;
