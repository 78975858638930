import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import salary from '../Model/salary';
import DataTable from '../components/Tables/Tables';
import TableDropdown from '../components/TableDropDown/TableDropdown';
import moment from 'moment';
import { useSelector } from 'react-redux';
const Reimbursement = () => {
    const [salarydeat, setSalary] = useState("");
    const [updateres , setUpdateres] = useState([]);
    const [isLoading, setIsLoading]=useState(true)
    const busid = useSelector(state =>state?.selectOption?.value)
    const handleReject = (id) => {
        sendDataToAPI('rejected', id);
    };
    const handleApprove = (id) => {
        sendDataToAPI('approved', id);
    };
    const sendDataToAPI = (status ,id) => {
        setIsLoading(true)
        const formdata = new FormData();
        formdata.append("update_reimbursement", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("update_status", status)
        formdata.append("update_id", id)
        salary.Updatereimbursement(formdata).then((res) => {
            setUpdateres(res.data)
            setIsLoading(false)
        }).catch((error) => {
            console.log("error => ", error)
        })
    }
    const columns = [
        {
            name: '#',
            cell: (row, index) => index + 1,
            width:'3vw'
        },
        {
            name: 'Staff ID',
            selector: (row) => row.emp_code,
            width:'6vw',
            center:'center'
        },
        {
            name: 'Staff Name',
            selector: (row) => row.emp_name,
        },
        {
            name: 'Designation',
            selector: (row) => row.emp_designation,
        },
        {
            name: 'File',
            cell: (row) => {
                return (
                    <div>
                        {
                            row.image ?
                            <img src={`${row.image}`} alt=""
                            height={40} 
                            onClick={()=>{Showdoctab(row.image)}}
                            style={{cursor:"pointer"}}
                        />
                        : 'No File'
                        }
                    </div>
                )
            },
            width:'5vw',  center:"center"
        },
        {
            name: 'Amount',
            selector: (row) => row.amount,
            width:'6vw',
            center:"center"
        },
        {
            name: 'Applied Date',
            selector: (row) => row.applied_on,
            cell: (row) => {
                return (
                    <div><div>{moment(row?.applied_on).format("DD-MM-YYYY")}</div></div>
                )},  
                width:'8vw',

        },
        {
            name: "Reason",
            selector: (row) => row.reason,
        },
        {
            name: "Status",
            selector: (row) => row.status,
            width:'7vw',
        },
        {name: '#',
            cell: (row) =>
            {
                return (
                    <TableDropdown
                       
                    Approvedres={() => ( handleApprove(row.id))}
                    Rejectres={() => (handleReject(row.id))}
                    // puchOutClick={() => (openCheckoutModal1(row.emp_id))}
                    // overTimeClick={() => (handleOvertimeClick(row.emp_id))}
                    // leaveClick={() => (handleLeaveClick(row.emp_id))}
                    // weekOffClick={() => (handleWeekClick(row.emp_id))}
                    // undoClick={() => onUndo(row.att_id)}
                    // isAttendance={true} id={row?.id} 
                    isApproved={true}
                    isRejected={true}
                    style={{}}
                 />
                
                )
                
            },
            width:'4vw',
  
        },
       
    ]
    const Showdoctab = (image) =>{
        window.open(image, '_blank');
       
      }
    useEffect(() => {
        const formdata = new FormData();
        formdata.append("my_emp_reimbursement", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        salary.Reimbursement(formdata).then((res) => {
            setSalary(res.data.data);
            setIsLoading(false)
        }).catch((error) => {
            console.log("error => ", error)
        })
    }, [busid]);

    return (
        <>
            <Container fluid >
                <Row>
                    <Col>
                    <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className=' mt-3 mb-4' >
                        <DataTable
                            columns={columns}
                            title={"Reimbursement List"}
                            data={salarydeat?.reimbursement}
                            pagination
                            fixedHeader
                            // fixedHeaderScrollHeight='400px'
                            highlightOnHover
                            loading={isLoading}
                        />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Reimbursement;