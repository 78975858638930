import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import auth from '../Model/profile.model';
import DataTable from 'react-data-table-component';
// import { CSVLink }from 'react-csv'
import { Container, Row, Col } from 'react-bootstrap';

const StaffRole = () => {
    const [search, setSearch] = useState("");
    const [attendancelist, setattendanceList] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const columns = [
        {
            name: 'Id',
            selector: (row) => row.emp_code,
        },
        {
            name: 'Name',
            selector: (row) => row.name,

        },
        {
            name: 'Designation',
            selector: (row) => row.designation,
        },
        {
            name: 'In Time',
            selector: (row) => row.in_tim,
        },

        {
            name: 'Action',
            cell: (row) =>
                <Row>
                    <Col sm={6}>
                        <p>Absent</p>
                    </Col>
                    <Col sm={6}>
                        <p>Leave</p>
                    </Col>
                    <Col sm={4}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                    </Col>
                    <Col sm={4}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16">
                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                    </Col>
                </Row>
        },

    ]
    useEffect(() => {
        const formdata = new FormData();
        formdata.append("emp_att_list", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        auth.attendance(formdata).then((res) => {

        

            setattendanceList(res.data.emp)
            setAttendance(res.data.emp)
        }).catch((error) => {
            
        })
    }, []);
    useEffect(() => {
        const result = attendancelist.filter(attendance => {
            return attendance.name.toLowerCase().match(search.toLowerCase());
        });
        setAttendance(result)
    }, [search])
    return (
        <>
            <div>
                <Container fluid >
                    <Row>
                        <Col>
                            <h3 className="fs-4 mb-3">Attendance List</h3>
                            {/* <table className="table bg-white rounded shadow-sm  table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">id</th>
                                                <th scope="col">Emplopyee ID</th>
                                                <th scope="col">Post</th>
                                                <th scope="col">In Time</th>
                                                <th scope="col">Out Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                        attendancelist.map((att)=>{    
                                                return <tr>    
                                        
                                                <th>{att.emp_code}</th>
                                                <td>{att.name}</td>
                                                <td>{att.designation}</td>
                                                <td>{att.in_tim}</td>
                                                <th>{att.out_comment}</th>
                                            
                                            </tr>
                                            })
                                        } 
                                        </tbody>
                                    </table> */}
                            {/* <CSVLink data={attendancelist} className="btn btn-success"> Export</CSVLink> */}
                            <DataTable
                                title='Attendance List'
                                columns={columns}
                                data={attendance}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight='450px'
                                selectableRows
                                selectableRowsHighlight
                                highlightOnHover
                                actions={<button className='btn btn-primary btn-sms'>Export</button>}
                                subHeader
                                subHeaderComponent={
                                    <input type="text"
                                        placeholder='Serach here'
                                        className='w-25 from-control'
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)} />
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default StaffRole;