import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Sidenav, Nav, Toggle } from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import MagicIcon from '@rsuite/icons/legacy/Magic';
import CalendarIcon from '@rsuite/icons/Calendar';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import ProjectIcon from '@rsuite/icons/Project';
import logo from '../Aseset/image/logo.png'
import OffIcon from '@rsuite/icons/Off';
import 'rsuite/dist/rsuite.min.css';
import { Card } from '@mui/material';
import WechatOutlineIcon from '@rsuite/icons/WechatOutline';
import HistoryIcon from '@rsuite/icons/History';
import ToolsIcon from '@rsuite/icons/Tools';
import TaskIcon from '@rsuite/icons/Task';
import EventDetailIcon from '@rsuite/icons/EventDetail';
import { useLocation } from 'react-router-dom';
const Sidenavbar = () => {
  const [expanded, setExpanded] = React.useState(true);
  const [activeKey, setActiveKey] = React.useState();
  const logout = () => {
    // Clear session storage
    sessionStorage.clear();

    // Clear local storage
    localStorage.clear();
  };
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
  };

  return (

    <div style={{ backgroundColor: "#1f263b", height: '100vh',  position:'fixed',overflowY:"scroll"}}>

      <Sidenav expanded={expanded} style={{ backgroundColor: "#1f263b", height: '100vh' }} >

        <Sidenav.Body  style={{backgroundColor:'#1f263b'}} >
          <Nav activeKey={activeKey} onSelect={setActiveKey}  style={{backgroundColor:'#1f263b'}} >
            <img src={logo} alt="" height={50} style={{ paddingLeft: '15px', paddingTop: "15px" }} onClick={() => navigate('/home')}  />
            <Nav.Item eventKey="1" icon={<DashboardIcon />} className='text-light navvitem ' onClick={() => navigate('/home')}  style={{backgroundColor:'#1f263b'}}>
              Dashboard
            </Nav.Item>
            <Nav.Menu eventKey="2" placement="rightStart" className='text-light navvitem' title="Business" icon={<ProjectIcon />}  style={{backgroundColor:'#1f263b'}}>
            <Nav.Item eventKey="2.1"   className='text-light navvitem'  onClick={() => navigate('/Businesslist')}  style={{backgroundColor:'#1f263b'}}>Business</Nav.Item>
            <Nav.Item eventKey="2.2"   className='text-light navvitem'  onClick={() => navigate('/Department')}  style={{backgroundColor:'#1f263b'}}>Department</Nav.Item>
            <Nav.Item eventKey="2.3"   className='text-light navvitem'  onClick={() => navigate('/Sub-Department')}  style={{backgroundColor:'#1f263b'}}>Sub-Department</Nav.Item>
            <Nav.Item eventKey="2.4"   className='text-light navvitem'  onClick={() => navigate('/Desigation')}  style={{backgroundColor:'#1f263b'}}>Designation</Nav.Item>
            <Nav.Item eventKey="2.5"   className='text-light navvitem'  onClick={() => navigate('/Level')}  style={{backgroundColor:'#1f263b'}}>Level</Nav.Item>
            </Nav.Menu>
            <Nav.Item eventKey="3" icon={<ProjectIcon />}  className='text-light navvitem'  href='http://3.6.142.0/RMS/login.php'  style={{backgroundColor:'#1f263b'}}>
              Recruitment
            </Nav.Item>
            <Nav.Menu eventKey="4" placement="rightStart" className='text-light navvitem' title="Staff" icon={<UserInfoIcon />}  style={{backgroundColor:'#1f263b'}}>
            <Nav.Item eventKey="4-1" className='text-light navvitem' onClick={() => navigate('/stafflist')}>Staff List</Nav.Item>
            <Nav.Item eventKey="4-2" className='text-light navvitem' onClick={() => navigate('/stafflist')}>Staff Map</Nav.Item>
            </Nav.Menu>
            <Nav.Menu placement="rightStart" className='text-light navvitem' eventKey="5" title="Attendance" icon={<CalendarIcon />}>
              <Nav.Item eventKey="5-1" className='text-light navvitem' onClick={() => navigate('/Attendance')}>Attendance</Nav.Item>
              <Nav.Item eventKey="5-2" className='text-light navvitem' onClick={() => navigate('/Updatetype')}>Update Attendance </Nav.Item>
              <Nav.Item eventKey="5-3" className='text-light navvitem' onClick={() => navigate('/SelfiAttendance')}>Selfe Attendance </Nav.Item>

            </Nav.Menu>
            <Nav.Menu placement="rightStart" className='text-light navvitem' eventKey="6" title="Holiday" icon={<HistoryIcon />}>
              <Nav.Item eventKey="6-1" className='text-light navvitem' onClick={() => navigate('/Holidays')}>All Holiday</Nav.Item>      
              {/* <Nav.Item eventKey="5-2" className='text-light navvitem'>Add  Holiday </Nav.Item> */}
            </Nav.Menu>
            <Nav.Menu placement="rightStart" className='text-light navvitem' eventKey="7" title="Payroll" icon={<WechatOutlineIcon />}>
            <Nav.Item eventKey="7-1" className='text-light navvitem' onClick={() => navigate('/Salary')}>Payroll</Nav.Item>
            <Nav.Item eventKey="7-2" className='text-light navvitem' onClick={() => navigate('/Reimbursement')}>Reimbursement</Nav.Item>
            </Nav.Menu>
            <Nav.Item eventKey="8" className='text-light navvitem' icon={<WechatOutlineIcon />} onClick={() => navigate('/Noticehistory')} style={{backgroundColor:'#1f263b'}}>
              Notice
            </Nav.Item>
            <Nav.Item eventKey="9" className='text-light navvitem' icon={<EventDetailIcon />} onClick={() => navigate('/LeaveHistory')} style={{backgroundColor:'#1f263b'}}>
              Leave
            </Nav.Item>
            <Nav.Menu placement="rightStart" className='text-light navvitem'  eventKey="10" title="Role" icon={<TaskIcon />}>
              <Nav.Item eventKey="10-1" className='text-light navvitem' onClick={() => navigate('/Role')}>Assigne Role</Nav.Item>
              {/* <Nav.Item eventKey="9-2"className='text-light navvitem'onClick={() => navigate('/level-management')} >Level </Nav.Item>  */}
            </Nav.Menu>
            <Nav.Menu placement="rightStart" className='text-light navvitem' eventKey="12" title="Library" icon={<ToolsIcon />}>
              <Nav.Item eventKey="12-1" className='text-light navvitem'  onClick={() => navigate('/Librarydoc')}>Documnet</Nav.Item>
              <Nav.Item eventKey="12-2" className='text-light navvitem' onClick={() => navigate('/Salary-Setting')}>Video</Nav.Item>
            </Nav.Menu>
            <Nav.Menu placement="rightStart" className='text-light navvitem' eventKey="11" title="Settings" icon={<ToolsIcon />}>
              <Nav.Item eventKey="11-1" className='text-light navvitem' onClick={() => navigate('/Auto-Punch-Out')}>Auto Punch Out</Nav.Item>
              <Nav.Item eventKey="11-2" className='text-light navvitem' onClick={() => navigate('/Salary-Setting')}>Salary-Setting</Nav.Item>
            </Nav.Menu>

            <Nav.Item eventKey="13" className='text-light navvitem' icon={<OffIcon />} onClick={logout} href='/' style={{backgroundColor:'#1f263b'}}>
              Logout            
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
        <Sidenav.Toggle expanded={expanded}className='text-light navvitem' onToggle={expanded => setExpanded(expanded)} style={{backgroundColor:'#1f263b'}} />
      </Sidenav>
    </div>

  );
};

export default Sidenavbar 