import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { HomeStatics, notification } from "../../store/silce/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { Chart } from "react-google-charts";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardEmpStatusChart = () => {
  const dispatch = useDispatch();
  const attendance = useSelector((state) => state?.dashboard?.attendance);
  const busid = useSelector((state) => state?.selectOption?.value);
  const homestatus = useSelector((state) => state?.dashboard?.Homedata);
  // console.log(homestatus, "homestatus");
  // window.location.reload()
  useEffect(() => {
    dispatch(HomeStatics());
  }, [busid]);
  const pieData = {
    labels: [" Contractual", "Full Time", "Part Time ", "Probation"],
    datasets: [
      {
        label: "Employee",
        data: [
          //   attendance?.absent ? attendance?.absent : "0",
          //   attendance?.present ? attendance?.present : "0",
          homestatus?.contractual_count ? homestatus?.contractual_count : "0",
          homestatus?.fullTime_count ? homestatus?.fullTime_count : "0",
          homestatus?.partTime_count ? homestatus?.partTime_count : "0",
          homestatus?.probation_count ? homestatus?.probation_count : "0",
        ],
        backgroundColor: [
          //   "#F7464A",
          "#46BFBD",
          "#FDB45C",
          "#949FB1",
          "#4D5360",
        ],
        hoverBackgroundColor: [
          //   "#FF5A5E",
          "#5AD3D1",
          "#FFC870",
          "#A8B3C5",
          "#616774",
        ],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    // maintainAspectRatio: false, // Set to false to adjust width and height independently
    // responsive: true,
    // width: 100, // Set the width of the chart
    // height: 100, // Set the height of the chart
  };

  const totalContractual = parseInt(homestatus?.contractual_count || 0);
  const totalFullTime = parseInt(homestatus?.fullTime_count || 0);
  const totalPartTime = parseInt(attendance?.total_count || 0);
  const totalProbation = parseInt(homestatus?.probation_count || 0);
  const hasData =
    totalContractual !== 0 ||
    totalFullTime !== 0 ||
    totalPartTime !== 0 ||
    totalProbation !== 0;

  //   const data = [
  //     ["On Leave", "Hours per Day"],
  //     ["Contractual", parseInt(homestatus?.contractual_count ?? 9)],
  //     ["Full Time", parseInt(homestatus?.fullTime_count ?? 0)],
  //     ["Part Time ", parseInt(attendance?.total_count ?? 0)],
  //     ["Probation", parseInt(homestatus?.probation_count  ?? 0)],
  //     // ["Commute", 2],
  //     // ["Watch TV", 2],
  //     // ["Sleep", 7],
  // ];

  const data = hasData
    ? [
        ["Label", "Value"],
        ["Contractual", totalContractual],
        ["Full Time", totalFullTime],
        ["Part Time", totalPartTime],
        ["Probation", totalProbation],
      ]
    : [
        ["Label", "Metric"],
        ["No Data", 100,], // Display a single color representing 100% when there is no data
      ];

    
  const options = {
    // title: "My Daily Activities",
    is3D: true,
    slices: {
      0: { color: "#8ba1c7" }, // Red color for "Absent" slice
      1: { color: "#7aa9fa" },
      2: { color: "#2a5091" }, // Red color for "Absent" slice
      3: { color: "#638bcf" },
    },
  
  };

  return (
    <div>
      {/* <Pie data={pieData} options={chartOptions} s /> */}
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width={"100%"}
        height={"200px"}
      />
    </div>
  );
};

export default DashboardEmpStatusChart;
