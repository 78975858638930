import React,{useState,useEffect} from "react";

import "../Aseset/css/staffview.css";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Video from "./Video";
import LibDocs from "./LIbDocs";
const LibraLibDocsrydoc = () => {

  const params = useParams();
  const [activeTab, setActiveTab] = useState(params?.id);
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };
  useEffect(() => {
    if (params.id) {
      setActiveTab(params.id);
    }
  }, [params.id]);
  return (
    <Container fluid >
    <div
    style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }}
      className=" mt-2 mb-8"
    >
      <Tabs
      defaultActiveKey='1'
      id="fill-tab-example"
      className="mb-3 mt-6"
      fill
      activeKey={activeTab || params?.id} // Use activeKey instead of tabIndex
      variant='pills'
      onSelect={handleTabChange}
    >
      <Tab eventKey='1' title="Documents">
        <LibDocs />
      </Tab>
      <Tab eventKey='2' title="Video"> {/* Assuming '2' as the event key for Video tab */}
        <Video />
      </Tab>
    </Tabs>
    </div>
    </Container>
  );
};
export default LibraLibDocsrydoc;
