import React from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";

const Request = () => {

    return (
        <>
            <div className="d-flex" id="wrapper">
                <Sidenav style={{ parseFloat }} />
                <div id="page-content-wrapper">
                    <Topnav />
                    <div class="container-fluid   "
                       style={{ backgroundColor: 'rgba(171, 171, 173, 0.15)' }}>
                        <div className='pt-5' >
                            <span style={{ fontSize: '20px' }} className='m-5'>Leave request</span>
                            <button type="button " style={{ position: 'relative', float: 'right' ,marginLeft:"10px"}} className='btn btn-primary'> Assign Leave</button>
                            <button type="button " style={{ position: 'relative', float: 'right',backgroundColor:"green" ,color:'white'}} className='btn '>Setting</button>
                        </div>

                        <div class="container-fluid  pb-4 mx-3">
                            <div className="  pt-4">

                                <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '130px', padding: '5px' }}>Apply between </button>
                                <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '110px', marginLeft: '25px', padding: '5px' }}>Department</button>
                                <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '90px', marginLeft: '25px', padding: '5px' }}>Work Shift</button>
                                <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '120px', marginLeft: '25px', padding: '5px' }}>See rejected</button>
                                <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '120px', marginLeft: '25px', padding: '5px' }}>Leave Duration</button>
                                
                                <button style={{ backgroundColor: 'white', borderRadius: '12px', width: '80px', marginLeft: '25px', padding: '5px' }}>User</button>

                                <div className='search-box' style={{ position: 'relative', display: 'inline-block', float: 'right' }} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-search p-1" viewBox="0 0 16 16" style={{ position: 'absolute', top: '5px', left: '0px', transform: 'translateY(-50)' }}>
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                    <input type="text" style={{ paddingLeft: "30px", height: '40px', borderRadius: '20px', borderStyle: 'solid', backgroundColor: "#f5f2f2", borderColor: 'lightblue' }} placeholder='Search' />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="container-fluid mt-4 " style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px", width: "95%" }}>
                        <div class=" container-fluid justify-content-between pt-5">
                        <table className=' ' style={{ width: '96%', marginLeft: '40px', marginTop: '20px' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '350px' }}>
                                                July, 2023
                                            </td>
                                            <td>Today</td>
                                            <td>This Week </td>
                                            <td> Last Week </td>
                                            <td>This Month </td>
                                            <td>Last Month</td>
                                            <td>This Year</td>
                                            <td><input type="checkbox" name="" id="" /><span> See leave 
                                                <br />
                                                request</span> </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                        <hr />
                        <br class="dropdown-divider " />
                        <div class=" container-fluid text-center">
                            <div class="row">
                                <div class="col-3 bg-body-tertiary mx-4 shadow-sm " style={{ width: "20%" }}>
                                    <h4>5</h4>
                                    <h6>Employees on leave</h6>
                                </div>
                                <div class="col-3 bg-body-tertiary mx-4 shadow-sm" style={{ width: "20%" }}>
                                    <h4>43</h4>
                                    <h6>Total leave hours</h6>
                                </div>
                                <div class="col-3 bg-body-tertiary mx-4 shadow-sm" style={{ width: "20%" }}>
                                    <h4>1</h4>
                                    <h6>On leave( Single day )</h6>
                                </div>
                                <div class="col-3 bg-body-tertiary mx-4 shadow-sm" style={{ width: "20%" }}>
                                    <h4>1</h4>
                                    <h6>On leave (Multi day)</h6>
                                </div>
                            </div>
                        </div>
                        <br class="dropdown-divider" />
                        <div class="container-fluid mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="form-check mx-3 ">
                                                <input class="form-check-input" style={{ width: "30px", height: "30px" }} type="checkbox"
                                                    value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckChecked">

                                                </label>
                                            </div>
                                        </th>
                                        <th scope="col">Profile</th>
                                        <th scope="col">Date & time</th>
                                        <th scope="col">Leave duration</th>
                                        <th scope="col">Leave Type</th>
                                        <th scope="col">Attachments</th>
                                        <th scope="col">Activity</th>
                                        <th scope="col">Actions</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>
                                            <div class="form-check mx-3">
                                                <input class="form-check-input" style={{ width: "30px", height: "30px" }} type="checkbox"
                                                    value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                </label>
                                            </div>
                                        </th>
                                        <th scope="row" style={{ color: "#019AFF" }}>
                                            <img src="download.png" alt="" style={{ width: "50px", height: "50px" }} /> Rickie Schinner <p style={{ color: "#AFB1B6" }}
                                                class=" pt-0 mx-lg-5">Accounts</p>
                                        </th>

                                        <td>16 Jun, 23</td>
                                        <td>1 Day</td>
                                        <td>Paid Sick</td>
                                        <td>
                                            <h2>-</h2>
                                        </td>
                                        <td>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                                class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                                <path
                                                    d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                                <path
                                                    d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                                class="bi bi-activity" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                            </svg>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF"
                                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="form-check mx-3">
                                                <input class="form-check-input" style={{ width: "30px", height: "30px" }} type="checkbox"
                                                    value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">

                                                </label>
                                            </div>
                                        </th>
                                        <th scope="row" style={{ color: "#019AFF" }}> <img src="download.png" alt=""
                                            style={{ width: "50px", height: "50px" }} /> Rickie Schinner <p style={{ color: "#AFB1B6" }}
                                                class=" pt-0 mx-lg-5">Accounts</p>
                                        </th>
                                        <td>17 Jun, 23</td>
                                        <td>First half</td>
                                        <td>Unpaid Casual</td>
                                        <td>
                                            <h2>-</h2>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                            class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                            <path
                                                d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                            <path
                                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                                class="bi bi-activity" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                            </svg>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF"
                                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="form-check mx-3">
                                                <input class="form-check-input" style={{ width: "30px", height: "30px" }} type="checkbox"
                                                    value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">

                                                </label>
                                            </div>
                                        </th>
                                        <th scope="row" style={{ color: "#019AFF" }}> <img src="download.png" alt=""
                                            style={{ width: "50px", height: " 50px" }} /> Rickie Schinner <p style={{ color: "#AFB1B6" }}
                                                class=" pt-0 mx-lg-5">UI & UX</p>
                                        </th>

                                        <td>from: 17 Jun, 23
                                            to: 19 Jun, 23</td>
                                        <td>3 Days
                                            Includes 1 off day</td>
                                        <td>Unpaid Sick</td>
                                        <td>
                                            <h2>-</h2>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                            class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                            <path
                                                d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                            <path
                                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                                class="bi bi-activity" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                            </svg>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF"
                                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="form-check mx-3">
                                                <input class="form-check-input" style={{ width: "30px", height: "30px" }} type="checkbox"
                                                    value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">

                                                </label>
                                            </div>
                                        </th>
                                        <th scope="row" style={{ color: "#019AFF" }}> <img src="download.png" alt=""
                                            style={{ width: "50px", height: "50px" }} /> Rickie Schinner <p style={{ color: "#AFB1B6" }}
                                                class=" pt-0 mx-lg-5">Development</p>
                                        </th>

                                        <td>from: 5:35 PM
                                            to: 1:35 AM, 20 Jun, 23</td>
                                        <td>08:00 Hours</td>
                                        <td>Unpaid Casual</td>
                                        <td>
                                            <h2>-</h2>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                            class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                            <path
                                                d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                            <path
                                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                                class="bi bi-activity" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                            </svg>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF"
                                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="form-check mx-3">
                                                <input class="form-check-input" style={{ width: "30px", height: "30px" }} type="checkbox"
                                                    value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">

                                                </label>
                                            </div>
                                        </th>
                                        <th scope="row" style={{ color: "#019AFF" }}> <img src="download.png" alt=""
                                            style={{ width: "50px", height: "50px" }} /> Rickie Schinner <p style={{ color: "#AFB1B6" }}
                                                class=" pt-0 mx-lg-5">Software</p>
                                        </th>

                                        <td>
                                            21 Jun, 23</td>
                                        <td>Last half</td>
                                        <td>Paid Sick</td>
                                        <td>
                                            <h2>-</h2>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                            class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                            <path
                                                d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                            <path
                                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                                class="bi bi-activity" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                            </svg>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF"
                                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div class="form-check mx-3">
                                                <input class="form-check-input" style={{ width: "30px", height: "30px" }} type="checkbox"
                                                    value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">

                                                </label>
                                            </div>
                                        </th>
                                        <th scope="row" style={{ color: "#019AFF" }}> <img src="download.png" alt=""
                                            style={{ width: "50px", height: "50px" }} /> Rickie Schinner <p style={{ color: "#AFB1B6" }}
                                                class=" pt-0 mx-lg-5">Software</p>
                                        </th>

                                        <td>from: 5:35 PM
                                            to: 12:35 AM, 24 Jun, 23</td>
                                        <td>07:00 Hours</td>
                                        <td>Unpaid Sick</td>
                                        <td>
                                            <h2>-</h2>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                            class="bi bi-file-earmark-bar-graph mx-1" viewBox="0 0 16 16">
                                            <path
                                                d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v6zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                            <path
                                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                        </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#019AFF"
                                                class="bi bi-activity" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                                            </svg>
                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#019AFF"
                                            class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                            <path
                                                d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        </svg></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="table-responsive overflow-y-scroll" style={{ scrollbarColor: "#019AFF" }}></div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Request;