import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import noticeModel from '../Model/notice.model';
import moment from 'moment';
import DataTable from '../components/Tables/Tables';
import { helper } from '../lib/helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setFilterData } from '../store/silce/roll';
import '../App.css'

const Boradcast = () => {
    const filterdData = useSelector((state) => { return state.staff.filterdData })
    const employeeDetails = useSelector(state => state?.employee?.Employee?.data?.permissions)
    const filteredData = employeeDetails && employeeDetails?.filter((item) => item?.name === "send_notice");
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [formData, setFormData] = useState({
        emps: '',
        message: '',
        subject: '',
        notice_file: '',
    });

    const [boradcastnotice, setBoradcastnotice] = useState("");

    const columns = [
        // {
        //     name: '#',
        //     cell: (row, index) => index + 1,
        //     maxWidth: '1px'
        // },
        {
            name: 'Staff ID',
            selector: (row) => row.id,
        },
        {
            name: 'Staff Name',
            selector: (row) => row.name,
        },
        // {
        //     name: '#',
        //     cell: (row) => (
        //         <Form.Check
        //             type="checkbox"
        //             label="Send"
        //             onChange={onOptionSelected}
        //             value={row.id}
        //             name="emps"
        //         />
        //     ),
            
        // },
    ];
    const[Newvalue,setNewvalue] =useState()
    const [input, setInput] = useState(null);
    const formattedDate = moment(input).format("DD-MM-YYYY kk:mm")       

    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
        var checkbox1 = document.getElementById("now");
      var timeInput   = document.getElementById("notnow");
      if (checkbox1.checked) {
        // Only checkbox1 is checked, send its individual value
   

        setNewvalue(checkbox1.value)
        // You can then send checkbox1.value to the server or perform any other action
      } else {


      
        setNewvalue(formattedDate)
      
      }
    };   
 
const [SelectIDs , setSelectIDs] = useState('')
const getAllID =(row) =>{
    setSelectIDs(row.id)
}
    const onOptionSelected = (SelectIDs) => {
        var options = selectedOptions;
        SelectIDs = SelectIDs.target.value;
       
        if (options.indexOf(SelectIDs) > -1) options.splice(options.indexOf(SelectIDs), 1)
        else options.push(SelectIDs);
        setSelectedOptions([...options]);
        
    }
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    const handleSelectedRowsChange = (state) => {
        // Update the selectedRows state whenever the selection changes
        setSelectedRows(state.selectedRows);
      };
      

    const idget = selectedRows && selectedRows?.map((item,index)=> item.id)
  

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (idget === 'undefined' || idget == null || idget.length === 0) {
            // Show alert if condition is true
            helper.sweetalert.toast1("Please Select Staff before sending");
        } else if(Newvalue ===  'undefined' || Newvalue === null || Newvalue === "Invalid date"){
            helper.sweetalert.toast1("Please Select  Sending Time");
        }  else {
            // Continue with form submission
            const form = new FormData(event.target);
            form.append("time", Newvalue);
            form.append("emps", idget);
            form.append("broadcast_notice", localStorage.getItem("token"));
    
            noticeModel.Boardcast(form)
                .then((response) => {
                    helper.sweetalert.toast("Notice Sent Successfully");
                    if (filteredData && filteredData.length > 0 && (filteredData[0]?.add === 'true')) {
                        navigate('/Noticehistorys');
                    } else {
                        navigate('/Noticehistory');
                    }
                })
                .catch((error) => {
                   
                });
        }
    };
    



    

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("notice_page", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        noticeModel.Noticeemp(formdata).then((res) => {
           
            setBoradcastnotice(res.data.employee);
        }).catch((error) => {
           
        });
    }, []);
   
    const currentdatatata =  new Date()

    const date = moment().format("DD/MM/YYYY")
    return (
        <div>
            <Container fluid>
                <Form onSubmit={handleSubmit} className='mt-3'>
                <div style={{border:"1px solid #18285A ",padding:"10px" , borderRadius:'10px'}}>
                <Row>
                        <Col sm={4} className='mt-3'>
                            <Form.Group>
                                <Form.Label> Subject*</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    name="subject"
                                    value={formData.subject}
                                    placeholder="Enter Subject"
                                    onChange={handleInputChange}
                                    
                                    id="form1"
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={3} className='mt-3'>
                            <Form.Group>
                                <Form.Label>Notice File</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="notice_file"
                                    onChange={handleInputChange}
                                    value={formData.notice_file}
                                />
                            </Form.Group>
                        </Col>
                        {/* <Col sm={3} className='mt-3'>
                            <Form.Group>
                                <Form.Label>Schedule Time</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    value={input} onInput={e => setInput(e.target.value)}
                                    onChange={handleInputChange}
                                    id="notnow"
                                />
                            </Form.Group>
                        </Col> */}
                        <Col sm={3} className='mt-3'>
                           
                                <Form.Label>Send Now</Form.Label>
                                <div className='d-flex gap-5 w-100 justify-content-around align-items-center' style={{border:'1px solid black', height:"36px", borderRadius:"5px", borderColor:"#DEE2E6"}}>
                                <span>{date}</span>
                        <Form.Check
                            type="checkbox" 
                        className=''
                            value='now'
                            style={{paddingRight:"5px"}}
                            id="now"
                            onChange={handleInputChange}
                            required
                            // id="form1"
                        />
                        </div>
                    </Col>

                    </Row>
                    <Row>
                        <Col className='mt-3'>
                            <Form.Group>
                                <Form.Label>Enter  Meassage*</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    value={formData.message}
                                    placeholder="Enter  Meassage "
                                    onChange={handleInputChange}
                                    required
                                    id="form1"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                  
                   
                </div>
          
                <Row>
                    <Col>
                        <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className='mt-4' >
                            <DataTable
                                title='Select Employee'
                                columns={columns}
                                data={boradcastnotice}
                                page={"broadcast"}
                                pagination
                                selectableRows={getAllID}
                                onSelectedRowsChange={handleSelectedRowsChange}
                                setData={setFilterData}
                                keys={[ 'name']}
                                filterdData={filterdData}
                          
                               
                            />
                        </div>
                    </Col>
                    <div className='mb-4'>
                        <button
                        type="submit"
                        className="btn btn-block"
                        style={{ backgroundColor: '#232c59', float:'right', marginTop: '25px' }}>
                        <b className='text-light'>Submit</b>
                        </button>
                       
                </div>
                </Row>
                    </Form>
            </Container>
        </div>
    );
};

export default Boradcast;

// import React, { useState, useEffect } from 'react';
// import '../Aseset/css/sidenav.css';
// import Sidenav from "../Layouts/sidenav";
// import Topnav from "../Layouts/topnav";
// import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
// import noticeModel from '../Model/notice.model';
// import moment from 'moment';
// import DataTable from '../components/Tables/Tables';
// import { helper } from '../lib/helper';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { setFilterData } from '../store/silce/roll';

// const Boradcast = () => {
//     const filterdData = useSelector((state) => { return state.staff.filterdData })
//     const employeeDetails = useSelector(state => state?.employee?.Employee?.data?.permissions)
//     const filteredData = employeeDetails && employeeDetails?.filter((item) => item?.name === "send_notice");
//     const [selectedOptions, setSelectedOptions] = useState([]);

//     const [formData, setFormData] = useState({
//         emps: '',
//         message: '',
//         subject: '',
//         notice_file: '',
//     });

//     const [boradcastnotice, setBoradcastnotice] = useState("");

//     const columns = [
//         // {
//         //     name: '#',
//         //     cell: (row, index) => index + 1,
//         //     maxWidth: '1px'
//         // },
//         {
//             name: 'Staff ID',
//             selector: (row) => row.id,
//         },
//         {
//             name: 'Staff Name',
//             selector: (row) => row.name,
//         },
//         // {
//         //     name: '#',
//         //     cell: (row) => (
//         //         <Form.Check
//         //             type="checkbox"
//         //             label="Send"
//         //             onChange={onOptionSelected}
//         //             value={row.id}
//         //             name="emps"
//         //         />
//         //     ),
            
//         // },
//     ];
//     const[Newvalue,setNewvalue] =useState()
//     const [input, setInput] = useState(null);
//     const formattedDate = moment(input).format("DD-MM-YYYY kk:mm")       
 
//     console.log(input , 'inputinput')
//     console.log(Newvalue,'Newvalue')
//     const handleInputChange = (event) => {
//         setFormData({
//             ...formData,
//             [event.target.name]: event.target.value,
//         });
//         var checkbox1 = document.getElementById("now");
//       var timeInput   = document.getElementById("notnow");
//       if (checkbox1.checked) {
//         // Only checkbox1 is checked, send its individual value
   

//         setNewvalue(checkbox1.value)
//         // You can then send checkbox1.value to the server or perform any other action
//       } else {


      
//         setNewvalue(formattedDate)
      
//       }
//     };   
 
// const [SelectIDs , setSelectIDs] = useState('')
// const getAllID =(row) =>{
//     setSelectIDs(row.id)
// }
//     const onOptionSelected = (SelectIDs) => {
//         var options = selectedOptions;
//         SelectIDs = SelectIDs.target.value;
//         console.log("selectedOptions", SelectIDs, options)
//         if (options.indexOf(SelectIDs) > -1) options.splice(options.indexOf(SelectIDs), 1)
//         else options.push(SelectIDs);
//         setSelectedOptions([...options]);
//         console.log("selectedOptions", selectedOptions)
//     }
//     const navigate = useNavigate();
//     const [selectedRows, setSelectedRows] = useState([]);
//     const handleSelectedRowsChange = (state) => {
//         // Update the selectedRows state whenever the selection changes
//         setSelectedRows(state.selectedRows);
//       };
      

//     const idget = selectedRows && selectedRows?.map((item,index)=> item.id)
//         const handleSubmit = (event) => {
//         event.preventDefault();
//         const form = new FormData(event.target);
//         form.append("time", Newvalue);
//         form.append("emps", idget);
//         form.append("broadcast_notice", localStorage.getItem("token"));
//         noticeModel.Boardcast(form)
//             .then((response) => {
//                 helper.sweetalert.toast("Notice Send Succesfully")
//                 if(filteredData && filteredData.length > 0 && (filteredData[0]?.add === 'true')){
//                     navigate('/Noticehistorys')}else{
//                         navigate('/Noticehistory')
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     };



    
// console.log(formattedDate , 'sgggg')
//     useEffect(() => {
//         const formdata = new FormData();
//         formdata.append("notice_page", localStorage.getItem("token"));
//         formdata.append("bus_id", localStorage.getItem("selecteoption"));
//         noticeModel.Noticeemp(formdata).then((res) => {
//             console.log(res.data.employee);
//             setBoradcastnotice(res.data.employee);
//         }).catch((error) => {
//             console.log("error => ", error);
//         });
//     }, []);
   
    
//     return (
//         <div>
//             <Container fluid>
//                 <Form onSubmit={handleSubmit} className='mt-3'>
//                 <div style={{border:"1px solid #18285A ",padding:"10px" , borderRadius:'10px'}}>
//                 <Row>
//                         <Col>
//                             <Form.Group>
//                                 <Form.Label> Subject*</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     required
//                                     name="subject"
//                                     value={formData.subject}
//                                     placeholder="Enter Subject"
//                                     onChange={handleInputChange}
//                                 />
//                             </Form.Group>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col className='mt-3'>
//                             <Form.Group>
//                                 <Form.Label>Enter  Meassage*</Form.Label>
//                                 <Form.Control
//                                     as="textarea"
//                                     required
//                                     rows={3}
//                                     name="message"
//                                     value={formData.message}
//                                     placeholder="Enter  Meassage "
//                                     onChange={handleInputChange}
//                                 />
//                             </Form.Group>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col sm={3} className='mt-3'>
//                             <Form.Group>
//                                 <Form.Label>Notice File</Form.Label>
//                                 <Form.Control
//                                     type="file"
//                                     name="notice_file"
//                                     onChange={handleInputChange}
//                                     value={formData.notice_file}
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col sm={5} className='mt-3'>
//                             <Form.Group>
//                                 <Form.Label>Schedule Time</Form.Label>
//                                 <Form.Control
//                                     type="datetime-local"
//                                     value={input} onInput={e => setInput(e.target.value)}
//                                     onChange={handleInputChange}
//                                     id="notnow"
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col sm={3} className='mt-3'>
                           
//                                 <Form.Label>Send Now</Form.Label>
//                         <Form.Check
//                             type="checkbox"
//                         className=''
//                             value='now'
                          
//                             id="now"
//                             onChange={handleInputChange}
//                         />
//                     </Col>
//                     </Row>
                  
                   
//                 </div>
          
//                 <Row>
//                     <Col>
//                         <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className='mt-4' >
//                             <DataTable
//                                 title='Select Employee'
//                                 columns={columns}
//                                 data={boradcastnotice}
//                                 page={"broadcast"}
//                                 pagination
//                                 selectableRows={getAllID}
//                                 setData={setFilterData}
//                                 keys={[ 'name']}
//                                 filterdData={filterdData}
                          
//                                 onSelectedRowsChange={handleSelectedRowsChange}
//                             />
//                         </div>
//                     </Col>
//                     <div className='mb-4'>
//                         <button
//                         type="submit"
//                         className="btn btn-block"
//                         style={{ backgroundColor: '#232c59', float:'right', marginTop: '25px' }}>
//                         <b className='text-light'>Submit</b>
//                         </button>
                       
//                 </div>
//                 </Row>
//                     </Form>
//             </Container>
//         </div>
//     );
// };

// export default Boradcast;

// import React, { useState, useEffect } from 'react';
// import '../Aseset/css/sidenav.css';
// import Sidenav from "../Layouts/sidenav";
// import Topnav from "../Layouts/topnav";
// import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
// import noticeModel from '../Model/notice.model';
// import moment from 'moment';
// import DataTable from 'react-data-table-component';
// import { helper } from '../lib/helper';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// const Boradcast = () => {
//     const employeeDetails = useSelector(state => state?.employee?.Employee?.data?.permissions)
//     const filteredData = employeeDetails && employeeDetails?.filter((item) => item?.name === "send_notice");
//     const [selectedOptions, setSelectedOptions] = useState([]);

//     const [formData, setFormData] = useState({
//         emps: '',
//         message: '',
//         subject: '',
//         notice_file: '',
//     });

//     const [boradcastnotice, setBoradcastnotice] = useState("");

//     const columns = [
//         {
//             name: '#',
//             cell: (row, index) => index + 1,
//             maxWidth: '1px'
//         },
//         {
//             name: 'ID',
//             selector: (row) => row.id,
//         },
//         {
//             name: 'Name',
//             selector: (row) => row.name,
//         },
//         // {
//         //     name: '#',
//         //     cell: (row) => (
//         //         <Form.Check
//         //             type="checkbox"
//         //             label="Send"
//         //             onChange={onOptionSelected}
//         //             value={row.id}
//         //             name="emps"
//         //         />
//         //     ),
            
//         // },
//     ];

//     const handleInputChange = (event) => {
//         setFormData({
//             ...formData,
//             [event.target.name]: event.target.value,
//         });
//     };   

//     const onOptionSelected = (id) => {
//         var options = selectedOptions;
//         id = id.target.value;
//         console.log("selectedOptions", id, options)
//         if (options.indexOf(id) > -1) options.splice(options.indexOf(id), 1)
//         else options.push(id);
//         setSelectedOptions([...options]);
//         console.log("selectedOptions", selectedOptions)
//     }

//     const navigate = useNavigate();
//     const [selectedRows, setSelectedRows] = useState([]);
//     const handleSelectedRowsChange = (state) => {
//         // Update the selectedRows state whenever the selection changes
//         setSelectedRows(state.selectedRows);
//       };

//     const idget = selectedRows && selectedRows?.map((item,index)=> item.id)
//       console.log(idget,"idget") 
//     const handleSubmit = (event) => {
//         event.preventDefault();

//         const formattedTime = moment(event.target.time.value).format('DD-MM-YYYY HH:mm');
//         const form = new FormData(event.target);
//         form.append("time", formattedTime);
//         form.append("emps", idget);
//         form.append("broadcast_notice", localStorage.getItem("token"));

//         noticeModel.Boardcast(form)
//             .then((response) => {
//                 helper.sweetalert.toast("Notice Send Succesfully")
//                 if(filteredData && filteredData.length > 0 && (filteredData[0]?.add === 'true')){
//                     navigate('/stafflists')}else{
//                         navigate('/stafflist')
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//                 // Display error message to the user
//             });
//     };

//     const currentDate = new Date();
//     const formattedDate = currentDate.toLocaleDateString();
    
// console.log(formattedDate , 'sgggg')
//     useEffect(() => {
//         const formdata = new FormData();
//         formdata.append("notice_page", localStorage.getItem("token"));
//         formdata.append("bus_id", localStorage.getItem("selecteoption"));

//         noticeModel.Noticeemp(formdata).then((res) => {
//             console.log(res.data.employee);
//             setBoradcastnotice(res.data.employee);
//         }).catch((error) => {
//             console.log("error => ", error);
//         });
//     }, []);
   
    
//     return (
//         <div>
//             <Container fluid>
//                 <Form onSubmit={handleSubmit} className='mt-3'>
//                 <div style={{border:"1px solid #18285A ",padding:"10px" , borderRadius:'10px'}}>
//                 <Row>
//                         <Col>
//                             <Form.Group>
//                                 <Form.Label> Subject*</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     required
//                                     name="subject"
//                                     value={formData.subject}
//                                     placeholder="Enter Subject"
//                                     onChange={handleInputChange}
//                                 />
//                             </Form.Group>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col className='mt-3'>
//                             <Form.Group>
//                                 <Form.Label>Enter  Meassage*</Form.Label>
//                                 <Form.Control
//                                     as="textarea"
//                                     required
//                                     rows={3}
//                                     name="message"
//                                     value={formData.message}
//                                     placeholder="Enter  Meassage "
//                                     onChange={handleInputChange}
//                                 />
//                             </Form.Group>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col sm={3} className='mt-3'>
//                             <Form.Group>
//                                 <Form.Label>Notice File</Form.Label>
//                                 <Form.Control
//                                     type="file"
//                                     name="notice_file"
//                                     onChange={handleInputChange}
//                                     value={formData.notice_file}
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col sm={5} className='mt-3'>
//                             <Form.Group>
//                                 <Form.Label>Schedule Time</Form.Label>
//                                 <Form.Control
//                                     type="datetime-local"
//                                     name='time'
//                                     onChange={handleInputChange}
//                                     id="usertime"
//                                 />
//                             </Form.Group>
//                         </Col>
//                         <Col sm={3} className='mt-3'>
                           
//                                 <Form.Label>Send Now</Form.Label>
//                         <Form.Check
//                             type="checkbox"
                        
//                             value={formattedDate}
//                         />
//                     </Col>
//                     </Row>
                  
                   
//                 </div>
          
//                 <Row>
//                     <Col>
//                         <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className='mt-4' >
//                             <DataTable
//                                 title='Select Employee'
//                                 columns={columns}
//                                 data={boradcastnotice}
//                                 page={"broadcast"}
//                                 pagination
//                                 selectableRows
//                                 highlightOnHover
//                                 onSelectedRowsChange={handleSelectedRowsChange}
//                             />
//                         </div>
//                     </Col>
//                     <div className='mb-4'>
//                         <button
//                         type="submit"
//                         className="btn btn-block"
//                         style={{ backgroundColor: '#232c59', float:'right', marginTop: '25px' }}>
//                         <b className='text-light'>Submit</b>
//                         </button>
                       
//                 </div>
//                 </Row>
//                     </Form>
//             </Container>
//         </div>
//     );
// };

// export default Boradcast;
