import React, { useEffect ,useState} from 'react'
import { fetchAllSubdepartments } from '../../store/silce/bussiness'
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'react-bootstrap';
function  SubDepartment  ({ selectedOption ,datas ,onSelectSubDepartment }) {
  const [selectedSubDepartment, setSelectedSubDepartment] = useState('');

  console.log(datas?.child_department_id, 'datatya')
    const dispatch = useDispatch();
    const SubDepartmentData = useSelector((state) => { return state?.Business?.Allnewsubdepartments })
    console.log(SubDepartmentData ,'AllSubdepartments')
 useEffect(() => {
    dispatch(fetchAllSubdepartments({Option :selectedOption}))
 }, [selectedOption])
 const handleSubDepartmentChange = (event) => {
  const value = event.target.value;
  setSelectedSubDepartment(value);
  onSelectSubDepartment(value);
};
  return (
    <div>
       <Form.Select  style={{ width: "-webkit-fill-available" }} className='mt-2' id="select2"    onChange={handleSubDepartmentChange}>
        <option value=""  >Select Sub-Department
        </option>
        {
          SubDepartmentData && SubDepartmentData?.map((subdepartments) =>
            <option value={subdepartments.department_id} selected={subdepartments.department_id === datas?.department_id} 
             style={{ height: "120px", }} >
              {subdepartments?.department_name}</option>)
        }

      </Form.Select>
    </div>
  )
}

export default SubDepartment
