// searchHelper.js

import { setFiltertoday } from "../store/silce/dashboardSlice";

export const handleSearch = ({ data, keys, searchTerm, dispatch ,setData }) => {
    console.log("handleSearch called",{ data, keys, searchTerm, dispatch });
    const searchTermLower = searchTerm.toLowerCase();

    const filteredData = data.filter((row) => {
        return keys.some((key) =>
            row[key].toLowerCase().includes(searchTermLower)
        );
    });

    dispatch(setData(filteredData));
};
