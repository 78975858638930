import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import hday from "../Model/holiday.model";
import { helper } from "../lib/helper";

import Button from "@mui/material/Button";
import moment from "moment";

import { Grid, TextField } from "@mui/material";
import DataTable from "../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllHolidays,
  addHoliday,
  fetchAllHolidays,
  removeHoliday,
  setFilterData,
} from "../store/silce/holidaySlice";
import RadioButtonGroup from "../components/Common/RadioButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import CommonModal from "../components/Common/CommonModal";
import { closeModal, openModal } from "../store/silce/modalSlice";
import TableDropdown from "../components/TableDropDown/TableDropdown";

const AllHolidays = () => {
  const [selectedYear, setSelectedYear] = useState("2024"); // State to store the selected year
  const [Holiday, setHolioday] = useState([]);
  const showModal = useSelector((state) => state.modal.showModal);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const loading = useSelector((state) => state.holiday.loading);
  const filterdData = useSelector((state) => state.holiday.filterdData);
  const dispatch = useDispatch();
  const busid = useSelector((state) => state?.selectOption?.value);
  const [show, setOpen] = useState(false);
  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showModal1, setShowModal1] = useState(false);

  const yearOptions = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    // Add more options as needed
  ];

  const holidayList = useSelector((state) => {
    return state?.holiday?.holidayslist?.data?.data?.holidays;
  });

 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [formDataNew, setFormDataNew] = useState({
    name: "",
  });
  const [remome, setRemove] = useState([]);
  const onDELETE = (holidayId) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(removeHoliday({ holidayId: holidayId }));
          dispatch(fetchAllHolidays({ selectedYear: selectedYear }));
        }
      });
  };
  const onAdd = (holidayId) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();

          formdata.append("addExisting", localStorage.getItem("token"));
          formdata.append("bus_id", localStorage.getItem("selecteoption"));
          formdata.append("holidayId", holidayId);
          hday
            .Addholiday(formdata)
            .then((res) => {
              

              setRemove(res.data);
              fetchData();
            })
            .catch((error) => {
              
            });
        }
      });
  };
  const [allholi, setAllholi] = useState([]);
  const AllHolidays = () => {
    const formdata = new FormData();
    formdata.append("addAllHolidays", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    dispatch(addAllHolidays()).then(() => fetchData());
  };
  const columns = [
    {
      name: "#.",
      cell: (row, index) => index + 1,
      width: "80px",
      center: "center",
    },
    {
      name: "Holiday Name",
      selector: (row) => row.name,
      // width: "500px",
      // center: "center",
    },
    {
      name: "Date",
      selector: (row) => row.date,
      center: "center",
    },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            onClick={() => onDELETE(row.holidayId)}
            isRemove={true}
            page={"holidays"}
            id={row.id}
          />
        );
      },
      center: "center",
    },
  ];


  // useEffect(() => {
  //   fetchData();
  // }, [busid,selectedYear]);
  const handleChange = (event, userTime) => {
    setFormDataNew({
      ...formDataNew,
      [event.target.name]: event.target.value,
    });
  };
  const [input, setInput] = useState("");
  const formattedDate = moment(input).format("DD-MM-YYYY");
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const formdata = new FormData();
    // formdata.append("addNewHoliday", localStorage.getItem("token"));
    // formdata.append("bus_id", localStorage.getItem("selecteoption"));
    // formdata.append("holidayName", formDataNew.name);
    // formdata.append("holidayDate", formattedDate);
    dispatch(
      addHoliday({
        name: formDataNew?.name,
        formattedDate: formattedDate,
        event: event,
      })
    ).then(() => fetchData());
    dispatch(closeModal());
    // hday.Addnew(formdata).then((res) => {
    //     console.log("formdatsssa", formdata)
    //     // setHolioday(res.data.data.holidays)

    //     fetchData();
    //     toggleModal();
    //     helper.sweetalert.toast("Add New Holiday")
    // }).catch((error) => {
    //     console.log("error => ", error)
    // })
  };
  const toggleModal = () => {
    setShowModal1(!showModal1);
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "900",
        border: "1px solid #000", // Add a border to the table rows
      },
    },
    rows: {
      style: {
        border: "1px solid #000", // Add a border to the table rows
      },
    },
    Cell: {
      style: {
        border: "1px solid #000", // Add a border to the table rows
      },
    },
  };

  const pageData = {
    formDataNew: formDataNew,
    input: input,
    setInput: setInput,
  };
  
  const handleLevelSelect = (year) => {
    setSelectedYear(year);
    // setSelectedLevelId(id)
    dispatch(fetchAllHolidays({ selectedYear: year }));
  };
  const fetchData =  () => {
    dispatch(
      fetchAllHolidays({ selectedYear: selectedYear == "" ? "2024" : selectedYear })
  );
  
  };
  useEffect(() => {
    dispatch(
        fetchAllHolidays({ selectedYear: selectedYear == "" ? "2024" : selectedYear })
    );
  }, [selectedYear,busid]);

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
             
              {/* <RadioButtonGroup value={selectedYear} onChange={handleYearChange} options={yearOptions} /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent:"space-between",
                //   gap: "5px",
                }}
              >
                <div  
                 style={{
                  display: "flex",
                  justifyContent:"space-between",
                //   gap: "5px",
                }}>
                   <DropdownButton
                id="dropdown-basic-button"
                className="custom-dropdown"
                title={selectedYear}
                variant="secondary"
                size="xl"
                style={{ fontSize: "14px", width: "20%" }}
              >
                <Dropdown.Item
                  onClick={() => handleLevelSelect("2022")}
                  style={{ fontSize: "14px" }}
                >
                  2022
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleLevelSelect("2023")}
                  style={{ fontSize: "14px" }}
                >
                  2023
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleLevelSelect("2024")}
                  style={{ fontSize: "14px" }}
                >
                  2024
                </Dropdown.Item>
              </DropdownButton>
              
              <Button
                className="btn "
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  borderStyle: "none",
                  marginLeft: "10px",
                  textTransform:'none'
                }}
                onClick={() => {
                    setSelectedYear("2024");
                    fetchData()
                //   setSelectedLevel("Select Status");
                }}
              >
                Clear
              </Button>
              </div>

                <div style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}>
                <Button
                  onClick={() => dispatch(openModal("add-new-holiday"))}
                  variant="contained"
                  sx={{ backgroundColor: "#18285a !important", color: "white" }}
                >
                  <AddIcon sx={{ paddingRight: "5px" }} /> Add New
                </Button>
                <Button
                  onClick={() => AllHolidays()}
                  variant="contained"
                  sx={{ backgroundColor: "#18285a !important", color: "white" }}
                >
                  <AddIcon sx={{ paddingRight: "5px" }} /> Add All
                </Button>

                </div>
              
               
              </div>
              <div
                style={{
                  "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                }}
                className=" mt-4 mb-4"
              >
                <DataTable
                  columns={columns}
                  data={holidayList}
                  loading={loading}
                  keys={["name"]}
                  filterdData={filterdData}
                  setData={setFilterData}
                  title="Holiday List"
                  page={"holiday"}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <CommonModal
          onChange={handleChange}
          pageData={pageData}
          onSubmit={handleSubmit}
          show={showModal}
          onHide={() => {
            dispatch(closeModal());
          }}
          name={currentPage}
          title={"Add Holiday"}
        />
        {/* {showModal1 && (
                    <Modal
                        show={showModal1}
                        onHide={handleClose}
                        className='modal-margin-top'

                    >
                        <Modal.Header >
                            <Modal.Title>Add Holiday</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Name of Holiday11"
                                            variant="outlined"
                                            name='name'
                                            size='small'
                                            value={formDataNew.nameholiday}
                                            onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input type="date" formate name="" value={input} onInput={e => setInput(e.target.value)} style={{ height: "40px", width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" type='submit' fullWidth>Add</Button>
                                    </Grid>
                                </Grid>

                            </form>
                        </Modal.Body>
                    </Modal>
                )} */}
      </div>
    </>
  );
};
export default AllHolidays;
