import React, { useState } from 'react'
import { Container, Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import Salaryhistory from '../Components/Salaryhistory';
import Advancepay from '../Components/Advancepay';
import Emppayslip from '../../Role/Componenet/Emppayslip';
import Allowance from '../../Role/Componenet/Allowance';
import Deduction from '../../InternalPage/Deduction';
import Tranaction from '../Components/Tranction';
import Overtime from '../Components/Overtime';
import salary from '../../Model/salary';
import { useParams } from 'react-router-dom';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-react-ui-kit';

const Payslip = () => {
    const params = useParams();

    const [TranFilter, setTranFilter] = useState();
  
    const sendDataToAPI = (data) => {

        const formdata = new FormData();

        formdata.append("emp_transaction", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("filter_type", data)
        formdata.append("emp_id", params?.id);
        salary.Trancation(formdata).then((res) => {
            setTranFilter(res.data.tran);
            console.log(res.data.tran);

        }).catch((error) => {
            console.log("error => ", error)
        })
    }

    return (
        <MDBCard className="mt-3">
            <MDBCardHeader>
            <span style={{ marginTop: 'px', marginLeft: '10px', fontSize: '19px' }}>
                                <b style={{ paddingLeft: '' }}>
                Salary Payrun  Badge And Payslip</b></span>
            </MDBCardHeader>
            <MDBCardBody>
                <div>
                    <Card className='mb-5'>
                        <Container>
                            <Row>
                                <Tabs
                                    defaultActiveKey="history"
                                    id="uncontrolled-tab-example"
                                    className="mt-3 m"
                                    onSelect={(e) => { sendDataToAPI(e) }}
                                    variant='pills'
                                    fill
                                >
                                    <Tab eventKey="history" title=" Salary History">
                                        <Salaryhistory title={"Salary History"} />
                                    </Tab>
                                    <Tab eventKey="advance" title="Advance Pay">
                                        <Advancepay title={"Advance Pay"} />
                                    </Tab>
                                    {/* <Tab eventKey="bank" title="Bank Details" >
                                Tab content for Contact
                            </Tab> */}
                                    <Tab eventKey="slip" title="Salary Slip" >
                                        <Emppayslip />
                                    </Tab>
                                    <Tab eventKey="transaction" title="Transaction" >
                                        <Tranaction />
                                    </Tab>
                                    <Tab eventKey="allowance" title="Allowance" >
                                        <Allowance />
                                    </Tab>

                                    <Tab eventKey="deduction" title="Deduction" >

                                        <Deduction data={TranFilter} />


                                    </Tab>
                                    <Tab eventKey="overTime" title="OverTime" >
                                        <Overtime />
                                    </Tab>
                                </Tabs>
                            </Row>
                        </Container>
                    </Card>
                </div >
            </MDBCardBody>
        </MDBCard>
    )
}

export default Payslip
