import React from 'react'
import { Container, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import blankdata from '../../Aseset/image/no_data.svg'
const Activity = () => {
    return (
        <div>
            <Card  className='mb-5'>
                <Container>
                    <Row>
                        <Col>
                        <h4 style={{marginTop:'20px',marginLeft:'20px'}}>Activity Log</h4>
                        </Col>
                    </Row>
                    <hr  style={{border:'1px solid grey'}}/>
                    <Row>
                        <Col>
                            <div className='search-box' style={{position:'relative',display:'inline-block',float:'right'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-search p-1" viewBox="0 0 16 16"  style={{position:'absolute',top:'5px',left:'0px',transform:'translateY(-50)'}}>
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg>
                                <input type="text" style={{paddingLeft:"30px" ,height:'40px',borderRadius:'20px',borderStyle:'solid' ,backgroundColor:"#f5f2f2" ,borderColor:'lightblue'}}  placeholder='Search' />
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={1}></Col>
                        <Col sm={3}><b>Title</b></Col>
                        <Col sm={3}><b>Name</b></Col>
                        <Col sm={5}><b>Time</b></Col>
                    </Row>
                    <Row>
                        <Col>
                      <center>:
                      <img src={blankdata} alt=""  style={{height:"200px",marginTop:'100px',marginBottom:'100px'}}/>
                      <p><b>Nothing to show here</b></p>
                      <p style={{marginBottom:'70px'}}>Please add a new entity or manage the data table to see the content here <br /> Thank you </p>
                      </center>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </div>
    )
}

export default Activity
