import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import img from "../../Aseset/image/kkntForm.jpeg";
import "../../Aseset/css/staffview.css";
import { useDispatch, useSelector } from "react-redux";
import { editEmployee } from "../../store/silce/staffSlice";
import { useParams } from "react-router-dom";
import employeeModel from "../../Model/employee.Model";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function StaffinfoDow() {
  const params = useParams();
  const employeeDetails = useSelector(
    (state) => state?.staff?.employeeDetails?.emp
  );
  const dispatch = useDispatch();
  const [viewbank, setViewbank] = useState(null);
  const [EmgCont, setEmgCont] = useState([]);
  const [address, setAddress] = useState([]);
  const [shwoDoc, setShowDoc] = useState([]);

  useEffect(() => {
    dispatch(editEmployee({ id: params?.id }));
  }, [dispatch, params?.id]);

  const getdata = () => {
    const form = new FormData();
    if (params?.id !== undefined && params.id !== "") {
      form.append("bank_detail_edit_admin", localStorage.getItem("token"));
      form.append("bus_id", sessionStorage.getItem("selecteoption"));
      form.append("emp_id", params.id);
    } else {
      form.append("bank_detail_edit", localStorage.getItem("token"));
    }
    // Calling employeeModel.bankdetail with the formed FormData
    employeeModel.bankdetail(form).then((res) => {
      // Setting the state with the retrieved data

      setViewbank(res.data.bank);
      // console.log(res?.data,"lllllllff");
    });
  };

  useEffect(() => {
    getdata();
  }, []);

  const fetchData = async () => {
    const formdata = new FormData();
    formdata.append("emp_id", params?.id);
    formdata.append("get_contacts", localStorage.getItem("token"));
    employeeModel
      .empemgviewaddmin(formdata)
      .then((res) => {
        console.log(res?.data?.emg_contact);
        setEmgCont(res?.data?.emg_contact);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  //   const Print = () => {
  //     console.log('print');
  //     let printContents = document.getElementById('print-content').innerHTML;
  //     let printWindow = window.open('', '_blank');
  //     printWindow.print();
  //     printWindow.close();
  // }

  const Print = () => {
    const printContents = document.getElementById("print-content").innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              body * {
                visibility: hidden;
              }
              .print-content, .print-content * {
                visibility: visible;
              }
              .print-content {
                position: absolute;
              
              }
              @page {
                size: A4;
              
              }
            }
          </style>
        </head>
        <body>
          <div class="print-content">${printContents}</div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const fetchDataAddress = async () => {
    const formdata = new FormData();
    formdata.append("emp_id", params?.id);
    formdata.append("emp_address_list", localStorage.getItem("token"));
    employeeModel
      .AddressList(formdata)
      .then((res) => {
        console.log(res?.data?.address, "saurabh");
        setAddress(res?.data?.address);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  console.log(EmgCont, "EmgCont");
  useEffect(() => {
    fetchDataAddress();
  }, []);
  const staffAddressLine1 =
    address && address[0] ? address[0].address_line1 : "";
  const staffAddressLine2 =
    address && address[0] ? address[0].address_line2 : "";
  console.log(address, "addressssssgtaffdow");

  const AllDocGet = () => {
    const form = new FormData();
    form.append("emp_id", params?.id);
    form.append("get_emp_docs", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    employeeModel
      .documents(form)
      .then((res) => {
        setShowDoc(res.data.documents);
        // const media = res.data.documents;
        // const parts = url.split(".");
        // const fileExtension = parts[parts.length - 1].toLowerCase();
        // let mediaType;
        // if (
        //   fileExtension === "png" ||
        //   fileExtension === "jpg" ||
        //   fileExtension === "jpeg" ||
        //   fileExtension === "gif"
        // ) {
        //   mediaType = "image";
        // } else if (
        //   fileExtension === "mp4" ||
        //   fileExtension === "avi" ||
        //   fileExtension === "mov"
        // ) {
        //   mediaType = "video";
        // } else if (fileExtension === "pdf") {
        //   mediaType = "pdf";
        // } else {
        //   mediaType = "unknown";
        // }
        // console.log(`Media type: ${mediaType}`);
        // console.log(res.data.documents);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  useEffect(() => {
    AllDocGet();
  }, []);
  const staffDocument = shwoDoc && shwoDoc[0] ? shwoDoc[0].docs_name === "Postgraduation" ?"	Postgraduation":"" : "";
  console.log(employeeDetails, "employeeDetails");
  return (
    <>
      <button
        className="btn"
        style={{
          float: "right",
          backgroundColor: "#18285a",
          color: "white",
        }}
        onClick={Print}
      >
        Download PDF
      </button>
      <div
        className="p-4 mb-5 "
        style={{ margin: "auto", width: "75%" }}
        id="print-content"
      >
        <Table
          className="table"
          striped="coloumns"
          style={{
            borderCollapse: "collapse",
            border: "2px solid black",
            width: "207mm",
            margin: "auto",
            height: "257mm",
          }}
        >
          <tr>
            <td colSpan={5} style={{ border: "2px solid black" }}>
              <div style={{ display: "flex" }}>
                <img src={img} alt="" style={{ marginRight: "40px" }} />
                <div>
                  <p
                    style={{
                      marginLeft: "65px",
                      textDecoration: "underline",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    KKNT SECURTY AND MANPOWER PIVATE LIMITED
                  </p>
                  <p
                    style={{
                      textDecoration: "underline",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    APPLICATION FOR ENROLLMENT FOR SECURITY JOB
                  </p>

                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    EMPLOYEE CODE: {employeeDetails?.emp_code}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              EMPLOYEE NAME
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {employeeDetails?.name} {employeeDetails?.middle_name}{" "}
              {employeeDetails?.last_name}
            </td>
            <td
              colSpan={2}
              style={{
                border: "2px solid black",
                fontWeight: "bold",
                position: "relative",
              }}
            >
              DESGN: {employeeDetails?.designation}
              <img
                src={`https://qrstaff.in/api/pro_pic/${employeeDetails?.profile_img}`}
                alt="avatar"
                fluid
                style={{
                  position: "absolute",
                  left: "30px",
                  top: "420%",
                  transform: "translateY(-50%)",
                  width: "70%",
                  borderRadius: "10px",
                  // marginTop: "80px",
                  height: "600%",
                }}
              />
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              FATHER'S/HUSBAND’S NAME
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {" "}
              {employeeDetails?.fathers_name} {employeeDetails?.husband_name}
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              MOTHER'S NAME
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {employeeDetails?.mothers_name}
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              DATE OF BIRTH
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {employeeDetails?.dob}
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              CONTACT NO
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {employeeDetails?.cont}
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              BLOOD GROUP
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {employeeDetails?.blood_group}
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              IDENIFICATION MARK
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {
               employeeDetails?.identification_mark 
              }
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              PRESENT ADDRESS
            </td>
            <td
              colSpan={4}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {staffAddressLine1}
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              PERMANENT ADDRESS
            </td>
            <td
              colSpan={4}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {staffAddressLine2}
            </td>
          </tr>{" "}
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              NEAREST POLICE STATION
            </td>
            <td colSpan={4} style={{ border: "2px solid black", fontWeight: "bold" }}>  {employeeDetails?.nearest_police_station}</td>
          </tr>{" "}
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              EDUCATIONAL QUALIFICATION
            </td>
            <td colSpan={4} style={{ border: "2px solid black",fontWeight: "bold" }}>{staffDocument}</td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              UAN NO.
            </td>
            <td
              colSpan={2}
              style={{ border: "2px solid black ", fontWeight: "bold" }}
            >
              {employeeDetails?.uan_number}
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              ESI NO.
            </td>
            <td
              colSpan={2}
              style={{
                // padding: "8px",
                border: "2px solid black",
                // width: "10%",
                fontWeight: "bold",
              }}
            >
              {employeeDetails?.esi_no}
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{
                border: "2px solid black",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              BANK DETAILS
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              BANK NAME
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              BRANCH
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              IFSC CODE
            </td>
            <td
              colSpan={5}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              A/C NO.
            </td>
          </tr>
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              {" "}
              1{viewbank?.bank_name}{" "}
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              {" "}
              {viewbank?.account_name}
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              {" "}
              {viewbank?.ifsc_code}
            </td>
            <td
              colSpan={5}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              {viewbank?.account_no}
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{
                border: "2px solid black",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              NOMINEE DETAILS
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              NAME
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              DOB
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              COMPLETE ADDRESS
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              RELATIONSHIP
            </td>
            <td
              colSpan={4}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            >
              CONTACT NO
            </td>
          </tr>
          <tr >
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {" "}
                  1 {employeeDetails?.nominee_name}{" "}
                  </td>
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {employeeDetails?.nominee_dob}
                  </td>
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {/* {employeeDetails.address} */}
                  </td>
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {employeeDetails?.nominee_relation}
                  </td>
                  <td
                    colSpan={4}
                    style={{ border: "2px solid black", fontWeight: "bold" }}
                  >
                    {employeeDetails?.nominee_cont}
                  </td>
                </tr>

          <tr>
            <td
              colSpan={5}
              style={{
                border: "2px solid black",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              FAMILY PARTICULARS
            </td>
          </tr>
          <tr style={{ textAlign: "center" }}>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              NAME
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              DOB
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              COMPLETE ADDRESS
            </td>
            <td
              style={{
                border: "2px solid black",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              RELATIONSHIP
            </td>
            <td
              style={{
                border: "2px solid black",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              CONTACT NO
            </td>
          </tr>
          {EmgCont &&
            EmgCont?.map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {" "}
                    {index + 1} {item.name}{" "}
                  </td>
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {item.dob}
                  </td>
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {item.address}
                  </td>
                  <td style={{ border: "2px solid black", fontWeight: "bold" }}>
                    {item.relation}
                  </td>
                  <td
                    colSpan={4}
                    style={{ border: "2px solid black", fontWeight: "bold" }}
                  >
                    {item.contact_no}
                  </td>
                </tr>
              );
            })}
          <tr>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              {" "}
              DATE OF SELECTION:
            </td>
            <td style={{ border: "2px solid black" }}>
              {employeeDetails?.joining_date}
            </td>
            <td style={{ border: "2px solid black", fontWeight: "bold" }}>
              DATE OF DEPLOYMENT:
            </td>
            <td
              colSpan={5}
              style={{ border: "2px solid black", fontWeight: "bold" }}
            ></td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{
                border: "2px solid black",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              {" "}
              LOCATION DEPLOYED AT: DATE :
            </td>
          </tr>
        </Table>
      </div>
    </>
  );
}

export default StaffinfoDow;
