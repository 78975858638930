
import axios from "axios";
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
      headers: { 'X-Requested-With': 'XMLHttpRequest',  },
    // withCredentials: true,
    transformRequest: function (data, headers) {
        const token = localStorage.getItem('token');
        if (token) {
            
            headers.Authorization = "Bearer " + token;
        }
        return data;
    },
});
export default axiosInstance;   