import React, { useEffect, useState } from "react";
import { Button, Container ,Tooltip} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,

   
} from "@react-google-maps/api";
import { staffMap } from "../store/silce/Employee";
import { CiLocationOn } from "react-icons/ci";
import { Link } from "react-router-dom";

const Usermap = () => {
  const dispatch = useDispatch();
  const busid = useSelector((state) => state?.selectOption?.value);
  const staffDetailsMap = useSelector(
    (state) => state?.employee?.Employee?.data?.emp
  );

 


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBmt4c6w1TlnwW9ZsxkPY90M5vG_KvidAs",
    // callbackName: "initMap",
    // libraries:'places',
  });
  const [map, setMap] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();

  const onLoad = (maps) => {
    setMap(maps);
  };

  const calculateBounds = () => {
    if (!map || !staffDetailsMap || staffDetailsMap?.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    staffDetailsMap?.forEach(({ latitude, longitude }) => {
      bounds.extend(new window.google.maps.LatLng(latitude, longitude));
    });
    map.fitBounds(bounds);
  };

  useEffect(() => {
    calculateBounds();
  }, [staffDetailsMap, map,busid]);

  // const customMarker = {
  //   path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759   c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z    M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713   v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336   h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805",
  //   fillColor: "red",
  //   fillOpacity: 2,
  //   strokeWeight: 1,
  //   rotation: 0,
  //   scale: 1,
  // };
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleMarkerClick = ({
    index,
    lat,
    lng,
    address,
    name,
    id,
    email_id,
    designation,
    pro_img,
  }) => {
    // console.log('Clicked marker index:', index);
    map?.panTo({ lat, lng });
    setInfoWindowData({
      index,
      address,
      lat,
      lng,
      name,
      id,
      email_id,
      designation,
      pro_img,
    });
    setIsOpen(true);
  };
  // console.log(infoWindowData?.index,"index")

  useEffect(() => {
    dispatch(staffMap());
  }, [busid]);

  return (
    <div>
      <Container fluid>
        <div>
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              onLoad={onLoad}
              mapContainerStyle={{ width: "100%", height: "83vh" }}
              zoom={15}
              options={{
                disableDefaultUI: false,
              }}
              onClick={() => setIsOpen(false)}
            >
              {staffDetailsMap &&
                staffDetailsMap?.map(
                  (
                    {
                      latitude,
                      longitude,
                      locationAddress,
                      name,
                      id,
                      email_id,
                      designation,
                      pro_img,
                    },
                    index
                  ) => {
                    const lat = parseFloat(latitude);
                    const lng = parseFloat(longitude);
                    return (
                      <Marker
                        key={index} // Use index as the key
                        position={{ lat, lng }}
                        onClick={() => {
                          handleMarkerClick({
                            index,
                            lat,
                            lng,
                            address: locationAddress,
                            name,
                            id,
                            email_id,
                            designation,
                            pro_img,
                          });
                        }}
                        // icon={customMarker}
                      >
                        {isOpen && infoWindowData?.index === index && (
                          
                       
                          <InfoWindow
                            position={{
                              lat: infoWindowData?.lat,
                              lng: infoWindowData?.lng,
                            }}
                            onCloseClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent:"space-between"
                                }}
                              >
                                <div>
                                <Link to={`/Staffview/${infoWindowData?.id}`}>   
                           
                      
                                  <span>
                                    <img
                                      src={`https://qrstaff.in/api/pro_pic/${infoWindowData?.pro_img}`}
                                      alt=""
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    {/* {infoWindowData?.address} */}
                                  </span>
                                  </Link>

                                  {/* <span>ID - {infoWindowData?.id}</span> */}
                                </div>
                                <div>
                                <span  
                                className="btn submitButtons"
                                style={{fontSize:"20px",color:"white"}}
                           >

                                <CiLocationOn />
                                </span>
                                  
                                </div>
                              </div>

                              <span>
                                <span style={{fontWeight:"bold"}}>Name - </span>
                                {infoWindowData?.name}
                              </span>

                              <span>
                                <span  style={{fontWeight:"bold"}}>Email - </span>
                                {infoWindowData?.email_id}
                              </span>

                              <span>
                                <span  style={{fontWeight:"bold"}}>Designation - </span>
                                {infoWindowData?.designation}
                              </span>

                              <span>
                                <span  style={{fontWeight:"bold"}}>Address - </span>
                                {infoWindowData?.address}
                              </span>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    );
                  }
                )}
            </GoogleMap>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Usermap;
