import React, { useState, useEffect } from "react";
import "../../Aseset/css/sidenav.css";
import Sidenav from "../../Layouts/sidenav";
import Topnav from "../../Layouts/topnav";
// import { CSVLink } from 'react-csv'
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import employeeModel from "../../Model/employee.Model";
import RoleModal from "../../Model/Role.modal";
import { Link } from "react-router-dom";
import DataTable from "../../components/Tables/Tables";
import CommonModal from "../../components/Common/CommonModal";
// import {
//   fetchAllBusinesses,
//   addBusiness,
//   editBusiness,
//   clearSelectedData,
//   updateSubmit,
//   businesssectorFetch,
//   setFilterData,
//   setLoading,
//   businessDelete,
// } from "../store/silce/bussiness";
import {
  closeDeleteModal,
  closeEditModal,
  closeModal,
  openDeleteModal,
  openEditModal,
  openModal,
} from "../../store/silce/modalSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllEmployees,
  nofetchAllEmployees,
  removeEmployee,
  setFilterData,
} from "../../store/silce/staffSlice";
import { helper } from "../../lib/helper";

import TableDropdown from "../../components/TableDropDown/TableDropdown";

const AssignEmproll = () => {
  const busid = useSelector(state =>state?.selectOption?.value)
  const employees = useSelector((state) => state.staff.employees);
  const filterdData = useSelector((state) => {
    return state.staff.filterdData;
  });
  const loading = useSelector((state) => {
    return state.staff.loading;
  });


  console.log(employees,"employees")
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(nofetchAllEmployees());
  }, [busid]);
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Image",
      cell: (row) => {
        return (
          <div>
            <img
              src={`https://qrstaff.in/api/pro_pic/${row.pro_img}`}
              alt=""
              style={{ width: "30px", height: "30px", borderRadius: "5px" }}
            />
          </div>
        );
      },
    },
    {
      name: "Staff ID",
      selector: (row) => row.emp_code,
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
      cell: (row) => {
        return (
          <div>
            <Link to={`/Staffview/${row.id}`}>{row.name}</Link>
          </div>
        );
      },
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
    },
    {
      name: "Role Name",
      selector: (row) => row.roleName,
    },
    {
      name: "Role Assign",
      selector: (row) => row.roleId == 2 ? "unAssign" :" Assign"
      ,
    },
    // {
    //   name: "Mobile No",
    //   selector: (row) => row.cont,
    // },
    {
      name: "Emp Status",
      selector: (row) => row.emp_status,
    },
    {
      name: "Action",
      cell: (row) => {
        return <TableDropdown isAssign={true} page={"stafflist"} id={row.id} />;
      },
    },
  ];
  return (
    <>
      <div>
        <DataTable
          loading={loading}
          title={"Staff List"}
          columns={columns}
          data={employees?.data?.emp}
          setData={setFilterData}
          keys={["email_id", "emp_code", "name", "designation"]}
          filterdData={filterdData}
        />
      </div>
    </>
  );
};

export default AssignEmproll;
