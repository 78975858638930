import React, { useState, useEffect, useRef, useContext } from "react";
import "../Aseset/css/sidenav.css";
import auth from "../Model/profile.model";
import Delete from "../Model/employee.Model";
import {
  Container,
  Row,
  Col,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Form from "react-bootstrap/Form";
import nodata from "../Aseset/image/no_data.svg";
import { Link, useNavigate } from "react-router-dom";
import employeeModel from "../Model/employee.Model";
import WeekOffDay from "./Components/WeekOffDay";
import { AppContext } from "../lib/AppContext";
import { helper } from "../lib/helper";
import InputAdornment from "@mui/material/InputAdornment";
import nouser from "../Aseset/image/user.png";
import loader from "../Aseset/image/loader.gif";
import DataTable from "../components/Tables/Tables";
import { GrAdd } from "react-icons/gr";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterStaff,
  FilterStaffDepartment,
  addAddress,
  fetchAllEmployees,
  fetchAllStatusEmployees,
  fetchSubAllEmployees,
  nofetchAllEmployees,
  removeEmployee,
  setFilterData,
} from "../store/silce/staffSlice";
import {
  fetchAllSubdepartments,
  fetchAlldepartments,
} from "../store/silce/bussiness";
import { closeModal, openModal } from "../store/silce/modalSlice";
import CommonModal from "../components/Common/CommonModal";

const Stafflist = () => {
  const [currentTitle, setCurrentTitle] = useState("Add Address");
  const showModal = useSelector((state) => state.modal.showModal);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50% ",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [search, setSearch] = useState("");
  const [filterstaff, setFilterstaff] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const busid = useSelector((state) => state?.selectOption?.value);
  const Admindetails = useSelector(
    (state) => state?.dashboard?.home?.data?.roleName
  );

  const employeeDetails = useSelector(
    (state) => state?.employee?.Employee?.data?.permissions
  );
  const filteredData =
    employeeDetails &&
    employeeDetails?.filter((item) => item?.name === "employees");
  console.log(busid, "selectedOptionOO");
  const [selectedImage, setSelectedImage] = useState(null);
  const [outid, setOutid] = useState(null);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const inputRef = useRef(null);
  // const [loading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.staff.employees);
  const filterdData = useSelector((state) => {
    return state.staff.filterdData;
  });
  const loading = useSelector((state) => {
    return state.staff.loading;
  });
  const onDELETE = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(removeEmployee({ id: id })).then(
            () => (
              helper.sweetalert.toast(
                "Deleted",
                "Your file has been deleted.",
                "success"
              ),
              getEmployees()
              // dispatch(nofetchAllEmployees())
            )
          );
        }
      });
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "900",
        border: "1px solid #000", // Add a border to the table rows
      },
    },
    rows: {
      style: {
        border: "1px solid #000", // Add a border to the table rows
      },
    },
    Cell: {
      style: {
        border: "1px solid #000", // Add a border to the table rows
      },
    },
  };
  const columns = [
    {
      name: "#",
      cell: (row, index) => {
        return index + 1;
      },
      center: "center",
      renderRow: "true",
      width: "5%",
    },
    {
      name: "Image",
      cell: (row) => {
        return (
          <div>
            <img
              src={`https://qrstaff.in/api/pro_pic/${row.pro_img}`}
              alt=""
              style={{ width: "30px", height: "30px", borderRadius: "5px" }}
            />
          </div>
        );
      },
      width: "8%",
      center: "center",
    },

    {
      name: "Staff ID",
      selector: (row) => row.emp_code,
      width: "10%",
      // center:'center'
      sortable: "sortable",
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
      cell: (row) => {
        return (
          <div
            style={{
              height: "17px",
              overflow: "hidden",
              "text-overflow": "ellipsis",
              color: "black",
            }}
          >
            <Link to={`/Staffview/${row.id}`}>
              <span>
                {" "}
                {row.prefix} {row.name} {row.last_name}{" "}
              </span>
            </Link>
          </div>
        );
      },
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      //    center:'center',
    },
    {
      name: "Email ID",
      selector: (row) => row.email_id,
      cell: (row) => {
        return (
          <>
            <div
              style={{
                height: "16px",
                overflow: "hidden",
                "text-overflow": "ellipsis",
                color: "black",
              }}
            >
              <Tooltip title={row.email_id} placement="top">
                <span>{row.email_id}</span>
              </Tooltip>
            </div>
          </>
        );
      },
    },

    {
      name: "Mobile No",
      selector: (row) => row.cont,
      center: "center",
    },
    {
      name: "Attendance",
      selector: (row) => row.present_count,
      center: "center",
    },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            openEditModal={() =>
              filteredData &&
              filteredData.length > 0 &&
              filteredData[0]?.edit === "true"
                ? navigate(`/Updates/${row.id}`)
                : navigate(`/Update/${row.id}`)
            }
            openViewClick={() =>
              filteredData &&
              filteredData.length > 0 &&
              filteredData[0]?.view === "true"
                ? navigate(`/Staffviews/${row.id}`)
                : navigate(`/Staffview/${row.id}`)
            }
            isEdit={
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0]?.edit == "true") ||
              (Admindetails === "client" && true)
            }
            isRemove={
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0]?.delete == "true") ||
              (Admindetails === "client" && true)
            }
            isView={
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0]?.view == "true") ||
              (Admindetails === "client" && true)
            }
            // isInfoDow={
            //   filteredData &&
            //   filteredData.length > 0 &&
            //   filteredData[0]?.view === "true"
            //     ? navigate(`/StaffinfoDow/${row.id}`)
            //     : navigate(`/StaffinfoDow/${row.id}`)
            // }
            page={"stafflist"}
            isStaffDow={true}
            id={row.id}
            // openViewClick={() => (navigate(`/Staffview/${row.id}`))}
            onDeleteClick={() => onDELETE(row.id)}
            onClick={() => onDELETE(row.id)}
            // isAdress={true}
            // openModal={()=>
            //     {
            //     dispatch(openModal("add-addressnew"))
            //     setOutid=(row.id)
            // }}
          />
        );
      },
      center: "center",
      width: "5%",
    },
  ];
  const getEmployees = () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("selecteoption")
    ) {
      dispatch(nofetchAllEmployees());
    }
  };

  useEffect(() => {
    dispatch(fetchAllStatusEmployees());
    dispatch(nofetchAllEmployees());
  }, [busid, currentPage]);

  const DepartmentData = useSelector((state) => {
    return state.Business.Alldepartments;
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [formData, setFormData] = useState({
    emp_name: "",
    emp_cont: "",
    emp_desg: "",
    emp_email: "",
    dob: "",
    emp_code: "",
    emp_att_type: "",
    start_time: "",
    start_end: "",
    paid_leave: "",
    att_basis: "",
    salary: "",
    profile_img: "",
    epf: "",
    eepf: "",
    emp_week_off: [
      {
        day: "",
        week_count: "",
      },
    ],
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (event.target.type === "file") {
      const imageFile = event.target.files[0];
      if (imageFile) {
        setSelectedImage(URL.createObjectURL(imageFile));
      }
    }
    if (event.target.type === "number") {
      setNum(event.target.value.slice(0, limit));
    }
  };
  const EmpStatus = [
    {
      value: "Termination",
      label: "Termination",
    },
    {
      value: "Resigned ",
      label: "Resigned ",
    },
    {
      value: "Probation",
      label: "Probation",
    },
    {
      value: "Full-Time",
      label: "Full-Time",
    },
    {
      value: "Part-Time",
      label: "Part-Time",
    },
  ];
  const getCheckedCheckboxes = (inputName) => {
    const checkboxes = document.querySelectorAll(
      'input[name="' + inputName + 'WeekCount"]:checked'
    );
    const checkedValues = [];

    checkboxes.forEach((checkbox) => {
      checkedValues.push(checkbox.value);
    });

    return checkedValues.join(",");
  };
  const handleimageClick = () => {
    inputRef.current.click();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("add_emp", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    if (selectedWeeks.length > 0) {
      selectedWeeks.map((curWeek, index) => {
        form.append("emp_week_off[" + index + "][day]", curWeek);
        form.append(
          "emp_week_off[" + index + "][week_count]",
          getCheckedCheckboxes(curWeek)
        );
      });
    }
    employeeModel
      .addnewemployee(form)
      .then((response) => {
        setOpen(false);
        getEmployees();

        helper.sweetalert.toast("Your work has been saved");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const weekArray = [
    { value: "sunday", name: "Sunday", checked: false, selectedWeek: "" },
    { value: "monday", name: "Monday", checked: false, selectedWeek: "" },
    { value: "tuesday", name: "Tuesday", checked: false, selectedWeek: "" },
    { value: "wednesday", name: "Wednesday", checked: false, selectedWeek: "" },
    { value: "thursday", name: "Thursday", checked: false, selectedWeek: "" },
    { value: "friday", name: "Friday", checked: false, selectedWeek: "" },
    { value: "saturday", name: "Saturday", checked: false, selectedWeek: "" },
  ];
  const handleSubmi1 = (event) => {
    event.preventDefault();
    dispatch(addAddress({ event: event, empid: outid }));
    dispatch(closeModal());
    helper.sweetalert.toast("Your Address add.", "success");
  };
  const [num, setNum] = useState("");
  const limit = 10;
  useEffect(() => {
    dispatch(fetchAlldepartments());
  }, [busid]);

  const SubDepartmentData = useSelector((state) => {
    return state?.Business?.Allnewsubdepartments;
  });
  console.log(SubDepartmentData, "AllSubdepartments");

  useEffect(() => {
    dispatch(nofetchAllEmployees());
  }, [busid]);
  const [selectedLevel, setSelectedLevel] = useState("Select Department");
  const [selecteddeparmentId, setSlecteddeparmentId] = useState(null);
  const handleLevelSelect = (department_id, department_name) => {
    setSelectedLevel(department_name);
    setSlecteddeparmentId(department_id);
    dispatch(
      fetchAllEmployees({ selectedOptions: department_id, name: "department" })
    );
    dispatch(fetchAllSubdepartments({ Option: department_id }));
  };
  const [selectedSubdepart, setSelectedSubdepart] = useState(
    "Select Sub Department"
  );
  const handleSubDepartmentSelect = (department_id, department_name) => {
    setSelectedSubdepart(department_name);
    dispatch(fetchSubAllEmployees({ Option: department_id }));
  };
  const filterdata = () => {
    dispatch(nofetchAllEmployees());
    setSelectedLevel("Select Department");
    setSelectedSubdepart("Select Sub Department");
    setSelectedStatus("Select Status");
  };
  useEffect(() => {
    dispatch(nofetchAllEmployees());
  }, [busid]);
  const [selectedStatus, setSelectedStatus] = useState("Select Status");
  const handleStatusSelect = (value, label) => {
    setSelectedStatus(label);
    dispatch(
      fetchAllStatusEmployees({ selectedOption: value, name: "staff_type" })
    );
  };
  return (
    <>
      {/* {loading ? (
                <div>
                    <img src={loader} alt="" style={{ height: '00px', width: '500px', marginLeft: '30%' }} />
                </div>
            ) : ( */}
      <div>
        <Container fluid>
          <DropdownButton
            id="dropdown-basic-button"
            variant="secondary"
            className="custom-dropdown"
            title={selectedLevel}
            size="xl"
            style={{ fontSize: "14px" }}
          >
            {DepartmentData &&
              DepartmentData?.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() =>
                    handleLevelSelect(item.department_id, item.department_name)
                  }
                  style={{ fontSize: "14px" }}
                >
                  {item.department_name}
                </Dropdown.Item>
              ))}
          </DropdownButton>
          <DropdownButton
            id="dropdown-basic-button"
            title={selectedSubdepart}
            variant="secondary"
            size="xl"
            style={{ fontSize: "14px", marginLeft: "10px" }}
          >
            {SubDepartmentData &&
              SubDepartmentData?.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() =>
                    handleSubDepartmentSelect(
                      item.department_id,
                      item.department_name
                    )
                  }
                  style={{ fontSize: "14px" }}
                >
                  {item.department_name}
                </Dropdown.Item>
              ))}
          </DropdownButton>

          <DropdownButton
            id="dropdown-basic-button"
            className="custom-dropdown"
            title={selectedStatus}
            variant="secondary"
            size="xl"
            style={{ fontSize: "14px", marginLeft: "10px" }}
          >
            {EmpStatus &&
              EmpStatus?.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleStatusSelect(item.value, item.label)}
                  style={{ fontSize: "14px" }}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
          </DropdownButton>

          <Button
            className="btn "
            style={{
              backgroundColor: "orange",
              color: "white",
              borderStyle: "none",
              marginLeft: "10px",
            }}
            onClick={filterdata}
          >
            Clear All
          </Button>
          <Link to="/AddStaff">
            {" "}
            <Button
              className="btn "
              style={{
                backgroundColor: "#18285a",
                color: "white",
                float: "right",
                borderStyle: "none",
              }}
            >
              Add Staff
            </Button>
          </Link>

          <Row>
            <Col>
              <div
                style={{
                  "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                }}
                className=" mt-2 mb-4"
              >
                <DataTable
                  loading={loading}
                  title={"Staff List"}
                  columns={columns}
                  data={employees?.data?.emp}
                  setData={setFilterData}
                  keys={["email_id", "emp_code", "name", "designation", "cont"]}
                  filterdData={filterdData}
                  rowReorder
                />
              </div>
            </Col>
          </Row>
          <CommonModal
            // pageData={pageData}
            onSubmit={handleSubmi1}
            show={showModal}
            onHide={() => {
              dispatch(closeModal());
            }}
            name={currentPage}
            title={currentTitle}
          />
        </Container>
      </div>
      {/* )} */}
    </>
  );
};
export default Stafflist;
