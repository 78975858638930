import axiosInstance from "../lib/axiosInstance";
const Profile = class {
  
    async employee(employee = []) {
        return await axiosInstance.post("/employee.php", employee);
    }
    async Addemployee(employee = []) {
        return await axiosInstance.post("/employee.php", employee);
    }
  
    async attendance(attendance = []) {
       
        return await axiosInstance.post("/attendance.php", attendance);
    }
    async profile(profile = []) {
       
        return await axiosInstance.post("/profile.php", profile);
    }
    async Previousdata(data = []) {  
        return await axiosInstance.post("/attendance.php", data);
    }
}
export default new Profile();