import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Card, DropdownButton, Form, InputGroup, Nav  } from 'react-bootstrap';

import { useNavigate, useParams } from 'react-router-dom';
import employeeModel from '../../Model/employee.Model';
import { Link } from 'react-router-dom';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardTitle, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBProgressBar, MDBProgress, MDBIcon, MDBListGroupItem, MDBListGroup, MDBBreadcrumb, MDBBreadcrumbItem, MDBCardHeader, MDBTypography } from 'mdb-react-ui-kit';
const Persoanlbustab = (data) => {
    console.log(data.data , 'shiavnwwwwwwwww')
    const navigate = useNavigate()
    const params = useParams()
    return (

<>

      <MDBCard className="mt-3">

<MDBCardHeader>
<span style={{ marginTop: 'px', marginLeft: '10px', fontSize: '19px' }}>
                                <b style={{  }}>   Employee Details</b></span>
  
</MDBCardHeader>
<MDBCardBody>
<MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Employee ID</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.emp_code}
                    // value={formData.emp_name}
                    name='email_id'
                    readOnly
                    disabled
                    // onChange={handleInputChange}
                    // defaultValue={empprivew?.emp_code}
                    required
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Designation</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type="text" id="" placeholder={data?.data?.designation}
                    readOnly
                    disabled
                    style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
                    // value={empprivew?.designation}
                    // name='dob'
                    // onChange={handleInputChange}
                />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText> Employee Status</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.emp_status}
                    // value={formData.emp_name}
                    name='email_id'
                    disabled
                    // onChange={handleInputChange}
                    // defaultValue={empprivew?.email_id}
                    required
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Department </MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.parent_department_name}
      
                 
                    disabled
              
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Sub-Department </MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.child_department_name}
      
                 
                    disabled
              
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText> Official Mobile Number</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <InputGroup className="">
                    <DropdownButton disabled style={{backgroundColor:'#2b303b'}}
                        variant="outline-secondary"
                        title="+91"
                        id="input-group-dropdown-1"
                    >
                    </DropdownButton>
                    <Form.Control aria-label="Text input with dropdown button" placeholder={data?.data?.official_cont}
                        // defaultValue={empprivew?.cont}
                        name='cont'
                        readOnly
                        disabled
                        // onChange={handleInputChange} 
                        />
                </InputGroup>
            </MDBCardText>
        </MDBCol>
    </MDBRow>

    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText> Official Email ID</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.official_email}
                    // value={formData.emp_name}
                    name='email_id'
                    disabled
                    // onChange={handleInputChange}
                    // defaultValue={empprivew?.email_id}
                    required
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    
  
   
 
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Check In Time</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type="text" id="" className='text-capitalize' placeholder={data?.data?.start_time}
                    readOnly
                    disabled
                    style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
                    // value={empprivew?.start_time}
                    name='starttime'
                    // onChange={handleInputChange}
                />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Check out Time</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type="text" id="" className='text-capitalize' placeholder={data?.data?.end_time}
                    readOnly
                    disabled
                    style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
                    // value={empprivew?.end_time}
                    // name='endtime'
                    // onChange={handleInputChange}
                />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Attendance Type</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type="text" id="" className='text-capitalize' placeholder={data?.data?.attendance_type ?data?.data?.attendance_type:""}
                    readOnly
                    disabled
                    style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
                    // value={empprivew?.attendance_type}
                    // name='endtime'
                    // onChange={handleInputChange}
                />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>Salary</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type="text" id="" placeholder={data?.data?.salary}
                    readOnly
                    style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
                    // value={empprivew?.salary}
                    name='salary'
                    disabled
                    // onChange={handleInputChange}
                />
            </MDBCardText>
        </MDBCol>

    </MDBRow>
    <hr />
 
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>UAN No.</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.uan_number}
                    // value={formData.emp_name}
                    name='email_id'
                    disabled
                    // onChange={handleInputChange}
                    // defaultValue={empprivew?.email_id}
                    required
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>ESI Account No.</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.esi_no}
                    // value={formData.emp_name}
                    name='email_id'
                    disabled
                    // onChange={handleInputChange}
                    // defaultValue={empprivew?.email_id}
                    required
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <MDBRow>
        <MDBCol sm="3">
            <MDBCardText>PF Account No.</MDBCardText>
        </MDBCol>
        <MDBCol sm="9">
            <MDBCardText className="text-muted">
                <Form.Control type='text' placeholder={data?.data?.pf_no}
                    // value={formData.emp_name}
                    name='email_id'
                    disabled
                    // onChange={handleInputChange}
                    // defaultValue={empprivew?.email_id}
                    required
                    // inputRef={empprivew?.name}
                    // label={empprivew.name}
                    style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
            </MDBCardText>
        </MDBCol>
    </MDBRow>
    <hr />
    <button className='mt-2'  onClick={() => navigate(`/Update/${params.id}`)} style={{float:"right" , backgroundColor:"rgb(24, 40, 90)" , color:'white ', padding:'6px', borderRadius:"4px" , borderStyle:'none' }}>  Edit Profile</button>
</MDBCardBody>
</MDBCard>

      
        </>
    )
}

export default Persoanlbustab