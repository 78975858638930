import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";

export const fetchData = createAsyncThunk(
  "leave/fetchAllLeave",
  async ({status}) => {
    try {
      const formdata = new FormData();
      formdata.append("all_leaves", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      formdata.append("status",status);
      const response = await axiosInstance.post("/leave.php", formdata);
      console.log("Responseseave", response);
      // dispatch(setFilterData(response.data?.leave));
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const approvedLeave = createAsyncThunk(
  "leave/approvedLeave",
  async ( { levelId,event }) => {
    try {
      const formdata = new FormData(event.target);
      formdata.append("approve_leave", localStorage.getItem("token"));
      formdata.append("leave_id", levelId);
      const response = await axiosInstance.post("/leave.php", formdata);
      console.log("Responses leave:", response);
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const deniedLeave = createAsyncThunk(
  "leave/deniedLeave",
  async ( { levelId,event }) => {
    try {
      const formdata = new FormData(event.target);
      formdata.append("deny_leave", localStorage.getItem("token"));
      formdata.append("leave_id", levelId);
      const response = await axiosInstance.post("/leave.php", formdata);
      console.log("Responses leave:", response);
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);


export const forwardLeave = createAsyncThunk(
  "leave/forwardLeave",
  async ( { levelId,event }) => {
    try {
      const formdata = new FormData(event.target);
      formdata.append("forward_leave", localStorage.getItem("token"));
      formdata.append("leave_id", levelId);
      const response = await axiosInstance.post("/leave.php", formdata);
      console.log("Responses leave:", response);
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const forwardLeaveEmpget = createAsyncThunk(
  "leave/forwardLeaveEmpget",
  async ( { levelId }) => {
    try {
      const formdata = new FormData();
      formdata.append("get_upperLevel_emp", localStorage.getItem("token"));
      formdata.append("leave_id", levelId);
      const response = await axiosInstance.post("/leave.php", formdata);
      console.log("Responses leave:", response);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
export const leavelog = createAsyncThunk("leave/leavelog", async ( { levelId }) => {
    try {
      const formdata = new FormData();
      formdata.append("leave_log", localStorage.getItem("token"));
      formdata.append("leave_id", levelId);
      const response = await axiosInstance.post("/leave.php", formdata);
      console.log("Responses leave:", response);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
// export const handleSubmit = createAsyncThunk(
//   "payroll/handleSubmit",
//   async ({ formattedDate }) => {
//     const formdata = new FormData();
//     formdata.append("get_month_salary", localStorage.getItem("token"));
//     formdata.append("bus_id", localStorage.getItem("selecteoption"));
//     formdata.append("data_dt", formattedDate);
//     try {
//       const response = await axiosInstance.post(`/salary.php`, formdata);
//       return response;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );
// export const onMarkPaid = createAsyncThunk(
//   "payroll/onMarkPaid",
//   async ({ sal_id }) => {
//     const formdata = new FormData();
//     formdata.append("removeHoliday", localStorage.getItem("token"));
//     formdata.append("bus_id", localStorage.getItem("selecteoption"));
//     formdata.append("sal_id", sal_id);
//     try {
//       const response = await axiosInstance.post(`/salary.php`, formdata);
//       return response;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );

const leaveSlice = createSlice({
  name: "leave",
  initialState: {
    leave: [],
    errorMessage: "",
    loading: true,
    filterdData: [],
    forwardLeaveEmpget:[],
    leavelog:[],
  },
  reducers: {
    // You can add other non-async actions here if needed.
    setFilterData: (state, action) => {
      state.filterdData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        console.log("action", action);
        state.businesses = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(forwardLeaveEmpget.pending, (state) => {
        state.loading = true;
      })
      .addCase(forwardLeaveEmpget.fulfilled, (state, action) => {
        console.log("action", action);
        state.forwardLeaveEmpget = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(forwardLeaveEmpget.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(leavelog.pending, (state) => {
        state.loading = true;
      })
      .addCase(leavelog.fulfilled, (state, action) => {
        console.log("action", action);
        state.leavelog = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(leavelog.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(deniedLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(deniedLeave.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(deniedLeave.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
      .addCase(approvedLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(approvedLeave.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(approvedLeave.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
      .addCase(forwardLeave.pending, (state) => {
        state.loading = true;
      })
      .addCase(forwardLeave.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(forwardLeave.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
    //   .addCase(handleSubmit.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(handleSubmit.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.updatedData = action.payload;
    //   })
    //   .addCase(handleSubmit.rejected, (state, action) => {
    //     state.loading = false;
    //     state.errorMessage = action.payload.message;
    //   })
    //   .addCase(onMarkPaid.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(onMarkPaid.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.updatedData = action.payload;
    //   })
    //   .addCase(onMarkPaid.rejected, (state, action) => {
    //     state.loading = false;
    //     state.errorMessage = action.payload.message;
    //   });
  },
});

// Export actions
export const { setFilterData } = leaveSlice.actions;

// Export the reducer
export default leaveSlice.reducer;
