import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import employeeModel from '../Model/employee.Model';
function Staffdetails() {
  const [employeedetail, setemployeedtail] = useState(false);
  const params = useParams();

  useEffect(() => {
    console.log(params, "data")
    const formdata = new FormData();
    formdata.append("edit_emp", localStorage.getItem("token"));
    formdata.append("emp_id", params?.id);
    employeeModel.employeedetails(formdata).then((respone) => {
      console.log(respone.data.emp)
      setemployeedtail(respone.data)
    }
    )
  }, [])
  const [formData, setFormData] = useState({
        emp_name: '',
        emp_cont: '',
        emp_desg: '',
        emp_email: '',
        dob: '',
        emp_att_type: '',
        start_time: '',
        start_end: '',
        paid_leave: '',
        att_basis: '',
        salary: '',

    });
    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = (event) => {

        event.preventDefault();
        const form = new FormData(event.target);
        form.append("add_emp", localStorage.getItem("token"));
        form.append("bus_id", localStorage.getItem("bus_id"));
        employeeModel.addnewemployee(form)
            .then((response) => {
                console.log(response.data, "yes data update");
                 alert("new user")
                // Display success message to the user
            })
            .catch((error) => {
                console.log(error);
                // Display error message to the user
            });
    };

  return (
    <Table  bordered >

      <tbody>
      <tr>
          <td><button> Edit Details</button> </td>
          <td><button Link> Delete</button> </td>

        </tr>
        <tr>
          <td>name </td>
          <td><input type="text" placeholder={employeedetail.name} /> </td>

        </tr>
        <tr>
          <td>name </td>
          <td><input type="text" placeholder={employeedetail.name} /> </td>

        </tr>
        <tr>
          <td>name </td>
          <td><input type="text" placeholder={employeedetail.name} /> </td>

        </tr>
        <tr>
          <td>name </td>
          <td><input type="text" placeholder={employeedetail.name} /> </td>

        </tr>
        <tr>
          <td>name </td>
          <td><input type="text" placeholder={employeedetail.name} /> </td>

        </tr>

        <tr>
          <td>name </td>
          <td><input type="text" placeholder={employeedetail.name} /> </td>

        </tr>
        <tr>
          <td>name </td>
          <td><input type="text" placeholder={employeedetail.name} /> </td>

        </tr>


        <tr>
          <td>Designation</td>
          <td>{employeedetail.designation}</td>

        </tr>
        <tr>
          <td>Photo</td>
          <td> <img src={`http://3.111.95.141/api/pro_pic/${employeedetail.profile_img}`} alt="Avatar" className="m-1" style={{ width: "150px", height: '150px', borderRadius: "50px" }} />
          </td>

        </tr>
        <tr>
          <td>NUmber</td>
          <td> {employeedetail.cont}</td>
        </tr>


      </tbody>
    </Table>
  );
}

export default Staffdetails;