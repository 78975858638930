import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import employeeModel from "../../../Model/employee.Model";
import { helper } from "../../../lib/helper";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardImage,
  MDBCardText,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { AiFillPhone } from "react-icons/ai";
import { MdEmail, MdOutlineDateRange } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { FaPeopleArrows } from "react-icons/fa";
import "../../../App.css";
import moment from "moment";
const EmpNomineeDetails = () => {
  const params = useParams();
  const [EmgCont, setEmgCont] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [outid, setOutid] = useState(0);
  const [showModal1, setShowModal1] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShowModal1(false);
  const handleShow1 = () => setShowModal1(true);
  const [EmgSingle, setEmgSingle] = useState();
  const [formattedDate, setFormattedDate] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    relation: "",
    email: "",
    address: "",
    contact_no: "",
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("emp_id", params?.id);
    form.append("add_contact", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    employeeModel
      .addempemg(form)
      .then((res) => {
        console.log(res.data);
        helper.sweetalert.toast("Your Address add.", "success");
        fetchData();
        handleClose();
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const [data,setData] = useState(null)
  const fetchData = async () => {
    const formdata = new FormData();
    formdata.append("edit_emp", localStorage.getItem("token"));
    formdata.append("emp_id", params.id);
    employeeModel
      .Editemplyee(formdata)
      .then((res) => {
        
        setData(res?.data?.emp);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, [params?.id]);

  const onDelete = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("delete_contact", localStorage.getItem("token"));
          formdata.append("del_id", id);
          employeeModel.delemgcont(formdata).then((res) => {
            console.log(res.data);
            helper.sweetalert.toast(
              "Deleted",
              "Your Conatact has been deleted.",
              "success"
            );
            fetchData();
            handleClose();
          });
        }
      });
  };
  const toggleModal1 = (id) => {
    displaydata(id);
    setShowModal1(!showModal1);
  };
 
  const displaydata = async (id) => {
    const formdata = new FormData();
    formdata.append("edit_emp", localStorage.getItem("token"));
    formdata.append("emp_id", params.id);
    employeeModel
      .emgsinglecont(formdata)
      .then((res) => {
        console.log(res.data.emg_contact);
        // setData(res.data);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const [formData1, setFormData1] = useState({
    name: "",
    relation: "",
    email: "",
    address: "",
    contact_no: "",
  });
  const handleChange = (event) => {
    setFormData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
  };
  const fromDate = moment(formattedDate).format("DD-MM-YYYY");
  const handleInSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("update_employee", localStorage.getItem("token"));
    form.append("emp_id", params?.id);
    form.append("nominee_dob",fromDate );
 
    employeeModel
      .Editemplyee(form)
      .then((res) => {
        console.log(res.data);
        handleClose1(true);
        fetchData();
        helper.sweetalert.toast("Your Nominee Details update.", "success");
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };

  console.log(data, "datapawan");

  const handleDateChangee = (event) => {
    const selectedDate = event.target.value;
    setFormattedDate(selectedDate);
  };
  useEffect(()=>{
    if (data) {
      if (data?.dob !== "") {
        setFormattedDate(
          moment(data?.nominee_dob, "DD-MM-YYYY").format("YYYY-MM-DD")
        )}}
  },[])

  return (
    <MDBCard className="mt-3">
      <MDBCardHeader>
        <Row>
          <Col>
            <div style={{ position: "relative" }}>
              <span
                style={{
                  marginTop: "px",
                  marginLeft: "10px",
                  fontSize: "19px",
                }}
              >
                <b>Nominee Details</b>
              </span>
              {/* <button
                className="btn"
                style={{
                  float: "right",
                  backgroundColor: "#18285a",
                  color: "white",
                }}
                onClick={handleShow}
              >
                Add New
              </button> */}
            </div>
          </Col>
        </Row>
      </MDBCardHeader>
      <MDBCardBody>
        <div>
          <>
            <Container className="mt-3">
              {data && (
                <div className="gradient-custom-2 mt-3">
                  <MDBContainer className=" h-100">
                    <MDBRow className="justify-content-center align-items-center h-100">
                      <MDBCol lg="9" xl="12">
                        <MDBCard>
                          <div
                            className="rounded-top text-white d-flex flex-row"
                            style={{
                              backgroundColor: "#2b303b",
                              height: "150px",
                            }}
                          >
                            <div className="ms-3 mt-4 d-flex flex-column">
                              <MDBContainer className="d-flex justify-content-start">
                                <Card className="p-4">
                                  <div className="mt-2">
                                    <MDBTypography tag="h5">
                                      {data.nominee_name}
                                    </MDBTypography>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <Button
                                      onClick={() => toggleModal1(params.id)}
                                      className="w-0"
                                      style={{ backgroundColor: "#18285a" }}
                                      outline
                                      color="dark"
                                    >
                                      <BiEdit />
                                    </Button>
                                    {/* <Button
                                      onClick={() => {
                                        onDelete(params.id);
                                      }}
                                      className="w-0"
                                      style={{ backgroundColor: "#18285a" }}
                                      outline
                                      color="dark"
                                    >
                                      <MdDeleteForever />
                                    </Button> */}
                                  </div>
                                </Card>
                              </MDBContainer>
                            </div>
                          </div>
                          <div
                            className="p-4 text-black mt-5"
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="d-flex justify-content-between text-center py-1">
                              <div>
                                <MDBCardText className="mb-1 h5">
                                  <FaPeopleArrows />
                                </MDBCardText>
                                <MDBCardText className="small text-muted mb-0">
                                  {data.nominee_relation}
                                </MDBCardText>
                              </div>
                              <div>
                                <MDBCardText className="mb-1 h5">
                                  <AiFillPhone />
                                </MDBCardText>
                                <MDBCardText className="small text-muted mb-0">
                                  {data.nominee_cont}
                                </MDBCardText>
                              </div>
                              <div>
                              
                                <MDBCardText className="mb-1 h5">
                                <MdOutlineDateRange />
                                </MDBCardText>
                                <MDBCardText className="small text-muted mb-0">
                                  {data.nominee_dob}
                                </MDBCardText>
                              </div>

                              <div>
                                <MDBCardText className="mb-1 h5">
                                  <MdEmail />
                                </MDBCardText>
                                <MDBCardText className="small text-muted mb-0">
                                  {data.nominee_email}
                                </MDBCardText>
                             
                              </div>
                            </div>
                          </div>
                        </MDBCard>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              )}
            </Container>
          </>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Nominee Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <form onSubmit={handleSubmit}>
                  <Container>
                    <Row>
                      <Col sm={4}>
                        <span style={{ fontSize: "17px", marginTop: "3px" }}>
                          Name
                        </span>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="name"
                          onChange={handleInputChange}
                          placeholder="Add  Name"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                          required
                          id="form1"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <span style={{ fontSize: "17px", marginTop: "3px" }}>
                          Relation
                        </span>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="relation"
                          onChange={handleInputChange}
                          placeholder="Add Relation"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <span style={{ fontSize: "17px", marginTop: "3px" }}>
                          Email ID
                        </span>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={handleInputChange}
                          placeholder="Add Email"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <span style={{ fontSize: "17px", marginTop: "3px" }}>
                          Address
                        </span>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          name="address"
                          onChange={handleInputChange}
                          placeholder="Add Address"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <span style={{ fontSize: "17px", marginTop: "3px" }}>
                          Number
                        </span>
                      </Col>
                      <Col sm={8}>
                        <Form.Control
                          type="number"
                          name="contact_no"
                          onChange={handleInputChange}
                          placeholder="Add Number"
                          required
                          id="form1"
                          style={{
                            height: "35px",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="mt-4">
                      {/* <Col sm={6}>
                        <Button
                          className=""
                          style={{
                            backgroundColor: "gray",
                            color: "white",
                            borderStyle: "none",
                          }}
                          onClick={() => handleClose()}
                        >
                          {" "}
                          Close
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "#18285a",
                            borderStyle: "none",
                            float: "right",
                          }}
                          className=""
                          // style={{
                          //     width: "116px",
                          //     borderRadius: " 25px",
                          //     padding: "10px",
                          //     position: 'relative', float: "right",
                          //     backgroundColor: '#18285a', color: 'white',
                          //     marginTop: '20px', marginBottom: '20px'

                          // }}
                        >
                          {" "}
                          Submit
                        </Button>
                      </Col> */}

                      <Button
                        style={{ float: "left", marginLeft: "5%" }}
                        className="w-25"
                        variant="secondary"
                        onClick={() => handleClose()}
                      >
                        Close
                      </Button>
                      <Button
                        style={{
                          float: "right",
                          borderStyle: "none",
                          marginRight: "5%",
                        }}
                        className="btn submitButtons w-25"
                        type="submit"
                        variant="primary"
                      >
                        Submit
                      </Button>
                    </div>
                  </Container>
                  {/* <div className='d-flex align-items-center justify-content-end gap-2 mt-2'>
                                        <Button type="submit"
                                            style={{ backgroundColor: '#18285a' }}
                                            className='w-25'
                                        // style={{
                                        //     width: "116px",
                                        //     borderRadius: " 25px",
                                        //     padding: "10px",
                                        //     position: 'relative', float: "right",
                                        //     backgroundColor: '#18285a', color: 'white',
                                        //     marginTop: '20px', marginBottom: '20px'

                                        // }}  
                                        > submit</Button>
                                        <Button className='w-25' style={{ backgroundColor: 'gray', color: 'white' }} onClick={() => handleClose()} > Close</Button>
                                    </div> */}
                  {/* <button type='submit' className='btn mt-4 mb-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white', marginRight: '10px' }}> Submit</button> */}
                </form>
                {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{
                                    float: 'left', marginLeft
                                        : "10px"
                                }}>Close</button> */}
              </div>
            </Modal.Body>
          </Modal>
          {showModal1 && (
            <Modal show={showModal1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Nominee Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <form onSubmit={handleInSubmit}>
                    <Container>
                      <Row>
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            Name
                          </span>
                        </Col>
                        <Col sm={8}>
                          <input
                            type="hidden"
                            name="update_id"
                            value={EmgSingle?.id}
                            id=""
                          />
                          <input
                            type="text"
                            name="nominee_name"
                            onChange={handleChange}
                            defaultValue={data?.nominee_name}
                            required
                            id=""
                            style={{
                              height: "35px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            Relation
                          </span>
                        </Col>
                        <Col sm={8}>
                          <input
                            type="text"
                            name="nominee_relation"
                            onChange={handleChange}
                            defaultValue={data?.nominee_relation}
                            required
                            id=""
                            style={{
                              height: "35px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            Email Id
                          </span>
                        </Col>
                        <Col sm={8}>
                          <input
                            type="email"
                            name="nominee_email"
                            onChange={handleChange}
                            defaultValue={data?.nominee_email}
                            required
                            id=""
                            style={{
                              height: "35px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </Col>
                      </Row>
                   
                      <Row className="mt-2">
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            Number
                          </span>
                        </Col>
                        <Col sm={8}>
                          <input
                            type="number"
                            name="nominee_cont"
                            onChange={handleChange}
                            maxLength={10}
                            minLength={10}
                            defaultValue={data?.nominee_cont}
                            required
                            id=""
                            style={{
                              height: "35px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm={4}>
                          <span style={{ fontSize: "17px", marginTop: "3px" }}>
                            DOB
                          </span>
                        </Col>
                        <Col sm={8}>
                          <input
                            type="date"
                            // name="nominee_dob"
                            value={formattedDate}
                            onChange={handleDateChangee}
                            required
                            id=""
                            style={{
                              height: "35px",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </Col>
                      </Row>
                      <div className="mt-4">
                        {/* <Col sm={6}>
                          <Button
                            className=""
                            style={{
                              backgroundColor: "gray",
                              color: "white",
                              borderStyle: "none",
                            }}
                            onClick={() => handleClose()}
                          >
                            {" "}
                            Close
                          </Button>
                        </Col>
                        <Col sm={6}>
                          <Button
                            type="submit"
                            style={{
                              backgroundColor: "#18285a",
                              borderStyle: "none",
                              float: "right",
                            }}
                            className=""
                            // style={{
                            //     width: "116px",
                            //     borderRadius: " 25px",
                            //     padding: "10px",
                            //     position: 'relative', float: "right",
                            //     backgroundColor: '#18285a', color: 'white',
                            //     marginTop: '20px', marginBottom: '20px'

                            // }}
                          >
                            {" "}
                            Submit
                          </Button>
                        </Col> */}

                        <Button
                          style={{ float: "left", marginLeft: "5%" }}
                          className="w-25"
                          variant="secondary"
                          onClick={() => handleClose1()}
                        >
                          Close
                        </Button>
                        <Button
                          style={{
                            float: "right",
                            borderStyle: "none",
                            marginRight: "5%",
                          }}
                          className="btn submitButtons w-25"
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                      </div>
                    </Container>
                    <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
                      {/* <Button className='w-25' style={{ backgroundColor: 'gray', color: 'white' ,borderStyle:"none"}} onClick={() => handleClose()} > Close</Button> */}
                    </div>
                    {/* <button type='submit' className='btn mt-4 mb-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white', marginRight: '10px' }}> Submit</button> */}
                  </form>
                  {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{
                                        float: 'left', marginLeft
                                            : "10px"
                                    }}>Close</button> */}
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default EmpNomineeDetails;
