import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import { helper } from "../../lib/helper";

export const fetchData = createAsyncThunk(
  "roll/fetchAllRoll",
  async (_, { dispatch }) => {
    try {
      const formdata = new FormData();
      formdata.append("emp_list", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      const response = await axiosInstance.post("/employee.php", formdata);
      console.log("Response roll:", response);
      dispatch(setFilterData(response.data));
      return response;  
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
export const fetchDataAllRollList = createAsyncThunk(
  "roll/fetchDataAllRollList",
  async (_, { dispatch }) => {
    try {
      const formdata = new FormData();
      formdata.append("role_lists", localStorage.getItem("token"));
      formdata.append("bus_id", localStorage.getItem("selecteoption"));
      const response = await axiosInstance.post("/roles.php", formdata);
      console.log("Response rolllists:", response?.data);
      dispatch(setFilterData(response?.data));
      return response?.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);
export const fetchDataAllPermissionsRollList = createAsyncThunk(
  "roll/fetchDataAllPermissionsRollList",
  async (_, { dispatch }) => {
    try {
      const formdata = new FormData();
      formdata.append("permissions_lists", localStorage.getItem("token"));
      // formdata.append("bus_id", localStorage.getItem("selecteoption"));
      const response = await axiosInstance.post("/roles.php", formdata);
      console.log("Response rolllists:", response?.data);
      // dispatch(setFilterData(response.data));
      return response?.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

export const fetchDataAssignEmpRollList = createAsyncThunk(
  "roll/fetchDataAssignEmpRollList",
  async ({ id }) => {
    try {
      const formdata = new FormData();
      formdata.append("role_detail", localStorage.getItem("token"));
      formdata.append("roleId", id);
      const response = await axiosInstance.post("/roles.php", formdata);
      // console.log("Responserolllists", response.data);
      // dispatch(setFilterData(response.data));
      return response?.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    } 
  }
);


export const fetchDatRoleListByRoleId = createAsyncThunk(
  "roll/fetchDatRoleListByRoleId",
  async ({ id }) => {
    try {
      const formdata = new FormData();
      formdata.append("role_lists", localStorage.getItem("token"));
      formdata.append("roleId", id);
      const response = await axiosInstance.post("/roles.php", formdata);
      console.log("Responserolllists", response.data);
      // dispatch(setFilterData(response.data));
  
      return response?.data;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    } 
  }
);



export const addRole = createAsyncThunk("roll/addRole", async (event) => {
  const form = new FormData(event.target);
  form.append("add_role", localStorage.getItem("token"));
  form.append("bus_id", localStorage.getItem("selecteoption"));
  // form.append("email_count", 1);

  try {
    const response = await axiosInstance.post("/roles.php", form);

    helper.sweetalert.toast(
      response?.data?.message
      // "Your Role has been added.",
      // "success"
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const assignRollEmp = createAsyncThunk(
  "roll/assignrollemp",
  async ({ roleId, empid }) => {
    const form = new FormData();
    form.append("assign_role", localStorage.getItem("token"));
    form.append("roleId", roleId);
    form.append("employee_ids", empid);
    // form.append("email_count", 1);

    try {
      const response = await axiosInstance.post("/roles.php", form);

      helper.sweetalert.toast(
        response?.data?.message
        // "Role has been Assigend Emp",
        // "success"
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const unAssignRollEmp = createAsyncThunk(
  "roll/unassignrollemp",
  async ({ roleId, empid }) => {
    const form = new FormData();
    form.append("unassign_role", localStorage.getItem("token"));
    form.append("emp_id", empid);
    // form.append("email_count", 1);

    try {
      const response = await axiosInstance.post("/roles.php", form);

      helper.sweetalert.toast(
        response?.data?.message
        // "Role has been Assigend Emp",
        // "success"
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// export const ViewEmployeeSuperviderStaff = createAsyncThunk(
//   "roll/ViewEmployeeSuperviderStaff",
//   async ({empid }) => {
//     const form = new FormData();
//     form.append("assigned_emp_list", localStorage.getItem("token"));
//     form.append("reporting_manager_emp_id", empid);
    

//     try {
//       const response = await axiosInstance.post("/roles.php", form);
//       return response.data;
//     } catch (error) {
//       return error.response.data;
//     }
//   }
// );




export const editRoll = createAsyncThunk(
  "roll/editRoll",
  async ({ rollId ,event}) => {
    const formdata = new FormData(event.target);
    formdata.append("update_role", localStorage.getItem("token"));
    // formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("role_id", rollId);
    console.log(event.target,"event")
    try {
      const response = await axiosInstance.post(`/roles.php`, formdata);
   
      helper.sweetalert.toast(
        response?.data?.message,
        "Your Role has been update.",
        "success"
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeRoll = createAsyncThunk(
  "staff/removeRoll",
  async ({ id: id }, { dispatch }) => {
    const formdata = new FormData();
    formdata.append("delete_role", localStorage.getItem("token"));
    // formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("role_id", id);
    try {
      const response = await axiosInstance.post("/roles.php", formdata, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: formdata,
      });
      return response;
    } catch (error) {
      console.error("API Error:", error);
      return error.response.data;
    }
  }
);

const rollSlice = createSlice({
  name: "roll",
  initialState: {
    roll: [],
    errorMessage: "",
    loading: true,
    filterdData: [],
    fetchDataAllRollList: [],
    selectedData: null,
    fetchDataAssignEmpRollList:[],
  },
  reducers: {
    // You can add other non-async actions here if needed.
    clearSelectedData: (state) => {
      state.selectedData = null;
    },

    setFilterData: (state, action) => {
      state.filterdData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        console.log("action", action);
        state.businesses = action.payload; // Update state with fetched data
        state.loading = false;
      })
      .addCase(fetchData.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchDataAllRollList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDataAllRollList.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchDataAllRollList = action.payload;
      })
      .addCase(fetchDataAllRollList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
     
      .addCase(fetchDataAllPermissionsRollList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDataAllPermissionsRollList.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchDataAllPermissionsRollList = action.payload;
      })
      .addCase(fetchDataAllPermissionsRollList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(fetchDataAssignEmpRollList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDataAssignEmpRollList.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchDataAssignEmpRollList = action.payload;
      })
      .addCase(fetchDataAssignEmpRollList.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(fetchDatRoleListByRoleId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDatRoleListByRoleId.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedData = action.payload;
      })
      .addCase(fetchDatRoleListByRoleId.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
      .addCase(removeRoll.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeRoll.fulfilled, (state, action) => {
        state.loading = false;
        state.employees = action.payload;
      })
      .addCase(removeRoll.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(addRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(addRole.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(addRole.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      })
      .addCase(assignRollEmp.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignRollEmp.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(assignRollEmp.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      }) 
      .addCase(unAssignRollEmp.pending, (state) => {
        state.loading = true;
      })
      .addCase(unAssignRollEmp.fulfilled, (state, { load }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(unAssignRollEmp.rejected, (state, { load }) => {
        state.loading = false;
        state.errorMessage = load.message;
      }) 
      .addCase(editRoll.pending, (state) => {
        state.loading = true;
      })
      .addCase(editRoll.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedData = action.payload;
        // Handle success or update state accordingly
      })
      .addCase(editRoll.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload.message;
      })
    //   .addCase(onMarkPaid.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(onMarkPaid.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.updatedData = action.payload;
    //   })
    //   .addCase(onMarkPaid.rejected, (state, action) => {
    //     state.loading = false;
    //     state.errorMessage = action.payload.message;
    //   });
  },
});

// Export actions
export const { clearSelectedData, setFilterData } = rollSlice.actions;

// Export the reducer
export default rollSlice.reducer;
