import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import employeeModel from "../Model/employee.Model";
import RoleModal from "../Model/Role.modal";
import { Link } from "react-router-dom";
import DataTable from "../components/Tables/Tables";
import CommonModal from "../components/Common/CommonModal";
import {
  closeDeleteModal,
  closeEditModal,
  closeModal,
  openDeleteModal,
  openEditModal,
  openModal,
} from "../store/silce/modalSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllEmployees,
  nofetchAllEmployees,
  removeEmployee,
  setFilterData,
} from "../store/silce/staffSlice";
import {
  addLevel,
  assignLevelData,
  clearSelectedData,
  editLevels,
  fetchAlllevels,
  setLoading,
  setManageLevel,
  setSelectedData,
  removelevel,
  fetchLevelById,
} from "../store/silce/levelSlice";
import { helper } from "../lib/helper";
import {
  supervisedStaffAdd,
  ViewEmployeeSuperviderStaff,
} from "../store/silce/staffSlice";

import TableDropdown from "../components/TableDropDown/TableDropdown";
import CommonEditModal from "../components/Common/CommonEditModal";
import { unAssignRollEmp } from "../store/silce/roll";

const RoleLevelManege = () => {
  const employees = useSelector((state) => state.staff.employees);

  const busid = useSelector((state) => state?.selectOption?.value);
  const filterdData = useSelector((state) => {
    return state.staff.filterdData;
  });
  const loading = useSelector((state) => {
    return state.level.loading;
  });

  console.log(loading, "loadingasdsad");
  const [selectedLevel, setSelectedLevel] = useState("Level-0");
  const [selectedLevelId, setSelectedLevelId] = useState(null);

  const handleLevelSelect = (level, id) => {
    setSelectedLevel(level);
    setSelectedLevelId(id);

    dispatch(fetchLevelById({ selectedLevelId: id }));
  };

  const levelList = useSelector((state) => state.level.levels);

  console.log(levelList, "levelListsdfdfs");
  const levelListById = useSelector((state) => state?.level?.levelsId?.levels);
  const assignedEmpData =
    levelListById && levelListById?.map((item) => item.assigned_emp);
  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const selectedEmpId = useSelector((state) => state.level.selectedLevel);
  const [outid, setOutid] = useState(selectedEmpId?.id);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(nofetchAllEmployees());
    handleLevelSelect("Level-0");
  }, [busid]);
  // useEffect(() => {
  //   // Dispatch the action with the updated outid
  //   if (outid) {
  //     dispatch(ViewEmployeeSuperviderStaff({ empid: outid }));
  //   }
  // }, [outid, dispatch]);

  const ViewSupervisedListEmp = useSelector(
    (state) => state?.staff?.ViewEmployeeSuperviderStaff?.emp
  );
  console.log(ViewSupervisedListEmp, "ViewSupervisedListEmp");

  useEffect(() => {
    dispatch(fetchAlllevels());
    dispatch(ViewEmployeeSuperviderStaff({ empid: outid }));
  }, [outid, dispatch, busid]);

  const UnAssignRollEMp = (id) => {
    dispatch(unAssignRollEmp({ empid: id || id }));
    nofetchAllEmployees();
    // handleClose();
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "150px",
    },

    {
      name: "Staff Name",
      selector: (row) => row.name,
      cell: (row) => {
        return (
          <div style={{ textDecoration: "none" }}>
            <Link
              to={`/Staffview/${row.id}`}
              style={{ textDecoration: "none" }}
            >
              {row.name} {row.last_name}
            </Link>
          </div>
        );
      },
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
    },

    {
      name: "Role Name",
      selector: (row) => row.roleName,
    },

    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            isViewEmpPermissionsRole={true}
            allowEmpAssign={row.is_reporting_manager}
            page={"stafflist"}
            id={row}
            openModal={() => {
              setOutid(row?.id);
              dispatch(openModal("Supervised-Staff-Assign-Emp"));
              dispatch(ViewEmployeeSuperviderStaff({ empid: row?.id }));
            }}
            UnAssignRollEMp={() => {
              UnAssignRollEMp(row.id);
            }}
            openModalStaffView={() => {
              setOutid(row?.id);
              dispatch(openModal("Supervised-Staff-Assign-Emp-view"));
              dispatch(ViewEmployeeSuperviderStaff({ empid: row?.id }));
            }}
            setOutid={row?.id}
            openEditModal={() => {
              setOutid(row?.id);
              dispatch(setSelectedData(row));
              dispatch(openEditModal("edit-level-managment_emp"));
            }}
          />
        );
      },
    },
  ];

  //   console.log(selectedLevelId,"selectedLevelId")
  const [levelId, setlevelId] = useState("");

  const handleLevelChange = (event, selectedId) => {
    setlevelId(selectedId);
  };

  const levelAssigned = (event) => {
    event.preventDefault();
    dispatch(assignLevelData({ outid: outid, levelId: levelId }));
    dispatch(closeEditModal());
    dispatch(fetchAlllevels());
  };
  const [showComponents, setShowComponents] = useState([]);
  const showModal = useSelector((state) => state.modal.showModal);
  const [currentTitle, setCurrentTitle] = useState("Selected Employee");
  const currentPage = useSelector((state) => state.modal.currentPage);

  const [empId, setEmpId] = useState([]);

  const handleEmpChange = (event) => {
    const itemId = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // If the checkbox is checked, add the item to the selectedItems
      setEmpId((prevSelectedItems) => [...prevSelectedItems, itemId]);
    } else {
      // If the checkbox is unchecked, remove the item from the selectedItems
      setEmpId((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    }
  };
  // console.log(outid,"outid")
  // console.log(empId,"empId")

  const getEmployees = () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("selecteoption")
    ) {
      // const formdata = new FormData();
      // formdata.append("emp_list", localStorage.getItem("token"));
      // formdata.append("bus_id", localStorage.getItem("selecteoption"));
      dispatch(ViewEmployeeSuperviderStaff({ empid: outid }));
      dispatch(nofetchAllEmployees());
      // setFilterstaff(employees?.data?.emp)
      // setIsLoading(false)
      // auth.employee(formdata).then((res) => {
      //     //console.log(res.data.emp)
      //     setStaff(res?.data?.emp)
      //     setFilterstaff(res?.data?.emp);
      //     setIsLoading(false)
      // }).catch((error) => {
      //     console.log("error => ", error)
      // })
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(supervisedStaffAdd({ outid: outid, empid: empId }));
    dispatch(closeModal());
    dispatch(nofetchAllEmployees());
    dispatch(ViewEmployeeSuperviderStaff({ empid: outid }));
    getEmployees();
  };

  console.log(empId, "empId");
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <DropdownButton
              id="dropdown-basic-button"
              className="custom-dropdown"
              title={selectedLevel}
              variant="secondary"
              size="xl"
              style={{ fontSize: "14px" }}
            >
              {/* <Dropdown.Item onClick={() => handleLevelSelect('Level-0')}>Level-0</Dropdown.Item>  */}
              {levelList &&
                levelList?.map((item, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleLevelSelect(item.name, item.id)}
                    style={{ fontSize: "14px" }}
                  >
                    {item.name}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
            <Button
              className="btn "
              style={{
                backgroundColor: "orange",
                color: "white",
                borderStyle: "none",
                marginLeft: "10px",
              }}
              // onClick={() => {
              //   setSelectedLevelTitle("Select Status");
              //   setSelectedLevel("Select Status");
              // }}
              onClick={() => handleLevelSelect("Level-0")}
            >
              Clear
            </Button>

            <Link to="/Role">
              <Button
                className="submitButtons"
                style={{
                  float: "right",
                  marginLeft: "10px",
                  color: "white",
                  textDecoration: "none",
                  borderStyle: "none",
                }}
              >
                Manage Role
              </Button>
            </Link>
            <Link to="/level-management">
              <Button
                className="submitButtons"
                style={{
                  float: "right",
                  marginLeft: "10px ",
                  borderStyle: "none",
                }}
              >
                Manage Level
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
              }}
              className=" mt-3 mb-4"
            >
              <DataTable
                loading={loading}
                title={"Staff List"}
                columns={columns}
                data={
                  selectedLevel === "Level-0"
                    ? employees?.data?.emp
                    : assignedEmpData?.[0]
                }
                setData={setFilterData}
                keys={["email_id", "emp_code", "name", "designation"]}
                filterdData={filterdData}
              />
            </div>
          </Col>
          <CommonModal
            size="md"
            pageData={
              selectedLevel === "Level-0"
                ? employees?.data?.emp
                : assignedEmpData?.[0]
            }
            onChange={handleEmpChange}
            onSubmit={handleSubmit}
            show={showModal}
            onHide={() => {
              dispatch(closeModal());
              dispatch(clearSelectedData());
            }}
            name={currentPage}
            title={currentTitle}
            showComponents={showComponents}
            setShowComponents={setShowComponents}
            selectedItems={empId}
          />
          <CommonEditModal
            onChange={handleLevelChange}
            onSubmit={levelAssigned}
            show={showEditModal}
            onHide={() => {
              dispatch(closeEditModal());
              dispatch(clearSelectedData());
            }}
            name={"edit-level-managment-emp"}
            title={"Edit Level Managment"}
          />
        </Row>
      </Container>
    </>
  );
};

export default RoleLevelManege;
