import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  DropdownButton,
  Dropdown,
  Button,
} from "react-bootstrap";
import EmphomeModal from "../Modal/Emphome.Modal";
import DataTable from "../../components/Tables/Tables";
const Salary = () => {
  const [EmpDetails, setEmpDetails] = useState();
  const [selectedPaid, setSelectedPaid] = useState("Salary type");
  const [selectedYear, setSelectedYear] = useState("Selected year");
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("emp_salary", localStorage.getItem("token"));
    formdata.append(
      "sal_type",
      selectedPaid === "Salary type" ? "not paid" : selectedPaid
    );
    formdata.append(
      "sal_year",
      selectedYear === "Selected year" ? "2024" : selectedYear
    );
    EmphomeModal.EmpSalaryhistory(formdata)
      .then((res) => {
       
        setEmpDetails(res.data);
      })
      .catch((error) => {
        
      });
  }, [selectedPaid, selectedYear]);
  

  const cardData = [
    {
      key: "Paid",
      title: "Paid",
      colorClass: "text-danger",
      data: EmpDetails?.last_month_paid,
    },
    {
      key: "Advance",
      title: "Advance",
      colorClass: "text-primary",
      data: EmpDetails?.last_month_advance,
    },
    { key: "Due", title: "Due", data: EmpDetails?.last_month_due },
  ];

 

  //   const [selecteddeparmentId, setSlecteddeparmentId] = useState(null);
  const handleSalarytypeSelect = (name) => {
    setSelectedPaid(name);
  };

  const handleSalaryyearSelect = (year) => {
    setSelectedYear(year);
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      //   width: "4%",
      //   center: "center",
    },
    {
      name: "paid",
      selector: (row) => row.paid,
      // center:'center',
      //   width: "4%",
    },
    {
      name: "due",
      selector: (row) => row.due,
      //   width: "12%",
    },
    {
      name: "Pay Date",
      selector: (row) => row.pay_date,
      //   center: "center",/
      width: "12%",
    },
    {
      name: "Year",
      selector: (row) => row.year,
      //   center: "center",
      //   width: "10%",
    },
    {
      name: "Month Name",
      selector: (row) => row.month_name,
      // center: "center",
      // width: "10%",
    },
  ];
  return (
    <div>
      <Container fluid>
        <DropdownButton
          id="dropdown-basic-button"
          variant="secondary"
          className="custom-dropdown"
          title={selectedYear}
          size="xl"
          style={{ fontSize: "14px" }}
        >
          {EmpDetails?.year &&
            EmpDetails?.year?.map((item, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  style={{ fontSize: "14px" }}
                  onClick={() => handleSalaryyearSelect(item)}
                >
                  {item}
                </Dropdown.Item>
              );
            })}
        </DropdownButton>
        <DropdownButton
          id="dropdown-basic-button"
          variant="secondary"
          className="custom-dropdown"
          title={selectedPaid}
          size="xl"
          style={{ fontSize: "14px", marginLeft: "10px" }}
        >
          <Dropdown.Item
            style={{ fontSize: "14px" }}
            onClick={() => handleSalarytypeSelect("paid")}
          >
            paid
          </Dropdown.Item>
          <Dropdown.Item
            style={{ fontSize: "14px" }}
            onClick={() => handleSalarytypeSelect("not paid")}
          >
            not paid
          </Dropdown.Item>
        </DropdownButton>
        <Button
          className="btn "
          style={{
            backgroundColor: "orange",
            color: "white",
            borderStyle: "none",
            marginLeft: "10px",
          }}
          onClick={() => {
            setSelectedPaid("not paid")
             setSelectedYear("2024");
          }}
        >
          Clear All
        </Button>
        <Row className="mt-5 mb-2">
        <span className="mb-2" style={{fontSize:"15px",fontWeight:"bold"}}>Last Month</span>
          {cardData?.map((data, index) => (
            <Col key={index}>
              <Card>
                <p
                  className={`text-center mt-1 ${data.colorClass}`}
                  style={{ fontSize: "35px", fontWeight: "900" }}
                >
                  {data.data}
                </p>
                <h5
                  className="text-center mt-1 mb-2"
                  style={{ fontWeight: "900" }}
                >
                  {data.title}
                </h5>
              </Card>
            </Col>
          ))}
        </Row>

        <DataTable
          //   loading={loading}
          columns={columns}
          data={EmpDetails?.salary}
          title={"Salary List"}
          page={"salarylist"}
          searchData={EmpDetails?.salary}
          //   setData={setFilterData}
          keys={["name", "contact", "business_sector_name"]}
          //   filterdData={filterdData}
        />
      </Container>
    </div>
  );
};

export default Salary;
