import React, { useState, useEffect } from "react";
import EmphomeModal from "../Modal/Emphome.Modal";
import DataTable from "../../components/Tables/Tables";

import { getEmpNoticeList ,setFilterData} from "../../store/silce/Employee";
import { useDispatch, useSelector } from "react-redux";

const Empnotice = () => {
  const [Empnotice, setEmpnotice] = useState();
  useEffect(() => {
    const formdata = new FormData();
    formdata.append("get_notice", localStorage.getItem("token"));
    EmphomeModal.EmpNotice(formdata).then((res) => {
      setEmpnotice(res.data.notice);
   
    });
  }, []);
  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      maxWidth: "0px",
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "Notice File",
      selector: (row) => row.notice_file,
    },
    {
      name: "Send by",
      selector: (row) => row.sender_name,
    },
    {
      name: "Sender Position",
      selector: (row) => row.sender_position,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
    },
  ];

  const dispatch = useDispatch();
 

  const leaveNoticeListsList = useSelector((state) => {
    return state?.employee?.getEmpNoticeList?.notice;
  });

  

  const filterdData = useSelector((state) => {
    return state.employee?.filterdData;
  });




  const loading = useSelector((state) => {
    return state.employee?.loading;
  });

  useEffect(() => {
    dispatch(getEmpNoticeList());
  }, []);
  return (
    <div>
      <DataTable
        loading={loading}
        columns={columns}
        data={leaveNoticeListsList}
        title={"Notice History"}
        setFilterData={setFilterData}
        keys={["message","sender_name","subject"]}
        filterdData={filterdData}
      />
    </div>
  );
};

export default Empnotice;
