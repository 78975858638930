import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import { useParams } from 'react-router-dom';
// import { CSVLink } from 'react-csv'
import { Container, Row, Col, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import AttModal from '../Model/Att.modal';

const Dayattendance = () => {
    const [toadayatt, setToadayatt] = useState([]);
    const params = useParams();
    const [formData, setFormData] = useState({
        dtd: '',
        dtm: '',
        dty: '',
        
    
    });
    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = (event) => {
    
        event.preventDefault();
        const form = new FormData(event.target);
        
        form.append("emp_id", params?.id);
        form.append("get_day_history", localStorage.getItem("token"));
       
    
        AttModal.dayatthisorty(form).then((res)=>{
            setToadayatt(res.data)
       
        }
        ).catch((error) => {
       
        })
    }
    
    return (
        <>
            
                    <Container fluid >
                        <Row>
                            <Col>
                                
                                <Card style={{height:'100px'}} className='mt-2'>
                                <form onSubmit={handleSubmit}>
                                    <label for="dtd" style={{paddingLeft:'40px',paddingTop:'40px'}}><b>Select Date</b></label>

                                    <select name="dtd" id="dtd" style={{width:"150px",marginLeft:'15px',paddingLeft:"50px"}}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>

                                    </select>
                                    <label for="dtm" style={{paddingLeft:'40px',paddingTop:'40px'}}><b>Month</b></label>

                                    <select name="dtm" id="dtm" style={{width:"150px",marginLeft:'15px',paddingLeft:"50px"}}>
                                        <option value="01 ">January </option>
                                        <option value="02">February</option>
                                        <option value="03 ">March </option>
                                        <option value="04 ">April </option>
                                        <option value="05">May</option>
                                        <option value="06 ">June </option>
                                        <option value="07 ">July </option>
                                        <option value="08">August</option>
                                        <option value="09 ">September </option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                        
                                    </select>
                                    
                                    <label for="dty" style={{paddingLeft:'40px',paddingTop:'40px'}}><b>Year</b></label>

                                    <select name="dty" id="dty" style={{width:"150px",marginLeft:'15px',paddingLeft:"50px"}}>
                                        <option value="2022 ">2022 </option>
                                        <option value="2023">2023</option>
                                        
                                    </select>
                                    <button  type="submit" style={{
                                            width:"116px",
                                            borderRadius:" 25px",
                                            padding: "4px",
                                            backgroundColor: "#18285a",
                                            color: "white",
                                            position:"relative",
                                            float:"right",
                                            marginRight:'40px',marginTop:'40px'

                                    }} > Submit</button>
                                </form>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                    <Col sm={6}> <p className='text-center mt-4'><b  >Check In</b></p></Col>
                    <Col sm={6}><p className='text-center mt-4'><b  >Check Out </b></p></Col>
                </Row>
                <Row>
                    {/* <h4 className=' mt-2 mb-4'>Attendance Details</h4> */}
                    {
                        toadayatt.data?.list && toadayatt.data?.list.map((todaylist,i) =>
                            <Col key={i} sm={6} className=' mt-3 mb-3'>
                                <div>

                                    <Card style={{ width: '100%', marginTop: "20px", "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px", }} className='m-1 shadow-2'>
                                        <div style={{ paddingLeft: "10px" }} className='m-2' >



                                            <span className='mt-3 mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16"  style={{marginRight:'3px'}}>
                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                            </svg><b className='text-primary'> Time</b> {todaylist?.tim}</span>  <span className='mt-3' style={{marginLeft:'20px'}}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
                                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                                            </svg> <b className='text-primary'> Mode </b> {todaylist?.attendance_mode}</span>
                                            <button style={{float:'right',borderRadius:'8px',backgroundColor:'hsl(168.24deg 70.28% 63.76% / 60%)'}}>On Time</button>

                                            <br />
                                            <br /><span className='mt-3' style={{
                                                "overflow": "hidden",
                                                height: '40px',
                                                " text-overflow": "ellipsis"
                                                ,
                                            }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16" style={{marginRight:'3px'}}>
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                </svg><b className='text-primary'>Location</b> {todaylist?.location}</span>
                                              
                                        </div>
                                    </Card>

                                    
                                </div>

                            </Col>
                        )
                    }
                     <center> <button style={{float:'right',borderRadius:'8px',backgroundColor:'hsl(168.24deg 70.28% 63.76% / 60%)',width:'100%'}} className='mb-4'>Total Working Hours 08:00 Hr</button></center>
                </Row>
                    </Container>
            
        </>
    )
}
export default Dayattendance;