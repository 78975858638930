// import React, { useEffect, useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// // import { clearSelectedData, setFilterData } from '../store/silce/bussiness';
// // import {  closeEditModal, closeModal, openEditModal, openModal } from '../store/silce/modalSlice';

// import DataTable from "../../components/Tables/Tables";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   removelevel,
//   addLevel,
//   assignLevelData,
//   clearSelectedData,
//   editLevels,
//   fetchAlllevels,
//   setFilterData,
//   setSelectedData,
// } from "../../store/silce/levelSlice";
// import CommonModal from "../../components/Common/CommonModal";
// import CommonEditModal from "../../components/Common/CommonEditModal";
// import { Button } from "@mui/material";
// import TableDropdown from "../../components/TableDropDown/TableDropdown";
// import {
//   closeDeleteModal,
//   closeEditModal,
//   closeModal,
//   openDeleteModal,
//   openEditModal,
//   openModal,
// } from "../../store/silce/modalSlice";
// import { useNavigate } from "react-router-dom";
// import UnassignedModal from "./UnassignedModal";
// import AssignModal from "./AssignModal";
// import { helper } from "../../lib/helper";

// const LevelList = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const selectedLevel = useSelector((state) => state.level.selectedLevel);
//   const [currentTitle, setCurrentTitle] = useState("add-level-management");
//   const [unassignModalOpen, setUnassignModalOpen] = useState(false);
//   const [assignModalOpen, setAssignModalOpen] = useState(false);
//   const [outid, setOutid] = useState(selectedLevel?.id);
//   const levelList = useSelector((state) => state.level.levels);
//   const loading = useSelector((state) => {
//     return state.level.loading;
//   });
//   const busid = useSelector(state =>state?.selectOption?.value)
//   const filterdData = useSelector((state) => {
//     return state.level.filterdData;
//   });
//   const showModal = useSelector((state) => state.modal.showModal);
//   const currentPage = useSelector((state) => state.modal.currentPage);
//   const showEditModal = useSelector((state) => state.modal.showEditModal);

//   const fetchAll = () => {
//     console.log("api call leves");
//     dispatch(fetchAlllevels());
//   };
//   useEffect(() => {
//     dispatch(fetchAlllevels());
//   }, [busid]);
//   const onDELETE = (id) => {
//     helper.sweetalert
//       .confirm(
//         "Are you sure?",
//         "You won't be able to revert this!",
//         "warning",
//         true
//       )
//       .then((result) => {
//         if (result.isConfirmed) {
//           dispatch(removelevel({ id: id })).then(
//             () => (
//               helper.sweetalert.toast(
//                 "Deleted",
//                 "Your Level has been deleted.",
//                 "success"
//               ),
//               fetchAll()
//             )
//           );
//         }
//       });
//   };

//   const columns = [
//     {
//       name: "S.No",
//       cell: (row, index) => index + 1,
//       width: "70px",
//     },

//     {
//       name: "Level Name",
//       selector: (row) => row.name,
//     },
//     {
//       name: "Assigned Emp Count",
//       selector: (row) => row.assigned_emp_count,
//     },
//     {
//       name: "Level",
//       selector: (row) => row.level,
//     },
//     {
//       name: "Accept Permission",
//       selector: (row) => row.accept_permission,
//       cell:(row) => {
//         if (row.accept_permission === "Forward" ? true : row.accept_permission === "forward") {
//           return (
//             <p>Forward</p>
//           )
//         } if (row.accept_permission === "accept" ? true : row.accept_permission === "Forward Admin"){
//           return ( <p>Accepted</p>)
//         }
//         else if (row.accept_permission === "accept_forward" ){
//           return ( <p>Accept & Forward</p>)
//         }
//         else if (row.accept_permission === "" ){
//           return ( <p>{""}</p>)
//         }
//         }
//     },
//     {
//       name: "Updated On",
//       selector: (row) => row.updated_on,
//     },
//     {
//       name: "Added On",
//       selector: (row) => row.added_on,
//     },
//     {
//       name: "Action",
//       cell: (row) => {
//         return (
//           <TableDropdown
//             onDeleteClick={() => onDELETE(row?.id)}
//             onClick={() => onDELETE(row?.id)}
//             openEditModal={() => {
             
//               dispatch(openEditModal("edit-level-managment"));
//               setOutid(row?.id);
//               dispatch(setSelectedData(row));

//             }}
//             isRemove={true}
//             isEdit={true}
//             removeAssignId={() => {
//               dispatch(setSelectedData(row));
//               setUnassignModalOpen(true);
//             }}
//             setCurrentTitle={setCurrentTitle}
//             page="levelManagement"
//             id={row?.id}
//             handleAssign={() => {
//               setOutid(row?.id);
//               dispatch(setSelectedData(row));
//               setAssignModalOpen(true);
//             }}
//           />
//         );
//       },
//     },
//   ];
//   const pageData = {};
//   const [formData, setFormData] = useState({
//     add_level: localStorage.getItem("token"),
//     bus_id: localStorage.getItem("selecteoption"),
//     name: "",
//     accept_permission: "",
//   });

//   const [formData1, setFormData1] = useState({
//     update_level: localStorage.getItem("token"),
//     bus_id: localStorage.getItem("selecteoption"),
//     name: "",
//     accept_permission: "",
//     level_id: outid,
//   });
//   const [assignLevel, setAssignLevel] = useState({
//     assign_emps: localStorage.getItem("token"),
//     bus_id: localStorage.getItem("selecteoption"),
//     level_id: outid,
//     emp_ids: "",
//   });

//   const [empId, setEmpId] = useState("");

//   const handleLevelChange = (event, selectedId) => {
//     setEmpId(selectedId);
//   };

//   const levelAssigned = (event) => {
//     event.preventDefault();
//     dispatch(assignLevelData({ outid: empId, levelId: outid }));
//     dispatch(fetchAlllevels());
//     setAssignModalOpen(false);
//   };
// const[Newvalue ,setNewvalue] =useState()
//   const [num, setNum] = useState("");
//   const handleInputChange = (event) => {
//     setFormData({
//       ...formData,
//       [event.target.name]: event.target.value,
//     });

//     if (event.target.type === "number") {
//       setNum(event.target.value.slice(0));
//     }

//       var checkbox1 = document.getElementById("accept");
//       var checkbox2 = document.getElementById("forward");

//       if (checkbox1.checked && checkbox2.checked) {
//           var combinedValue = checkbox1.value + "_" + checkbox2.value;
//           console.log(combinedValue ,'combinedValue')
//           setNewvalue(combinedValue)
//        // You can replace this with your desired action (e.g., sending the value to a server).
//       } else if (checkbox1.checked) {
//         setNewvalue(checkbox1.value) 
 
//       } else if (checkbox2.checked) {
//         setNewvalue(checkbox2.value)
      
//       }

//   };
//   console.log(Newvalue ,'combinedValue')
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     dispatch(addLevel({formData:formData ,combinedValues:Newvalue} ));
//     dispatch(closeModal());
//     dispatch(fetchAlllevels());
//   };
//   const limit = 10
//   const handleInputChange1 = (event) => {
//     setFormData1({
//         ...formData1,
//         [event.target.name]: event.target.value,
//     });
//     let value = event.target.value;
//     if (event.target.type === "number") {
//         value = value.length > limit ? value.substring(0, limit) : value;
//     }
//     console.log(value, event.key, event, num);
//     event.target.value = value;
//     var checkbox1 = document.getElementById("accept");
//     var checkbox2 = document.getElementById("forward");

//     if (checkbox1.checked && checkbox2.checked) {
//         var combinedValues = checkbox1.value + "_" + checkbox2.value;
//         console.log(combinedValues ,'combinedValue')
//         setNewvalues(combinedValues)
//      // You can replace this with your desired action (e.g., sending the value to a server).
//     } else if (checkbox1.checked) {
//       setNewvalues(checkbox1.value) 

//     } else if (checkbox2.checked) {
//       setNewvalues(checkbox2.value)
    
//     }
// };
//   const handleSubmit1 = (event) => {
//     event.preventDefault();
//     dispatch(editLevels(formData1 ,{combinedValues:Newvalues}));
//     dispatch(fetchAlllevels());
//     dispatch(closeEditModal());
//   };
//   useEffect(() => {
//     setFormData1({
//       ...formData1,
//       level_id: selectedLevel?.id,
//     });
//     setAssignLevel({
//       ...assignLevel,
//       level_id: selectedLevel?.id,
//     });
//     dispatch(fetchAlllevels());
//   }, [selectedLevel ,busid]);

//   console.log("assign", assignLevel);
//   return (
//     <>
//       <div>
//         <Container fluid>
//           <Row>
//             <Col>
//               <div className="mt-0 mb-5 gap-2">
//                 <div>
//                   {" "}
//                   <button
//                     className="btn "
//                     style={{
//                       float: "right",
//                       backgroundColor: "#18285a",
//                       color: "white",
//                     }}
//                     onClick={() => {
//                       dispatch(openModal("add-level-management"));
//                       setCurrentTitle("Add Level");
//                     }}
//                   >
//                     {" "}
//                     Add Level
//                   </button>
//                 </div>
//                 <div></div>
//               </div>

//               <div className="mt-2 mb-4">
//                 <DataTable
//                   loading={loading}
//                   columns={columns}
//                   data={levelList}
//                   title={"Level"}
//                   page={"levelList"}
//                   searchData={levelList}
//                   setData={setFilterData}
//                   keys={["id", "name"]}
//                   filterdData={filterdData}
//                 />
//               </div>
//             </Col>
//           </Row>
//         </Container>
//         <CommonModal
//           pageData={pageData}
//           onChange={handleInputChange}
//           onSubmit={handleSubmit}
//           show={showModal}
//           onHide={() => {
//             dispatch(closeModal());
//             dispatch(clearSelectedData());
//           }}
//           name={currentPage}
//           title={currentTitle}
//         />

//         <CommonEditModal
//           pageData={pageData}
//           onChange={handleInputChange1}
//           onSubmit={handleSubmit1}
//           show={showEditModal}
//           onHide={() => {
//             dispatch(closeEditModal());
//             dispatch(clearSelectedData());
//           }}
//           name={"edit-level-managment"}
//           title={"Edit Level Managment"}
//         />

//         <UnassignedModal
//           tableData={() => dispatch(fetchAlllevels())}
//           levelList={levelList}
//           open={unassignModalOpen}
//           close={setUnassignModalOpen}
//         />
//         <AssignModal
//           open={assignModalOpen}
//           close={() => setAssignModalOpen(false)}
//           onChange={handleLevelChange}
//           onSubmit={levelAssigned}
//         />
//       </div>
//     </>
//   );
// };
// export default LevelList;


import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { clearSelectedData, setFilterData } from '../store/silce/bussiness';
// import {  closeEditModal, closeModal, openEditModal, openModal } from '../store/silce/modalSlice';

import DataTable from "../../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  removelevel,
  addLevel,
  assignLevelData,
  clearSelectedData,
  editLevels,
  fetchAlllevels,
  setFilterData,
  setSelectedData,
  fetchLevelById,
} from "../../store/silce/levelSlice";
import CommonModal from "../../components/Common/CommonModal";
import CommonEditModal from "../../components/Common/CommonEditModal";
import { Button } from "@mui/material";
import TableDropdown from "../../components/TableDropDown/TableDropdown";
import {
  closeDeleteModal,
  closeEditModal,
  closeModal,
  openDeleteModal,
  openEditModal,
  openModal,
} from "../../store/silce/modalSlice";
import { useNavigate } from "react-router-dom";
import UnassignedModal from "./UnassignedModal";
import AssignModal from "./AssignModal";
import { helper } from "../../lib/helper";

const LevelList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLevel = useSelector((state) => state.level.selectedLevel);
  const [currentTitle, setCurrentTitle] = useState("add-level-management");
  const [unassignModalOpen, setUnassignModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [outid, setOutid] = useState(selectedLevel?.id);
  const levelList = useSelector((state) => state.level.levels);
  const loading = useSelector((state) => {
    return state.level.loading;
  });
  const busid = useSelector(state =>state?.selectOption?.value)
  const filterdData = useSelector((state) => {
    return state.level.filterdData;
  });
  const showModal = useSelector((state) => state.modal.showModal);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const fetchAll = () => {
   
    dispatch(fetchAlllevels());
  };

  useEffect(() => {
    dispatch(fetchAlllevels());
  }, [busid]);
  const displaydata = async (LevelId) => {
    dispatch(fetchLevelById({ selectedLevelId: LevelId }));
    setOutid(LevelId);
  };
  const onDELETE = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(removelevel({ id: id })).then(
            () => (
              helper.sweetalert.toast(
                "Deleted",
                "Your Level has been deleted.",
                "success"
              ),
              fetchAll()
            )
          );
        }
      });
  };
  const updatedData = useSelector((state) => {
    return state?.level?.selectedLevel;
  });
  

  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Level Name",
      selector: (row) => row.name,
    },
    {
      name: "Assigned Emp Count",
      selector: (row) => row.assigned_emp_count,
    },
    {
      name: "Level",
      selector: (row) => row.level,
    },
    {
      name: "Accept Permission",
      selector: (row) => row.accept_permission,
      cell:(row) => {
        if (row.accept_permission === "Forward" ? true : row.accept_permission === "forward") {
          return (
            <p>Forward</p>
          )
        } if (row.accept_permission === "accept" ? true : row.accept_permission === "Forward Admin"){
          return ( <p>Accepted</p>)
        }
        else if (row.accept_permission === "accept_forward" ){
          return ( <p>Accept & Forward</p>)
        }
        else if (row.accept_permission === "" ){
          return ( <p>{""}</p>)
        }
        }
    },
    {
      name: "Updated On",
      selector: (row) => row.updated_on,
    },
    {
      name: "Added On",
      selector: (row) => row.added_on,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <TableDropdown
            onDeleteClick={() => onDELETE(row?.id)}
           
            onClick={() => onDELETE(row?.id)}
            openEditModal={() => {
              dispatch(openEditModal("edit-level-managment"));
              setOutid(row?.id);
              dispatch(setSelectedData(row));
              displaydata(row.id)
            }}
            displaydata={row.id}
            isRemove={true}
            isEdit={true}
            removeAssignId={() => {
              dispatch(setSelectedData(row));
              setUnassignModalOpen(true);
            }}
            setCurrentTitle={setCurrentTitle}
            page="levelManagement"
            id={row?.id}
            handleAssign={() => {
              setOutid(row?.id);
              dispatch(setSelectedData(row));
              setAssignModalOpen(true);
            }}
           
          />
        );
      },
    },
  ];
  const pageData = {};
  const [formData, setFormData] = useState({
    add_level: localStorage.getItem("token"),
    bus_id: localStorage.getItem("selecteoption"),
    name: "",
    accept_permission: "",
  });

  const [formData1, setFormData1] = useState({
    // update_level: localStorage.getItem("token"),
    // bus_id: localStorage.getItem("selecteoption"),
    name: "",
    accept_permission: "",
    level_id: outid,
  });
  const [assignLevel, setAssignLevel] = useState({
    assign_emps: localStorage.getItem("token"),
    bus_id: localStorage.getItem("selecteoption"),
    level_id: outid,
    emp_ids: "",
  });

  const [empId, setEmpId] = useState("");

  const handleLevelChange = (event, selectedId) => {
    setEmpId(selectedId);
  };

  const levelAssigned = (event) => {
    event.preventDefault();
    dispatch(assignLevelData({ outid: empId, levelId: outid }));
    dispatch(fetchAlllevels());
    setAssignModalOpen(false);
  };
const[Newvalue ,setNewvalue] =useState()
  const [num, setNum] = useState("");
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

    if (event.target.type === "number") {
      setNum(event.target.value.slice(0));
    }

      var checkbox1 = document.getElementById("accept");
      var checkbox2 = document.getElementById("forward");

      if (checkbox1.checked && checkbox2.checked) {
          var combinedValue = checkbox1.value + "_" + checkbox2.value;
         
          setNewvalue(combinedValue)
      } else if (checkbox1.checked) {
        setNewvalue(checkbox1.value)
      } else if (checkbox2.checked) {
        setNewvalue(checkbox2.value)
      }
  };
 
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addLevel({formData:formData ,combinedValues:Newvalue}));
    dispatch(closeModal());
    dispatch(fetchAlllevels());
  };


  const limit = 10
  const handleInputChange1 = (event) => {
    setFormData1({
        ...formData1,
        [event.target.name]: event.target.value,
    });
    const handleInputChange1 = (event) => {
      setFormData1({
          ...formData1,
          [event.target.name]: event.target.value,
      });
      let value = event.target.value;
      // if (event.target.type === "number") {
      //     value = value.length > limit ? value.substring(0, limit) : value;
      // }
      event.target.value = value;
  };
    
    let value = event.target.value;
    if (event.target.type === "number") {
        value = value.length > limit ? value.substring(0, limit) : value;
    }
    
    event.target.value = value;
    var checkbox1 = document.getElementById("accept");
    var checkbox2 = document.getElementById("forward");

    if (checkbox1.checked && checkbox2.checked) {
        var combinedValue = checkbox1.value + "_" + checkbox2.value;
        
        setNewvalue(combinedValue)
     // You can replace this with your desired action (e.g., sending the value to a server).
    } else if (checkbox1.checked) {
      // Only checkbox1 is checked, send its individual value
   
      setNewvalue(checkbox1.value)
      // You can then send checkbox1.value to the server or perform any other action
    } else if (checkbox2.checked) {
      // Only checkbox2 is checked, send its individual value
      setNewvalue(checkbox2.value)
      // You can then send checkbox2.value to the server or perform any other action
    }
};

  const handleSubmit1 = (event) => {
    event.preventDefault();
    dispatch(editLevels( {event:event ,combinedValues:Newvalue,outid:outid}));
    displaydata(updatedData?.id);
    dispatch(closeEditModal());
    dispatch(fetchAlllevels());
  };
  useEffect(() => {
    setFormData1({
      ...formData1,
      level_id: selectedLevel?.id,
    });
    setAssignLevel({
      ...assignLevel,
      level_id: selectedLevel?.id,
    });
    dispatch(fetchAlllevels());
  }, [selectedLevel ,busid]);


  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
              <div className="mt-0 mb-5 gap-2">
                <div>
                  {" "}
                  <button
                    className="btn "
                    style={{
                      float: "right",
                      backgroundColor: "#18285a",
                      color: "white",
                    }}
                    onClick={() => {
                      dispatch(openModal("add-level-management"));
                      setCurrentTitle("Add Level");
                    }}
                  >
                    {" "}
                    Add Level
                  </button>
                </div>
                <div></div>
              </div>

              <div className="mt-2 mb-4">
                <DataTable
                  loading={loading}
                  columns={columns}
                  data={levelList}
                  title={"Level"}
                  page={"levelList"}
                  searchData={levelList}
                  setData={setFilterData}
                  keys={["  "]}
                  filterdData={filterdData}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <CommonModal
          pageData={pageData}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          show={showModal}
          onHide={() => {
            dispatch(closeModal());
            dispatch(clearSelectedData());
          }}
          name={currentPage}
          title={currentTitle}
        />

        <CommonEditModal
          pageData={updatedData}
          onChange={handleInputChange1}
          onSubmit={handleSubmit1}
          show={showEditModal}
          onHide={() => {
            dispatch(closeEditModal());
            dispatch(clearSelectedData());
          }}
          name={"edit-level-managment"}
          title={"Edit Level Managment"}
        />

        <UnassignedModal
          tableData={() => dispatch(fetchAlllevels())}
          levelList={levelList}
          open={unassignModalOpen}
          close={setUnassignModalOpen}
        />
        <AssignModal
          open={assignModalOpen}
          close={() => setAssignModalOpen(false)}
          onChange={handleLevelChange}
          onSubmit={levelAssigned}
        />
      </div>
    </>
  );
};
export default LevelList;
