import React, { useState, useEffect } from 'react';
import '../../Aseset/css/sidenav.css';
import { Container, Row, Col } from 'react-bootstrap';
import DataTable from '../../components/Tables/Tables';
import TableDropdown from '../../components/TableDropDown/TableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../components/Common/CommonModal';
import { addBusiness, businesssectorFetch, clearSelectedData, editBusiness, fetchAllBusinesses, setFilterData, updateSubmit } from '../../store/silce/bussiness';
import { closeEditModal, closeModal, openEditModal, openModal } from '../../store/silce/modalSlice';
import CommonEditModal from '../../components/Common/CommonEditModal';
import { fetchAlllevels } from '../../store/silce/levelSlice';

const Levellist = () => {
    const dispatch = useDispatch()
    const [outid, setOutid] = useState(0)
    const [currentTitle, setCurrentTitle] = useState('add-business')
    const showModal = useSelector((state) => state.modal.showModal);
    const showEditModal = useSelector((state) => state.modal.showEditModal);
    const currentPage = useSelector((state) => state.modal.currentPage);
    const businessesList = useSelector((state) => { return state.Business.businessesList });
    const loading = useSelector((state) => { return state.Business.loading })
    const updatedData = useSelector((state) => { return state.Business.updatedData })
    const sectorData = useSelector((state) => { return state.Business.sector })
    const filterdData = useSelector((state) => { return state.Business.filterdData })
    const displaydata = async (id) => {
        dispatch(editBusiness({ id: id }))
        setOutid(id);
    }
    useEffect(() => {
        dispatch(fetchAllBusinesses())
        dispatch(businesssectorFetch())
    }, []);


    const columns = [
        {
            name: 'S. No',
            cell: (row, index) => index + 1,
        },
        {
            name: 'Company Logo',
            selector: (row) => row.bus_logo,
            cell: (row) => {
                return (
                    <img src={`http://qrstaff.in/api/logo/${row.bus_logo}`}
                        alt="Avatar"
                        style={{ width: "30px", height: '30px', borderRadius: "10px" }} />
                );
            },
        },

        {
            name: 'Company Name',
            selector: (row) => row.name,
        },
        {
            name: 'Company Sector',
            selector: (row) => row.business_sector_name,
        },
        {
            name: 'Mobile Number',
            selector: (row) => row.contact,
        },
        {
            name: 'Total Staff',
            selector: (row) => row.staff_count,
        },
        {
            name: 'Salary Type',
            selector: (row) => row.salary_basis,
            cell: row => {
                return (
                    <div>

                        <span className='text-capitalize'>{row.salary_basis}</span></div>
                )

            },
        },
        {
            name: 'Company Email Addrerss',
            selector: (row) => row.business_mails,

        },
        {
            name: 'Action',
            cell: (row) => {
                return (
                    <TableDropdown openEditModal={() => dispatch(openEditModal('edit-business'))} isRemove={true} isEdit={true} displaydata={displaydata} setCurrentTitle={setCurrentTitle} page="businessList" id={row.id} />
                )
            },
        },
    ]


    const [formData, setFormData] = useState({
        add_site: localStorage.getItem("token"),
        cl_name: '',
        cl_cont: '',
        bus_sector: '',
        salary_basis: '',
        cl_email1: '',
        email_count: '1',
    });
    const [num, setNum] = useState('');
    const handleInputChange = (event) => {

        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });

        if (event.target.type === "number") {
            setNum(event.target.value.slice(0, limit));
        }

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(addBusiness(formData));
    };

    const limit = 10;
    const [formData1, setFormData1] = useState({
        bus_name: '',
        staff_count: '',
        salary_basis: '',
        whatsapp: '',
        bus_sector: '',
        bus_id: '',
        cl_email1: '',
        email_count: '1'
    });
    const handleInputChange1 = (event) => {
        setFormData1({
            ...formData1,
            [event.target.name]: event.target.value,
        });
        let value = event.target.value;
        if (event.target.type === "number") {
            value = value.length > limit ? value.substring(0, limit) : value;
        }
       
        event.target.value = value;
    };

    const handleSubmit1 = (event) => {
        event.preventDefault();
        dispatch(updateSubmit({ outid: outid, event: event }))
        displaydata(updatedData?.id)
        dispatch(fetchAllBusinesses())
        dispatch(closeModal())
    };
    const pageData = {
        outid: outid
    }


    useEffect(() => {
        dispatch(fetchAlllevels())
    }, []);
    return (
        <>
            <div>
                <Container fluid >
                    <Row>
                        <Col>
                            <div className='mt-0 mb-5'>
                                <button className='btn ' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }} onClick={() => {
                                    dispatch(openModal("add-business"))
                                    setCurrentTitle("Add New Business")
                                }}> Add Business</button>
                            </div>
                            <div style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px" }} className=' mt-2 mb-4'>
                                <DataTable loading={loading} columns={columns} data={businessesList} title={"Level List"}
                                    page={"businessList"}
                                    searchData={businessesList} setData={setFilterData}
                                    keys={['name', 'contact']}
                                    filterdData={filterdData}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <CommonModal pageData={pageData} onChange={currentPage === 'add-business' ? handleInputChange : handleInputChange1} onSubmit={
                    currentPage === 'edit-business' ? handleSubmit1 : handleSubmit
                } show={showModal} onHide={() => {
                    dispatch(closeModal())
                    dispatch(clearSelectedData())
                }} name={currentPage} title={currentTitle} />
                <CommonEditModal pageData={pageData} onChange={handleInputChange1} onSubmit={handleSubmit1} show={showEditModal} onHide={() => {
                    dispatch(closeEditModal())
                    dispatch(clearSelectedData())
                }} name={'edit-business'} title={'Edit Business'} />
            </div>
        </>
    )
}
export default Levellist;