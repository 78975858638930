import axiosInstance from "../lib/axiosInstance";
const Leavehistory = class {
  
  
    async leavehistory(leavehistory ) {
       
        return await axiosInstance.post("/leave.php", leavehistory);
    }
    async approveleave(leavehistory ) {
       
        return await axiosInstance.post("/leave.php", leavehistory);
    }
    async denieleave(leavehistory ) {
       
        return await axiosInstance.post("/leave.php", leavehistory);
    }
    async empleave(leavehistory ) {
       
        return await axiosInstance.post("/leave.php", leavehistory);
    }
    
}

export default new Leavehistory();