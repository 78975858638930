import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../lib/axiosInstance";
import { helper } from "../../lib/helper";

// Create an async thunk for getting all businesses



// Create an async thunk for adding a business
export const addHoliday = createAsyncThunk("holiday/addHoliday", async ({ name, formattedDate }) => {
  const formdata = new FormData();
  formdata.append("addNewHoliday", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("holidayName", name);
  formdata.append("holidayDate", formattedDate);
  try {
    const response = await axiosInstance.post("/holidays.php", formdata);
    helper.sweetalert.toast("Added New Holiday")
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const addAllHolidays = createAsyncThunk("holiday/addAllHolidays", async () => {
  const formdata = new FormData();
  formdata.append("addAllHolidays", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  try {
    const response = await axiosInstance.post("/holidays.php", formdata);
    helper.sweetalert.toast("Added All Holiday")
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

// Create an async thunk for deleting a business
export const removeHoliday = createAsyncThunk("holiday/removeHoliday", async ({ holidayId }) => {
  const formdata = new FormData();
  formdata.append("removeHoliday", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("holidayId", holidayId);
  try {
    const response = await axiosInstance.post(`/holidays.php`, formdata);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const fetchAllHolidays = createAsyncThunk("holiday/fetchAllHolidays", async ({ selectedYear }, { dispatch }) => {
  const formdata = new FormData();
  formdata.append("getHolidayLists", localStorage.getItem("token"));
  formdata.append("bus_id", localStorage.getItem("selecteoption"));
  formdata.append("sel_year", selectedYear);
  try {
    const response = await axiosInstance.post("/holidays.php", formdata);
    console.log("Response holiday:", response.data?.data?.holidays);
    dispatch(setFilterData(response.data?.data?.holidays))
    return response;
  } catch (error) {
    console.error("API Error:", error);
    return error.response.data;
  }
});

const holidaySlice = createSlice({
  name: "holiday",
  initialState: {
    holidayslist: [],
    errorMessage: "",
    loading: true,
    filterdData: [],
  },
  reducers: {
    // You can add other non-async actions here if needed.
    setFilterData: (state, action) => {
      state.filterdData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllHolidays.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllHolidays.fulfilled, (state, action) => {
        state.loading = false;
        state.holidayslist = action.payload;
      })
      .addCase(fetchAllHolidays.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
        
      })
      .addCase(addHoliday.pending, (state) => {
        state.loading = true;
      })
      .addCase(addHoliday.fulfilled, (state, { payload }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(addHoliday.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      })
      .addCase(removeHoliday.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeHoliday.fulfilled, (state, { payload }) => {
        state.loading = false;
        // Handle success or update state accordingly
      })
      .addCase(removeHoliday.rejected, (state, { payload }) => {
        state.loading = false;
        state.errorMessage = payload.message;
      });
  },
});

// Export actions
export const { /* Other non-async actions */ setFilterData } = holidaySlice.actions;

// Export the reducer
export default holidaySlice.reducer;
