import axiosInstance from "../lib/axiosInstance";
const Businessdetails = class {
  
    async Businesslist(data) {
       
        return await axiosInstance.post("/get_bus.php", data);
    }

    async ChangeDefaultBusiness(data ) {
       
        return await axiosInstance.post("/change_bussiness.php", data);
    }    
    async salarybasis(data) {
       
        return await axiosInstance.post("/get_bus.php", data);
    }
    async businesssector(data) {
       
        return await axiosInstance.post("/get_bus.php", data);
    }
    async Addnewbusiness(data) {
       
        return await axiosInstance.post("/add_bus.php", data);
    }
    async deletebusiness(data) {
       
        return await axiosInstance.post("/add_bus.php", data);
    }
    async editbusiness(data) {
       
        return await axiosInstance.post("/add_bus.php", data);
    }
    async displaybus(data) {
       
        return await axiosInstance.post("/get_bus.php", data);
    }
    async Adddepartments(data) {
        return await axiosInstance.post("/departments.php", data);
    }
}
export default new Businessdetails();