import axiosInstance from "../lib/axiosInstance";
const Hday = class {
    async allHoliday(data = []) {
        return await axiosInstance.post("/holidays.php", data);
    }
    async removeholiday(data = []) {
        return await axiosInstance.post("/holidays.php", data);
    }
    async addAllholi(data = []) {
        return await axiosInstance.post("/holidays.php", data);
    }
    async Addnew(data = []) {
        return await axiosInstance.post("/holidays.php", data);
    }
    async Addholiday(data = []) {
        return await axiosInstance.post("/holidays.php", data);
    }
}
export default new Hday();