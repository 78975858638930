/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Tabs,
  Tab,
  Button,
  Form,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import employeeModel from "../../Model/employee.Model";
import MediaPreview from "./MediaPreview";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
import DataTable from "../../components/Tables/Tables";
import TableDropdown from "../../components/TableDropDown/TableDropdown";
import { helper } from "../../lib/helper";
import "../../App.css";

const Document = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShowModal1(false);
  const handleShow1 = () => setShow1(true);
  const [mntatt, setMntatt] = useState([]);
  const [shwoDoc, setShowDoc] = useState([]);
  const [doclist, setDoclist] = useState([]);
  const [doclistfilter, setDoclistfilter] = useState([]);
  const params = useParams();
  const [formData, setFormData] = useState({
    docs_name: "",
    document: "",
    doc_type_id: "",
    doc_number: "",
  });
  const [formData1, setFormData1] = useState({
    docs_name: "",
    document: "",
    doc_type_id: "",
    doc_number: "",
  });
  const toggleModal = () => {
    console.log("current = ", showModal);
    setShowModal(!showModal);
    setShow(!show);
  };
  const toggleModal1 = () => {
    console.log("current = ", showModal1);
    setShowModal1(!showModal1);
    setShow(!show1);
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChange1 = (event) => {
    setFormData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
  };
  const [Docid, setDocId] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);

    form.append("emp_id", params?.id);
    form.append("upload_emp_docs", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    employeeModel
      .documents(form)
      .then((res) => {
        setMntatt(res.data);
        AllDocGet();
        sendDataToAPI(Docid);
        helper.sweetalert.toast(res.data.message, "success");
        toggleModal();
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const [selectedValueId, setSelectedValueId] = useState("");
  const [selectedValueDocName, setSelectedValueDocName] = useState("");
  const handleSubmit1 = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    form.append("update_emp_docs", localStorage.getItem("token"));
    form.append("doc_id", EmgSingle?.doc_id);
    form.append("doc_type_id", selectedValueId);
    form.append("docs_name", selectedValueDocName);
    employeeModel
      .editDisplayDocment(form)
      .then((res) => {
        // setMntatt(res.data);
        AllDocGet();
        sendDataToAPI(Docid);
        helper.sweetalert.toast(res.data.message, "success");
        handleClose1();
        toggleModal1();
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  useEffect(() => {
    const form = new FormData();
    form.append("get_doc_types", localStorage.getItem("token"));
    employeeModel
      .doclist(form)
      .then((res) => {
        console.log(res.data.document_type);
        setDoclist(res.data.document_type);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  }, []);

  const [EmgSingle, setEmgSingle] = useState();
  console.log(EmgSingle, "EmgSingle");
  const displaydata = async (id) => {
    const formdata = new FormData();
    formdata.append("get_docs_detail", localStorage.getItem("token"));
    formdata.append("doc_id", id);
    // formdata.append("emp_id",params?.id );
    employeeModel
      .editDisplayDocment(formdata)
      .then((res) => {
        setEmgSingle(res?.data?.documents);
        console.log(res.data, "singh");
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const AllDocGet = () => {
    const form = new FormData();
    form.append("emp_id", params?.id);
    form.append("get_emp_docs", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    employeeModel
      .documents(form)
      .then((res) => {
        setShowDoc(res.data.documents);
        const media = res.data.documents;
        const parts = url.split(".");
        const fileExtension = parts[parts.length - 1].toLowerCase();
        let mediaType;
        if (
          fileExtension === "png" ||
          fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "gif"
        ) {
          mediaType = "image";
        } else if (
          fileExtension === "mp4" ||
          fileExtension === "avi" ||
          fileExtension === "mov"
        ) {
          mediaType = "video";
        } else if (fileExtension === "pdf") {
          mediaType = "pdf";
        } else {
          mediaType = "unknown";
        }
        console.log(`Media type: ${mediaType}`);
        console.log(res.data.documents);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  useEffect(() => {
    AllDocGet();
  }, []);
  const onDelete = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("delete_document", localStorage.getItem("token"));
          formdata.append("doc_id", id);
          employeeModel.DeleteDocment(formdata).then((res) => {
            console.log(res.data);
            helper.sweetalert.toast(
              "Deleted",
              "Your Document has been deleted.",
              "success"
            );
            AllDocGet();
            sendDataToAPI(Docid);
            handleClose();
          });
        }
      });
  };

  console.log(Docid, "Docid");
  const sendDataToAPI = (eventKey) => {
    setDocId(eventKey);

    const formdata = new FormData();

    formdata.append("get_emp_docs", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("doc_type_id", eventKey);
    formdata.append("emp_id", params?.id);
    employeeModel
      .documents(formdata)
      .then((res) => {
        setDoclistfilter(res.data.documents);
        // helper.sweetalert.toast( 'Document Added.', 'success');
        console.log(res.data.documents);
        fetchData();
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };

  const documentsColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => <b>{row.docs_name}</b>,
    },
    {
      name: "Attachment",
      selector: "attachment",
      sortable: true,
      cell: (row) => {
        return (
          <img
            src={`${row.doc_url}`}
            alt=""
            className="m-1"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              Showdoctab(row.doc_url);
            }}
          />
        );
      },
    },
    {
      name: "Document Type",
      selector: (row) => row.document_type_name,
      sortable: true,
    },
    {
      name: "Added by",
      selector: (row) => row.added_by,
      sortable: true,
      // cell: (row) => <b>{row.addedBy}</b>,
    },
    {
      name: "Actions",
      sortable: false,
      cell: (row) => {
        return (
          <TableDropdown
            isEdit={true}
            isRemove={true}
            id={row?.doc_id}
            onClick={() => onDelete(row.doc_id)}
            onDeleteClick={() => onDelete(row.doc_id)}
            openEditModal={() => {
              displaydata(row.doc_id);
              toggleModal1();
            }}
          />
        );
      },
    },
  ];

  const onBoardingColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => <b>{row.docs_name}</b>,
    },
    {
      name: "Attachment",
      selector: "attachment",
      sortable: true,
      cell: (row) => {
        // if (row.doc_url )
        return (
          <img
            src={`${row.doc_url}`}
            alt=""
            className="m-1"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              Showdoctab(row.doc_url);
            }}
          />
        );
      },
    },
    {
      name: "Document Type",
      selector: (row) => row.document_type_name,
      sortable: true,
    },
    {
      name: "Added by",
      selector: (row) => row.added_by,
      sortable: true,
      // cell: (row) => <b>{row.addedBy}</b>,
    },
    {
      name: "Actions",
      sortable: false,
      cell: (row) => {
        return (
          <TableDropdown
            isEdit={true}
            isRemove={true}
            id={row?.doc_id}
            onClick={() => onDelete(row.doc_id)}
            onDeleteClick={() => onDelete(row.doc_id)}
            openEditModal={() => {
              displaydata(row.doc_id);
              toggleModal1();
            }}
          />
        );
      },
    },
  ];

  const Showdoctab = (doc_url) => {
    window.open(doc_url, "_blank");
  };
  const Greviences = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => <b>{row.docs_name}</b>,
    },
    {
      name: "Attachment",
      selector: "attachment",
      sortable: true,
      cell: (row) => {
        return (
          <img
            src={`${row.doc_url}`}
            alt=""
            className="m-1"
            style={{ width: "50px", height: "50px", borderRadius: "10px" }}
          />
        );
      },
    },
    {
      name: "Document Type",
      selector: (row) => row.document_type_name,
      sortable: true,
    },
    {
      name: "Added by",
      selector: (row) => row.added_by,
      sortable: true,
      // cell: (row) => <b>{row.addedBy}</b>,
    },
    {
      name: "Actions",
      sortable: false,
      cell: (row) => {
        return (
          <TableDropdown
            isEdit={true}
            isRemove={true}
            id={row?.doc_id}
            onClick={() => onDelete(row.doc_id)}
            onDeleteClick={() => onDelete(row.doc_id)}
            openEditModal={() => {
              displaydata(row.doc_id);
              toggleModal1();
            }}
          />
        );
      },
    },
  ];

  const documentsData = shwoDoc || [];
  const onBoardingData = doclistfilter || [];

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    const selectedName = event.target.value;
    const selectedOption = doclist.find(
      (option) => option.type === selectedName
    );
    if (selectedOption) {
      setSelectedValue(selectedName);
      setSelectedValueId(selectedOption.id);
    } else {
      setSelectedValue("");
      setSelectedValueId("");
    }
  };

  
 

  const handleChangeDocName = (event) => {
    const selectedName = event.target.value;
    setSelectedValueDocName(selectedName);
  };
  useEffect(() => {
    if (EmgSingle?.docs_name) {
      setSelectedValueDocName(EmgSingle.docs_name);
    }
  }, [EmgSingle]);
  
  return (
    <MDBCard className="mt-3">
      <MDBCardHeader>
        <Col>
          <div style={{ position: "relative" }}>
            <span
              style={{ marginTop: "px", marginLeft: "10px", fontSize: "19px" }}
            >
              <b>Document</b>
            </span>
            <button
              className="btn"
              onClick={toggleModal}
              style={{
                float: "right",
                backgroundColor: "#18285a",
                color: "white",
              }}
            >
              Add New
            </button>
          </div>
        </Col>
      </MDBCardHeader>
      <MDBCardBody>
        <div>
          {/* <Card className='mb-5'> */}
          <Container>
            <Tabs
              defaultActiveKey="Documents"
              id="uncontrolled-tab-example"
              className="mt-3 m"
              onSelect={sendDataToAPI}
              fill
              variant="pills"
            >
              <Tab eventKey="Documents" title="Documents">
                <DataTable
                  title={"Documents"}
                  columns={documentsColumns}
                  data={documentsData}
                />
              </Tab>

              <Tab eventKey="1" title="On-Boarding">
                <DataTable
                  title={"On-Boarding"}
                  columns={onBoardingColumns}
                  data={onBoardingData}
                />
              </Tab>
              <Tab eventKey="2" title="Memo / Warning letter">
                <DataTable
                  title={"Memo / Warning letter"}
                  columns={onBoardingColumns}
                  data={onBoardingData}
                />
              </Tab>

              <Tab eventKey="3" title="Paperwork">
                <DataTable
                  title={"Paperwork"}
                  columns={onBoardingColumns}
                  data={onBoardingData}
                />
              </Tab>
              <Tab eventKey="4" title="Exit">
                <DataTable
                  title={"Exit"}
                  columns={onBoardingColumns}
                  data={onBoardingData}
                />
              </Tab>
              <Tab eventKey="5" title="Library">
                <DataTable
                  title={"Library"}
                  columns={onBoardingColumns}
                  data={onBoardingData}
                />
              </Tab>
            </Tabs>
            {showModal && (
              <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <form onSubmit={handleSubmit}>
                      <Container>
                        <Row>
                          <Col sm={4}>
                            <span
                              style={{ fontSize: "17px", marginTop: "3px" }}
                            >
                              Name
                            </span>
                          </Col>

                          <Col sm={8}>
                            <Form.Select
                              name="docs_name"
                              id=""
                              required
                              style={{ height: "35px", width: "100%" }}
                            >
                              <option value="">Select Docement Name</option>
                              <option value="AadharCard"> Aadhar Card </option>
                              <option value="PanCard"> Pan Card </option>
                              <option value="Education-10th">
                                {" "}
                                Education - 10th
                              </option>
                              <option value="Education-12th">
                                {" "}
                                Education - 12th
                              </option>
                              <option value=" Diploma">Diploma </option>
                              <option value="Graduation">Graduation</option>
                              <option value="Postgraduation">
                                Post-Graduation
                              </option>
                              <option value="DL">DL</option>
                              <option value="CancleCheque">
                                {" "}
                                Cancle Cheque
                              </option>
                              <option value=" Others">Others </option>
                            </Form.Select>{" "}
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={4}>
                            <span
                              style={{ fontSize: "17px", marginTop: "3px" }}
                            >
                              Document No
                            </span>
                          </Col>
                          <Col sm={8}>
                            <Form.Control
                              type="text"
                              name="doc_number"
                              onChange={handleInputChange}
                              placeholder="Add Document Number"
                              required
                              id="form1"
                              // style={{
                              //   height: "35px",
                              //   width: "100%",
                              //   fontSize: "16px",
                              // }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={4}>
                            <span
                              style={{ fontSize: "17px", marginTop: "3px" }}
                            >
                              {" "}
                              Select Document
                            </span>
                          </Col>
                          <Col sm={8}>
                            {" "}
                            <Form.Control
                              type="file"
                              name="document"
                              required
                              id="form1"
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={4}>
                            {" "}
                            <span style={{ fontSize: "17px" }}>
                              {" "}
                              Documnet Type
                            </span>{" "}
                          </Col>
                          <Col sm={8}>
                            <Form.Select
                              name="doc_type_id"
                              id=""
                              required
                              style={{ height: "35px", width: "100%" }}
                            >
                              {doclist &&
                                doclist.map((list) => (
                                  <option value={list.id}> {list.type}</option>
                                ))}
                            </Form.Select>{" "}
                          </Col>
                        </Row>
                      </Container>
                      <div className=" mt-2">
                        {/* <Button
                          className=""
                          style={{
                            backgroundColor: "gray",
                            color: "white",
                            borderStyle: "none",
                          }}
                          onClick={() => handleClose()}
                        >
                          {" "}
                          Close
                        </Button>

                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "#18285a",
                            borderStyle: "none",
                            float: "right",
                            marginRight: "8px",
                          }}
                          className=""
                          // style={{
                          //     width: "116px",
                          //     borderRadius: " 25px",
                          //     padding: "10px",
                          //     position: 'relative', float: "right",
                          //     backgroundColor: '#18285a', color: 'white',
                          //     marginTop: '20px', marginBottom: '20px'

                          // }}
                        >
                          {" "}
                          Submit
                        </Button> */}

                        <Button
                          style={{ float: "left", marginLeft: "5%" }}
                          className="w-25"
                          variant="secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </Button>
                        <Button
                          style={{
                            float: "right",
                            borderStyle: "none",
                            marginRight: "5%",
                          }}
                          className="btn submitButtons w-25"
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                      </div>
                      {/* <button type='submit' className='btn mt-4 mb-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }}> Submit</button> */}
                    </form>
                    {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{ float: 'left' }}>Close</button> */}
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {showModal1 && (
              <Modal show={showModal1} onHide={handleClose1}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <form onSubmit={handleSubmit1}>
                      <Container>
                        <Row>
                          <Col sm={4}>
                            <span
                              style={{ fontSize: "17px", marginTop: "3px" }}
                            >
                              Name
                            </span>
                          </Col>
                          {/* <Col sm={8}>
                            <Form.Control
                              type="text"
                              name="docs_name"
                              onChange={handleInputChange1}
                              defaultValue={EmgSingle?.docs_name}
                              placeholder="Add Document Name"
                              required
                              id=""
                              style={{
                                height: "35px",
                                width: "100%",
                                fontSize: "16px",
                              }}
                            />
                          </Col> */}
                          <Col sm={8}>
                            <Form.Select
                              // name="docs_name"
                              id=""
                              value={selectedValueDocName}
                              onChange={(e) => handleChangeDocName(e)}
                              style={{ height: "35px", width: "100%" }}
                            >
                              <option value="">Select Document Name</option>
                              <option value="AadharCard">Aadhar Card</option>
                              <option value="PanCard">Pan Card</option>
                              <option value="Education-10th">
                                Education - 10th
                              </option>
                              <option value="Education-12th">
                                Education - 12th
                              </option>
                              <option value="Diploma">Diploma</option>
                              <option value="Graduation">Graduation</option>
                              <option value="Postgraduation">
                                Post-Graduation
                              </option>
                              <option value="DL">DL</option>
                              <option value="CancleCheque">
                                Cancle Cheque
                              </option>
                              <option value="Others">Others</option>
                            </Form.Select>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={4}>
                            <span
                              style={{ fontSize: "17px", marginTop: "3px" }}
                            >
                              Document No
                            </span>
                          </Col>
                          <Col sm={8}>
                            <Form.Control
                              type="text"
                              name="doc_number"
                              onChange={handleInputChange1}
                              defaultValue={EmgSingle?.doc_number}
                              placeholder="Add Document Number"
                              required
                              id=""
                              style={{
                                height: "35px",
                                width: "100%",
                                fontSize: "16px",
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={4}>
                            <span
                              style={{ fontSize: "17px", marginTop: "3px" }}
                            >
                              {" "}
                              Select Document
                            </span>
                          </Col>
                          <Col sm={8}>
                            {" "}
                            <Form.Control
                              type="file"
                              name="document"
                              id=""
                              onChange={handleInputChange1}
                              // required
                              style={{ height: "40px", width: "100%" }}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={4}>
                            {" "}
                            <span style={{ fontSize: "17px" }}>
                              {" "}
                              Documnet Type
                            </span>{" "}
                          </Col>
                          <Col sm={8}>
                            <select
                              // name="doc_type_id"
                              id=""
                              required
                              style={{ height: "35px", width: "100%" }}
                              value={
                                selectedValue
                                  ? selectedValue
                                  : EmgSingle?.document_type_name || ""
                              }
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="">Select a document type</option>
                              {/* {doclist &&
                                doclist.map((list) => (
                                  <option key={list.id} value={list.id}>
                                    {list.type}
                                  </option>
                                ))} */}
                              <option value="">Select a document type</option>
                              {doclist &&
                                doclist.map((list) => (
                                  <option key={list.id} value={list.type}>
                                    {list.type}
                                  </option>
                                ))}
                            </select>
                          </Col>
                        </Row>
                      </Container>
                      <div className=" mt-2">
                        <Button
                          style={{ float: "left", marginLeft: "5%" }}
                          className="w-25"
                          variant="secondary"
                          onClick={() => handleClose()}
                        >
                          Close
                        </Button>
                        <Button
                          style={{
                            float: "right",
                            borderStyle: "none",
                            marginRight: "5%",
                          }}
                          className="btn submitButtons w-25"
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                        {/* <Button
                          className=""
                          style={{
                            backgroundColor: "gray",
                            color: "white",
                            borderStyle: "none",
                          }}
                          onClick={() => handleClose()}
                        >
                          {" "}
                          Close
                        </Button>

                        <Button
                          type="submit"
                          style={{
                            backgroundColor: "#18285a",
                            borderStyle: "none",
                            float: "right",
                            marginRight: "8px",
                          }}
                          className=""
                          // style={{
                          //     width: "116px",
                          //     borderRadius: " 25px",
                          //     padding: "10px",
                          //     position: 'relative', float: "right",
                          //     backgroundColor: '#18285a', color: 'white',
                          //     marginTop: '20px', marginBottom: '20px'

                          // }}
                        >
                          {" "}
                          Submit
                        </Button> */}
                      </div>
                      {/* <button type='submit' className='btn mt-4 mb-4' style={{ float: 'right', backgroundColor: '#18285a', color: 'white' }}> Submit</button> */}
                    </form>
                    {/* <button className='btn btn-secondary mt-4 mb-4' onClick={handleClose} style={{ float: 'left' }}>Close</button> */}
                  </div>
                </Modal.Body>
              </Modal>
            )}
          </Container>
          {/* </Card> */}
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Document;
