import React, { useEffect, useState } from 'react';
import { Modal, Row, Col,Container,Tooltip ,OverlayTrigger, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import salary from '../../Model/salary';
import DataTable from '../../components/Tables/Tables';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAllEmployees, nofetchAllEmployees } from '../../store/silce/staffSlice';

import { helper } from "../../lib/helper";
import TableDropdown from '../../components/TableDropDown/TableDropdown';
// import {Container,Row , Col } from 'react-bootstrap';
const Advancepay = ({title}) => {

  // console.log(employees);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Advance, setAdvance] = useState([]);
  const dispatch = useDispatch()
const employeess = useSelector((state) => state?.staff?.employees?.data?.emp)
console.log(employeess,  'employeesemployeesemployees')
  const [RemoveAdvance, setRemoveAdavnce] = useState([])
  const params = useParams();
  const fetchData = () => {
    console.log(params, "data")
    const formdata = new FormData();

    formdata.append("advance_list", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("emp_id", params?.id);
    salary.Advancepayemp(formdata).then((respone) => {
      console.log(respone.data.data.advance)
      setAdvance(respone.data.data.advance)
    }
    )
  }
  const columns = [

    {
      name: 'Amount',
      selector: (row) => row.amount,
    },
    {
      name: 'Name',
      selector: (row) => row.reason,
    },
    {
      name: 'Paid',
      selector: (row) => row.paid_by,
      cell: (row) => {
        return <div> <span>{row.paid_by} {row.paid_by_user_detail?.paid_by_emp_name} </span> </div>;
      },
    },
    // {
    //   name: 'Paid by',
    //   selector: (row) => 
    // },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            onClick={() => onDELETE(row.id)}
            onDeleteClick={() => onDELETE(row.id)}
            // openEditModal={() => dispatch(openEditModal("edit-business"))}
            isRemove={true}
            page="businessList"
          />
        );
      },
      width: "4%",
      center: "center",
    },
  ]

  // const onDELETE = (id) => {
  //   const formdata = new FormData();

  //   formdata.append("remove_advance", localStorage.getItem("token"));
  //   formdata.append("advance_id", id);
  //   salary.Removepay(formdata).then((res) => {
  //     console.log(res.data)

  //     setRemoveAdavnce(res.data)
  //     fetchData();
  //     // alert("Advance Pay Remove")
  //   });
  //   // window.location.reload();
  // }

  const onDELETE = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("remove_advance", localStorage.getItem("token"));
          formdata.append("advance_id", id);
          salary.Removepay(formdata)
            .then((res) => {
              helper.sweetalert.toast(
                "Deleted",
                "Your Deduction has been deleted.",
                "success"
              );
              return fetchData();
            })
            .then(() => fetchData()); // Fetch sector data
        }
      });
  };


  const [formDataNew, setFormDataNew] = useState({
    amount:'',
    reason:'',
    paid_by:'',
    paid_by_id:''
});
const handleChange = (event) => {
    setFormDataNew({
        ...formDataNew,
        [event.target.name]: event.target.value,
    });
}
const handleSubmit = async (event) => {
    event.preventDefault();
    const formdata = new FormData(event.target);
    formdata.append("add_advance", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("emp_id", params?.id);
    salary.Addadvance(formdata).then((res) => {
        console.log(res.data.message)
        fetchData()
        handleClose()
        // alert(res.data.message)
    }).catch((error) => {
        console.log("error => ", error)
    })
}
useEffect(() => {
fetchData()
}, [])

useEffect(() => {
    dispatch(nofetchAllEmployees())  
    // setFilterstaff(employees?.data?.emp)
}, []);
const [isSwitchOn, setSwitchOn] = useState(false);
const [isChecked, setChecked] = useState(false);
const handleSwitchToggle = () => {
  setSwitchOn(!isSwitchOn);
};
  return (
    <div>
      <div className='mt-1 d-flex justify-content-end'>
        <Button onClick={handleShow}  
        //  style={{
        //           float: "right",
        //           borderStyle: "none",
        //           marginRight: "5%",
        //         }}
                className="btn submitButtons w-25"
                type="submit"
                variant="primary" >Add </Button>
      </div>
      <div >
        <DataTable
          columns={columns}
          data={Advance}
          pagination
          fixedHeader
          fixedHeaderScrollHeight='400px'
          highlightOnHover
          title={title}
        />
      </div>
      <Modal show={show} onHide={handleClose} size='md'>
        <Modal.Header closeButton>
          <Modal.Title><span style={{fontSize:"20px"}}>Add Advance Payment</span> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col sm={4}> <span style={{fontSize:'16px'}}>Amount*</span> 
                </Col>
                <Col sm={8}>
                <input type="number" name="amount" id="" placeholder='Amount' onChange={handleChange}  required style={{ width: '100%', height: '40px',paddingLeft:'10px' }} />

                </Col>
                </Row>
                <Row className='mt-3'>
                <Col sm={4}><span style={{fontSize:'16px'}}> Write Note</span>
                </Col>
                <Col sm={8}>
                <input type="text" name="reason" id="" placeholder='Write Note for Advance Payment' onChange={handleChange} style={{ width: '100%', height: '40px',paddingLeft:'10px' }} />

                </Col>
              </Row>
              <Row className='mt-3'>
              <span>
              <input type="checkbox" checked={isSwitchOn} onChange={handleSwitchToggle} className='inputAdvance' value="Employee" name='paid_by'   />
       
                  Paid By {isSwitchOn ? 'Staff' : 'You'}</span>
                  {isSwitchOn &&    <table>
                    <tr>
                      <td>
                        Name 
                      </td> 
                      <td>Desgination</td>
                      <td> Select</td>
                    </tr>
                    {employeess && employeess?.map((showEmplist) => 
                    <tr>
                      <td>{showEmplist.name}</td>
                      <td>{showEmplist.designation}</td>
                      <td><input type="radio" value={showEmplist.id} name="paid_by_id" id="" /></td>
                    </tr>
                    )}
                  </table>}
               
              
            
              </Row>
             
            </Container>
           
            <br />
            <br />
            <div >
             
             
            
            </div>
            {/* {showComponent1 ? <>
              <div>
                <>
                  
                    <div >
                      <input type="checkbox" name="admin_attendance[view]" id="" value="true" style={{ paddingLeft: '10px' }} /> <span style={{ paddingLeft: '10px' }}>View</span>
                      <input type="checkbox" name="admin_attendance[add]" id="" value="true" style={{ marginLeft: '10px' }} /> <span style={{ paddingLeft: '10px' }}>Add</span>
                      <input type="checkbox" name="admin_attendance[edit]" id="" value="true" style={{ marginLeft: '10px' }} /> <span style={{ paddingLeft: '10px' }}>Edit</span>
                      <input type="checkbox" name="admin_attendance[delete]" id="" value="true" style={{ marginLeft: '10px' }} /> <span style={{ paddingLeft: '10px' }}>Delete</span>

                    </div>
             
                </>
              </div>
            </> : ""} */}
            <button className='btn  mt-4 mb-2' style={{ float: "right", backgroundColor: '#18285a', color: 'white' }}> Submit</button>
          </form>
          <button className='btn btn-secondary mt-4 mb-2' onClick={handleClose}> Close</button>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default Advancepay;
