import React, { useState, useEffect } from 'react';
import '../Aseset/css/sidenav.css';
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
import { PieChart, Pie, Legend, Tooltip } from 'recharts';
import { Container, Row, Col, Card } from 'react-bootstrap';
const data = [
    { name: 'Regular', value:100, },
    { name: 'Early', value: 200 },
    // { name: 'Late', value: 200 },
    // { name: 'On leave', value: 100 },
];
const Summary = () => {


    return (
        <>
            <div className="d-flex" id="wrapper">
                <Sidenav style={{ parseFloat }} />
                <div id="page-content-wrapper">
                    <Topnav />
                    <Container fluid style={{ backgroundColor: 'rgba(171, 171, 173, 0.15)' }} >
                        <Row className='pt-3 pb-3'>
                            <Col>
                                <div className='' >
                                    <span style={{ fontSize: '20px' }}>Summary</span>
                                    <button type="button " style={{ position: 'relative', float: 'right', marginLeft: "10px" }} className=' btn btn-success'>Export all</button>
                                    <button type="button " style={{ position: 'relative', float: 'right', marginLeft: "10px" }} className='btn btn-success'>Export </button>
                                    <button type="button " style={{ position: 'relative', float: 'right' }} className='btn btn-primary'>Add attendance</button>

                                </div>
                            </Col>
                        </Row>
                        <Row className='pb-3'>
                            <Col>

                            </Col>
                        </Row>
                        <Row className='pb-3'>
                            <Col>Showing 1 to 4 items of 4</Col>
                        </Row>
                        <Card style={{ "box-shadow": "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px" }}>
                            <Row className='mt-2 mb-3'>
                                <table style={{ width: '96%', marginLeft: '40px', marginTop: '20px' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '480px' }}>
                                                July, 2023
                                            </td>
                                            <td>Today</td>
                                            <td>This Week </td>
                                            <td> Last Week </td>
                                            <td>This Month </td>
                                            <td>Last Month</td>
                                            <td>This Year</td>
                                        </tr>
                                    </tbody>
                                </table>
                              
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <div className='top-card-div' style={{}}>
                                        <span className='card-icon' style={{ marginLeft: '30px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                            </svg>
                                        </span>
                                        <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                            <br /> Development

                                        </span>
                                    </div>
                                    <span style={{ float: 'right', position: 'relative', marginTop: '-50px',right:'40px' }}> Prevoius | Next</span>
                                </Col>
                            </Row>
                            <Row>
                                <table className='tabel ' style={{ width: '96%', marginLeft: '40px', marginTop: '20px' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ position: 'relative', width: '20px' }}>

                                                <PieChart width={400} height={300}>
                                                    <Pie
                                                        data={data}
                                                        dataKey="value"
                                                        nameKey="name"
                                                        cx="50%"
                                                        cy="50%"
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        label
                                                    />
                                                    <Tooltip />
                                                    <Legend />
                                                </PieChart>
                                            </td>
                                            <td >
                                                <Card style={{ margin: '20px', width: '150px' }}>
                                                    <center>
                                                        <p>
                                                            176:00:00
                                                        </p>
                                                        <p>
                                                            Total schedule hour
                                                        </p>
                                                    </center>
                                                </Card>
                                                <Card style={{ margin: '20px', width: '150px' }}>
                                                    <center>
                                                        <p>
                                                            21:00:00
                                                        </p>
                                                        <p>
                                                            Total active hour


                                                        </p>
                                                    </center>
                                                </Card>
                                            </td>
                                            <td>
                                                <Card style={{ margin: '20px', width: '150px' }}>
                                                    <center>
                                                        <p>
                                                            00:00:00

                                                        </p>
                                                        <p>
                                                            Leave hour (paid)


                                                        </p>
                                                    </center>
                                                </Card>
                                                <Card style={{ margin: '20px', width: '150px' }}>
                                                    <center>
                                                        <p>
                                                            -155:00:00
                                                        </p>
                                                        <p>
                                                            Balance (Lack)




                                                        </p>
                                                    </center>
                                                </Card>
                                            </td>
                                            <td >
                                                <Card style={{ margin: '20px', width: '150px' }}>
                                                    <center>
                                                        <p>
                                                            11.93%

                                                        </p>
                                                        <p>
                                                            Total work availability


                                                        </p>
                                                    </center>
                                                </Card>
                                                <Card style={{ margin: '20px', width: '150px' }}>
                                                    <center>
                                                        <p>
                                                            Early
                                                        </p>
                                                        <p>
                                                            Average Behaviour




                                                        </p>
                                                    </center>
                                                </Card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>
                            <Row>
                                <table className='table  table-responsive-sm' style={{ width: '96%', marginLeft: '20px' }}>
                                    <thead style={{ height: '100px' }}>
                                        <tr style={{ verticalAlign: 'middle' }} className=''>
                                            <td style={{ paddingLeft: '20px', width: '180px' }}>Profile </td>
                                            <td >Punched in	 </td>
                                            <td>In Geolocation	</td>
                                            <td >Punched Out	</td>
                                            <td >Out Geolocation	</td>
                                            <td >Behavior</td>
                                            <td >Type</td>
                                            <td>Break time</td>
                                            <td >Total hours</td>
                                            <td>Entry</td>
                                            <td>Actions</td>


                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                            <td style={{ paddingLeft: '20px', width: '280px' }}>
                                                <div className='top-card-div' style={{}}>
                                                    <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                        </svg>
                                                    </span>
                                                    <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                        <br /> Development

                                                    </span>

                                                </div>
                                            </td>
                                            <td >11:34 AM, Today</td>
                                            <td>--</td>
                                            <td> 6:34 PM, Today</td>
                                            <td>--</td>
                                            <td> <button style={{ color: 'white', backgroundColor: 'orange', borderRadius: '10px' }}> Early</button></td>
                                            <td>Auto</td>
                                            <td>00:00	</td>
                                            <td> 07:00	</td>
                                            <td>  <button style={{ color: 'white', backgroundColor: 'green', borderRadius: '10px' }}> Single</button></td>
                                            <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                            </svg></td>
                                        </tr>
                                        <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                            <td style={{ paddingLeft: '20px', width: '280px' }}>
                                                <div className='top-card-div' style={{}}>
                                                    <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                        </svg>
                                                    </span>
                                                    <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                        <br /> Development

                                                    </span>

                                                </div>
                                            </td>
                                            <td >11:34 AM, Today</td>
                                            <td>--</td>
                                            <td> 6:34 PM, Today</td>
                                            <td>--</td>
                                            <td> <button style={{ color: 'white', backgroundColor: 'orange', borderRadius: '10px' }}> Early</button></td>
                                            <td>Auto</td>
                                            <td>00:00	</td>
                                            <td> 07:00	</td>
                                            <td>  <button style={{ color: 'white', backgroundColor: 'green', borderRadius: '10px' }}> Single</button></td>
                                            <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                            </svg></td>
                                        </tr>
                                        <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                            <td style={{ paddingLeft: '20px', width: '280px' }}>
                                                <div className='top-card-div' style={{}}>
                                                    <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                        </svg>
                                                    </span>
                                                    <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>shivam
                                                        <br /> UI & UX

                                                    </span>

                                                </div>
                                            </td>
                                            <td >11:34 AM, Today</td>
                                            <td>--</td>
                                            <td> 8:34 PM, Today</td>
                                            <td>--</td>
                                            <td> <button style={{ color: 'white', backgroundColor: 'orange', borderRadius: '10px' }}> Early</button></td>
                                            <td>Auto</td>
                                            <td>00:00	</td>
                                            <td> 09:00	</td>
                                            <td>  <button style={{ color: 'white', backgroundColor: 'green', borderRadius: '10px' }}> Single</button></td>
                                            <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                            </svg></td>
                                        </tr>
                                        <tr style={{ height: '100px', verticalAlign: 'middle' }} >
                                            <td style={{ paddingLeft: '20px', width: '280px' }}>
                                                <div className='top-card-div' style={{}}>
                                                    <span className='card-icon' style={{ marginLeft: 'px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                        </svg>
                                                    </span>
                                                    <span className='' style={{ fontSize: '15px', marginLeft: '5px' }}>Camila Murphy
                                                        <br /> Development

                                                    </span>

                                                </div>
                                            </td>
                                            <td >11:34 AM, Today</td>
                                            <td>--</td>
                                            <td> 6:34 PM, Today</td>
                                            <td>--</td>
                                            <td> <button style={{ color: 'white', backgroundColor: 'orange', borderRadius: '10px' }}> Early</button></td>
                                            <td>Auto</td>
                                            <td>00:00	</td>
                                            <td> 07:00	</td>
                                            <td>  <button style={{ color: 'white', backgroundColor: 'green', borderRadius: '10px' }}> Single</button></td>
                                            <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                            </svg></td>
                                        </tr>

                                    </tbody>
                                </table>

                            </Row>

                        </Card>
                    </Container>





                </div>
            </div>
        </>
    )
}
export default Summary;