import React, { useEffect, useState } from "react";
import { Container, InputGroup } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedData } from "../../store/silce/bussiness";
import { BiCalendar } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import "./commanStyle.css";
import { fetchDataAllPermissionsRollList } from "../../store/silce/roll";
import { TextField } from "@mui/material";
import RolePermission from "./RolePermission";
import "../../App.css"
const CommonEditModal = ({
  show,
  onHide,
  name,
  title,
  onChange,
  onSubmit,
  pageData,
  displaybus,
  size,
  handleItemClick,
}) => {

  const dispatch = useDispatch();
  const selectedData = useSelector((state) => {
    return state.Business.selectedData;
  });
  const Departmentdta = useSelector((state) => {
    return state.Business?.selectedData?.department;
  });
  const subDepartmentdta = useSelector((state) => {
    return state.Business?.selectedData?.department;
  });

  const sectorData = useSelector((state) => {
    return state.Business.sector;
  });
  const selectedLevel = useSelector((state) => state.level.selectedLevel);
  const manageLevel = useSelector((state) => state.level.manageLevel);
  const levelList = useSelector((state) => state.level.levels);
  const onCloseModal = () => {
    dispatch(clearSelectedData());
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };


  const permissions = useSelector((state) => {
    return state?.roll?.fetchDataAllPermissionsRollList?.data?.permissions;
  });

  const [showComponents, setShowComponents] = useState([]);
  useEffect(() => {
    dispatch(fetchDataAllPermissionsRollList());
  }, []);
  const handleToggle = (index) => {
    setShowComponents((prevShowComponents) => {
      const newShowComponents = [...prevShowComponents];
      newShowComponents[index] = !newShowComponents[index];
    
      return newShowComponents;
    });
  };
  const DepartmentData = useSelector((state) => {
    return state.Business.Alldepartments;
  });
  const roleUpdateDataPermissions =
  pageData?.data && pageData?.data?.map((item) => item?.permissions);


const generateCheckboxes = (index, permission) => {
  return showComponents[index] ? (
    <div key={index}>
      {/* <Row>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        > */}
          
        
          <RolePermission permission={permission} />
        {/* </div>
      </Row> */}
    </div>
  ) : null;
};
    

  const roleUpdateData =
    pageData?.data && pageData?.data?.map((item) => item?.roleName);
  const RoleName = roleUpdateData?.join(", ");


  const renderFields = () => {
    switch (name) {
      case "edit-business":
        return (
          <Form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <input
                  type="hidden"
                  name="bus_id"
                  id=""
                  value={pageData?.outid}
                />
                <Form.Group controlId="nameOfBusiness">
                  <Form.Label className="mt-2">Name of Business*</Form.Label>
                  <Form.Control
                    onChange={onChange}
                    name="cl_name"
                    type="text"
                    placeholder="Name of Business"
                    required
                    defaultValue={selectedData?.bus?.business_name}
                  />
                </Form.Group>
                <Form.Group controlId="businessSector">
                  <Form.Label className="mt-3">Business Sector*</Form.Label>
                  <select
                    name="bus_sector"
                    id=""
                    style={{ height: "35px", width: "100%" }}
                  >
                    {sectorData &&
                      sectorData.map((sectorlist) => (
                        <option
                          value={sectorlist.id}
                          name="bus_sector"
                          selected={
                            sectorlist.name ==
                            selectedData?.bus?.business_sector_name
                          }
                          onChange={onChange}
                          style={{ height: "120px" }}
                        >
                          {sectorlist.name}
                        </option>
                      ))}
                  </select>
                </Form.Group>
                <Form.Group controlId="mobileNumber">
                  <Form.Label className="mt-3">Mobile Number*</Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      id="basic-addon1"
                      style={{ height: "35px", fontSize: "16px" }}
                    >
                      +91
                    </InputGroup.Text>
                    <Form.Control
                      type="number" // Use type "tel" for phone numbers
                      style={{ height: "35px", appearance: "textfield" }}
                      name="cl_cont"
                      placeholder="Mobile Number"
                      pattern="[0-9]*" // Use a regex pattern to allow only numeric input
                      // value={num}
                      maxLength={10}
                      defaultValue={selectedData?.bus?.whatsapp}
                      onChange={onChange}
                      // placeholder=""
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label className="mt-3"> Email ID</Form.Label>
                  <Form.Control
                    name="cl_email1"
                    placeholder="Enter Your Email ID"
                    defaultValue={selectedData?.bus?.email?.[0]?.id}
                    type="email"
                    
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  How do you want to calculate your staff's Salary?
                </div>
                <ListGroup>
                  <ListGroup.Item
                    as="li"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-start"
                    onClick={() => handleItemClick("monthlyRadio")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        {" "}
                        <BiCalendar /> Monthly basis
                      </div>
                      Salary will be calculated for a month
                    </div>
                    <Form.Check
                      id="monthlyRadio"
                      defaultChecked={
                        selectedData?.bus?.salary_basis &&
                        selectedData?.bus?.salary_basis == "monthly"
                      }
                      type="radio"
                      name="salary_basis"
                      value="monthly"
                      aria-label="option 1"
                    />
                  </ListGroup.Item>
                  <ListGroup.Item
                    as="li"
                    className="list-group-item list-group-item-action d-flex justify-content-between align-items-start bg-light"
                    onClick={() => handleItemClick("30daysRadio")}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        <BsCalendar2Date /> 30 Days basis
                      </div>
                      Salary will be calculated for 30 days (Doesn't matter how
                      many days in the current month)
                    </div>
                    <Form.Check
                      id="30daysRadio"
                      defaultChecked={
                        selectedData?.bus?.salary_basis &&
                        selectedData?.bus?.salary_basis == "30 days"
                      }
                      name="salary_basis"
                      value="30 days"
                      type="radio"
                      aria-label="option 2"
                    />
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>

            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "edit-department":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="mobileNumber">
              <Form.Label>Department*</Form.Label>
              <Form.Control
                onChange={onChange}
                name="name"
                className="form-group required"
                type="text"
                defaultValue={Departmentdta?.department_name}
                placeholder="Enter Department Name"
                required
              />
            </Form.Group>
            {/* <Form.Group controlId="email">
                            <Form.Label className="mt-2">Add Note *</Form.Label>
                            <Form.Control onChange={onChange} name='cl_name' type="text" placeholder="Add Note" required />
                        </Form.Group> */}
          <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        );
      case "edit-sub-department":
        return (
        <>
          <Form onSubmit={onSubmit}>
          <Form.Group controlId="department">
              {/* <Form.Label className="mt-2">Department *</Form.Label> */}
              <Form.Group controlId="businessSector">
                {/* <Form.Select
                  name="department_id"
                  id=""required
                  style={{ height: "35px", width: "100%" }}
                >
                  <option disabled selected>Select Department</option>
                  {DepartmentData &&
                    DepartmentData.map((sectorlist) => (
                      <option
                        value={sectorlist.department_id}
                        name="department_id"
                        style={{ height: "120px" }}
                      >
                        {sectorlist.department_name}
                      </option>
                    ))}
                  {/* {pageData?.dropdrownElement && pageData?.dropdrownElement.map((DepartmentData) =>
                                        <option value={DepartmentData.id} name='bus_sector' defaul
                                            onChange={onChange} style={{ height: "120px", }}>{DepartmentData.name}</option>
                                    )} */}
                {/* </Form.Select> */} 
              </Form.Group>
            </Form.Group>
            <Form.Group controlId="mobileNumber">
              <Form.Label>Name of Sub-Department *</Form.Label>
              <Form.Control
                onChange={onChange}
                name="name"
                className="form-group required"
                type="text"
                placeholder="Enter Sub-Department Name "
                required
                defaultValue={subDepartmentdta?.department_name}
              />
            </Form.Group>
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
        </>
        );
      case "edit-designation":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="cl_name">
              <Form.Label>Name Of Desigation *</Form.Label>
              <Form.Control
                onChange={onChange}
                name="cl_name"
                className="form-group required"
                type="text"
                placeholder="Name Of Designation"
                required
              />
            </Form.Group>
            <Form.Group controlId="department">
              <Form.Label className="mt-2">Name of Department *</Form.Label>
              <Form.Group controlId="businessSector">
                <select
                  name="bus_sector"
                  id=""
                  style={{ height: "35px", width: "100%" }}
                >
                  {pageData?.dropdrownElement &&
                    pageData?.dropdrownElement.map((sectorlist) => (
                      <option
                        value={sectorlist.id}
                        name="bus_sector"
                        defaul
                        onChange={onChange}
                        style={{ height: "120px" }}
                      >
                        {sectorlist.name}
                      </option>
                    ))}
                </select>
              </Form.Group>
            </Form.Group>
            <Form.Group controlId="department">
              <Form.Label className="mt-2">Name of Sub Department *</Form.Label>
              <Form.Group controlId="businessSector">
                <select
                  name="bus_sector"
                  id=""
                  style={{ height: "35px", width: "100%" }}
                >
                  {pageData?.dropdrownElement &&
                    pageData?.dropdrownElement.map((sectorlist) => (
                      <option
                        value={sectorlist.id}
                        name="bus_sector"
                        defaul
                        onChange={onChange}
                        style={{ height: "120px" }}
                      >
                        {sectorlist.name}
                      </option>
                    ))}
                </select>
              </Form.Group>
            </Form.Group>
            <Modal.Footer>
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
            </Modal.Footer>
          </Form>
        );
        case "denied-leave":
          return (
            <Form onSubmit={onSubmit}>
            <Form.Group controlId="mobileNumber">
              <Form.Label>comment *</Form.Label>
              <Form.Control
                onChange={onChange}
                name="leave_comment"
                className="form-group required"
                type="text"
                placeholder="Comment"
                required
                id="form1"
              />
            </Form.Group>

            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
          </Form>
          );
      case "edit-address":
        return (
          <Form onSubmit={onSubmit}>
            <div>
              <input
                type="hidden"
                name="bus_id"
                id=""
                value={pageData?.outid}
              />
              <Row className="">
                <Col sm={4}>
                  <p>Address Line 1</p>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    style={{ height: "40px" }}
                    name="address_line1"
                    id=""
                    required
                    placeholder=" Address Line 1"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={4}>
                  <p>Address Line 2</p>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    style={{ height: "40px" }}
                    placeholder=" Address Line 2"
                    required
                    name="address_line2"
                    id=""
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={4}>
                  <p>City</p>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    style={{ height: "40px" }}
                    placeholder="Enter Your city"
                    required
                    // onChange={handleChange}
                    name="city"
                    id=""
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={4}>
                  <p>State</p>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    style={{ height: "40px" }}
                    name="state"
                    id=""
                    required
                    // onChange={handleChange}
                    placeholder="Enter Your State"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={4}>
                  <p>Pincode</p>
                </Col>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    style={{ height: "40px" }}
                    name="pincode"
                    id=""
                    required
                    // onChange={handleChange}
                    placeholder="Enter Your Pincode"
                  />
                </Col>
              </Row>
              <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>

              {/* <button className='btn btn-primary mb-4 mt-4' style={{ float: "right" }} type='submit'>Submit</button> */}
            </div>
          </Form>
        );
      case "edit-level-managment":
        return (
          <Form onSubmit={onSubmit}>
            {
              <>
                <div>Level Name</div>
                <div class="input-group mb-3 mt-2">
                  {/* <input
                    onChange={onChange}
                    name="name"
                    id=""
                  
                  /> */}
                   <Form.Control
                onChange={onChange}
                name="name"
                className="form-group required"
                type="text"
                defaultValue={pageData?.name}
                placeholder="Enter Level Name"
                required
              />
                </div>
                <div className="col-md-12">
                  <div
                    className="mb-3"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    Permission for this level
                  </div>
                  <ListGroup>
                    <div className="d-flex gap-2 mt-3 mb-2">
                      <div className="ms-2 me-auto">
                        <div> Can accept or deny leave requests of staff</div>
                      </div>
                      <Form.Check
                        style={{ fontSize: "25px", padding: "0px" }}
                        onChange={onChange}
                        type="checkbox"
                        name="accept_permission"
                        value="accept"
                        aria-label="option 1"
                        id='accept'
                        defaultChecked={
                          pageData?.accept_permission === "accept" ? true :   pageData?.accept_permission === "accept_forward"  ? true : false
                        }
                      />
                    </div>
                    <div className="d-flex gap-2 mt-3 mb-2">
                      <div className="ms-2 me-auto">
                        <div>Can forward leave requests to admin</div>
                      </div>
                      <Form.Check
                        style={{ fontSize: "25px", padding: "0px" }}
                        onChange={onChange}
                        name="accept_permission"
                        value="forward"
                        id='forward'
                        type="checkbox"
                        aria-label="option 2"
                        defaultChecked={
                          pageData?.accept_permission === "forward"
                            ? true :   pageData?.accept_permission === "accept_forward"  ? true : false                        }
                      />
                    </div>
                  </ListGroup>
                </div>
              </>
            }
            
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
            
          </Form>
        );
      case "edit-level-managment-emp":
        return (
          <Form onSubmit={onSubmit}>
            <select
              style={{
                width: "100%",
                margin: "0rem 0rem 1rem",
                padding: "0.5rem",
              }}
              onChange={(event) => onChange(event, event.target.value)}
            >
              <option value="" disabled selected>
                Select Level
              </option>
              {levelList &&
                levelList?.map((ele) => {
                  return (
                    <option key={ele?.id} value={ele?.id}>
                      {ele?.name}
                    </option>
                  );
                })}
            </select>
            
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
            
          </Form>
        );
      case "edit-roll":
        return (
          <Form onSubmit={onSubmit}>
            <Form.Control
              type="text"
              placeholder="Enter Role Name"
              name="role_name"
              onChange={onChange}
              defaultValue={RoleName}
            />
            <hr />
            <p style={{ fontSize: "20px" }}>Permissions</p>
            {permissions &&
              permissions?.map((permission, index) => {
              return(
                <div key={index} className="mt-4" style={{ cursor: "pointer" }}>
                  <span
                    style={{
                      fontSize: "15px",
                      position: "relative",
                      top: "-5px",
                    }}
                  >
                    <b>{permission.show_name}</b>
                  </span>
                  <div className="toggle">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={showComponents[index]}
                      onChange={() => handleToggle(index)}
                      id={`toggle-${index}`}
                    />
                    <label htmlFor={`toggle-${index}`}></label>
                  </div>

                  {/* {generateCheckboxes(index, permission)} */}
             <RolePermission index={index} permission={permission} showComponents={showComponents} />
                </div>
              )
                  })
              }

            
            <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
           
          </Form>
        );
        case "Apply-Leavse":
          return (
            <Form onSubmit={onSubmit}>
              <Container>
              <Row > 
              <Col sm={4}>
                  {" "}
                  <span style={{ fontSize: "16px" }}>Leave Type</span>
                </Col>
              <Col sm={8}>
          
                  <Form.Select
                    name="leave_type"
                    id=""
                    required
                    style={{ height: "35px", width: "100%" }}
                  >
                    <option value=''>Select </option>
                    <option value='casual_leave'>Casual Leave </option>
                    <option value='sick_leave'>Sick Leave </option>
                  </Form.Select>{" "}
                </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    {" "}
                    <span style={{ fontSize: "16px" }}>From  Date </span>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      id="form1"
                      placeholder="Enter Amoiunt"
                      variant="outlined"
                      fullWidth
                      type="date"
                      size="small"
                      name="from_date"
                      required
                      
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    {" "}
                    <span style={{ fontSize: "16px" }}>To Date </span>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      id="form1"
                      placeholder="Enter Amoiunt"
                      variant="outlined"
                      fullWidth
                      type="date"
                      size="small"
                      name="to_date"
                      required
                      
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    {" "}
                    <span style={{ fontSize: "16px" }}>Reason</span>
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      id="form1"
                      placeholder="Enter Reason"
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="reason"
                      required
                    />
                  </Col>
                </Row>
            
                <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
              </Container>
            </Form>
          );
      
          case'Mark-Paid':
            return (
              <Form onSubmit={onSubmit}>
                <Container>
                  <Row>
                    <Col sm={4}>
                      {" "}
                      <span style={{ fontSize: "16px" }}>Amount Paid </span>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        // id="outlined-basic"
                        placeholder="Enter Ammount"
                        variant="outlined"
                        fullWidth
                        type='number'
                        size="small"
                        name="amount"
                        required
                        id="form1"
                      />
                    </Col>
                  </Row>
           
                  <Row className="mt-3">
                    <Col sm={4}>
                      {" "}
                      <span style={{ fontSize: "16px" }}>Reason</span>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        // id="outlined-basic"
                        placeholder="Enter Reason"
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="remark"
                        required
                        id="form1"
                      />
                    </Col>
                  </Row>
                  <div className="mt-4">
              <Button
                style={{ float: "left", marginLeft:"5%"}}
                className="w-25"
                variant="secondary"
                onClick={() => {
                  onHide();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                style={{ float: "right", borderStyle: "none", marginRight:"5%"}}
                className="submitButtons w-25"
                type="submit"
                variant="primary"
              >
                Submit
              </Button>
            </div>
                </Container>
              </Form>
            );    default:
        return null;
    }
  };
  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title ? title : null}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderFields()}</Modal.Body>
    </Modal>
  );
};

export default CommonEditModal;
