import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import Sidenav from "../Layouts/sidenav";
import Topnav from "../Layouts/topnav";
// import { CSVLink } from 'react-csv'
import { Container, Row, Col, Card, Button, Form,Tooltip } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import employeeModel from "../Model/employee.Model";
import RoleModal from "../Model/Role.modal";
import Addnew from "./Componenet/AssignEmproll";
import { Link } from "react-router-dom";
import DataTable from "../components/Tables/Tables";
import CommonModal from "../components/Common/CommonModal";
// import {
//   fetchAllBusinesses,
//   addBusiness,
//   editBusiness,
//   clearSelectedData,
//   updateSubmit,
//   businesssectorFetch,
//   setFilterData,
//   setLoading,
//   businessDelete,
// } from "../store/silce/bussiness";
import {
  closeDeleteModal,
  closeEditModal,
  closeModal,
  openDeleteModal,
  openEditModal,
  openModal,
} from "../store/silce/modalSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchData,
  setFilterData,
  fetchDataAllRollList,
  addRole,
  clearSelectedData,
  removeRoll,
  editRoll,
  fetchDatRoleListByRoleId,
} from "../store/silce/roll";
import { helper } from "../lib/helper";

import TableDropdown from "../components/TableDropDown/TableDropdown";
import CommonEditModal from "../components/Common/CommonEditModal";

const Roleemplist = () => {
  const [Roleassign, setRoleassignek] = useState([]);
  const [rollist, setRolelists] = useState([]);
  const [emplist, setEmplist] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = (id) => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [outid, setOutid] = useState(0);
  const [currentTitle, setCurrentTitle] = useState("add-role");
  const showModal = useSelector((state) => state.modal.showModal);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const busid = useSelector(state =>state?.selectOption?.value)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
    dispatch(fetchDataAllRollList());
  }, [busid]);


  const AllrollLists = useSelector((state) => {
    return state?.roll?.fetchDataAllRollList?.data?.roles;
  });

  console.log(AllrollLists,"AllrollLists")

  const filterdData = useSelector((state) => {
    return state?.roll?.filterdData;
  });

  const loading = useSelector((state) => {
    return state.roll.loading;
  });

  const updatedData = useSelector((state) => {
    return state.roll.updatedData;
  });

  console.log(updatedData, "updatedData");

  const getRoll = () => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("selecteoption")
    ) {
      dispatch(fetchDataAllRollList());
    }
  };
  const onDELETE = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(removeRoll({ id: id })).then(
            () => (
              helper.sweetalert.toast(
                "Deleted",
                "Your Role has been deleted.",
                "success"
              ),
              getRoll()
            )
          );
        }
      });
  };

  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Name",
      selector: (row) => row.roleName,
      width: "150px",
    },
    {
      name: "Permissions Name",
      selector: (row) => row.permissions?.map((item) => item?.show_name),
      width: "450px",
    // // },
    // name: "Permissions Name",
    // width: "10%",
    // selector: (row) => row.permissions.map((item) => item.show_name),
    // cell: (item) => {
    //   return (
    //     <div>
    //       <Tooltip title={ (item) => item.permissions.map((item) => item.show_name)} arrow>
    //         <p
    //           style={{
    //             height: "20px",
    //             overflow: "hidden",
    //             "text-overflow": "ellipsis",
    //           }}
    //         >
    //          { (item) => item.permissions.map((item) => item.show_name)}
    //         </p>
    //       </Tooltip>
    //     </div>
    //   );
    // },
  },
    {
      name: "No. of Permissions",
      selector: (row) => row.permissions.length,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <TableDropdown
            onDeleteClick={() => onDELETE(row.roleId)}
            onClick={() => onDELETE(row.roleId)}
            isRemove={true}
            openEditModal={() => dispatch(openEditModal("edit-roll"))}
            isEdit={true}
            isPermissionsRole={true}
            page="roleList"
            id={row.roleId}
            displaydata={displaydata}
          />
        );
      },
    },
  ];

  const [showComponents, setShowComponents] = useState([]);

  const [formData, setFormData] = useState({
    role_name: "",
  });
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addRole(event));
    dispatch(fetchDataAllRollList());
    getRoll();
    dispatch(closeModal());
    setShowComponents([]);
  };

  useEffect(() => {
    dispatch(fetchDataAllRollList());
  }, [busid]);

  const [formData1, setFormData1] = useState({
    role_name: "",
  });

  const [num, setNum] = useState("");
  const limit = 10;
  const handleInputChange1 = (event) => {
    setFormData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
    let value = event.target.value;
    if (event.target.type === "number") {
      value = value.length > limit ? value.substring(0, limit) : value;
    }
    console.log(value, event.key, event, num);
    event.target.value = value;
  };

  const displaydata = async (id) => {
    dispatch(fetchDatRoleListByRoleId({ id: id }));
    setOutid(id);
  };

  const AllrolllistViewRole = useSelector((state) => {
    return state?.roll?.selectedData?.data?.roles;
  });

  console.log(AllrolllistViewRole,"AllrolllistViewRole")

  const handleSubmit1 = (event) => {
    event.preventDefault();
    dispatch(editRoll({ rollId: outid, event: event }));
    // displaydata(updatedData?.rollId);
    dispatch(fetchDataAllRollList());
    dispatch(closeEditModal());
  };

  const pageData = {
    outid: outid,
    formData: formData,
    data: AllrolllistViewRole,
  };

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col className="mt-3 mb-2">
              <Link to="/AssignEmproll">
                <Button
                  className="submitButtons"
                  style={{
                    float: "right",
                    marginLeft: "10px",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Assign Role
                </Button>
              </Link>
              <Button
                className="submitButtons"
                onClick={() => {
                  dispatch(openModal("add-role"));
                  setCurrentTitle("Add New Role");
                }}
                style={{ float: "right", marginLeft: "10px" }}
              >
                Add New Role
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                style={{
                  "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
                }}
                className=" mt-3 mb-4"
              >
                <DataTable
                  loading={loading}
                  title={"Role List"}
                  columns={columns}
                  data={AllrollLists}
                  setData={setFilterData}
                  keys={["roleName"]}
                  filterdData={filterdData}
                />
              </div>
            </Col>

            <CommonModal
              size="lg"
              pageData={pageData}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              show={showModal}
              onHide={() => {
                dispatch(closeModal());
                dispatch(clearSelectedData());
              }}
              name={currentPage}
              title={currentTitle}
              showComponents={showComponents}
              setShowComponents={setShowComponents}
            />

            <CommonEditModal
              size="lg"
              pageData={pageData}
              onChange={handleInputChange1}
              onSubmit={handleSubmit1}
              show={showEditModal}
              onHide={() => {
                dispatch(closeEditModal());
                dispatch(clearSelectedData());
              }}
              name={"edit-roll"}
              title={"Edit Roll"}
            />
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Roleemplist;
