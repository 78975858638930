import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import {
  Container,
  Row,
  Modal,
  Tab,
  Tabs,
  Button,
  Form,
} from "react-bootstrap";
import AttModal from "../Model/Att.modal";
import moment from "moment";
import { helper } from "../lib/helper";
import DataTable from "../components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAttendance,
  fetchAllPrevAttendance,
  setFilterData,
  setOldData,
  setPrevFilterData,
  AttendanceStatusprev,
} from "../store/silce/attendance";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import { EmpHomeData } from "../store/silce/Employee";
import { HomeStatus } from "../store/silce/dashboardSlice";
import Loader from "../components/Common/Loading";
const Typeupdate = () => {
  const [attendancelist, setattendanceList] = useState([]);
  const [popUpEmpId, setPopUpEmpId] = useState(0);
  const [undoattand, setUndoattand] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const employeeDetails = useSelector(
    (state) => state?.employee?.Employee?.data?.permissions
  );
  const filteredData =
    employeeDetails &&
    employeeDetails?.filter((item) => item?.name === "admin_attendance");
  const busid = useSelector((state) => state?.selectOption?.value);
  const filterdData = useSelector((state) => state.attendance.filterdData);
  
  const prevAttendanceList = useSelector(
    (state) => state.attendance.prevAttendanceList
  );
  const prevAttendanceListStatus = useSelector(
    (state) => state.attendance.prevAttendanceListStatusbaise
  );
  const prevFilterdData = useSelector(
    (state) => state.attendance.prevFilterdData
  );
  const Admindetails = useSelector(
    (state) => state?.dashboard?.home?.data?.roleName
  );
  const loading = useSelector((state) => {
    return state.attendance.loading;
  });
  const dashboardloading = useSelector((state) => {
    return state.dashboard.homeLoading;
  });
  const prevAttloading = useSelector((state) => {
    return state.attendance.prevStatusloading;
  });
 

  const attendanceList = useSelector((state) => {
    return state?.attendance?.attendanceList;
  });
  const oldData = useSelector((state) => {
    return state?.attendance?.oldData;
  });

  const homestatus = useSelector((state) => state?.dashboard?.HomeStatuss?.emp);
  const homestatus1 = useSelector((state) => state?.dashboard);
  const [homeStatuss, setHomeStatuss] = useState("homestatus");
  const openCheckoutModal = (emp_id) => {
    setPopUpEmpId(emp_id);
    handleShow(true);
  };

  const openCheckoutModal1 = (emp_id) => {
    setPopUpEmpId(emp_id);
    handleShow1(true);
  };
  const openCheckoutModal2 = (emp_id) => {
    setPopUpEmpId(emp_id);
    handleShow2(true);
  };
  const openCheckoutModal3 = (emp_id) => {
    setPopUpEmpId(emp_id);
    handleShow3(true);
  };
  useEffect(() => {
    dispatch(fetchAllAttendance());
  }, [busid]);

  const dispatch = useDispatch();
  const currentDate = new Date();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleAbsentClick = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI("Absent", emp_id);
  };
  const handleAbsentClick1 = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI1("Absent", emp_id);
  };
  const handleIntClick = () => {
    setLoadingApi(true);
    const userTime = document.getElementById("punchInTime").value;
    sendDataToAPI("present", popUpEmpId, userTime);
  };
  const handleIntClick1 = () => {
    setLoadingApi(true);
    const userTime = document.getElementById("punchInTime").value;

    sendDataToAPI1("present", popUpEmpId, userTime);
  };

  const handleOuttClick = () => {
    setLoadingApi(true);
    const userTime = document.getElementById("punchOutTime").value;
    sendDataToAPI("out", popUpEmpId, userTime);
  };
  const handleOuttClick1 = () => {
    setLoadingApi(true);
    const userTime = document.getElementById("punchOutTime").value;
    sendDataToAPI1("out", popUpEmpId, userTime);
  };

  const handleLeaveClick = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI("Leave", emp_id);
  };
  const handleLeaveClick1 = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI1("Leave", emp_id);
  };

  const handleOvertimeClick = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI("overtime", emp_id);
  };
  const handleOvertimeClick1 = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI1("overtime", emp_id);
  };
  const handleWeekClick = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI("Week Off", emp_id);
  };
  const handleWeekClick1 = (emp_id) => {
    setLoadingApi(true);
    sendDataToAPI1("Week Off", emp_id);
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "900",
      },
    },
  };
  const sendDataToAPI1 = (status, emp_id, userTime) => {
    const momentTime = moment(userTime, "HH:mm A");
    const formattedTime = momentTime.format("hh:mm A");
    const perviousData = moment(input).format("DD-MM-YYYY");
    const formdata = new FormData();
    moment().format("LT");
    formdata.append("mark_attendance", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("mark_type", status);
    formdata.append("emp_id", emp_id);

    formdata.append("mark_time", formattedTime);
    formdata.append("mark_date", perviousData);
    AttModal.Attupdate(formdata)
      .then((res) => {
        helper.sweetalert.toast("Attendance Marked Successfully");
        if (res?.data?.is_error === false) {
          setattendanceList(res.data);
          dispatch(fetchAllPrevAttendance({ formattedDate: formattedDate }));
          // dispatch(AttendanceStatusprev({ formattedDate: formattedDate }));
          handleClose2();
          handleClose3();
          Previousdat();
          setLoadingApi(false);
        } else {
          helper.sweetalert.toast(res?.data?.message, "", "warning");
        }
      })
      .catch((error) => {
        
      });
  };

  const [loadingApi, setLoadingApi] = useState(false);
  const sendDataToAPI = (status, emp_id, userTime) => {
    const momentTime = moment(userTime, "HH:mm A");
    const formattedTime = momentTime.format("hh:mm A");
    const formdata = new FormData();
    const fromDateds = moment(currentDate).format("DD-MM-YYYY");
    moment().format("LT");
    formdata.append("mark_attendance", localStorage.getItem("token"));
    formdata.append("bus_id", localStorage.getItem("selecteoption"));
    formdata.append("mark_type", status);
    formdata.append("emp_id", emp_id);
    formdata.append("mark_date", fromDateds);
    formdata.append("mark_time", formattedTime);
    // formdata.append("mark_date", formattedDate)
    AttModal.Attupdate(formdata)
      .then((res) => {
        handleClose();
        handleClose1();
        setLoadingApi(false);
        
        if (res?.data?.is_error === false) {
          setattendanceList(res.data);
          fetchData();
          helper.sweetalert.toast("Attendance Marked Successfully");
          handleClose();
          handleClose1();
        } else {
          helper.sweetalert.toast(res?.data?.message, "", "warning");
          handleClose();
          handleClose1();
        }
      })
      .catch((error) => {
        
      });
  };
  const onUndo = (att_id) => {
    const formdata = new FormData();
    formdata.append("undo_attendance", localStorage.getItem("token"));
    formdata.append("attId", att_id);
    AttModal.undoatt(formdata)
      .then((res) => {
        fetchData();
        setUndoattand(res.data.emp);
        helper.sweetalert.toast("Attendance Undo Successfully");
      })
      .catch((error) => {
  
      });
  };
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      //RDT provides index by default,
      // width: "5%",
      center: "center",
    },
    {
      name: "Image",
      selector: (row) => row.pro_img,
      cell: (row) => {
        return (
          <img
            src={
              row.profile_imgUrl
                ? row.profile_imgUrl
                : `http://qrstaff.in/api/pro_pic/${row.pro_img}`
            }
            alt="Avatar"
            className="m-1"
            style={{ width: "30px", height: "30px", borderRadius: "10px" }}
          />
        );
      },
      // width: "8%",
      center: "center",
    },
    {
      name: "Staff ID",
      selector: (row) => row.emp_code,
      // width: "10%",.
      //  center:'center'
    },
    {
      name: "Staff Name",
      selector: (row) => `${row.name}`,
      // width: "15%",
      //  center:'center'
    },

    {
      name: "Designation",
      selector: (row) => row.designation,
      // width: "15%",
      //  center:'center'
    },

    {
      name: "Status",
      selector: (row) => row.marked_status,
      cell: (row) => {
        return (
          <div>
            <span className="text-capitalize">{row.marked_status}</span>
          </div>
        );
      },
      // width: "8%",
      //   center:'center'
    },
    {
      name: "Punch In",
      selector: (row) => row.in_tim,
      cell: (row) => {
        return (
          <div>
            <span className="text-uppercase">{row.in_tim}</span>
          </div>
        );
      },
      // width: "8%",
    },
    {
      name: "Punch Out",
      selector: (row) => row.out_tim,
      cell: (row) => {
        return (
          <div>
            <span className="text-uppercase">{row.out_tim}</span>
          </div>
        );
      },
      // width: "9%",
    },
    {
      name: "Task",
      selector: (row) => row.out_comment,
      // width: "18%",
    },
    ((filteredData &&
      filteredData.length > 0 &&
      filteredData[0]?.add == "true" &&
      filteredData[0]?.view == "true") ||
      Admindetails === "client") && {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            absentClick={() => handleAbsentClick(row.emp_id)}
            punchInClick={() => openCheckoutModal(row.emp_id)}
            puchOutClick={() => openCheckoutModal1(row.emp_id)}
            overTimeClick={() => handleOvertimeClick(row.emp_id)}
            leaveClick={() => handleLeaveClick(row.emp_id)}
            weekOffClick={() => handleWeekClick(row.emp_id)}
            undoClick={() => onUndo(row.att_id)}
            isAttendance={true}
            id={row?.id}
            style={{}}
          />
        );
      },
    },
  ];
  const columns1 = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      //RDT provides index by default,
      // width: "5%",
      center: "center",
    },
    {
      name: "Image",
      selector: (row) => row.pro_img,
      cell: (row) => {
        return (
          <img
            src={`http://qrstaff.in/api/pro_pic/${row.pro_img}`}
            alt="Avatar"
            className="m-1"
            style={{ width: "30px", height: "30px", borderRadius: "10px" }}
          />
        );
      },
      // width: "8%",
      center: "center",
    },
    {
      name: "Staff ID",
      selector: (row) => row.emp_code,
      // width: "10%",
      //  center:'center'
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
      // width: "15%",
      //  center:'center'
    },

    {
      name: "Designation",
      selector: (row) => row.designation,
      // width: "15%",
      //  center:'center'
    },

    {
      name: "Status",
      selector: (row) => row.marked_status,
      cell: (row) => {
        return (
          <div>
            <span className="text-capitalize">{row.marked_status}</span>
          </div>
        );
      },
      // width: "8%",
      //   center:'center'
    },
    {
      name: "Punch In",
      selector: (row) => row.in_tim,
      cell: (row) => {
        return (
          <div>
            <span className="text-uppercase">{row.in_tim}</span>
          </div>
        );
      },
      // width: "8%",

      //  center:'center'
    },
    {
      name: "Punch Out",
      selector: (row) => row.out_tim,
      cell: (row) => {
        return (
          <div>
            <span className="text-uppercase">{row.out_tim}</span>
          </div>
        );
      },
      // width: "9%",
      //   center:'center'
    },
    {
      name: "Task",
      selector: (row) => row.out_comment,
      //   center:'center',
      // width: "18%",
    },
    {
      name: "#",
      selector: (row) => row.action,
      cell: (row) => {
        return (
          <TableDropdown
            absentClick={() => handleAbsentClick1(row.emp_id)}
            punchInClick={() => openCheckoutModal2(row.emp_id)}
            puchOutClick={() => openCheckoutModal3(row.emp_id)}
            overTimeClick={() => handleOvertimeClick1(row.emp_id)}
            leaveClick={() => handleLeaveClick1(row.emp_id)}
            weekOffClick={() => handleWeekClick1(row.emp_id)}
            undoClick={() => onUndo(row.att_id)}
            isAttendance={true}
            id={row?.id}
          />
        );
      },
    },
  ];

  const newhomw = homestatus?.[0]?.marked_status;

  const [input, setInput] = useState("");
  const formattedDate = moment(input).format("DD-MM-YYYY");
  const Previousdat = async (event) => {
    event.preventDefault();
    dispatch(fetchAllPrevAttendance({ formattedDate: formattedDate }));
    // dispatch(AttendanceStatusprev({ formattedDate: formattedDate }));
    // profileModel.Previousdata(formdata).then((res) => {
    //     setPredata(res.data.emp)
    //    
    // }).catch((error) => {
    //    
    // })
  };
  const fetchData = async () => {
    dispatch(fetchAllAttendance());
  };
  useEffect(() => {
    fetchData();
    Previousdat();
  }, [busid]);
  const cleer = () => {
    setHomeStatuss("");
    dispatch(fetchAllAttendance());
    setSelectedOptionname("Select Status");
    setSelectedOptionId("Select Status");
  };
  useEffect(() => {
    dispatch(fetchAllAttendance());
  }, [oldData]);
  const [selectedOptionname, setSelectedOptionname] = useState("Select Emp");
  const [selectedOptionIds, setSelectedOptionId] = useState("Select Status");
  const handlenewdataad = (event) => {
    const selectedId = event.target.value;
    const selectedname = event.target.name;
    setSelectedOptionId(selectedId);
    setSelectedOptionname(selectedname);
    dispatch(HomeStatus({ status: selectedId }));
    dispatch(AttendanceStatusprev({ status: selectedId, date: formattedDate }));
  };
  const [SelectIDs, setSelectIDs] = useState("");
  const getAllID = (row) => {
  
    setSelectIDs(row.id);
  };
  const [selectedRows, setSelectedRows] = useState();
  const handleSelectedRowsChange = (state) => {
    // Update the selectedRows state whenever the selection changes
    setSelectedRows(state.selectedRows);
  };

  const idget = selectedRows && selectedRows?.map((item, index) => item.emp_id);

  const menuItems = [
    {
      label: "Absent",
      color: "rgb(202 90 90)",
      onClick: () => {
        handleAbsentClick(idget);
      },
    },
    {
      label: "Punch In",
      color: "rgb(81 151 81)",
      onClick: () => {
        handleClose();
        openCheckoutModal(idget);
      },
    },
    {
      label: "Punch Out",
      color: "rgb(204, 204, 204)",
      onClick: () => {
        handleClose();
        openCheckoutModal1(idget);
      },
    },

    {
      label: "Overtime",
      color: "rgb(255, 165, 0)",
      onClick: () => {
        handleClose();
        handleOvertimeClick(idget);
      },
    },
    {
      label: "Leave",
      color: "rgb(95 95 202)",
      onClick: () => {
        handleClose();
        handleLeaveClick(idget);
      },
    },
    {
      label: "Week Off",
      color: "rgb(211 101 211)",
      onClick: () => {
        handleClose();
        handleWeekClick(idget);
      },
    },
    {
      label: "Undo",
      color: "rgb(128, 128, 128)",
      onClick: () => {
        handleClose();
        onUndo(idget);
      },
    },
  ];

 

  return (
    <>
      <Container fluid>
        <select
          name=""
          id=""
          className="cutum-data"
          onChange={handlenewdataad}
          title={selectedOptionname}
        >
          <option
            value="Select Status"
            selected={"Select Status" === selectedOptionname}
          >
            Select Status{" "}
          </option>
          <option
            value="present"
            selected={"in" === homestatus?.[0]?.marked_status}
          >
            Present
          </option>
          <option
            value="absent"
            selected={"Absent" === homestatus?.[0]?.marked_status}
          >
            Absent
          </option>
          <option
            value="leave"
            selected={homestatus?.[0]?.marked_status == "Leave"}
          >
            Leave
          </option>
          {/* <option value="OverTime">OverTime</option> */}
          <option value="week_off">WeekOff </option>
        </select>

        <Button
          className="btn "
          style={{
            backgroundColor: "orange ",
            color: "white",
            borderStyle: "none",
            marginLeft: "10px",
          }}
          onClick={cleer}
        >
          Clear All
        </Button>

        <Row>
          <div
            style={{
              "box-shadow": "rgba(17, 12, 46, 0.15) 5px 5px 34px 5px",
              width: "100%",
            }}
            className=" mt-2 mb-4"
          >
            <Tabs
              defaultActiveKey="home"
              id="fill-tab-example"
              className="mb-3 mt-3"
              variant="pills"
              fill
            >
              <Tab
                eventKey="home"
                title="Today Attendance "
                onClick={() => setInput("")}
              >
                {selectedRows == "" ? (
                  ""
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    {menuItems.map((item, index) => (
                      <div key={index}>
                        <button
                          onClick={item.onClick}
                          className="text-center"
                          style={{
                            backgroundColor: item.color,
                            color: "#FFFFFF",
                            borderRadius: "2px",
                            width: "15vh",
                            marginLeft: "2px",
                          }}
                        >
                          {item.label}
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                <DataTable
                  className="mt-2"
                  columns={columns}
                  data={
                    selectedOptionIds === "Select Status"
                      ? attendanceList
                      : homestatus
                  }
                  loading={
                    selectedOptionIds === "Select Status"
                      ? loading
                      : dashboardloading
                  }
                  keys={["name", "designation"]}
                  setData={setFilterData}
                  filterdData={filterdData}
                  pagination
                  responsive
                  fixedHeader
                  selectableRowsHighlight
                  selectableRows={getAllID}
                  onSelectedRowsChange={handleSelectedRowsChange}
                />
              </Tab>
              <Tab eventKey="profile" title="Previous Attendance">
                {selectedRows == "" ? (
                  ""
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    {menuItems.map((item, index) => (
                      <div key={index}>
                        <button
                          onClick={item.onClick}
                          className="text-center"
                          style={{
                            backgroundColor: item.color,
                            color: "#FFFFFF",
                            borderRadius: "2px",
                            width: "15vh",
                            marginLeft: "2px",
                          }}
                        >
                          {item.label}
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                <form onSubmit={Previousdat}>
                  <span className="text-black" style={{ fontSize: "16px" }}>
                    Select Date
                  </span>{" "}
                  <input
                    className="text-black"
                    type="date"
                    name="data_dt"
                    id=""
                    value={input}
                    onInput={(e) => setInput(e.target.value)}
                    style={{ height: "35px", width: "20%" }}
                  />
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      float: "right",
                      backgroundColor: "#18285a",
                      color: "white",
                    }}
                  >
                    submit
                  </button>
                </form>
                <div className="mt-3">
                  <DataTable
                    customStyles={customStyles}
                    columns={columns1}
                    loading={
                      selectedOptionIds === "Select Status"
                        ? loading
                        : prevAttloading
                    }
                    data={
                      selectedOptionIds === "Select Status"
                        ? prevAttendanceList
                        : prevAttendanceListStatus
                    }
                    setData={setPrevFilterData}
                    filterdData={prevFilterdData}
                    keys={["name", "designation"]}
                    pagination
                    responsive
                    fixedHeader
                    render
                    // fixedHeaderScrollHeight='400px'
                    selectableRowsHighlight
                    selectableRows={getAllID}
                    onSelectedRowsChange={handleSelectedRowsChange}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Punch In Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "20px", marginBottom: "20px" }}>
            Today, {currentDate.toDateString()}
          </p>
          <Form.Control type="time" name="" formate id={`punchInTime`} />
        </Modal.Body>
        <div className="m-3">
          {/* <button className='secondary ' style={{borderRadius:'8px',padding:"8px"}}  onClick={handleClose} >Close</button>
                <button onClick={() => (handleIntClick())} style={{backgroundColor:'#0B142D',color:'#fff',padding:"8px" , float:'right',borderRadius:'5px'}} >Punch In</button> */}

          <Button
            style={{ float: "left", marginLeft: "5%" }}
            className="w-25"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          {loadingApi ? (
            <p
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
            >
              <Loader />
            </p>
          ) : (
            <Button
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
              className="btn submitButtons w-25"
              type="submit"
              variant="primary"
              onClick={() => handleIntClick()}
            >
              Submit
            </Button>
          )}
        </div>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Punch Out Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontSize: "20px", marginBottom: "20px" }}>
            Today, {currentDate.toDateString()}
          </p>

          <Form.Control
            type="time"
            formate
            id={`punchOutTime`}
            style={{ borderRadius: "8px" }}
          />
        </Modal.Body>
        <div className="m-3">
          {/* <button
            className="secondary"
            style={{ borderRadius: "8px", padding: "8px" }}
            onClick={handleClose1}
          >
            Close
          </button>
          <button
            style={{
              backgroundColor: "#0B142D",
              color: "#fff",
              padding: "8px",
              float: "right",
              borderRadius: "5px",
            }}
            onClick={() => handleOuttClick()}
          >
            Punch Out
          </button> */}

          <Button
            style={{ float: "left", marginLeft: "5%" }}
            className="w-25"
            variant="secondary"
            onClick={handleClose1}
          >
            Close
          </Button>
          {loadingApi ? (
            <p
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
            >
              <Loader />
            </p>
          ) : (
            <Button
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
              className="btn submitButtons w-25"
              type="submit"
              variant="primary"
              onClick={() => handleOuttClick()}
            >
              Submit
            </Button>
          )}
        </div>
      </Modal>
      {/* jj */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Punch In Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control type="time" name="" formate id={`punchInTime`} />
        </Modal.Body>
        <div className="m-3">
          {/* <button
            className="secondary"
            style={{ borderRadius: "8px", padding: "8px" }}
            onClick={handleClose2}
          >
            Close
          </button>
          <button
            style={{
              backgroundColor: "#0B142D",
              color: "#fff",
              padding: "8px",
              float: "right",
              borderRadius: "5px",
            }}
            disabled={() => handleIntClick1()}
          >
            Punch In
          </button> */}

          <Button
            style={{ float: "left", marginLeft: "5%" }}
            className="w-25"
            variant="secondary"
            onClick={handleClose2}
          >
            Close
          </Button>
          {loadingApi ? (
            <p
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
            >
              <Loader />
            </p>
          ) : (
            <Button
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
              className="btn submitButtons w-25"
              type="submit"
              variant="primary"
              onClick={() => handleIntClick1()}
            >
              Submit
            </Button>
          )}
        </div>
      </Modal>
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title>Punch Out Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control type="time" formate id={`punchOutTime`} />
        </Modal.Body>
        <div className="m-3">
          {/* <button
            className="secondary"
            style={{ borderRadius: "8px", padding: "8px" }}
            onClick={handleClose3}
          >
            Close
          </button>
          <button
            style={{
              backgroundColor: "#0B142D",
              color: "#fff",
              padding: "8px",
              float: "right",
              borderRadius: "5px",
            }}
            onClick={() => handleOuttClick1()}
          >
            Punch Out
          </button> */}

          <Button
            style={{ float: "left", marginLeft: "5%" }}
            className="w-25"
            variant="secondary"
            onClick={handleClose3}
          >
            Close
          </Button>
          {loadingApi ? (
            <p
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
            >
              <Loader />
            </p>
          ) : (
            <Button
              style={{
                float: "right",
                borderStyle: "none",
                marginRight: "5%",
              }}
              className="btn submitButtons w-25"
              type="submit"
              variant="primary"
              onClick={() => handleOuttClick1()}
            >
              Submit
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};
export default Typeupdate;
