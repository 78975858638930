import React from 'react'
import { Container, Row, Col, Card, } from 'react-bootstrap';

const SalaryOver = () => {
    return (
        <div>
            <Card>
                <Container>
                    <Row>
                        <Col>
                            <h4 className='m-4'>Salary</h4>
                        </Col>
                    </Row>
                    <hr />

                    <Row className='mb-5'>
                        <Col>
                            <div class="container-line">
                                <div class="timeline-block timeline-block-right">
                                    <div class="marker"></div>
                                    <div class="timeline-content">
                                        <h3>Facebook</h3>
                                        <span>SalaryOver</span>
                                        <p>50000</p>
                                    </div>
                                </div>

                                
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </div>
    )
}

export default SalaryOver
