import React, { useState, useEffect } from "react";

import DataTable from "../../components/Tables/Tables";
import { Container, Row, Col } from "react-bootstrap";

import EmphomeModal from "../Modal/Emphome.Modal";
import { getEmpHolidayLists, setFilterData } from "../../store/silce/Employee";
import { useDispatch, useSelector } from "react-redux";

const EmpHoliday = () => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [Holiday, setHolioday] = useState([]);



  

  const columns = [
    {
      name: "SN.",
      cell: (row, index) => index + 1,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmpHolidayLists());
  }, []);

  const holidayListEmp = useSelector((state) => {
    return state?.employee?.getEmpHolidayLists?.data?.data?.holidays;
  });

  const filterdData = useSelector((state) => {
    return state.employee?.getEmpHolidayLists?.data?.data?.holidays;
  });

  const loading = useSelector((state) => {
    return state?.employee?.loading;
  });

  useEffect(() => {
    const formdata = new FormData();
    formdata.append("getEmpHolidayLists", localStorage.getItem("token"));
    formdata.append("sel_year", "2023");
    EmphomeModal.Empholiday(formdata)
      .then((res) => {
        setHolioday(res.data.data.holidays);
        // setLoading(false);
      })
      .catch((error) => {
       
      });
  }, [selectedYear]);

  const handleYearChange = (event) => {
  ;
    setSelectedYear(parseInt(event.target.value));
  };

  

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
              {/* <DataTable
                                title='Holiday List'
                                columns={columns}
                                data={Holiday}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight='450px'
                                selectableRowsHighlight
                                highlightOnHover
                            /> */}
              <DataTable
                loading={loading}
                columns={columns}
                data={holidayListEmp}
                title={"Leave History"}
                setData={setFilterData}
                keys={["name"]}
                filterdData={filterdData}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default EmpHoliday;
