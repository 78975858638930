import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Nav, Tab, Button } from 'react-bootstrap';
import blankdata from '../../Aseset/image/no_data.svg'
import { useParams } from 'react-router-dom';
import leaveModel from '../../Model/leave.model';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-react-ui-kit';
import { FcApproval } from 'react-icons/fc';
import { FcCancel } from 'react-icons/fc'
import { MdPendingActions } from 'react-icons/md'
import { ImSigma } from 'react-icons/im'
import { IoIosPeople } from 'react-icons/io'
import DataTable from '../../components/Tables/Tables';
const Leave = () => {
    const params = useParams();
    const [empleave, setEmpleave] = useState([]);
    const columns = [
        {
            name: 'Applied Date',
            selector: (row) => row.applied_on,
            // maxWidth: '150px',
        },

        {
            name: 'Leave Start Date',
            selector: (row) => row.leave_to,
            // maxWidth: '120px',
        },
        {
            name: 'Leave End Date',
            selector: (row) => row.leave_from,
            // maxWidth: '120px',
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            cell: (row) => {
                if (row.status === "approved") {
                  return (
                    <>
                      <div
                        className="w-50"
                        style={{
                          backgroundColor: "rgb(81 151 81)",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          height: "3vh",
                        }}
                      >
                        <center>
                          {" "}
                          <p
                            className="text-capitalize mt-1"
                            style={{ position: "relative", bottom: "2px" }}
                          >
                            {" "}
                            {row.status}
                          </p>
                        </center>
                      </div>
                    </>
                  );
                } else if (row.status === "denied") {
                  return (
                    <>
                      <div
                        className="w-50"
                        style={{
                          backgroundColor: "rgb(202 90 90)",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          height: "3vh",
                        }}
                      >
                        <center>
                          {" "}
                          <p
                            className="text-capitalize  mt-1"
                            style={{ position: "relative", bottom: "2px" }}
                          >
                            {" "}
                            {row.status}
                          </p>
                        </center>
                      </div>
                    </>
                  );
                } else if (row.status === "pending") {
                  return (
                    <>
                      <div
                        className="w-50"
                        style={{
                          backgroundColor: " rgb(255, 165, 0)",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          height: "3vh",
                        }}
                      >
                        <center>
                          {" "}
                          <p
                            className="text-capitalize  mt-1"
                            style={{ position: "relative", bottom: "2px" }}
                          >
                            {" "}
                            {row.status}
                          </p>
                        </center>
                      </div>
                    </>
                  );
                }
              },
        },
        {
            name: 'Admin Comment',
            selector: (row) => row.admin_comment,

        },

    ]
    const leave_status = [
        {
            id: 1,
            total: empleave?.approved_leave,
            title: 'Leave Approved',
            icon: <FcApproval />
        },
        {
            id: 2,
            total: empleave?.denied_leave,
            title: 'Denied Leave',
            icon: <FcCancel />
        },
        {
            id: 3,
            total: empleave?.pending_leave,
            title: 'Pending Request',
            icon: <MdPendingActions />
        },
        {
            id: 4,
            total: empleave?.total_leave,
            title: 'Total Leave',
            icon: <ImSigma />
        },
    ];
    useEffect(() => {

        const formdata = new FormData();
        formdata.append("employee_leaves", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"));
        formdata.append("emp_id", params?.id);
        leaveModel.empleave(formdata).then((res) => {
            setEmpleave(res.data)
            console.log(res.data.leave)
        }).catch((error) => {
            console.log("error => ", error)
        })
    }, []);

    return (
        // <div>
        //     <Card className=' mb-5'>
        //         <Container>
        //             <Row>
        //                 <Col>
        //                 <h4 style={{marginTop:'20px',marginLeft:'20px'}}>Leave</h4>
        //                 </Col>
        //             </Row>
        //             <hr  style={{border:'1px solid grey'}}/>
        //             <Row>
        //                 <Col sm={3}>
        //                     <Card>
        //                         <div className='mt-4 mb-4'>
        //                             <center>{empleave?.approved_leave}</center>
        //                             <p className='text-center'>Leave approved</p>
        //                         </div>
        //                     </Card>
        //                 </Col>

        //                 <Col sm={3}>
        //                     <Card>
        //                         <div className='mt-4 mb-4'>
        //                             <center>{empleave?.denied_leave}</center>
        //                             <p className='text-center'>Denied leave</p>
        //                         </div>
        //                     </Card>
        //                 </Col>
        //                 <Col sm={3}>
        //                     <Card>
        //                         <div className='mt-4 mb-4'>
        //                             <center>{empleave?.pending_leave}</center>
        //                             <p className='text-center'>Pending request</p>
        //                         </div>
        //                     </Card>
        //                 </Col>
        //                 <Col sm={3}>
        //                     <Card>
        //                         <div className='mt-4 mb-4'>
        //                             <center>{empleave?.total_leave}</center>
        //                             <p className='text-center'>Total leave</p>
        //                         </div>
        //                     </Card>
        //                 </Col>
        //             </Row>

        //             <hr />
        //             <Row className='mt-4'>
        //                 <DataTable

        //                     columns={columns}
        //                     data={empleave.leave}
        //                     pagination
        //                     fixedHeader
        //                     fixedHeaderScrollHeight='470px'
        //                     selectableRowsHighlight
        //                     highlightOnHover

        //                 />
        //             </Row>
        //             <Row>

        //             </Row>
        //         </Container>
        //     </Card>
        // </div>

        <MDBCard className="mt-3">
            <MDBCardHeader>
            <span style={{ marginTop: 'px', marginLeft: '10px', fontSize: '19px' }}>
                                <b style={{ paddingLeft: '' }}>Leave</b></span>
            </MDBCardHeader>
            <MDBCardBody>
                <Row xs={1} md={4} sm={12} className="g-4">
                    {leave_status.map((ele, idx) => (
                        <Col key={idx}>
                            <Card
                                style={{ border: 'none', boxShadow: 'rgba(17, 12, 46, 0.15) 5px 5px 34px 5px' }}
                                className='text-center'
                            >
                                <Card.Body>
                                    <div className='d-flex align-items-center flex-wrap gap-5'>
                                        <div className='text-justify'>
                                            <Card.Title>{ele.total}</Card.Title>
                                            <Card.Text><span>{ele.icon}</span>{' '} <span className='small'>{ele.title}</span> </Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </MDBCardBody>
            <DataTable
                columns={columns}
                data={empleave.leave}
                pagination
                fixedHeader
                fixedHeaderScrollHeight='470px'
                selectableRowsHighlight
                highlightOnHover
                title={"Leave History"}

            />
        </MDBCard>
    )
}

export default Leave
