import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Tabs,
  Tab,
  Form,
  Button,
} from "react-bootstrap";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import { useParams } from "react-router-dom";
import AttModal from "../../Model/Att.modal";
import Singleatt from "../../attendance/Singleatt";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBInput,
} from "mdb-react-ui-kit";
import DataTable from "react-data-table-component";
import moment from "moment";
// import DataTable from "../../components/Tables/Tables";
const Attendance = () => {
  const [mntatt, setMntatt] = useState([]);
  const params = useParams();
  const reversedAttendanceData = mntatt?.att?.slice().reverse();

  console.log(reversedAttendanceData, "reversedAttendanceData");
  const currentDate = moment();
  // Get the current year as "YYYY" (e.g., "2024")
  const currentYear = currentDate.format("YYYY");

  // Get the current month as "MM" (e.g., "02")
  const currentMonth = currentDate.format("MM");
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const handleMonthChange = (event) => {
    const value = event.target.value;
    setSelectedMonth(value.substring(5, 7));
    setSelectedYear(value.substring(0, 4));
    console.log("cvhgjfjhfjh", value.substring(5, 7));
  };

  const handleSubmit = () => {
    const form = new FormData();
    form.append("emp_id", params?.id);
    form.append("at_month", selectedMonth);
    form.append("at_year", selectedYear);
    form.append("get_emp_detail_att", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));
    AttModal.monthatthisorty(form)
      .then((res) => {
        setMntatt(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };
  const columns = [
    // {
    //     name: 'SN.',
    //     cell: (row, index) => index + 1,
    //     // RDT provides index by default,
    //     // width: '50px'
    // },
    {
      name: "Day",
      selector: (row) => row.day,
      // width: '65px'
    },
    {
      name: "Punch In",
      selector: (row) => row.punch_in,
      // width: '100px',
    },
    {
      name: "In Location",
      selector: (row) => row.punch_in_loc,
      // width: '310px'
    },
    {
      name: "Punch Out",
      selector: (row) => row.punch_out,
      // maxWidth: '100px',
    },
    {
      name: "Out Location",
      selector: (row) => row.punch_out_loc,
      // width: '310px'
    },
    // {
    //     name: 'Behavior',
    //     selector: (row) => row.behavior,
    // },
    {
      name: "Type",
      selector: (row) => row.punch_in_mode,
      cell: (row) => {
        return (
          <div>
            <span> In - {row.punch_in_mode}</span>/
            <span className=""> Out - {row.punch_out_mode}</span>
          </div>
        );
      },
      // width: '100px'
    },
    {
      name: "	Break time",
      selector: (row) => row.break_time,
      // width: '100px'
    },
    {
      name: "Total Hours",
      selector: (row) => row.shift_hour,
      // width: '100px'
    },
    {
      name: "Entry",
      selector: (row) => row.entry_type,
      // width: '100px'
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "900",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        // wordWrap: "break-word",
      },
    },
  };
  const [salslip, setSaslip] = useState([]);
  const openLinkInNewTab = () => {
    if (salslip) {
      window.open(salslip, "_blank");
    }
  };

  const [formData1, setFormData1] = useState({
    report_month: "",
    report_year: "",
    report_format: "",
  });
  const handleInputChange1 = (event) => {
    setFormData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
  };

  const [selectedMonths, setSelectedMonths] = useState("");
  const [selectedYears, setSelectedYears] = useState("");
  const handleYearChange = (event) => {
    const value = event.target.value;
    setSelectedMonths(value.substring(5, 7));
    setSelectedYears(value.substring(0, 4));
    console.log("cvhgjfjhfjh", value.substring(5, 7));
  };
  const handleDownSubmited = () => {
    const form = new FormData();
    form.append("at_month", selectedMonths);
    form.append("at_year", selectedYears);
    form.append("emp_ids", params?.id);
    form.append("get_monthly_attendance_report", localStorage.getItem("token"));
    form.append("bus_id", localStorage.getItem("selecteoption"));

    AttModal.genmonth(form)
      .then((res) => {
        setMntatt(res.data);
        console.log(res.data);
        setSaslip(res.data.data.pdfUrl);
      })
      .catch((error) => {
        console.log("error => ", error);
      });
  };

  useEffect(() => {
    handleSubmit();
  }, []);
  return (
    <MDBCard className="mt-3">
      <MDBCardHeader>
        <span style={{ marginTop: "px", marginLeft: "10px", fontSize: "19px" }}>
          <b style={{ paddingLeft: "" }}>Attendance Report</b>
        </span>
      </MDBCardHeader>
      <MDBCardBody>
        <div className="mt-3">
          {/* <hr /> */}
          <Tabs
            defaultActiveKey="advance"
            id="uncontrolled-tab-example"
            className="mt-3 m"
            variant="pills"
          >
            {/* <Tab eventKey="Attendance" title="Attendance By Day">
                            <Singleatt />
                        </Tab> */}

            <Tab eventKey="advance" title="Attendance By Month" className="p-2">
              <Card>
                <div className="mt-4 mb-4 d-flex justify-content-end">
                  <span style={{ margin: "8px" }}>Select Month</span>
                  <MDBInput
                    type="month"
                    onChange={handleMonthChange}
                    value={`${selectedYear}-${selectedMonth}`}
                    style={{ width: "200px", marginLeft: "20px" }}
                  />
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      backgroundColor: "#18285a",
                      borderRadius: "5px",
                      marginLeft: "10px",
                      padding: "8px",
                      color: "#fff",
                      marginRight: "10px",
                    }}
                    className="btn submitButtons w-18"
                    // type="submit"
                    variant="primary"
                  >
                    {" "}
                    Submit
                  </Button>
                  {/* <button className='btn' type='submit' onClick={handleSubmit} style={{backgroundColor:'#18285a', borderRadius:'5px',marginLeft:'10px', padding:'1px', color:'#fff',marginRight:'10px'}}> Submit</button> */}
                </div>
              </Card>
              <hr />

              <DataTable
                className="mt-2"
                columns={columns}
                data={reversedAttendanceData}
                title={"Attendance"}
                // pagination
                // responsive
                // fixedHeader
                // selectableRowsHighlight
              />
            </Tab>
            <Tab eventKey="" title="Download Report" className="p-2">
              <Card>
              

                <div className="mt-4 mb-4 d-flex ">
                <div style={{marginLeft:"50px"}}>
                    <span style={{ marginRight: "10px" }}>From </span>
                    <input
                      name=""
                      id=""
                      style={{
                        width: "30%",
                        borderRadius: "8px",
                        marginRight: "10px",
                        height: "40px",
                      }}
                      type="date"
                      // value={selectedDatee}
                      // onChange={handleDateChangee}
                    />
                    <span style={{ marginRight: "10px" }}>To</span>
                    <input
                      type="date"
                      name=""
                      id=""
                      // value={selectedDate}
                      // onChange={handleDateChange}
                      style={{
                        width: "30%",
                        borderRadius: "8px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                    {/* {showDropdown && (
                      <>
                        {" "}
                        <button
                          style={{
                            backgroundColor: "#18285A",
                            borderRadius: "8px",
                            padding: "8px",
                            color: "#fff",
                          }}
                          // onClick={handleSubmite}
                        >
                          <BsFiletypeExe />
                        </button>
                        {ShowDownloadMonthloader && (
                          <img
                            src={download}
                            style={{ width: "50px", height: "50px" }}
                          />
                        )}
                      </>
                    )} */}
                    {/* {ShowDownload && (
                      <button
                        onClick={openLinkInNewTab}
                        className="btn   "
                        style={{
                          backgroundColor: "#18285a",
                          color: "white",
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                        <IoIosCloudDownload />
                      </button>
                    )} */}
                  </div>
                  <span className="mt-2">Select Month</span>
                  <MDBInput
                    wrapperClass=""
                    type="month"
                    onChange={handleYearChange}
                    style={{ width: "150px", marginLeft: "20px" }}
                  />
                  <button
                    type="submit"
                    onClick={handleDownSubmited}
                    style={{
                      backgroundColor: "#18285a",
                      borderRadius: "5px",
                      marginLeft: "10px",
                      padding: "8px",
                      color: "#fff",
                      marginRight: "10px",
                    }}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </Card>
            </Tab>
          </Tabs>
        </div>
      </MDBCardBody>
    </MDBCard>
  );
};

export default Attendance;
