import { MDBCard, MDBCardBody, MDBCardImage, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { EmpHomeData } from '../../store/silce/Employee';
import Persoanl from '../../staff/staffview/persoanl';
import EmpPersoanl from '../../staff/staffview/Employeetab/EmpPersoanl';
const MyProfile = () => {
    const dispatch = useDispatch()
    const employeeDetails = useSelector(state => state?.employee?.Employee?.data)
    
    useEffect(() => {
    dispatch(EmpHomeData())
    }, [])
    return (
        <div>
            <MDBCard className="mb-3" style={{ borderRadius: '.5rem' }}>
                <MDBRow className="g-0">
                    <MDBCol md="4" className="gradient-custom text-center text-white"
                        style={{ borderTopLeftRadius: '.5rem', borderBottomLeftRadius: '.5rem' }}>
                        <MDBCardImage
                            className=" "
                            style={{ width: '150px', borderRadius: '10px', marginTop: '80px', height: '150px' }}
                            src={`https://qrstaff.in/api/pro_pic/${employeeDetails?.profile_img}`} alt="avatar"
                            fluid />
                        <h4 className='mt-3'> {employeeDetails?.prefix} {employeeDetails?.name}
                            <br />
                        </h4>
                        {employeeDetails?.emp_status === "Active" ? (
                            <><Button style={{ backgroundColor: 'green', borderRadius: '8px', marginTop: '10px', width: '40%', padding: '5px', borderStyle: 'none' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button>
                            </>) : (<>
                                {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>)
                        }
                        {employeeDetails?.emp_status === "Inactive" ? (
                            <><Button style={{ backgroundColor: 'red', borderRadius: '8px', marginTop: '10px', width: '40%', padding: '5px', borderStyle: 'none' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button>
                            </>) : (<>
                                {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>)
                        }
                        {employeeDetails?.emp_status === "Probation" ? (
                            <><Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '40%', padding: '5px', borderStyle: 'none' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button>
                            </>) : (<>
                                {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>)
                        }
                        {employeeDetails?.emp_status === "Full-Time" ? (
                            <><Button style={{ backgroundColor: '#be3910', borderRadius: '8px', marginTop: '10px', width: '40%', padding: '5px', borderStyle: 'none' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button>
                            </>) : (<>
                                {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>)
                        }
                        {employeeDetails?.emp_status === "Part-Time" ? (
                            <><Button style={{ backgroundColor: 'blue', borderRadius: '8px', marginTop: '10px', width: '40%', padding: '5px', borderStyle: 'none' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button>
                            </>) : (<>
                                {/* <Button style={{ backgroundColor: 'orange', borderRadius: '8px', marginTop: '10px', width: '60%', padding: '5px' }}><b className='text-light'>{employeeDetails?.emp_status}</b></Button> */}
                            </>)
                        }
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBCardBody className="p-4">
                            <div>
                                <div style={{ lineHeight: '13px', paddingTop: '15px' }}>
                                    <p>{employeeDetails?.designation}</p>
                                    <p>{employeeDetails?.emp_code}</p>

                                </div>
                            </div>
                            <hr className="mt-0 mb-4" />
                            <MDBRow className="pt-1">
                                <MDBCol size="6" className="mb-3">
                                    <div className='top-card-div' >
                                        <span className='card-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
                                                <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                                            </svg>
                                        </span>
                                        <p className='card-text'><span style={{ color: 'lightgray' }}>Department</span>  <br /> {employeeDetails?.department_name} </p>
                                    </div>
                                </MDBCol>
                                <MDBCol size="6" className="mb-3">
                                    <div className='top-card-div'>
                                        <span className='card-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                            </svg>
                                        </span>
                                        <p className='card-text'><span style={{ color: 'lightgray' }}>Shift Time

                                        </span> <br />

                                            {employeeDetails?.start_time}-{employeeDetails?.end_time}</p>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <hr className="mt-0 mb-4" />
                            <MDBRow className="pt-1">
                                <MDBCol size="6" className="mb-3">
                                    <div className='top-card-div' >
                                        <span className='card-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z" />
                                            </svg>
                                        </span>
                                        <p className='card-text'><span style={{ color: 'lightgray' }}>Salary</span>  <br /> ₹ {employeeDetails?.salary} <span className='text-primary'>(current)</span> </p>
                                    </div>
                                </MDBCol>
                                <MDBCol size="6" className="mb-3">
                                    <div className='top-card-div'>
                                        <span className='card-icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-calendar-check" viewBox="0 0 16 16">
                                                <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                            </svg>
                                        </span>
                                        <p className='card-text'><span style={{ color: 'lightgray' }}>Joining date</span></p>

                                        {/* </span>  <br />  {moment(Emphist?.joining_date).format('DD/MM/YYYY')}</p> */}
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <div className="d-flex justify-content-start">
                                <a href="#!"><MDBIcon fab icon="facebook me-3" size="lg" /></a>
                                <a href="#!"><MDBIcon fab icon="twitter me-3" size="lg" /></a>
                                <a href="#!"><MDBIcon fab icon="instagram me-3" size="lg" /></a>
                            </div>
                        </MDBCardBody>
                    </MDBCol>
                </MDBRow>
                <EmpPersoanl/>
                {/* <MDBRow className="g-0 mt-2">
                <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      variant='pills'
      fill
    >
      <Tab eventKey="home" title="Basic Details">
        Tab content for Home
      </Tab>
      <Tab eventKey="profile" title=" Address Details">
        Tab content for Profile
      </Tab>
      <Tab eventKey="longer-tab" title="Emergency Contacts">
        Tab content for Loooonger Tab
      </Tab>
      <Tab eventKey="contact" title="Professional  Details" >
        Tab content for Contact
      </Tab>
    </Tabs>
                </MDBRow> */}
            </MDBCard>
        </div>
    )
}

export default MyProfile
