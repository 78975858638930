// import React, { useEffect, useState, useRef } from 'react'
// import { Container, Row, Col, Card, DropdownButton, Form, InputGroup, Nav } from 'react-bootstrap';

// import { useParams } from 'react-router-dom';
// import employeeModel from '../../Model/employee.Model';
// import { Link } from 'react-router-dom';
// import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardTitle, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBProgressBar, MDBProgress, MDBIcon, MDBListGroupItem, MDBListGroup, MDBBreadcrumb, MDBBreadcrumbItem, MDBCardHeader, MDBTypography } from 'mdb-react-ui-kit';
// const Persoanl = () => {
//     const params = useParams();
//     const [empprivew, setEmpPrivew] = useState();
//     useEffect(() => {
//         console.log(params, "data")
//         const formdata = new FormData();
//         formdata.append("edit_emp", localStorage.getItem("token"));
//         formdata.append("bus_id", localStorage.getItem("selecteoption"))
//         formdata.append("emp_id", params?.id);
//         employeeModel.Editemplyee(formdata).then((respone) => {
//             console.log(respone.data.emp)
//             setEmpPrivew(respone.data.emp)
//         }
//         ).catch((error) => {
//             console.log("error => ", error)
//         })
//     }, [])
//     const [formData, setFormData] = useState({
//         emp_name: '',
//         emp_cont: '',

//         emp_email: '',
//         dob: '',


//     });
//     const handleInputChange = (event) => {
//         setFormData({
//             ...formData,
//             [event.target.name]: event.target.value,
//         });
//     };
//     const handleSubmit = (event) => {

//         event.preventDefault();
//         const form = new FormData(event.target);
//         form.append("emp_code", empprivew?.emp_code)
//         form.append("emp_id", params?.id);
//         form.append("update_employee", localStorage.getItem("token"));
//         form.append("bus_id", localStorage.getItem("selecteoption"));

//         employeeModel.updateemp(form)
//             .then((response) => {
//                 console.log(response.data, "yes data update");
//                 alert("new user")
//                 // Display success message to the user
//                 // window.location.assign("/stafflist");
//             })
//             .catch((error) => {
//                 console.log(error);
//                 // Display error message to the user
//             });
//     };
//     const handleimageClick = () => {
//         inputRef.current.click();
//     }
//     const inputRef = useRef(null);
//     const [selectedImage, setSelectedImage] = useState(null);
//     return (
//         // <div>
//         //     <Card className='mb-5'>
//         //         <Container>
//         //             <Row>
//         //                 <Col>
//         //                     <h4 style={{ marginTop: '20px', marginLeft: '20px' }}>Personal Details</h4>
//         //                 </Col>
//         //             </Row>
//         //             <hr style={{ border: '1px solid grey' }} />
//         //             <form >
//         //                 <Row>
//         //                     <center>
//         //                         <div style={{ width: '25%' }} >
//         //                             {/* {selectedImage ? <img src={selectedImage} alt="" style={{ height: "150px", width: '150px', borderRadius: '50%', marginBottom: '20px', marginLeft: '20px' }} /> : */}
//         //                             <img src={`http://qrstaff.in/api/pro_pic/${empprivew?.profile_img}`} alt="" style={{ height: "150px", width: '150px', borderRadius: '50%', marginBottom: '20px', marginLeft: '20px' }} />
//         //                             {/* <input type="file" accept="image/*" onChange={handleInputChange} value={formData.profile_img}
//         //                                                     name='profile_img' style={{ display: 'none' }} ref={inputRef} /> */}
//         //                         </div>
//         //                     </center>
//         //                 </Row>
//         //                 <Row>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Full Name</p></Col>
//         //                     <Col sm={7}>
//         //                         <Form.Control type='text' placeholder={empprivew?.name}
//         //                             contentEditable='false'
//         //                             readOnly
//         //                             name='emp_name'
//         //                             onChange={handleInputChange}
//         //                             value={empprivew?.name}
//         //                             required
//         //                             // inputRef={empprivew?.name}
//         //                             // label={empprivew.name}
//         //                             style={{ width: '100%', height: '35px', paddingLeft: '10px' }} /></Col>
//         //                 </Row>
//         //                 {/* <Row className='mt-3'>
//         //                     <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>Last name</p></Col>
//         //                     <Col sm={7}>
//         //                         <TextField id="outlined-basic" label="" variant="outlined" fullWidth size="small" /></Col>
//         //                 </Row> */}
//         //                 <Row className='mt-3'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Email</p></Col>
//         //                     <Col sm={7}>
//         //                         <Form.Control type='text' placeholder={empprivew?.name}
//         //                             // value={formData.emp_name}
//         //                             name='email_id'
//         //                             readOnly
//         //                             onChange={handleInputChange}
//         //                             defaultValue={empprivew?.email_id}
//         //                             required
//         //                             // inputRef={empprivew?.name}
//         //                             // label={empprivew.name}
//         //                             style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
//         //                     </Col>
//         //                 </Row>
//         //                 <Row className='mt-3'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Employee ID</p></Col>
//         //                     <Col sm={7}>
//         //                         <Form.Control type='text' placeholder={empprivew?.name}
//         //                             // value={formData.emp_name}
//         //                             name='email_id'
//         //                             readOnly
//         //                             onChange={handleInputChange}
//         //                             defaultValue={empprivew?.emp_code}
//         //                             required
//         //                             // inputRef={empprivew?.name}
//         //                             // label={empprivew.name}
//         //                             style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
//         //                     </Col>
//         //                 </Row>
//         //                 <Row className='mt-3'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Mobile Number</p></Col>
//         //                     <Col sm={7}> <InputGroup className="mb-3">
//         //                         <DropdownButton
//         //                             variant="outline-secondary"
//         //                             title="+91"
//         //                             id="input-group-dropdown-1"
//         //                         >
//         //                         </DropdownButton>
//         //                         <Form.Control aria-label="Text input with dropdown button" placeholder={empprivew?.cont}
//         //                             defaultValue={empprivew?.cont}
//         //                             name='cont'
//         //                             readOnly
//         //                             onChange={handleInputChange} />
//         //                     </InputGroup></Col>
//         //                 </Row>
//         //                 {/* <Row>
//         //                 <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Gender</p></Col>
//         //                     <Col sm={7}>
//         //                         <div>
//         //                             <input type="radio"
//         //                                 name="Gender" value="CSS" checked className='inputt' style={{marginRight:'5px',fontSize:'10px'}} />
//         //                             <label for="contactChoice1" style={{marginRight:'10px'}}>Male</label>

//         //                             <input type="radio" id="contactChoice2"
//         //                                 name="Gender" value="is" className='inputt' style={{marginRight:'5px'}} />
//         //                             <label for="contactChoice2" style={{marginRight:'10px'}}>Female</label>

//         //                             <input type="radio" id="contactChoice3"
//         //                                 name="Gender" value="nice" className='inputt' style={{marginRight:'5px'}} />
//         //                             <label for="contactChoice3" style={{marginRight:'10px'}}>Other</label>
//         //                         </div>                        </Col>
//         //                 </Row> */}
//         //                 <Row className='mt-2'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Date of Birth</p></Col>
//         //                     <Col sm={7}><Form.Control type="date" id="" placeholder={empprivew?.dob}
//         //                         readOnly

//         //                         style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//         //                         value={empprivew?.dob}
//         //                         name='dob'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row>
//         //                 <Row className='mt-2'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Designation</p></Col>
//         //                     <Col sm={7}><Form.Control type="text" id="" placeholder={empprivew?.dob}
//         //                         readOnly

//         //                         style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//         //                         value={empprivew?.designation}
//         //                         name='dob'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row>
//         //                 {/* <Row className='mt-2'>
//         //                 <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}>Designation</p></Col>
//         //                     <Col sm={7}><Form.Control type="text" id="" placeholder={empprivew?.dob}
//         //                     readOnly

//         //                         style={{ height: "35px", width: '100%',paddingLeft:'10px' }}
//         //                         value={empprivew?.designation}
//         //                         name='dob'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row> */}


//         //                 <Row className='mt-2'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}> In Time</p></Col>
//         //                     <Col sm={7}><Form.Control type="text" id="" className='text-capitalize' placeholder={empprivew?.start_time}
//         //                         readOnly

//         //                         style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//         //                         value={empprivew?.start_time}
//         //                         name='starttime'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row>

//         //                 <Row className='mt-2'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}> Out Time</p></Col>
//         //                     <Col sm={7}><Form.Control type="text" id="" className='text-capitalize' placeholder={empprivew?.end_time}
//         //                         readOnly

//         //                         style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//         //                         value={empprivew?.end_time}
//         //                         name='endtime'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row>
//         //                 <Row className='mt-2 '>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}> Attendance Basis </p></Col>
//         //                     <Col sm={7}><Form.Control type="text" id="" className='text-capitalize'

//         //                         style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//         //                         value={empprivew?.attendance_basis}
//         //                         name='endtime'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row>

//         //                 <Row className='mt-2'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}> Attendance Type </p></Col>
//         //                     <Col sm={7}><Form.Control type="text" id="" className='text-capitalize' placeholder={empprivew?.attendance_type}
//         //                         readOnly

//         //                         style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//         //                         value={empprivew?.attendance_type}
//         //                         name='endtime'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row>

//         //                 <Row className='mt-2'>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={3}><p style={{ fontSize: '16px', paddingLeft: '20px' }}> Salary </p></Col>
//         //                     <Col sm={7}><Form.Control type="text" id="" placeholder={empprivew?.salary}
//         //                         readOnly

//         //                         style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//         //                         value={empprivew?.salary}
//         //                         name='salary'
//         //                         onChange={handleInputChange}
//         //                     /></Col>
//         //                 </Row>



//         //                 <Row>
//         //                     <Col sm={1}></Col>
//         //                     <Col sm={10}>
//         //                         <Link to={`/Update/${params?.id}`}><button className='btn ' style={{ width: "110px", marginTop: '10px', marginBottom: "40px", float: 'right', backgroundColor: '#18285a', color: 'white' }}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
//         //                             <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
//         //                         </svg> Edit</button>
//         //                         </Link></Col>
//         //                 </Row>
//         //                 {/* <Row className='mt-3'>
//         //                     <Col sm={3}><p style={{ fontSize: '20px', paddingLeft: '20px' }}>About me</p></Col>
//         //                     <Col sm={7}>   <textarea class="form-control" id="exampleFormControlTextarea1" placeholder='Add About me  here' rows="3"></textarea></Col>
//         //                 </Row> */}

//         //             </form>
//         //         </Container>
//         //     </Card>
//         // </div>

//         <MDBCard className="mt-3">
//             <MDBCardHeader>
//                 Personal Details
//             </MDBCardHeader>
//             <MDBCardBody>
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Full Name</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type='text' placeholder={empprivew?.name}
//                                 contentEditable='false'
//                                 readOnly
//                                 name='emp_name'
//                                 onChange={handleInputChange}
//                                 value={empprivew?.name}
//                                 required
//                                 // inputRef={empprivew?.name}
//                                 // label={empprivew.name}
//                                 style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Email</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type='text' placeholder={empprivew?.name}
//                                 // value={formData.emp_name}
//                                 name='email_id'
//                                 readOnly
//                                 onChange={handleInputChange}
//                                 defaultValue={empprivew?.email_id}
//                                 required
//                                 // inputRef={empprivew?.name}
//                                 // label={empprivew.name}
//                                 style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Date of Birth</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type="date" id="" placeholder={empprivew?.dob}
//                                 readOnly

//                                 style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//                                 value={empprivew?.dob}
//                                 name='dob'
//                                 onChange={handleInputChange}
//                             />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Mobile Number</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <InputGroup className="mb-3">
//                                 <DropdownButton
//                                     variant="outline-secondary"
//                                     title="+91"
//                                     id="input-group-dropdown-1"
//                                 >
//                                 </DropdownButton>
//                                 <Form.Control aria-label="Text input with dropdown button" placeholder={empprivew?.cont}
//                                     defaultValue={empprivew?.cont}
//                                     name='cont'
//                                     readOnly
//                                     onChange={handleInputChange} />
//                             </InputGroup>
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Employee ID</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type='text' placeholder={empprivew?.name}
//                                 // value={formData.emp_name}
//                                 name='email_id'
//                                 readOnly
//                                 onChange={handleInputChange}
//                                 defaultValue={empprivew?.emp_code}
//                                 required
//                                 // inputRef={empprivew?.name}
//                                 // label={empprivew.name}
//                                 style={{ width: '100%', height: '35px', paddingLeft: '10px' }} />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Designation</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type="text" id="" placeholder={empprivew?.dob}
//                                 readOnly

//                                 style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//                                 value={empprivew?.designation}
//                                 name='dob'
//                                 onChange={handleInputChange}
//                             />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>In Time</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type="text" id="" className='text-capitalize' placeholder={empprivew?.start_time}
//                                 readOnly
//                                 style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//                                 value={empprivew?.start_time}
//                                 name='starttime'
//                                 onChange={handleInputChange}
//                             />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Out Time</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type="text" id="" className='text-capitalize' placeholder={empprivew?.end_time}
//                                 readOnly
//                                 style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//                                 value={empprivew?.end_time}
//                                 name='endtime'
//                                 onChange={handleInputChange}
//                             />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Attendance Type</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type="text" id="" className='text-capitalize' placeholder={empprivew?.attendance_type}
//                                 readOnly
//                                 style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//                                 value={empprivew?.attendance_type}
//                                 name='endtime'
//                                 onChange={handleInputChange}
//                             />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Attendance Basis</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type="text" id="" className='text-capitalize'

//                                 style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//                                 value={empprivew?.attendance_basis}
//                                 name='endtime'
//                                 onChange={handleInputChange}
//                             />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//                 <hr />
//                 <MDBRow>
//                     <MDBCol sm="3">
//                         <MDBCardText>Slary</MDBCardText>
//                     </MDBCol>
//                     <MDBCol sm="9">
//                         <MDBCardText className="text-muted">
//                             <Form.Control type="text" id="" placeholder={empprivew?.salary}
//                                 readOnly
//                                 style={{ height: "35px", width: '100%', paddingLeft: '10px' }}
//                                 value={empprivew?.salary}
//                                 name='salary'
//                                 onChange={handleInputChange}
//                             />
//                         </MDBCardText>
//                     </MDBCol>
//                 </MDBRow>
//             </MDBCardBody>
//         </MDBCard>
//     )
// }

// export default Persoanl
import React, { useEffect, useState, useRef } from 'react'
import { Tab ,Tabs} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import employeeModel from '../../Model/employee.Model';
import { Link } from 'react-router-dom';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardTitle, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBProgressBar, MDBProgress, MDBIcon, MDBListGroupItem, MDBListGroup, MDBBreadcrumb, MDBBreadcrumbItem, MDBCardHeader, MDBTypography } from 'mdb-react-ui-kit';
import Persoanltab from './personaltab';
import Persoanlbustab from './personalbustab';
import Address from './Address';
import Contact from './Contact';
import { editEmployee } from '../../store/silce/staffSlice';
import NomineeDetails from './NomineeDetails';

const Persoanl = () => {
    const params = useParams();
    const [empprivew, setEmpPrivew] = useState();
    const dispatch = useDispatch()
    const employeeDetails = useSelector((state) => state?.staff?.employeeDetails?.emp)
    const fetchData =  () => {
        console.log(params, "data")
        const formdata = new FormData();
        formdata.append("edit_emp", localStorage.getItem("token"));
        formdata.append("bus_id", localStorage.getItem("selecteoption"))
        formdata.append("emp_id", params?.id);
        dispatch(editEmployee({ id: params?.id }))
        console.log()
       
    }
    useEffect(() => {
        fetchData()
    }, [])

    
    return (

<>
<Tabs
      defaultActiveKey="basic"
      id="fill-tab-example"
      className="mb-3 mt-3"
    variant='pills'
    fill
    >
      <Tab eventKey="basic" title="Basic Details">
        <Persoanltab  data={employeeDetails}/>
      </Tab>
      <Tab eventKey="address"  title="Address Details">
        <Address  data={employeeDetails}/>
      </Tab>
      <Tab eventKey="nomineedetails" title="Nominee Details">
      <NomineeDetails  data={employeeDetails}/>
      </Tab>
      <Tab eventKey="comapny" title="Professional Details">
      <Persoanlbustab  data={employeeDetails}/>
      </Tab>
      <Tab eventKey="contact" title="Emergency contact">
      <Contact  data={employeeDetails}/>
      </Tab>
   
    </Tabs>
      
        </>
    )
}

export default Persoanl