import React, { useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttendance } from "../../store/silce/dashboardSlice";
import Loader from "../../components/Common/Loading";
import { Chart } from "react-google-charts";

const DashboardChartNews = ({ attendance }) => {
  const pieData = {
    labels: ["On Leave", "Late Entry", "Total Staff"],
    datasets: [
      {
        label: "Employee",
        data: [
          attendance?.paid_holiday || 0,
          attendance?.late_entry || 0,
          attendance?.total_count || 0,
        ],
        backgroundColor: ["#FDB45C", "#949FB1", "#4D5360"],
        hoverBackgroundColor: ["#FFC870", "#A8B3C5", "#616774"],
        borderWidth: 1,
      },
    ],
  };

  // const options = {
  //   cutoutPercentage: 50, // Center the hole
  //   responsive: true,
  //   maintainAspectRatio: false, // Set to false to avoid the chart being sized as per parent container
  // };

  // const data = [
  //   ["On Leave", "Hours per Day"],
  //   ["Late Entry", 11],
  //   ["Eat", 2],
  //   ["Commute", 2],
  //   ["Watch TV", 2],
  //   ["Sleep", 7],
  // ];

  const totalLeave = parseInt(attendance?.paid_holiday || 0);
  const totalLateEntry = parseInt(attendance?.late_entry || 0);
  // const totalStaff = parseInt(attendance?.total_count || 0);
  const hasData = totalLeave !== 0 || totalLateEntry !== 0 ;

  const data = hasData
  ? [
      ["Label", "Value"],
      ["On Leave", totalLeave,],
      ["Late Entry", totalLateEntry],
      // ["Total Staff", totalStaff],
    ]
  : [
    ["Label", "Metric"],
    ["No Data", 100], // Display a single color representing 100% when there is no data
    ];
  const options = {
    // title: "My Daily Activities",

    is3D: true,

    slices: {
      0: { color: "#8ba1c7" }, // Red color for "Absent" slice
      1: { color: "#7aa9fa" }, // Green color for "Present" slice
    },
  };

  return (
    <div >
      {/* <Pie data={pieData} options={options} /> */}
      <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"200px"}
    />
    </div>
  );
};

export default DashboardChartNews;
