import React, { useState, useEffect } from "react";
import "../Aseset/css/sidenav.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import DataTable from "../components/Tables/Tables";
import TableDropdown from "../components/TableDropDown/TableDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllBusinesses,
  addBusiness,
  editBusiness,
  clearSelectedData,
  updateSubmit,
  businesssectorFetch,
  setFilterData,
  setLoading,
  businessDelete,
  updateBusLogo,
} from "../store/silce/bussiness";
import {
  closeEditModal,
  closeModal,
  openEditModal,
  openModal,
} from "../store/silce/modalSlice";
import CommonModal from "../components/Common/CommonModal";
import CommonEditModal from "../components/Common/CommonEditModal";
import { helper } from "../lib/helper";
import businessModel from "../Model/business.model";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const BusinessLIst = () => {
  const dispatch = useDispatch();
  const [outid, setOutid] = useState(0);
  const [currentTitle, setCurrentTitle] = useState("add-business");
  const showModal = useSelector((state) => state.modal.showModal);
  const currentPage = useSelector((state) => state.modal.currentPage);
  const showEditModal = useSelector((state) => state.modal.showEditModal);
  const businessesList = useSelector((state) => {
    return state.Business.businessesList;
  });
  const loading = useSelector((state) => {
    return state.Business.loading;
  });
  const updatedData = useSelector((state) => {
    return state.Business.updatedData;
  });
 
  const sectorData = useSelector((state) => {
    return state.Business.sector;
  });
  const filterdData = useSelector((state) => {
    return state.Business.filterdData;
  });
  const employeeDetails = useSelector(
    (state) => state?.employee?.Employee?.data?.permissions
  );
  const filteredData =
    employeeDetails &&
    employeeDetails?.filter((item) => item?.name === "business");
  const Admindetails = useSelector(
    (state) => state?.dashboard?.home?.data?.roleName
  );
 
  const displaydata = async (id) => {
    dispatch(editBusiness({ id: id }));
    setOutid(id);
  };
  useEffect(() => {
    dispatch(fetchAllBusinesses()).then(()  => dispatch(businesssectorFetch()));
  }, []);
  const onDelete = (id) => {
    helper.sweetalert
      .confirm(
        "Are you sure?",
        "You won't be able to revert this!",
        "warning",
        true
      )
      .then((result) => {
        if (result.isConfirmed) {
          const formdata = new FormData();
          formdata.append("delete_bus", localStorage.getItem("token"));
          formdata.append("bus_id", id);
          businessModel
            .deletebusiness(formdata)
            .then((res) => {
              helper.sweetalert.toast(
                "Deleted",
                "Your Business has been deleted.",
                "success"
              );
              return dispatch(fetchAllBusinesses());
            })
            .then(() => dispatch(businesssectorFetch())); // Fetch sector data
        }
      });
  };
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "4%",
      center: "center",
    },
    {
      name: "Logo",
      selector: (row) => row.bus_logo,
      cell: (row) => {
        return (
          <img
            src={row.bus_logoUrl}
            alt="Avatar"
            style={{ width: "30px", height: "30px", borderRadius: "10px" }}
          />
        );
      },
      width: "8%",
      center: "center",
    },
    {
      name: "Company Name",
      selector: (row) => row.name,
      // center:'center',
    },
    {
      name: "Sector",
      selector: (row) => row.business_sector_name,
      width: "12%",
    },
    {
      name: "Mobile No",
      selector: (row) => row.contact,
      center: "center",
      width: "12%",
    },
    {
      name: "Total Staff",
      selector: (row) => row.staff_count,
      center: "center",
      width: "10%",
    },
    {
      name: "Salary Type",
      selector: (row) => row.salary_basis,
      cell: (row) => {
        return (
          <div>
            <span className="text-capitalize">{row.salary_basis}</span>
          </div>
        );
      },
      width: "10%",
      center: "center",
    },
    {
      name: "Company Email Addrerss",
      selector: (row) => row.business_mails,
    },
    {
      name: "#",
      cell: (row) => {
        return (
          <TableDropdown
            onClick={() => onDelete(row.id)}
            onDeleteClick={() => onDelete(row.id)}
            openEditModal={() => dispatch(openEditModal("edit-business"))}
            isRemove={
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0]?.delete == "true") ||
              (Admindetails === "client" && true)
            }
            isEditbuss={
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0]?.edit == "true") ||
              (Admindetails === "client" && true)
            }
            isEditlogo={
              (filteredData &&
                filteredData.length > 0 &&
                filteredData[0]?.edit == "true") ||
              (Admindetails === "client" && true)
            }
            isQRlogo={true}
            displaydata={displaydata}
            setCurrentTitle={setCurrentTitle}
            page="businessList"
            id={row.id}
            handleShow={handleShow}
          />
        );
      },
      width: "4%",
      center: "center",
    },
  ];
  const [formData, setFormData] = useState({
    add_site: localStorage.getItem("token"),
    cl_name: "",
    cl_cont: "",
    bus_sector: "",
    salary_basis: "",
    cl_email1: "",
    email_count: "1",
  });
  const [num, setNum] = useState("");
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (event.target.type === "number") {
      setNum(event.target.value.slice(0, limit));
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(addBusiness(event));
    dispatch(fetchAllBusinesses());
    dispatch(closeModal());
  };
  const limit = 10;
  const [formData1, setFormData1] = useState({
    bus_name: "",
    staff_count: "",
    salary_basis: "",
    whatsapp: "",
    bus_sector: "",
    bus_id: "",
    cl_email1: "",
    email_count: "1",
  });
  const handleInputChange1 = (event) => {
    setFormData1({
      ...formData1,
      [event.target.name]: event.target.value,
    });
    let value = event.target.value;
    if (event.target.type === "number") {
      value = value.length > limit ? value.substring(0, limit) : value;
    }
    event.target.value = value;
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    dispatch(updateSubmit({ outid: outid, event: event }));
    displaydata(updatedData?.id);
    dispatch(fetchAllBusinesses());
    dispatch(closeEditModal());
  };
  const [formDatas, setFormDatas] = useState({
    logo: "",
  });
  const handleInputChanges = (event) => {
    setFormDatas({
      ...formDatas,
      [event.target.name]: event.target.value,
    });

    setNum(event.target.value.slice(0, limit));
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    dispatch(updateBusLogo({ event: event }));
    dispatch(fetchAllBusinesses());
    handleClose();
  };
  const pageData = {
    outid: outid,
    dropdrownElement: sectorData,
    formData: formData,
  };

  const handleItemClick = (radioId) => {
    // Programmatically trigger the click event on the radio button
    document.getElementById(radioId).click();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col>
              {(filteredData &&
                filteredData.length > 0 &&
                filteredData[0]?.add == "true") ||
              Admindetails === "client" ? (
                <>
                  {" "}
                  <div className="mt-0 mb-5">
                    <Button
                      className="btn submitButtons"
                      style={{
                        float: "right",
                        backgroundColor: "#18285a",
                        color: "white",
                      }}
                      onClick={() => {
                        dispatch(openModal("add-business"));
                        setCurrentTitle("Add New Business");
                      }}
                    >
                      Add Business
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="mt-1 mb-3">
                <DataTable
                  loading={loading}
                  columns={columns}
                  data={businessesList}
                  title={"Business List"}
                  page={"businessList"}
                  searchData={businessesList}
                  setData={setFilterData}
                  keys={["name", "contact", "business_sector_name"]}
                  filterdData={filterdData}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <CommonModal
          size="lg"
          pageData={pageData}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          show={showModal}
          onHide={() => {
            dispatch(closeModal());
            dispatch(clearSelectedData());
          }}
          name={currentPage}
          title={currentTitle}
          handleItemClick={handleItemClick}
        />
        <CommonEditModal
          size="lg"
          pageData={pageData}
          onChange={handleInputChange1}
          onSubmit={handleSubmit1}
          show={showEditModal}
          onHide={() => {
            dispatch(closeEditModal());
            dispatch(clearSelectedData());
          }}
          name={"edit-business"}
          title={"Edit Business"}
          handleItemClick={handleItemClick}
        />
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Logo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Form onSubmit={handleSubmit2}>
              <div className="row">
                <div className="col-md-">
                  <input
                    type="hidden"
                    name="bus_id"
                    id=""
                    value={pageData?.outid}
                  />
                  <Form.Group controlId="nameOfBusiness">
                    <Form.Label className="mt-2">Update Logo*</Form.Label>
                  </Form.Group>
                </div>
                <div className="col-md-8">
                  {" "}
                  <Form.Control
                    onChange={handleInputChanges}
                    name="logo"
                    type="file"
                  />
                </div>
              </div>
              <div className="mt-4">
                <Button
                  style={{ float: "left" }}
                  className=""
                  variant="secondary"
                  onClick={() => {}}
                >
                  Close
                </Button>
                <Button
                  style={{ float: "right", borderStyle: "none" }}
                  className="submitButtons "
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default BusinessLIst;
