// modalSlice.js
import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    showModal: false,
    currentPage: "",
    showEditModal:false,
    showEditModal1:false,
    showDeleteModal:false,
    idRemove:""
  },
  reducers: {
    openModal: (state, action) => {
      state.showModal = true;
      state.currentPage = action.payload;
    },
    closeModal: (state) => {
      state.showModal = false;
      state.currentPage = ""
    },

    openEditModal: (state, action) => {
      state.showEditModal = true;
      state.currentPage = action.payload;
      state.idRemove = action.payload
    },
    closeEditModal: (state) => {
      state.showEditModal = false;
      state.currentPage = ""
    },
    openEditModal1: (state, action) => {
      state.showEditModal1 = true;
      state.currentPage = action.payload;
      state.idRemove = action.payload
    },
    closeEditModal1: (state) => {
      state.showEditModal1 = false;
      state.currentPage = ""
    },

    openDeleteModal: (state, action) => {
      state.showDeleteModal = true;
      // state.currentPage = action.payload;
      state.idRemove = action.payload
    },
    closeDeleteModal: (state) => {
      state.showDeleteModal = false;
      state.currentPage = ""
    },
  },
});

export const { openModal, closeModal, openEditModal, closeEditModal, openEditModal1, closeEditModal1, openDeleteModal, closeDeleteModal } = modalSlice.actions;
export default modalSlice.reducer;
